import { Injectable } from '@angular/core';
import * as crypto from 'crypto-js';

import { AHubServiceCredentialsVO } from 'services/ahub/ahub-service-credentials.vo';
import { PaginationControlAppVO } from 'valueObjects/app/pagination-control.app.vo';
import { HttpParams, HttpRequest } from '@angular/common/http';
import { StoreAccess } from 'store/store-access';
import { sessionUserSessionCredentials } from 'selector/app.selector';

@Injectable()
export class AhubAuthInterceptorUtilsService {

  constructor() {
    // This is intentional
  }

  /**
 * Takes a request path ( api domoain is added according to environment ) , http method, optional call parameters and
 * body to construct request Options for making the URL call this will include aHub signatures if required.
 */

  setAhubRequestHeaders(httpMethod: string, requestPath: string, sessionCredentials: AHubServiceCredentialsVO, queryString: string, body?: any, paginationControl?: PaginationControlAppVO): any {

    // Create a new set of request options.
    let headersObject: Object = {};

    // Establish timestamp for request.
    const requestTime: string = (new Date).getTime().toString();

    // Add basic headers for the request
    headersObject['hark-auth-timestamp'] = requestTime;

    if (paginationControl) {
      headersObject['hark-options-pagination-page-size'] = paginationControl.pageSize.toString();
      headersObject['hark-options-pagination-next-index'] = paginationControl.nextIndex.toString();
      headersObject['hark-options-pagination-sort-direction'] = paginationControl.sortDirection.toString();
    }


    // Calculate signature and add to headers.
    if (sessionCredentials != undefined) {

      // Get sesion details from the the store.
      const sessionId: string = sessionCredentials.sessionId;
      const sessionKey: string = sessionCredentials.sessionKey;

      //We want to add the auth session id into the header ... we need to do this before we create the signature so it will be included
      if (sessionId !== undefined)
        headersObject['hark-auth-session-id'] = sessionId;

      // Do we have a session key?
      if (sessionKey !== undefined) {

        //Create a signature
        const signature: string = this.signature(sessionKey, httpMethod, requestPath, headersObject, queryString, body);

        //Add the session id and the signature to the request headers
        headersObject['hark-auth-signature'] = signature;
      }

    }

    // All aHub requests are JSON. at this time.
    // headersObject['Content-Type'] = 'application/json';

    // If we are pagniating, we will need to know the next starting index to ask for
    if (paginationControl) {
      headersObject['Access-Control-Allow-Headers'] = 'ahub-pagination-next-index';
      headersObject['Access-Control-Expose-Headers'] = 'ahub-pagination-next-index';
    }

    //Return the request options
    return headersObject;
  }

  /**
 * Takes a request path ( api domoain is added according to environment ) , http method, optional call parameters and
 * body to construct request Options for making the URL call this will include aHub signatures if required.
 */

  addAuthHeaders(req: HttpRequest<any>, requestPath: string, sessionCredentials: AHubServiceCredentialsVO, queryString: string): HttpRequest<any> {

    // Create a new set of request options.
    let headersObject: Object = this.buildHeadersObjectFromRequestHeaders(req);

    // Establish timestamp for request.
    const requestTime: string = (new Date).getTime().toString();

    // Add basic headers for the request
    headersObject['hark-auth-timestamp'] = requestTime;

    // Calculate signature and add to headers.
    if (sessionCredentials != undefined) {

      // Get sesion details from the the store.
      const sessionId: string = sessionCredentials.sessionId;
      const sessionKey: string = sessionCredentials.sessionKey;

      //We want to add the auth session id into the header ... we need to do this before we create the signature so it will be included
      if (sessionId !== undefined)
        headersObject['hark-auth-session-id'] = sessionId;

      // Do we have a session key?
      if (sessionKey !== undefined) {

        //Create a signature
        let signature: string = this.signature(sessionKey, req.method, requestPath, headersObject, queryString, req.body);

        req = req.clone({
          setHeaders: {
            'hark-auth-timestamp': requestTime,
            'hark-auth-session-id': sessionId,
            'hark-auth-signature': signature
          }
        })

      }

    }

    return req;
  }

  buildHeadersObjectFromRequestHeaders(req: HttpRequest<any>) {
    let headersObject: Object = {};
    req.headers.keys().forEach(headerKey => {
      headersObject[headerKey] = req.headers.get(headerKey);
    })
    return headersObject;
  }

  /**
 * Constructs an aHub request signature string
 */
  private signature(sessionKey: string, httpMethod: string, requestPath: string, headers: Object, queryString: string, body?: string): string {

    //If the body is non existant then we will use an empty string
    if (body == undefined || body == null)
      body = '';

    // This will hold the data required to create a signature.
    // Signature consists of:
    // 0:     Http method
    // 1:     Api request path ( excluding domain and parameters )
    // 2 - x: Http request headers ( in alphabetical order one per line )
    // x + 1: parameters ( in alphabetical order )
    // x + 2: Hash value of the data in the body.

    // Empty data object
    let signatureData: string[] = [];

    // Add in the easy predefined data.
    signatureData.push(httpMethod.toUpperCase());
    signatureData.push(requestPath);
    signatureData = signatureData.concat(this.headersObjectToHeadersStringArray(headers));
    signatureData.push(queryString);

    // Calculate the body hash and add to signature data.
    signatureData.push(crypto.SHA256(body).toString());

    //Create a signature string for the message
    let signatureString = signatureData.join('\n').toString();

    //Hash the signature string
    let hashedSignatureString = crypto.SHA256(signatureString).toString();

    // Constuct signature string from parts.
    // Signature data is joined using newlines and hash created of value, this is used with session key to create HMAC signing value.
    let signature = crypto.HmacSHA256(hashedSignatureString, sessionKey).toString();

    //Return the signature to the caller
    return signature;
  }

  httpParamsToQueryString(params: HttpParams): string {

    // Quick sanity check for null object.
    // Returns empty string to represent no params.
    if (!params) {
      return "";
    }

    // Constructing {key ,value} pairs array from object
    // Object properties have no order.. arrays do.
    // If the object property itself is an array of strings ( ie one property has multiple values ),
    // then we need to create an individual key value pair for each.
    let paramKeyPairArray: Object[] = [];

    params.keys().forEach(paramKey => {

      // Loop through object properties.
      for (var paramkey in params) {
        // Does this property have multiple values ( ie an array )
        if (Array.isArray(params.getAll(paramkey))) {

          //Cast the values as an array
          let values: any[] = <any[]>params.getAll(paramkey);

          //For each value create the parameter pair and add them to the array
          values.forEach(value => paramKeyPairArray.push({ key: paramkey, value: value }));
        }
        else {

          //Create the parameter value pair and add it to the array
          paramKeyPairArray.push({ key: paramkey, value: params.get(paramkey) });
        }
      }

    });
    this.paramSort(paramKeyPairArray);

    // Construct the URLSearchParams which will carry out any encoding.
    let urlParams: URLSearchParams = new URLSearchParams();
    for (let paramKeyPair of paramKeyPairArray) { urlParams.append(paramKeyPair["key"], paramKeyPair["value"]); }

    // Turn into string and return.
    return urlParams.toString();
  }

  private paramSort(paramKeyPairArray: Object[]) {
    paramKeyPairArray.sort((n1, n2) => {
      // Get the keys and values as strings. This is to ensure
      // that all comparisons are done with string values.
      // This means 150 will actually equate to less and thefore
      // be before 95.
      let n1Key: string = (n1["key"] === undefined) ? "" : n1["key"].toString();
      let n2Key: string = (n2["key"] === undefined) ? "" : n2["key"].toString();
      let n1Value: string = (n1["value"] === undefined) ? "" : n1["value"].toString();
      let n2Value: string = (n2["value"] === undefined) ? "" : n2["value"].toString();
      if (n1Key > n2Key) {
        return 1;
      }
      if (n1Key < n2Key) {
        return -1;
      }
      if (n1Value > n2Value) {
        return 1;
      }
      if (n1Value < n2Value) {
        return -1;
      }
      return 0;
    });
  }


  /**
   * Takes a headers object and converts it into a string array which can be used for signing a http request
   */
  private headersObjectToHeadersStringArray(headers: Object): string[] {

    //Array which we will add all the elements to
    let headerStrArray: string[] = [];

    //Loop through each header item, and then each value adding each header value pair to the headers string array
    Object.keys(headers).forEach(headerKey => {
      headerStrArray.push(headerKey + ':' + headers[headerKey]);
    });

    //Sort our header string array into alphabetical order
    headerStrArray.sort();

    //Return our created and sorted array
    return headerStrArray;
  }


  /**
 * Gets unsigned credentials for the unsigned requests
 */
  getUnsignedCredentials(): AHubServiceCredentialsVO {

    //Now I know this looks strange but this will give us a level of consistancy accross our requests
    //so it will be obvious which are signed and not.
    return undefined;
  }

  /**
 * Get the signed credentials for the ahub service request
 */
  getSignedCredentials(): AHubServiceCredentialsVO {

    //Get the session credentials for the currently signed in user
    let sessionCredentials: AHubServiceCredentialsVO = StoreAccess.dataGet(sessionUserSessionCredentials);

    //If the session credentials are missing we will rerturn with unsigned credentials.
    //as the request is expecting credentials it will likly fail
    if (sessionCredentials == undefined || sessionCredentials == null)
      return this.getUnsignedCredentials();

    //Return a new ahub session credentials object
    return {
      sessionId: sessionCredentials.sessionId,
      sessionKey: sessionCredentials.sessionKey
    };
  }
}

import { Action, Reducer } from 'redux';
import { tassign } from 'modules/common/type-assign.util';

import {
    AHubPermanentState,
    AHubPermanentStateInitial,
    AHubPermanentReducer,
    AHubPermanentReducerHasHandler
} from 'reducers/ahub/ahub-permanent.reducer';

import {
    AHubTemporaryState,
    AHubTemporaryStateInitial,
    AHubTemporaryReducer,
    AHubTemporaryReducerHasHandler
} from 'reducers/ahub/ahub-temporary.reducer';


/**
 * ----------------------------------
 * aHub State interface
 * ----------------------------------
 */
export interface AHubState {
    permanent: AHubPermanentState,
    temporary: AHubTemporaryState
};

/**
 * ----------------------------------
 * Initial State
 * ----------------------------------
 */
const initialState: AHubState = {
    permanent: AHubPermanentStateInitial,
    temporary: AHubTemporaryStateInitial
};

/**
 * ----------------------------------
 * aHub State Reducer
 * ----------------------------------
 */
export const AHubReducer: Reducer<AHubState> = (state: AHubState = initialState, action): AHubState => {

    //Does the permanent section of the store have a handler for this action type
    if (AHubPermanentReducerHasHandler(action.type))
        return tassign(state, { permanent: AHubPermanentReducer(state.permanent, action) });

    //Does the temporary section of the store have a handler for this action type
    if (AHubTemporaryReducerHasHandler(action.type))
        return tassign(state, { temporary: AHubTemporaryReducer(state.temporary, action) });

    //If we got to the end and the state was not returned we cannot handel the state change
    //so we will juest return the basic state
    return state;
};
import { Component } from '@angular/core';
import { IHeaderGroupAngularComp } from 'ag-grid-angular';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-grid-group-header-checkbox',
  templateUrl: './grid-group-header-checkbox.component.html',
  styleUrls: ['./grid-group-header-checkbox.component.css']
})
export class GridGroupHeaderCheckboxComponent implements IHeaderGroupAngularComp {

  /**
   * Parameters for the renderer
   */
  params: any;
  checkboxState = false;

  agInit(params): void {

    //Set the current set of parameters
    this.params = params;
  }

  checkboxStateChanged() {
    this.params.checkboxState$.next(this.checkboxState);
  }
}

/**
 * Custom parameters for this componenet
 */
export interface GridGroupHeaderCheckboxParameters {
  checkboxState$: BehaviorSubject<boolean>;
  label?: string;
}


import { debounceTime, takeUntil, combineLatest, startWith } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
  FormGroup,
  FormBuilder
} from '@angular/forms';
import { Hark, componentDestroyStream } from 'modules/common/hark.decorator';

/**
 * Value objects.
 */
import { ProductClassFilterOptionData } from '../product-class-filter-option-data.vo';
import { ProductClassRuleAHubVO } from 'valueObjects/ahub/library/product-class-rule.ahub.vo';


@Component({
  selector: 'app-add-edit-product-class-rule-dialog',
  templateUrl: './add-edit-product-class-rule-dialog.component.html',
  styleUrls: ['./add-edit-product-class-rule-dialog.component.scss']
})
@Hark()
export class AddEditProductClassRuleDialogComponent implements OnInit, OnDestroy {

  /**** REQUIRED FOR USE IN A DIALOGUE  ****/
  /**
   * The VO that is passed in / out.
   */
  public dialogVO: any = null;

  /**
   * This is the full list of the product class filters options available.
   */
  allClassOptionObjects$: Observable<ProductClassFilterOptionData[]>;

  /**
   * These are the class options that the user can select in the include class id section.
   */
  includeClassOptionObjects: ProductClassFilterOptionData[] = []

  /**
   * These are the class options that the user can select in the exclude class id section.
   */
  excludeClassOptionObjects: ProductClassFilterOptionData[] = []

  /**
   * This is the product class rule object we are working with.
   */
  productClassRule: ProductClassRuleAHubVO;

  /**
   * Boolean stream should emit values to indicate whether dialog is valid.
   */
  public dialogIsValid$: Subject<boolean> = new Subject<boolean>();


  /**
   * Form to control the selection of the include class id.
   */
  includeForm: FormGroup = this.formBuilder.group({
    includeClassId: []
  });

  /**
   * Form to control the selection of the next exclude class id.
   */
  excludeForm: FormGroup = this.formBuilder.group({});

  /**
   * This is the count of selected classes.
   */
  selectedClassCount: number = 0;


  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {

    // Get the product class rule we are working with.
    this.productClassRule = this.dialogVO.productClassRule;

    // Make sure we have at least a list of product class rule exclusion class ids.
    if (!this.productClassRule.excludeClassIds)
      this.productClassRule.excludeClassIds = [];


    // Get the class option objects.
    this.allClassOptionObjects$ = this.dialogVO.classOptionObjects$;

    // Watch the class objects.
    this.allClassOptionObjects$.pipe(
      takeUntil(componentDestroyStream(this)))
      .subscribe(classObjectOptions => {

        // Duplicate the exclude and put in the include list.
        this.includeClassOptionObjects = classObjectOptions.slice();

        // Then add a new un-selected item at the first position.
        this.includeClassOptionObjects.unshift({
          productClass: {
            id: -1,
            label: "No class",
            ancestry: "",
            description: "",
            productMatrixDefinition: null
          }
        });
      });



    // Set the selected class count.
    this.selectedClassCount = this.productClassRule.excludeClassIds.length;


    // Now pass the include id to the form if we have one.
    this.includeClassIdControlGet().setValue(this.productClassRule.includeClassId);


    // Watch the changes to the include form.
    this.allClassOptionObjects$.pipe(
      debounceTime(200),
      takeUntil(componentDestroyStream(this)),
      combineLatest(this.includeForm.valueChanges.pipe(startWith(null)), (classObjectOptions, formResults) => (classObjectOptions)))
      .subscribe(classObjectOptions => {

        // Get the new include class id.
        let includeClassId: number = this.includeClassIdControlGet().value;

        // Now pass it onto the product class rule.
        this.productClassRule.includeClassId = includeClassId;


        // Filter the class object options if we the include class id has been selected.
        if (includeClassId > -1)
          classObjectOptions = classObjectOptions.filter(classObjectOption => {

            // Does this class object option have the selected class id in it's ancestry?
            let foundInAncestry = classObjectOption.productClass.ancestry.search("," + includeClassId + ",") > -1

            // If not, we want to remove the current control.
            if (!foundInAncestry)
              this.excludeForm.removeControl(classObjectOption.productClass.id.toFixed(0))

            // Now return the is found value.
            return foundInAncestry;
          });


        // Set the exclude class option objects.
        this.excludeClassOptionObjects = classObjectOptions;

        // Set up the class list.
        this.excludeClassOptionObjects.forEach(classOption => {

          // Has this class been selected?
          let selectedClass: boolean = (this.productClassRule.excludeClassIds.indexOf(classOption.productClass.id) > -1);

          // Add each product class control to the list.
          this.excludeForm.addControl(classOption.productClass.id.toFixed(0), this.formBuilder.control(selectedClass));
        });

        // Call the function to update the exclude class id's.
        this.excludeClassIdsUpdate();

        // Make sure we set set the dialogue is valid to true.
        this.dialogIsValid$.next(true);
      });


    // Now we need to track the selection of new exclude ids.
    this.excludeForm.valueChanges.pipe(
      debounceTime(200),
      takeUntil(componentDestroyStream(this)))
      .subscribe(controls => {

        // Call the function to update the exclude class id's.
        this.excludeClassIdsUpdate();

        // Make sure we set set the dialogue is valid to true.
        this.dialogIsValid$.next(true);
      });
  }

  /**
   * Empty On destroy to ensure @Hark decorator works for an AOT build
   */
  ngOnDestroy() { }

  /**
   * This function will update the exclude class id's.
   */
  private excludeClassIdsUpdate() {

    // Create a new list of product class id's.
    let excludeClassIds: number[] = [];

    // Repeat through the class id's in the controls list.
    for (const classId in this.excludeForm.controls) {

      // Is the value assigned to this control true? If so, add it to the excluded list.
      if (this.excludeForm.controls[classId].value)
        excludeClassIds.push(parseInt(classId));
    }

    // Now set the exclude class ids.
    this.productClassRule.excludeClassIds = excludeClassIds;

    // Set the selected class count.
    this.selectedClassCount = excludeClassIds.length;
  }


  /**
   * Get and return the include class id control.
   */
  includeClassIdControlGet() {
    return this.includeForm.controls["includeClassId"];
  }
}
import { StoreAccess } from 'store/store-access';
import { AppActions } from 'actions/app.actions';
import { AHubActions } from 'actions/ahub.actions';

import { UserSessionCredentialsAHubVO } from 'valueObjects/ahub/accounts/user-session-credentials.ahub.vo';

import { sessionUserSessionCredentials, sessionClientId, returnToUrl, appLibraryViewConfigMapStorage } from 'selector/app.selector';
import { loginToken, externalAppToken } from 'selector/app.selector';
import { MapStorage } from '../map-storage.vo';
import { DataSetLibraryViewConfigAHubVO } from 'app/valueObjects/ahub/library/dataset-library-view-config.ahub.vo';
import { AViewActions } from '../actions/aview.actions';
import { ProductViewConfigAViewVO } from 'app/modules/routes/aview/valueObjects/product-view-config.aview.vo';
import { aViewProductViewConfigMapStorage } from '../selector/aview.selector';

const LOGIN_TOKEN_LOCAL_STORAGE_NAME = 'loginToken';
const EXTERNAL_APP_TOKEN_LOCAL_STORAGE_NAME = 'externalAppToken';
const RETURN_TO_URL_LOCAL_STORAGE_NAME = 'returnToUrlToken';
const LIBRARY_VIEW_CONFIG_LOCAL_STORAGE_NAME = 'libraryViewConfig';
const AVIEW_PRODUCT_VIEW_CONFIG_LOCAL_STORAGE_NAME = 'aViewProductViewConfig';

export class StoreLocalStorage {


  /**
   * This is the property name of the user credentials in the local storage.
   */
  private sessionCredentialsItemName: string = "sessionCredentials";

  /**
   * This is the property name of the sessions client id in the local storage.
   */
  private sessionClientIdItemName: string = "sessionClientId";


  constructor() {

    // Get the user credentials.
    let userCredentials: UserSessionCredentialsAHubVO = this.userCredentialsGet();

    // Pass on all of the local storage values into the store.
    StoreAccess.dispatch(AppActions.sessionUserSessionCredentialsSet(userCredentials));
    StoreAccess.dispatch(AppActions.sessionClientIdSet(this.sessionClientIdGet()));
    StoreAccess.dispatch(AppActions.loginTokenSet(this.loginTokenGet()));
    StoreAccess.dispatch(AppActions.externalAppTokenSet(this.externalAppTokenGet()));
    StoreAccess.dispatch(AppActions.returnToUrlSet(this.returnToUrlGet()));
    StoreAccess.dispatch(AppActions.libraryViewConfigSet(this.libraryViewConfigGet()));

    // TODO: Create local storage for AView?
    StoreAccess.dispatch(AViewActions.productViewConfigSet(this.aViewProductViewConfigGet()));

    // Get the current user id by credentials.
    if (userCredentials) {
      let actionId = StoreAccess.dispatch(AHubActions.userIdBySessionFetch(userCredentials));
    }

    // Listen for the change in the user credentials.
    StoreAccess.dataGetObvs(sessionUserSessionCredentials).subscribe(userCredentials => {

      // Save the user credentials.
      this.userCredentialsSet(userCredentials);
    })


    // Listen for the change in the session client id.
    StoreAccess.dataGetObvs(sessionClientId).subscribe(clientId => {

      // Save the session client id.
      this.sessionClientIdSet(clientId);
    })

    StoreAccess.dataGetObvs(loginToken).subscribe(loginToken => {

      // Save the login token.
      this.loginTokenSet(loginToken);
    })

    StoreAccess.dataGetObvs(externalAppToken).subscribe(externalAppToken => {

      // Save the external app token.
      this.externalAppTokenSet(externalAppToken);
    })

    StoreAccess.dataGetObvs(returnToUrl).subscribe(url => {

      // Save the return to URL token.
      this.returnToUrlSet(url);
    })

    StoreAccess.dataGetObvs(appLibraryViewConfigMapStorage).subscribe(libraryViewConfig => {

      // Save the return to URL token.
      this.libraryViewConfigSet(libraryViewConfig);
    })

    StoreAccess.dataGetObvs(aViewProductViewConfigMapStorage).subscribe(productViewConfig => {

      // Save the return to URL token.
      this.aViewProductViewConfigSet(productViewConfig);
    })
  }


  /**
   * Record a set of user credentials in the local storage.
   */
  private userCredentialsSet(userCredentials: UserSessionCredentialsAHubVO) {

    // Are the user credentials null or undefined?
    if (!userCredentials) {

      // Delete the session credentials item.
      localStorage.removeItem(this.sessionCredentialsItemName);

      // Then stop here.
      return;
    }

    // Convert the user session credentials into a JSON string and save.
    localStorage.setItem(this.sessionCredentialsItemName, JSON.stringify(userCredentials));
  }

  /**
   * Get the saved user credentials from the local storage.
   */
  private userCredentialsGet(): UserSessionCredentialsAHubVO {

    // Create the variable to hold the session credentials we have found.
    let userCredentials: UserSessionCredentialsAHubVO;

    try {

      // Get the user credentials, parse them and return them.
      userCredentials = JSON.parse(localStorage.getItem(this.sessionCredentialsItemName));
    }
    catch (error) {

      // Set the credentials to undefined.
      userCredentials = undefined;
    }

    // Now return the credentials.
    return userCredentials;
  }


  /**
   * Pass the session client id into the local storage.
   *
   * @param clientId      The client id to record.
   */
  private sessionClientIdSet(clientId: number) {

    // Is the client id null, undefined or less than 0?
    if (!clientId || clientId < 0) {

      // Yes, so remove the item.
      localStorage.removeItem(this.sessionClientIdItemName);

      // Then stop here.
      return;
    }

    // Set the client id in the local storage.
    localStorage.setItem(this.sessionClientIdItemName, clientId.toString());
  }

  /**
   * Get the current session client id from the local storage.
   */
  private sessionClientIdGet(): number {

    // Get the client id from the local storage.
    let clientId = localStorage.getItem(this.sessionClientIdItemName);

    // If the client id is undefined or null then return undefined;
    if (!clientId)
      return undefined;

    // Get the client id from the local storage and return.
    return parseInt(localStorage.getItem(this.sessionClientIdItemName));
  }


  /**
   * Get the current session client id from the local storage.
   */
  private loginTokenGet(): string {

    // Get the loginToken from the local storage.
    let loginToken = localStorage.getItem(LOGIN_TOKEN_LOCAL_STORAGE_NAME);

    // If the loginToken is undefined or null then return undefined;
    if (!loginToken)
      return undefined;

    // Get the loginToken from the local storage and return.
    return loginToken;
  }

  /**
   * Pass the login token into the local storage.
   *
   * @param loginToken      The login token to record.
   */
  private loginTokenSet(loginToken: string) {
    // Is the loginToken null, undefined or empty string
    if (!loginToken || loginToken === "") {

      // Yes, so remove the item.
      localStorage.removeItem(LOGIN_TOKEN_LOCAL_STORAGE_NAME);

      // Then stop here.
      return;
    }

    // Set the loginToken in the local storage.
    localStorage.setItem(LOGIN_TOKEN_LOCAL_STORAGE_NAME, loginToken);
  }

  /**
   * Get the external app token from the local storage.
   */
  private externalAppTokenGet(): string {

    // Get the loginToken from the local storage.
    let externalAppToken = localStorage.getItem(EXTERNAL_APP_TOKEN_LOCAL_STORAGE_NAME);

    // If the loginToken is undefined or null then return undefined;
    if (!externalAppToken)
      return undefined;

    // Get the loginToken from the local storage and return.
    return externalAppToken;
  }

  /**
   * Pass the login token into the local storage.
   *
   * @param externalAppToken      The login token to record.
   */
  private externalAppTokenSet(externalAppToken: string) {
    // Is the loginToken null, undefined or empty string
    if (!externalAppToken || externalAppToken === "") {

      // Yes, so remove the item.
      localStorage.removeItem(EXTERNAL_APP_TOKEN_LOCAL_STORAGE_NAME);

      // Then stop here.
      return;
    }

    // Set the loginToken in the local storage.
    localStorage.setItem(EXTERNAL_APP_TOKEN_LOCAL_STORAGE_NAME, externalAppToken);
  }

  /**
   * This function will get the return to URL in the external app.
   */
  private returnToUrlGet(): string {

    // Get the return to URL from the local storage.
    let returnToUrl = localStorage.getItem(RETURN_TO_URL_LOCAL_STORAGE_NAME);

    // If the return to URL is undefined or null then return undefined;
    if (!returnToUrl)
      return undefined;

    // Get the return to URL from the local storage and return.
    return returnToUrl;
  }

  /**
   * Pass the return to URL token into the local storage.
   *
   * @param returnToUrl      The return to URL to record.
   */
  private returnToUrlSet(returnToUrl: string) {

    // Is the URL null, undefined or empty string
    if (!returnToUrl || returnToUrl === "") {

      // Yes, so remove the item.
      localStorage.removeItem(RETURN_TO_URL_LOCAL_STORAGE_NAME);

      // Then stop here.
      return;
    }

    // Set the URL in the local storage.
    localStorage.setItem(RETURN_TO_URL_LOCAL_STORAGE_NAME, returnToUrl);
  }

  /**
   * This function will get the libraryViewConfig in the external app.
   */
  private libraryViewConfigGet(): MapStorage<MapStorage<DataSetLibraryViewConfigAHubVO>> {

    // Get the libraryViewConfig from the local storage.
    const libraryViewConfigString = localStorage.getItem(LIBRARY_VIEW_CONFIG_LOCAL_STORAGE_NAME);

    // If the libraryViewConfig is undefined or null then return undefined;
    if (!libraryViewConfigString) {
      return undefined;
    }

    // Get the libraryViewConfig from the local storage and return.
    return JSON.parse(libraryViewConfigString);

  }

  /**
   * Pass the libraryViewConfig into the local storage.
   *
   * @param libraryViewConfig      libraryViewConfig to record.
   */
  private libraryViewConfigSet(libraryViewConfig: MapStorage<MapStorage<DataSetLibraryViewConfigAHubVO>>) {

    // Is the libraryViewConfig null, undefined or empty string
    if (!libraryViewConfig) {

      // Yes, so remove the item.
      localStorage.removeItem(LIBRARY_VIEW_CONFIG_LOCAL_STORAGE_NAME);

      // Then stop here.
      return;
    }

    // Set the libraryViewConfig in the local storage.
    localStorage.setItem(LIBRARY_VIEW_CONFIG_LOCAL_STORAGE_NAME, JSON.stringify(libraryViewConfig));
  }

  /**
   * This function will get the libraryViewConfig in the external app.
   */
  private aViewProductViewConfigGet(): MapStorage<MapStorage<ProductViewConfigAViewVO>> {

    // Get the libraryViewConfig from the local storage.
    const productViewConfigString = localStorage.getItem(AVIEW_PRODUCT_VIEW_CONFIG_LOCAL_STORAGE_NAME);

    // If the libraryViewConfig is undefined or null then return undefined;
    if (!productViewConfigString) {
      return undefined;
    }

    // Get the libraryViewConfig from the local storage and return.
    return JSON.parse(productViewConfigString);

  }

  /**
   * Pass the libraryViewConfig into the local storage.
   *
   * @param productViewConfig      libraryViewConfig to record.
   */
  private aViewProductViewConfigSet(productViewConfig: MapStorage<MapStorage<ProductViewConfigAViewVO>>) {

    // Is the libraryViewConfig null, undefined or empty string
    if (!productViewConfig) {

      // Yes, so remove the item.
      localStorage.removeItem(AVIEW_PRODUCT_VIEW_CONFIG_LOCAL_STORAGE_NAME);

      // Then stop here.
      return;
    }

    // Set the libraryViewConfig in the local storage.
    localStorage.setItem(AVIEW_PRODUCT_VIEW_CONFIG_LOCAL_STORAGE_NAME, JSON.stringify(productViewConfig));
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';



@Component({
  selector: 'app-file-select-dialog',
  templateUrl: './file-select-dialog.component.html',
  styleUrls: ['./file-select-dialog.component.css'],
})
export class FileSelectDialogComponent implements OnInit {

  /**
   * Get the file input component from the template.
   */
  @ViewChild("fileInput", { static: true }) fileInput;

  //********************
  // Public properties
  //*********************/

  /**
   * Does this dialog allow multiple files to be selected at once?
   */
  public allowMultipleSelect: boolean = false;

  /**
   * This is the title and content to display in this confirmation dialog.
   */
  public title: string;
  public content: string;

  /**
   * These are the labels to be used on the confirm and cancel buttons.
   */
  public confirmButtonLabel: string;
  public cancelButtonLabel: string;


  //********************
  // Protected properties
  //*********************/

  /**
   * The list of file types that we are allowing the user to choose from.
   * Defaults to null for all.
   */
  public acceptAtributeValue: string = null;


  //********************
  // Private properties
  //*********************/

  /**
   * This is the currently selected file list.
   */
  public currentFileList: File[];

  /**
   * This is the actual list of the acceptable file types. We will use this
   * to create the acceptAtributeValue property value above.
   */
  private acceptableFileTypes: string[];



  constructor(public dialogRef: MatDialogRef<FileSelectDialogComponent>) {
  }


  ngOnInit() {

    // Create a default empty list of files.
    this.currentFileList = [];
  }


  /**
   * This function will set up the acceptable file type properties.
   * 
   * @param fileTypes       The list of file types we will accept.
   */
  public setAcceptableFileTypes(fileTypes: string[]) {

    // Stop here if the list is empty.
    if (!fileTypes)
      return;

    // Record the list.
    this.acceptableFileTypes = fileTypes;

    // Now we want to create the attribute value. We do this by joining the list up with commas.
    this.acceptAtributeValue = this.acceptableFileTypes.join(",");
  }


  /**
   * This handler is called when the inputs change event is dispatched. This
   * will be because the user has choosen one or more files.
   */
  onChangeHandler() {

    // Set up the new files list.
    let newFiles: File[];

    // Use a catch to stop errors.
    try {

      // Get the files from the native element.
      newFiles = this.fileInput.nativeElement.files;

    } catch (error) {

      // Do nothing at the moment.
      newFiles = [];
    }

    // Deal with the new list of files.
    this.addFiles(newFiles);

    // Clear the currently selected values to make sure the user can select the same file twice.
    this.fileInput.nativeElement.value = null;
  }


  /**
   * This handler is called when the user clicks on the choose files button.
   */
  public chooseFilesHandler() {

    // Make a call to click on the file input component.
    this.fileInput.nativeElement.click();
  }


  /**
   * This function is called when we recieve the dropped event.
   * 
   * @param data      The data we recieved.
   */
  public onDrop(data) {

    // Set up the new files list.
    let newFiles: File[];

    // Use a catch to stop errors.
    try {

      // Pass the files into the list.
      newFiles = data.files;

    } catch (error) {

      // Do nothing at the moment.
      newFiles = [];
    }

    // Deal with the new list of files.
    this.addFiles(newFiles);
  }


  /**
   * This function will remove a specified file from the current list.
   * 
   * @param fileToRemove      The file to remove.
   */
  public fileRemove(fileToRemove: File) {

    // Get the index of the file in the current list.
    let index: number = this.currentFileList.findIndex(file => file.name === fileToRemove.name);

    // Is the index -1? If so, stop here as the file is not in the list.
    if (index === -1)
      return;

    // Now remove the file from the list.
    this.currentFileList.splice(index, 1);
  }


  /**
   * This function will take a list of files and add them to the current list.
   * 
   * @param fileList      The list of files to add.
   */
  private addFiles(fileList: File[]) {

    // Repeat through all of the files passed in.
    for (let i = 0; i < fileList.length; i++) {

      // Get each file object.
      let file = fileList[i];


      // Get the file extension.
      let fileExtension = "." + file.name.split('.').pop();

      // Is the files extension on the list of acceptable file types? If not, reject it.
      if (this.acceptableFileTypes.indexOf(fileExtension) === -1)
        continue;

      // Look for this file on the current list.
      let currentFile = this.currentFileList.find(currentFile => file.name === currentFile.name);

      // If the file exists then move on.
      if (currentFile)
        continue;

      // If we get here though we want to add the file.
      this.currentFileList.push(file);
    }
  }
}
import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

/**
 * This component displays JSON form control errors.
 */
@Component({
  selector: 'app-json-form-control-error-display',
  templateUrl: './json-form-control-error-display.component.html',
  styleUrls: ['./json-form-control-error-display.component.css']
})
export class JsonFormControlErrorDisplayComponent implements OnInit {

  /**
   * The JSON form control who's error we want to display.
   */
  @Input()
  jsonFormControl: FormControl;

  constructor() {
    // This is intentional
  }

  ngOnInit() {
  }
}

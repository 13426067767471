import { Component, OnInit, Input } from '@angular/core';
import { ExportVersionAHubVO } from 'valueObjects/ahub/accounts/export-version.ahub.vo';
import { ExportVersionStateAHubEnum } from 'valueObjects/ahub/accounts/export-version-state.ahub.enum';

@Component({
  selector: 'app-export-version-ball',
  templateUrl: './export-version-ball.component.html',
  styleUrls: ['./export-version-ball.component.css']
})
export class ExportVersionBallComponent implements OnInit {

  constructor() {
    // This is intentional
  }

  @Input()
  /**
   * Is the version currently displayed as busy
   */
  exportVersion: ExportVersionAHubVO = undefined;

  @Input()
  /**
   * Is the version currently displayed as busy
   */
  busy = false;

  @Input()
  /**
   * Is the version currently displayed as an outline only (used for styling the ball as just an outline)
   */
  outlineOnly = false;

  @Input()
  /**
   * Perhaps we only want a small ball, lets allow the 'size' x by x to be set
   */
  size = 64;

  ngOnInit() {
    // This is intentional
  }

  /**
   * Get the colour of the version circle based on the export version state
   */
  versionCircleColourGet() {

    //Get the state from the export version
    const state = this.exportVersion ? this.exportVersion.state : undefined;

    //Do a switch based on the export version state
    switch (state) {

      case ExportVersionStateAHubEnum.RELEASED:
      case ExportVersionStateAHubEnum.LIMITED_RELEASE:
        return '#81C784';
      case ExportVersionStateAHubEnum.INTERNAL:
      case ExportVersionStateAHubEnum.PREVIEW:
        return '#7986CB';
      case ExportVersionStateAHubEnum.HIDDEN:
        return '#B0B0B0';
      case ExportVersionStateAHubEnum.DELETED:
        return '#000';
      case ExportVersionStateAHubEnum.ERROR:
      case ExportVersionStateAHubEnum.DELETING:
        return '#E57373';
      default:
        return 'lightgrey';
    }
  }
}

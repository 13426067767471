
import { distinctUntilChanged, map, startWith, tap } from 'rxjs/operators';
import { Observable, combineLatest } from 'rxjs';
import { isEqual } from 'lodash';

/**
 * Store
 */
import { StoreAccess } from 'store/store-access';
import { sessionClientId, sessionUserId, entityPermissions } from 'selector/app.selector';

/**
 * Value Objects
 */
import { EntityPermissionAHubVO } from 'valueObjects/ahub/accounts/entity-permission.ahub.vo';

export class EntityPermissionsStream {

  /**
     * Create a observable watching the current client id.
     */
  currentClientId$: Observable<number> = StoreAccess.dataGetObvs(sessionClientId);

  /**
     * Create a observable watching the current client id.
     */
  currentUserId$: Observable<number> = StoreAccess.dataGetObvs(sessionUserId);

  /**
  * Used to know whether we should go get some new entity permissions
  */
  userAndClientIdAreSet(): Observable<boolean> {
    return combineLatest(
      this.currentClientId$,
      this.currentUserId$.pipe(
        startWith(-1)
      )
    ).pipe(
      map(([clientId, userId]) => {
        if (clientId && userId) {
          return true;
        }
      })
    )
  }

  entityPermissions$: Observable<EntityPermissionAHubVO[]> = StoreAccess.dataGetObvs(entityPermissions);

  entityPermissionsDistinct(): Observable<EntityPermissionAHubVO[]> {
    return this.entityPermissions$.pipe(
      distinctUntilChanged((x, y) => {
        return isEqual(x, y);
      }))
  }

}

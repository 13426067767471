import { Pipe, PipeTransform } from '@angular/core';
import { PermissionType } from 'valueObjects/ahub/accounts/entity-permission-type.ahub.enum';

@Pipe({
  name: 'permissionToNumber'
})
export class PermissionToSliderNumberValuePipe implements PipeTransform {

  transform(value: string, args?: any): any {
    return PermissionType[value];
  }

}

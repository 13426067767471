import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductClassIndexAHubVO } from 'app/valueObjects/ahub/library/product-class-index.ahub.vo';
import { PropertyAllocationObjectVO } from 'app/valueObjects/stream/product-allocation-object-stream.vo';
import { ExtractDefinitionPropertyAllocationObjectVO } from 'app/valueObjects/view/extract-definition-property-allocation.view.vo';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { componentDestroyStream, Hark } from '../../hark.decorator';
import { Utils } from '../../utils';

@Component({
  selector: 'app-product-view-class-config',
  templateUrl: './product-view-class-config.component.html',
  styleUrls: ['./product-view-class-config.component.scss']
})
@Hark()
export class ProductViewClassConfigComponent implements OnInit {

  productViewClassConfigModel$: BehaviorSubject<ProductViewClassConfigModel> = new BehaviorSubject(undefined);

  /**
 * Input which describes the product view class config.
 * From this we will build a model to be used by the view.
 * If this set method is called (e.g. the input value changes) we will assume that the view needs to be rebuilt
 */
  @Input() set productViewClassConfigModel(productViewClassConfigModel: ProductViewClassConfigModel) {
    this.productViewClassConfigModel$.next(productViewClassConfigModel);
  }

  /**
   * Heres the event emitter for when something changes, let the parent worry about what to do with it using a handler perhaps
   */
  @Output() configChangeHandler: EventEmitter<ProductViewClassConfigModel> = new EventEmitter()

  @Input() allocs$: Observable<PropertyAllocationObjectVO[]>;
  assetTypeAllocs$: Observable<PropertyAllocationObjectVO[]>;
  nonAssetTypeAllocs$: Observable<PropertyAllocationObjectVO[]>;

  @Input() selectedClass$: Observable<ProductClassIndexAHubVO>;

  filteredAssetTypeAllocs$: Observable<PropertyAllocationObjectVO[]>;
  filteredNoneAssetTypeAllocs$: Observable<PropertyAllocationObjectVO[]>;


  @Input() classIndexes: ProductClassIndexAHubVO[];
  /**
   * Handy class label map for displaying inheritedFrom values
   */
  classLabelMap: Map<number, string>;

  /**
 * Used to return the value of each 'select-with-search' option we should use for searching
 */
  allocPropertyLabelValue = (option: ExtractDefinitionPropertyAllocationObjectVO) => {
    return option.property.label;
  }

  /**
   * Used to determine which value of the option object should be applied if an option is selected
   */
  allocValue = (option: ExtractDefinitionPropertyAllocationObjectVO) => {
    return option;
  }

  /**
   * Used to determine which value of the option object should be applied if an option is selected
   */
  allocIdValue = (option: ExtractDefinitionPropertyAllocationObjectVO) => {
    return option.id;
  }

  /**
   * Used to display info properties in a mat table
   */
  dataSource$: BehaviorSubject<ProductViewClassConfigAllocationModel[]> = new BehaviorSubject([]);
  displayedColumns = ['id', 'value'];

  constructor() {
    // This is intentional
  }

  ngOnInit() {


    this.assetTypeAllocs$ = this.allocs$.pipe(
      map(allocs => allocs.filter(alloc => alloc.property.primitiveType === 'ASSET' || alloc.property.label === 'NOT SET'))
    )

    this.nonAssetTypeAllocs$ = this.allocs$.pipe(
      map(allocs => allocs.filter(alloc => alloc.property.primitiveType !== 'ASSET' || alloc.property.label === 'NOT SET'))
    )

    // Filtered properties for search (Alphabetical Order)
    this.filteredAssetTypeAllocs$ = this.assetTypeAllocs$.pipe(
      map(allocs => {
        return this.sortAllocationsAlphabetically(allocs);
      })
    )

    this.filteredNoneAssetTypeAllocs$ = this.nonAssetTypeAllocs$.pipe(
      map(allocs => {
        return this.sortAllocationsAlphabetically(allocs);
      })
    )

    this.productViewClassConfigModel$.pipe(
      Utils.isNotNullOrUndefined(),
      takeUntil(componentDestroyStream(this)),
    ).subscribe(productViewClassConfigModel => {
      this.dataSource$.next(productViewClassConfigModel.productInfoPropertyAllocs);
    })

    this.classLabelMap = new Map<number, string>(this.classIndexes.map(clazz => [clazz.id, clazz.label]));

  }

  productIdentifierChangeHandler($event: PropertyAllocationObjectVO) {
    const updatedProductViewClassConfigModel = this.productViewClassConfigModel$.getValue();

    updatedProductViewClassConfigModel.productIdentifierPropertyAlloc = {
      allocationId: $event.id
    }
    this.configChangeHandler.emit(updatedProductViewClassConfigModel);
  }

  productMainImageChangeHandler($event: PropertyAllocationObjectVO) {
    const updatedProductViewClassConfigModel = this.productViewClassConfigModel$.getValue();

    updatedProductViewClassConfigModel.productMainImagePropertyAlloc = {
      allocationId: $event.id
    }
    this.configChangeHandler.emit(updatedProductViewClassConfigModel);
  }

  productInfoPropertyChangeHandler($event: PropertyAllocationObjectVO, tableIndex) {
    const updatedProductViewClassConfigModel = this.productViewClassConfigModel$.getValue();

    const updatedInfoProperties: ProductViewClassConfigAllocationModel[] = updatedProductViewClassConfigModel.productInfoPropertyAllocs;

    updatedInfoProperties[tableIndex].allocationId = $event.id;

    updatedProductViewClassConfigModel.productInfoPropertyAllocs = updatedInfoProperties;

    this.configChangeHandler.emit(updatedProductViewClassConfigModel);

  }

  addProductInfoAlloc() {
    const updatedProductViewClassConfigModel = this.productViewClassConfigModel$.getValue();

    let updatedInfoProperties: ProductViewClassConfigAllocationModel[] = updatedProductViewClassConfigModel.productInfoPropertyAllocs;

    if (!updatedInfoProperties) {
      updatedInfoProperties = [];
    }

    updatedInfoProperties.push({
      allocationId: 0
    })

    updatedProductViewClassConfigModel.productInfoPropertyAllocs = updatedInfoProperties;

    this.productViewClassConfigModel$.next(updatedProductViewClassConfigModel);
  }

  /**
  * allows info properties to be moved 'up' and 'down' the viewing order.
  */
  moveProductInfoProperty(shift, tableIndex) {

    const updatedProductViewClassConfigModel = this.productViewClassConfigModel$.getValue();

    const updatedInfoProperties: ProductViewClassConfigAllocationModel[] = updatedProductViewClassConfigModel.productInfoPropertyAllocs;

    let newIndex: number = tableIndex + shift;
    if (newIndex === -1) {
      newIndex = updatedInfoProperties.length - 1;
    } else if (newIndex === updatedInfoProperties.length) {
      newIndex = 0;
    }

    this.moveItem(updatedInfoProperties, tableIndex, newIndex);

    updatedProductViewClassConfigModel.productInfoPropertyAllocs = updatedInfoProperties;

    this.productViewClassConfigModel$.next(updatedProductViewClassConfigModel);

    this.configChangeHandler.emit(updatedProductViewClassConfigModel);
  }

  moveItem(data, from, to) {
    // remove `from` item and store it
    const f = data.splice(from, 1)[0];
    // insert stored item into position `to`
    data.splice(to, 0, f);
  }

  removeProductInfoProperty(tableIndex: number) {

    const updatedProductViewClassConfigModel = this.productViewClassConfigModel$.getValue();

    const updatedInfoProperties: ProductViewClassConfigAllocationModel[] = updatedProductViewClassConfigModel.productInfoPropertyAllocs;

    updatedInfoProperties.splice(tableIndex, 1);

    updatedProductViewClassConfigModel.productInfoPropertyAllocs = updatedInfoProperties;

    this.configChangeHandler.emit(updatedProductViewClassConfigModel);
  }

  emptyInfoPropertyAlreadyAvailable() {
    const updatedProductViewClassConfigModel = this.productViewClassConfigModel$.getValue();

    const updatedInfoProperties: ProductViewClassConfigAllocationModel[] = updatedProductViewClassConfigModel.productInfoPropertyAllocs;

    let emptyInfoProperties;
    if (updatedInfoProperties) {
      emptyInfoProperties = updatedInfoProperties.find(infoProperty => infoProperty.allocationId === 0)
    }
    return emptyInfoProperties !== undefined
  }

  sortAllocationsAlphabetically(allocations) {

    if (!allocations) {
      return 0;
    }

    return allocations.sort((a, b) => {

      // Make sure NOT SET is at the end.
      if (a.property.label === "NOT SET") {
        return 1;
      }

      if (a.property.label > b.property.label) {
        return 1;
      } else if (a.property.label < b.property.label) {
        return -1;
      }
      return (a.section.label > b.section.label) ? 1 : -1;
    })
  }

  hasValidSelectableOptions(alloc: PropertyAllocationObjectVO[]): boolean {

    //Nothing there then bail out
    if (!alloc || alloc.length === 0) {
      return false;
    }

    //Did we find a valid allocation
    return alloc.find(alloc => alloc.id > 0) !== undefined;
  }

  getClassLabelById(classId) {
    return this.classLabelMap.get(classId);
  }

  ngOnDestroy() { }

}

export interface ProductViewClassConfigModel {
  classId: number;
  productIdentifierPropertyAlloc?: ProductViewClassConfigAllocationModel;
  productMainImagePropertyAlloc?: ProductViewClassConfigAllocationModel;
  productInfoPropertyAllocs?: ProductViewClassConfigAllocationModel[];
}

export interface ProductViewClassConfigAllocationModel {
  allocationId: number;
  inheritedFrom?: number;
}

import { Component, ComponentFactory, ComponentFactoryResolver, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ObjectStoreService } from 'app/services/object-store/object-store.service';
import { RequestActionMonitorService } from 'app/services/request-action-monitor/request-action-monitor.service';
import { AHubActions } from 'app/store/actions/ahub.actions';
import { ComponentActions } from 'app/store/actions/component.actions';
import {
  aHubStateTemporaryProductClassIndexes,
  aHubStateTemporaryProductPropertySectionIndexes
} from 'app/store/selector/ahub/ahub-temporary.selector';
import { viewPropertyIconMap } from 'app/store/selector/view/view-library-classification-class.selector';
import { StoreAccess } from 'app/store/store-access';
import { DataSetCategoryIndexAHubVO } from 'app/valueObjects/ahub/library/dataset-category-index.ahub.vo';
import { ProductAssetSectionPropertyValues } from 'app/valueObjects/ahub/library/product-asset.ahub.vo';
import { ProductClassIndexAHubVO } from 'app/valueObjects/ahub/library/product-class-index.ahub.vo';
import { PropertyAllocationObjectVO } from 'app/valueObjects/stream/product-allocation-object-stream.vo';
import { ExtractDefinitionPropertyAllocationObjectVO } from 'app/valueObjects/view/extract-definition-property-allocation.view.vo';
import { DialogService } from 'dialogs/dialog.service';

import {
  ProductAssetViewDialogImageComponent,
  ProductAssetViewDialogImageComponentParams
} from 'dialogs/product-asset-view-dialog/product-asset-view-dialog-image/product-asset-view-dialog-image.component';
import {
  ProductAssetViewDialogVideoComponent,
  ProductAssetViewDialogVideoComponentParams
} from 'dialogs/product-asset-view-dialog/product-asset-view-dialog-video/product-asset-view-dialog-video.component';
import { ProductAssetViewParamsVO } from 'modules/common/components/product-asset-view/product-asset-view.component';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilKeyChanged, filter, map, takeUntil, tap } from 'rxjs/operators';
import { AssetUtils } from '../../asset-utils';
import {
  ProductAssetViewDialogFlickbookComponent,
  ProductAssetViewDialogFlickbookComponentParams
} from '../../dialogs/product-asset-view-dialog/product-asset-view-dialog-flickbook/product-asset-view-dialog-flickbook.component';
import { componentDestroyStream, Hark } from '../../hark.decorator';
import { Utils } from '../../utils';
import { ProductViewData } from '../product-view/product-view.component';
import { ImageResizeParameters, ImageResizeDialogComponent } from '../../dialogs/image-resize-dialog/image-resize-dialog.component';
import { AppActions } from 'app/store/actions/app.actions';
import { ProductPropertySectionIndexAHubVO } from 'app/valueObjects/ahub/library/product-property-section-index.ahub.vo';
import { GridOptions, ColDef } from 'ag-grid-community';
import { ProductPropertyGridColumnReadUtil } from '../../components/ag-grid/product-grid/utils/product-property-grid-column-read-util';
import { ProductAHubVO } from 'app/valueObjects/ahub/library/product.ahub.vo';
import { MatrixGridRow, ProductViewFullUtil } from './product-view-full-util';
import { TableData } from '../property-table-renderer/property-table-renderer.component';
import { LibraryViewUtils } from '../../library-view-utils';
import { NotificationGeneratorService } from 'app/services/notification-generator/notification-generator.service';

@Component({
  selector: 'app-product-view-full',
  templateUrl: './product-view-full.component.html',
  styleUrls: ['./product-view-full.component.scss']
})
@Hark()
export class ProductViewFullComponent implements OnInit {

  @Input() productViewData: ProductViewData = null;

  @Input() selectedCategory: DataSetCategoryIndexAHubVO;

  @Input() productTitle = '';

  /**
   * Close full view
   */
  @Output()
  public closeClick: EventEmitter<void> = new EventEmitter<void>();


  // Used by the view to display product property values

  productViewPropertiesDataSourceGroupedBySection$: BehaviorSubject<PropertySectionView[]> = new BehaviorSubject([]);

  productSectionCount$: Observable<number> = this.productViewPropertiesDataSourceGroupedBySection$.pipe(
    map((productSectionViews) => {
      return productSectionViews.map(
        propertySectionView => propertySectionView.sections.length
      ).reduce((prev, current) => current + prev, 0);
    })
  );

  propertyTitle: string;

  // Used by the view to display assets
  productAssetsView$: Observable<ProductAssetViewParamsVO[]>;

  /**
   * Renderers which we will display
   */
  assetRenderers: ProductAssetViewParamsVO[] = [];

  /**
   * Framework which the grid should use
   */
  frameworkComponents = ProductPropertyGridColumnReadUtil.frameworkComponents();

  /**
 * Component facotry for the asset  preview components
 */
  private readonly imagePreviewFactory: ComponentFactory<ProductAssetViewDialogImageComponent> =
    this.resolver.resolveComponentFactory(ProductAssetViewDialogImageComponent);
  private readonly flickbookPreviewFactory: ComponentFactory<ProductAssetViewDialogFlickbookComponent> =
    this.resolver.resolveComponentFactory(ProductAssetViewDialogFlickbookComponent);
  private readonly videoPreviewFactory: ComponentFactory<ProductAssetViewDialogVideoComponent> =
    this.resolver.resolveComponentFactory(ProductAssetViewDialogVideoComponent);

  groupBySection$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  groupBySection = true;


  displayedColumns: string[] = ['propertyName', 'propertyValue'];
  displayedColumnsTable: string[] = ['propertyNameTable', 'propertyValueTable'];

  propertyIconMap = StoreAccess.dataGet(viewPropertyIconMap);

  readonly WIDTH_X_HEIGHT = '[width]x[height]'

  /**
   * Seperator we will use for the matrix options
   */
  private static readonly MATRIX_OPTION_SEPERATOR = " / ";

  constructor(
    private readonly dialogService: DialogService,
    private readonly resolver: ComponentFactoryResolver,
    private readonly requestActionMonitor: RequestActionMonitorService,
    private readonly notificationGenerator: NotificationGeneratorService,
    private readonly objectStoreService: ObjectStoreService
  ) { }

  ngOnInit() {

    // Lets make some nice datasources for our tables (properties or properties grouped by section)
    StoreAccess.dispatch(AHubActions.productPropertySectionIndexsFetch());

    // Lets get the property types (useful when displaying matrix properties)
    StoreAccess.dispatch(AHubActions.productPropertyTypesFetch());

    //Initalise th product view data
    this.initaliseProductViewData();

    //Initalise the update of the product asset renderers
    this.initaliseProductAssetRendererUpdate();

    //Lets keep the product name up to date
    this.initaliseProductLabelDisplay();
  }

  ngOnDestroy() { }

  /**
   * Initalise the stream which will keep the displayed product name up to date!
   */
  initaliseProductLabelDisplay() {

    //Initalise the stream which will keep the displayed product name up to date!
    // combineLatest([
    //   this.productViewData.product$,
    //   this.productViewData.allocationStream$,
    //   this.productViewData.libraryConfig$
    // ]).pipe(
    //   takeUntil(componentDestroyStream(this)),
    //   map(([product, allocactions, libraryConfig]) => {

    //     // Findng alloction ID and allocation Object for product title
    //     if (!product || !allocactions || !libraryConfig || !libraryConfig.libraryViewClassConfigs) {
    //       return undefined;
    //     }

    //     const productNameAllocactionID = libraryConfig.libraryViewClassConfigs[0].productIdentifierPropertyAlloc;
    //     const productNameAllocaction = allocactions.find(allocaction => productNameAllocactionID === allocaction.id);

    //     // Finding correct section for product title
    //     if (!productNameAllocaction || !product.productSectionValues) { return undefined; }
    //     const productNameSectionValue = product.productSectionValues.find(sectionValue => sectionValue.sectionId === productNameAllocaction.section.id);

    //     // Finding correct property for product title
    //     if (!productNameSectionValue || !productNameSectionValue.productSectionPropertyValues) { return undefined; }
    //     const productNamePropertyValue = productNameSectionValue.productSectionPropertyValues
    //       .find(propertyValue => propertyValue.propertyId === productNameAllocaction.property.id);

    //     // Return found value if any.
    //     if (!productNamePropertyValue) { return undefined; }
    //     return productNamePropertyValue.value;
    //   })
    // )
    //   .subscribe(title => this.propertyTitle = title);
  }

  /**
   * Initalise the setup and maintance of the product asset renderers
   */
  initaliseProductAssetRendererUpdate() {

    this.productAssetsView$ = combineLatest([
      this.productViewData.productAssets$
        .pipe(
          Utils.isNotNullOrUndefined(),
          filter(assets => assets.productSectionValueAssets && assets.productSectionValueAssets.length > 0),
          distinctUntilKeyChanged('id'),
        ),
      this.productViewData.allocationStream$
        .pipe(
          Utils.isNotNullOrUndefined(),
          filter(allocs => allocs.length > 0),
        )
    ])
      .pipe(
        takeUntil(componentDestroyStream(this)),
        map(([productAssets, allocStream]) => {

          //New list of asset renderers
          const newAssetRenderers: ProductAssetViewParamsVO[] = [];

          // Loop around the asset sections so that we can grab the bits we need and find the
          // matching allocs for displaying prettily
          productAssets.productSectionValueAssets.forEach(sectionValueAssets => {
            if (sectionValueAssets && sectionValueAssets.productSectionPropertyValues && sectionValueAssets.productSectionPropertyValues) {
              const sectionId = sectionValueAssets.productPropertySectionId;

              // Now loop the properties within the section
              sectionValueAssets.productSectionPropertyValues.forEach(sectionProperty => {

                // Ok lets grab the matching alloc by section id and property id
                const propertyAlloc = this.getAllocBySectionAndPropertyId(sectionId, sectionProperty.propertyId, allocStream);

                const assetRendererParams: ProductAssetViewParamsVO =
                  this.getAssetRendererParamsByAssetTypeReference(
                    propertyAlloc ? propertyAlloc.property.typeReference : undefined, propertyAlloc, sectionProperty);

                //Do we create a renderer? if so then add it to our list
                if (assetRendererParams) {
                  newAssetRenderers.push(assetRendererParams);
                }
              });
            }
          });

          return newAssetRenderers;
        })
      );
  }

  /**
   * Watch all the data to keep the product data view upto date
   */
  initaliseProductViewData() {

    //Create a stream which will collate all the info we need to create a product section view
    combineLatest([
      this.productViewData.allocationStream$.pipe(
        Utils.isNotNullOrUndefined(),
        filter(allocs => allocs.length > 0)
      ),
      this.productViewData.product$.pipe(
        filter(product => product !== undefined && product.productSectionValues !== undefined)
      ),
      StoreAccess.dataGetObvs(aHubStateTemporaryProductPropertySectionIndexes).pipe(
        Utils.isNotNullOrUndefined()
      ),
      this.groupBySection$
    ]).pipe(
      takeUntil(componentDestroyStream(this)),
    ).subscribe(([allocStream, product, sectionIndex, groupedBySection]) => {

      //Call the section view create calss with all out pieces of data
      this.sectionViewProductCreate(product, sectionIndex, allocStream, this.productViewData.productClassIndexes, groupedBySection);
    })
  }

  /**
   * Create the section view based on all the supplied data
   *
   * @param product             Product which the data will be based arround
   * @param sectionIndex        Sections which we want to render
   * @param allocations         Allocation indexes which we will be using for renderering
   * @param classIndexes        Class indexes which this product could belong too
   * @param groupBySection      Are we grouping by section
   */
  sectionViewProductCreate(product: ProductAHubVO, sectionIndex: ProductPropertySectionIndexAHubVO[], allocations: PropertyAllocationObjectVO[],
    classIndexes: ProductClassIndexAHubVO[], groupBySection: boolean) {

    const sectionIds = new Set<number>();

    // Get the class this product is in.
    const productClass = classIndexes.find(classIndex => classIndex.id === product.productClassId);

    // Get the ancestry for the product class.
    const productClassAncestry = LibraryViewUtils.getAncestryAsNumberArray(productClass.ancestry);

    // Add the actual product class id to the list.
    productClassAncestry.push(productClass.id);

    // Now filter the allocations down to those that are actually valid for this product.
    const productAllocations = allocations.filter(allocation => (productClassAncestry.includes(allocation.productClass.id)));

    if (product.productSectionValues) {
      product.productSectionValues.forEach(section => sectionIds.add(section.sectionId));
    }
    if (product.productMatrixSectionValues) {
      product.productMatrixSectionValues.forEach(section => sectionIds.add(section.sectionId));
    }

    let propertyViewsBySection: PropertySectionView[] = [];

    if (groupBySection) {

      // For each section we will create a property view section
      sectionIds.forEach(sectionId => {

        // Lets get the section name for this section id
        const section = sectionIndex.find(section => section.id === sectionId);

        // Lets make an empty propertySectionView container for our product data
        const propertySectionView: PropertySectionView = Utils.clone(EMPTY_PROPERTY_SECTION_VIEW);

        propertySectionView.sections = [section];
        propertySectionView.propertyViews = [];
        propertySectionView.propertyTableViews = [];

        //Return the section for this id
        propertyViewsBySection.push(propertySectionView);
      });

    } else {
      propertyViewsBySection.push({
        sections: [...sectionIndex],
        propertyViews: [],
        propertyTableViews: [],
        matrixGridOptions: undefined
      });
    }

    if (product.productSectionValues) {

      product.productSectionValues.forEach(productSection => {

        //Get the property section
        const propertySectionView = propertyViewsBySection.find(sectionView => sectionView.sections.find(section => section.id === productSection.sectionId));

        productSection.productSectionPropertyValues.forEach(sectionProperty => {

          // Lets make an empty propertyView container for our product data
          const propertyView: PropertyView = Utils.clone(EMPTY_PROPERTY_VIEW);

          // Lets get the alloc for this section/property so we can flesh out the PropertyView
          const propertyAlloc: PropertyAllocationObjectVO = this.getAllocBySectionAndPropertyId(productSection.sectionId, sectionProperty.propertyId, productAllocations);
          propertyView.propertyAlloc = propertyAlloc;

          // Now we should fill in the value
          propertyView.propertyValue = sectionProperty.value;

          // Skip any property with no value.
          if (!sectionProperty.value || sectionProperty.value.trim().length === 0) {
            return;
          }

          // Lets not show the asset 'number' in the property table
          if (propertyAlloc.property.primitiveType === "ASSET") {
            return;
          }

          //We will add all properties to the left view they will be split into left and right later
          if (propertyAlloc.property.primitiveType === "TABLE") {
            propertySectionView.propertyTableViews.push(propertyView);
          }
          else {
            propertySectionView.propertyViews.push(propertyView);
          }
        });
      });
    }

    // Do we have matrix values for this product?
    if (product.productMatrixSectionValues && product.productMatrixSectionValues.length > 0) {
      this.sectionViewMatrixGridOptionsCreate(propertyViewsBySection, product, classIndexes, productAllocations);
    }

    // Filter out any data that is empty.
    propertyViewsBySection = propertyViewsBySection
      .filter(section => section.propertyViews.length > 0 || section.matrixGridOptions || section.propertyTableViews.length > 0)
      .map(sectionView => {
        sectionView.sections = sectionView.sections
          .filter(section =>
            sectionView.propertyViews.find(propertyView => propertyView.propertyAlloc.section.id === section.id) ||
            sectionView.propertyTableViews.find(propertyView => propertyView.propertyAlloc.section.id === section.id) ||
            sectionView.matrixGridOptions.allocations.find(allocation => allocation.section.id === section.id));
        return sectionView;
      });


    //Now this has been done we need to first sort the whole list and split them into a left and right side
    propertyViewsBySection.forEach(pvbs => {

      //Get the section id's in the order which we need them
      const sectionIdsOrdered = pvbs.sections.map(section => section.id);

      //Sort the list of properties using our allocation sort function
      pvbs.propertyViews.sort((pv1, pv2) => ProductViewFullUtil.allocationSortFunction(pv1.propertyAlloc, pv2.propertyAlloc, sectionIdsOrdered));

      const rightListStartIndex = Math.round((pvbs.propertyViews.length / 2));

      for (let index = rightListStartIndex; index < pvbs.propertyViews.length; index++) {
        pvbs.propertyViews[index].columnNumber = 2;
      }
    });

    //Now we have created the views we will post them to the stream
    this.productViewPropertiesDataSourceGroupedBySection$.next(propertyViewsBySection);
  }

  /**
   * Create the grid options for the matrix view
   *
   * @param sectionViews      Sections views which we need to create the sections for
   * @param product           Product that the data is related too
   * @param classIndexes      Classes which this product relates too
   * @param allocations       Allocations for the data
   */
  sectionViewMatrixGridOptionsCreate(sectionViews: PropertySectionView[], product: ProductAHubVO,
    classIndexes: ProductClassIndexAHubVO[], allocations: PropertyAllocationObjectVO[]) {

    //Get the matrix for the associated class
    const matrixDefinition = ProductViewFullUtil.productMatrixDefinitionForClassId(product.productClassId, classIndexes);

    //If we don't have one we need to bail our right now!
    if (!matrixDefinition || !matrixDefinition.dimensions) {
      return;
    }

    //Create the matrix sort order for the matrix associated with the product
    const matrixOptionSortOrder: string[] = ProductViewFullUtil.productMatrixOptionSorted(matrixDefinition, ProductViewFullComponent.MATRIX_OPTION_SEPERATOR);

    //Define a simple value getter for the product row we will re-use this many times later
    const valueGetter = (row: MatrixGridRow, sectionId: number, propertyId: number) => {

      if (!row || !row.values) {
        return undefined;
      }

      //Find the value for the appropriate property senction and then return it if we have it!
      const valueForSectionProperty = row.values.find(v => v.sectionId === sectionId && v.propertyId === propertyId);
      return valueForSectionProperty ? valueForSectionProperty.value : undefined;
    };

    //Just get the matrix allocations
    const matrixAllocs = allocations.filter(alloc => alloc.property.matrix);

    sectionViews.forEach(sectionView => {

      // Create a new list of the allocations in this section view.
      const sectionViewAllocations = [];

      const dataRows: MatrixGridRow[] = ProductViewFullUtil.sectionViewMatrixGridOptionRowsCreate(product, sectionView);

      //Get the section id's in the order which we need them
      const sectionIdsOrdered = sectionView.sections.map(section => section.id);

      //Is there no data for the product grid?
      if (dataRows.length <= 0) {
        return;
      }

      //Create an array from our unique set of our allocations and then sort them by the label
      const columnAllocations = matrixAllocs.filter(a => sectionIdsOrdered.includes(a.section.id));
      columnAllocations.sort((a, b) => ProductViewFullUtil.allocationSortFunction(a, b, sectionIdsOrdered));


      // Are we putting the section and property together?
      const addSectionNameToColumn = sectionView.sections.length > 1;

      // Set up the options (first) column.
      const optionsColumn: ColDef = {
        headerName: "Options",
        field: "id",
        valueFormatter: (value) => value.value ? (value.value as string[]).join(ProductViewFullComponent.MATRIX_OPTION_SEPERATOR) : value.value
      }

      // Set up the columns with the first options column in it.
      const columns: ColDef[] = [optionsColumn];

      //For each of our allocations we will create a column for that allocation
      columnAllocations.forEach(alloc => {

        // Set up the standard column definition.
        const colDef = ProductPropertyGridColumnReadUtil.columnReadFilterForProperty({
          property: alloc.property
        });

        //Set a value getter for the column
        colDef.valueGetter = (params) => valueGetter(params.data, alloc.section.id, alloc.property.id);

        // Add the allocation to the section views list of allocations.
        sectionViewAllocations.push(alloc);

        // Are we showing section and property together?
        if (addSectionNameToColumn) {

          // Yes, so set up the column definition to handle this change.
          colDef.headerName = `${alloc.section.label} \n${alloc.property.label}`;
          colDef.headerComponentParams = ProductPropertyGridColumnReadUtil.headerComponentParamsCreate(alloc.section, alloc.property);
          colDef.valueGetter = (params) => valueGetter(params.data, alloc.section.id, alloc.property.id);
          colDef.autoHeight = false;
        }

        // Add the column to the list.
        columns.push(colDef);
      });

      //Sort the matrix options into the same order they appear in in the matrix grid.
      //We are doing this be working out where they appear index wise in our source list
      dataRows.sort((a, b) => {

        const aIndex = matrixOptionSortOrder.indexOf(a.id.join(ProductViewFullComponent.MATRIX_OPTION_SEPERATOR));
        const bIndex = matrixOptionSortOrder.indexOf(b.id.join(ProductViewFullComponent.MATRIX_OPTION_SEPERATOR));

        return aIndex - bIndex;
      });

      sectionView.matrixGridOptions = {
        allocations: sectionViewAllocations,
        gridOptions: {
          columnDefs: columns,
          rowData: dataRows,
          headerHeight: (addSectionNameToColumn) ? 48 : 34,
        }
      };
    });
  }

  /**
   * Function to get the property views for a given column
   *
   * @param propertyViews
   * @param columnNumber
   */
  propertyViewsForColumn(propertyViews: PropertyView[], columnNumber: number) {

    //Null check
    if (!propertyViews) {
      return undefined;
    }

    //Find the property views for this column
    return propertyViews.filter(propertyView => propertyView.columnNumber === columnNumber);
  }

  /**
   * String to JSON
   */
  stringToJson(data) {
    return JSON.parse(data);
  }

  getAllocBySectionAndPropertyId(sectionId: number, propertyId: number, allocStream: PropertyAllocationObjectVO[]): PropertyAllocationObjectVO {
    return allocStream.find(alloc => alloc.property.id === propertyId && alloc.section.id === sectionId);
  }

  groupBySectionOrPropertyChange($event) {
    this.groupBySection = $event.checked;
    this.groupBySection$.next($event.checked);
  }

  getAssetRendererParamsByAssetTypeReference(
    typeReference: string,
    alloc: PropertyAllocationObjectVO,
    sectionProperty: ProductAssetSectionPropertyValues): ProductAssetViewParamsVO {

    switch (typeReference) {
      case "IMAGE":
        return this.imageAssetSetup(alloc, sectionProperty);
      case "VIDEO":
        return this.videoAssetSetup(alloc, sectionProperty);
      case "FLICKBOOK":
        return this.flickbookAssetSetup(alloc, sectionProperty);
      case "PDF":
        return this.pdfAssetSetup(alloc, sectionProperty);
      case "BLOB":
        return this.blobAssetSetup(alloc, sectionProperty);
      default:
        return this.imageAssetSetup(alloc, sectionProperty);
    }
  }
  /**
    * Setup the image asset renderer
    */
  imageAssetSetup(
    allocation: PropertyAllocationObjectVO,
    assetData: ProductAssetSectionPropertyValues): ProductAssetViewParamsVO {

    //Get the asset images from the asset data
    const assetImages = (assetData && assetData.previewUrl) ? [assetData.previewUrl.replace(this.WIDTH_X_HEIGHT, '512x512')] : [];

    // We want to show a large version of the image asset, but not necessarily the full version as could be massive.
    // So get the asset info.
    const assetInfoMap = AssetUtils.buildAssetInfoMapFromAssetData(assetData);

    // Get the assets full width and height.
    const assetWidth = assetInfoMap.get('sourceWidthPx');
    const assetHeight = assetInfoMap.get('sourceHeightPx');

    // Get the first asset URL to use. This is the first path in the list by default.
    let imageUrl = assetData ? assetData.assetUrls[0] : undefined;

    // Is the image URL empty, or is the asset too big?
    if (!imageUrl || +assetWidth > 2048 || +assetHeight > 2048) {

      // If the asset is to big then we need to display a smaller one.
      imageUrl = (assetData && assetData.previewUrl) ? assetData.previewUrl.replace(this.WIDTH_X_HEIGHT, '2048x2048') : undefined;
    }

    //Setup config for the larger view of the asset
    const dialogParameters: ProductAssetViewDialogImageComponentParams = {
      sectionName: allocation.section.label,
      sectionColour: allocation.section.colour,
      propertyName: allocation.property.label,
      imageUrl
    };

    //Handel the full screen asset click
    const clickHandler = (params) => {

      //No data then we won't open the dialogue
      if (!assetData) return;

      //Open the dialogue
      this.dialogService.componentDialogOpen('', this.imagePreviewFactory, 'dialogParams', dialogParameters, null, "Close");
    };

    /**
     *
     * @param params
     */
    const downloadResizedImageAssetClickHandlerFunc = (params: ProductAssetViewParamsVO) => {

      if (!assetData || !params) {
        return;
      }

      const assetInfoMap: Map<string, string> = AssetUtils.buildAssetInfoMapFromAssetData(assetData);

      //Setup config for the image resize dialog
      const resizeImageDialogParameters: ImageResizeParameters = {
        maxWidth: +assetInfoMap.get('sourceWidthPx'),
        maxHeight: +assetInfoMap.get('sourceHeightPx'),
        resizedWidth: undefined,
        resizedHeight: undefined,
        resizeDefaults: "LARGE",
        imageType: undefined
      };

      const imageResizeDialogFactory: ComponentFactory<ImageResizeDialogComponent> =
        this.resolver.resolveComponentFactory(ImageResizeDialogComponent);

      const dialogTitle = `<span>${params.sectionName}: ${params.propertyName}`

      const imageResizeDialogResult$ = this.dialogService.componentDialogOpen(dialogTitle, imageResizeDialogFactory,
        'dialogVO', resizeImageDialogParameters, "dialogIsValid$", 'Download', 'Cancel');

      // Subscribe to the new dialogue, if we recive an product class rule then we will add it
      imageResizeDialogResult$.subscribe((resizeImageDialogParameters) => {

        // If we dont have resized values, lets not continue any further
        if (!resizeImageDialogParameters || !resizeImageDialogParameters.resizedHeight || !resizeImageDialogParameters.resizedWidth) {
          return;
        }

        let downloadUrl = assetData.previewUrl.replace(this.WIDTH_X_HEIGHT, `${resizeImageDialogParameters.resizedHeight}x${resizeImageDialogParameters.resizedWidth}`);

        // Type of file selection
        if (resizeImageDialogParameters.imageType !== "") {
          if (resizeImageDialogParameters.imageType === "PNG") {
            downloadUrl = downloadUrl.replace('.jpg', '.png')
          }
          if (resizeImageDialogParameters.imageType === "JPG") {
            downloadUrl = downloadUrl.replace('.png', '.jpg');
          }
        }

        StoreAccess.dispatch(AppActions.fileDownloadConfigSet([{ url: downloadUrl }]));

      });
    };

    const extractAlloc: ExtractDefinitionPropertyAllocationObjectVO = this.extractAllocCreate(allocation);

    const editable$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    //Setup a renderer for the single asset
    return this.assetRendererCreate(extractAlloc, assetData, 'Image Asset', assetImages, clickHandler, editable$, downloadResizedImageAssetClickHandlerFunc);
  }

  /**
  * Setup the video asset
  */
  videoAssetSetup(allocation: PropertyAllocationObjectVO, assetData: ProductAssetSectionPropertyValues): ProductAssetViewParamsVO {

    //Get the asset images from the asset data
    let assetImages: string[] = [];

    if (assetData && assetData.assetUrls) {
      // We would like to be able to show preview stills of various frames throughout the video, so lets see if we can get those stills now
      const frameURLs = (assetData && assetData.previewUrl) ? assetData.assetUrls.filter(assetUrl => assetUrl.includes('stills')) : [];
      //If we have found some frames then we will use them
      if (frameURLs.length > 0) {
        assetImages = frameURLs;
      } else { // Otherwise lets just use a static preview image
        assetImages = (assetData && assetData.previewUrl) ? [assetData.previewUrl.replace(this.WIDTH_X_HEIGHT, '256x256')] : [];
      }

    }

    //Setup config for the larger view of the asset
    const dialogParameters: ProductAssetViewDialogVideoComponentParams = {
      sectionName: allocation.section.label,
      sectionColour: allocation.section.colour,
      propertyName: allocation.property.label,
      videoURL: assetData ? assetData.assetUrls.find(url => url.indexOf("hd/video.mp4") > -1) : undefined
    };

    //Handler for viewing the full screen asset
    const clickHandler = (params) => {

      //No data then we won't open the dialogue
      if (!assetData) return;

      //Open the dialogue
      this.dialogService.componentDialogOpen('', this.videoPreviewFactory, 'dialogParams', dialogParameters, null, "Close");
    };

    const extractAlloc: ExtractDefinitionPropertyAllocationObjectVO = this.extractAllocCreate(allocation);

    const editable$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    //Setup a renderer for the single asset
    return this.assetRendererCreate(extractAlloc, assetData, 'Video Asset', assetImages, clickHandler, editable$);
  }


  /**
   * Setup the PDF asset
   */
  pdfAssetSetup(allocation: PropertyAllocationObjectVO, assetData: ProductAssetSectionPropertyValues): ProductAssetViewParamsVO {

    //Get the asset images from the asset data
    const assetImages = (assetData && assetData.previewUrl) ? [assetData.previewUrl.replace(this.WIDTH_X_HEIGHT, '512x512')] : [];

    const clickHandler = (params) => {

      const pdfAssetURL: string = assetData ? assetData.assetUrls.find(url => url.includes(".pdf")) : undefined;

      //No asset then we won't open it!
      if (!pdfAssetURL) return;

      //Open a new window for the PDF
      const win = window.open(pdfAssetURL, '_blank');

      //Focus on the new window
      win.focus();
    };

    //Setup a renderer for the single asset
    const extractAlloc: ExtractDefinitionPropertyAllocationObjectVO = this.extractAllocCreate(allocation);

    const editable$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    //Setup a renderer for the single asset
    return this.assetRendererCreate(extractAlloc, assetData, 'PDF Asset', assetImages, clickHandler, editable$);
  }

  /**
   * Setup the blob asset renderer
   */
  blobAssetSetup(allocation: PropertyAllocationObjectVO, assetData: ProductAssetSectionPropertyValues): ProductAssetViewParamsVO {

    //Get the asset images from the asset data
    const assetImages = (assetData && assetData.previewUrl) ? [assetData.previewUrl.replace(this.WIDTH_X_HEIGHT, '512x512')] : [];

    //Setup a renderer for the single asset
    const extractAlloc: ExtractDefinitionPropertyAllocationObjectVO = this.extractAllocCreate(allocation);

    const editable$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    //Setup a renderer for the single asset
    return this.assetRendererCreate(extractAlloc, assetData, 'Blob Asset', assetImages, undefined, editable$);
  }


  /**
   * Setup the flickbook asset renderer
   */
  flickbookAssetSetup(allocation: PropertyAllocationObjectVO, assetData: ProductAssetSectionPropertyValues): ProductAssetViewParamsVO {

    //Get the asset images from the asset data
    let assetImages = (assetData && assetData.assetUrls) ? assetData.assetUrls.filter(url => url.includes('standard')) : [];

    //Setup config for the larger view of the asset
    const dialogParameters: ProductAssetViewDialogFlickbookComponentParams = {
      sectionName: allocation.section.label,
      sectionColour: allocation.section.colour,
      propertyName: allocation.property.label,
      rotationIntervalMili: 200,
      chapters: AssetUtils.buildFlickBookChapters(assetData)
    };

    // const frameURLs = AssetUtils.buildFlickBookChapters(assetData, dialogParameters)

    //If we have found some frames then we will use them
    // if (frameURLs.length > 0) {
    //   assetImages = frameURLs;
    // }

    //Handler for viewing the full screen asset
    const clickHandler = (params) => {

      //No data then we won't open the dialogue
      if (!assetData) return;

      //Open the dialogue
      this.dialogService.componentDialogOpen('', this.flickbookPreviewFactory, 'dialogParams', dialogParameters, null, "Close");
    };

    //Setup the asset renderer
    const extractAlloc: ExtractDefinitionPropertyAllocationObjectVO = this.extractAllocCreate(allocation);

    const editable$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    //Setup a renderer for the single asset
    return this.assetRendererCreate(extractAlloc, assetData, 'Flickbook Asset', assetImages, clickHandler, editable$);
  }

  closeProductViewFull() {
    StoreAccess.dispatch(ComponentActions.componentDataSetProductsDataSetCategorySelectProductIdSet(this.productViewData.componentId, undefined))
  }

  /**
   * Create a new extract allocation form an allocation.
   *
   * @param allocation      The allocation to create from.
   */
  extractAllocCreate(allocation): ExtractDefinitionPropertyAllocationObjectVO {

    //Setup the asset renderer
    return {
      priority: undefined,
      extractDefinitionProductClassId: undefined,
      id: allocation.id,
      productClass: allocation.productClass,
      property: allocation.property,
      readOnly: undefined,
      section: allocation.section
    }
  }

  /**
   * Create an instance of an asset renderer.
   *
   * @param extractAlloc      The extract allocation it's in.
   * @param assetData         The assets data.
   * @param assetType         The type of asset.
   * @param assetImages       The images in the asset.
   * @param clickHandler      The click handler to be added.
   * @param editable$         Is it editable?
   * @param downloadResizedImageAssetClickHandlerFunc         function to allow opening a dialog for downloading an image of a custom size
   */
  assetRendererCreate(extractAlloc, assetData, assetType, assetImages, clickHandler, editable$, downloadResizedImageAssetClickHandlerFunc?) {
    return AssetUtils.assetRendererSetup(
      undefined,
      this.selectedCategory.dataSetId,
      undefined,
      extractAlloc,
      assetData,
      assetType,
      assetImages,
      undefined,
      undefined,
      undefined,
      clickHandler,
      editable$,
      this.requestActionMonitor,
      this.notificationGenerator,
      this.objectStoreService,
      undefined,
      undefined,
      false,
      downloadResizedImageAssetClickHandlerFunc
    );
  }

  formatTableData(originalData): TableData {

    const data = JSON.parse(originalData);

    return {
      headerRow: data.headerRow,
      rows: data.rows
    } as TableData;
  }

  /**
   * Test to check if the table has any data
   *
   * @param table   Table which we want to test for data
   */
  tableHasData(table: TableData): boolean {

    //Do we have any table data at all?
    if (!table || !(table.headerRow || table.rows)) {
      return false;
    }

    //Return if the header has any data
    return table.headerRow.find(hdata => hdata && hdata.trim().length > 0) !== undefined ||
      table.rows.find(row => row && row.find(rdata => rdata && rdata.trim().length > 0)) !== undefined;
  }

  /**
   * Function to handle the click on the config box
   */
  closeProductView() {
    this.closeClick.emit();
  }
}

export interface ProductView {
  productId: number,
  productClass: string,
  lastModified: Date,
  propertyViewsBySection: PropertySectionView[]
}

export const EMPTY_PRODUCT_VIEW: ProductView = {
  productId: undefined,
  productClass: undefined,
  lastModified: undefined,
  propertyViewsBySection: undefined,
}

export interface PropertySectionView {
  sections: ProductPropertySectionIndexAHubVO[],
  propertyViews: PropertyView[],
  propertyTableViews: PropertyView[],
  matrixGridOptions: MatrixGridOptions
}

export const EMPTY_PROPERTY_SECTION_VIEW: PropertySectionView = {
  sections: undefined,
  propertyViews: undefined,
  propertyTableViews: undefined,
  matrixGridOptions: undefined
}

export interface PropertyView {
  propertyAlloc: PropertyAllocationObjectVO,
  propertyValue: string,
  isMatrix: boolean,
  columnNumber?: number
}

export const EMPTY_PROPERTY_VIEW: PropertyView = {
  propertyAlloc: undefined,
  propertyValue: undefined,
  isMatrix: undefined,
  columnNumber: 1
}

export interface MatrixGridOptions {
  gridOptions: GridOptions,
  allocations: PropertyAllocationObjectVO[]
}

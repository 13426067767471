
import { takeUntil } from 'rxjs/operators';

import { Observable, Subject, Subscription } from 'rxjs';

import { StoreAccess } from 'store/store-access';
import { ViewActions } from 'actions/view.actions';


/**
 * General Hark annotation which will allow us to add extra implementation to a component
 */
export function Hark() {

  /**
   * Return the modification which we are making to the component
   */
  return (target) => {

    /**
     * Generate a componenet id based on the component id and add a little random to the end
     */
    target.prototype.componentId = undefined;

    /**
     * Get an instance of the original onInit function from the component.
     * By keeping a copy means that we will be able to execute both our custom code and
     * that implemented by the function.
     */
    target.prototype.originalOnInit = target.prototype.ngOnInit;

    /**
     * Get an instance of the original onDestory function from the component.
     * By keeping a copy means that we will be able to execute both our custom code and
     * that implemented by the function.
     */
    target.prototype.originalOnDestroy = target.prototype.ngOnDestroy;

    /**
     * Subject that will only be published too when the associated component is destroyed
     * this will allow users to react as a result of an on destroy.
     */
    let onDestoryComponentSubject: Subject<void> = undefined;

    /**
     * Subscription for the tracked form we are watching
     */
    target.prototype.trackedComponentSubscription = undefined;

    /**
     * Function to get the component destory stream. This function will be overlayed onto
     * the component. This can either be called on the any type or using the strongly typed getter
     * within this file.
     */
    target.prototype.componentDestroyStreamGet = function () {

      //We will create a new subject only when there is a no subject
      if (!this.onDestoryComponentSubject) {
        this.onDestoryComponentSubject = new Subject<void>();
      }

      //Return the subject as an observable
      return this.onDestoryComponentSubject.asObservable();
    };


    /**
     * Override the ngOnInit with our own function which will call the original onInit
    */
    target.prototype.ngOnInit = function () {

      //Generate the component id
      this.componentId = this.constructor.name + "-" + Math.random().toString(36).substr(2, 5);

      //We we have the original onInit function ... if there was one then we will call it.
      if (this.originalOnInit) {
        this.originalOnInit();
      }
    };


    /**
     * Override the ngOnDestory with our own function which will call the original onDestroy
     */
    target.prototype.ngOnDestroy = function () {

      //We we have the original onDestory function ... if there was one then we will call it.
      if (this.originalOnDestroy)
        this.originalOnDestroy();

      //Do we have a tracked componenet subscription ... if so this means that we were tracking
      //this component but its now being destroyed. So we will remove the reference for it.
      if (this.trackedComponentSubscription) {

        //Dispatch an action to remove the
        StoreAccess.dispatch(ViewActions.componentStateRemoveById(this.componentId));
      }

      //Do we have an onDestory subject. If so this suggests that something with our component
      //is intrested in the components destory handler.
      if (this.onDestoryComponentSubject) {

        //We will call a next on the subject to trigger an action in the stream
        this.onDestoryComponentSubject.next();

        //We will then continue to unsubscribe from the subject
        this.onDestoryComponentSubject.unsubscribe();
      }
    };
  };
}

/**
 * Get an observable stream from the component which will only be triggered once our
 * component is being destoryd. The component must be annotated with this @HARK decorator.
 *
 * The component supplied to the function should be the one annotated. Often will be 'this'
 */
export function componentDestroyStream(component): Observable<void> {

  //Component stream function appears to be missing throw a new error
  if (component.componentDestroyStreamGet === undefined) {
    throw new Error('Component missing function. missing @Hark decorator?');
  }

  //Return the strongly typed component destory stream
  return component.componentDestroyStreamGet();
}

export function componentTrack(component, prestineData$: Observable<boolean>, startPrestine?: Boolean): void {

  //Get the component destory stream
  let destroyStream: Observable<void> = component.componentDestroyStreamGet();

  //Do we have an original tacked stream ... then we must un-sub subscription
  if (component.trackedComponentSubscription) {
    let subscription: Subscription = <Subscription>component.trackedComponentSubscription;
    subscription.unsubscribe();
  }

  //Dispatch an initial value to the store
  StoreAccess.dispatch(ViewActions.componentStateSet({
    componentId: component.componentId,
    pristine: (startPrestine) ? startPrestine.valueOf() : true
  }));

  //Subscribe to the prestine data steam ... store the subscription
  component.trackedComponentSubscription = prestineData$.pipe(
    takeUntil(destroyStream))
    .subscribe(clean => {
      StoreAccess.dispatch(ViewActions.componentStateSet({
        componentId: component.componentId,
        pristine: clean
      }));
    });
}

/**
 * Function to get the id for the given component
 *
 * @param component
 */
export function componentIdGet(component): string {
  return component.componentId;
}

export class ClientLogEntryTypeAHubEnum {

    /**
     * Something maybe useful for a developer but nobody else.
     */
    static readonly DEBUG: string = "DEBUG";

    /**
     * Info useful to the caller.
     */
    static readonly INFO: string = "INFO";

    /**
     * Warning but non breaking issues.
     */
    static readonly WARN: string = "WARN";

    /**
     * Errors which will prevent progress.
     */
    static readonly ERROR: string = "ERROR";

    /**
     * All of the log level typ.es
     */
    static readonly ALL_TYPES: ClientLogEntryTypeAHubEnum[] = [
        ClientLogEntryTypeAHubEnum.DEBUG,
        ClientLogEntryTypeAHubEnum.INFO,
        ClientLogEntryTypeAHubEnum.WARN,
        ClientLogEntryTypeAHubEnum.ERROR
    ]

    /**
     * Conver the type to friendly name
     *
     * @param type
     */
    public static typeToLabel(type: string) {

        //Switch on type to get human readable log names
        switch (type) {
            case ClientLogEntryTypeAHubEnum.DEBUG:
                return "Debug";
            case ClientLogEntryTypeAHubEnum.INFO:
                return "Info";
            case ClientLogEntryTypeAHubEnum.WARN:
                return "Warning";
            case ClientLogEntryTypeAHubEnum.ERROR:
                return "Error";

            default:
                return undefined;
        }
    }
}

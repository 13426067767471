import { Component } from '@angular/core';
import { IHeaderGroupAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-grid-group-header-menu',
  templateUrl: './grid-group-header-menu.component.html',
  styleUrls: ['./grid-group-header-menu.component.css']
})
export class GridGroupHeaderMenuComponent implements IHeaderGroupAngularComp {

  /**
   * Parameters for the renderer
   */
  params: any;
  menuItems: GridMenuItem[];

  agInit(params): void {

    //Set the current set of parameters
    this.params = params;

    this.menuItems = params.menuItems;
  }

}


/**
 * Custom parameters for this componenet
 */
export interface GridGroupHeaderMenuParameters {
  menuItems: GridMenuItem[];
}

export interface GridMenuItem {
  label: string;
  icon?: string;
  disabled?: boolean;
  menuItemClickHandler: () => void
}

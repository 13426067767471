import { Component, Input, OnInit } from '@angular/core';
import { exportTypeIconPathGet } from 'app/valueObjects/ahub/accounts/export-type-code.ahub.enum';

@Component({
  selector: 'app-export-distribution-index-item',
  templateUrl: './export-distribution-index-item.component.html',
  styleUrls: ['./export-distribution-index-item.component.css']
})
export class ExportDistributionIndexItemComponent implements OnInit {

  /**
   * The export type to display.
   */
  @Input() public exportDistributionVO: any = null;

  /**
   * This is the path to the export type icon we want to display.
   */
  exportTypeIconPath = "";

  constructor() {
    // This is intentional
  }

  ngOnInit() {
    // Update the export type icon path.
    this.exportTypeIconPath = exportTypeIconPathGet(this.exportDistributionVO.exportTypeCode);
  }

}

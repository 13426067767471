export class DocumentProductFileTypeAHubEnum {
    static readonly JSON: string = "JSON";
    static readonly XLSX: string = "XLSX";
    static readonly XLSX_SKU: string = "XLSX_SKU";

    static readonly ALL_TYPES: string[] = [
        DocumentProductFileTypeAHubEnum.XLSX,
        DocumentProductFileTypeAHubEnum.XLSX_SKU,
        DocumentProductFileTypeAHubEnum.JSON
    ]

    static readonly ALL_EXTRACT_DOCUMENT_SETTING_TYPES: string[] = [
        DocumentProductFileTypeAHubEnum.XLSX,
        DocumentProductFileTypeAHubEnum.XLSX_SKU
    ];

    /**
     * This function will convert a file type into a user friendly string.
     *
     * @param type          The type to convert.
     */
    static typeToLabel(type: DocumentProductFileTypeAHubEnum): string {

        // Set up the label to return.
        let label: string = "";

        // What type do we have?
        switch (type) {

            case DocumentProductFileTypeAHubEnum.JSON:
                label = "JSON";
                break;

            case DocumentProductFileTypeAHubEnum.XLSX:
                label = "Excel";
                break;

            case DocumentProductFileTypeAHubEnum.XLSX_SKU:
                label = "Excel SKU Level";
                break;
        }

        // Return the label we have found.
        return label;
    }


    /**
     * This function will convert a file extension into a user friendly string.
     *
     * @param type          The type to convert.
     */
    static typeToFileExtension(type: DocumentProductFileTypeAHubEnum): string {

        // Set up the file extension to return.
        let fileExtension: string = "";

        // What type have we had passed in?
        switch (type) {

            case DocumentProductFileTypeAHubEnum.JSON:
                fileExtension = "json";
                break;

            case DocumentProductFileTypeAHubEnum.XLSX:
            case DocumentProductFileTypeAHubEnum.XLSX_SKU:
                fileExtension = "xlsx";
                break;
        }

        // Return the file extension we have found.
        return fileExtension;
    }
}

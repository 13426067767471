
import { map, takeUntil, filter, startWith, combineLatest } from 'rxjs/operators';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Hark, componentDestroyStream } from 'modules/common/hark.decorator';

/**
 * Store access.
 */
import { StoreAccess } from 'store/store-access';

/**
 * Actions.
 */
import { AHubActions } from 'actions/ahub.actions';

/**
 * Value objects.
 */
import { UserIndexAHubVO } from 'valueObjects/ahub/accounts/user-index.ahub.vo';
import { UserAHubVO } from 'valueObjects/ahub/accounts/user.ahub.vo';
import { List } from 'store/list.vo';

/**
 * Selectors.
 */
import { aHubStateTemporaryUserIndexList } from 'selector/ahub/ahub-temporary.selector';
import { sessionUserId } from 'selector/app.selector';

/**
 * Utilites.
 */
import { ListUtil } from 'store/list.util';
import { Utils } from '../../utils';


@Component({
  selector: 'app-user-spot',
  templateUrl: './user-spot.component.html',
  styleUrls: ['./user-spot.component.scss']
})
@Hark()
export class UserSpotComponent implements OnInit, OnDestroy {

  /**
   * This is the user id we are working with.
   */
  @Input()
  public userId$: Observable<number>;

  /**
   * This is the user id we are working with.
   */
  @Input()
  public userId: number;

  /**
   * This is the size of the component
   */
  @Input()
  public size = 28;

  /**
   * This is the size of the component
   */


  /**
   * This is the user we are working with. We can use this instead of
   * the user id.
   */
  @Input()
  public user$: Observable<UserAHubVO>;

  /**
   * Optional tooltip passed in
   */
  @Input()
  public tooltip: string = undefined;

  /**
   * This is the list of user indexes.
   */
  private readonly userIndexes$: Observable<List<UserIndexAHubVO>> = StoreAccess.dataGetObvs(aHubStateTemporaryUserIndexList);

  /**
   * This is the current user.
   */
  private userIndex$: Observable<UserIndexAHubVO>;

  /**
   * Are we displaying the current user id?
   */
  isSessionUser$: Observable<boolean> = of(true);

  /**
   * This is the users name.
   */
  userName$: Observable<string>;

  /**
   * Get the users initials.
   */
  userInitials$: Observable<string>;

  constructor() {
    // This is intentional
  }


  ngOnInit() {

    // Do we have a user object passed in?
    if (this.user$) {

      // Set up the is session user obsvervable.
      this.isSessionUser$ = this.user$.pipe(
        filter(user => user != undefined),
        takeUntil(componentDestroyStream(this)),
        map(user => StoreAccess.dataGet(sessionUserId) == user.id));

      // Get the user initials when they change.
      this.userName$ = this.user$.pipe(
        map(user => {

          // Get the first character of the users first and surname and add them to create the initials.
          return (user) ? `${user.firstName} ${user.lastName}` : "";
        }));

      // Get the user initials when they change.
      this.userInitials$ = this.user$.pipe(
        map(user => {

          // Get the first character of the users first and surname and add them to create the initials.
          return (user) ? user.firstName.charAt(0) + user.lastName.charAt(0) : null;
        }));

      // Then stop here.
      return;
    }


    // Lets have a non observable user spot too!
    if (this.userId) {
      this.userId$ = of(this.userId);
    }

    // Subscribe to the user id so we can make a request to get the user index.
    this.userId$.pipe(
      Utils.isNotNullOrUndefined(),
      takeUntil(componentDestroyStream(this)),
      filter(userId => userId > 0))
      .subscribe(userId => {
        StoreAccess.dispatch(AHubActions.userIndexesByIdFetch([userId]));
      });



    // Set up the is session user obsvervable.
    this.isSessionUser$ = this.userId$.pipe(
      takeUntil(componentDestroyStream(this)),
      map(userId => StoreAccess.dataGet(sessionUserId) === userId));

    // Listen to the user index list and combine them with the user id observable so we can get the user.
    this.userIndex$ = this.userIndexes$.pipe(
      combineLatest(this.userId$.pipe(startWith(-1)), (indexList, userId) => ({ indexList: indexList, userId: userId })),
      takeUntil(componentDestroyStream(this)),
      map(data => {

        // Get the user id.
        const userId = data.userId;

        // Get the user index list.
        const userIndexList = data.indexList;

        // Stop here if we don't have the right data.
        if (!userIndexList || userId === -1) {
          return undefined;
        }

        // Return the user based on the list and the user id.
        return ListUtil.listDataItemGet(userIndexList, userId);
      }))


    // Get the user initials when they change.
    this.userName$ = this.userIndex$.pipe(
      map(userIndex => {

        // Get the first character of the users first and surname and add them to create the initials.
        return (userIndex) ? userIndex.firstName + " " + userIndex.lastName : "";
      }));

    // Get the user initials when they change.
    this.userInitials$ = this.userIndex$.pipe(
      map(userIndex => {

        // Get the first character of the users first and surname and add them to create the initials.
        return (userIndex) ? userIndex.firstName.charAt(0) + userIndex.lastName.charAt(0) : null;
      }));
  }

  /**
   * Empty On destroy to ensure @Hark decorator works for an AOT build
   */
  ngOnDestroy() {
    // This is intentional
  }
}

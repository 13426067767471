
import { ExportOutputAssetAHubVO } from 'app/valueObjects/ahub/accounts/export-output-asset.ahub.vo';
import { ExportTypeCodeAHubEnum } from 'app/valueObjects/ahub/accounts/export-type-code.ahub.enum';
import { ClientLogAHubVO } from 'app/valueObjects/ahub/library/client-log.ahub.vo';
import { ExportGeneratorExporterMappingAHubVO } from 'app/valueObjects/ahub/library/export-generator-exporter-mapping.ahub.vo';
import { ExporterBuildHistoryIndexAHubVO } from 'app/valueObjects/ahub/library/exporter-build-history-index.ahub.vo';
import { ExporterBuildHistoryAHubVO } from 'app/valueObjects/ahub/library/exporter-build-history.ahub.vo';
import { ExporterExportGeneratorSettingsVO } from 'app/valueObjects/ahub/library/exporter-export-generator-settings.vo';
import { ExtractAssetMatchAHubVO } from 'app/valueObjects/ahub/library/extract-asset-match.vo';
import { ExtractDefaultValueRulesAHubVO } from 'app/valueObjects/ahub/library/extract-default-value-rules.ahub.vo';
import { ExtractDocumentSettings } from 'app/valueObjects/ahub/library/extract-document-settings.ahub.vo';
import { ProductSortAHubVO } from 'app/valueObjects/ahub/library/product-sort.ahub.vo';
import { ResourcePackIndexAHubVO } from 'app/valueObjects/ahub/library/resource-pack-index.ahub.vo';
import { ResourcePackAHubVO } from 'app/valueObjects/ahub/library/resource-pack.ahub.vo';
import { PaginationIdBreakdownVO } from 'app/valueObjects/ahub/system/pagination-id-breakdown.ahub.vo';
import { PaginationIdRequestVO } from 'app/valueObjects/ahub/system/pagination-id-request.ahub.vo';
import { WorkflowDownloadFileAHubVO } from 'app/valueObjects/ahub/work/workflow-download-file.ahub.vo';
/**
 * More utils
 */
import { Utils } from 'modules/common/utils';
/**
 * Core
 */
import { Observable } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';
/**
 * Selectors
 */
import { appDataItemLimit, session } from 'selector/app.selector';
/**
 * Store Access
 */
import { StoreAccess } from 'store/store-access';
import { ClientConfigurationAHubVO } from 'valueObjects/ahub/accounts/client-configuration.ahub.vo';
import { ClientIndexAHubVO } from 'valueObjects/ahub/accounts/client-index.ahub.vo';
import { ClientLibraryIndexAHubVO } from 'valueObjects/ahub/accounts/client-library-index.ahub.vo';
import { ClientLibraryVersionIndexAHubVO } from 'valueObjects/ahub/accounts/client-library-version-index.ahub.vo';
import { ClientLibraryVersionAHubVO } from 'valueObjects/ahub/accounts/client-library-version.ahub.vo';
import { ClientLibraryAHubVO } from 'valueObjects/ahub/accounts/client-library.ahub.vo';
import { ClientQuotaAHubVO } from 'valueObjects/ahub/accounts/client-quota.ahub.vo';
import { ClientStorageIndexAHubVo } from 'valueObjects/ahub/accounts/client-storage-index.ahub.vo';
import { ClientAHubVO } from 'valueObjects/ahub/accounts/client.ahub.vo';
import { DistributionGroupIndexAHubVO } from 'valueObjects/ahub/accounts/distribution-group-index.ahub.vo';
import { DistributionGroupAHubVO } from 'valueObjects/ahub/accounts/distribution-group.ahub.vo';
import { DistributionIndexAHubVO } from 'valueObjects/ahub/accounts/distribution-index.ahub.vo';
import { DistributionAHubVO } from 'valueObjects/ahub/accounts/distribution.ahub.vo';
import { EntityPermissionAHubVO } from 'valueObjects/ahub/accounts/entity-permission.ahub.vo';
import { ExportDistributionAHubVO } from 'valueObjects/ahub/accounts/export-distribution.ahub.vo';
import { ExportGeneratorAHubVO } from 'valueObjects/ahub/accounts/export-generator.ahub.vo';
import { ExportIndexAHubVO } from 'valueObjects/ahub/accounts/export-index.ahub.vo';
import { ExportTypeAHubVO } from 'valueObjects/ahub/accounts/export-type.ahub.vo';
import { ExportVersionAHubVO } from 'valueObjects/ahub/accounts/export-version.ahub.vo';
import { ExportAHubVO } from 'valueObjects/ahub/accounts/export.ahub.vo';
import { UserExtendedAHubVO } from 'valueObjects/ahub/accounts/user-extended.ahub.vo';
import { UserIndexAHubVO } from 'valueObjects/ahub/accounts/user-index.ahub.vo';
import { UserSessionCredentialsAHubVO } from 'valueObjects/ahub/accounts/user-session-credentials.ahub.vo';
import { UserAHubVO } from 'valueObjects/ahub/accounts/user.ahub.vo';
import { WorkGroupIndexAHubVO } from 'valueObjects/ahub/accounts/work-group-index.ahub.vo';
import { WorkGroupAHubVO } from 'valueObjects/ahub/accounts/work-group.ahub.vo';
import { DataSetCategoryAHubVO } from 'valueObjects/ahub/library/dataset-category.ahub.vo';
import { DataSetIndexAHubVO } from 'valueObjects/ahub/library/dataset-index.ahub.vo';
import { DataSetAHubVO } from 'valueObjects/ahub/library/dataset.ahub.vo';
import { DocumentProductFileTypeAHubEnum } from 'valueObjects/ahub/library/document-product-file-type.ahub.enum';
import { ExporterIndexAHubVO } from 'valueObjects/ahub/library/exporter-index.ahub.vo';
import { ExporterAHubVO } from 'valueObjects/ahub/library/exporter.ahub.vo';
import { ExtractDefinitionIndexAHubVO } from 'valueObjects/ahub/library/extract-definition-index.ahub.vo';
import { ExtractDefinitionAHubVO } from 'valueObjects/ahub/library/extract-definition.ahub.vo';
import { ExtractHistoryIndexAHubVO } from 'valueObjects/ahub/library/extract-history-index.ahub.vo';
import { ExtractIndexAHubVO } from 'valueObjects/ahub/library/extract-index.ahub.vo';
import { ExtractAHubVO } from 'valueObjects/ahub/library/extract.ahub.vo';
import { ProductAssetAHubVO } from 'valueObjects/ahub/library/product-asset.ahub.vo';
import { ProductClassFilterAHubVO } from 'valueObjects/ahub/library/product-class-filter.ahub.vo';
import { ProductClassIndexAHubVO } from 'valueObjects/ahub/library/product-class-index.ahub.vo';
import { ProductClassProductCount } from 'valueObjects/ahub/library/product-class-product-count.ahub.vo';
import { ProductClassAHubVO } from 'valueObjects/ahub/library/product-class.ahub.vo';
import { ProductPropertyAllocChainIndexAHubVO } from 'valueObjects/ahub/library/product-property-alloc-chain-index.ahub.vo';
import { ProductPropertyAllocChainValidateReportAHubVO } from 'valueObjects/ahub/library/product-property-alloc-chain-validate-report.ahub.vo';
import { ProductPropertyAllocChainValueCommitAHubVO } from 'valueObjects/ahub/library/product-property-alloc-chain-value-commit.ahub';
import { ProductPropertyAllocChainAHubVO } from 'valueObjects/ahub/library/product-property-alloc-chain.ahub.vo';
import { ProductPropertyAllocationAHubVO } from 'valueObjects/ahub/library/product-property-allocation.ahub.vo';
import { ProductPropertyCompletenessAHubVO } from 'valueObjects/ahub/library/product-property-completeness.ahub.vo';
import { ProductPropertyFilterAHubVO } from 'valueObjects/ahub/library/product-property-filter.ahub.vo';
import { ProductPropertyIndexAHubVO } from 'valueObjects/ahub/library/product-property-index.ahub.vo';
import { ProductPropertySectionIndexAHubVO } from 'valueObjects/ahub/library/product-property-section-index.ahub.vo';
import { ProductPropertySectionAHubVO } from 'valueObjects/ahub/library/product-property-section.ahub.vo';
/**
 * Value objects.
 */
import { ProductPropertyTypeAHubVO } from 'valueObjects/ahub/library/product-property-type.ahub.vo';
import { ProductPropertyAHubVO } from 'valueObjects/ahub/library/product-property.ahub.vo';
import { ProductAHubVO } from 'valueObjects/ahub/library/product.ahub.vo';
/**
 * AHub VO
 */
import { PresignedUrlAHubVO } from 'valueObjects/ahub/presigned-url.ahub.vo';
//System
import { SystemStatusAHubVO } from 'valueObjects/ahub/system/system-status.ahub.vo';
import { RequestTicketAHubVO } from 'valueObjects/ahub/work/request-ticket.ahub.vo';
import { WorklogSegmentAHubVO } from 'valueObjects/ahub/work/worklog-segment.ahub.vo';
import { WorklogAHubVO } from 'valueObjects/ahub/work/worklog.ahub.vo';
import { PaginationControlAppVO, PAGINATION_DEFAULT } from 'valueObjects/app/pagination-control.app.vo';
/**
 * App VO
 */
import { SessionAppVO } from 'valueObjects/app/session.app.vo';
import { ProductAssetViewVO } from 'valueObjects/view/product-asset-view.vo';
import { AHubServiceUtil } from './ahub.service.util';

const RESPONSE_TYPE_TEXT = 'text';
const RESPONSE_TYPE_JSON = 'json'; // defualt !

export class AHubService {

  constructor(private serviceUtil: AHubServiceUtil) { }

  /**
   * ----------------------------------------------------------------------------
   * ACCOUNT
   * ----------------------------------------------------------------------------
   */

  /**
   * Make the request to create the system database validation files.
   */
  systemDatabaseValidationFilesCreate(): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/system/database/validationFiles/create', {})
  }

  /**
   * Get a new login token for the app id specified.
   */
  loginToken(appId: string): Observable<string> {
    return this.serviceUtil.requestGet('/accounts/login/token', { appId: appId }, undefined, RESPONSE_TYPE_TEXT)
  }

  /**
   * This function will make the call to validate an email address, password and token with a user.
   */
  logUserValidate(emailAddress: string, password: string, token: string): Observable<string> {
    return this.serviceUtil.requestPut('/accounts/login/token',
      { userEmail: emailAddress, userPassword: password, loginToken: token }, undefined, RESPONSE_TYPE_TEXT)
  }

  /**
   * This function will get the login provider URL for a identifier provider code.
   */
  loginUrl(email: string): Observable<string> {
    return this.serviceUtil.requestGet('/accounts/login/url', { email: email }, undefined, RESPONSE_TYPE_TEXT)
  }

  /**
   * This function will make the call to validate a login identifier provider token with an aHub token.
   */
  loginIdentifierProviderTokenValidate(loginToken: string, authenticationToken: string): Observable<string> {
    return this.serviceUtil.requestPut('/accounts/login/token',
      { authenticationToken: authenticationToken, loginToken: loginToken }, undefined, RESPONSE_TYPE_TEXT)
  }

  /**
   * This function will make a request to register a user based on a identifier provider token.
   *
   * @param loginToken              The token used to make the login request.
   * @param authenticationToken     The authentication token provided by the identifier provider.
   */
  loginIdentifierProviderTokenRegister(loginToken: string, authenticationToken: string): Observable<string> {
    return this.serviceUtil.requestPost('/accounts/login/token',
      { authenticationToken: authenticationToken, loginToken: loginToken }, undefined, RESPONSE_TYPE_TEXT)
  }

  /**
   * Authorize the token under our credentials
   *
   * @param token   Token which we want to authrorize
   */
  loginTokenAuthorize(token: string): Observable<any> {
    return this.serviceUtil.requestPut('/accounts/login/token/authorize', { loginToken: token });
  }

  /**
   * Get a new login token for the app id specified.
   */
  sessionToken(sessionId: string): Observable<string> {
    return this.serviceUtil.requestGet('/accounts/session/token', { sessionId: sessionId }, undefined, RESPONSE_TYPE_TEXT)
  }

  /**
   * Make a request to get the user credentials for a login token.
   *
   * @param token       The token thats assigned to a user who's credentials we want.
   */
  userSessionNewFromToken(token: string): Observable<UserSessionCredentialsAHubVO> {
    return this.serviceUtil.requestGet('/accounts/user/session/credentials', { loginToken: token })
  }

  /**
   * Make a SIGNED request to get the user credentials.
   */
  sessionInfo(): Observable<UserSessionCredentialsAHubVO> {
    return this.serviceUtil.requestGet('/accounts/user/session/credentials/info', {})
  }

  /**
   * Invalidate the current users session, effectively logging them out of all ahub applications.
   */
  sessionInvalidate(): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestDelete('/accounts/user/' + this.getUserId() + '/session/credentials', {})
  }

  /**
   * Make a request to get the user credentials for a login token.
   *
   * @param token       The token thats assigned to a user who's credentials we want.
   */
  userSessionCredentialsFromEmailPasswordAndToken(email: string, password: string, token: string): Observable<UserSessionCredentialsAHubVO> {
    return this.serviceUtil.requestGet('/accounts/user/session/credentials', { userEmail: email, userPassword: password, loginToken: token })
  }

  /**
   * Get the user id by the users current session
   */
  userIdBySession(): Observable<number> {
    return this.serviceUtil.requestGet('/accounts/userId').pipe(
      map((value: any) => <number>value));
  }

  /**
   * Get the user id by the users current session
   */
  serverTimeFetch(): Observable<number> {
    return this.serviceUtil.requestGet('/info/time', { dateTimeFormat: 'x' }, undefined, RESPONSE_TYPE_TEXT).pipe(
      map((response: any): number => <number>parseInt(response)));
  }
  /**
   * Call the aHub to commit a user value object.
   *
   * @param user     The user to commit.
   */
  userCommit(user: UserAHubVO): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/accounts/user', {}, this.getObjectString(user));
  }

  /**
   * Get the user id by the users current session
   */
  usersByIds(userIds: number[]): Observable<UserAHubVO> {
    return this.serviceUtil.requestIdBatch('/accounts/user', userIds, 'GET', 100, 'userId')
  }

  /**
   * Get the list of the user indexes by id.
   *
   * @param userIds       The list of user index ids we want.
   */
  userIndexByIds(userIds: number[]): Observable<UserIndexAHubVO> {
    return this.serviceUtil.requestIdBatch('/accounts/userIndex', userIds, 'GET');
  }

  /**
    * Get the full list of the user indexes.
    */
  userIndexFull(cancelSignal?: Observable<any>, paginationControl?: PaginationControlAppVO): Observable<UserIndexAHubVO[]> {

    // We want to paginate this request by default - Set default pagination if none supplied.
    if (!paginationControl)
      paginationControl = Utils.clone(PAGINATION_DEFAULT);

    return this.serviceUtil.requestGet<UserIndexAHubVO[]>('/accounts/userIndex', {}, paginationControl, RESPONSE_TYPE_JSON, cancelSignal);
  }

  /**
    * Get the full list of the user indexes.
    */
  userIndexesByClientIdFetch(clientId: number, cancelSignal?: Observable<any>, paginationControl?: PaginationControlAppVO): Observable<UserIndexAHubVO[]> {

    // We want to paginate this request by default - Set default pagination if none supplied.
    if (!paginationControl)
      paginationControl = Utils.clone(PAGINATION_DEFAULT);

    return this.serviceUtil.requestGet<UserIndexAHubVO[]>('/accounts/client/' + clientId + '/userIndexes', {}, paginationControl, RESPONSE_TYPE_JSON, cancelSignal);
  }

  /**
   *  Get extended user information value objects by id.
   */
  userExtendedsByIds(userIds: number[]): Observable<UserExtendedAHubVO> {
    return this.serviceUtil.requestIdBatch<UserExtendedAHubVO>('/accounts/userExtended', userIds).pipe(
      map((userExtended) => {
        userExtended.createdDate = (userExtended.createdDate) ? new Date(userExtended.createdDate) : null;
        userExtended.keyExpireDate = (userExtended.keyExpireDate) ? new Date(userExtended.keyExpireDate) : null;
        userExtended.verificationDate = (userExtended.verificationDate) ? new Date(userExtended.verificationDate) : null;
        userExtended.preferences = (userExtended.preferences) ? JSON.parse(userExtended.preferences.toString()) : undefined;
        userExtended.temporaryData = (userExtended.temporaryData) ? JSON.parse(userExtended.temporaryData.toString()) : undefined;
        return userExtended;
      }));
  }

  /**
   * Get a list of client log id's based on the current client id and the list passed in.
   * 
   * @param clientLogIds      The list of id's for the client logs we want to get.
   */
  clientLogsByIds(clientLogId: number, entryOffset: number): Observable<ClientLogAHubVO> {
    return this.serviceUtil.requestGet('/library/client/' + this.getClientId() + '/clientLog/' + clientLogId, { offset: entryOffset }).pipe(
      map((newClientLog: ClientLogAHubVO) => {

        // Make sure the updated date is a date.
        newClientLog.updated = new Date(newClientLog.updated);

        // Make sure we have some client log entries.
        if (!newClientLog.entries) {
          newClientLog.entries = [];
        }

        // Make sure all of the logged times are date objects.
        newClientLog.entries = newClientLog.entries.map(clientLogEntry => {

          // Correct each logged time.
          clientLogEntry.loggedTime = new Date(clientLogEntry.loggedTime);

          // Then return the entry.
          return clientLogEntry;
        });

        // Return the corrected client log.
        return newClientLog;
      }));
  }

  /**
   * Get the client index list based on the user id.
   */
  clientIndexsByUserId(userId: number): Observable<ClientIndexAHubVO> {
    return this.serviceUtil.requestGet('/accounts/user/' + userId + '/clientIndex', {})
  }

  /**
   * Get the full list of clients in the system not user related.
   */
  clientIndexs(): Observable<ClientIndexAHubVO> {
    return this.serviceUtil.requestGet('/accounts/clientIndex', {})
  }

  /**
   * Get a list of clients by id's.
   */
  clientsByIds(clientsIds: number[]): Observable<ClientAHubVO> {
    return this.serviceUtil.requestGet('/accounts/client', { clientId: clientsIds })
  }

  /**
   * Register a new client.
   */
  clientAdd(client: ClientAHubVO): Observable<RequestTicketAHubVO> {
    // We don't add an admin user by default to the admin group.. Hark staff will already have admin access.
    // Going forward if we supply these its because we have added the admin email via form when setting up the client.
    return this.serviceUtil.requestPost('/accounts/client', { adminEmail: [] }, this.getObjectString(client))
  }

  /**
   * Save the changed client to the aHub.
   */
  clientSave(client: ClientAHubVO): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/accounts/client/' + client.id, {}, this.getObjectString(client))
  }

  /**
   * Disables the client and prevents any activity on their account.
   */
  clientDisable(clientId: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/accounts/client/' + clientId + '/action/disable')
  }

  /**
   * Deletes a client.
   */
  clientDelete(clientId: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestDelete('/accounts/client/' + clientId)
  }

  /**
   * Enables the client and restoring access to materials and functions for client users.
   */
  clientEnable(clientId: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/accounts/client/' + clientId + '/action/enable')
  }

  /**
   * Get the client library version indexes
   */
  clientLibraryVersionIndex(): Observable<ClientLibraryVersionIndexAHubVO> {
    return this.serviceUtil.requestGet('/accounts/clientLibraryVersionIndex').pipe(
      mergeMap((response: any): ClientLibraryVersionIndexAHubVO[] => response),
      map((clientLibraryVersionIndex: ClientLibraryVersionIndexAHubVO) => {
        clientLibraryVersionIndex.createdDate = new Date(clientLibraryVersionIndex.createdDate);
        return clientLibraryVersionIndex;
      }));
  }

  /**
   * Get the client library version by the version number
   */
  clientLibraryVersion(versions: number[]): Observable<ClientLibraryVersionIndexAHubVO> {
    return this.serviceUtil.requestIdBatch('/accounts/clientLibraryVersion', versions, 'GET', 100, 'versionNumber').pipe(
      map((clientLibraryVersion: ClientLibraryVersionAHubVO) => {
        clientLibraryVersion.createdDate = new Date(clientLibraryVersion.createdDate);
        return clientLibraryVersion;
      }));
  }


  /**
   * Set the current build from version of the client library versions.
   */
  clientLibraryVersionSaveBuildFrom(clientLibraryVersion: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/system/clientLibraryVersion/' + clientLibraryVersion + '/action/buildFrom', { versionNumber: clientLibraryVersion })
  }

  /**
   * Make the request to generate the client library version model.
   *
   * @param clientLibraryVersion
   */
  clientLibraryVersionModelGenerate(clientLibraryVersion: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/system/clientLibraryVersion/' + clientLibraryVersion + '/model/action/generate', { versionNumber: clientLibraryVersion })
  }

  /**
   * Get a presigned URL for a library version model decription.
   *
   * @param versionId                   The id of client library vesion who's model we want.
   */
  clientLibraryVersionModelPresignedUrlFetch(versionId: number): Observable<PresignedUrlAHubVO> {
    return this.serviceUtil.requestGet('/system/clientLibraryVersion/' + versionId + '/model/url', {})
  }

  /**
   * This will make the request to add a new client library version.
   *
   * @param clientLibraryVersion        The client library version to add to the aHub.
   */
  clientLibraryVersionAdd(clientLibraryVersion: ClientLibraryVersionAHubVO): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPost('/system/clientLibraryVersion', {}, this.getObjectString(clientLibraryVersion))
  }

  /**
   * This function will make the request to commit changes to a client library version.
   *
   * @param clientLibraryVersion        The client library version to commit/save.
   */
  clientLibraryVersionCommit(clientLibraryVersion: ClientLibraryVersionAHubVO): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/system/clientLibraryVersion/' + clientLibraryVersion.version, {}, this.getObjectString(clientLibraryVersion))
  }

  /**
   * This function will make the request to validate a client library version by number.
   *
   * @param clientLibraryVersionNumber    The client library version to validate.
   */
  clientLibraryVersionValidate(clientLibraryVersionNumber: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/system/clientLibraryVersion/' + clientLibraryVersionNumber + '/action/validate', {})
  }

  /**
   * This will make the request to delete a client library version from the system
   *
   * @param clientLibraryVersionNumber        The client library version number we want to remove
   */
  clientLibraryVersionDelete(clientLibraryVersionNumber: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestDelete('/system/clientLibraryVersion/' + clientLibraryVersionNumber, {})
  }

  /**
   * Make the request to search for cline library versions.
   */
  clientLibraryVersionsDiscover(): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/system/clientLibraryVersion/action/discover', {})
  }

  /**
   * This function will make the request for all of the client storage indexes for the entire system.
   */
  clientStorageIndexes(): Observable<ClientStorageIndexAHubVo> {
    return this.serviceUtil.requestGet('/accounts/clientStorageIndex')
  }

  /**
   * This function will make the request for all of the client library indexes for the entire system.
   */
  clientLibraryIndexes(): Observable<ClientLibraryIndexAHubVO> {
    return this.serviceUtil.requestGet('/accounts/clientLibraryIndex').pipe(
      mergeMap((response: any): ClientLibraryIndexAHubVO[] => response),
      map((clientLibrary: ClientLibraryIndexAHubVO) => {
        clientLibrary.createdDate = new Date(clientLibrary.createdDate);
        clientLibrary.lastUsedDate = (clientLibrary.lastUsedDate) ? new Date(clientLibrary.lastUsedDate) : null;
        return clientLibrary;
      }));
  }

  /**
   * Get the client libraries for the ids specified.
   *
   * @param ids         The ids of the libraries we want.
   */
  clientLibrariesById(ids: number[]): Observable<ClientLibraryAHubVO> {
    return this.serviceUtil.requestIdBatch('/accounts/clientLibrary', ids).pipe(
      map((clientLibrary: ClientLibraryAHubVO) => {
        clientLibrary.createdDate = new Date(clientLibrary.createdDate);
        clientLibrary.lastUsedDate = (clientLibrary.lastUsedDate) ? new Date(clientLibrary.lastUsedDate) : null;
        return clientLibrary;
      }));
  }

  // /**
  //  * Get the distribution group indexs based on the current client
  //  */
  // distributionGroupIndexsByClientId(): Observable<DistributionGroupIndexAHubVO> {
  //   return this.serviceUtil.requestGet('/accounts/client/' + this.getClientId() + '/distributionGroupIndex');
  // }

  /**
   * Get the distribution group indexs based on the current client
   */
  distributionGroupIndexsByClientId(clientId: number): Observable<DistributionGroupIndexAHubVO> {

    return this.serviceUtil.requestGet('/accounts/client/' + clientId.toString() + '/distributionGroupIndex');
  }

  /**
   * Get the distribution group indexs based on the current client
   */
  distributionGroupIndexsBySessionClientId(): Observable<DistributionGroupIndexAHubVO> {

    return this.serviceUtil.requestGet('/accounts/client/' + this.getClientId() + '/distributionGroupIndex');
  }


  /**
   * Get the distribution groups by ids
   */
  distributionGroupsByIds(ids: number[]): Observable<DistributionGroupAHubVO> {
    return this.serviceUtil.requestIdBatch('/accounts/distributionGroup', ids, 'GET');
  }


  /**
   * This function will send a new distribution group to the aHub.
   */
  distributionGroupAdd(distributionGroup: DistributionGroupAHubVO): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPost('/accounts/distributionGroup', {}, this.getObjectString(distributionGroup))
  }


  /**
   * This function will save a distribution group to the aHub.
   */
  distributionGroupSave(distributionGroup: DistributionGroupAHubVO): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/accounts/distributionGroup/' + distributionGroup.id, {}, this.getObjectString(distributionGroup))
  }


  /**
   * This function will delete a distribution group from the aHub.
   */
  distributionGroupDelete(distributionGroupId: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestDelete('/accounts/distributionGroup/' + distributionGroupId)
  }


  /**
   * This function will make a request to add a user to a distribution group by email and id.
   */
  distributionGroupUserEmailAdd(userEmail: string, distributionGroupId: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/accounts/distributionGroup/' + distributionGroupId + '/action/userJoinByEmail', { userEmail: userEmail })
  }

  /**
  * This function will make a request to add a user to a work group by key and id.
  */
  distributionGroupUserJoinByKey(key: string, userId: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/accounts/distributionGroup/action/userJoinByKey', { userId: userId, key: key })
  }
  /**
   * This function will make a reques to add a user to a distribution group by ids.
   */
  distributionGroupUserIdRemove(distributionGroupId: number, userId: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestDelete('/accounts/distributionGroup/' + distributionGroupId + '/user/' + userId)
  }

  /**
   * This function will make a request to delete users from a distribution group by ids.
   */
  distributionGroupUserIdsRemove(distributionGroupId: number, userIds: number[]): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestIdBatch('/accounts/distributionGroup/' + distributionGroupId + '/user', userIds, 'DELETE', 100, 'userId');
  }

  /**
   * This function will make a request to delete user from distribution groups by ids.
   */
  distributionGroupsUserIdRemove(userId: number, distributionGroupIds: number[]): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestDelete('/accounts/user/' + userId + '/distributionGroups', { 'distributionGroupIds': distributionGroupIds });
  }

  /**
   * This function will make a request to add a user from distribution groups by ids.
   */
  distributionGroupsUserIdAdd(userId: number, distributionGroupIds: number[]): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/accounts/user/' + userId + '/distributionGroups', { 'distributionGroupIds': distributionGroupIds });
  }

  /**
   * Get a list of user indexes for the users which are associated with the specified distribution group
   */
  distributionGroupUserIndex(distributionGroupId: number): Observable<UserIndexAHubVO> {
    return this.serviceUtil.requestGet('/accounts/distributionGroup/' + distributionGroupId + '/userIndex', {});
  }

  /**
   * Get a DG indexes for the user id specified
   */
  distributionGroupIndexesByUserId(userId: number): Observable<DistributionGroupIndexAHubVO> {
    return this.serviceUtil.requestGet('/accounts/user/' + userId + '/distributionGroupIndex', {})
  }

  /**
   * Get a DG indexes for the user id specified
   */
  distributionGroupIndexesByClientIdAndUserId(clientId: number, userId: number): Observable<DistributionGroupIndexAHubVO> {
    return this.serviceUtil.requestGet('/accounts/client/' + clientId + '/user/' + userId + '/distributionGroupIndex', {})
  }

  /**
   * Get a list of distribution indexes for the users which are associated with the specified distribution group
   */
  distributionGroupDistributionIndex(distributionGroupId: number): Observable<DistributionIndexAHubVO> {
    return this.serviceUtil.requestGet('/accounts/distributionGroup/' + distributionGroupId + '/distributionIndex', {})
  }

  /**
  * Get the distributions (Distribution details) by ids
  */
  distributionsByIds(ids: number[]): Observable<DistributionAHubVO> {
    return this.serviceUtil.requestIdBatch('/accounts/distribution/', ids, 'GET');
  }

  /**
   * This function will send a new distribution to the aHub.
   */
  distributionAdd(distribution: DistributionAHubVO[]): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPost('/accounts/distribution', {}, this.getObjectString(distribution))
  }

  /**
   * Save the distribution.
   */
  distributionSave(distribution: DistributionAHubVO): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/accounts/distribution/' + distribution.id, {}, this.getObjectString(distribution))
  }

  /**
     * This function will delete a distribution from the aHub.
     */
  distributionDelete(distributionId: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestDelete('/accounts/distribution/' + distributionId)
  }

  /**
     * This function will delete a distribution from the aHub.
     */
  distributionsDelete(distributionIds: number[]): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestIdBatch('/accounts/distribution/', distributionIds, 'DELETE');
  }

  /**
     * This function will disable a distributions in the aHub.
     */
  distributionsDisable(distributionIds: number[]): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestIdBatch('/accounts/distribution/action/disable/', distributionIds, 'PUT');
  }

  /**
     * This function will enable a distributions in the aHub.
     */
  distributionsEnable(distributionIds: number[]): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestIdBatch('/accounts/distribution/action/enable/', distributionIds, 'PUT');
  }

  /**
   * Copys distribution parameters from one distribution id to other distribution ids
   */
  distributionParametersCopy(fromDistributionId: number, toDistributionIds: number[]): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestIdBatch('/accounts/distribution/' + fromDistributionId + '/parameter/action/copy', toDistributionIds, 'PUT');
  }

  /**
   * This will get a full listing of the export types in the aHub.
   */
  exportTypesAll(): Observable<ExportTypeAHubVO> {
    return this.serviceUtil.requestGet('/accounts/exportType');
  }

  /**
   * This will get a full listing of the export generators in the aHub.
   */
  exportGeneratorsAll(): Observable<ExportGeneratorAHubVO> {
    return this.serviceUtil.requestGet('/accounts/exportGenerator');
  }

  /**
   * Requests a url to access the mapping blueprint for the export generator supplied
   */
  exportGeneratorMappingBlueprintUrl(generatorId: number, majorVersion?: number, minorVersion?: number): Observable<string> {

    //Parameters for the request
    const params = {};

    //Add the parameters in where required
    if (majorVersion) params["majorVersion"] = majorVersion;
    if (minorVersion) params["minorVersion"] = minorVersion;

    return this.serviceUtil.requestGet<PresignedUrlAHubVO>(
      `/accounts/exportGenerator/${generatorId}/mappingBlueprint/url`,
      params
    ).pipe(
      map(presigned => presigned.signedUrl)
    );
  }

  /**
   * Get a list of export indexes which belong to the current client
   */
  exportIndexesByClientId(): Observable<ExportIndexAHubVO> {
    return this.serviceUtil.requestGet('/accounts/client/' + this.getClientId() + '/exportIndex', {}).pipe(
      mergeMap((response: any): ExportIndexAHubVO[] => response),
      map((newExport: ExportIndexAHubVO) => {
        newExport.statusChanged = new Date(newExport.statusChanged);
        newExport.created = new Date(newExport.created);
        return newExport;
      }));
  }

  /**
   * Get a list of export indexes which belong to the current client
   */
  exportsByIds(exportIds: number[]): Observable<ExportAHubVO> {
    return this.serviceUtil.requestGet('/accounts/export', { exportId: exportIds }).pipe(
      mergeMap((response: any): ExportAHubVO[] => response),
      map((newExport: ExportAHubVO) => {
        newExport.statusChanged = new Date(newExport.statusChanged);
        newExport.created = new Date(newExport.created);
        return newExport;
      }));
  }

  /**
   * Get a list of distribution indexes for the users which are associated with the specified export
   */
  exportDistributionIndex(exportId: number): Observable<DistributionIndexAHubVO> {
    return this.serviceUtil.requestGet('/accounts/export/' + exportId + '/distributionIndex', {})
  }

  /**
   * This function will send a new export to the aHub.
   */
  exportAdd(exportVO: ExportAHubVO): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPost('/accounts/export', {}, this.getObjectString(exportVO))
  }


  /**
   * This function will save an export to the aHub.
   */
  exportSave(exportVO: ExportAHubVO): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/accounts/export/' + exportVO.id, {}, this.getObjectString(exportVO))
  }

  /**
   * This function will delete an export from the aHub.
   */
  exportDelete(exportId: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestDelete('/accounts/export/' + exportId)
  }

  /**
   * Get the URL for the image to prepresent a preview of an export.
   */
  exportPreviewImageUrl(exportId: number, versionId: number): Observable<PresignedUrlAHubVO> {
    return this.serviceUtil.requestGet('/accounts/export/' + exportId + '/version/' + versionId + '/previewImageUrl', {})
  }

  /**
   * Get the path URL for the latest version of the export.
   */
  exportLatestVersionPathUrl(exportId: number): Observable<PresignedUrlAHubVO> {
    return this.serviceUtil.requestGet('/accounts/export/' + exportId + '/version/latest/pathUrl', {});
  }

  /**
   * Get the path URL for the specified version of the export.
   */
  exportVersionPathUrl(exportId: number, version: number): Observable<PresignedUrlAHubVO> {
    return this.serviceUtil.requestGet('/accounts/export/' + exportId + '/version/' + version + '/pathUrl', {});
  }

  /**
   * Get the legacy refs of an export.
   */
  exportLegacyRefs(exportId: number): Observable<string[]> {
    return this.serviceUtil.requestGet('/accounts/export/' + exportId + '/legacyRefs', {})
  }


  /**
   * This function will commit export legacy refs to the aHub.
   */
  exportLegacyRefsCommit(exportId: number, exportLegacyRefs: string[]): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut(
      '/accounts/export/' + exportId + '/legacyRefs',
      {},
      this.getObjectString(exportLegacyRefs))
  }

  /**
   * Get the export version assets for a export and version.
   * 
   * @param exportId              The id of the export who's assets we want.
   * @param exportVersionId       The id of the version in the export.
   */
  exportVersionAssets(exportId: number, exportVersionId: number): Observable<ExportOutputAssetAHubVO[]> {
    return this.serviceUtil.requestGet('/accounts/export/' + exportId + '/version/' + exportVersionId + '/assets');
  }


  /**
   * Get the export version assets for a export and version and ids supplied.
   * 
   * @param exportId              The id of the export who's assets we want.
   * @param exportVersionId       The id of the version in the export.
   * @param assetIds              The id's for the assets
   */
  exportVersionAssetsByIds(exportId: number, exportVersionId: number, assetIds: number[]): Observable<ExportOutputAssetAHubVO[]> {
    return this.serviceUtil.requestIdBatch(`/accounts/export/${exportId}/version/${exportVersionId}/asset`, assetIds);
  }

  /**
   * This function will commit an export version to the aHub.
   */
  exportVersionCommit(exportVersion: ExportVersionAHubVO): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/accounts/export/' + exportVersion.exportId + '/version/' + exportVersion.version, {}, this.getObjectString(exportVersion))
  }

  /**
   * This function will delete an export version from the aHub.
   */
  exportVersionDelete(exportId: number, exportVersionId: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestDelete('/accounts/export/' + exportId + '/version/' + exportVersionId)
  }

  /**
   * This function will withdraw an export version from being downloadable.
   */
  exportVersionWithdraw(exportId: number, exportVersionId: number, withdraw): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/accounts/export/' + exportId + '/version/' + exportVersionId + '/action/withdraw', { withdraw: withdraw })
  }

  /**
   * This function will disable an export version in the aHub.
   */
  exportVersionHidden(exportId: number, exportVersionNumber: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/accounts/export/' + exportId + '/version/' + exportVersionNumber + '/action/hidden')
  }

  /**
   * This function will internal an export version in the aHub.
   */
  exportVersionInternal(exportId: number, exportVersionNumber: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/accounts/export/' + exportId + '/version/' + exportVersionNumber + '/action/internal')
  }

  /**
   * This function will preview an export version in the aHub.
   */
  exportVersionPreview(exportId: number, exportVersionNumber: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/accounts/export/' + exportId + '/version/' + exportVersionNumber + '/action/preview')
  }

  /**
   * This function will limited release an export version in the aHub.
   */
  exportVersionLimitedRelease(exportId: number, exportVersionNumber: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/accounts/export/' + exportId + '/version/' + exportVersionNumber + '/action/limitedRelease')
  }

  /**
   * This function will release an export version in the aHub.
   */
  exportVersionRelease(exportId: number, exportVersionNumber: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/accounts/export/' + exportId + '/version/' + exportVersionNumber + '/action/release')
  }

  /**
   * This function will zip an export versions files and commence a download (worklog triggered).
   */
  exportVersionFileZipDownload(exportId: number, exportVersionNumber: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/accounts/export/' + exportId + '/version/' + exportVersionNumber + '/files/action/download')
  }

  /**
   * Get a list of export indexes which belong to the current client
   */
  exportDistributions(userId: number, exportTypes: ExportTypeCodeAHubEnum[]): Observable<ExportDistributionAHubVO> {
    return this.serviceUtil.requestGet('/accounts/user/' + userId + '/exportDistributions', { exportTypeCode: exportTypes });
  }

  /**
   * This function will get all of the aViews that the current user is attached too.
   */
  exportDistributionAviews(): Observable<ExportDistributionAHubVO[]> {
    return this.serviceUtil.requestGet('/accounts/user/' + this.getUserId() + '/exportDistributions', { exportTypeCode: [ExportTypeCodeAHubEnum.AVIEW] })
  }

  /**
   * Get a list of exporter indexes which belong to the current client
   */
  exporterIndexesByClientId(): Observable<ExporterIndexAHubVO> {
    return this.serviceUtil.requestGet('/library/client/' + this.getClientId() + '/exporterIndex', {})
  }

  /**
   * Get exporter(s) by id
   */
  exportersByIds(exporterIds: number[]): Observable<ExporterAHubVO> {
    return this.serviceUtil.requestGet('/library/client/' + this.getClientId() + '/exporter', { exporterId: exporterIds }).pipe(
      mergeMap((response: any): ExporterAHubVO[] => response),
      map((newExport: ExporterAHubVO) => {
        newExport.created = new Date(newExport.created);
        return newExport;
      }));
  }

  /**
   * This function will send a new exporter to the aHub.
   */
  exporterAdd(exporterVO: ExporterAHubVO): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPost('/library/client/' + this.getClientId() + '/exporter', {}, this.getObjectString(exporterVO))
  }


  /**
   * This function will save an exporter to the aHub.
   */
  exporterSave(exporterVO: ExporterAHubVO): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/library/client/' + this.getClientId() + '/exporter/' + exporterVO.id, {}, this.getObjectString(exporterVO))
  }

  /**
   * This function will delete an exporter from the aHub.
   */
  exporterDelete(exporterId: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestDelete('/library/client/' + this.getClientId() + '/exporter/' + exporterId)
  }

  /**
   * This function will copy an exporter from the aHub.
   */
  exporterCopy(exporterId: number, newExporterName): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPost('/library/client/' + this.getClientId() + '/exporter/' + exporterId + '/copy', { newExporterName: newExporterName })
  }

  /**
   * Get exporter build history by the exporter id
   */
  exporterBuildHistoryIndexesById(exporterId: number): Observable<ExporterBuildHistoryIndexAHubVO> {
    return this.serviceUtil.requestGet('/library/client/' + this.getClientId() + '/exporter/' + exporterId + "/exporterBuildHistoryIndexes", {}).pipe(
      mergeMap((response: any): ExporterBuildHistoryIndexAHubVO[] => response),
      map((history: ExporterBuildHistoryIndexAHubVO) => {

        //Fix the dates if we have them and then return them
        if (history.buildStartDate) history.buildStartDate = new Date(history.buildStartDate);
        if (history.buildEndDate) history.buildEndDate = new Date(history.buildEndDate);
        return history;
      }));
  }

  /**
   * This function gets a list of exporter build histories based on id's.
   *
   * @param historyIds              The id's of the exporter build histories that we want to get.
   */
  exporterBuildHistoryByIds(historyIds: number[]): Observable<ExporterBuildHistoryAHubVO> {
    return this.serviceUtil.requestGet('/library/client/' + this.getClientId() + '/exporterBuildHistory', { id: historyIds });
  }

  /**
   * This function will build an export from the exporter from the aHub.
   */
  exporterBuild(exporterId: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/library/client/' + this.getClientId() + '/exporter/' + exporterId + '/action/build');
  }

  /**
   * This function will cancel an exporters build.
   * 
   * @param exporterId          The id of the export who's build you want to cancel.
   */
  exporterCancelBuild(exporterId: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPost('/library/client/' + this.getClientId() + '/exporter/' + exporterId + '/action/cancelBuild');
  }

  /**
   * This function will save an exporter to the aHub.
   */
  exporterMappingCommit(exporterId: number, exportGeneratorExporterMapping: ExportGeneratorExporterMappingAHubVO): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/library/client/' + this.getClientId() + '/exporter/' + exporterId + '/mapping', {}, this.getObjectString(exportGeneratorExporterMapping))
  }

  /**
   * This function will save an exporter export settings to the aHub.
   */
  exporterSettingsCommit(exporterId: number, exporterExportGeneratorSettings: ExporterExportGeneratorSettingsVO): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/library/client/' + this.getClientId() + '/exporter/' + exporterId + '/settings', {}, this.getObjectString(exporterExportGeneratorSettings))
  }

  /**
  * Get the distribution group indexs based on the current client
  */
  workGroupIndexsByClientId(): Observable<WorkGroupIndexAHubVO> {
    return this.serviceUtil.requestGet('/accounts/client/' + this.getClientId() + '/workGroupIndex')
  }

  /**
  * Get a list of workGroup indexes which belong to the current client
  */
  workGroupsByIds(workGroupIds: number[]): Observable<WorkGroupAHubVO> {
    return this.serviceUtil.requestGet('/accounts/workgroup', { workgroupId: workGroupIds })
  }

  /**
   * This function will send a new workGroup to the aHub.
   */
  workGroupAdd(workGroupVO: WorkGroupAHubVO): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPost('/accounts/workgroup', {}, this.getObjectString(workGroupVO))
  }


  /**
   * This function will save an workGroup to the aHub.
   */
  workGroupSave(workGroupVO: WorkGroupAHubVO): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/accounts/workgroup/' + workGroupVO.id, {}, this.getObjectString(workGroupVO))
  }

  /**
   * This function will delete an workGroup from the aHub.
   */
  workGroupDelete(workGroupId: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestDelete('/accounts/workgroup/' + workGroupId)
  }

  /**
  * This function will make a request to add a user to a work group by email and id.
  */
  workGroupUserEmailAdd(userEmail: string, workGroupId: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/accounts/workgroup/' + workGroupId + '/action/userJoinByEmail', { userEmail: userEmail })
  }

  /**
  * This function will make a request to add a user to a work group by key and id.
  */
  workGroupUserJoinByKey(key: string, userId: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/accounts/workgroup/action/userJoinByKey', { userId: userId, key: key })
  }

  /**
   * This function will make a reques to add a user to a work group by ids.
   */
  workGroupUserIdRemove(workGroupId: number, userId: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestDelete('/accounts/workgroup/' + workGroupId + '/user/' + userId)
  }

  /**
   * This function will make a reques to remove users from a work group by ids.
   */
  workGroupUserIdsRemove(workGroupId: number, userIds: number[]): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestIdBatch('/accounts/workgroup/' + workGroupId + '/user', userIds, 'DELETE', 100, 'userId');
  }

  /**
   * Get a list of user indexes for the users which are associated with the specified work group
   */
  workGroupUserIndex(workGroupId: number): Observable<UserIndexAHubVO> {
    return this.serviceUtil.requestGet('/accounts/workgroup/' + workGroupId + '/userIndex', {})
  }

  /**
   * Get a entity permissions which belong to the current client/user
   */
  entityPermissionsByUserAndClientId(): Observable<EntityPermissionAHubVO> {
    return this.serviceUtil
      .requestGet('/accounts/user/' + this.getUserId() + '/client/' + this.getClientId() + '/entityPermission', {})
  }

  /**
   * Get a list of work groups which belong to the current client
   */
  workGroupsByUserAndClientId(): Observable<WorkGroupAHubVO> {
    return this.serviceUtil
      .requestGet('/accounts/user/' + this.getUserId() + '/workgroup', { 'clientId': this.getClientId() })
  }

  /**
  * Get a list of client quotas by client id
  */
  clientQuotasByClientId(clientId: number): Observable<ClientQuotaAHubVO> {
    return this.serviceUtil.requestGet('/accounts/client/' + clientId + '/quotas', {})
  }
  /**
  * Get a list of client quotas by client id
  */
  clientQuotasHistoryByClientId(clientId: number): Observable<ClientQuotaAHubVO> {
    return this.serviceUtil.requestGet('/accounts/client/' + clientId + '/quotas/history',
      { 'fromEpoch': 0, 'toEpoch': (new Date).getTime() })
  }

  /**
  * Gets a specific quota by its id.
  */
  clientQuotaByIds(ids: number[]): Observable<ClientQuotaAHubVO> {
    return this.serviceUtil.requestIdBatch('/accounts/client/quotas', ids);
  }

  /**
   * Sets a quota in the aHub.
   */
  clientQuotasCommit(clientQuotas: ClientQuotaAHubVO[]): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/accounts/client/quotas', {}, this.getObjectString(clientQuotas))
  }

  /**
  * Get a client configuration (eg permittedInclusionList) by client id
  */
  sessionClientConfiguration(): Observable<ClientConfigurationAHubVO> {
    return this.clientConfigurationByClientId(this.getClientId());
  }

  /**
  * Get a client configuration (eg permittedInclusionList) by client id
  */
  clientConfigurationByClientId(clientId: number): Observable<ClientConfigurationAHubVO> {
    return this.serviceUtil.requestGet('/accounts/client/' + clientId + '/configuration', {})
  }

  /**
   * Sets a client configuration in the aHub.
   */
  clientConfigurationCommit(clientConfiguration: ClientConfigurationAHubVO): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/accounts/client/' + clientConfiguration.id + '/configuration', {}, this.getObjectString(clientConfiguration))
  }

  /**
   * This function will begin the process of registering a new user with aHub.
   */
  registerUser(firstName, lastName, email): Observable<RequestTicketAHubVO> {
    let user = {
      firstName: firstName,
      lastName: lastName,
      email: email
    }
    return this.serviceUtil.requestPost('/accounts/user/action/userRegister', {}, this.getObjectString(user))
  }

  /**
   * This function will begin the process of registering a new user with aHub.
   */
  verifyUser(userEmail, userPassword, verificationCode): Observable<RequestTicketAHubVO> {
    let params = {
      'userEmail': userEmail,
      'userPassword': userPassword,
      'verificationCode': verificationCode
    }
    return this.serviceUtil.requestPut('/accounts/user/action/userVerification', params)
  }

  /**
   * This function will attempt to verify an updated email address.
   */
  verifyEmailUpdate(userId, verificationCode): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/accounts/user/action/verifyEmailUpdate', { 'verificationCode': verificationCode, 'userId': userId })
  }

  /**
   * This function will begin the process of registering a new user with aHub.
   */
  verifyUserManualOverride(userId): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/accounts/user/action/userVerificationManualOverride', { 'userId': userId })
  }

  /**
   * This function will request a new verification code for the specified user.
   */
  sendVerificationCode(email): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/accounts/user/action/verificationCodeGenerate', { 'userEmail': email })
  }

  /**
   * This function will send an email about the user having problems with login verification.
   */
  verificationProblemsContactSupport(email): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/accounts/user/action/userVerification/help', { 'userEmail': email })
  }

  /**
   * This function will request a new verification code for the specified user and updated email address.
   */
  sendEmailUpdateVerificationCode(userId, updatedEmail): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/accounts/user/action/verificationCodeGenerateEmailUpdate', { 'updatedUserEmail': updatedEmail, 'userId': userId })
  }

  /**
   * ----------------------------------------------------------------------------
   * LIBRARY SERVICES
   * ----------------------------------------------------------------------------
   */

  /**
     * Get the dataSet indexs based on the current client
     */
  dataSetIndexs(): Observable<DataSetIndexAHubVO> {
    return this.serviceUtil.requestGet('/library/client/' + this.getClientId() + '/datasetIndex')
  }

  /**
   * Get a list of dataSet indexes which belong to the current client
   */
  dataSetsByIds(dataSetIds: number[]): Observable<DataSetAHubVO> {
    return this.serviceUtil.requestGet('/library/client/' + this.getClientId() + '/dataset', { dataSetId: dataSetIds })
  }

  /**
   * This function will send a new dataSet to the aHub.
   */
  dataSetAdd(dataSetVO: DataSetAHubVO): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPost('/library/client/' + this.getClientId() + '/dataset', {}, this.getObjectString(dataSetVO))
  }


  /**
   * This function will save an dataSet to the aHub.
   */
  dataSetSave(dataSetVO: DataSetAHubVO): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/library/client/' + this.getClientId() + '/dataset/' + dataSetVO.id, {}, this.getObjectString(dataSetVO))
  }

  /**
   * This function will delete an dataSet from the aHub.
   */
  dataSetDelete(dataSetId: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestDelete('/library/client/' + this.getClientId() + '/dataset/' + dataSetId)
  }

  /**
   * This function will make a copy of a dataSet.
   */
  dataSetCopy(dataSetId: number, newDataSetName, includeExporters: boolean): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPost('/library/client/' + this.getClientId() + '/dataset/' + dataSetId + '/copy', { newDataSetName: newDataSetName, includeExporters: includeExporters })
  }

  /**
   * This function will transform all section properties from one section to another.
   */
  dataSetSwapSectionProperties(dataSetId: number, swapSectionPropertiesFrom: number, swapSectionPropertiesTo: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPost('/library/client/' + this.getClientId() + '/dataset/' + dataSetId + '/swapSectionProperties', { swapSectionPropertiesFrom: swapSectionPropertiesFrom, swapSectionPropertiesTo: swapSectionPropertiesTo })
  }

  /**
   * This function will get the data set products for a list of product id's in a data set.
   *
   * @param dataSetId           The id of the data set that owns the products.
   * @param productIds          The id's of the product assets we want.
   */
  dataSetProductsByIds(dataSetId: number, productIds: number[]): Observable<ProductAHubVO> {
    return this.serviceUtil.requestGet('/library/client/' + this.getClientId() + '/dataset/' + dataSetId + '/products', { id: productIds });
  }

  /**
   * This function will get the data set product assets for a list of product id's in a data set.
   *
   * @param dataSetId           The id of the data set that owns the products.
   * @param productIds          The id's of the product assets we want.
   */
  dataSetProductAssetsByIds(dataSetId: number, productIds: number[]): Observable<ProductAssetAHubVO> {
    return this.serviceUtil.requestGet('/library/client/' + this.getClientId() + '/dataset/' + dataSetId + '/productAssets', { id: productIds });
  }

  /**
   * Call to generate a zip of an asset in a dataset
   *
   * @param dataSetId       The id of the dataset which we want to match the files too
   * @param assetId         id of asset
   * @param assetLocation   asset location
   */
  dataSetProductAssetZipGenerate(dataSetId: number, assetId: number, assetLocation: string): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut(
      '/library/client/' +
      this.getClientId() +
      '/dataset/' + dataSetId +
      '/product/asset/' +
      assetId +
      '/action/zip',
      { assetLocation: assetLocation });
  }

  /**
   * Call the aHub to get a list of data set categories by id's.
   *
   * @param dataSetCategoryIds      The id's of the data set categories we want.
   */
  dataSetCategoriesByIds(dataSetCategoryIds: number[]): Observable<DataSetCategoryAHubVO> {
    return this.serviceUtil.requestGet('/library/client/' + this.getClientId() + '/datasetCategory', { id: dataSetCategoryIds });
  }

  /**
   * Call the aHub to add this new data set category.
   *
   * @param dataSetCategory     The data set category to add to the aHub.
   */
  dataSetCategoryAdd(dataSetCategory: DataSetCategoryAHubVO): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPost('/library/client/' + this.getClientId() + '/datasetCategory', {}, this.getObjectString(dataSetCategory));
  }

  /**
   * This function will make a copy of a dataSet.
   */
  dataSetCopyCategories(dataSetId: number, sourceCategoryIds: number[], targetCategoryId): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPost('/library/client/' + this.getClientId() + '/dataset/' + dataSetId + '/dataSetCategoriesCopy', { 'sourceCategoryIds': sourceCategoryIds, 'targetCategoryId': targetCategoryId })
  }

  /**
   * Call the aHub to commit a data set category value object.
   *
   * @param dataSetCategory     The data set category to commit.
   */
  dataSetCategoryCommit(dataSetCategory: DataSetCategoryAHubVO): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/library/client/' + this.getClientId() + '/datasetCategory/' + dataSetCategory.id, {}, this.getObjectString(dataSetCategory))
  }

  /**
   * This function will commit a data set category product property filters.
   *
   * @param dataSetCategoryId         The id of the data set category who's filters we want to commit.
   * @param productPropertyFilter     The filter to commit.
   */
  dataSetCategoryCommitProductPropertyFilter(dataSetCategoryId: number, productPropertyFilter: ProductPropertyFilterAHubVO): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/library/client/' + this.getClientId() + '/datasetCategory/' + dataSetCategoryId + '/productPropertyFilter', {}, this.getObjectString(productPropertyFilter))
  }

  /**
   * This function will commit a data set category product class filters.
   *
   * @param dataSetCategoryId         The id of the data set category who's filters we want to commit.
   * @param productPropertyFilter     The filter to commit.
   */
  dataSetCategoryCommitProductClassFilter(dataSetCategoryId: number, productClassFilter: ProductClassFilterAHubVO): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/library/client/' + this.getClientId() + '/datasetCategory/' + dataSetCategoryId + '/productClassFilter', {}, this.getObjectString(productClassFilter))
  }

  /**
   * This function will commit a data set category product sort.
   *
   * @param dataSetCategoryId         The id of the data set category who's filters we want to commit.
   * @param productSort               The sort to commit.
   */
  dataSetCategoryCommitProductSort(dataSetCategoryId: number, productSort: ProductSortAHubVO): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/library/client/' + this.getClientId() + '/datasetCategory/' + dataSetCategoryId + '/productSort', {}, this.getObjectString(productSort))
  }

  /**
   * This function will commit a data set category product sort.
   *
   * @param dataSetCategoryId         The id of the data set category who's filters we want to commit.
   * @param priorityIndex             priorityIndex to commit.
   */
  dataSetCategoryCommitPriority(dataSetCategoryId: number, priorityIndex: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/library/client/' + this.getClientId() + '/datasetCategory/' + dataSetCategoryId + '/priorityIndex/' + priorityIndex)
  }


  /**
   * Call the aHub to delete a data set category by id.
   *
   * @param dataSetCategoryId   The id of the data set category to delete.
   */
  dataSetCategoryDelete(dataSetCategoryId: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestDelete('/library/client/' + this.getClientId() + '/datasetCategory/' + dataSetCategoryId)
  }

  /**
   * Call the aHub to move a data set category by id.
   *
   * @param dataSetCategoryId         The id of the data set category to move.
   * @param parentDataSetCategoryId   The id of the data set category which will be the new parent.
   */
  dataSetCategoryMove(dataSetCategoryId: number, parentDataSetCategoryId: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/library/client/' + this.getClientId() + '/datasetCategory/action/move', { dataSetCategoryId: dataSetCategoryId, parentDataSetCategoryId: parentDataSetCategoryId })
  }

  /**
   * Get the data set category products pagination
   */
  dataSetCategoryProductPagination(dataSetCategoryId: number, pagesize: number): Observable<PaginationIdBreakdownVO> {
    return this.serviceUtil.requestGet('/library/client/' + this.getClientId() + '/datasetCategory/' + dataSetCategoryId + '/products/pagination', { pageSize: pagesize })
  }

  /**
   * Get a full listing of the products for a data set category id.
   *
   * @param dataSetCategoryId       The id of the data set category who's id we want.
   */
  dataSetCategoryProducts(dataSetCategoryId: number, cancelSignal?: Observable<any>): Observable<ProductAHubVO[]> {

    //Subscribe the extract product pagination fetch
    return this.dataSetCategoryProductPagination(dataSetCategoryId, 500).pipe(
      switchMap((pagination) => {

        //Limit the pagination of the data to a fixed size
        pagination = this.limitPaginationToAppDataLimit(pagination);

        //Create a new pagionation id request
        let paginationRequest: PaginationIdRequestVO = {
          paginationIdBreakdown: pagination,
          paginationDirection: "desc"
        }

        //Make the request with our new pagination data
        return this.serviceUtil.requestGet<ProductAHubVO[]>('/library/client/' + this.getClientId() + '/datasetCategory/' + dataSetCategoryId + '/product', {}, paginationRequest, RESPONSE_TYPE_JSON, cancelSignal)
      })
    );
  }

  /**
   * Function for getting the id's which represent the sort order of the products
   */
  dataSetCategoryProductIdsSortOrder(dataSetCategoryId: number, searchTerm: string = undefined) {
    const params = searchTerm ? { search: searchTerm } : {};
    return this.serviceUtil.requestGet('/library/client/' + this.getClientId() + '/datasetCategory/' + dataSetCategoryId + '/productId/sorted', params);
  }

  /**
   * Get a listing of the products for a data set category id and the supplied product ids.
   *
   * @param dataSetCategoryId       The id of the data set category who's id we want.
   * @param productIds              The id's of the product which we want
   */
  dataSetCategoryProductsFetchByIds(dataSetCategoryId: number, productIds: number[]): Observable<ProductAHubVO[]> {

    // Now make the call to get all of the products.
    return this.serviceUtil.requestGet<ProductAHubVO[]>('/library/client/' + this.getClientId() + '/datasetCategory/' + dataSetCategoryId + '/products', { id: productIds });
  }

  /**
   * Call the aHub to cancel an export processing
   */
  exportUpdateCancel(exportId: number): Observable<boolean> {
    return this.serviceUtil.requestPost('/library/export/' + exportId + '/cancel', undefined, undefined, RESPONSE_TYPE_TEXT).pipe(
      map((response: any): boolean => response == 'true'));
  }

  /**
   * Call to the aHub to get the product class product counts for the current client.
   */
  productClassProductCountGet(): Observable<ProductClassProductCount> {
    return this.serviceUtil.requestGet('/library/client/' + this.getClientId() + '/productClassProductCounts', {})
  }

  /**
   * Get a list of export indexes which belong to the current client
   */
  productClassIndexsByClientId(): Observable<ProductClassIndexAHubVO> {
    return this.serviceUtil.requestGet('/library/client/' + this.getClientId() + '/productClassIndex', {})
  }

  /**
   * Get a list of product classes which belong to the current client
   */
  productClasses(productClassIds: number[]): Observable<ProductClassAHubVO> {
    return this.serviceUtil.requestIdBatch('/library/client/' + this.getClientId() + '/productClass', productClassIds);
  }


  /**
   * This function will send a new product class to the aHub.
   */
  productClassAdd(productClassVO: ProductClassAHubVO): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPost('/library/client/' + this.getClientId() + '/productClass', {}, this.getObjectString(productClassVO))
  }

  /**
   * This function will send a product class to the aHub.
   */
  productClassCommit(productClassVO: ProductClassAHubVO): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/library/client/' + this.getClientId() + '/productClass/' + productClassVO.id, {}, this.getObjectString(productClassVO))
  }

  /**
   * This function will send a new product class to the aHub.
   */
  productClassDelete(productClassId: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestDelete('/library/client/' + this.getClientId() + '/productClass/' + productClassId, {})
  }

  /**
   * This function will send a new product class to the aHub.
   */
  productClassMove(productClassId: number, parentClassId): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPost('/library/client/' + this.getClientId() + '/productClassMove', { productClassId: productClassId, parentClassId: parentClassId })
  }

  /**
   * Get the full list of product property types for the current client id.
   */
  productPropertyTypes(): Observable<ProductPropertyTypeAHubVO> {
    return this.serviceUtil.requestGet('/library/client/' + this.getClientId() + '/productPropertyType', {})
  }

  /**
   * Get a list of product propery indexes which belong to the current client
   */
  productPropertyIndexs(): Observable<ProductPropertyIndexAHubVO> {
    return this.serviceUtil.requestGet('/library/client/' + this.getClientId() + '/productPropertyIndex', {})
  }

  /**
   * Get a list of product properties which belong to the current client
   */
  productProperty(productPropertyIds: number[]): Observable<ProductPropertyAHubVO> {
    return this.serviceUtil.requestIdBatch('/library/client/' + this.getClientId() + '/productProperty', productPropertyIds);
  }

  /**
   * This function will send a new product property to the aHub.
   */
  productPropertyAdd(productPropertyVO: ProductPropertyAHubVO): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPost('/library/client/' + this.getClientId() + '/productProperty', {}, this.getObjectString(productPropertyVO))
  }

  /**
   * This function will commit an existing product property to the aHub.
   */
  productPropertyCommit(productPropertyVO: ProductPropertyAHubVO): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/library/client/' + this.getClientId() + '/productProperty/' + productPropertyVO.id, {}, this.getObjectString(productPropertyVO))
  }

  /**
   * This function will delete a product property from the aHub.
   */
  productPropertyDelete(productPropertyId: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestDelete('/library/client/' + this.getClientId() + '/productProperty/' + productPropertyId, {})
  }

  /**
   * Get a list of product propery section indexes which belong to the current client
   */
  productPropertySectionIndexs(): Observable<ProductPropertySectionIndexAHubVO> {
    return this.serviceUtil.requestGet('/library/client/' + this.getClientId() + '/productPropertySectionIndex', {}).pipe(
      mergeMap((response: any): ProductPropertySectionIndexAHubVO[] => response));
  }

  /**
   * Get the list of product property sections by ids.
   */
  productPropertySectionByIds(productPropertySectionIds: number[]): Observable<ProductPropertySectionAHubVO> {
    return this.serviceUtil.requestIdBatch('/library/client/' + this.getClientId() + '/productPropertySection', productPropertySectionIds)
  }

  /**
   * This function will send a new product property section to the aHub.
   */
  productPropertySectionAdd(productPropertySectionVO: ProductPropertySectionAHubVO): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPost('/library/client/' + this.getClientId() + '/productPropertySection', {}, this.getObjectString(productPropertySectionVO))
  }

  /**
  * This function will create a new product property section, but base it on an existing section.
  */
  productPropertySectionCopy(productPropertySectionVO: ProductPropertySectionAHubVO, sourceSectionId: number, deepCopy: boolean): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPost('/library/client/' + this.getClientId() + '/productPropertySection', { id: sourceSectionId, deepCopy: deepCopy }, this.getObjectString(productPropertySectionVO))
  }

  /**
   * This function will commit an existing product property section to the aHub.
   */
  productPropertySectionCommit(productPropertySectionVO: ProductPropertySectionAHubVO): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/library/client/' + this.getClientId() + '/productPropertySection/' + productPropertySectionVO.id, {}, this.getObjectString(productPropertySectionVO))
  }

  /**
   * This function will delete a product property section from the aHub.
   */
  productPropertySectionDelete(productPropertySectionId: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestDelete('/library/client/' + this.getClientId() + '/productPropertySection/' + productPropertySectionId, {})
  }

  /**
   * Get a list of product propery allocation indexes which belong to the current client
   */
  productPropertyAllocationIndexs(): Observable<ProductPropertyAllocationAHubVO> {
    return this.serviceUtil.requestGet('/library/client/' + this.getClientId() + '/productPropertyAllocationIndex', {}).pipe(
      mergeMap((response: any): ProductPropertyAllocationAHubVO[] => response));
  }

  /**
   * This function will send a new product property allocation to the aHub.
   */
  productPropertyAllocationsAdd(productPropertyAllocationVOs: ProductPropertyAllocationAHubVO[]): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPost('/library/client/' + this.getClientId() + '/productPropertyAllocations', {}, this.getObjectString(productPropertyAllocationVOs))
  }

  /**
   * This function will delete a product property allocation from the aHub.
   */
  productPropertyAllocationDelete(productPropertyAllocationId: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestDelete('/library/client/' + this.getClientId() + '/productPropertyAllocation/' + productPropertyAllocationId, {})
  }

  /**
   * This function will get the list of product property allocation chain indexes.
   */
  productPropertyAllocationChainIndexes(): Observable<ProductPropertyAllocChainIndexAHubVO> {
    return this.serviceUtil.requestGet('/library/client/' + this.getClientId() + '/productPropertyAllocationChainIndex', {}).pipe(
      mergeMap((response: any): ProductPropertyAllocChainIndexAHubVO[] => response));
  }

  /**
   * This function will get a chain by chain id.
   *
   * @param chainId         The id of the chain to get.
   */
  productPropertyAllocationChainsByIds(chainIds: number[]): Observable<ProductPropertyAllocChainAHubVO> {
    return this.serviceUtil.requestIdBatch('/library/client/' + this.getClientId() + '/productPropertyAllocationChain', chainIds)
  }

  /**
   * This function will make the call to the aHub to add a new product property allocation chain.
   *
   * @param productPropertyAllocationChain        The product property allocation chain to send to the aHub.
   */
  productPropertyAllocationChainAdd(productPropertyAllocationChain: ProductPropertyAllocChainAHubVO): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPost('/library/client/' + this.getClientId() + '/productPropertyAllocationChain', {}, this.getObjectString(productPropertyAllocationChain))
  }

  /**
   * This function will make the call to delete a product property allocation chain.
   *
   * @param productPropertyAllocationChainId      The id of the product property allocation chain to delete.
   */
  productPropertyAllocationChainDelete(productPropertyAllocationChainId: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestDelete('/library/client/' + this.getClientId() + '/productPropertyAllocationChain/' + productPropertyAllocationChainId, {})
  }

  /**
   * This function will make the call to commit a list of product property allocation chain values.
   *
   * @param productPropertyAllocationChainValues              The values to commit.
   */
  productPropertyAllocationChainValuesCommit(productPropertyAllocChainValueCommitAHubVOs: ProductPropertyAllocChainValueCommitAHubVO[]): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/library/client/' + this.getClientId() + '/productPropertyAllocationChainValues', {}, this.getObjectString(productPropertyAllocChainValueCommitAHubVOs));
  }

  /**
   * This function will make a call to validate a product property allocation chain by it's id.
   *
   * @param productPropertyAllocationChainId          The id of the product property allocation chain to validate.
   */
  productPropertyAllocationChainValidate(productPropertyAllocationChainId: number): Observable<ProductPropertyAllocChainValidateReportAHubVO> {
    return this.serviceUtil.requestGet('/library/client/' + this.getClientId() + '/productPropertyAllocationChain/' + productPropertyAllocationChainId + '/action/validate');
  }

  /**
   * This function will make a call to rebuild a product property allocation chains values.
   *
   * @param productPropertyAllocationChainId          The id of the product property allocation chain to rebuild.
   */
  productPropertyAllocationChainRebuild(productPropertyAllocationChainId: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/library/client/' + this.getClientId() + '/productPropertyAllocationChain/' + productPropertyAllocationChainId + '/action/rebuild');
  }

  /**
    * This function will make a call to apply all of the values in a product property allocation chain to the library.
    *
    * @param productPropertyAllocationChainId          The id of the product property allocation chain who's values we want to apply.
    * @param applyToAll                                Are we applying to all products, or just the ones missing a value?
    */
  productPropertyAllocationChainAllValuesApply(productPropertyAllocationChainId: number, applyToAll: boolean): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/library/client/' + this.getClientId() + '/productPropertyAllocationChain/' + productPropertyAllocationChainId + '/action/applyAllValues', { "applyToAll": applyToAll });
  }

  /**
   * Make a request to get the chained values for a chain value.
   *
   * @param productPropertyAllocationChainValueId         The id of the product property allocation chain values who's chained values we want.
   */
  productPropertyAllocationChainValueChainedValuesFetch(productPropertyAllocationChainValueId: number): Observable<string> {
    return this.serviceUtil.requestGet('/library/client/' + this.getClientId() + '/productPropertyAllocationChainValue/' + productPropertyAllocationChainValueId + '/chainedValues');
  }

  /**
   * Make a request to apply a product property allocation chain value across the product library.
   *
   * @param productPropertyAllocationChainValueId         The id of the product property allocation chain value to apply.
   * @param applyToAll                                    Are we applying to all products, or just the ones missing a value?
   */
  productPropertyAllocationChainValueApply(productPropertyAllocationChainValueId: number, applyToAll: boolean): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/library/client/' + this.getClientId() + '/productPropertyAllocationChainValue/' + productPropertyAllocationChainValueId + '/action/apply', { "applyToAll": applyToAll });
  }

  /**
   * This functon will make the request to get the extract definitions.
   */
  extractDefinitionIndexes(): Observable<ExtractDefinitionIndexAHubVO> {
    return this.serviceUtil.requestGet('/library/client/' + this.getClientId() + '/extractDefinitionIndex', { userId: this.getUserId() })
  }

  /**
   * This function will make the call to add a new extract definition value object.
   */
  extractDefinitionAdd(extractDefinition: ExtractDefinitionAHubVO): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPost('/library/client/' + this.getClientId() + '/extractDefinition', {}, this.getObjectString(extractDefinition))
  }

  /**
   * This function will make the call to commit a new extract definition value object.
   */
  extractDefinitionCommit(extractDefinition: ExtractDefinitionAHubVO): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/library/client/' + this.getClientId() + '/extractDefinition/' + extractDefinition.id, {}, this.getObjectString(extractDefinition))
  }

  /**
   * Get the list of extract definitions by ids.
   *
   * @param extractDefinitionIds       The ids to get.
   */
  extractDefinitionByIds(extractDefinitionIds: number[]): Observable<ExtractDefinitionAHubVO> {
    return this.serviceUtil.requestIdBatch('/library/client/' + this.getClientId() + '/extractDefinition', extractDefinitionIds, 'GET');
  }

  /**
   * This function will delete a extract definition from the aHub.
   */
  extractDefinitionDelete(extractDefinitionId: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestDelete('/library/client/' + this.getClientId() + '/extractDefinition/' + extractDefinitionId, {})
  }

  /**
   * This function will get the list of the extract indexes for the current client id.
   */
  extractIndexes(): Observable<ExtractIndexAHubVO> {
    return this.serviceUtil.requestGet('/library/client/' + this.getClientId() + '/extractIndex', { userId: this.getUserId() }).pipe(
      mergeMap((response: any): ExtractIndexAHubVO[] => response),
      map((newExtract: ExtractIndexAHubVO) => {
        newExtract.changed = new Date(newExtract.changed);
        return newExtract;
      }));
  }

  /**
   * This function will get the list of the extract indexes for the extract definition specified
   */
  extractIndexesByExtractDefinitionId(extractDefinitionId: number): Observable<ExtractIndexAHubVO> {
    return this.serviceUtil.requestGet('/library/client/' + this.getClientId() + '/extractDefinition/' + extractDefinitionId + '/extractIndex', {}).pipe(
      mergeMap((response: any): ExtractIndexAHubVO[] => response),
      map((newExtract: ExtractIndexAHubVO) => {
        newExtract.changed = (newExtract.changed) ? new Date(newExtract.changed) : null;
        return newExtract;
      }));
  }

  /**
   * Get the list of extracts by ids.
   *
   * @param extractIds       The ids to get.
   */
  extractByIds(extractIds: number[]): Observable<ExtractAHubVO> {
    return this.serviceUtil.requestIdBatch('/library/client/' + this.getClientId() + '/extract', extractIds).pipe(
      map((newExtract: ExtractAHubVO) => {
        newExtract.changed = (newExtract.changed) ? new Date(newExtract.changed) : null;
        return newExtract;
      }));
  }


  /**
   * This function will make the call to add a new extract value object.
   */
  extractAdd(extract: ExtractAHubVO): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPost('/library/client/' + this.getClientId() + '/extract', {}, this.getObjectString(extract))
  }

  /**
   * This function will make the call to commit a new extract value object.
   */
  extractCommit(extract: ExtractAHubVO): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/library/client/' + this.getClientId() + '/extract/' + extract.id, {}, this.getObjectString(extract))
  }

  /**
   * This function will make the call to commit extract default value rules object.
   */
  extractDefaultValueRulesCommit(extractDefaultValueRules: ExtractDefaultValueRulesAHubVO, extractId): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/library/client/' + this.getClientId() + '/extract/' + extractId + '/defaultValueRules', {}, this.getObjectString(extractDefaultValueRules))
  }

  /**
   * This function will delete a extract from the aHub.
   */
  extractDelete(extractId: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestDelete('/library/client/' + this.getClientId() + '/extract/' + extractId, {})
  }

  /**
   * This function will call the aHub to commit a list of extract document settings.
   * 
   * @param extractId                 The id of the extract that owns the document settings.
   * @param extractDocumentSettings   The extract document settings to commit.
   */
  extractDocumentSettingsCommit(extractId: number, extractDocumentSettings: ExtractDocumentSettings[]): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/library/client/' + this.getClientId() + '/extract/' + extractId + '/documentSettings', {}, this.getObjectString(extractDocumentSettings));
  }

  /**
   * This function will get any currently conflicting extracts
   */
  extractConflicting(extractId: number): Observable<ExtractAHubVO> {
    return this.serviceUtil.requestGet('/library/client/' + this.getClientId() + '/extract/' + extractId + '/conflicts', {})
  }

  /**
   * This function will commit an extracts filters.
   *
   * @param extractId                 The id of the extract who's filters we want to commit.
   * @param productPropertyFilter     The filter to commit.
   */
  extractCommitProductPropertyFilter(extractId: number, productPropertyFilter: ProductPropertyFilterAHubVO): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/library/client/' + this.getClientId() + '/extract/' + extractId + '/productPropertyFilter', {}, this.getObjectString(productPropertyFilter))
  }

  /**
   * This function change the state of an extract to work in progress.
   *
   * @param extractId             The id of the extract we're working with.
   * @param userId                The id of the user to assign too.
   * @param comments              The comments to go with the state change.
   */
  extractStateWIP(extractId: number, userId: number, comments: string): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/library/client/' + this.getClientId() + '/extract/' + extractId + '/state/wip', { userId: userId }, this.getObjectString({ comments: comments }))
  }

  /**
   * This function change the state of an extract to complete.
   *
   * @param extractId             The id of the extract we're working with.
   * @param comments              The comments to go with the state change.
   */
  extractStateComplete(extractId: number, comments: string): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/library/client/' + this.getClientId() + '/extract/' + extractId + '/state/complete', {}, this.getObjectString({ comments: comments }))
  }

  /**
   * This function change the state of an extract to archived.
   *
   * @param extractId             The id of the extract we're working with.
   * @param comments              The comments to go with the state change.
   */
  extractStateArchive(extractId: number, comments: string): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/library/client/' + this.getClientId() + '/extract/' + extractId + '/state/archive', {}, this.getObjectString({ comments: comments }))
  }

  /**
   * This function will obtain the completeness of product information for a give extract.
   */
  extractProductPropertyCompleteness(extractId: number, pagination: PaginationIdBreakdownVO, cancelSignal: Observable<any>): Observable<ProductPropertyCompletenessAHubVO> {

    //Create a new pagionation id request
    let paginationRequest: PaginationIdRequestVO = {
      paginationIdBreakdown: pagination,
      paginationDirection: "asc"
    }

    //Get the pagination request
    return this.serviceUtil.requestGet('/library/client/' + this.getClientId() + '/extract/' + extractId + '/productPropertyCompleteness', {}, paginationRequest, undefined, cancelSignal);
  }

  /**
   * Get the extract history index by the extract id
   *
   * @param extractId           The id of the extract we want the history for
   */
  extractHistoryIndexByExtractId(extractId: number): Observable<ExtractHistoryIndexAHubVO> {
    return this.serviceUtil.requestGet('/library/client/' + this.getClientId() + '/extract/' + extractId + '/historyIndex', {}).pipe(
      mergeMap((response: any): ExtractHistoryIndexAHubVO[] => response),
      map((extractHistoryIndex: ExtractHistoryIndexAHubVO) => {
        extractHistoryIndex.date = new Date(extractHistoryIndex.date);
        return extractHistoryIndex;
      }));
  }

  /**
   * Get the product source download URL
   *
   * @param extractId             Extract id for the history
   * @param extractHistoryId      The id of the extract history
   */
  extractHistoryProductSourceDownloadURL(extractId: number, extractHistoryId: number): Observable<PresignedUrlAHubVO> {
    return this.serviceUtil.requestGet('/library/client/' + this.getClientId() + '/extract/' + extractId + '/history/' + extractHistoryId + '/product/source/url', {})
  }

  /**
   * Gets the products for a given extract.
   *
   * @param extractId     The id of the extract we're working with.
   * @param pagesize      The size of the page
   */
  extractProductPaginationFetch(extractId: number, pagesize: number): Observable<PaginationIdBreakdownVO> {
    return this.serviceUtil.requestGet<PaginationIdBreakdownVO>('/library/client/' + this.getClientId() + '/extract/' + extractId + '/products/pagination', { pageSize: pagesize });
  }

  /**
   * Gets the products for a given extract.
   *
   * @param extractId             The id of the extract we're working with.
   */
  extractProductsFetch(extractId: number, cancelSignal?: Observable<any>): Observable<ProductAHubVO[]> {

    //Subscribe the extract product pagination fetch
    return this.extractProductPaginationFetch(extractId, 500).pipe(
      switchMap((pagination) => {

        //Limit the pagination of the data to a fixed size
        pagination = this.limitPaginationToAppDataLimit(pagination);

        //Create a new pagionation id request
        let paginationRequest: PaginationIdRequestVO = {
          paginationIdBreakdown: pagination,
          paginationDirection: "desc"
        }

        //Make the request with our new pagination data
        return this.serviceUtil.requestGet<ProductAHubVO[]>('/library/client/' + this.getClientId() + '/extract/' + extractId + '/product', {}, paginationRequest, RESPONSE_TYPE_JSON, cancelSignal)
      })
    );
  }

  /**
   * Gets the products for a given extract and product ids.
   *
   * @param productIds            The ids of the extract products.
   * @param extractId             The id of the extract we're working with.
   */
  extractProductsFetchByIds(extractId: number, productIds: number[]): Observable<ProductAHubVO[]> {
    return this.serviceUtil.requestIdBatch('/library/client/' + this.getClientId() + '/extract/' + extractId + '/products', productIds, 'GET', 100);
  }

  /**
   * This function make a request to start the creation of a product extract document.
   *
   * @param extractId           The id of the extract we want the products from.
   * @param documentType        The type of document we want.
   */
  extractProductDocumentCreate(extractId: number, documentType: DocumentProductFileTypeAHubEnum): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/library/client/' + this.getClientId() + '/extract/' + extractId + '/product/document/' + documentType + '/create');
  }

  /**
   * This function will make the call to upload extract products from a file to the specified extract id. This will require uploads, and completion
   */
  extractProductsUpload(extractId: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/library/client/' + this.getClientId() + '/extract/' + extractId + '/product/upload')
  }

  /**
   * This function will make the call to commit an extract product value object to the specified extract id. This will not require an upload
   */
  extractProductsCommitObjectsOnly(extractId: number, extractProducts: ProductAHubVO[]): Observable<RequestTicketAHubVO> {

    //NOTE: we have set the "useAHubId" to true. This will mean that all product commits will expect the aHub product id rather than using any custom defined id's.
    //      I think this is ok because within the portal we are starting off with getting the data from the aHub (which has the aHub id). Until this needs to be optional
    //      we will specify that we have to supply the aHub Id

    return this.serviceUtil.requestPut('/library/client/' + this.getClientId() + '/extract/' + extractId + '/product/data/json', { useAHubId: true }, this.getObjectString(extractProducts))
  }

  /**
   * This function will make the call to commit an extract product value object to the specified extract id. This will require uploads, and completion
   */
  extractProductsCommitObjectsAndAssets(extractId: number, extractProducts: ProductAHubVO[], trimThreshold = 3): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/library/client/' + this.getClientId() + '/extract/' + extractId + '/product/json', { useAHubId: true, trimThreshold }, this.getObjectString(extractProducts))
  }

  /**
   * This function will make the call to delete a list of products by ids.
   *
   * @param extractId             The id of the extract we're working with.
   * @param extractProductIds     The list of product id's to delete.
   */
  extractProductsDelete(extractId: number, extractProductIds: number[]): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestDelete('/library/client/' + this.getClientId() + '/extract/' + extractId + '/product', { id: extractProductIds })
  }

  /**
   * Call to match the files supplied with the properties of the asset matcher for a given extract
   *
   * @param extractId       The id of the extract which we want to match the files too
   * @param files           Files which we want to match
   */
  extractProductAssetMatch(extractId: number, files: string[]): Observable<ExtractAssetMatchAHubVO> {
    return this.serviceUtil.requestPut('/library/client/' + this.getClientId() + '/extract/' + extractId + '/product/asset/match', {}, JSON.stringify(files))
  }

  /**
   * This function will make the call to begin the delete of an extract product asset object to the specified extract product.
   */
  extractProductSectionPropertyAssetDelete(extractId: number, productId: number, sectionId: number, propertyId: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestDelete('/library/client/' + this.getClientId() + '/extract/' + extractId + '/product/' + productId + '/section/' + sectionId + '/property/' + propertyId + '/asset', {})
  }

  /**
   * This function will make the call to fetch a list of product asset urls by ids.
   *
   * @param extractId             The id of the extract we're working with.
   * @param extractProductIds     The list of product id's to delete.
   */
  extractProductAssetUrlsByExtractAndProductIds(extractId: number, extractProductIds: number[]): Observable<ProductAssetAHubVO> {
    return this.serviceUtil.requestIdBatch('/library/client/' + this.getClientId() + '/extract/' + extractId + '/assets/product', extractProductIds, 'GET', 100)
  }

  /**
   * Call to match the files supplied with the properties of the asset matcher for a given extract
   *
   * @param extractId       The id of the extract which we want to match the files too
   * @param files           Files which we want to match
   */
  extractProductAssetZipGenerate(extractId: number, assetId: number, assetLocation: string): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/library/client/' + this.getClientId() + '/extract/' + extractId + '/product/asset/' + assetId + '/action/zip', { assetLocation: assetLocation });
  }

  /**
   * This function will make the call to commit an extract product value object to the specified extract id.
   */
  extractProductAssetsSwap(extractId: number, assetsToSwap: ProductAssetViewVO[]): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/library/client/' + this.getClientId() + '/extract/' + extractId + '/product/assets/swap', {}, this.getObjectString(assetsToSwap))
  }

  /**
   * This function will make a request to start the upgrading of a clients library.
   * Very similar to the clientLibraryUse method, except the library picked is upgraded
   * before being put to use.
   *
   * @param clientId            The id of the client who's library we want to upgrade.
   * @param libraryId                     The id of the library to use.
   * @param libraryReplacementId        Optional ID of the library to put in place of the one being made live.
   */
  clientLibraryUpgradeAndUse(clientId: number, libraryUseId: number, libraryReplacementId?: number): Observable<RequestTicketAHubVO> {
    if (libraryReplacementId)
      return this.serviceUtil.requestPut('/system/client/' + clientId + '/clientLibrary/' + libraryUseId + '/action/upgradeAndUse', { libraryReplacementId: libraryReplacementId });
    else
      return this.serviceUtil.requestPut('/system/client/' + clientId + '/clientLibrary/' + libraryUseId + '/action/upgradeAndUse', {});
  }

  /**
   * This function will make the request to start the creation of a new client library.
   *
   * @param clientId               The id of the client we are adding the library too.
   */
  clientLibraryAdd(clientId: number, clientStorageId: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPost('/system/client/' + clientId + '/clientLibrary', { clientStorageId: clientStorageId })
  }

  /**
   * This function will make the request to delete the clients library.
   *
   * @param clientId                  The id of the client that owns the library to delete.
   * @param libraryId                 The id of the library to delete.
   */
  clientLibraryDelete(clientId: number, libraryId: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestDelete('/system/client/' + clientId + '/clientLibrary/' + libraryId, {})
  }

  /**
   * This function will make a request make a client library the live library.
   * The current live library will be disconnected and be left as a spare.
   * The selected library will be made live.
   * An additional library can be selected to relace the position of the library being
   * premoted.
   *
   * @param clientId                      The id of the client we are working with.
   * @param libraryId                     The id of the library to use.
   * @param libraryReplacementId        Optional ID of the library to put in place of the one being made live.
   */
  clientLibraryUse(clientId: number, libraryUseId: number, libraryReplacementId?: number): Observable<RequestTicketAHubVO> {
    if (libraryReplacementId)
      return this.serviceUtil.requestPut('/system/client/' + clientId + '/clientLibrary/' + libraryUseId + '/action/use', { libraryReplacementId: libraryReplacementId });
    else
      return this.serviceUtil.requestPut('/system/client/' + clientId + '/clientLibrary/' + libraryUseId + '/action/use', {});
  }

  /**
  * This function will make a request to have a library sync with its source.
  * @param clientId                      The id of the client we are working with.
  * @param libraryId                     The id of the library to use.
  */
  clientLibrarySync(clientId: number, libraryId: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/system/client/' + clientId + '/clientLibrary/' + libraryId + '/action/sync', {})
  }

  /**
  * This function will make a request to have a library sync with another library
  * @param clientId                      The id of the client we are working with.
  * @param libraryId                     The id of the library to use.
  * @param librarySourceId                The id of the source library to sync from.
  */
  clientLibrarySyncFrom(clientId: number, libraryId: number, librarySourceId: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/system/client/' + clientId + '/clientLibrary/' + libraryId + '/action/sync', { librarySourceId: librarySourceId })
  }

  /**
   * This function will make a request to create a snapshot of a client library.
   *
   * @param clientId                      The id of the client we are working with.
   * @param libraryId                     The id of the library to take a snapshot of.
   */
  clientLibrarySnapShot(clientId: number, libraryId: number, clientStorageId: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/system/client/' + clientId + '/clientLibrary/' + libraryId + '/action/snapshot', { clientStorageId: clientStorageId })
  }

  /**
  * This function will make a request to make changes to a client library via its index object.
  * @param clientId                      The id of the client we are working with.
  * @param clientLibrary                  The client library object to update.
  */
  clientLibraryCommit(clientId: number, clientLibrary: ClientLibraryAHubVO): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/system/client/' + clientId + '/clientLibrary', {}, this.getObjectString(clientLibrary))
  }

  /**
   * This function will make a request to get all of the resource pack indexes.
   */
  resourcePackIndexes(): Observable<ResourcePackIndexAHubVO> {
    return this.serviceUtil.requestGet('/library/client/' + this.getClientId() + '/resourcePackIndex');
  }

  /**
   * Get a list of resource packs by the id's.
   *
   * @param resourcePackIds           The id's of the resource packs we want.
   */
  resourcePacksById(resourcePackIds: number[]): Observable<ResourcePackAHubVO> {
    return this.serviceUtil.requestIdBatch('/library/client/' + this.getClientId() + '/resourcePack', resourcePackIds, 'GET', 100);
  }

  /**
   * Create a new resource pack on the aHub.
   *
   * @param resourcePack            The resource pack to send to the aHub.
   */
  resourcePackAdd(resourcePack: ResourcePackAHubVO): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPost('/library/client/' + this.getClientId() + '/resourcePack', {}, this.getObjectString(resourcePack));
  }

  /**
   * Commit resource pack changes to the aHub.
   *
   * @param resourcePack        The resource pack that has changed and is being committed.
   */
  resourcePackCommit(resourcePack: ResourcePackAHubVO): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/library/client/' + this.getClientId() + '/resourcePack/' + resourcePack.id, {}, this.getObjectString(resourcePack));
  }

  /**
   * Delete a resource pack from the aHub.
   *
   * @param resourcePackId            The id of the resource pack to delete.
   */
  resourcePackDelete(resourcePackId: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestDelete('/library/client/' + this.getClientId() + '/resourcePack/' + resourcePackId, {})
  }

  /**
   * Function to upload resource files to an existing resource pack
   *
   * @param resourcePackId    Id of the resource pack we want to place files in
   */
  resourcePackFilesUpload(resourcePackId: number): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/library/client/' + this.getClientId() + '/resourcePack/' + resourcePackId + '/files', {})
  }

  /**
   * Function to delete the files
   *
   * @param resourcePackId    Id of the resource pack we want to delete files from
   * @param fileKeys          Files keys which we want to delete
   */
  resourcePackFilesDelete(resourcePackId: number, fileKeys: string[]): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestDelete('/library/client/' + this.getClientId() + '/resourcePack/' + resourcePackId + '/files', { path: fileKeys })
  }

  /**
   * Function to add a folder (path) to the specified resource pack
   *
   * @param resourcePackId    Id of the resource pack we want to add a folder to
   * @param pathToNewFolder   Path of the folder we would like to add
   */
  resourcePackFolderAdd(resourcePackId: number, pathToNewFolder: string): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/library/client/' + this.getClientId() + '/resourcePack/' + resourcePackId + '/folder', { newFolderPath: pathToNewFolder })
  }

  /**
   * Function to add a folder (path) to the specified resource pack
   *
   * @param resourcePackId    Id of the resource pack we want to add a folder to
   * @param folderPath      Path of the folder we would like to rename
   * @param newFolderPath   renamed folder path
   */
  resourcePackFolderRename(resourcePackId: number, folderPath: string, newFolderPath: string): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/library/client/' + this.getClientId() + '/resourcePack/' + resourcePackId + '/folder/action/rename', { folderPath: folderPath, newFolderPath: newFolderPath })
  }

  /**
   * Function to download a zip of the specified resource pack files
   *
   * @param resourcePackId    Id of the resource pack we want to delete files from
   * @param resourcePackDir   Directory which we want zipped for download
   */
  resourcePackFilesZipDownload(resourcePackId: number, resourcePackDir: string): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/library/client/' + this.getClientId() + '/resourcePack/' + resourcePackId + '/files/action/download', { path: resourcePackDir })
  }

  /**
   * Function to download a specified resource pack file
   *
   * @param resourcePackId    Id of the resource pack we want to delete files from
   * @param resourcePackFileLocation   Directory which we want zipped for download
   */
  resourcePackFileDownload(resourcePackId: number, resourcePackFileLocation: string): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/library/client/' + this.getClientId() + '/resourcePack/' + resourcePackId + '/file/action/download', { path: resourcePackFileLocation })
  }

  /**
   * ----------------------------------------------------------------------------
   * WORK SERVICES
   * ----------------------------------------------------------------------------
   */

  workLogsAllByUserIdOrClientIdAfterEPOCH(afterDate: Date): Observable<WorklogSegmentAHubVO> {
    return this.serviceUtil.requestGet('/work/worklogSegment', { userId: this.getUserId(), clientId: this.getClientId(), epochTime: (afterDate != undefined) ? afterDate.valueOf() : 0 }).pipe(
      map((response: any): WorklogSegmentAHubVO => {

        // Get the raw segment data.
        let worklogSegment = response;

        // Get the work logs.
        let worklogs = worklogSegment.workLogs;

        // Do we have any work logs?
        if (worklogs) {

          // Yes, so lets create them and then correct the date properties.
          let newWorklogs: WorklogAHubVO[] = worklogs
            .map((data: any): WorklogAHubVO => data)
            .map(worklog => {

              let startTime = Date.parse(worklog.startTime);
              let loggedTime = Date.parse(worklog.loggedTime);
              let endTime = Date.parse(worklog.endTime);

              worklog.startTime = startTime ? new Date(startTime) : undefined;
              worklog.loggedTime = loggedTime ? new Date(loggedTime) : undefined;
              worklog.endTime = endTime ? new Date(endTime) : undefined;
              return worklog;
            })

          // Set the work logs in the segment.
          worklogSegment.workLogs = newWorklogs;
        }

        // Then return the segment.
        return worklogSegment;
      }
      ));
  }

  /**
  * This function will make the call to get the presigned url required to download files
  */
  workflowDownloadSignedUrlsGet(workflowId: string, filePath: string[]): Observable<PresignedUrlAHubVO[]> {
    return this.serviceUtil.requestGet('/work/workflow/' + workflowId + '/signedurl/download', { filePath: filePath })
  }

  /**
   * This function will make the call to get the presigned url required to upload files
   */
  workflowUploadSignedUrlsGet(workflowId: string, filePath: string[]): Observable<PresignedUrlAHubVO[]> {
    return this.serviceUtil.requestGet('/work/workflow/' + workflowId + '/signedurl/upload', { filePath: filePath })
  }

  /**
   * This function will make the call to continue the a workflow after its been waiting for failes to be uploaded for a user
   */
  workflowUploadComplete(workflowId: string): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPost('/work/workflow/' + workflowId, {})
  }

  /**
   * This function will make the call to cancel the workflow
   */
  workflowUploadCancel(workflowId: string): Observable<any> {
    return this.serviceUtil.requestDelete('/work/workflow/' + workflowId, {})
  }

  /**
   * Gets a list of the downloadable files for a given workflow
   */
  workflowDownloadFiles(workflowId: string): Observable<WorkflowDownloadFileAHubVO[]> {
    return this.serviceUtil.requestGet('/work/workflow/' + workflowId + '/download/files', {})
  }

  /**
   * ----------------------------------------------------------------------------
   * SYSTEM SERVICES
   * ----------------------------------------------------------------------------
   */

  /**
   * Turn On Maintanence Mode
   */
  maintenanceModeOn(): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/system/action/maintenanceMode/enable', {})
  }

  /**
   * Turn Off Maintanence Mode
   */
  maintenanceModeOff(): Observable<RequestTicketAHubVO> {
    return this.serviceUtil.requestPut('/system/action/maintenanceMode/disable', {})
  }

  /**
   * Get the system status for the current system
   */
  systemStatusFetch(): Observable<SystemStatusAHubVO> {
    return this.serviceUtil.requestGet('/system/systemStatus', {})
  }

  /**
   * ----------------------------------------------------------------------------
   * PRIVATE
   * ----------------------------------------------------------------------------
   */

  /**
   * Gets the client id currently set as default for all requests.
   */
  private getClientId(): number {

    //Get the session credentials for the currently signed in user
    let sessionVO: SessionAppVO = StoreAccess.dataGet(session);

    //If we have no session then we will return with a -1 client id
    if (sessionVO == undefined || sessionVO == null)
      return -1;

    // Check the id is set.
    if (sessionVO.clientId == null)
      return -1;

    //Return the client id
    return sessionVO.clientId;
  }

  /**
   * Gets the id of the user currently logged in this session.
   */
  private getUserId(): number {

    //Get the session credentials for the currently signed in user
    let sessionVO: SessionAppVO = StoreAccess.dataGet(session);

    //If we have no session then we will return with a -1 client id
    if (sessionVO == undefined || sessionVO == null)
      return -1;

    // Check the userId is set.
    if (sessionVO.userId == null)
      return -1;

    //Return the client id
    return sessionVO.userId;
  }

  /**
   * Gets the email of the user currenly logged in.
   */
  private getUserEmail(): string {

    //Get the session credentials for the currently signed in user
    let sessionVO: SessionAppVO = StoreAccess.dataGet(session);

    //If we have no session then we will return with a -1 client id
    if (sessionVO == undefined || sessionVO == null)
      return "";

    //Return the users email.
    return sessionVO.userSessionCredentials.userEmail;
  }

  /**
   * This function will convert an object into a string.
   */
  private getObjectString(object): string {
    return JSON.stringify(object);
  }

  /**
   * Limit the pagination to the current limit
   *
   * @param pagination    The pagination object which we want to limit
   */
  private limitPaginationToAppDataLimit(pagination: PaginationIdBreakdownVO) {

    //Get the item limit
    let limit: number = StoreAccess.dataGet(appDataItemLimit);

    //If we have no pagination or the pagination item count is less then the limit
    //we will spit it back to the caller.
    if (!pagination || pagination.itemCount <= limit) return pagination;

    //If we get here we have more data than the limit so we will alter the pagination object
    //so that we only get the first x data items. Due to the way pagination works we will not be able to do a hard cap
    //as we don't know what the x's item is but we will get close.

    //New start array of the start it's
    let newStartIdArray: number[] = [];
    let currentStartIdList: number[] = pagination.pageStartIds;

    //Current item count
    let currentItemCount = 0;

    //Whilst we still want more items we will keep getting the items
    while (currentItemCount < limit) {

      //We still have space on our list so we will get the next item

      //We have no start id's bail out
      if (!currentStartIdList || currentStartIdList.length == 0) break;

      //Take the first item from the array and add it to the end of the
      //our new list this will make it one of the first items to get
      newStartIdArray.push(currentStartIdList.shift());

      //Increate the item count by the page size
      currentItemCount += pagination.paginationRecordCount;
    }

    //OK set we will now set the new start id's array which will take us somewhere near the limit
    pagination.pageStartIds = newStartIdArray;

    //Do we have any ramaining start id's if so we need to reset the last id
    //to the next one in the start or we will have an issues with the last request
    //requesting too much data
    if (currentStartIdList.length > 0)
      pagination.lastId = currentStartIdList[0] - 1;

    //Return the pagination
    return pagination;
  }
}

import { Pipe, PipeTransform } from '@angular/core';

/**
 * Simple pipe to change a gender value into a string.
 */
@Pipe({
    name: 'genderToString'
})
export class GenderToStringPipe implements PipeTransform {

    transform(value: string, args?: any): any {

        // Return value.
        let returnValue: string;

        // What value did we get passed in?
        switch (value) {
            case "M":

                // Change to Mens.
                returnValue = "Mens";
                break;

            case "W":

                // Change it to Womens.
                returnValue = "Womens";
                break;

            case "U":

                // Change it to Unisex.
                returnValue = "Unisex";
                break;

            case "B":

                // Change it to Boys.
                returnValue = "Boys";
                break;

            case "G":

                // Change it to Girls.
                returnValue = "Girls";
                break;

            case "Y":

                // Change it to Youth.
                returnValue = "Youth";
                break;

            case "C":

                // Change it to Child.
                returnValue = "Child";
                break;

            case "I":

                // Change it to Infant.
                returnValue = "Infant";
                break;

            default:

                // Otherwise use the one passed in.
                returnValue = value
                break;
        }

        // Now return the value.
        return returnValue;
    }
}
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ProductClassRuleAHubVO } from 'valueObjects/ahub/library/product-class-rule.ahub.vo';

@Component({
  selector: 'app-product-class-rule',
  templateUrl: './product-class-rule.component.html',
  styleUrls: ['./product-class-rule.component.css']
})
export class ProductClassRuleComponent implements OnInit {

  /**
   * This is the product class rule to display.
   */
  @Input()
  productClassRule: ProductClassRuleAHubVO;

  /**
   * This is the index of the product class rule.
   */
  @Input()
  productClassRuleIndex: number;

  /**
   * Are we allowing editing?
   */
  @Input()
  allowEditing: boolean;

  /**
   * This is the event that is dispatched when the user requests an edit.
   */
  @Output()
  editProductClassRule: EventEmitter<number> = new EventEmitter<number>();

  /**
   * This is the event that is dispatched when the user requests a delete.
   */
  @Output()
  deleteProductClassRule: EventEmitter<any> = new EventEmitter<any>();


  constructor() {
    // This is intentional
  }

  ngOnInit() {
  }

  /**
   * This handler is called when the user clicks on the edit button.
   */
  editClickHandler() {

    // Call the edit product class rule function.
    this.editProductClassRule.emit(this.productClassRuleIndex);
  }

  /**
   * This handler is called when the user clicks on the delete button.
   */
  deleteClickHandler() {

    // Call the delete product class rule function.
    this.deleteProductClassRule.emit(this.productClassRuleIndex);
  }
}

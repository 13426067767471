/**
 * Action Types
 */
import { ActionNumber } from 'actions/types/common.action-types';
/**
 * Actions
 */
import { ViewActions } from 'actions/view.actions';
import { tassign } from 'modules/common/type-assign.util';
import { createReducer } from 'reducers/reducer-util';
import { Reducer } from 'redux';

/**
 * ----------------------------------
 * App State interface
 * ----------------------------------
 */
export interface ViewContentViewerState {
    numberOfItemsPerRow: number;
    infinityScrollerCurrentViewStart: number;
}

/**
 * ----------------------------------
 * Initial State
 * ----------------------------------
 */
export const ViewContentViewerInitialState: ViewContentViewerState = {
    numberOfItemsPerRow: null,
    infinityScrollerCurrentViewStart: null
};


/**
 * ----------------------------------
 * View State Reducer
 * ----------------------------------
 */

/**
 * Set the number of items per row.
 */
export const gridViewItemsPerRowSet = (state: ViewContentViewerState, action: ActionNumber) => {

    return tassign(state, { numberOfItemsPerRow: action.number })
};

/**
 * Set the position of the infinity scroller view start.
 */
export const infinityScrollerCurrentViewStartSet = (state: ViewContentViewerState, action: ActionNumber) => {

    return tassign(state, { infinityScrollerCurrentViewStart: action.number })
};

/**
 * ----------------------------------
 * Reducers Mapping
 * ----------------------------------
 */

/**
 * Reducers handlers object ... match actions to the handler functions
 */
const reducerHandlers = {};

/**
 * Map the actions to the reducer functions this will allow us to
 */
reducerHandlers[ViewActions.GRID_VIEW_ITEMS_PER_ROW_SET] = gridViewItemsPerRowSet;
reducerHandlers[ViewActions.INFINITY_SCROLLER_CURRENT_VIEW_START] = infinityScrollerCurrentViewStartSet;

/**
 * Create a reducers based on the reducers handlers
 */
export const ViewContentViewerStateReducer: Reducer<ViewContentViewerState> = createReducer(ViewContentViewerInitialState, reducerHandlers);

/**
 * Check if this reducers can handel the function specified
 */
export const ViewContentViewerStateHasHandler = (actionType: string): boolean => reducerHandlers.hasOwnProperty(actionType);

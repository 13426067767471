import { Action, Reducer } from 'redux';
import { createReducer } from 'reducers/reducer-util';
import { tassign } from 'modules/common/type-assign.util';

/**
 * Action Types
 */
import {
  ActionNumber
} from 'actions/types/common.action-types'

import {
  ActionProductClassIndexAHubVOs, ActionProductPropertyAllocationIndexAHubVOs, ActionProductPropertyIndexAHubVOs
} from 'actions/types/library.action-types'

/**
 * Actions
 */
import { ViewActions } from 'actions/view.actions';

/**
 * Value objects
 */
import { ProductClassIndexAHubVO } from 'valueObjects/ahub/library/product-class-index.ahub.vo';
import { ProductPropertyAllocationAHubVO } from 'app/valueObjects/ahub/library/product-property-allocation.ahub.vo';
import { ProductPropertyIndexAHubVO } from 'app/valueObjects/ahub/library/product-property-index.ahub.vo';


/**
 * ----------------------------------
 * View State interface
 * ----------------------------------
 */
export interface ViewLibraryClassificationState {
  productSectionSelectedId: number;
  propertySelectedId: number;
  productClassesSelected: ProductClassIndexAHubVO[];
  propertyIconMap: {};
  productSelectedSectionPropertyId: number;
  allocIndexes: ProductPropertyIndexAHubVO[];
};

const propertyIconMap = {
  WEIGHT: 'fitness_center',
  BOOLEAN: 'check_box',
  DECIMAL: 'linear_scale',
  GENDER: 'wc',
  HTML: 'code',
  INTEGER: 'exposure_zero',
  IMAGE: 'image',
  VIDEO: 'videocam',
  FLICKBOOK: 'collections',
  PARAGRAPH: 'format_align_left',
  PDF: 'picture_as_pdf',
  RGB: 'palette',
  TABLE: 'table_chart',
  TEXTBLOCK: 'format_align_left',
  TEXTLINE: 'short_text',
  TEXTLIST: 'list',
  TOKEN: 'label_outline',
  TOKENLIST: 'view_headline',
  WORD: 'font_download',
  VOLUME: 'local_drink',
  MEASUREMENT: 'straighten',
  BLOB: 'redeem'
}

/**
 * ----------------------------------
 * Initial State
 * ----------------------------------
 */
export const ViewLibraryClassificationInitialState: ViewLibraryClassificationState = {
  productSectionSelectedId: -1,
  propertySelectedId: -1,
  productClassesSelected: [],
  propertyIconMap: propertyIconMap,
  productSelectedSectionPropertyId: -1,
  allocIndexes: null
};

/**
 * ----------------------------------
 * View State Reducer
 * ----------------------------------
 */

/**
 * Set the product selected id set
 */
export const productSectionSelectedIdSet = (state: ViewLibraryClassificationState, action: ActionNumber) => {
  return tassign(state, { productSectionSelectedId: action.number });
}

/**
 * Set the property selected id set
 */
export const propertySelectedIdSet = (state: ViewLibraryClassificationState, action: ActionNumber) => {
  return tassign(state, { propertySelectedId: action.number });
}

/**
 * Set the selected classes
 */
export const productClassesSelectedSet = (state: ViewLibraryClassificationState, action: ActionProductClassIndexAHubVOs) => {
  return tassign(state, { productClassesSelected: action.productClassIndexs });
}

/**
 * Set the selected product section property id.
 */
export const productSelectedSectionPropertyIdSet = (state: ViewLibraryClassificationState, action: ActionNumber) => {
  return tassign(state, { productSelectedSectionPropertyId: action.number });
}

/**
 * Set alloc indexes for the view
 */
export const productPropertyIndexesSet = (state: ViewLibraryClassificationState, action: ActionProductPropertyIndexAHubVOs) => {
  return tassign(state, { allocIndexes: action.productPropertyIndexes });
}


/**
 * ----------------------------------
 * Reducers Mapping
 * ----------------------------------
 */

/**
 * Reducers handlers object ... match actions to the handler functions
 */
let reducerHandlers = {};

/**
 * Map the actions to the reducer functions this will allow us to call the reducer
 */
reducerHandlers[ViewActions.LIBRARY_CLASSIFICATION_PRODUCT_SECTION_ID_SET] = productSectionSelectedIdSet
reducerHandlers[ViewActions.PROPERTY_SELECTED_ID_SET] = propertySelectedIdSet
reducerHandlers[ViewActions.LIBRARY_CLASSIFICATION_PRODUCT_CLASSES_SELECTED_SET] = productClassesSelectedSet;
reducerHandlers[ViewActions.PRODUCT_SELECTED_SECTION_PROPERTY_ID_SET] = productSelectedSectionPropertyIdSet;
reducerHandlers[ViewActions.LIBRARY_CLASSIFICATION_PRODUCT_PROPERTY_INDEXES_SET] = productPropertyIndexesSet


/**
 * Create a reducers based on the reducers handlers
 */
export const ViewLibraryClassificationStateReducer: Reducer<ViewLibraryClassificationState> = createReducer(ViewLibraryClassificationInitialState, reducerHandlers);

/**
 * Check if this reducers can handel the function specified
 */
export const ViewLibraryClassificationStateHasHandler = (actionType: string): boolean => reducerHandlers.hasOwnProperty(actionType);

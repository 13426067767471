
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AHubActions } from 'actions/ahub.actions';
/**
 * Utilities.
 */
import { ListUtil } from 'app/store/list.util';
import { aHubStatePermanentExportTypes } from 'app/store/selector/ahub/ahub-permanent.selector';
import { ExportTypeCodeAHubEnum, exportTypeIconPathGet } from 'app/valueObjects/ahub/accounts/export-type-code.ahub.enum';
import { componentDestroyStream, Hark } from 'modules/common/hark.decorator';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, publishReplay, refCount, takeUntil } from 'rxjs/operators';
/**
 * Selectors.
 */
import { aHubStateTemporaryExportList } from 'selector/ahub/ahub-temporary.selector';
/**
 * Store access and actions.
 */
import { StoreAccess } from 'store/store-access';
/**
 * Value objects.
 */
import { ExportDistributionAHubVO } from 'valueObjects/ahub/accounts/export-distribution.ahub.vo';

@Component({
  selector: 'app-user-export-distribution',
  templateUrl: './user-export-distribution.component.html',
  styleUrls: ['./user-export-distribution.component.css']
})
@Hark()
export class UserExportDistributionComponent implements OnInit, OnDestroy {

  /**
   * The export distribution that has been selected to view.
   */
  @Input()
  exportDistribution$: Observable<ExportDistributionAHubVO>;

  /**
   * Does the current export distribution have versions.
   */
  exportDistributionHasVersions$: Observable<boolean>;

  /**
   * The path to the export type icon.
   */
  exportTypeIconPath$: Observable<string>;

  /**
   * The distributions export type.
   */
  distributionExportType$: Observable<ExportTypeCodeAHubEnum>;

  /**
   * Is the current distribution export type code a legacy aWorkbook.
   */
  distributionExportTypeIsAWLegacy$: Observable<boolean>;

  /**
   * Is the current distribution export type code a JSON aWorkbook.
   */
  distributionExportTypeIsAWJSON$: Observable<boolean>;



  constructor() {
    // This is intentional
  }

  ngOnInit() {
    this.exportDistribution$.pipe(
      takeUntil(componentDestroyStream(this)),
      filter(exportDistribution => exportDistribution != undefined))
      .subscribe(exportDistribution => {

        // Make a request for the aHub export value object.
        StoreAccess.dispatch(AHubActions.exportsByIdFetch([exportDistribution.exportId]));
      });

    // Does the current export distribution have any versions available to this user?
    this.exportDistributionHasVersions$ = this.exportDistribution$
      .pipe(
        takeUntil(componentDestroyStream(this)),
        map(exportDistribution =>
          exportDistribution !== undefined &&
            exportDistribution.distributionExportVersions &&
            exportDistribution.distributionExportVersions.length ?
            exportDistribution.distributionExportVersions.length > 0 :
            false)
      );

    // Make a call for the export types.
    StoreAccess.dispatch(AHubActions.exportTypesAllFetch());

    // Set up the distribution export type observable.
    this.distributionExportType$ = combineLatest(
      this.exportDistribution$
        .pipe(
          filter(distribution => distribution != null),
          map(distribution => distribution.exportId)
        ),
      StoreAccess.dataGetObvs(aHubStatePermanentExportTypes),
      StoreAccess.dataGetObvs(aHubStateTemporaryExportList),
      (exportId, exportTypes, exportList) => {

        // Get the export base on the id.
        let exportAHubVO = ListUtil.listDataItemGet(exportList, exportId);

        // Get the export type.
        let exportType = (exportAHubVO) ? exportTypes.find(exportType => exportType.id == exportAHubVO.exportTypeId) : null;

        // Now return the export type.
        return (exportType) ? exportType.code : null;
      })
      .pipe(
        publishReplay(1),
        refCount()
      );

    // Get the path to the export type based on this export.
    this.exportTypeIconPath$ = this.distributionExportType$
      .pipe(
        map(exportTypeCode => exportTypeIconPathGet(exportTypeCode))
      );

    // Set up the is distribution export type a legacy aWorkbook observable.
    this.distributionExportTypeIsAWLegacy$ = this.distributionExportType$.pipe(map(typeCode => typeCode == ExportTypeCodeAHubEnum.AWORKBOOK_CATALOGUE));

    // Set up the is distribution export type a JSON aWorkbook observable.
    this.distributionExportTypeIsAWJSON$ = this.distributionExportType$.pipe(map(typeCode => typeCode == ExportTypeCodeAHubEnum.AWORKBOOK_CATALOGUE_JSON));
  }

  /**
   * Empty On destroy to ensure @Hark decorator works for an AOT build
   */
  ngOnDestroy() { }
}

/**
 * --------------------------------------------------
 * State Imports
 * --------------------------------------------------
 */

import { viewState } from 'selector/view/view.selector';
import { ViewPageState } from 'reducers/view/view-page.reducer';

/**
 * --------------------------------------------------
 * Value Object Imports
 * --------------------------------------------------
 */

import { ComponentStateViewVO } from 'valueObjects/view/component-state.view.vo';
import { FormStateViewVO } from 'valueObjects/view/form-state.view.vo';

/**
 * --------------------------------------------------
 * Core Selectors
 * --------------------------------------------------
 */

/**
 * Root selector to get the forms state
 */
const viewPageStates = (state): ViewPageState => viewState(state).page;

/**
 * --------------------------------------------------
 * Selectors
 * --------------------------------------------------
 */

/**
 * Get the list of the form states from the store form state
 */
export const viewFormStateFormStates = (state): FormStateViewVO[] => viewPageStates(state).formStates;

/**
 * Get the list of the form states which are dirty
 */
export const viewFormStateFormStatesDirty = (state): FormStateViewVO[] => viewFormStateFormStates(state).filter(f => !f.pristine);

/**
 * Get a list of the forms that are currently requesting a force refresh.
 */
export const viewFormStateFormStatesForceReset = (state): FormStateViewVO[] => viewFormStateFormStates(state).filter(form => form.forceReset);

/**
 * Get the list of the component states from the store form state
 */
export const viewComponenetStateFormStates = (state): ComponentStateViewVO[] => viewPageStates(state).componentStates;

/**
 * Get the list of the componenet states which are dirty
 */
export const viewComponentStateFormStatesDirty = (state): ComponentStateViewVO[] => viewComponenetStateFormStates(state).filter(f => !f.pristine);
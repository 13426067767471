import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pipeToOr'
})
export class PipeToOrPipe implements PipeTransform {

  transform(value: string, args?: any): any {
    if (value) {
      return value.replace(/\|/g, '<strong> OR </strong>');
    }
  }

}


import { takeUntil } from 'rxjs/operators';
import {
  Component,
  OnInit,
  Input,
  ComponentFactoryResolver,
  ComponentFactory,
  OnDestroy
} from '@angular/core';

import {
  FormGroup,
  FormControl
} from '@angular/forms';

import { Observable } from 'rxjs';

import { Hark, componentDestroyStream } from 'modules/common/hark.decorator';

import { DialogService } from 'modules/common/dialogs/dialog.service';
import { DistributionRulesDialogComponent, DistributionRulesDialogComponentConfig } from 'modules/common/dialogs/distribution-rules-dialog/distribution-rules-dialog.component';

@Component({
  selector: 'app-distribution-rules-form-chips',
  templateUrl: './distribution-rules-form-chips.component.html',
  styleUrls: ['./distribution-rules-form-chips.component.css']
})
@Hark()
export class DistributionRulesFormChipsComponent implements OnInit, OnDestroy {

  /**
   * Title to be displayed
   */
  @Input() titleText: string;

  /**
   * Form group which contains the list of items we wish to display
   */
  @Input() additiveFilterControl: FormControl;

  /**
   * Form group which contains the list of items we wish to display
   */
  @Input() form: FormGroup;

  /**
   * Are we showing the add item input? No by default
   */
  @Input() itemAddInputShow: boolean = false;

  /**
   *  Hide AND, EMPTY and NOT buttons if idalog should be simple
   */
  @Input() simple: boolean = false;

  @Input() theme: string;

  /**
   * Value of the item add input
   */
  itemAddInputValue: string = '';

  // Get the copmponent Factory we will use to create the component dialog
  private distributionFilterDialogComponentFactory: ComponentFactory<DistributionRulesDialogComponent> = undefined;

  constructor(private dialogService: DialogService, private resolver: ComponentFactoryResolver) { }


  /**
   * Empty On Init to ensure @Hark decorator works for an AOT build
   */
  ngOnInit() {

    //Setup the component factory for the dialogue box
    this.distributionFilterDialogComponentFactory = this.resolver.resolveComponentFactory(DistributionRulesDialogComponent);

    // Initialise with empty array if this.listControlForm.value is null or undefined
    this.newListData(this.additiveFilterControl.value);
  }

  /**
   * Empty On destroy to ensure @Hark decorator works for an AOT build
   */
  ngOnDestroy() { }

  /**
   * The new list data has been set so we will re-initalise our list
   */
  newListData(dataList) {

    //If the data is missing we will default our form control to an empty array
    if (!dataList)
      this.additiveFilterControl.setValue([]);
  }

  /**
   * Remove the item at the specified index from the list
   */
  itemRemove(index: number, filterControl: FormControl) {

    //Get the item array
    let itemArray: any[] = this.itemListFromControl(filterControl);

    //If the index is off either bounds then we cannot remove the item
    if (index < 0 || itemArray == undefined || index >= itemArray.length)
      return;

    //Remove the item from the item array
    itemArray.splice(index, 1);

    //Mark the form as dirty
    filterControl.markAsDirty();

    //Set the altered list of values in the form control
    filterControl.setValue(itemArray);
  }

  /**
   * Return the item list from the form control
   */
  itemListFromControl(filterControl: FormControl): any[] {

    //If the list form control is undefined then we will return an empty list
    if (filterControl == undefined)
      return [];

    //Return the item list
    return (<any[]>filterControl.value);
  }

  /**
   * Open the distribution filter dialog
   */
  openDistributionFilterDialog() {

    //Create a configuration so that we can configure the form to look how we want it to
    let config: DistributionRulesDialogComponentConfig = {
      listFormControl: this.additiveFilterControl,
      simpleDistribution: this.simple,
    }

    // Open a dialog for creating new filters.
    let dialogRef: Observable<any> = this.dialogService.componentDialogOpen(
      this.titleText,
      this.distributionFilterDialogComponentFactory,
      undefined, {},
      '',
      'OK', undefined,
      'config',
      config);

    // Listen for the users selection.
    dialogRef.pipe(takeUntil(componentDestroyStream(this))).subscribe(result => {

      // Did the user select any data? If not, then stop here.
      if (result === undefined)
        return;
    });
  }
}
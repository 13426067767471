import { Action, Reducer } from 'redux';

import {
    createReducer,
    arrayAppend
} from 'reducers/reducer-util';

import { tassign } from 'modules/common/type-assign.util';

/**
 * Action Types
 */
import {
    ActionNumber,
    ActionBoolean,
    ActionString,
    ActionStringArray
} from 'actions/types/common.action-types'

import {
    ActionFormState,
    ActionComponentStateViewVO
} from 'actions/types/view.action-types'

/**
 * Actions
 */
import { ViewActions } from 'actions/view.actions';

/**
 * Value Objects
 */
import { ComponentStateViewVO } from 'valueObjects/view/component-state.view.vo';
import { FormStateViewVO } from 'valueObjects/view/form-state.view.vo';

/**
 * ----------------------------------
 * App State interface
 * ----------------------------------
 */
export interface ViewPageState {
    componentStates: ComponentStateViewVO[]
    formStates: FormStateViewVO[]
};

/**
 * ----------------------------------
 * Initial State
 * ----------------------------------
 */
export const ViewPageInitialState: ViewPageState = {
    componentStates: [],
    formStates: []
};

/**
 * ----------------------------------
 * View State Reducer
 * ----------------------------------
 */

/**
 * Set the form state for the form specified
 */
export const formStateSet = (state: ViewPageState, action: ActionFormState) => {

    //Append the form to the existing form states
    return tassign(state, { formStates: arrayAppend(state.formStates, [action.formState], (a, b) => (a.formId == b.formId)) });
}

/**
 * Set a set of forms force reset value to true.
 *
 * @param state         The state to work with.
 * @param action        The action containg the id string array.
 */
export const formStateForceResetByIds = (state: ViewPageState, action: ActionStringArray) => {

    // Look for all of the forms with the matching id and set the force state to true.
    return tassign(state, {
        formStates: state.formStates.map(form => {

            // Does the id exist on the action list? If so, set force reset to true.
            if (action.strings.indexOf(form.formId) > -1)
                form.forceReset = true;

            // Then return the form to be added to the retuned list.
            return form;
        })
    });

}

/**
 * Removes the form state by the Id specified
 */
export const formStateRemoveById = (state: ViewPageState, action: ActionString) => {

    //Append the form to the existing form states
    return tassign(state, { formStates: state.formStates.filter((a) => (a.formId != action.string)) });
}

/**
 * Set the component state for the componenet specified
 */
export const componentStateSet = (state: ViewPageState, action: ActionComponentStateViewVO) => {

    //Append the form to the existing component states
    return tassign(state, { componentStates: arrayAppend(state.componentStates, [action.componentState], (a, b) => (a.componentId == b.componentId)) });
}

/**
 * Removes the componenet state by the id specified
 */
export const componentStateRemoveById = (state: ViewPageState, action: ActionString) => {

    //Append the form to the existing form states
    return tassign(state, { componentStates: state.componentStates.filter((a) => (a.componentId != action.string)) });
}

/**
 * ----------------------------------
 * Reducers Mapping
 * ----------------------------------
 */

/**
 * Reducers handlers object ... match actions to the handler functions
 */
let reducerHandlers = {};

/**
 * Map the actions to the reducer functions this will allow us to
 */
reducerHandlers[ViewActions.PAGE_FORM_STATE_SET] = formStateSet;
reducerHandlers[ViewActions.PAGE_FORM_STATE_FORCE_RESET_BY_IDS] = formStateForceResetByIds;
reducerHandlers[ViewActions.PAGE_FORM_STATE_REMOVE_BY_ID] = formStateRemoveById;
reducerHandlers[ViewActions.PAGE_COMPONENT_STATE_SET] = componentStateSet;
reducerHandlers[ViewActions.PAGE_COMPONENT_STATE_REMOVE_BY_ID] = componentStateRemoveById;

/**
 * Create a reducers based on the reducers handlers
 */
export const ViewPageStateReducer: Reducer<ViewPageState> = createReducer(ViewPageInitialState, reducerHandlers);

/**
 * Check if this reducers can handel the function specified
 */
export const ViewPageStateHasHandler = (actionType: string): boolean => reducerHandlers.hasOwnProperty(actionType);
import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.css']
})
export class LoadingSpinnerComponent implements OnInit {

  @Input() thingThatIsLoading$: Observable<any> = undefined;

  /**
   * This is the text that appears when there is not content
   */
  @Input() noContentText: string;
  /**
   * This is the text that appears when there is not content
   */
  @Input() searchTerm: string;
  /**
   * Index Count of unfiltered lists
   */
  @Input() indexCount: string;

  constructor() {
    // This is intentional
  }

  ngOnInit() {
  }

}

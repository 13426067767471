import { Reducer } from 'redux';
import { createReducer } from 'reducers/reducer-util';
import { tassign } from 'modules/common/type-assign.util';

/**
 * Action Types
 */
import {
  ActionIdItem, ActionNumberNumber, ActionStringNumber, ActionNumberNumberString, ActionStringNumberArray, ActionString, ActionBoolean
} from 'actions/types/common.action-types'

/**
 * Actions
 */
import { ActionWork } from 'actions/types/work.action-types';
import { AViewActions } from 'app/store/actions/aview.actions';
import { PresignedUrlAHubVO } from 'app/valueObjects/ahub/presigned-url.ahub.vo';
import { List } from '../list.vo';
import { IdItemVO } from '../id-item.vo';
import { IdListUtil } from '../id-list.util';
import {
  ActionAViewVO, ActionComponentPublicationEditionAssets, ActionLibraryViewConfigsAHubVO,
  ActionLibraryViewConfigByEditionAHubVO, ActionLibraryViewConfigMapStorageByPublicationIdAHubVO, ActionAViewProductFullDataVO
} from '../actions/types/aview.action-types';
import { AViewVO } from 'app/modules/routes/aview/valueObjects/aview.aview.vo';
import { MapStorage } from '../map-storage.vo';
import { ComponentsAViewVO } from 'app/modules/routes/aview/valueObjects/components.aview.vo';
import { MapStorageUtil } from '../map-storage.util';
import { Utils } from 'app/modules/common/utils';
import { ProductViewConfigAViewVO } from 'app/modules/routes/aview/valueObjects/product-view-config.aview.vo';

/**
 * ----------------------------------
 * App State interface
 * ----------------------------------
 */
export interface AViewState {
  publicationPathUrls: List<IdItemVO<PresignedUrlAHubVO>>;
  aViews: AViewVO[];
  componentMap: MapStorage<ComponentsAViewVO>;
  productViewConfigMapStorageByPublicationId: MapStorage<MapStorage<ProductViewConfigAViewVO>>
  productViewConfigByEdition: MapStorage<ProductViewConfigAViewVO>,
  displaySetting: string,
  globalSidebarStateActive: boolean;
};

/**
 * ----------------------------------
 * Initial State
 * ----------------------------------
 */
export const AViewInitialState: AViewState = {
  publicationPathUrls: IdListUtil.listCreateEmpty(),
  aViews: [],
  componentMap: MapStorageUtil.mapStorageCreate(),
  productViewConfigByEdition: MapStorageUtil.mapStorageCreate(),
  productViewConfigMapStorageByPublicationId: MapStorageUtil.mapStorageCreate(),
  displaySetting: null,
  globalSidebarStateActive: null
};

/**
 * ----------------------------------
 * View State Reducer
 * ----------------------------------
 */


/**
 * Store the path url of specified publication edition.
 */
const publicationEditionPathUrlSet = (state: AViewState, action: ActionIdItem<PresignedUrlAHubVO>) => {
  return tassign(state, {
    publicationPathUrls: IdListUtil.listAppend(state.publicationPathUrls, [action.idItem])
  });
}

/**
 * Store AView.
 */
const aViewSet = (state: AViewState, action: ActionAViewVO) => {

  // Look at each of the current aViews in the store.
  const newAViews: AViewVO[] = state.aViews.filter(aView => {

    // First thing to check is whether we are updating something already in the store.
    // If we are then we want to replace it.
    if (action.aView.publicationId === aView.publicationId && action.aView.edition === aView.edition) {
      return false;
    }

    // Now check to see if any of the current components are looking at it.
    // So return true if we find a component that wants/needs this aView.
    return state.componentMap.keys.find(componentId => {

      // Get each component.
      const component = aViewComponentById(state.componentMap, componentId);

      // Is this component looking at this aView? If so we want to return true. Otherwise return false
      // and then move onto the next component.
      return (component.selectedPublicationId === aView.publicationId && component.selectedEdition === aView.edition);
    });
  });

  // Now add the new aView to the list
  newAViews.push(action.aView);

  return tassign(state, {
    aViews: newAViews
  });
}

/**
 * Remove an AView.
 */
const aViewRemove = (state: AViewState, action: ActionNumberNumber) => {
  const newAViews: AViewVO[] = state.aViews.filter(aView => action.number1 !== aView.publicationId && action.number2 !== aView.edition);
  return tassign(state, {
    aViews: newAViews
  });
}


/**
 * Clear all of the aViews.
 */
const aViewClear = (state: AViewState, action: ActionWork) => {
  return tassign(state, AViewInitialState);
}

/**
* Set the data set category selected product id for the component specified
*/
const aViewComponentIdSet = (state: AViewState, action: ActionString) => {

  //Get the component id from the action
  const componentId = action.string;

  //Get the data set product item
  const aViewItem = aViewComponentByIdOrCreate(state.componentMap, componentId);

  //Set the data set item into this list
  return tassign(state, { componentMap: MapStorageUtil.mapStorageSet(state.componentMap, componentId, aViewItem) });
}

/**
* Delete component from component map by id
*/
const aViewComponentDelete = (state: AViewState, action: ActionString) => {

  //Get the component id from the action
  const componentId = action.string;

  const mapWithComponentRemoved = MapStorageUtil.mapStorageRemove(state.componentMap, componentId);

  //Set the data set item into this list
  return tassign(state, { componentMap: mapWithComponentRemoved });
}

/**
* Set the data set category id for the component specified
*/
const aViewComponentSelectedCategoryIdSet = (state: AViewState, action: ActionStringNumber) => {

  //Get the component id from the action
  const componentId = action.string;

  //Get the data set product item
  const aViewItem = aViewComponentById(state.componentMap, componentId);

  if (!aViewItem) {
    return tassign(state, { componentMap: state.componentMap });
  }

  //Set the data set id
  aViewItem.selectedCategoryId = action.number;

  //Set the data set item into this list
  return tassign(state, { componentMap: MapStorageUtil.mapStorageSet(state.componentMap, componentId, aViewItem) });
}

/**
* Set the data set category selected product id for the component specified
*/
const aViewComponentProductCountSet = (state: AViewState, action: ActionStringNumber) => {

  //Get the component id from the action
  const componentId = action.string;

  //Get the data set product item
  const aViewItem = aViewComponentById(state.componentMap, componentId);

  if (!aViewItem) {
    return tassign(state, { componentMap: state.componentMap });
  }

  //Set the data set id
  aViewItem.productCount = action.number;

  //Set the data set item into this list
  return tassign(state, { componentMap: MapStorageUtil.mapStorageSet(state.componentMap, componentId, aViewItem) });
}

/**
* Set the data set category selected product id for the component specified
*/
const aViewComponentSelectedProductIdSet = (state: AViewState, action: ActionStringNumber) => {

  //Get the component id from the action
  const componentId = action.string;

  //Get the data set product item
  const aViewItem = aViewComponentById(state.componentMap, componentId);

  if (!aViewItem) {
    return tassign(state, { componentMap: state.componentMap });
  }

  //Set the data set id
  aViewItem.selectedProductId = action.number;

  //Set the data set item into this list
  return tassign(state, { componentMap: MapStorageUtil.mapStorageSet(state.componentMap, componentId, aViewItem) });
}

/**
* Set the data set category selected product id for the component specified
*/
const aViewComponentSelectedProductViewDataSet = (state: AViewState, action: ActionAViewProductFullDataVO) => {

  //Get the component id from the action
  const componentId = action.componentId;

  //Get the data set product item
  const aViewItem = aViewComponentById(state.componentMap, componentId);

  if (!aViewItem) {
    return tassign(state, { componentMap: state.componentMap });
  }

  //Set the data set id
  aViewItem.selectedProductViewData = action.aViewProductFullData;

  //Set the data set item into this list
  return tassign(state, { componentMap: MapStorageUtil.mapStorageSet(state.componentMap, componentId, aViewItem) });
}

/**
* Set the assets for the component specified
*/
const aViewComponentAssetsSet = (state: AViewState, action: ActionComponentPublicationEditionAssets) => {

  //Get the component id from the action
  const componentId = action.componentId;

  //Get the component store object
  const aViewItem = aViewComponentById(state.componentMap, componentId);

  if (!aViewItem) {
    return tassign(state, { componentMap: state.componentMap });
  }

  // Stop here if the publication and edition have changed this the call was made.
  if (aViewItem.selectedPublicationId !== action.publicationId || aViewItem.selectedEdition !== action.edition) {
    return state;
  }

  //Set the content set id
  aViewItem.assets = action.assets;

  //Set the data set item into this list
  return tassign(state, { componentMap: MapStorageUtil.mapStorageSet(state.componentMap, componentId, aViewItem) });
}

/**
* Set the assets for the component specified
*/
const aViewComponentFilteredProductIdsSet = (state: AViewState, action: ActionStringNumberArray) => {

  //Get the component id from the action
  const componentId = action.string;

  //Get the component store object
  const aViewItem = aViewComponentById(state.componentMap, componentId);

  if (!aViewItem) {
    return tassign(state, { componentMap: state.componentMap });
  }

  //Set the filteredProductIds
  aViewItem.filteredProductIds = action.numbers;

  //Set the data set item into this list
  return tassign(state, { componentMap: MapStorageUtil.mapStorageSet(state.componentMap, componentId, aViewItem) });
}

/**
* Append the assets for the component specified
*/
const aViewComponentAssetsAppend = (state: AViewState, action: ActionComponentPublicationEditionAssets) => {

  //Get the component id from the action
  const componentId = action.componentId;

  //Get the component store object
  const aViewItem = aViewComponentById(state.componentMap, componentId);

  if (!aViewItem) {
    return tassign(state, { componentMap: state.componentMap });
  }

  // Stop here if the publication and edition have changed this the call was made.
  if (aViewItem.selectedPublicationId !== action.publicationId || aViewItem.selectedEdition !== action.edition) {
    return state;
  }

  //Convert new list of items into a set so we can easily tell what we want to remove
  //so when we filter the new items out we can look them up quickly
  const newAssetIdSet: Set<number> = new Set(action.assets.map(a => a.assetId));

  //Filter out all the new items from this list then we will replace them with the newly supplied versions of them
  const newAssetArray = aViewItem.assets.filter(asset => !newAssetIdSet.has(asset.assetId));

  //Add all our new assets array
  newAssetArray.push(...action.assets);

  aViewItem.assets = newAssetArray;

  //Set the data set item into this list
  return tassign(state, { componentMap: MapStorageUtil.mapStorageSet(state.componentMap, componentId, aViewItem) });
}

/**
 * Set the selected publication id and edition for a component id.
 *
 * @param state         The state to change.
 * @param action        The action to use to make the change.
 */
const aViewComponentSelectedPublicationEditionSet = (state: AViewState, action: ActionNumberNumberString) => {

  // Get the component id from the action.
  const componentId = action.string;

  const aViewComp: ComponentsAViewVO = {
    componentId,
    assets: [],
    productCount: 0,
    selectedCategoryId: -1,
    selectedProductId: -1,
    selectedPublicationId: action.number1,
    selectedEdition: action.number2,
    selectedProductViewData: undefined,
    filteredProductIds: undefined
  }

  // Set the data set item into this list.
  return tassign(state, { componentMap: MapStorageUtil.mapStorageSet(state.componentMap, componentId, aViewComp) });
}


/**
 * Get the object from the list with the id, if we don't have one we will create one
 *
 * @param map
 * @param id
 */
const aViewComponentById = (map: MapStorage<ComponentsAViewVO>, id: string): ComponentsAViewVO => {

  //Get the value from the map
  let valueFromMap = map ? MapStorageUtil.mapStorageGet(map, id) : undefined;

  if (!valueFromMap) {
    return;
  }

  valueFromMap = Utils.clone(valueFromMap); //Clone the object so we don't affct the orginal object

  //Return the value from the map
  return valueFromMap;
}

/**
 * Get the object from the list with the id, if we don't have one we will create one
 *
 * @param map
 * @param id
 */
const aViewComponentByIdOrCreate = (map: MapStorage<ComponentsAViewVO>, id: string): ComponentsAViewVO => {

  //Get the value from the map
  let valueFromMap = map ? MapStorageUtil.mapStorageGet(map, id) : undefined;

  // //No map
  if (!valueFromMap) {

    //Create a new object with default values set
    valueFromMap = {
      componentId: id,
      selectedCategoryId: -1,
      selectedProductId: -1,
      productCount: 0,
      assets: undefined,
      selectedPublicationId: -1,
      selectedEdition: -1,
      selectedProductViewData: undefined,
      filteredProductIds: undefined
    };
  }
  else {
    valueFromMap = Utils.clone(valueFromMap); //Clone the object so we don't affct the orginal object
  }

  //Return the value from the map
  return valueFromMap;
}

/**
 * Set the whole libraery view config.
 */
const productViewConfigSet = (state: AViewState, action: ActionLibraryViewConfigsAHubVO) => {
  return tassign(state, { productViewConfigMapStorageByPublicationId: action.productViewConfigs });
}

/**
 * Set the library view config by edition.
 */
const productViewConfigByEditionSet = (state: AViewState, action: ActionLibraryViewConfigByEditionAHubVO) => {
  return tassign(state, { productViewConfigByEdition: MapStorageUtil.mapStorageSet(state.productViewConfigByEdition, action.edition.toString(), action.productViewConfig) });
}

/**
 * Set the library view config by dataset id.
 */
const productViewConfigMapStorageByPublicationIdSet = (state: AViewState, action: ActionLibraryViewConfigMapStorageByPublicationIdAHubVO) => {
  return tassign(state, {
    productViewConfigMapStorageByPublicationId: MapStorageUtil.mapStorageSet(
      state.productViewConfigMapStorageByPublicationId,
      action.publicationId.toString(),
      action.productViewConfigMapStorage)
  });
}

/**
 * Set the extract products change set list.
 */
const productViewConfigClear = (state: AViewState, action: ActionWork) => {
  return tassign(state, { productViewConfigMapStorageByPublicationId: MapStorageUtil.mapStorageCreate() });
}

const aViewDisplaySettingSet = (state: AViewState, action: ActionString) => {
  return tassign(state, { displaySetting: action.string });
}

const globalSidebarStateActive = (state: AViewState, action: ActionBoolean) => {
  return tassign(state, { globalSidebarStateActive: action.boolean });
}



/**
 * ----------------------------------
 * Reducers Mapping
 * ----------------------------------
 */

/**
 * Reducers handlers object ... match actions to the handler functions
 */
const reducerHandlers = {};

/**
 * Map the actions to the reducer functions this will allow us to
 */
reducerHandlers[AViewActions.AVIEW_PUBLICATION_EDITION_PATH_URL_SET] = publicationEditionPathUrlSet;
reducerHandlers[AViewActions.AVIEW_SET] = aViewSet;
reducerHandlers[AViewActions.AVIEW_REMOVE] = aViewRemove;
reducerHandlers[AViewActions.AVIEW_CLEAR] = aViewClear;
reducerHandlers[AViewActions.AVIEW_COMPONENT_SELECTED_PRODUCT_ID_SET] = aViewComponentSelectedProductIdSet;
reducerHandlers[AViewActions.AVIEW_COMPONENT_SELECTED_PRODUCT_VIEW_DATA_SET] = aViewComponentSelectedProductViewDataSet;
reducerHandlers[AViewActions.AVIEW_COMPONENT_ID_SET] = aViewComponentIdSet;
reducerHandlers[AViewActions.AVIEW_COMPONENT_DELETE] = aViewComponentDelete;
reducerHandlers[AViewActions.AVIEW_COMPONENT_PRODUCT_COUNT_SET] = aViewComponentProductCountSet;
reducerHandlers[AViewActions.AVIEW_COMPONENT_SELECTED_CATEGORY_ID_SET] = aViewComponentSelectedCategoryIdSet;
reducerHandlers[AViewActions.AVIEW_COMPONENT_ASSETS_SET] = aViewComponentAssetsSet;
reducerHandlers[AViewActions.AVIEW_FILTERED_PRODUCT_IDS_SET] = aViewComponentFilteredProductIdsSet;
reducerHandlers[AViewActions.AVIEW_COMPONENT_ASSETS_APPEND] = aViewComponentAssetsAppend;
reducerHandlers[AViewActions.AVIEW_COMPONENT_PUBLICATION_EDITION_SET] = aViewComponentSelectedPublicationEditionSet;

reducerHandlers[AViewActions.AVIEW_DISPLAY_SETTING_SET] = aViewDisplaySettingSet;

reducerHandlers[AViewActions.AVIEW_GLOBAL_SIDEBAR_STATE_ACTIVE_SET] = globalSidebarStateActive;

reducerHandlers[AViewActions.AVIEW_PRODUCT_VIEW_CONFIG_SET] = productViewConfigSet;
reducerHandlers[AViewActions.AVIEW_PRODUCT_VIEW_CONFIG_BY_EDITION_SET] = productViewConfigByEditionSet;
reducerHandlers[AViewActions.AVIEW_PRODUCT_VIEW_CONFIGS_BY_PUBLICATION_ID_SET] = productViewConfigMapStorageByPublicationIdSet;
reducerHandlers[AViewActions.AVIEW_PRODUCT_VIEW_CONFIGS_CLEAR] = productViewConfigClear;
/**
 * Create a reducers based on the reducers handlers
 */
export const AViewReducer: Reducer<AViewState> = createReducer(AViewInitialState, reducerHandlers);

/**
 * Check if this reducers can handel the function specified
 */
export const AViewReducerHasHandler = (actionType: string): boolean => reducerHandlers.hasOwnProperty(actionType);

// valueObjects
import { EntityPermissionAHubVO } from 'valueObjects/ahub/accounts/entity-permission.ahub.vo';
import { EntityRefAHubEnum } from 'valueObjects/ahub/system/entity-ref.ahub.enum';

export class EntityPermissions {
    public static SYSTEM_NONE: EntityPermissionAHubVO = { entityRef: EntityRefAHubEnum.SYSTEM, permissionType: 'NONE' };
    public static SYSTEM_GUEST: EntityPermissionAHubVO = { entityRef: EntityRefAHubEnum.SYSTEM, permissionType: 'GUEST' };
    public static SYSTEM_USER: EntityPermissionAHubVO = { entityRef: EntityRefAHubEnum.SYSTEM, permissionType: 'USER' };
    public static SYSTEM_EDITOR: EntityPermissionAHubVO = { entityRef: EntityRefAHubEnum.SYSTEM, permissionType: 'EDITOR' };
    public static SYSTEM_ADMINISTRATOR: EntityPermissionAHubVO = { entityRef: EntityRefAHubEnum.SYSTEM, permissionType: 'ADMINISTRATOR' };
    public static ACCOUNTS_NONE: EntityPermissionAHubVO = { entityRef: EntityRefAHubEnum.ACCOUNTS, permissionType: 'NONE' };
    public static ACCOUNTS_GUEST: EntityPermissionAHubVO = { entityRef: EntityRefAHubEnum.ACCOUNTS, permissionType: 'GUEST' };
    public static ACCOUNTS_USER: EntityPermissionAHubVO = { entityRef: EntityRefAHubEnum.ACCOUNTS, permissionType: 'USER' };
    public static ACCOUNTS_EDITOR: EntityPermissionAHubVO = { entityRef: EntityRefAHubEnum.ACCOUNTS, permissionType: 'EDITOR' };
    public static ACCOUNTS_ADMINISTRATOR: EntityPermissionAHubVO = { entityRef: EntityRefAHubEnum.ACCOUNTS, permissionType: 'ADMINISTRATOR' };
    public static USAGE_NONE: EntityPermissionAHubVO = { entityRef: EntityRefAHubEnum.USAGE, permissionType: 'NONE' };
    public static USAGE_GUEST: EntityPermissionAHubVO = { entityRef: EntityRefAHubEnum.USAGE, permissionType: 'GUEST' };
    public static USAGE_USER: EntityPermissionAHubVO = { entityRef: EntityRefAHubEnum.USAGE, permissionType: 'USER' };
    public static USAGE_EDITOR: EntityPermissionAHubVO = { entityRef: EntityRefAHubEnum.USAGE, permissionType: 'EDITOR' };
    public static USAGE_ADMINISTRATOR: EntityPermissionAHubVO = { entityRef: EntityRefAHubEnum.USAGE, permissionType: 'ADMINISTRATOR' };
    public static DISTRIBUTION_NONE: EntityPermissionAHubVO = { entityRef: EntityRefAHubEnum.DISTRIBUTION, permissionType: 'NONE' };
    public static DISTRIBUTION_GUEST: EntityPermissionAHubVO = { entityRef: EntityRefAHubEnum.DISTRIBUTION, permissionType: 'GUEST' };
    public static DISTRIBUTION_USER: EntityPermissionAHubVO = { entityRef: EntityRefAHubEnum.DISTRIBUTION, permissionType: 'USER' };
    public static DISTRIBUTION_EDITOR: EntityPermissionAHubVO = { entityRef: EntityRefAHubEnum.DISTRIBUTION, permissionType: 'EDITOR' };
    public static DISTRIBUTION_ADMINISTRATOR: EntityPermissionAHubVO = { entityRef: EntityRefAHubEnum.DISTRIBUTION, permissionType: 'ADMINISTRATOR' };
    public static LIBRARY_DATA_NONE: EntityPermissionAHubVO = { entityRef: EntityRefAHubEnum.LIBRARY_DATA, permissionType: 'NONE' };
    public static LIBRARY_DATA_GUEST: EntityPermissionAHubVO = { entityRef: EntityRefAHubEnum.LIBRARY_DATA, permissionType: 'GUEST' };
    public static LIBRARY_DATA_USER: EntityPermissionAHubVO = { entityRef: EntityRefAHubEnum.LIBRARY_DATA, permissionType: 'USER' };
    public static LIBRARY_DATA_EDITOR: EntityPermissionAHubVO = { entityRef: EntityRefAHubEnum.LIBRARY_DATA, permissionType: 'EDITOR' };
    public static LIBRARY_DATA_ADMINISTRATOR: EntityPermissionAHubVO = { entityRef: EntityRefAHubEnum.LIBRARY_DATA, permissionType: 'ADMINISTRATOR' };
    public static LIBRARY_ASSETS_NONE: EntityPermissionAHubVO = { entityRef: EntityRefAHubEnum.LIBRARY_ASSETS, permissionType: 'NONE' };
    public static LIBRARY_ASSETS_GUEST: EntityPermissionAHubVO = { entityRef: EntityRefAHubEnum.LIBRARY_ASSETS, permissionType: 'GUEST' };
    public static LIBRARY_ASSETS_USER: EntityPermissionAHubVO = { entityRef: EntityRefAHubEnum.LIBRARY_ASSETS, permissionType: 'USER' };
    public static LIBRARY_ASSETS_EDITOR: EntityPermissionAHubVO = { entityRef: EntityRefAHubEnum.LIBRARY_ASSETS, permissionType: 'EDITOR' };
    public static LIBRARY_ASSETS_ADMINISTRATOR: EntityPermissionAHubVO = { entityRef: EntityRefAHubEnum.LIBRARY_ASSETS, permissionType: 'ADMINISTRATOR' };
    public static LIBRARY_ATTACHMENTS_NONE: EntityPermissionAHubVO = { entityRef: EntityRefAHubEnum.LIBRARY_ATTACHMENTS, permissionType: 'NONE' };
    public static LIBRARY_ATTACHMENTS_GUEST: EntityPermissionAHubVO = { entityRef: EntityRefAHubEnum.LIBRARY_ATTACHMENTS, permissionType: 'GUEST' };
    public static LIBRARY_ATTACHMENTS_USER: EntityPermissionAHubVO = { entityRef: EntityRefAHubEnum.LIBRARY_ATTACHMENTS, permissionType: 'USER' };
    public static LIBRARY_ATTACHMENTS_EDITOR: EntityPermissionAHubVO = { entityRef: EntityRefAHubEnum.LIBRARY_ATTACHMENTS, permissionType: 'EDITOR' };
    public static LIBRARY_ATTACHMENTS_ADMINISTRATOR: EntityPermissionAHubVO = { entityRef: EntityRefAHubEnum.LIBRARY_ATTACHMENTS, permissionType: 'ADMINISTRATOR' };
    public static PUBLISHING_NONE: EntityPermissionAHubVO = { entityRef: EntityRefAHubEnum.PUBLISHING, permissionType: 'NONE' };
    public static PUBLISHING_GUEST: EntityPermissionAHubVO = { entityRef: EntityRefAHubEnum.PUBLISHING, permissionType: 'GUEST' };
    public static PUBLISHING_USER: EntityPermissionAHubVO = { entityRef: EntityRefAHubEnum.PUBLISHING, permissionType: 'USER' };
    public static PUBLISHING_EDITOR: EntityPermissionAHubVO = { entityRef: EntityRefAHubEnum.PUBLISHING, permissionType: 'EDITOR' };
    public static PUBLISHING_ADMINISTRATOR: EntityPermissionAHubVO = { entityRef: EntityRefAHubEnum.PUBLISHING, permissionType: 'ADMINISTRATOR' };
}

/**
 * Class used by pagination requests to monitor request thread count and indexes retrieved.
 */

/**
 * PAGINATION
 * Some aHub calls support pagination headers. 
 * Pagination helps because when dealing with a large data set .. say 100,000 products.. we dont want to wait until all the data has been
 * downlaoded before we return and display. We cant stream as this is a REST based request, and data sizes can cause issues... So by
 * adding paginationheaders to the request we can ask for pages say 200 records at a time.
 * 
 * With HTTP/2 we can make many parellel calls ( up to 128 ) So the pagination system here, will make one initial call to get the first 
 * bit of data back as quickly as possible for display , then spawn multiple requests to parellel download data , this allows for
 * data from one request to be downloading whilst another request is being made , or data prepped from the aHub.
 * 
 * Browser and CORS issue !
 * As the request for the data is to the same end point.. the browser will stall subsequent requests whilst teh first is in progress,
 * I believe this is because it thinking "I've already requested this object, I need to let to finish before I request it
 * again (.. so it could be cached for example )"
 * 
 * This can be overcome with the addition of a changing parameter, but this trigger additional CORS preflight OPTIONS requests, for
 * every request ! 
 * 
 * So to get round this we can;t use a random number, rather we need to use a request ID for each parallel request, so that the
 * preflight OPTION calls can be cached... Get it .. Good.
 */

import { AHubServiceRequestPaginationThreadVO } from 'services/ahub/ahub-service-request-pagination-thread.vo';

/**
 * When pagination is being used to download multiple sets of data, this indicates the 
 * number of concurrent threads to use.
 */
const PAGINATION_REQUESTS_CONCURRENT_MAX: Number = 5;

export class AHubServiceRequestPaginationControl {

    /**
     * Thisis a record of requests that have been made. 
     */
    private requestsMade: AHubServiceRequestPaginationThreadVO[] = [];

    /**
     * This is the current number of requests in progress.
     */
    private requestsInProgress: number = 0;

    /**
     * This flag indicates that the last page of data has been returned
     * so no need to make any more requests.
     */
    private lastDataSetRetrieved: boolean = false;

    /**
     * Marks a request made for a page of data with a given index as complete.
     * @param requestedIndex 
     */
    public requestThreadComplete(requestedIndex: number) {
        this.requestsMade.forEach(requestThread => { if (requestThread.index == requestedIndex) { requestThread.done = true; this.requestsInProgress--; } })
    }

    /**
     * Returns true if the last of the data set has been retrieved ( there still may be others in progress )
     */
    public isLastDataSetRetrieved(): boolean {
        return this.lastDataSetRetrieved;
    }

    /**
     * Sets the flag indicating we have retrieved the last data set.
     */
    public flagLastDatasetRetrieved() {
        this.lastDataSetRetrieved = true;
    }

    /**
     * Indicates if more requests can be made.
     */
    public canMakeMoreRequests(): boolean {

        // Initially we only want to make 1 request so we get back data ASAP.
        // After this first request, initial data back and shown to the user, we 
        // will up the number of requests being made concurrently to get the 
        // lager portion of data more efficently
        if ((this.requestsMade.length < 2) && (this.requestsInProgress > 0)) {
            return false;
        }

        return (this.requestsInProgress < PAGINATION_REQUESTS_CONCURRENT_MAX);
    }

    /**
     * Checks to see if the pagination index has already been requested.
     * @param index 
     */
    public isIndexRequestedAlready(index: number): boolean {
        return (this.requestsMade.filter(request => request.index == index).length > 0)
    }

    /**
     * Tells the control that this index will be requested.
     * Returns a request thread number. between 0 and the max thread count.
     * You should check with canMakeMoreRequests() before calling this function.
     * @param requestedIndex 
     */
    public requestThreadAdd(requestedIndex: number): number {
        // We 
        // Find the first availible request thread number.
        let newRequestNumber: number = 0;
        while (newRequestNumber < PAGINATION_REQUESTS_CONCURRENT_MAX && this.requestsMade.filter(requestThread => !requestThread.done).some(requestThread => requestThread.requestNumber == newRequestNumber))
            newRequestNumber++;

        //Create a new request requestThread.                            
        let requestThread: AHubServiceRequestPaginationThreadVO =
        {
            index: Number(requestedIndex),
            requestNumber: newRequestNumber,
            endFlag: false,
            done: false
        };

        // add it to our requests made array.
        this.requestsMade.push(requestThread);
        this.requestsInProgress++;

        // Return the thread number used.
        return newRequestNumber;

    }

    /**
     * Returns true.. if all requests made have completed and the last data set has beend flag as retrieved.
     */
    public isRequestsAllComplete(): boolean {
        if ((!this.requestsMade.some(requestThread => !requestThread.done)) && this.isLastDataSetRetrieved())
            return true;

        return false;
    }



}
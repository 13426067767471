import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-asset-view-dialog-video',
  templateUrl: './product-asset-view-dialog-video.component.html',
  styleUrls: ['./product-asset-view-dialog-video.component.css']
})
export class ProductAssetViewDialogVideoComponent implements OnInit {

  /**
   * Parameters for the dialogue (must be null or property isn't found!)
   */
  public dialogParams: ProductAssetViewDialogVideoComponentParams = null;

  constructor() {
    // This is intentional
  }

  ngOnInit() {
    // This is intentional
  }
}

/**
 * Parameters for the object
 */
export interface ProductAssetViewDialogVideoComponentParams {
  sectionName: string;
  sectionColour: string;
  propertyName: string;
  videoURL: string;
}


import { takeUntil, debounceTime, tap, delay } from 'rxjs/operators';
import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { componentDestroyStream, Hark } from 'modules/common/hark.decorator';
import { Observable, combineLatest } from 'rxjs';

@Component({
  selector: 'app-grid-header-count',
  templateUrl: './grid-header-count.component.html',
  styleUrls: ['./grid-header-count.component.css']
})
@Hark()
export class GridHeaderCountComponent implements OnDestroy, OnInit {

  @Input()
  /**
   * Stream to monitor the visible rows.
   */
  rowsVisible$: Observable<any>;

  @Input()
  /**
   * Stream for the current count of the items
   */
  itemCount$: Observable<number>;

  /**
   * Any warnings that need to bd displayed.
   */
  @Input()
  warnings$: Observable<string>;

  /**
   * The total amount of data items as reported by our external stream
   */
  dataTotal = 0;

  /**
   * The current count of items in the table
   */
  tableCurrent = 0;

  /**
   * Is the data being updated
   */
  downloading = false;

  /**
   * Warnings string
   */
  warnings: string;

  ngOnInit() {


    //Set the warnings observable
    if (this.warnings$) {
      this.warnings$
        .pipe(takeUntil(componentDestroyStream(this)))
        .subscribe((warning) => this.warnings = warning);
    }

    //We want to display a bouncing cloud whilst we are downloading data
    //so we have a dedicated stream for it
    this.itemCount$.pipe(
      tap(() => this.downloading = true),
      debounceTime(2000),
      takeUntil(componentDestroyStream(this)))
      .subscribe(data => {
        this.downloading = false;
      });

    //We want to update the item counts of the data item in the gird
    combineLatest([
      this.itemCount$,
      this.rowsVisible$
    ])
      .pipe(
        delay(100),
        takeUntil(componentDestroyStream(this)))
      .subscribe(([count, rowsVisible]) => {
        this.dataTotal = count;
        this.tableCurrent = (rowsVisible === -1) ? count : rowsVisible;
      });
  }

  ngOnDestroy() { }
}

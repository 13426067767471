import {
    Component,
    Injectable
} from '@angular/core'
import { CanDeactivate } from '@angular/router';
import { of } from 'rxjs';
import { DialogService } from 'modules/common/dialogs/dialog.service';

/**
 * Store access
 */
import { StoreAccess } from 'store/store-access';
import { ViewActions } from 'actions/view.actions';

/**
 * Selector to get any dirty view forms from the view
 */
import { viewFormStateFormStatesDirty, viewComponentStateFormStatesDirty } from 'selector/view/view-page-state.selector';


@Injectable()
/**
 * Class which will guard against navigating away from unsaved changes
 */
export class UnsavedChangesGuard implements CanDeactivate<Component> {

    constructor(private readonly dialogService: DialogService) { }

    /**
     * Boolena to show that the dialogue is currently open
     */
    private static dialogueOpen = false;

    /**
     * A guard to check if we can deativate the current page we are on
     */
    canDeactivate(target: Component) {

        //Get the dirty forms which are currently on view
        const dirtyForms = StoreAccess.dataGet(viewFormStateFormStatesDirty);
        const dirtyComponenets = StoreAccess.dataGet(viewComponentStateFormStatesDirty);

        // Is the currently selected distribution groups data pristine?
        if (dirtyForms.length === 0 || dirtyComponenets.length === 0) {

            //We have no changes to we will return an observable of false
            if ((!dirtyForms || dirtyForms.length <= 0) && (!dirtyComponenets || dirtyComponenets.length <= 0)) {
                return of(true);
            }
        }

        //OK there is a form onscreen which has changes.

        //It looks like we already have this dialogue open...
        //this means more than one item onscreen had changes.
        //So although we have change we will allow navigations .... providing the popup is confirmed
        if (UnsavedChangesGuard.dialogueOpen) {
            return of(true);
        }

        //Flag the dialogue as open
        UnsavedChangesGuard.dialogueOpen = true;

        //Open a unsaved dialog box return the result which will affect the navigation
        const dialogueResultObservable$ = this.dialogService.confirmDialogOpen("Discard Changes?",
            'There are unsaved changes do want to discard changes and continue navigation?', 'Discard', 'Cancel');

        //Listen for the dialogue close so that we can allow other routes to call this
        dialogueResultObservable$.subscribe((result) => {

            // Did the user click "Yes"/"Discard"?
            if (result) {

                // Get a list of the ids we need to reset.
                const resetIds: string[] = dirtyForms.map(form => form.formId);

                // Now make the call to force the reset of the forms.
                StoreAccess.dispatch(ViewActions.formStateForceResetByIds(resetIds));
            }

            //Unflag the dialogue open flag
            UnsavedChangesGuard.dialogueOpen = false;
        });

        //Return the dialogue result observable
        return dialogueResultObservable$;
    }
}


import { filter } from 'rxjs/operators';
/**
 * ----------------------------------------------------------
 * Core Imports
 * ----------------------------------------------------------
 */

import { Observable } from 'rxjs';
import { WorklogAHubVO } from 'valueObjects/ahub/work/worklog.ahub.vo';
import { EntityRefAHubEnum } from 'valueObjects/ahub/system/entity-ref.ahub.enum';

/**
 * ----------------------------------------------------------
 * Selectors
 * ----------------------------------------------------------
 */

import { StoreAccess } from 'store/store-access';

import { session } from 'selector/app.selector';



/**
 * Worklog Observable
 */
export class WorklogObservable {

    /**
     * Entity statics
     */
    private static readonly ENTITY_ACCOUNTS: string = EntityRefAHubEnum.ACCOUNTS;
    private static readonly ENTITY_LIBRARY: string = EntityRefAHubEnum.LIBRARY_DATA;

    /**
     * Worklog types statics
     */
    static readonly WORK_LOG_TYPES_DISTRIBUTION_GROUPS: string = "DISTRIBUTION_GROUP";
    static readonly WORK_LOG_TYPES_WORK_GROUPS: string = "WORK_GROUP";
    static readonly WORK_LOG_TYPES_DISTRIBUTION: string = "DISTRIBUTION";
    static readonly WORK_LOG_TYPES_EXPORT: string = "EXPORT";
    static readonly WORK_LOG_TYPES_DATASET: string = "DATASET";
    static readonly WORK_LOG_TYPES_DATASET_CATEGORY: string = "DATASET_CATEGORY";
    static readonly WORK_LOG_TYPES_CLIENT: string = "CLIENT";
    static readonly WORK_LOG_TYPES_USER: string = "USER";
    static readonly WORK_LOG_TYPES_CLIENT_QUOTA: string = "CLIENT_QUOTA";
    static readonly WORK_LOG_TYPES_PRODUCT: string = "PRODUCT";
    static readonly WORK_LOG_TYPES_PRODUCT_CLASSES: string = "PRODUCT_CLASS";
    static readonly WORK_LOG_TYPES_PRODUCT_PROPERTY: string = "PRODUCT_PROPERTY";
    static readonly WORK_LOG_TYPES_PRODUCT_PROPERTY_SECTION: string = "PRODUCT_PROPERTY_SECTION";
    static readonly WORK_LOG_TYPES_PRODUCT_PROPERTY_ALLOCATION: string = "PRODUCT_PROPERTY_ALLOCATION";
    static readonly WORK_LOG_TYPES_PRODUCT_PROPERTY_ALLOCATION_CHAIN: string = "PRODUCT_PROPERTY_ALLOCATION_CHAIN";
    static readonly WORK_LOG_TYPES_EXTRACT_DEFINITION: string = "EXTRACT_DEFINITION";
    static readonly WORK_LOG_TYPES_EXTRACT: string = "EXTRACT";
    static readonly WORK_LOG_TYPES_EXTRACT_CONTENTS: string = "EXTRACT_CONTENTS";
    static readonly WORK_LOG_TYPES_CLIENT_LIBRARY_VERSION: string = "CLIENT_LIBRARY_VERSION";
    static readonly WORK_LOG_TYPES_CLIENT_LIBRARY: string = "CLIENT_LIBRARY";
    static readonly WORK_LOG_TYPES_MAINTENANCE: string = "MAINTENANCE";
    static readonly WORK_LOG_TYPES_EXPORTER: string = "EXPORTER";
    static readonly WORK_LOG_TYPES_EXPORTER_BUILD_HISTORY: string = "EXPORTER_BUILD_HISTORY";
    static readonly WORK_LOG_TYPES_RESOURCE_PACK: string = "RESOURCE_PACK";




    /**
     * The observable which makes up this observable
     */
    private baseObservable: Observable<WorklogAHubVO> = undefined;

    constructor(baseObservable: Observable<WorklogAHubVO>) {

        //Set the base observable
        this.baseObservable = baseObservable;
    }

    /**
     * Return the base observable
     */
    observable(): Observable<WorklogAHubVO> {

        //Just return the base observable
        return this.baseObservable;
    }

    /**
     * ----------------------------------------------------------------
     *  Session Filters
     * ----------------------------------------------------------------
     */

    /**
     * Filter the observable to only worklogs associated to the current user
     */
    sessionUser(): WorklogObservable {
        return new WorklogObservable(this.baseObservable.pipe(filter(worklog => WorklogObservable.isWorklogForSessionUser(worklog))))
    }

    /**
     * Filter the observable to only worklogs associated to the current user
     */
    sessionClient(): WorklogObservable {
        return new WorklogObservable(this.baseObservable.pipe(filter(worklog => WorklogObservable.isWorklogForSessionClient(worklog))));
    }

    /**
     * Filter the worklog to either being associated to the current client or current user of the system
     */
    sessionUserOrClient(): WorklogObservable {
        return new WorklogObservable(this.baseObservable.pipe(filter(worklog => (WorklogObservable.isWorklogForSessionClient(worklog) || WorklogObservable.isWorklogForSessionUser(worklog)))));
    }

    //Is the worklog supplied for the client who is currently selected in the session
    private static isWorklogForSessionClient = (worklog: WorklogAHubVO) => (StoreAccess.dataGet(session).clientId == worklog.clientId);

    //Is the worklog supplied for the client who is currently selected in the session
    private static isWorklogForSessionUser = (worklog: WorklogAHubVO) => (StoreAccess.dataGet(session).userId == worklog.userId);

    /**
     * ----------------------------------------------------------------
     *  Process State
     * ----------------------------------------------------------------
     */

    /**
     * Filters to only those worklogs which have not yet finished
     */
    onlyIncomplete(): WorklogObservable {

        //If the worklog complete is set to 1 so check for anything but
        return new WorklogObservable(this.baseObservable.pipe(filter(worklog => !worklog.complete)));
    }

    /**
     * Filters to only those worklogs which have finished ( it could have failed )
     */
    onlyComplete(): WorklogObservable {

        //If the worklog complete is set to 1 then the worklog has finished but could have failed.
        return new WorklogObservable(this.baseObservable.pipe(
            filter(worklog => worklog.complete)));
    }

    /**
     * Filters to only those worklogs which are still running on completed, i.e they have not failed.
     */
    onlyNonFailed(): WorklogObservable {

        // If the fault is false then return true so therefore add the worklog to the non failed stream.
        return new WorklogObservable(this.baseObservable.pipe(filter(worklog => !worklog.fault)));
    }

    /**
     * Filters to only those worklogs which are complete and hides the failed ones ( items will be complete )
     */
    onlySuccess(): WorklogObservable {

        //Get a stream which has definetly finished and filter out the ones which have failed
        return new WorklogObservable(this.onlyComplete().observable().pipe(filter(worklog => !worklog.fault)));
    }

    /**
     * Filters to only those worklogs which have failed ( item will be complete )
     */
    onlyFailed(): WorklogObservable {

        //Get a stream which has definetly finished and filter out the ones which have completed
        return new WorklogObservable(this.onlyComplete().observable().pipe(filter(worklog => worklog.fault)));
    }

    /**
     * Worklog has download avalible
     */
    hasDownload(): WorklogObservable {

        //Get a stream which has downloadable content
        return new WorklogObservable(this.baseObservable.pipe(filter(worklog => worklog.download)));
    }

    /**
     * ----------------------------------------------------------------
     *  Affected Ids
     * ----------------------------------------------------------------
     */

    /**
     * Filter to only worklogs which have affected ids
     */
    hasAffectedIds(): WorklogObservable {
        return new WorklogObservable(this.baseObservable.pipe(filter(worklog => (worklog.workAffectedIds && worklog.workAffectedIds.length > 0) || worklog.hasOverflowed)))
    }

    /**
     * ----------------------------------------------------------------
     *  Worklog Action Filters
     * ----------------------------------------------------------------
     */

    /**
     * Filter the observable to only worklogs that are adding data
     */
    workActionAdd(): WorklogObservable {
        return this.workActionFilter(["ADD"]);
    }

    /**
     * Filter the observable to only worklogs that are updating data
     */
    workActionUpdate(): WorklogObservable {
        return this.workActionFilter(["UPDATE"]);
    }

    /**
     * Filter the observable to worklogs that are deleting data
     */
    workActionDelete(): WorklogObservable {
        return this.workActionFilter(["DELETE"]);
    }

    /**
     * Generic function which will allow us to create a custom filter for this item
     */
    workActionFilter(actionTypes: string[]): WorklogObservable {

        //Create a new worklog observable based on the filtering out of items
        return new WorklogObservable(this.baseObservable.pipe(filter(worklog =>
            (actionTypes.find((action) => worklog.workAction == action) != undefined)
        )));
    }

    /**
     * ----------------------------------------------------------------
     *  Worklog type filters
     * ----------------------------------------------------------------
     */


    /**
     * Filter on the worklog types
     */
    worklogTypeFilter(worklogType: String): WorklogObservable {

        //Create a new worklog observable based on the filtering out of items
        return new WorklogObservable(this.baseObservable.pipe(filter(worklog => worklog.workType == worklogType)));
    }

    /**
     * ----------------------------------------------------------------
     *  Entity type filters
     * ----------------------------------------------------------------
     */

    /**
     * Get only the accounts entitys
     */
    entityTypeAccounts(): WorklogObservable {
        return this.entityTypeFilter(WorklogObservable.ENTITY_ACCOUNTS);
    }

    /**
     * Get only the library entitys
     */
    entityTypeLibrary(): WorklogObservable {
        return this.entityTypeFilter(WorklogObservable.ENTITY_LIBRARY);
    }

    /**
     * Filter the worklogs based on the entity types
     */
    private entityTypeFilter(entityRef: String): WorklogObservable {
        return new WorklogObservable(this.baseObservable.pipe(filter(worklog => worklog.entityRef == entityRef)));
    }

    /**
     * ----------------------------------------------------------------
     *  Workflow ID filter.
     * ----------------------------------------------------------------
     */

    workflowId(workflowExecutionId: string) {
        return new WorklogObservable(this.baseObservable.pipe(filter(worklog => worklog.workflowExecutionId == workflowExecutionId)));
    }
};

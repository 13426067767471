import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AppRoutingNavigation } from 'app/app-routing-navigation';
import { AppActions } from 'app/store/actions/app.actions';
import { sessionUserSessionCredentials } from 'selector/app.selector';
/**
 * Store access
 */
import { StoreAccess } from 'store/store-access';
/**
 * Value objects.
 */
import { UserSessionCredentialsAHubVO } from 'valueObjects/ahub/accounts/user-session-credentials.ahub.vo';

@Injectable()
/**
 * Class which will guard against navigating when not logged in.
 */
export class LoggedInGuard implements CanActivate {

  constructor(private readonly router: Router) { }

  /**
   * Can we activate this route?
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    // Are we logged in?
    const loggedIn = this.loggedInTest();

    // Are we logged in? If not, send the user to the log in route.
    if (!loggedIn) {

      // Record the return to URL.
      StoreAccess.dispatch(AppActions.returnToUrlSet(state.url));

      // Now go to the log in screen.
      AppRoutingNavigation.navigateLoginSelection(this.router);
    }

    // Now return the logged in state.
    return loggedIn;
  }


  /**
   * Are we logged in?
   */
  private loggedInTest(): boolean {

    // Get the current session information.
    const sessionUserCredentials: UserSessionCredentialsAHubVO = StoreAccess.dataGet(sessionUserSessionCredentials);

    // Are the credentials missing any properties? If so, return false.
    if (!sessionUserCredentials ||
      sessionUserCredentials.sessionId === undefined ||
      sessionUserCredentials.sessionKey === undefined ||
      sessionUserCredentials.userEmail === undefined ||
      sessionUserCredentials.sessionExpiry === undefined) {
      return false;
    }

    // If we get here then return true as we must be logged in.
    return true;
  }
}

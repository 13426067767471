import { ActionPayloadLibraryNumberProductsNumberArray } from 'actions/payloads/ahub-action-library.payload.vo';
import { ActionClientAHubVO, ActionClientAHubVOs, ActionClientConfigurationAHubVO, ActionClientIndexAHubVOs, ActionClientLibraryAHubVOs, ActionClientLibraryIndexAHubVOs, ActionClientLibraryVersionAHubVO, ActionClientLibraryVersionAHubVOs, ActionClientLibraryVersionIndexAHubVOs, ActionClientQuotaAHubVO, ActionClientQuotasAHubVOs, ActionClientStorageIndexAHubVOs, ActionDistributionAHubVO, ActionDistributionAHubVOs, ActionDistributionGroupAHubVO, ActionDistributionGroupAHubVOs, ActionDistributionGroupIndexAHubVOs, ActionExportAHubVO, ActionExportAHubVOs, ActionExportDistributionAHubVOs, ActionExporterAHubVO, ActionExporterAHubVOs, ActionExporterExportGeneratorSettingsAHubVO, ActionExporterIndexAHubVOs, ActionExportGeneratorAHubVOs, ActionExportGeneratorExporterMappingAHubVO, ActionExportIdExportVersionNumber, ActionExportIndexAHubVOs, ActionExportTypeAHubVOs, ActionExportVersionAHubVO, ActionExportVersionWithdraw, ActionRelationshipAHubVO, ActionUserAHubVO, ActionUserArrayAHubVO, ActionUserExtendedAHubVOs, ActionUserIndexAHubVO, ActionUserSessionCredentialsAHubVO, ActionWorkGroupAHubVO, ActionWorkGroupAHubVOs, ActionWorkGroupIndexAHubVOs } from 'actions/types/ahub-accounts.action-types';
import { ActionSystemClientLibrary, ActionSystemClientLibraryId, ActionSystemStatusArrayAHubVO } from 'actions/types/ahub-system.action-types';
import { ActionWorklogSegmentAHubVO } from 'actions/types/ahub-work.action-types';
/**
 * SESSION INFO ( ids , keys and client ):
 * Most of the fetch actions would notmally require two additional
 * pices of information. Sesion credentials and clientId of the client
 * we are currently working with. The pure way of doing things would be to
 * have the component select this information from the store and pass it
 * in to evey action. But to keep things simple, this information is fetched
 * by the aHub service and added there.
 */
/**
 * ----------------------------------------------------------
 * Action Types
 * ----------------------------------------------------------
 */
import {
  ActionBoolean, ActionDate, ActionIdItem, ActionNumber, ActionNumberArray, ActionNumberNumber,
  ActionNumberNumberNumber, ActionNumberNumberNumbersArray, ActionNumberNumbersArray, ActionNumberNumberString, ActionNumberStringArray,
  ActionNumberStringString, ActionNumberStringStringArray, ActionString, ActionStringArray,
  ActionStringNumber, ActionStringNumberBoolean
} from 'actions/types/common.action-types';
import {
  ActionClientLogAHubVO, ActionDataSetAHubVO, ActionDataSetAHubVOs, ActionDataSetCategoryAHubVO,
  ActionDataSetCategoryAHubVOs, ActionDataSetIndexAHubVOs,
  ActionExporterBlueprintFetch, ActionExporterBuildHistoryAHubVOs,
  ActionExporterBuildHistoryIndexesAHubVO, ActionExtractAHubVO, ActionExtractAHubVOs,
  ActionExtractAssetMatchArray,
  ActionExtractDefaultValueRulesAHubVO, ActionExtractDefinitionAHubVO, ActionExtractDefinitionAHubVOs, ActionExtractDefinitionIndexAHubVOs,
  ActionExtractDocumentProductFileTypeAHubVO,
  ActionExtractDocumentSettings, ActionExtractHistoryIndexAHubVOs, ActionExtractIndexAHubVOs,
  ActionExtractProductAHubVOs, ActionExtractProductAndAssetsUploadAHubVOs, ActionExtractProductBulkUploadAHubVOs,
  ActionExtractProductPaginationPage, ActionExtractProductSectionPropertyAsset, ActionProductAssetAHubVOs,
  ActionProductAssetVOsWithId, ActionProductClassAHubVO,
  ActionProductClassAHubVOs, ActionProductClassFilterAHubVONumber, ActionProductClassIndexAHubVOs,
  ActionProductClassProductCountAHubVOs, ActionProductPropertyAHubVO, ActionProductPropertyAHubVOs,
  ActionProductPropertyAllocationChainAHubVO,
  ActionProductPropertyAllocationChainAHubVOs, ActionProductPropertyAllocationChainIndexAHubVOs,
  ActionProductPropertyAllocationChainValueCommitAHubVOs, ActionProductPropertyAllocationIndexAHubVOs, ActionProductPropertyAllocationsAHubVO,
  ActionProductPropertyCompletenessAHubVO, ActionProductPropertyFilterAHubVONumber, ActionProductPropertyIndexAHubVOs, ActionProductPropertySectionAHubVO,
  ActionProductPropertySectionAHubVOs, ActionProductPropertySectionIndexAHubVOs, ActionProductPropertyTypesAHubVOs,
  ActionProductSortAHubVONumber,
  ActionProductVOsWithId, ActionResourcePackAHubVO, ActionResourcePackAHubVOs, ActionResourcePackIndexesAHubVO
} from 'actions/types/library.action-types';
import { ExportTypeCodeAHubEnum } from 'app/valueObjects/ahub/accounts/export-type-code.ahub.enum';
import { ClientLogAHubVO } from 'app/valueObjects/ahub/library/client-log.ahub.vo';
import { ExportGeneratorExporterMappingAHubVO } from 'app/valueObjects/ahub/library/export-generator-exporter-mapping.ahub.vo';
import { ExporterBuildHistoryIndexAHubVO } from 'app/valueObjects/ahub/library/exporter-build-history-index.ahub.vo';
import { ExporterBuildHistoryAHubVO } from 'app/valueObjects/ahub/library/exporter-build-history.ahub.vo';
import { ExporterExportGeneratorSettingsVO } from 'app/valueObjects/ahub/library/exporter-export-generator-settings.vo';
import { ExtractAssetMatchAHubVO } from 'app/valueObjects/ahub/library/extract-asset-match.vo';
import { ExtractDefaultValueRulesAHubVO } from 'app/valueObjects/ahub/library/extract-default-value-rules.ahub.vo';
import { ExtractDocumentSettings } from 'app/valueObjects/ahub/library/extract-document-settings.ahub.vo';
import { ExtractProductAssetUploadAHubVO } from 'app/valueObjects/ahub/library/extract-product-asset-upload.ahub.vo';
import { ProductPropertyCompletenessAHubVO } from 'app/valueObjects/ahub/library/product-property-completeness.ahub.vo';
import { ProductSortAHubVO } from 'app/valueObjects/ahub/library/product-sort.ahub.vo';
import { ResourcePackIndexAHubVO } from 'app/valueObjects/ahub/library/resource-pack-index.ahub.vo';
import { PaginationIdBreakdownVO } from 'app/valueObjects/ahub/system/pagination-id-breakdown.ahub.vo';
import { ActionWork, ActionWorkPayload } from 'store/actions/types/work.action-types';
import { ClientConfigurationAHubVO } from 'valueObjects/ahub/accounts/client-configuration.ahub.vo';
import { ClientIndexAHubVO } from 'valueObjects/ahub/accounts/client-index.ahub.vo';
import { ClientLibraryIndexAHubVO } from 'valueObjects/ahub/accounts/client-library-index.ahub.vo';
import { ClientLibraryVersionIndexAHubVO } from 'valueObjects/ahub/accounts/client-library-version-index.ahub.vo';
import { ClientLibraryVersionAHubVO } from 'valueObjects/ahub/accounts/client-library-version.ahub.vo';
import { ClientLibraryAHubVO } from 'valueObjects/ahub/accounts/client-library.ahub.vo';
import { ClientQuotaAHubVO } from 'valueObjects/ahub/accounts/client-quota.ahub.vo';
import { ClientStorageIndexAHubVo } from 'valueObjects/ahub/accounts/client-storage-index.ahub.vo';
import { ClientAHubVO } from 'valueObjects/ahub/accounts/client.ahub.vo';
import { DistributionGroupIndexAHubVO } from 'valueObjects/ahub/accounts/distribution-group-index.ahub.vo';
//Accounts
import { DistributionGroupAHubVO } from 'valueObjects/ahub/accounts/distribution-group.ahub.vo';
import { DistributionIndexAHubVO } from 'valueObjects/ahub/accounts/distribution-index.ahub.vo';
import { DistributionAHubVO } from 'valueObjects/ahub/accounts/distribution.ahub.vo';
import { ExportDistributionAHubVO } from 'valueObjects/ahub/accounts/export-distribution.ahub.vo';
import { ExportGeneratorAHubVO } from 'valueObjects/ahub/accounts/export-generator.ahub.vo';
import { ExportIndexAHubVO } from 'valueObjects/ahub/accounts/export-index.ahub.vo';
import { ExportTypeAHubVO } from 'valueObjects/ahub/accounts/export-type.ahub.vo';
import { ExportVersionAHubVO } from 'valueObjects/ahub/accounts/export-version.ahub.vo';
import { ExportAHubVO } from 'valueObjects/ahub/accounts/export.ahub.vo';
import { UserExtendedAHubVO } from 'valueObjects/ahub/accounts/user-extended.ahub.vo';
import { UserIndexAHubVO } from 'valueObjects/ahub/accounts/user-index.ahub.vo';
import { UserSessionCredentialsAHubVO } from 'valueObjects/ahub/accounts/user-session-credentials.ahub.vo';
import { UserAHubVO } from 'valueObjects/ahub/accounts/user.ahub.vo';
import { WorkGroupIndexAHubVO } from 'valueObjects/ahub/accounts/work-group-index.ahub.vo';
import { WorkGroupAHubVO } from 'valueObjects/ahub/accounts/work-group.ahub.vo';
import { DataSetCategoryAHubVO } from 'valueObjects/ahub/library/dataset-category.ahub.vo';
//Library
import { DataSetIndexAHubVO } from 'valueObjects/ahub/library/dataset-index.ahub.vo';
import { DataSetAHubVO } from 'valueObjects/ahub/library/dataset.ahub.vo';
import { DocumentProductFileTypeAHubEnum } from 'valueObjects/ahub/library/document-product-file-type.ahub.enum';
import { ExporterIndexAHubVO } from 'valueObjects/ahub/library/exporter-index.ahub.vo';
import { ExporterAHubVO } from 'valueObjects/ahub/library/exporter.ahub.vo';
import { ExtractDefinitionIndexAHubVO } from 'valueObjects/ahub/library/extract-definition-index.ahub.vo';
import { ExtractDefinitionAHubVO } from 'valueObjects/ahub/library/extract-definition.ahub.vo';
import { ExtractHistoryIndexAHubVO } from 'valueObjects/ahub/library/extract-history-index.ahub.vo';
import { ExtractIndexAHubVO } from 'valueObjects/ahub/library/extract-index.ahub.vo';
import { ExtractAHubVO } from 'valueObjects/ahub/library/extract.ahub.vo';
import { ProductAssetAHubVO } from 'valueObjects/ahub/library/product-asset.ahub.vo';
import { ProductClassFilterAHubVO } from 'valueObjects/ahub/library/product-class-filter.ahub.vo';
import { ProductClassIndexAHubVO } from 'valueObjects/ahub/library/product-class-index.ahub.vo';
import { ProductClassProductCount } from 'valueObjects/ahub/library/product-class-product-count.ahub.vo';
import { ProductClassAHubVO } from 'valueObjects/ahub/library/product-class.ahub.vo';
import { ProductPropertyAllocChainIndexAHubVO } from 'valueObjects/ahub/library/product-property-alloc-chain-index.ahub.vo';
import { ProductPropertyAllocChainValueCommitAHubVO } from 'valueObjects/ahub/library/product-property-alloc-chain-value-commit.ahub';
import { ProductPropertyAllocChainAHubVO } from 'valueObjects/ahub/library/product-property-alloc-chain.ahub.vo';
import { ProductPropertyAllocationAHubVO } from 'valueObjects/ahub/library/product-property-allocation.ahub.vo';
import { ProductPropertyFilterAHubVO } from 'valueObjects/ahub/library/product-property-filter.ahub.vo';
import { ProductPropertyIndexAHubVO } from 'valueObjects/ahub/library/product-property-index.ahub.vo';
import { ProductPropertySectionIndexAHubVO } from 'valueObjects/ahub/library/product-property-section-index.ahub.vo';
import { ProductPropertySectionAHubVO } from 'valueObjects/ahub/library/product-property-section.ahub.vo';
import { ProductPropertyTypeAHubVO } from 'valueObjects/ahub/library/product-property-type.ahub.vo';
import { ProductPropertyAHubVO } from 'valueObjects/ahub/library/product-property.ahub.vo';
import { ProductAHubVO } from 'valueObjects/ahub/library/product.ahub.vo';
import { ResourcePackAHubVO } from 'valueObjects/ahub/library/resource-pack.ahub.vo';
/**
 * ----------------------------------------------------------
 * Value Objects
 * ----------------------------------------------------------
 */
import { PresignedUrlAHubVO } from 'valueObjects/ahub/presigned-url.ahub.vo';
//System
import { SystemStatusAHubVO } from 'valueObjects/ahub/system/system-status.ahub.vo';
import { WorklogSegmentAHubVO } from 'valueObjects/ahub/work/worklog-segment.ahub.vo';
import { RequestActionStatusUploadVO } from 'valueObjects/app/request-action-status-upload.vo';
import { ActionExportTypeCodesAndNumber } from './types/hark.action-types';

/**
 * App Actions class
 */
export class AHubActions {

  /**
   * Event Type statics
   */
  static COMMS_ERROR = '[AHUB] COMMS_ERROR';

  static SYSTEM_DATABASE_VALIDATION_FILES_CREATE = '[AHUB] SYSTEM_DATABASE_VALIDATION_FILES_CREATE';
  static SYSTEM_STATUS_FETCH = '[AHUB] SYSTEM_STATUS_FETCH';
  static SYSTEM_STATUS_SET = '[AHUB] SYSTEM_STATUS_SET';
  static SYSTEM_MAINTANENCE_MODE_ON = '[AHUB] SYSTEM_MAINTANENCE_MODE_ON';
  static SYSTEM_MAINTANENCE_MODE_OFF = '[AHUB] SYSTEM_MAINTANENCE_MODE_OFF';

  static LOGIN_TOKEN_FETCH = '[AHUB] LOGIN_TOKEN_FETCH';
  static LOGIN_USER_VALIDATE = '[AHUB] LOGIN_USER_VALIDATE';
  static LOGIN_URL_FETCH = '[AHUB] LOGIN_URL_FETCH';
  static LOGIN_TOKEN_AUTHORIZE = '[AHUB] LOGIN_TOKEN_AUTHORIZE';
  static LOGIN_IDENTIFIER_PROVIDER_TOKEN_VALIDATE = '[AHUB] LOGIN_IDENTIFIER_PROVIDER_TOKEN_VALIDATE';
  static LOGIN_IDENTIFIER_PROVIDER_TOKEN_REGISTER = '[AHUB] LOGIN_IDENTIFIER_PROVIDER_TOKEN_REGISTER';
  static SESSION_TOKEN_FETCH = '[AHUB] SESSION_TOKEN_FETCH';
  static SESSION_TOKEN_SET = '[AHUB] SESSION_TOKEN_SET';

  static USER_SESSION_NEW_FROM_TOKEN = '[AHUB] USER_SESSION_NEW_FROM_TOKEN';
  static USER_CLIENTS_INDEXS_FETCH = '[AHUB] USER_CLIENTS_INDEXS_FETCH';
  static USER_CLIENTS_INDEXS_SET = '[AHUB] USER_CLIENTS_INDEXS_SET';
  static USER_ID_BY_SESSION_FETCH = '[AHUB] USER_ID_BY_SESSION_FETCH';

  static USER_HAS_AVIEWS_FETCH = '[AHUB] USER_HAS_AVIEWS_FETCH';
  static USER_HAS_AVIEWS_SET = '[AHUB] USER_HAS_AVIEWS_SET';

  static SESSION_USERS_BY_IDS_FETCH = '[AHUB] SESSION_USERS_BY_IDS_FETCH';
  static USERS_BY_IDS_FETCH = '[AHUB] USERS_BY_IDS_FETCH';
  static USER_COMMIT = '[AHUB] USER_COMMIT';
  static SESSION_USERS_SET = '[AHUB] SESSION_USERS_SET';
  static USERS_SET = '[AHUB] USERS_SET';

  static USER_INDEXES_BY_IDS_FETCH = '[AHUB] USER_INDEXES_BY_IDS_FETCH';
  static USER_INDEXES_SET = '[AHUB] USER_INDEXES_SET';
  static USER_INDEXES_REMOVE = '[AHUB] USER_INDEXES_REMOVE';
  static USER_INDEXES_CLEAR = '[AHUB] USER_INDEXES_CLEAR';

  static USER_INDEXES_FULL_SET = '[AHUB] USER_INDEXES_FULL_SET';
  static USER_INDEXES_PAGINATED_SET = '[AHUB] USER_INDEXES_PAGINATED_SET';
  static USER_INDEXES_FULL_FETCH = '[AHUB] USER_INDEXES_FULL_FETCH';
  static USER_INDEXES_BY_CLIENT_ID_FETCH = '[AHUB] USER_INDEXES_BY_CLIENT_ID_FETCH';

  static USER_EXTENDEDS_BY_IDS_FETCH = '[AHUB] USER_EXTENDEDS_BY_IDS_FETCH';
  static USER_EXTENDEDS_SET = '[AHUB] USER_EXTENDEDS_SET';

  static DATASET_INDEXS_FETCH = '[AHUB] DATASET_INDEXS_FETCH';
  static DATASET_INDEXS_SET = '[AHUB] DATASET_INDEXS_SET';
  static DATASETS_BY_ID_FETCH = '[AHUB] DATASETS_BY_ID_FETCH';
  static DATASETS_SET = '[AHUB] DATASETS_SET';
  static DATASET_ADD = '[AHUB] DATASET_ADD';
  static DATASET_SAVE = '[AHUB] DATASET_SAVE';
  static DATASET_DELETE = '[AHUB] DATASET_DELETE';
  static DATASET_COPY = '[AHUB] DATASET_COPY';
  static DATASET_SECTION_PROPERTIES_SWAP = '[AHUB] DATASET_SECTION_PROPERTIES_SWAP';
  static DATASETS_REMOVE = '[AHUB] DATASETS_REMOVE';
  static DATA_SET_PRODUCTS_BY_IDS_FETCH = '[AHUB] DATA_SET_PRODUCTS_BY_IDS_FETCH';
  static DATA_SET_PRODUCTS_SET = '[AHUB] DATA_SET_PRODUCTS_SET';
  static DATA_SET_PRODUCT_ASSETS_BY_IDS_FETCH = '[AHUB] DATA_SET_PRODUCT_ASSETS_BY_IDS_FETCH';
  static DATA_SET_PRODUCT_ASSETS_SET = '[AHUB] DATA_SET_PRODUCT_ASSETS_SET';
  static DATA_SET_PRODUCT_ASSET_ZIP_GENERATE = '[AHUB] DATA_SET_PRODUCT_ASSET_ZIP_GENERATE';

  static DATA_SET_CATEGORIES_BY_ID_FETCH = '[AHUB] DATA_SET_CATEGORIES_BY_ID_FETCH';
  static DATA_SET_CATEGORIES_SET = '[AHUB] DATA_SET_CATEGORIES_SET';
  static DATA_SET_CATEGORY_ADD = '[AHUB] DATA_SET_CATEGORY_ADD';
  static DATA_SET_CATEGORIES_COPY = '[AHUB] DATA_SET_CATEGORIES_COPY';
  static DATA_SET_CATEGORY_COMMIT = '[AHUB] DATA_SET_CATEGORY_COMMIT';
  static DATA_SET_CATEGORY_COMMIT_PRODUCT_PROPERTY_FILTER = '[AHUB] DATA_SET_CATEGORY_COMMIT_PRODUCT_PROPERTY_FILTER';
  static DATA_SET_CATEGORY_COMMIT_PRODUCT_CLASS_FILTER = '[AHUB] DATA_SET_CATEGORY_COMMIT_PRODUCT_CLASS_FILTER';
  static DATA_SET_CATEGORY_COMMIT_PRODUCT_SORT = '[AHUB] DATA_SET_CATEGORY_COMMIT_PRODUCT_SORT';
  static DATA_SET_CATEGORY_COMMIT_PRIORITY = '[AHUB] DATA_SET_CATEGORY_COMMIT_PRIORITY';
  static DATA_SET_CATEGORY_DELETE = '[AHUB] DATA_SET_CATEGORY_DELETE';
  static DATA_SET_CATEGORY_REMOVE = '[AHUB] DATA_SET_CATEGORY_REMOVE';
  static DATA_SET_CATEGORY_MOVE = '[AHUB] DATA_SET_CATEGORY_MOVE';

  static DATA_SET_CATEGORY_PRODUCTS_FETCH = '[AHUB] DATA_SET_CATEGORY_PRODUCTS_FETCH';
  static DATA_SET_CATEGORY_PRODUCTS_FETCH_BY_ID = '[AHUB] DATA_SET_CATEGORY_PRODUCTS_FETCH_BY_ID';
  static DATA_SET_CATEGORY_PRODUCTS_REMOVE = '[AHUB] DATA_SET_CATEGORY_PRODUCTS_REMOVE';
  static DATA_SET_CATEGORY_PRODUCTS_REMOVE_BEFORE_TIME = '[AHUB] DATA_SET_CATEGORY_PRODUCTS_REMOVE_BEFORE_TIME';
  static DATA_SET_CATEGORY_PRODUCTS_APPEND_AND_REMOVE = '[AHUB] DATA_SET_CATEGORY_PRODUCTS_APPEND_AND_REMOVE';
  static DATA_SET_CATEGORY_PRODUCTS_CLEAR = '[AHUB] DATA_SET_CATEGORY_PRODUCTS_CLEAR';
  static DATA_SET_CATEGORY_PRODUCT_ID_SORTED_FETCH = '[AHUB] DATA_SET_CATEGORY_PRODUCT_ID_SORTED_FETCH';
  static DATA_SET_CATEGORY_PRODUCT_ID_SORTED_SET = '[AHUB] DATA_SET_CATEGORY_PRODUCT_ID_SORTED_SET';

  static MY_THINGS_DATASET_CATEGORY_PRODUCTS_FETCH = '[AHUB] MY_THINGS_DATASET_CATEGORY_PRODUCTS_FETCH';

  static CLIENT_INDEXS_FETCH = '[AHUB] CLIENT_INDEXS_FETCH';
  static CLIENT_INDEXS_SET = '[AHUB] CLIENT_INDEXS_SET';

  static CLIENTS_BY_ID_FETCH = '[AHUB] CLIENTS_BY_ID_FETCH';
  static CLIENTS_SET = '[AHUB] CLIENTS_SET';
  static CLIENT_ADD = '[AHUB] CLIENT_ADD';
  static CLIENT_SAVE = '[AHUB] CLIENT_SAVE';
  static CLIENT_ENABLE = '[AHUB] CLIENT_ENABLE';
  static CLIENT_DISABLE = '[AHUB] CLIENT_DISABLE';
  static CLIENT_DELETE = '[AHUB] CLIENT_DELETE';


  static CLIENT_LIBRARY_VERSION_INDEXS_FETCH = '[AHUB] CLIENT_LIBRARY_VERSION_INDEXS_FETCH';
  static CLIENT_LIBRARY_VERSION_INDEXS_SET = '[AHUB] CLIENT_LIBRARY_VERSION_INDEXS_SET';

  static CLIENTS_LIBRARY_VERSION_BY_ID_FETCH = '[AHUB] CLIENTS_LIBRARY_VERSION_BY_ID_FETCH';
  static CLIENTS_LIBRARY_VERSION_SET = '[AHUB] CLIENTS_LIBRARY_VERSION_SET';
  static CLIENT_LIBRARY_VESION_MODEL_URL_FETCH = '[AHUB] CLIENT_LIBRARY_VESION_MODEL_URL_FETCH';
  static CLIENT_LIBRARY_VESION_MODEL_URLS_SET = '[AHUB] CLIENT_LIBRARY_VESION_MODEL_URLS_SET';
  static CLIENTS_LIBRARY_VERSION_SAVE_BUILD_FROM = '[AHUB] CLIENTS_LIBRARY_VERSION_SAVE_BUILD_FROM';
  static CLIENT_LIBRARY_VERSION_MODEL_GENERATE = '[AHUB] CLIENT_LIBRARY_VERSION_MODEL_GENERATE';
  static CLIENT_LIBRARY_VERSION_ADD = '[AHUB] CLIENT_LIBRARY_VERSION_ADD';
  static CLIENT_LIBRARY_VERSION_DELETE = '[AHUB] CLIENT_LIBRARY_VERSION_DELETE';
  static CLIENT_LIBRARY_VERSION_COMMIT = '[AHUB] CLIENT_LIBRARY_VERSION_COMMIT';
  static CLIENT_LIBRARY_VERSION_VALIDATE = '[AHUB] CLIENT_LIBRARY_VERSION_VALIDATE';

  static CLIENT_LIBRARY_VERSIONS_DISCOVER = '[AHUB] CLIENT_LIBRARY_VERSIONS_SEARCH';

  static CLIENT_LIBRARY_INDEXES_FETCH = '[AHUB] CLIENT_LIBRARY_INDEXES_FETCH';
  static CLIENTS_LIBRARY_INDEXES_SET = '[AHUB] CLIENTS_LIBRARY_INDEXES_SET';
  static CLIENT_STORAGE_INDEXES_FETCH = '[AHUB] CLIENT_STORAGE_INDEXES_FETCH';
  static CLIENT_STORAGE_INDEXES_SET = '[AHUB] CLIENTS_STORAGE_INDEXES_SET';
  static CLIENTS_LIBRARIES_BY_ID_FETCH = '[AHUB] CLIENTS_LIBRARIES_BY_ID_FETCH';
  static CLIENTS_LIBRARIES_REMOVE = '[AHUB] CLIENTS_LIBRARIES_REMOVE';
  static CLIENTS_LIBRARIES_SET = '[AHUB] CLIENTS_LIBRARIES_SET';
  static CLIENT_LIBRARY_UPGRADE_AND_USE = '[AHUB] CLIENT_LIBRARY_UPGRADE_AND_USE';
  static CLIENT_LIBRARY_SYNC = '[AHUB] CLIENT_LIBRARY_SYNC';
  static CLIENT_LIBRARY_SYNC_FROM = '[AHUB] CLIENT_LIBRARY_SYNC_FROM';
  static CLIENT_LIBRARY_ADD = '[AHUB] CLIENT_LIBRARY_ADD';
  static CLIENT_LIBRARY_DELETE = '[AHUB] CLIENT_LIBRARY_DELETE';
  static CLIENT_LIBRARY_USE = '[AHUB] CLIENT_LIBRARY_USE';
  static CLIENT_LIBRARY_SNAPSHOT = '[AHUB] CLIENT_LIBRARY_SNAPSHOT';
  static CLIENT_LIBRARY_COMMIT = '[AHUB] CLIENT_LIBRARY_COMMIT';

  static DISTRIBUTIONS_BY_IDS_FETCH = '[AHUB] DISTRIBUTIONS_BY_IDS_FETCH';
  static DISTRIBUTIONS_SET = '[AHUB] DISTRIBUTIONS_SET';
  static DISTRIBUTIONS_REMOVE = '[AHUB] DISTRIBUTIONS_REMOVE';

  static DISTRIBUTION_ADD = '[AHUB] DISTRIBUTION_ADD';
  static DISTRIBUTION_SAVE = '[AHUB] DISTRIBUTION_SAVE';
  static DISTRIBUTION_DELETE = '[AHUB] DISTRIBUTION_DELETE';
  static DISTRIBUTIONS_DELETE = '[AHUB] DISTRIBUTIONS_DELETE';
  static DISTRIBUTIONS_DISABLE = '[AHUB] DISTRIBUTIONS_DISABLE';
  static DISTRIBUTIONS_ENABLE = '[AHUB] DISTRIBUTIONS_ENABLE';
  static DISTRIBUTION_PARAMETERS_COPY = '[AHUB] DISTRIBUTION_PARAMETERS_COPY';

  static DISTRIBUTION_GROUPS_FETCH = '[AHUB] DISTRIBUTION_GROUPS_FETCH';
  static DISTRIBUTION_GROUPS_SET = '[AHUB] DISTRIBUTION_GROUPS_SET';
  static DISTRIBUTION_GROUPS_REMOVE = '[AHUB] DISTRIBUTION_GROUPS_REMOVE';
  static DISTRIBUTION_GROUP_INDEXS_FETCH = '[AHUB] DISTRIBUTION_GROUP_INDEXS_FETCH';
  static DISTRIBUTION_GROUP_INDEXES_BY_CLIENT_ID_FETCH = '[AHUB] DISTRIBUTION_GROUP_INDEXES_BY_CLIENT_ID_FETCH';
  static DISTRIBUTION_GROUP_INDEXES_BY_CLIENT_ID_APPEND = '[AHUB] DISTRIBUTION_GROUP_INDEXES_BY_CLIENT_ID_APPEND';
  static DISTRIBUTION_GROUP_INDEXES_BY_USER_ID_FETCH = '[AHUB] DISTRIBUTION_GROUP_INDEXES_BY_USER_ID_FETCH';
  static DISTRIBUTION_GROUP_INDEXES_BY_CLIENT_ID_AND_USER_ID_FETCH = '[AHUB] DISTRIBUTION_GROUP_INDEXES_BY_CLIENT_ID_AND_USER_ID_FETCH';
  static DISTRIBUTION_GROUP_INDEXS_SET = '[AHUB] DISTRIBUTION_GROUP_INDEXS_SET';
  static DISTRIBUTION_GROUP_INDEXS_APPEND = '[AHUB] DISTRIBUTION_GROUP_INDEXS_APPEND';
  static DISTRIBUTION_GROUP_DISTRIBUTION_INDEXS_FETCH = '[AHUB] DISTRIBUTION_GROUP_DISTRIBUTION_INDEXS_FETCH';
  static DISTRIBUTION_GROUP_DISTRIBUTION_INDEXS_SET = '[AHUB] DISTRIBUTION_GROUP_DISTRIBUTION_INDEXS_SET';
  static DISTRIBUTION_GROUP_DISTRIBUTION_INDEXES_CLEAR = '[AHUB] DISTRIBUTION_GROUP_DISTRIBUTION_INDEXES_CLEAR';
  static DISTRIBUTION_GROUP_USER_INDEXS_FETCH = '[AHUB] DISTRIBUTION_GROUP_USER_INDEXS_FETCH';
  static DISTRIBUTION_GROUP_USER_INDEXS_SET = '[AHUB] DISTRIBUTION_GROUP_USER_INDEXS_SET';
  static DISTRIBUTION_GROUP_ADD = '[AHUB] DISTRIBUTION_GROUP_ADD';
  static DISTRIBUTION_GROUP_SAVE = '[AHUB] DISTRIBUTION_GROUP_SAVE';
  static DISTRIBUTION_GROUP_DELETE = '[AHUB] DISTRIBUTION_GROUP_DELETE';
  static DISTRIBUTION_GROUP_USER_EMAIL_ADD = '[AHUB] DISTRIBUTION_GROUP_USER_EMAIL_ADD';
  static DISTRIBUTION_GROUP_USER_ID_REMOVE = '[AHUB] DISTRIBUTION_GROUP_USER_ID_REMOVE';
  static DISTRIBUTION_GROUP_USER_IDS_REMOVE = '[AHUB] DISTRIBUTION_GROUP_USER_IDS_REMOVE';
  static DISTRIBUTION_GROUPS_USER_ID_REMOVE = '[AHUB] DISTRIBUTION_GROUPS_USER_ID_REMOVE';
  static DISTRIBUTION_GROUPS_USER_ID_ADD = '[AHUB] DISTRIBUTION_GROUPS_USER_ID_ADD';
  static DISTRIBUTION_GROUP_USER_JOIN_BY_KEY = '[AHUB] DISTRIBUTION_GROUP_USER_JOIN_BY_KEY';

  static EXPORT_TYPES_ALL_FETCH = '[AHUB] EXPORT_TYPES_ALL_FETCH';
  static EXPORT_TYPES_ALL_SET = '[AHUB] EXPORT_TYPES_ALL_SET';

  static EXPORT_GENERATORS_ALL_FETCH = '[AHUB] EXPORT_GENERATORS_ALL_FETCH';
  static EXPORT_GENERATORS_ALL_SET = '[AHUB] EXPORT_GENERATORS_ALL_SET';
  static EXPORT_GENERATOR_MAPPING_BLUEPRINT_URL_FETCH = '[AHUB] EXPORT_GENERATOR_MAPPING_BLUEPRINT_URL_FETCH';
  static EXPORT_GENERATOR_MAPPING_BLUEPRINT_URL_SET = '[AHUB] EXPORT_GENERATOR_MAPPING_BLUEPRINT_URL_SET';

  static EXPORTS_BY_ID_FETCH = '[AHUB] EXPORTS_BY_ID_FETCH';
  static EXPORTS_SET = '[AHUB] EXPORTS_SET';
  static EXPORTS_REMOVE = '[AHUB] EXPORTS_REMOVE';

  static EXPORT_INDEXS_FETCH = '[AHUB] EXPORT_INDEXS_FETCH';
  static EXPORT_INDEXS_SET = '[AHUB] EXPORT_INDEXS_SET';
  static EXPORT_DISTRIBUTION_INDEXS_FETCH = '[AHUB] EXPORT_DISTRIBUTION_INDEXS_FETCH';
  static EXPORT_DISTRIBUTION_INDEXS_SET = '[AHUB] EXPORT_DISTRIBUTION_INDEXS_SET';
  static EXPORT_DISTRIBUTION_INDEXES_CLEAR = '[AHUB] EXPORT_DISTRIBUTION_INDEXES_CLEAR';
  static EXPORT_ADD = '[AHUB] EXPORT_ADD';
  static EXPORT_SAVE = '[AHUB] EXPORT_SAVE';
  static EXPORT_DELETE = '[AHUB] EXPORT_DELETE';
  static EXPORT_UPDATE_CANCEL_REMOVE = '[AHUB] EXPORT_UPDATE_CANCEL_REMOVE';
  static EXPORT_PREVIEWIMAGE_URL_FETCH = '[AHUB] EXPORT_PREVIEWIMAGE_URL_FETCH';
  static EXPORT_PREVIEWIMAGE_URL_SET = '[AHUB] EXPORT_PREVIEWIMAGE_URL_SET';
  static EXPORT_LATEST_VERSION_PATH_URL_FETCH = '[AHUB] EXPORT_LATEST_VERSION_PATH_URL_FETCH';
  static EXPORT_LATEST_VERSION_PATH_URL_SET = '[AHUB] EXPORT_LATEST_VERSION_PATH_URL_SET';
  static EXPORT_VERSION_PATH_URL_FETCH = '[AHUB] EXPORT_VERSION_PATH_URL_FETCH';
  static EXPORT_VERSION_PATH_URL_SET = '[AHUB] EXPORT_VERSION_PATH_URL_SET';
  static EXPORT_VERSION_COMMIT = '[AHUB] EXPORT_VERSION_COMMIT';
  static EXPORT_VERSION_DELETE = '[AHUB] EXPORT_VERSION_DELETE';
  static EXPORT_VERSION_WITHDRAW = '[AHUB] EXPORT_VERSION_WITHDRAW';
  static EXPORT_VERSION_STATE_HIDDEN_SAVE = '[AHUB] EXPORT_VERSION_STATE_HIDDEN_SAVE';
  static EXPORT_VERSION_STATE_INTERNAL_SAVE = '[AHUB] EXPORT_VERSION_STATE_INTERNAL_SAVE';
  static EXPORT_VERSION_STATE_PREVIEW_SAVE = '[AHUB] EXPORT_VERSION_STATE_PREVIEW_SAVE';
  static EXPORT_VERSION_STATE_LIMITED_RELEASE_SAVE = '[AHUB] EXPORT_VERSION_STATE_LIMITED_RELEASE_SAVE';
  static EXPORT_VERSION_STATE_RELEASE_SAVE = '[AHUB] EXPORT_VERSION_STATE_RELEASE_SAVE';
  static EXPORT_VERSION_FILES_ZIP_DOWNLOAD = '[AHUB] EXPORT_VERSION_FILES_ZIP_DOWNLOAD';
  static EXPORT_LEGACY_REF_FETCH = '[AHUB] EXPORT_LEGACY_REF_FETCH';
  static EXPORT_LEGACY_REF_SET = '[AHUB] EXPORT_LEGACY_REF_SET';
  static EXPORT_LEGACY_REF_COMMIT = '[AHUB] EXPORT_LEGACY_REF_COMMIT';

  static EXPORT_DISTRIBUTIONS_FETCH = '[AHUB] EXPORT_DISTRIBUTIONS_FETCH';
  static EXPORT_DISTRIBUTIONS_SET = '[AHUB] EXPORT_DISTRIBUTIONS_SET';

  static EXPORTER_INDEXES_FETCH = '[AHUB] EXPORTER_INDEXES_FETCH';
  static EXPORTER_INDEXES_SET = '[AHUB] EXPORTER_INDEXES_SET';
  static EXPORTERS_BY_ID_FETCH = '[AHUB] EXPORTERS_BY_ID_FETCH';
  static EXPORTERS_SET = '[AHUB] EXPORTERS_SET';
  static EXPORTER_ADD = '[AHUB] EXPORTER_ADD';
  static EXPORTER_SAVE = '[AHUB] EXPORTER_SAVE';
  static EXPORTER_DELETE = '[AHUB] EXPORTER_DELETE';
  static EXPORTER_COPY = '[AHUB] EXPORTER_COPY';
  static EXPORTER_BUILD = '[AHUB] EXPORTER_BUILD';
  static EXPORTER_CANCEL_BUILD = '[AHUB] EXPORTER_CANCEL_BUILD'
  static EXPORTERS_REMOVE = '[AHUB] EXPORTERS_REMOVE';
  static EXPORTER_MAPPING_COMMIT = '[AHUB] EXPORTER_MAPPING_COMMIT';
  static EXPORTER_SETTINGS_COMMIT = '[AHUB] EXPORTER_SETTINGS_COMMIT';

  static EXPORTER_BUILD_HISTORY_INDEX_FETCH = '[AHUB] EXPORTER_BUILD_HISTORY_INDEX_FETCH';
  static EXPORTER_BUILD_HISTORY_INDEX_SET = '[AHUB] EXPORTER_BUILD_HISTORY_INDEX_SET';
  static EXPORTER_BUILD_HISTORYS_BY_IDS_FETCH = '[AHUB] EXPORTER_BUILD_HISTORYS_BY_IDS_FETCH';
  static EXPORTER_BUILD_HISTORYS_SET = '[AHUB] EXPORTER_BUILD_HISTORYS_SET';
  static EXPORTER_BUILD_HISTORYS_REMOVE = '[AHUB] EXPORTER_BUILD_HISTORYS_REMOVE';

  static STORE_TEMPORARY_CLEAR = '[AHUB] STORE_TEMPORARY_CLEAR';
  static STORE_PERMANENT_CLEAR = '[AHUB] STORE_PERMANENT_CLEAR';

  static WORKFLOW_FILES_DOWNLOAD = '[AHUB] WORKFLOW_FILES_DOWNLOAD';

  static WORKLOG_FILE_DOWNLOAD = '[AHUB] WORKLOG_FILE_DOWNLOAD';

  static WORKLOG_SEGMENT_FETCH = '[AHUB] WORKLOG_SEGMENT_FETCH';
  static WORKLOG_SEGMENT_FETCH_ERROR = '[AHUB] WORKLOG_SEGMENT_FETCH_ERROR'; // Specific Error action - non generic handling.
  static WORKLOG_SEGMENT_SET = '[AHUB] WORKLOG_SEGMENT_SET';
  static WORKLOG_PURGE = '[AHUB] WORKLOG_PURGE';

  static WORK_GROUPS_REMOVE = '[AHUB] WORK_GROUPS_REMOVE';
  static WORK_GROUPS_FETCH = '[AHUB] WORK_GROUPS_FETCH';
  static WORK_GROUPS_BY_USER_AND_CLIENT_FETCH = '[AHUB] WORK_GROUPS_BY_USER_AND_CLIENT_FETCH';
  static WORK_GROUPS_PERMANENT_SET = '[AHUB] WORK_GROUPS_PERMANENT_SET';
  static WORK_GROUPS_SET = '[AHUB] WORK_GROUPS_SET';

  static WORK_GROUP_INDEXS_FETCH = '[AHUB] WORK_GROUP_INDEXS_FETCH';
  static WORK_GROUP_INDEXS_SET = '[AHUB] WORK_GROUP_INDEXS_SET';
  static WORK_GROUP_ADD = '[AHUB] WORK_GROUP_ADD';
  static WORK_GROUP_SAVE = '[AHUB] WORK_GROUP_SAVE';
  static WORK_GROUP_DELETE = '[AHUB] WORK_GROUP_DELETE';
  static WORK_GROUP_USER_INDEXS_FETCH = '[AHUB] WORK_GROUP_USER_INDEXS_FETCH';
  static WORK_GROUP_USER_INDEXS_SET = '[AHUB] WORK_GROUP_USER_INDEXS_SET';
  static WORK_GROUP_USER_EMAIL_ADD = '[AHUB] WORK_GROUP_USER_EMAIL_ADD';
  static WORK_GROUP_USER_ID_REMOVE = '[AHUB] WORK_GROUP_USER_ID_REMOVE';
  static WORK_GROUP_USER_IDS_REMOVE = '[AHUB] WORK_GROUP_USER_IDS_REMOVE';
  static WORK_GROUP_USER_JOIN_BY_KEY = '[AHUB] WORK_GROUP_USER_JOIN_BY_KEY';

  static CLIENT_QUOTAS_BY_CLIENT_ID_FETCH = '[AHUB] CLIENT_QUOTAS_BY_CLIENT_ID_FETCH';
  static CLIENT_QUOTAS_HISTORY_BY_CLIENT_ID_FETCH = '[AHUB] CLIENT_QUOTAS_HISTORY_BY_CLIENT_ID_FETCH';
  static CLIENT_QUOTA_BY_CLIENT_QUOTA_ID_FETCH = '[AHUB] CLIENT_QUOTA_BY_CLIENT_QUOTA_ID_FETCH';
  static CLIENT_QUOTAS_SET = '[AHUB] CLIENT_QUOTAS_SET';
  static CLIENT_QUOTAS_HISTORY_SET = '[AHUB] CLIENT_QUOTAS_HISTORY_SET';
  static CLIENT_QUOTA_SET = '[AHUB] CLIENT_QUOTA_SET';
  static CLIENT_QUOTAS_COMMIT = '[AHUB] CLIENT_QUOTAS_COMMIT';
  static CLIENT_CONFIGURATION_BY_CLIENT_ID_FETCH = '[AHUB] CLIENT_CONFIGURATION_BY_CLIENT_ID_FETCH';
  static CLIENT_CONFIGURATION_SET = '[AHUB] CLIENT_CONFIGURATION_SET';
  static CLIENT_CONFIGURATION_COMMIT = '[AHUB] CLIENT_CONFIGURATION_COMMIT';

  static REGISTER_USER = '[AHUB] REGISTER_USER';
  static VERIFY_USER = '[AHUB] VERIFY_USER';
  static VERIFY_EMAIL_UPDATE = '[AHUB] VERIFY_EMAIL_UPDATE';
  static VERIFY_USER_MANUAL_OVERRIDE = '[AHUB] VERIFY_USER_MANUAL_OVERRIDE';
  static SEND_VERIFICATION_CODE = '[AHUB] RESEND_REGISTRATION_VERIFICATION_CODE';
  static VERIFICATION_PROBLEMS_CONTACT_SUPPORT = '[AHUB] VERIFICATION_PROBLEMS_CONTACT_SUPPORT';
  static SEND_EMAIL_UPDATE_VERIFICATION_CODE = '[AHUB] SEND_EMAIL_UPDATE_VERIFICATION_CODE';

  static PRODUCT_CLASS_PRODUCT_COUNTS_FETCH = '[AHUB] PRODUCT_CLASS_PRODUCT_COUNTS_FETCH';
  static PRODUCT_CLASS_PRODUCT_COUNTS_SET = '[AHUB] PRODUCT_CLASS_PRODUCT_COUNTS_SET';
  static PRODUCT_CLASS_INDEXS_BY_CLIENT_ID_FETCH = '[AHUB] PRODUCT_CLASS_INDEXS_BY_CLIENT_ID_FETCH';
  static PRODUCT_CLASS_INDEXS_SET = '[AHUB] PRODUCT_CLASS_INDEXS_SET';
  static PRODUCT_CLASSES_FETCH = '[AHUB] PRODUCT_CLASS_FETCH';
  static PRODUCT_CLASSES_SET = '[AHUB] PRODUCT_CLASS_SET';
  static PRODUCT_CLASS_ADD = '[AHUB] PRODUCT_CLASS_ADD';
  static PRODUCT_CLASS_COMMIT = '[AHUB] PRODUCT_CLASS_COMMIT';
  static PRODUCT_CLASS_DELETE = '[AHUB] PRODUCT_CLASS_DELETE';
  static PRODUCT_CLASS_MOVE = '[AHUB] PRODUCT_CLASS_MOVE';
  static PRODUCT_CLASSES_REMOVE = '[AHUB] PRODUCT_CLASSES_REMOVE';


  static PRODUCT_PROPERTY_TYPES_BY_CLIENT_ID_FETCH = '[AHUB] PRODUCT_PROPERTY_TYPES_BY_CLIENT_ID_FETCH';
  static PRODUCT_PROPERTY_TYPES_SET = '[AHUB] PRODUCT_PROPERTY_TYPES_SET';
  static PRODUCT_PROPERTY_INDEXS_BY_CLIENT_ID_FETCH = '[AHUB] PRODUCT_PROPERTY_INDEXS_BY_CLIENT_ID_FETCH';
  static PRODUCT_PROPERTY_INDEXS_SET = '[AHUB] PRODUCT_PROPERTY_INDEXS_SET';
  static PRODUCT_PROPERTY_FETCH = '[AHUB] PRODUCT_PROPERTY_FETCH';
  static PRODUCT_PROPERTY_SET = '[AHUB] PRODUCT_PROPERTY_SET';
  static PRODUCT_PROPERTY_ADD = '[AHUB] PRODUCT_PROPERTY_ADD';
  static PRODUCT_PROPERTY_COMMIT = '[AHUB] PRODUCT_PROPERTY_COMMIT';
  static PRODUCT_PROPERTY_DELETE = '[AHUB] PRODUCT_PROPERTY_DELETE';
  static PRODUCT_PROPERTY_REMOVE = '[AHUB] PRODUCT_PROPERTY_REMOVE';

  static PRODUCT_PROPERTY_SECTION_INDEXS_BY_CLIENT_ID_FETCH = '[AHUB] PRODUCT_PROPERTY_SECTION_INDEXS_BY_CLIENT_ID_FETCH';
  static PRODUCT_PROPERTY_SECTION_INDEXS_SET = '[AHUB] PRODUCT_PROPERTY_SECTION_INDEXS_SET';

  static PRODUCT_PROPERTY_SECTIONS_FETCH = '[AHUB] PRODUCT_PROPERTY_SECTION_FETCH';
  static PRODUCT_PROPERTY_SECTIONS_SET = '[AHUB] PRODUCT_PROPERTY_SECTION_SET';
  static PRODUCT_PROPERTY_SECTION_ADD = '[AHUB] PRODUCT_PROPERTY_SECTION_ADD';
  static PRODUCT_PROPERTY_SECTION_COPY = '[AHUB] PRODUCT_PROPERTY_SECTION_COPY';
  static PRODUCT_PROPERTY_SECTION_COMMIT = '[AHUB] PRODUCT_PROPERTY_SECTION_COMMIT';
  static PRODUCT_PROPERTY_SECTION_DELETE = '[AHUB] PRODUCT_PROPERTY_SECTION_DELETE';
  static PRODUCT_PROPERTY_SECTIONS_REMOVE = '[AHUB] PRODUCT_PROPERTY_SECTION_REMOVE';

  static PRODUCT_PROPERTY_ALLOCATION_INDEXS_FETCH = '[AHUB] PRODUCT_PROPERTY_ALLOCATION_INDEXS_FETCH';
  static PRODUCT_PROPERTY_ALLOCATION_INDEXS_SET = '[AHUB] PRODUCT_PROPERTY_ALLOCATION_INDEXS_SET';
  static PRODUCT_PROPERTY_ALLOCATIONS_ADD = '[AHUB] PRODUCT_PROPERTY_ALLOCATION_ADD';
  static PRODUCT_PROPERTY_ALLOCATION_DELETE = '[AHUB] PRODUCT_PROPERTY_ALLOCATION_DELETE';

  static PRODUCT_PROPERTY_ALLOCATION_CHAIN_INDEXS_FETCH = '[AHUB] PRODUCT_PROPERTY_ALLOCATION_CHAIN_INDEXS_FETCH';
  static PRODUCT_PROPERTY_ALLOCATION_CHAIN_INDEXS_SET = '[AHUB] PRODUCT_PROPERTY_ALLOCATION_CHAIN_INDEXS_SET';

  static PRODUCT_PROPERTY_ALLOCATION_CHAINS_FETCH = '[AHUB] PRODUCT_PROPERTY_ALLOCATION_CHAINS_FETCH';
  static PRODUCT_PROPERTY_ALLOCATION_CHAINS_SET = '[AHUB] PRODUCT_PROPERTY_ALLOCATION_CHAINS_SET';
  static PRODUCT_PROPERTY_ALLOCATION_CHAIN_ADD = '[AHUB] PRODUCT_PROPERTY_ALLOCATION_CHAIN_ADD';
  static PRODUCT_PROPERTY_ALLOCATION_CHAIN_DELETE = '[AHUB] PRODUCT_PROPERTY_ALLOCATION_CHAIN_DELETE';
  static PRODUCT_PROPERTY_ALLOCATION_CHAINS_REMOVE = '[AHUB] PRODUCT_PROPERTY_ALLOCATION_CHAINS_REMOVE';
  static PRODUCT_PROPERTY_ALLOCATION_CHAIN_VALUES_COMMIT = '[AHUB] PRODUCT_PROPERTY_ALLOCATION_CHAIN_VALUES_COMMIT';
  static PRODUCT_PROPERTY_ALLOCATION_CHAIN_VALIDATE = '[AHUB] PRODUCT_PROPERTY_ALLOCATION_CHAIN_VALIDATE';
  static PRODUCT_PROPERTY_ALLOCATION_CHAIN_REBUILD = '[AHUB] PRODUCT_PROPERTY_ALLOCATION_CHAIN_REBUILD';
  static PRODUCT_PROPERTY_ALLOCATION_CHAIN_ALL_VALUES_APPLY = '[AHUB] PRODUCT_PROPERTY_ALLOCATION_CHAIN_ALL_VALUES_APPLY';
  static PRODUCT_PROPERTY_ALLOCATION_CHAIN_ALL_VALUES_APPLY_TO_ALL = '[AHUB] PRODUCT_PROPERTY_ALLOCATION_CHAIN_ALL_VALUES_APPLY_TO_ALL';
  static PRODUCT_PROPERTY_ALLOCATION_CHAIN_VALUE_CHAINED_VALUES_FETCH = '[AHUB] PRODUCT_PROPERTY_ALLOCATION_CHAIN_VALUE_CHAINED_VALUES_FETCH';
  static PRODUCT_PROPERTY_ALLOCATION_CHAIN_VALUE_APPLY = '[AHUB] PRODUCT_PROPERTY_ALLOCATION_CHAIN_VALUE_APPLY';
  static PRODUCT_PROPERTY_ALLOCATION_CHAIN_VALUE_APPLY_TO_ALL = '[AHUB] PRODUCT_PROPERTY_ALLOCATION_CHAIN_VALUE_APPLY_TO_ALL';

  static EXTRACT_DEFINITION_INDEXES_FETCH = '[AHUB] EXTRACT_DEFINITION_INDEXES_FETCH';
  static EXTRACT_DEFINITION_INDEXES_SET = '[AHUB] EXTRACT_DEFINITION_INDEXES_SET';
  static EXTRACT_DEFINITION_EXTRACT_INDEXES_FETCH = '[AHUB] EXTRACT_DEFINITION_EXTRACT_INDEXES_FETCH';
  static EXTRACT_DEFINITION_SET = '[AHUB] EXTRACT_DEFINITION_SET';
  static EXTRACT_DEFINITION_ADD = '[AHUB] EXTRACT_DEFINITION_ADD';
  static EXTRACT_DEFINITION_COMMIT = '[AHUB] EXTRACT_DEFINITION_COMMIT';
  static EXTRACT_DEFINITIONS_FETCH = '[AHUB] EXTRACT_DEFINITIONS_FETCH';
  static EXTRACT_DEFINITION_DELETE = '[AHUB] EXTRACT_DEFINITIONS_DELETE';
  static EXTRACT_DEFINITIONS_REMOVE = '[AHUB] EXTRACT_DEFINITIONS_REMOVE';

  static EXTRACT_INDEXES_FETCH = '[AHUB] EXTRACT_INDEXES_FETCH';
  static EXTRACT_INDEXES_SET = '[AHUB] EXTRACT_INDEXES_SET';
  static EXTRACTS_FETCH = '[AHUB] EXTRACTS_FETCH;'
  static EXTRACTS_SET = '[AHUB] EXTRACTS_SET;'
  static EXTRACT_ADD = '[AHUB] EXTRACT_ADD;'
  static EXTRACT_COMMIT = '[AHUB] EXTRACT_COMMIT;'
  static EXTRACT_DEFAULT_VALUE_RULES_COMMIT = '[AHUB] EXTRACT_DEFAULT_VALUE_RULES_COMMIT;'
  static EXTRACT_DELETE = '[AHUB] EXTRACT_DELETE;'
  static EXTRACTS_REMOVE = '[AHUB] EXTRACTS_REMOVE;'
  static EXTRACT_COMMIT_PRODUCT_PROPERTY_FILTER = '[AHUB] EXTRACT_COMMIT_PRODUCT_PROPERTY_FILTER';
  static EXTRACTS_CONFLICTING_FETCH = '[AHUB] EXTRACTS_CONFLICTING_FETCH';
  static EXTRACT_STATE_WIP = '[AHUB] EXTRACT_STATE_WIP';
  static EXTRACT_STATE_COMPLETE = '[AHUB] EXTRACT_STATE_COMPLETE';
  static EXTRACT_STATE_ARCHIVE = '[AHUB] EXTRACT_STATE_ARCHIVE';
  static EXTRACT_PRODUCT_PROPERTY_COMPLETENESS_FETCH = '[AHUB] EXTRACT_PRODUCT_PROPERTY_COMPLETENESS_FETCH';
  static EXTRACT_PRODUCT_PROPERTY_COMPLETENESS_SET = '[AHUB] EXTRACT_PRODUCT_PROPERTY_COMPLETENESS_SET';
  static EXTRACT_PRODUCT_PROPERTY_COMPLETENESS_DELETE = '[AHUB] EXTRACT_PRODUCT_PROPERTY_COMPLETENESS_DELETE';
  static EXTRACT_PRODUCT_PROPERTY_COMPLETENESS_PAGINATION_PAGE_FETCH = '[AHUB] EXTRACT_PRODUCT_PROPERTY_COMPLETENESS_PAGINATION_PAGE_FETCH';
  static EXTRACT_PRODUCT_PROPERTY_COMPLETENESS_PAGINATION_PAGE_SET = '[AHUB] EXTRACT_PRODUCT_PROPERTY_COMPLETENESS_PAGINATION_PAGE_SET';
  static EXTRACT_DOCUMENT_SETTINGS_COMMIT = '[AHUB] EXTRACT_DOCUMENT_SETTINGS_COMMIT';


  static EXTRACT_HISTORY_INDEXES_FETCH = '[AHUB] EXTRACT_HISTORY_INDEXES_FETCH';
  static EXTRACT_HISTORY_INDEXES_SET = '[AHUB] EXTRACT_HISTORY_INDEXES_SET';
  static EXTRACT_HISTORY_PRODUCT_SOURCE_DOWNLOAD = '[AHUB] EXTRACT_HISTORY_PRODUCT_SOURCE_DOWNLOAD';

  static EXTRACT_PRODUCTS_FETCH = '[AHUB] PRODUCTS_BY_EXTRACT_ID_FETCH';
  static EXTRACT_PRODUCTS_FETCH_BY_IDS = '[AHUB] PRODUCTS_BY_PRODUCT_IDS_AND_EXTRACT_ID_FETCH';
  static EXTRACT_PRODUCTS_REMOVE = '[AHUB] EXTRACT_PRODUCTS_REMOVE';
  static EXTRACT_PRODUCTS_REMOVE_BEFORE_TIME = '[AHUB] EXTRACT_PRODUCTS_REMOVE_BEFORE_TIME';
  static EXTRACT_PRODUCTS_UPDATE_SET = '[AHUB] EXTRACT_PRODUCTS_PAGINATED_SET';
  static EXTRACT_PRODUCT_DOCUMENT_CREATE = '[AHUB] EXTRACT_PRODUCT_DOCUMENT_CREATE';
  static EXTRACT_PRODUCTS_COMMIT_OBJECTS_ONLY = '[AHUB] EXTRACT_PRODUCTS_COMMIT_OBJECTS_ONLY';
  static EXTRACT_PRODUCTS_COMMIT_OBJECTS_AND_ASSETS = '[AHUB] EXTRACT_PRODUCTS_COMMIT_OBJECTS_AND_ASSETS';
  static EXTRACT_PRODUCTS_BULK_UPLOAD = '[AHUB] EXTRACT_PRODUCTS_BULK_UPLOAD';
  static EXTRACT_PRODUCTS_DELETE = '[AHUB] EXTRACT_PRODUCTS_DELETE';
  static EXTRACT_PRODUCTS_CLEAR = '[AHUB] EXTRACT_PRODUCTS_CLEAR';
  static EXTRACT_PRODUCT_ASSET_MATCH = '[AHUB] EXTRACT_PRODUCT_ASSET_MATCH';
  static EXTRACT_PRODUCT_ASSET_MATCH_SET = '[AHUB] EXTRACT_PRODUCT_ASSET_MATCH_SET';
  static EXTRACT_PRODUCT_SECTION_PROPERTY_ASSET_COMMIT = '[AHUB] EXTRACT_PRODUCT_SECTION_PROPERTY_ASSET_COMMIT';
  static EXTRACT_PRODUCT_ASSET_ZIP_GENERATE = '[AHUB] EXTRACT_PRODUCT_ASSET_ZIP_GENERATE';
  static EXTRACT_PRODUCT_SECTION_PROPERTY_ASSET_DELETE = '[AHUB] EXTRACT_PRODUCT_SECTION_PROPERTY_ASSET_DELETE';
  static EXTRACT_PRODUCT_ASSET_URLS_FETCH = '[AHUB] EXTRACT_PRODUCT_ASSET_URLS_FETCH';
  static EXTRACT_PRODUCTS_ASSETS_SET = '[AHUB] EXTRACT_PRODUCTS_ASSETS_SET';


  static RESOURCE_PACK_INDEXES_FETCH = '[AHUB] RESOURCE_PACK_INDEXES_FETCH';
  static RESOURCE_PACK_INDEXES_SET = '[AHUB] RESOURCE_PACK_INDEXES_SET';
  static RESOURCE_PACKS_FETCH = '[AHUB] RESOURCE_PACKS_FETCH';
  static RESOURCE_PACKS_SET = '[AHUB] RESOURCE_PACKS_SET';
  static RESOURCE_PACK_ADD = '[AHUB] RESOURCE_PACK_ADD';
  static RESOURCE_PACK_COMMIT = '[AHUB] RESOURCE_PACK_COMMIT';
  static RESOURCE_PACK_DELETE = '[AHUB] RESOURCE_PACK_DELETE';
  static RESOURCE_PACKS_REMOVE = '[AHUB] RESOURCE_PACKS_REMOVE';
  static RESOURCE_PACK_FILES_UPLOAD = '[AHUB] RESOURCE_PACK_FILES_UPLOAD';
  static RESOURCE_PACK_FILES_DELETE = '[AHUB] RESOURCE_PACK_FILES_DELETE';
  static RESOURCE_PACK_FOLDER_ADD = '[AHUB] RESOURCE_PACK_FOLDER_ADD';
  static RESOURCE_PACK_FOLDER_RENAME = '[AHUB] RESOURCE_PACK_FOLDER_RENAME';
  static RESOURCE_PACK_FILES_ZIP_DOWNLOAD = '[AHUB] RESOURCE_PACK_FILES_ZIP_DOWNLOAD';
  static RESOURCE_PACK_FILE_DOWNLOAD = '[AHUB] RESOURCE_PACK_FILE_DOWNLOAD';


  static CLIENT_LOG_FETCH = '[AHUB] CLIENT_LOG_FETCH';
  static CLIENT_LOG_SET = '[AHUB] CLIENT_LOG_SET';
  static CLIENT_LOG_REPLACE = '[AHUB] CLIENT_LOG_REPLACE';
  static CLIENT_LOG_ENTRIES_FETCH = '[AHUB] CLIENT_LOG_ENTRIES_FETCH';

  /**
   * ----------------------------------------------------------
   * General Actions
   * ----------------------------------------------------------
   */

  /**
   * Clear all cached aHub data ( thats not in permanent store )
   */
  static storeTemporaryClear(): ActionWork {

    //Constuct action with the specified interface
    return {
      type: AHubActions.STORE_TEMPORARY_CLEAR
    };
  }


  /**
   * Clear all cached aHub data ( thats not in permanent store )
   */
  static storePermanentClear(): ActionWork {

    //Constuct action with the specified interface
    return {
      type: AHubActions.STORE_PERMANENT_CLEAR
    };
  }

  /**
   * Dispatched when a service method call, has errored and after being picked up
   * the error code suggests that it is a communications issue.
   * Sets the commsError flag to true, allowing visual feedback.
   * At time of writing it is cleared by worklog monitoring, which automatically retrys
   * and gives a good indication of when we are back online ! We could
   * do a dedicated service ? Something around status.. but could be regarded as
   * unnecessary traffic.
   */
  static commsError(): ActionWork {
    return {
      type: AHubActions.COMMS_ERROR
    };
  }


  /**
   * ----------------------------------------------------------
   * System Actions
   * ----------------------------------------------------------
   */

  /**
   * Create an instance of the action to start the creation of the system database validation files.
   */
  static systemDatabaseValidationFilesCreate(): ActionWork {
    return {
      type: AHubActions.SYSTEM_DATABASE_VALIDATION_FILES_CREATE
    };
  }

  /**
   * ----------------------------------------------------------
   * Account Actions
   * ----------------------------------------------------------
   */

  /**
   * Create an action to get a login token.
   */
  static loginTokenFetch(appId: string): ActionString {

    //Constuct action with the specified interface
    return {
      type: AHubActions.LOGIN_TOKEN_FETCH,
      string: appId
    };
  }

  /**
   * This function is called when we want to validate a non third party user.
   */
  static logUserValidate(emailAddress: string, password: string, token: string): ActionStringArray {

    //Constuct action with the specified interface
    return {
      type: AHubActions.LOGIN_USER_VALIDATE,
      strings: [emailAddress, password, token]
    };
  }

  /**
   * Create an action to get a login identifier provider URL.
   */
  static loginUrlFetch(email: string): ActionString {
    //Construct action with the specified interface
    return {
      type: AHubActions.LOGIN_URL_FETCH,
      string: email
    };
  }

  /**
   * Authorize the token with the current set of aHub credentials
   * allowing the supplier of the token to login successfully
   *
   * @param token     Token we want to validate
   */
  static loginTokenAuthorize(token: string): ActionString {
    return {
      type: AHubActions.LOGIN_TOKEN_AUTHORIZE,
      string: token
    }
  }

  /**
   * Create an action to validate a login identifier provider token.
   */
  static loginIdentifierProviderTokenValidate(loginToken: string, authenticationToken: string): ActionStringArray {

    //Constuct action with the specified interface
    return {
      type: AHubActions.LOGIN_IDENTIFIER_PROVIDER_TOKEN_VALIDATE,
      strings: [loginToken, authenticationToken]
    };
  }

  /**
   * Create an action to get register a user with a identifier provider token.
   */
  static loginIdentifierProviderTokenRegister(loginToken: string, authenticationToken: string): ActionStringArray {

    //Constuct action with the specified interface
    return {
      type: AHubActions.LOGIN_IDENTIFIER_PROVIDER_TOKEN_REGISTER,
      strings: [loginToken, authenticationToken]
    };
  }


  /**
   * Create an action to get a session token.
   */
  static sessionTokenFetch(sessionId: string): ActionString {

    //Constuct action with the specified interface
    return {
      type: AHubActions.SESSION_TOKEN_FETCH,
      string: sessionId
    };
  }

  /**
   * Create a new action to get a new set of user credentials for a specified token.
   */
  static userSessionNewFromToken(token: string): ActionString {

    //Constuct action with the specified interface
    return {
      type: AHubActions.USER_SESSION_NEW_FROM_TOKEN,
      string: token
    };
  }

  /**
   * Fetch the userId from the session credentials
   */
  static userIdBySessionFetch(userSessionCredentials: UserSessionCredentialsAHubVO): ActionUserSessionCredentialsAHubVO {

    //Constuct action with the specified interface
    return {
      type: AHubActions.USER_ID_BY_SESSION_FETCH,
      userSessionCredentials: userSessionCredentials
    };
  }


  /**
   * Create an action for fetching whether the user has aViews or not.
   */
  static userHasAViewsFetch(): ActionWork {
    return {
      type: AHubActions.USER_HAS_AVIEWS_FETCH
    };
  }

  /**
   * Create an action for setting whether the user has aViews or not.
   */
  static userHasAViewsSet(hasAviews: boolean): ActionBoolean {
    return {
      type: AHubActions.USER_HAS_AVIEWS_SET,
      boolean: hasAviews
    };
  }


  /**
   * Fetch the session users by the user ids supplied
   */
  static sessionUsersByIdsFetch(userIds: number[]): ActionNumberArray {

    //Constuct action with the specified interface
    return {
      type: AHubActions.SESSION_USERS_BY_IDS_FETCH,
      numbers: userIds
    };
  }

  /**
   * Fetch the users by the user ids supplied
   */
  static usersByIdsFetch(userIds: number[]): ActionNumberArray {

    //Constuct action with the specified interface
    return {
      type: AHubActions.USERS_BY_IDS_FETCH,
      numbers: userIds
    };
  }


  /**
   * Set the users list in the store
   */
  static userCommit(user: UserAHubVO): ActionUserAHubVO {

    //Constuct action with the specified interface
    return {
      type: AHubActions.USER_COMMIT,
      user: user
    };
  }

  /**
   * Set the session users list in the store
   */
  static sessionUsersSet(users: UserAHubVO[]): ActionUserArrayAHubVO {

    //Constuct action with the specified interface
    return {
      type: AHubActions.SESSION_USERS_SET,
      users: users
    };
  }

  /**
   * Set the users list in the store
   */
  static usersSet(users: UserAHubVO[]): ActionUserArrayAHubVO {

    //Constuct action with the specified interface
    return {
      type: AHubActions.USERS_SET,
      users: users
    };
  }

  /**
   * Create an action to get user indexes by user id.
   *
   * @param userIds           The list of user index ids we want.
   */
  static userIndexesByIdFetch(userIds: number[]): ActionNumberArray {

    // Construct the action.
    return {
      type: AHubActions.USER_INDEXES_BY_IDS_FETCH,
      numbers: userIds
    };
  }

  /**
   * Create an action to set the user indexes.
   *
   * @param userIndexes         The list of user indexes to set too.
   */
  static userIndexesSet(userIndexes: UserIndexAHubVO[]): ActionUserIndexAHubVO {

    // Construct the action.
    return {
      type: AHubActions.USER_INDEXES_SET,
      userIndexs: userIndexes
    };
  }

  /**
   * Create an action to remove the user indexes from the store.
   *
   * @param userIds              The list of user ids to remove.
   */
  static userIndexesRemove(userIds: number[]): ActionNumberArray {

    // Construct the action.
    return {
      type: AHubActions.USER_INDEXES_REMOVE,
      numbers: userIds
    };
  }

  /**
   * Create an action to clear the user indexes from the store.
   *
   */
  static userIndexesClear(): ActionWork {

    // Construct the action.
    return {
      type: AHubActions.USER_INDEXES_CLEAR,
    };
  }

  /**
   * Create an action to Fetch the user indexes (Full Array.)
   */
  static userIndexsFullFetch(): ActionWork {

    // Construct the action.
    return {
      type: AHubActions.USER_INDEXES_FULL_FETCH
    };
  }

  /**
   * Create an action to Fetch the distribution group user indexes by client id
   */
  static userIndexesByClientIdFetch(clientId): ActionNumber {

    // Construct the action.
    return {
      type: AHubActions.USER_INDEXES_BY_CLIENT_ID_FETCH,
      number: clientId
    };
  }


  /**
   * Set the full user index list.
   */
  static userIndexsFullSet(userIndexes: UserIndexAHubVO[]): ActionUserIndexAHubVO {

    // Construct the action with the specified interace.
    return {
      type: AHubActions.USER_INDEXES_FULL_SET,
      userIndexs: userIndexes
    };
  }

  /**
   * Set the full user index list.
   */
  static userIndexsPaginatedSet(userIndexes: UserIndexAHubVO[]): ActionUserIndexAHubVO {

    // Construct the action with the specified interace.
    return {
      type: AHubActions.USER_INDEXES_PAGINATED_SET,
      userIndexs: userIndexes
    };
  }

  /**
   * Create an action to get the extended users by id's.
   *
   * @param userIds           The id's of the users to get.
   */
  static userExtendedsByIdFetch(userIds: number[]): ActionNumberArray {

    // Create the action and return it.
    return {
      type: AHubActions.USER_EXTENDEDS_BY_IDS_FETCH,
      numbers: userIds
    };
  }


  /**
   * Create an action to set the extended users in the store.
   *
   * @param userExtendeds                 The extended users to store.
   */
  static userExtendedsSet(userExtendeds: UserExtendedAHubVO[]): ActionUserExtendedAHubVOs {

    // Create the action and return it.
    return {
      type: AHubActions.USER_EXTENDEDS_SET,
      userExtendeds: userExtendeds
    };
  }


  /**
   * Set the users clients in the store.
   */
  static userClientIndexsFetch(userId: number): ActionNumber {

    // Construct the action with the specified interace.
    return {
      type: AHubActions.USER_CLIENTS_INDEXS_FETCH,
      number: userId
    };
  }

  /**
   * Set the user client indexes.
   */
  static userClientIndexsSet(userClientIndexs: ClientIndexAHubVO[]): ActionClientIndexAHubVOs {

    // Construct the action with the specified interace.
    return {
      type: AHubActions.USER_CLIENTS_INDEXS_SET,
      clientIndexs: userClientIndexs
    };
  }


  /**
   * Fetch the index of ahub clients ( req system permissions )
   * This is a full list, not related to the user.
   */
  static clientIndexsFetch(): ActionWork {
    return {
      type: AHubActions.CLIENT_INDEXS_FETCH
    }
  }

  /**
   * Set the full list  client indexs in the store.
   * @param clientIndexs
   */
  static clientIndexsSet(clientIndexs: ClientIndexAHubVO[]): ActionClientIndexAHubVOs {

    // Construct the action with the specified interace.
    return {
      type: AHubActions.CLIENT_INDEXS_SET,
      clientIndexs: clientIndexs
    };

  }

  /**
   * Action to return client information for the given ids.
   */
  static clientsByIdFetch(clientIds: number[]): ActionNumberArray {
    return {
      type: AHubActions.CLIENTS_BY_ID_FETCH,
      numbers: clientIds
    }
  }

  /**
   * Action to set the client objects in the store.
   */
  static clientsSet(clients: ClientAHubVO[]): ActionClientAHubVOs {
    return {
      type: AHubActions.CLIENTS_SET,
      clients: clients
    }
  }

  /**
   * Action to register a client.
   */
  static clientAdd(client: ClientAHubVO): ActionClientAHubVO {
    return {
      type: AHubActions.CLIENT_ADD,
      client: client
    }
  }

  /**
   * Action to save changes to a client
   */
  static clientSave(client: ClientAHubVO): ActionClientAHubVO {
    return {
      type: AHubActions.CLIENT_SAVE,
      client: client
    }
  }

  /**
   * Action to enable a client ( if disabled )
   */
  static clientEnable(clientId: number): ActionNumber {
    return {
      type: AHubActions.CLIENT_ENABLE,
      number: clientId
    }
  }

  /**
   * Action to disable a client - without removing any information.
   */
  static clientDisable(clientId: number): ActionNumber {
    return {
      type: AHubActions.CLIENT_DISABLE,
      number: clientId
    }
  }

  /**
   * Action to delete a client and all related materials !
   */
  static clientDelete(clientId: number): ActionNumber {
    return {
      type: AHubActions.CLIENT_DELETE,
      number: clientId
    }
  }

  /**
   * Action to fetch all the indexes from the client library version indexes
   */
  static clientLibraryVersionIndexFetch(): ActionWork {
    return { type: AHubActions.CLIENT_LIBRARY_VERSION_INDEXS_FETCH }
  }

  /**
   * Set the client library version indexes into the system
   *
   * @param indexs
   */
  static clientLibraryVersionIndexSet(indexs: ClientLibraryVersionIndexAHubVO[]): ActionClientLibraryVersionIndexAHubVOs {
    return {
      type: AHubActions.CLIENT_LIBRARY_VERSION_INDEXS_SET,
      clientLibraryVersionIndexs: indexs
    }
  }

  /**
   * Action to fetch the client library versions by their version numbers
   */
  static clientLibraryVersionFetchByVersion(versions: number[]): ActionNumberArray {
    return {
      type: AHubActions.CLIENTS_LIBRARY_VERSION_BY_ID_FETCH,
      numbers: versions
    }
  }

  /**
   * Set the client library version indexes into the system
   *
   * @param indexs
   */
  static clientLibraryVersionSet(version: ClientLibraryVersionAHubVO[]): ActionClientLibraryVersionAHubVOs {
    return {
      type: AHubActions.CLIENTS_LIBRARY_VERSION_SET,
      clientLibraryVersions: version
    }
  }

  /**
   * Fetches an image URL representing an export.
   *
   * @param clientLibraryVersionId            The id of the client library version who's model we want.
   */
  static clientLibraryVersionModelUrlFetch(clientLibraryVersionId: number): ActionNumber {
    return {
      type: AHubActions.CLIENT_LIBRARY_VESION_MODEL_URL_FETCH,
      number: clientLibraryVersionId
    }
  }

  /**
   * Set the client library version model URL in the store.
   *
   * @param clientLibraryVersionId                The id of the client library version.
   * @param clientLibraryVersionModelUrl          The URL for the client library version model.
   */
  static clientLibraryVersionModelUrlsSet(clientLibraryVersionId: number, clientLibraryVersionModelUrl: PresignedUrlAHubVO): ActionIdItem<PresignedUrlAHubVO> {
    return {
      type: AHubActions.CLIENT_LIBRARY_VESION_MODEL_URLS_SET,
      idItem: {
        id: clientLibraryVersionId,
        item: clientLibraryVersionModelUrl
      }
    };
  }

  /**
   * Action to set the current library version (the template from which to build libraries for new clients say) !
   */
  static clientLibraryVersionSaveBuildFrom(clientLibraryVersion: number): ActionNumber {
    return {
      type: AHubActions.CLIENTS_LIBRARY_VERSION_SAVE_BUILD_FROM,
      number: clientLibraryVersion
    };
  }

  /**
   * Create an instance of the action to start the generation of the client library version model by version.
   *
   * @param clientLibraryVersion              The version to generate the model for.
   */
  static clientLibraryVersionModelGenerate(clientLibraryVersion: number): ActionNumber {
    return {
      type: AHubActions.CLIENT_LIBRARY_VERSION_MODEL_GENERATE,
      number: clientLibraryVersion
    };
  }

  /**
   * Start the request to add a new client library version.
   *
   * @param clientLibraryVersion              The new client library version to add.
   */
  static clientLibraryVersionAdd(clientLibraryVersion: ClientLibraryVersionAHubVO): ActionClientLibraryVersionAHubVO {
    return {
      type: AHubActions.CLIENT_LIBRARY_VERSION_ADD,
      clientLibraryVersion: clientLibraryVersion
    };
  }

  /**
   * Start the request to delete a client library version
   */
  static clientLibraryVersionDelete(clientLibraryVersionNumber: number): ActionNumber {
    return {
      type: AHubActions.CLIENT_LIBRARY_VERSION_DELETE,
      number: clientLibraryVersionNumber
    };
  }

  /**
   * Create an instance of the client library version commit action.
   *
   * @param clientLibraryVersion          The client library version to commit.
   */
  static clientLibraryVersionCommit(clientLibraryVersion: ClientLibraryVersionAHubVO): ActionClientLibraryVersionAHubVO {
    return {
      type: AHubActions.CLIENT_LIBRARY_VERSION_COMMIT,
      clientLibraryVersion: clientLibraryVersion
    };
  }

  /**
   * Create an instance of the client library version validate action.
   *
   * @param clientLibraryVersion          The client library version number to validate.
   */
  static clientLibraryVersionValidate(clientLibraryVersion: number): ActionNumber {
    return {
      type: AHubActions.CLIENT_LIBRARY_VERSION_VALIDATE,
      number: clientLibraryVersion
    };
  }

  /**
   * Start the request to search for client library versions.
   */
  static clientLibraryVersionsDiscover(): ActionWork {
    return {
      type: AHubActions.CLIENT_LIBRARY_VERSIONS_DISCOVER
    };
  }

  /**
   * Create an instance of the action to get all of the system client storage indexes.
   */
  static clientStorageIndexesFetch(): ActionWork {
    return {
      type: AHubActions.CLIENT_STORAGE_INDEXES_FETCH
    };
  }

  /**
   * Create an action to set the client storage indexes.
   *
   * @param clientStorageIndexes          The client storage indexes to set.
   */
  static clientStorageIndexesSet(clientStorageIndexes: ClientStorageIndexAHubVo[]): ActionClientStorageIndexAHubVOs {
    return {
      type: AHubActions.CLIENT_STORAGE_INDEXES_SET,
      clientStorageIndexes: clientStorageIndexes
    };
  }

  /**
   * Create an instance of the action to get all of the system client library indexes.
   */
  static clientLibraryIndexesFetch(): ActionWork {
    return {
      type: AHubActions.CLIENT_LIBRARY_INDEXES_FETCH
    };
  }

  /**
   * Create an action to set the client library indexes.
   *
   * @param clientLibraryIndexes          The client libraray indexes to set.
   */
  static clientLibraryIndexesSet(clientLibraryIndexes: ClientLibraryIndexAHubVO[]): ActionClientLibraryIndexAHubVOs {
    return {
      type: AHubActions.CLIENTS_LIBRARY_INDEXES_SET,
      clientLibraryIndexes: clientLibraryIndexes
    };
  }

  /**
   * This action is called when we want to fetch the client library information.
   *
   * @param ids              The ids of the library information we are getting.
   */
  static clientLibrariesByIdFetch(ids: number[]): ActionNumberArray {
    return {
      type: AHubActions.CLIENTS_LIBRARIES_BY_ID_FETCH,
      numbers: ids
    };
  }

  /**
   * This action is called when we want to remove a set of client libraries.
   *
   * @param ids
   */
  static clientLibrariesRemove(ids: number[]): ActionNumberArray {
    return {
      type: AHubActions.CLIENTS_LIBRARIES_REMOVE,
      numbers: ids
    };
  }

  /**
   * This action is called when we want to set the client libraries.
   *
   * @param clientLibraries           The client libraries to set.
   */
  static clientLibrariesSet(clientLibraries: ClientLibraryAHubVO[]): ActionClientLibraryAHubVOs {
    return {
      type: AHubActions.CLIENTS_LIBRARIES_SET,
      clientLibraries: clientLibraries
    };
  }

  /**
   * This function will make a request to start the upgrading of a clients library.
   * Very similar to the clientLibraryUse method, except the library picked is upgraded
   * before being put to use.
   *
   * @param clientId            The id of the client who's library we want to upgrade.
   * @param libraryId                     The id of the library to use.
   * @param libraryReplacementId        Optional ID of the library to put in place of the one being made live.
   */
  static clientLibraryUpgradeAndUse(clientId: number, libraryId: number, libraryReplacementId?: number): ActionSystemClientLibraryId {
    return {
      type: AHubActions.CLIENT_LIBRARY_UPGRADE_AND_USE,
      clientId: clientId,
      libraryUseId: libraryId,
      libraryWithId: libraryReplacementId
    };
  }

  /**
   * Create an instance of the action to create a new client library.
   *
   * @param clientId               The id of the client we are adding the library too.
   */
  static clientLibraryAdd(clientId: number, clientStorageId: number): ActionNumberNumber {
    return {
      type: AHubActions.CLIENT_LIBRARY_ADD,
      number1: clientId,
      number2: clientStorageId
    };
  }

  /**
   * Create an instance of the action to delete a client library.
   *
   * @param clientId                  The id of the client that owns the library to delete.
   * @param libraryId                 The id of the library to delete.
   */
  static clientLibraryDelete(clientId: number, libraryId: number): ActionSystemClientLibraryId {
    return {
      type: AHubActions.CLIENT_LIBRARY_DELETE,
      clientId: clientId,
      libraryUseId: libraryId
    };
  }

  /**
   * Create an instance of the action to make a request to use a client library.
   *
   * @param clientId                      The id of the client we are working with.
   * @param libraryId                     The id of the library to use.
   */
  static clientLibraryUse(clientId: number, libraryId: number, libraryWithId?: number): ActionSystemClientLibraryId {
    return {
      type: AHubActions.CLIENT_LIBRARY_USE,
      clientId: clientId,
      libraryUseId: libraryId,
      libraryWithId: libraryWithId
    };
  }

  /**
  * Request the client library to sync. If no "with library" is supplied it will attempt
  * to sync with teh source library configured with the library.
  * If a "with library" is supplied , an attempt is made to sync with it..
  *
  * @param clientId                      The id of the client we are working with.
  * @param libraryId                     The id of the library to use.
  * @param libraryWithId                 Optionally carry out the action with this id.
  */
  static clientLibrarySync(clientId: number, libraryId: number, libraryWithId?: number): ActionSystemClientLibraryId {
    return {
      type: libraryWithId ? AHubActions.CLIENT_LIBRARY_SYNC_FROM : AHubActions.CLIENT_LIBRARY_SYNC,
      clientId: clientId,
      libraryUseId: libraryId,
      libraryWithId: libraryWithId
    };
  }

  /**
   * Commit changes to a client library Index.
   * @param clientId
   * @param clientLibrary
   */
  static clientLibraryCommit(clientId: number, clientLibrary: ClientLibraryAHubVO): ActionSystemClientLibrary {
    return {
      type: AHubActions.CLIENT_LIBRARY_COMMIT,
      clientId: clientId,
      clientLibrary: clientLibrary
    };
  }

  /**
   * Fetch the dataSets by the ids supplied
   */
  static dataSetIndexsFetch(): ActionWork {
    return {
      type: AHubActions.DATASET_INDEXS_FETCH
    }
  }

  /**
   * Set the dataSet indexs
   */
  static dataSetIndexsSet(dataSetIndexs: DataSetIndexAHubVO[]): ActionDataSetIndexAHubVOs {
    return {
      type: AHubActions.DATASET_INDEXS_SET,
      dataSetIndexs: dataSetIndexs
    }
  }

  /**
   * Fetch the dataSets by the ids supplied.
   */
  static dataSetsByIdFetch(ids: number[]): ActionNumberArray {
    return {
      type: AHubActions.DATASETS_BY_ID_FETCH,
      numbers: ids
    }
  }

  /**
   * Set the datasets.
   */
  static dataSetsSet(dataSetsVal: DataSetAHubVO[]): ActionDataSetAHubVOs {

    // DataSets appears to be reserved word.
    return {
      type: AHubActions.DATASETS_SET,
      dataSets: dataSetsVal
    }
  }

  /**
   * Create an action to add a new dataSet to the aHub.
   */
  static dataSetAdd(dataSetVO: DataSetAHubVO): ActionDataSetAHubVO {
    return {
      type: AHubActions.DATASET_ADD,
      dataSet: dataSetVO
    }
  }

  /**
   * Create an action to save a dataSet changes.
   */
  static dataSetSave(dataSetVO: DataSetAHubVO): ActionDataSetAHubVO {
    return {
      type: AHubActions.DATASET_SAVE,
      dataSet: dataSetVO
    }
  }

  /**
   * Create an action to delete an dataSet by its id.
   */
  static dataSetDelete(dataSetId: number): ActionNumber {
    return {
      type: AHubActions.DATASET_DELETE,
      number: dataSetId
    }
  }

  /**
   * Create an action to copy a dataSet by its id.
   */
  static dataSetCopy(dataSetId: number, newDataSetName: string, includeExporters: boolean): ActionStringNumberBoolean {
    return {
      type: AHubActions.DATASET_COPY,
      number: dataSetId,
      string: newDataSetName,
      boolean: includeExporters
    }
  }

  /**
   * Create an action to transform dataset section properties between sections.
   */
  static dataSetSwapSectionProperties(dataSetId: number, swapSectionPropertiesFrom: number, swapSectionPropertiesTo: number): ActionNumberNumberNumber {
    return {
      type: AHubActions.DATASET_SECTION_PROPERTIES_SWAP,
      number1: dataSetId,
      number2: swapSectionPropertiesFrom,
      number3: swapSectionPropertiesTo
    }
  }

  /**
   * Create an action to remove an dataSet by its id from the store.
   */
  static dataSetsRemove(dataSetIds: number[]): ActionNumberArray {
    return {
      type: AHubActions.DATASETS_REMOVE,
      numbers: dataSetIds
    }
  }

  /**
   * Create an action for getting products by product id's in a data set.
   *
   * @param dataSetId             The data set that contains the products.
   * @param productIds            The id's of the product who's asset we want.
   */
  static dataSetProductsByIdsFetch(dataSetId: number, productIds: number[]): ActionNumberNumbersArray {
    return {
      type: AHubActions.DATA_SET_PRODUCTS_BY_IDS_FETCH,
      number: dataSetId,
      numbers: productIds
    };
  }

  /**
   * Create an action that sets the data set products.
   *
   * @param dataSetId                 The id of the data set the products are for
   * @param dataSetProductsAssets     The data set product assets to set too.
   */
  static dataSetProductsSet(dataSetId: number, dataSetProducts: ProductAHubVO[]): ActionProductVOsWithId {
    return {
      type: AHubActions.DATA_SET_PRODUCTS_SET,
      id: dataSetId,
      products: dataSetProducts
    };
  }

  /**
   * Create an action for getting product assets by product id's in a data set.
   *
   * @param dataSetId             The data set that contains the products.
   * @param productIds            The id's of the product who's asset we want.
   */
  static dataSetProductAssetsByIdsFetch(dataSetId: number, productIds: number[]): ActionNumberNumbersArray {
    return {
      type: AHubActions.DATA_SET_PRODUCT_ASSETS_BY_IDS_FETCH,
      number: dataSetId,
      numbers: productIds
    };
  }

  /**
   * Create an action that sets the data set product assets.
   *
   * @param dataSetId                  The data set that contains the product assets.
   * @param dataSetProductsAssets      The data set product assets to set too.
   */
  static dataSetSpecificProductsAssetsSet(dataSetId: number, dataSetProductsAssets: ProductAssetAHubVO[]): ActionProductAssetVOsWithId {
    return {
      type: AHubActions.DATA_SET_PRODUCT_ASSETS_SET,
      id: dataSetId,
      productAssets: dataSetProductsAssets
    };
  }

  /**
 * Create an action to generate a zip for a specific product asset
 *
 * @param dataSetId         Id of the dataset we want the asset from
 * @param assetId           Id of the asset we want to zip
 * @param assetLocation     Location of the asset we want!
 */
  static dataSetProductAssetZipGenerate(dataSetId: number, assetId: number, assetLocation: string): ActionNumberNumberString {
    return {
      type: AHubActions.DATA_SET_PRODUCT_ASSET_ZIP_GENERATE,
      number1: dataSetId,
      number2: assetId,
      string: assetLocation
    }
  }

  /**
   * Create an action to get a list of data set categories by id's.
   *
   * @param dataSetCategoryIds            The id's of the data set categories to get.
   */
  static dataSetCategoriesByIdsFetch(dataSetCategoryIds: number[]): ActionNumberArray {
    return {
      type: AHubActions.DATA_SET_CATEGORIES_BY_ID_FETCH,
      numbers: dataSetCategoryIds
    };
  }

  /**
   * Create an action to set the data set categories to the ones passed in.
   *
   * @param dataSetCategories         The new data set categories to record.
   */
  static dataSetCategoriesSet(dataSetCategories: DataSetCategoryAHubVO[]): ActionDataSetCategoryAHubVOs {
    return {
      type: AHubActions.DATA_SET_CATEGORIES_SET,
      dataSetCategories: dataSetCategories
    }
  }

  /**
   * Create an action to add a new data set category to the aHub.
   *
   * @param dataSetCategory           The new data set category to add to the aHub.
   */
  static dataSetCategoryAdd(dataSetCategory: DataSetCategoryAHubVO): ActionDataSetCategoryAHubVO {
    return {
      type: AHubActions.DATA_SET_CATEGORY_ADD,
      dataSetCategory: dataSetCategory
    }
  }

  /**
   * Create an action to copy categories to a new parent category.
   *
   * @param dataSetId                 Id of the dataset for the categories
   * @param sourceCategoryIds         The ids of the categories to be copied.
   * @param targetCategoryId          The id of the category to copy to.
   */
  static dataSetCategoriesCopy(dataSetId: number, sourceCategoryIds: number[], targetCategoryId: number): ActionNumberNumberNumbersArray {
    return {
      type: AHubActions.DATA_SET_CATEGORIES_COPY,
      number1: dataSetId,
      numbers: sourceCategoryIds,
      number2: targetCategoryId
    }
  }

  /**
   * Create an action to commit a data set category to the aHub.
   *
   * @param dataSetCategory           The new data set category to add to the aHub.
   */
  static dataSetCategoryCommit(dataSetCategory: DataSetCategoryAHubVO): ActionDataSetCategoryAHubVO {
    return {
      type: AHubActions.DATA_SET_CATEGORY_COMMIT,
      dataSetCategory: dataSetCategory
    }
  }

  /**
   * Create an action to commit a data set category product property filter to the aHub.
   *
   * @param dataSetCategory           The new data set category to add to the aHub.
   */
  static dataSetCategoryCommitProductPropertyFilter(dataSetCategoryId: number, filter: ProductPropertyFilterAHubVO): ActionProductPropertyFilterAHubVONumber {
    return {
      type: AHubActions.DATA_SET_CATEGORY_COMMIT_PRODUCT_PROPERTY_FILTER,
      productPropertyFilter: filter,
      number: dataSetCategoryId
    }
  }

  /**
   * Create an action to commit a data set category product class filter to the aHub.
   *
   * @param dataSetCategory           The new data set category to add to the aHub.
   */
  static dataSetCategoryCommitProductClassFilter(dataSetCategoryId: number, filter: ProductClassFilterAHubVO): ActionProductClassFilterAHubVONumber {
    return {
      type: AHubActions.DATA_SET_CATEGORY_COMMIT_PRODUCT_CLASS_FILTER,
      productClassFilter: filter,
      number: dataSetCategoryId
    }
  }

  /**
   * Create an action to commit a data set category product sort to the aHub.
   *
   * @param dataSetCategory           The new data set category to add to the aHub.
   */
  static dataSetCategoryCommitProductSort(dataSetCategoryId: number, sort: ProductSortAHubVO): ActionProductSortAHubVONumber {
    return {
      type: AHubActions.DATA_SET_CATEGORY_COMMIT_PRODUCT_SORT,
      productSort: sort,
      number: dataSetCategoryId
    }
  }

  /**
   * Create an action to commit a data set category priority to the aHub.
   *
   * @param dataSetCategory           The new data set category to add to the aHub.
   */
  static dataSetCategoryCommitPriority(dataSetCategoryId: number, priorityIndex: number): ActionNumberNumber {
    return {
      type: AHubActions.DATA_SET_CATEGORY_COMMIT_PRIORITY,
      number1: dataSetCategoryId,
      number2: priorityIndex
    }
  }

  /**
   * Create an action to delete a data set category by id.
   *
   * @param dataSetCategoryId         The id of the data set category to delete.
   */
  static dataSetCategoryDelete(dataSetCategoryId: number): ActionNumber {
    return {
      type: AHubActions.DATA_SET_CATEGORY_DELETE,
      number: dataSetCategoryId
    }
  }

  /**
   * Create an action to remove data set categories from the local store.
   *
   * @param dataSetCategoryIds         The id's of the data set category to remove.
   */
  static dataSetCategoryRemove(dataSetCategoryIds: number[]): ActionNumberArray {
    return {
      type: AHubActions.DATA_SET_CATEGORY_REMOVE,
      numbers: dataSetCategoryIds
    }
  }

  /**
   * Create an action to move the data set categories specified
   *
   * @param dataSetCategoryIds         The id's of the data set category to remove.
   */
  static dataSetCategoryMove(dataSetCategoryId: number, parentSetCategoryId: number): ActionNumberArray {
    return {
      type: AHubActions.DATA_SET_CATEGORY_MOVE,
      numbers: [dataSetCategoryId, parentSetCategoryId]
    }
  }

  /**
   * Create an action to get the products for a data set category.
   *
   * @param dataSetCategoryId         The id of the data set category who's products we want.
   */
  static dataSetCategoryProductsFetch(dataSetCategoryId: number): ActionNumber {
    return {
      type: AHubActions.DATA_SET_CATEGORY_PRODUCTS_FETCH,
      number: dataSetCategoryId
    };
  }

  /**
   * Create an action to get the products for a data set category.
   *
   * @param dataSetCategoryId         The id of the data set category who's products we want.
   * @param productIds                The ids of the products we want to fetch
   */
  static dataSetCategoryProductsFetchByIds(dataSetCategoryId: number, productIds: number[]): ActionNumberNumbersArray {
    return {
      type: AHubActions.DATA_SET_CATEGORY_PRODUCTS_FETCH_BY_ID,
      number: dataSetCategoryId,
      numbers: productIds
    };
  }

  /**
   * Append and remove the products from the specified payload
   */
  static dataSetCategoryProductsAppendAndRemove(payload: ActionPayloadLibraryNumberProductsNumberArray): ActionWorkPayload<ActionPayloadLibraryNumberProductsNumberArray> {
    return {
      type: AHubActions.DATA_SET_CATEGORY_PRODUCTS_APPEND_AND_REMOVE,
      payload: payload
    };
  }


  /**
   * Create an action to remove data set categories from the local store.
   *
   * @param categoryId        The id of the category which we want to remove the products from
   * @param productIds        The product id's which we want to remove from the store
   */
  static dataSetCategoryProductsRemove(categoryId: number, productIds: number[]): ActionNumberNumbersArray {
    return {
      type: AHubActions.DATA_SET_CATEGORY_PRODUCTS_REMOVE,
      number: categoryId,
      numbers: productIds
    }
  }

  /**
   * Create an action to clear the data set category products from the store based on a category id.
   *
   * @param categoryIds         The id's of the category we want to clear out!
   */
  static dataSetCategoryProductsClear(categoryIds: number[]): ActionNumberArray {

    // Construct the action.
    return {
      type: AHubActions.DATA_SET_CATEGORY_PRODUCTS_CLEAR,
      numbers: categoryIds
    };
  }

  /**
   * Create an action to remove data set categories from the local store.
   *
   * @param categoryId        The id of the category which we want to remove the products from
   * @param productIds        The product id's which we want to remove from the store
   */
  static dataSetCategoryProductsRemoveBeforeTime(categoryId: number, time: number): ActionNumberNumber {
    return {
      type: AHubActions.DATA_SET_CATEGORY_PRODUCTS_REMOVE_BEFORE_TIME,
      number1: categoryId,
      number2: time
    }
  }

  /**
   * Create an action to fetch the sorted product id's for a data set category
   *
   * @param categoryId        The id of the category which we want the sorted id's for
   */
  static dataSetCategoryProductIdSortedFetch(categoryId: number): ActionNumber {
    return {
      type: AHubActions.DATA_SET_CATEGORY_PRODUCT_ID_SORTED_FETCH,
      number: categoryId
    }
  }

  /**
   * Create an action to set the sorted product id's for a data set category
   *
   * @param categoryId        The id of the category which we want the sorted id's for
   * @param ids               The sorted product id's
   */
  static dataSetCategoryProductIdSortedSet(categoryId: number, ids: number[]): ActionNumberNumbersArray {
    return {
      type: AHubActions.DATA_SET_CATEGORY_PRODUCT_ID_SORTED_SET,
      number: categoryId,
      numbers: ids
    }
  }

  /**
   * Create an action to get the products for a data set category.
   *
   * @param dataSetId         The id of the data set category who's products we want.
   * @param dataSetCategoryId         The id of the data set category who's products we want.
   */
  static myThingsDataSetCategoryProductsFetch(dataSetId: number, dataSetCategoryId: number): ActionNumberNumber {
    return {
      type: AHubActions.MY_THINGS_DATASET_CATEGORY_PRODUCTS_FETCH,
      number1: dataSetId,
      number2: dataSetCategoryId
    };
  }

  /**
   * Fetch the index for the distribution groups.
   */
  static distributionGroupIndexesForSessionClientIdFetch(): ActionWork {
    return {
      type: AHubActions.DISTRIBUTION_GROUP_INDEXS_FETCH,
    }
  }

  /**
   * Get ditribution groups indexes by client id.
   */
  static distributionGroupIndexesByClientIdFetch(clientId: number): ActionNumber {
    return {
      type: AHubActions.DISTRIBUTION_GROUP_INDEXES_BY_CLIENT_ID_FETCH,
      number: clientId
    }
  }

  /**
   * Get ditribution groups indexes by client id.
   */
  static distributionGroupIndexesByClientIdAppend(clientId: number): ActionNumber {
    return {
      type: AHubActions.DISTRIBUTION_GROUP_INDEXES_BY_CLIENT_ID_APPEND,
      number: clientId
    }
  }

  /**
   * Get ditribution groups indexes by user id.
   */
  static distributionGroupIndexesByUserIdFetch(userId: number): ActionNumber {
    return {
      type: AHubActions.DISTRIBUTION_GROUP_INDEXES_BY_USER_ID_FETCH,
      number: userId
    }
  }

  /**
   * Get ditribution groups indexes by clientId and user id.
   */
  static distributionGroupIndexesByClientIdAndUserIdFetch(clientId: number, userId: number): ActionNumberNumber {
    return {
      type: AHubActions.DISTRIBUTION_GROUP_INDEXES_BY_CLIENT_ID_AND_USER_ID_FETCH,
      number1: clientId,
      number2: userId
    }
  }

  /**
   * Set the index for the distribution groups in the store
   */
  static distributionGroupIndexsSet(distributionGroupIndexs: DistributionGroupIndexAHubVO[]): ActionDistributionGroupIndexAHubVOs {
    return {
      type: AHubActions.DISTRIBUTION_GROUP_INDEXS_SET,
      distributionGroupIndexs: distributionGroupIndexs
    }
  }

  /**
   * Set the index for the distribution groups in the store
   */
  static distributionGroupIndexsAppend(distributionGroupIndexs: DistributionGroupIndexAHubVO[]): ActionDistributionGroupIndexAHubVOs {
    return {
      type: AHubActions.DISTRIBUTION_GROUP_INDEXS_APPEND,
      distributionGroupIndexs: distributionGroupIndexs
    }
  }

  /**
   * Fetch the distributions based on the ids supplied.
   */
  static distributionsByIdsFetch(distributionIds: number[]): ActionNumberArray {
    return {
      type: AHubActions.DISTRIBUTIONS_BY_IDS_FETCH,
      numbers: distributionIds
    }
  }

  /**
   * Set the distributions
   */
  static distributionsSet(distributions: DistributionAHubVO[]): ActionDistributionAHubVOs {
    return {
      type: AHubActions.DISTRIBUTIONS_SET,
      distributions: distributions
    }
  }

  /**
   * Fetch the distribution indexs for the distributions that relate to the specified distribution groups.
   */
  static distributionGroupDistributionIndexsFetch(distributionGroupId: number): ActionNumber {
    return {
      type: AHubActions.DISTRIBUTION_GROUP_DISTRIBUTION_INDEXS_FETCH,
      number: distributionGroupId
    }
  }

  /**
   * Set the distribution indexs for which are associated to the specified distribution group
   */
  static distributionGroupDistributionIndexsSet(distributionGroupId: number, distributionIndexs: DistributionIndexAHubVO[]): ActionRelationshipAHubVO<DistributionIndexAHubVO> {
    return {
      type: AHubActions.DISTRIBUTION_GROUP_DISTRIBUTION_INDEXS_SET,
      relationship: {
        id: distributionGroupId,
        relationships: distributionIndexs
      }
    }
  }


  /**
   * clear the distribution indexes relationships
   */
  static distributionGroupDistributionIndexsClear(): ActionWork {
    // Construct the action.
    return {
      type: AHubActions.DISTRIBUTION_GROUP_DISTRIBUTION_INDEXES_CLEAR,
    };
  }

  /**
   * Fetch the user indexs for the user that relate to the specified distribution groups.
   */
  static distributionGroupUserIndexsFetch(distributionGroupId: number): ActionNumber {
    return {
      type: AHubActions.DISTRIBUTION_GROUP_USER_INDEXS_FETCH,
      number: distributionGroupId
    }
  }

  /**
   * Set the user indexs for which are associated to the specified distribution group
   */
  static distributionGroupUserIndexsSet(distributionGroupId: number, userIndexs: UserIndexAHubVO[]): ActionRelationshipAHubVO<UserIndexAHubVO> {
    return {
      type: AHubActions.DISTRIBUTION_GROUP_USER_INDEXS_SET,
      relationship: {
        id: distributionGroupId,
        relationships: userIndexs
      }
    }
  }

  /**
   * Fetches full details for one or more distribution groups.
   */
  static distributionGroupsFetch(distributionGroupIds: number[]): ActionNumberArray {
    return {
      type: AHubActions.DISTRIBUTION_GROUPS_FETCH,
      numbers: distributionGroupIds
    }
  }

  /**
   * Set details for a distribution group in the store.
   */
  static distributionGroupSet(distributionGroups: DistributionGroupAHubVO[]): ActionDistributionGroupAHubVOs {
    return {
      type: AHubActions.DISTRIBUTION_GROUPS_SET,
      distributionGroups: distributionGroups
    }
  }


  /**
   * Create an action to add a new distribution group to the aHub.
   */
  static distributionGroupAdd(distributionGroup: DistributionGroupAHubVO): ActionDistributionGroupAHubVO {
    return {
      type: AHubActions.DISTRIBUTION_GROUP_ADD,
      distributionGroup: distributionGroup
    }
  }


  /**
   * Create an action to save a distribution group changes.
   */
  static distributionGroupSave(distributionGroup: DistributionGroupAHubVO): ActionDistributionGroupAHubVO {
    return {
      type: AHubActions.DISTRIBUTION_GROUP_SAVE,
      distributionGroup: distributionGroup
    }
  }


  /**
   * Create an action to delete a distribution group.
   */
  static distributionGroupDelete(distributionGroupId: number): ActionNumber {
    return {
      type: AHubActions.DISTRIBUTION_GROUP_DELETE,
      number: distributionGroupId
    }
  }

  /**
   * Create an action to remove a distribution group.
   */
  static distributionGroupsRemove(distributionGroupIds: number[]): ActionNumberArray {
    return {
      type: AHubActions.DISTRIBUTION_GROUPS_REMOVE,
      numbers: distributionGroupIds
    }
  }


  /**
   * Create an action to add a user email address to a distribution group by id.
   */
  static distributionGroupUserEmailAdd(distributionGroupId: number, userEmail: string): ActionStringNumber {
    return {
      type: AHubActions.DISTRIBUTION_GROUP_USER_EMAIL_ADD,
      string: userEmail,
      number: distributionGroupId
    }
  }

  /**
   * Create an action to remove a user by id from a distribution group by id.
   */
  static distributionGroupUserIdRemove(distributionGroupId: number, userId: number): ActionNumberArray {
    return {
      type: AHubActions.DISTRIBUTION_GROUP_USER_ID_REMOVE,
      numbers: [distributionGroupId, userId]
    }
  }

  /**
   * Create an action to remove users by ids from a distribution group by id.
   */
  static distributionGroupUserIdsRemove(distributionGroupId: number, userIds: number[]): ActionNumberNumbersArray {
    return {
      type: AHubActions.DISTRIBUTION_GROUP_USER_IDS_REMOVE,
      number: distributionGroupId,
      numbers: userIds
    }
  }

  /**
   * Create an action to remove user by id from a distribution groups by ids.
   */
  static distributionGroupsUserIdRemove(userId: number, distributionGroupIds: number[]): ActionNumberNumbersArray {
    return {
      type: AHubActions.DISTRIBUTION_GROUPS_USER_ID_REMOVE,
      number: userId,
      numbers: distributionGroupIds
    }
  }

  /**
   * Create an action to add user by id from a distribution groups by ids.
   */
  static distributionGroupsUserIdAdd(userId: number, distributionGroupIds: number[]): ActionNumberNumbersArray {
    return {
      type: AHubActions.DISTRIBUTION_GROUPS_USER_ID_ADD,
      number: userId,
      numbers: distributionGroupIds
    }
  }

  /**
   * Create an action for user to join DG by key.
   */
  static distributionGroupUserJoinByKey(userId: number, key: string): ActionStringNumber {
    return {
      type: AHubActions.DISTRIBUTION_GROUP_USER_JOIN_BY_KEY,
      string: key,
      number: userId
    }
  }

  /**
   * Create an action to add a new distribution to the aHub.
   */
  static distributionsAdd(distributions: DistributionAHubVO[]): ActionDistributionAHubVOs {
    return {
      type: AHubActions.DISTRIBUTION_ADD,
      distributions: distributions
    }
  }

  /**
   * Create an action to save a distribution to the aHub.
   */
  static distributionSave(distribution: DistributionAHubVO): ActionDistributionAHubVO {
    return {
      type: AHubActions.DISTRIBUTION_SAVE,
      distribution: distribution
    }
  }

  /**
   * Create an action to delete a distribution.
   */
  static distributionDelete(distributionId: number): ActionNumber {
    return {
      type: AHubActions.DISTRIBUTION_DELETE,
      number: distributionId
    }
  }

  /**
   * Create an action to delete a distribution.
   */
  static distributionsDelete(distributionIds: number[]): ActionNumberArray {
    return {
      type: AHubActions.DISTRIBUTIONS_DELETE,
      numbers: distributionIds
    }
  }

  /**
   * Create an action to delete a distribution.
   */
  static distributionsDisable(distributionIds: number[]): ActionNumberArray {
    return {
      type: AHubActions.DISTRIBUTIONS_DISABLE,
      numbers: distributionIds
    }
  }

  /**
   * Create an action to delete a distribution.
   */
  static distributionsEnable(distributionIds: number[]): ActionNumberArray {
    return {
      type: AHubActions.DISTRIBUTIONS_ENABLE,
      numbers: distributionIds
    }
  }

  /**
   * Creates an action to copy the parameters listed in the distribution id to the other distribution ids specified
   */
  static distributionParametersCopy(distributionId: number, distributionIdCopyTo: number[]): ActionNumberNumbersArray {
    return {
      type: AHubActions.DISTRIBUTION_PARAMETERS_COPY,
      number: distributionId,
      numbers: distributionIdCopyTo
    };
  }

  /**
   * Create an action to remove a list of distributions.
  */
  static distributionsRemove(distributionIds: number[]): ActionNumberArray {
    return {
      type: AHubActions.DISTRIBUTIONS_REMOVE,
      numbers: distributionIds
    }
  }

  /**
   * Create an instance of the action to get all of the export types.
   */
  static exportTypesAllFetch(): ActionWork {
    return {
      type: AHubActions.EXPORT_TYPES_ALL_FETCH
    }
  }

  /**
   * Create an instance of the action to get all of the export types.
   */
  static exportTypesAllSet(exportTypes: ExportTypeAHubVO[]): ActionExportTypeAHubVOs {
    return {
      type: AHubActions.EXPORT_TYPES_ALL_SET,
      exportTypes: exportTypes
    }
  }


  /**
   * Create an instance of the action to get all of the export generators.
   */
  static exportGeneratorsAllFetch(): ActionWork {
    return {
      type: AHubActions.EXPORT_GENERATORS_ALL_FETCH
    }
  }

  /**
   * Create an instance of the action to get all of the export fenerators.
   */
  static exportGeneratorsAllSet(exportGenerators: ExportGeneratorAHubVO[]): ActionExportGeneratorAHubVOs {
    return {
      type: AHubActions.EXPORT_GENERATORS_ALL_SET,
      exportGenerators: exportGenerators
    }
  }


  /**
   * Create an instance of the action to get all of the export generators.
   */
  static exportGeneratorMappingBlueprintURLFetch(exporterId, generatorId: number,
    generatorMajorVersion?: number, generatorMinorVersion?: number): ActionExporterBlueprintFetch {
    return {
      type: AHubActions.EXPORT_GENERATOR_MAPPING_BLUEPRINT_URL_FETCH,
      exporterId: exporterId,
      exporterGeneratorId: generatorId,
      exporterGeneratorMajorVersion: generatorMajorVersion,
      exporterGeneratorMinorVersion: generatorMinorVersion
    }
  }

  /**
   * Create an instance of the action to get all of the export fenerators.
   */
  static exportGeneratorMappingBlueprintURLSet(generatorId: number, url: string): ActionStringNumber {
    return {
      type: AHubActions.EXPORT_GENERATOR_MAPPING_BLUEPRINT_URL_SET,
      number: generatorId,
      string: url
    }
  }

  /**
   * Fetch the export indexs
   */
  static exportIndexsFetch(): ActionWork {
    return {
      type: AHubActions.EXPORT_INDEXS_FETCH,
    }
  }

  /**
   * Set the export indexs
   */
  static exportIndexsSet(exportIndexs: ExportIndexAHubVO[]): ActionExportIndexAHubVOs {
    return {
      type: AHubActions.EXPORT_INDEXS_SET,
      exportIndexs: exportIndexs
    }
  }

  /**
   * Fetch the exports by the ids supplied
   */
  static exportsByIdFetch(ids: number[]): ActionNumberArray {
    return {
      type: AHubActions.EXPORTS_BY_ID_FETCH,
      numbers: ids
    }
  }

  /**
   * Fetch the distribution indexs for the distributions that relate to the specified exports.
   */
  static exportDistributionIndexsFetch(exportId: number): ActionNumber {
    return {
      type: AHubActions.EXPORT_DISTRIBUTION_INDEXS_FETCH,
      number: exportId
    }
  }

  /**
   * Set the distribution indexs for which are associated to the specified export
   */
  static exportDistributionIndexsSet(exportId: number, distributionIndexs: DistributionIndexAHubVO[]): ActionRelationshipAHubVO<DistributionIndexAHubVO> {
    return {
      type: AHubActions.EXPORT_DISTRIBUTION_INDEXS_SET,
      relationship: {
        id: exportId,
        relationships: distributionIndexs
      }
    }
  }

  /**
   * Clear the distribution indexs relationships
   */
  static exportDistributionIndexsClear(): ActionWork {
    // Construct the action.
    return {
      type: AHubActions.EXPORT_DISTRIBUTION_INDEXES_CLEAR,
    };
  }

  /**
   * Set the exports
   */
  static exportsSet(exportsVal: ExportAHubVO[]): ActionExportAHubVOs {

    //Exports appears to be reserved word.
    return {
      type: AHubActions.EXPORTS_SET,
      exports: exportsVal
    }
  }

  /**
   * Create an action to add a new export to the aHub.
   */
  static exportAdd(exportVO: ExportAHubVO): ActionExportAHubVO {
    return {
      type: AHubActions.EXPORT_ADD,
      export: exportVO
    }
  }

  /**
   * Create an action to save a export changes.
   */
  static exportSave(exportVO: ExportAHubVO): ActionExportAHubVO {
    return {
      type: AHubActions.EXPORT_SAVE,
      export: exportVO
    }
  }

  /**
   * Create an action to delete an export by its id.
   */
  static exportDelete(exportId: number): ActionNumber {
    return {
      type: AHubActions.EXPORT_DELETE,
      number: exportId
    }
  }

  /**
   * Create an action to remove an export by its id from the store.
   */
  static exportsRemove(exportIds: number[]): ActionNumberArray {
    return {
      type: AHubActions.EXPORTS_REMOVE,
      numbers: exportIds
    }
  }



  /**
   * Fetches an image URL representing an export.
   */
  static exportPreviewImageURLFetch(exportId: number, versionId: number): ActionNumberNumber {
    return {
      type: AHubActions.EXPORT_PREVIEWIMAGE_URL_FETCH,
      number1: exportId,
      number2: versionId
    }
  }

  /**
   * Store an image URL for an export in the store.
   */
  static exportPreviewImageURLSet(exportId: number, exportPreviewImageUrl: PresignedUrlAHubVO): ActionIdItem<PresignedUrlAHubVO> {
    return {
      type: AHubActions.EXPORT_PREVIEWIMAGE_URL_SET,
      idItem: { id: exportId, item: exportPreviewImageUrl }
    }
  }

  /**
   * Fetches the path URL export latest version.
   */
  static exportLatestVersionPathURLFetch(exportId: number): ActionNumber {
    return {
      type: AHubActions.EXPORT_LATEST_VERSION_PATH_URL_FETCH,
      number: exportId
    }
  }

  /**
   * Store the path URL export latest version.
   */
  static exportLatestVersionPathURLSet(exportId: number, exportLatestVersionPathUrl: PresignedUrlAHubVO): ActionIdItem<PresignedUrlAHubVO> {
    return {
      type: AHubActions.EXPORT_LATEST_VERSION_PATH_URL_SET,
      idItem: { id: exportId, item: exportLatestVersionPathUrl }
    }
  }

  /**
   * Fetches the path URL export specified version.
   */
  static exportVersionPathURLFetch(exportId: number, version: number): ActionNumberNumber {
    return {
      type: AHubActions.EXPORT_VERSION_PATH_URL_FETCH,
      number1: exportId,
      number2: version
    }
  }

  /**
   * Store the path URL export version.
   */
  static exportVersionPathURLSet(exportId: number, exportVersionPathUrl: PresignedUrlAHubVO): ActionIdItem<PresignedUrlAHubVO> {
    return {
      type: AHubActions.EXPORT_VERSION_PATH_URL_SET,
      idItem: { id: exportId, item: exportVersionPathUrl }
    }
  }

  /**
   * Export version commit
   */
  static exportVersionCommit(exportVersion: ExportVersionAHubVO): ActionExportVersionAHubVO {
    return {
      type: AHubActions.EXPORT_VERSION_COMMIT,
      exportVersion: exportVersion
    }
  }

  /**
   * Export version delete
   */
  static exportVersionDelete(exportId: number, exportVersionNumber: number): ActionExportIdExportVersionNumber {
    return {
      type: AHubActions.EXPORT_VERSION_DELETE,
      exportId: exportId,
      exportVersionNumber: exportVersionNumber
    }
  }

  /**
   * Export version withdraw
   */
  static exportVersionWithdraw(exportId: number, exportVersionNumber: number, withdraw: boolean): ActionExportVersionWithdraw {
    return {
      type: AHubActions.EXPORT_VERSION_WITHDRAW,
      exportId: exportId,
      exportVersionNumber: exportVersionNumber,
      withdraw: withdraw
    }
  }

  /**
   * Action to change the state of an export version to disabled
   */
  static exportVerionStateHiddenSave(exportId: number, exportVersionNumber: number): ActionExportIdExportVersionNumber {
    return {
      type: AHubActions.EXPORT_VERSION_STATE_HIDDEN_SAVE,
      exportId: exportId,
      exportVersionNumber: exportVersionNumber
    }
  }

  /**
   * Action to change the state of an export version to internal
   */
  static exportVerionStateInternalSave(exportId: number, exportVersionNumber: number): ActionExportIdExportVersionNumber {
    return {
      type: AHubActions.EXPORT_VERSION_STATE_INTERNAL_SAVE,
      exportId: exportId,
      exportVersionNumber: exportVersionNumber
    }
  }

  /**
   * Action to change the state of an export version to preview
   */
  static exportVerionStatePreviewSave(exportId: number, exportVersionNumber: number): ActionExportIdExportVersionNumber {
    return {
      type: AHubActions.EXPORT_VERSION_STATE_PREVIEW_SAVE,
      exportId: exportId,
      exportVersionNumber: exportVersionNumber
    }
  }

  /**
   * Action to change the state of an export version to limited release
   */
  static exportVerionStateLimitedReleaseSave(exportId: number, exportVersionNumber: number): ActionExportIdExportVersionNumber {
    return {
      type: AHubActions.EXPORT_VERSION_STATE_LIMITED_RELEASE_SAVE,
      exportId: exportId,
      exportVersionNumber: exportVersionNumber
    }
  }

  /**
   * Action to change the state of an export version to release
   */
  static exportVerionStateReleaseSave(exportId: number, exportVersionNumber: number): ActionExportIdExportVersionNumber {
    return {
      type: AHubActions.EXPORT_VERSION_STATE_RELEASE_SAVE,
      exportId: exportId,
      exportVersionNumber: exportVersionNumber
    }
  }

  /**
   * Generates and downloads a zip file of the export version files and prepares for download (worklog triggered download)
   *
   * @param exportId    Id of the export
   * @param versionNumber  version number of the export to be downloaded
   */
  static exportVersionFilesZipDownload(exportId: number, versionNumber: number): ActionExportIdExportVersionNumber {
    return {
      type: AHubActions.EXPORT_VERSION_FILES_ZIP_DOWNLOAD,
      exportId: exportId,
      exportVersionNumber: versionNumber
    };
  }

  /**
   * fetch the export legacy ref(s).
   */
  static exportLegacyRefFetch(exportId: number): ActionNumber {

    // Construct the action with the specified interace.
    return {
      type: AHubActions.EXPORT_LEGACY_REF_FETCH,
      number: exportId
    };
  }

  /**
   * commit the export legacy ref(s).
   */
  static exportLegacyRefSet(exportId: number, legacyRefs: string[]): ActionIdItem<string[]> {
    // Construct the action with the specified interace.
    return {
      type: AHubActions.EXPORT_LEGACY_REF_SET,
      idItem: { id: exportId, item: legacyRefs }
    };
  }

  /**
   * commit the export legacy ref(s).
   */
  static exportLegacyRefCommit(exportId: number, legacyRefs: string[]): ActionNumberStringArray {

    // Construct the action with the specified interace.
    return {
      type: AHubActions.EXPORT_LEGACY_REF_COMMIT,
      number: exportId,
      strings: legacyRefs
    };
  }

  /**
   * fetch the export distributions (much like aWorkbook does).
   */
  static exportDistributionsFetch(exportTypeCodes: ExportTypeCodeAHubEnum[], userId: number): ActionExportTypeCodesAndNumber {

    // Construct the action with the specified interace.
    return {
      type: AHubActions.EXPORT_DISTRIBUTIONS_FETCH,
      exporTypeCodes: exportTypeCodes,
      number: userId
    };
  }

  /**
   * Set the exports
   */
  static exportDistributionsSet(exportDistributions: ExportDistributionAHubVO[]): ActionExportDistributionAHubVOs {

    //Exports appears to be reserved word.
    return {
      type: AHubActions.EXPORT_DISTRIBUTIONS_SET,
      exportDistributions: exportDistributions
    }
  }

  /**
   * Fetch the export indexs
   */
  static exporterIndexesFetch(): ActionWork {
    return {
      type: AHubActions.EXPORTER_INDEXES_FETCH,
    }
  }

  /**
   * Set the exporter indexes
   */
  static exporterIndexesSet(exporterIndexes: ExporterIndexAHubVO[]): ActionExporterIndexAHubVOs {
    return {
      type: AHubActions.EXPORTER_INDEXES_SET,
      exporterIndexes: exporterIndexes
    }
  }

  /**
   * Fetch the exports by the ids supplied
   */
  static exportersByIdFetch(ids: number[]): ActionNumberArray {
    return {
      type: AHubActions.EXPORTERS_BY_ID_FETCH,
      numbers: ids
    }
  }

  /**
   * Set the exports
   */
  static exportersSet(exportersVal: ExporterAHubVO[]): ActionExporterAHubVOs {

    //Exports appears to be reserved word.
    return {
      type: AHubActions.EXPORTERS_SET,
      exporters: exportersVal
    }
  }

  /**
   * Create an action to add a new export to the aHub.
   */
  static exporterAdd(exporterVO: ExporterAHubVO): ActionExporterAHubVO {
    return {
      type: AHubActions.EXPORTER_ADD,
      exporter: exporterVO
    }
  }

  /**
   * Create an action to save a exporter changes.
   */
  static exporterSave(exporterVO: ExporterAHubVO): ActionExporterAHubVO {
    return {
      type: AHubActions.EXPORTER_SAVE,
      exporter: exporterVO
    }
  }

  /**
* Create an action to remove an export by its id from the store.
*/
  static exportersRemove(exporterIds: number[]): ActionNumberArray {
    return {
      type: AHubActions.EXPORTERS_REMOVE,
      numbers: exporterIds
    }
  }

  /**
   * Create an action to commit an exporter mapping.
   */
  static exporterMappingCommit(exporterId: number, exportGeneratorExporterMapping: ExportGeneratorExporterMappingAHubVO): ActionExportGeneratorExporterMappingAHubVO {
    return {
      type: AHubActions.EXPORTER_MAPPING_COMMIT,
      exporterId: exporterId,
      exportGeneratorExporterMapping: exportGeneratorExporterMapping
    }
  }

  /**
   * Create an action to commit an exporter settings.
   */
  static exporterSettingsCommit(exporterId: number, exporterExportGeneratorSettings: ExporterExportGeneratorSettingsVO): ActionExporterExportGeneratorSettingsAHubVO {
    return {
      type: AHubActions.EXPORTER_SETTINGS_COMMIT,
      exporterId: exporterId,
      exporterExportGeneratorSettings: exporterExportGeneratorSettings
    }
  }

  /**
   * Create an action to delete an exporter by its id.
   */
  static exporterDelete(exporterId: number): ActionNumber {
    return {
      type: AHubActions.EXPORTER_DELETE,
      number: exporterId
    }
  }

  /**
   * Create an action to copy an exporter by its id.
   */
  static exporterCopy(exporterId: number, newExporterName: string): ActionStringNumber {
    return {
      type: AHubActions.EXPORTER_COPY,
      number: exporterId,
      string: newExporterName
    }
  }

  /**
   * Create an action to delete an exporter by its id.
   */
  static exporterBuild(exporterId: number): ActionNumber {
    return {
      type: AHubActions.EXPORTER_BUILD,
      number: exporterId
    }
  }

  /**
   * Create an action to cancel an exporters build.
   * 
   * @param exporterId        The id of the exporter who's build we want to cancel.
   */
  static exporterCancelBuild(exporterId: number): ActionNumber {
    return {
      type: AHubActions.EXPORTER_CANCEL_BUILD,
      number: exporterId
    }
  }

  /**
   * Fetch the exporter build history indexes for the given exporter
   */
  static exporterBuildHistoryIndexesFetch(exporterId: number): ActionNumber {
    return {
      type: AHubActions.EXPORTER_BUILD_HISTORY_INDEX_FETCH,
      number: exporterId
    }
  }

  /**
   * Set the build history indexes against the export id specified
   */
  static exporterBuildHistoryIndexesSet(exporterId: number, buildHistoryIndex: ExporterBuildHistoryIndexAHubVO[]): ActionExporterBuildHistoryIndexesAHubVO {
    return {
      type: AHubActions.EXPORTER_BUILD_HISTORY_INDEX_SET,
      exporterId: exporterId,
      buildHistoryIndexes: buildHistoryIndex
    }
  }

  /**
   * Create an action to get exporter build histories by id's.
   *
   * @param historyIds            The id's of the exporter builder histories that we want to fetch.
   */
  static exporterBuildHistorysByIdsFetch(historyIds: number[]): ActionNumberArray {
    return {
      type: AHubActions.EXPORTER_BUILD_HISTORYS_BY_IDS_FETCH,
      numbers: historyIds
    };
  }

  /**
   * Create an action to set the exporter builder histories in the store.
   *
   * @param exporterBuilderHistories      The exporter builder histories to set too.
   */
  static exporterBuildHistorysSet(exporterBuildHistories: ExporterBuildHistoryAHubVO[]): ActionExporterBuildHistoryAHubVOs {
    return {
      type: AHubActions.EXPORTER_BUILD_HISTORYS_SET,
      exporterBuildHistories: exporterBuildHistories
    };
  }

  /**
   * Create an action to remov exporter build historys from the store.
   *
   * @param historyIds            The id's of the exporter build historys to remove.
   */
  static exporterBuildHistoryRemove(historyIds: number[]): ActionNumberArray {
    return {
      type: AHubActions.EXPORTER_BUILD_HISTORYS_REMOVE,
      numbers: historyIds
    };
  }


  /**
   * Fetches full details for one or more work groups.
   */
  static workGroupsFetch(workGroupIds: number[]): ActionNumberArray {
    return {
      type: AHubActions.WORK_GROUPS_FETCH,
      numbers: workGroupIds
    }
  }

  /**
* Fetches full details for one or more work groups.
*/
  static workGroupsByUserAndClientFetch(): ActionWork {
    return {
      type: AHubActions.WORK_GROUPS_BY_USER_AND_CLIENT_FETCH,
    }
  }

  /**
   * Set the workGroups.
   */
  static workGroupsSet(workGroupsVal: WorkGroupAHubVO[]): ActionWorkGroupAHubVOs {

    // WorkGroups appears to be reserved word.
    return {
      type: AHubActions.WORK_GROUPS_SET,
      workGroups: workGroupsVal
    }
  }

  /**
   * Set the workGroups by user and client into the permanent part of the store.
   */
  static workGroupsPermanentSet(workGroupsVal: WorkGroupAHubVO[]): ActionWorkGroupAHubVOs {

    // WorkGroups appears to be reserved word.
    return {
      type: AHubActions.WORK_GROUPS_PERMANENT_SET,
      workGroups: workGroupsVal
    }
  }

  /**
* Create an action to add a new workGroup to the aHub.
*/
  static workGroupAdd(workGroupVO: WorkGroupAHubVO): ActionWorkGroupAHubVO {
    return {
      type: AHubActions.WORK_GROUP_ADD,
      workGroup: workGroupVO
    }
  }

  /**
   * Create an action to save a workGroup changes.
   */
  static workGroupSave(workGroupVO: WorkGroupAHubVO): ActionWorkGroupAHubVO {
    return {
      type: AHubActions.WORK_GROUP_SAVE,
      workGroup: workGroupVO
    }
  }

  /**
   * Create an action to delete an workGroup by its id.
   */
  static workGroupDelete(workGroupId: number): ActionNumber {
    return {
      type: AHubActions.WORK_GROUP_DELETE,
      number: workGroupId
    }
  }

  /**
   * Create an action to remove an workGroup by its id from the store.
   */
  static workGroupsRemove(workGroupIds: number[]): ActionNumberArray {
    return {
      type: AHubActions.WORK_GROUPS_REMOVE,
      numbers: workGroupIds
    }
  }

  /**
* Fetch the user indexs for the user that relate to the specified work groups.
*/
  static workGroupUserIndexsFetch(workGroupId: number): ActionNumber {
    return {
      type: AHubActions.WORK_GROUP_USER_INDEXS_FETCH,
      number: workGroupId
    }
  }

  /**
   * Set the user indexs for which are associated to the specified work group
   */
  static workGroupUserIndexsSet(workGroupId: number, userIndexs: UserIndexAHubVO[]): ActionRelationshipAHubVO<UserIndexAHubVO> {
    return {
      type: AHubActions.WORK_GROUP_USER_INDEXS_SET,
      relationship: {
        id: workGroupId,
        relationships: userIndexs
      }
    }
  }


  /**
   * Create an action to add a user email address to a work group by id.
   */
  static workGroupUserEmailAdd(workGroupId: number, userEmail: string): ActionStringNumber {
    return {
      type: AHubActions.WORK_GROUP_USER_EMAIL_ADD,
      string: userEmail,
      number: workGroupId
    }
  }

  /**
   * Create an action to remove a user by id from a work group by id.
   */
  static workGroupUserIdRemove(workGroupId: number, userId: number): ActionNumberArray {
    return {
      type: AHubActions.WORK_GROUP_USER_ID_REMOVE,
      numbers: [workGroupId, userId]
    }
  }

  /**
   * Create an action for user to join WG by key.
   */
  static workGroupUserJoinByKey(userId: number, key: string): ActionStringNumber {
    return {
      type: AHubActions.WORK_GROUP_USER_JOIN_BY_KEY,
      string: key,
      number: userId
    }
  }

  /**
   * Create an action to remove users by id from a work group by id.
   */
  static workGroupUserIdsRemove(workGroupId: number, userIds: number[]): ActionNumberNumbersArray {
    return {
      type: AHubActions.WORK_GROUP_USER_IDS_REMOVE,
      number: workGroupId,
      numbers: userIds
    }
  }

  /**
   * ----------------------------------------------------------
   * Library Actions
   * ----------------------------------------------------------
   */

  /**
   * Action to cancel an update of a currently generating export
   */
  static exportUpdateCancel(exportId: number): ActionNumber {
    return {
      type: AHubActions.EXPORT_UPDATE_CANCEL_REMOVE,
      number: exportId
    }
  }

  /**
   * ----------------------------------------------------------
   * Work Actions
   * ----------------------------------------------------------
   */

  /**
   * Create an action to download all the files
   */
  static workflowFilesDownload(workflowId: string): ActionString {
    return {
      type: AHubActions.WORKFLOW_FILES_DOWNLOAD,
      string: workflowId
    }
  }

  /**
   * Create an action to download a workflows files.
   */
  static worklogFileDownload(workflowId: string, filePath: string, saveFilename: string): ActionStringArray {
    return {
      type: AHubActions.WORKLOG_FILE_DOWNLOAD,
      strings: [workflowId, filePath, saveFilename]
    }
  }

  /**
   * Fetches worklogs from a particular moment in time, including those that have been updated
   * ( even if started before this point )
   * Initially call with current time, then repeat call with segment
   * log time returned to make subsequent calls using this method
   * to ensure a continous stream of workogs. This method is long polling and will
   * idle for a period if no new logs since time have occured.
   */
  static worklogSegmentFetch(afterTime: Date): ActionDate {
    return {
      type: AHubActions.WORKLOG_SEGMENT_FETCH,
      date: afterTime
    }
  }

  /**
   * Called when an error has occured fetching the worklogs.
   * We need to take action to recover.
   */
  static worklogSegmentFetchError(): ActionWork {
    return {
      type: AHubActions.WORKLOG_SEGMENT_FETCH_ERROR
    }
  }

  /**
   * Updates the stores work logs with the the returned worklogs segment.
   */
  static worklogSegmentSet(worklogSegment: WorklogSegmentAHubVO): ActionWorklogSegmentAHubVO {
    return {
      type: AHubActions.WORKLOG_SEGMENT_SET,
      worklogSegment: worklogSegment
    }
  }

  /**
   * Removes old complete worklogs from teh store.
   */
  static worklogPurge(purgeDate: Date): ActionDate {
    return {
      type: AHubActions.WORKLOG_PURGE,
      date: purgeDate
    }
  }

  /**
* Fetch the index for the work groups.
*/
  static workGroupIndexsFetch(): ActionWork {
    return {
      type: AHubActions.WORK_GROUP_INDEXS_FETCH
    }
  }

  /**
   * Set the index for the work groups in the store
   */
  static workGroupIndexsSet(workGroupIndexs: WorkGroupIndexAHubVO[]): ActionWorkGroupIndexAHubVOs {
    return {
      type: AHubActions.WORK_GROUP_INDEXS_SET,
      workGroupIndexs: workGroupIndexs
    }
  }

  /**
  * Action to return client quota information for the given client id.
  */
  static clientQuotasByClientIdFetch(clientId: number): ActionNumber {
    return {
      type: AHubActions.CLIENT_QUOTAS_BY_CLIENT_ID_FETCH,
      number: clientId
    }
  }

  /**
  * Action to return client quota information for the given client id.
  */
  static clientQuotasHistoryByClientIdFetch(clientId: number): ActionNumber {
    return {
      type: AHubActions.CLIENT_QUOTAS_HISTORY_BY_CLIENT_ID_FETCH,
      number: clientId
    }
  }

  /**
  * Action to return client quota information for the given client id.
  */
  static clientQuotaByIdsFetch(ids: number[]): ActionNumberArray {
    return {
      type: AHubActions.CLIENT_QUOTA_BY_CLIENT_QUOTA_ID_FETCH,
      numbers: ids
    }
  }

  /**
   * Action to set the client objects in the store.
   */
  static clientQuotasSet(clientQuotas: ClientQuotaAHubVO[]): ActionClientQuotasAHubVOs {
    return {
      type: AHubActions.CLIENT_QUOTAS_SET,
      clientQuotas: clientQuotas
    }
  }

  /**
   * Action to set the client objects in the store.
   */
  static clientQuotasHistorySet(clientQuotas: ClientQuotaAHubVO[]): ActionClientQuotasAHubVOs {
    return {
      type: AHubActions.CLIENT_QUOTAS_HISTORY_SET,
      clientQuotas: clientQuotas
    }
  }

  /**
   * Action to set the client objects in the store.
   */
  static clientQuotaSet(clientQuota: ClientQuotaAHubVO): ActionClientQuotaAHubVO {
    return {
      type: AHubActions.CLIENT_QUOTA_SET,
      clientQuota: clientQuota
    }
  }

  /**
  * Create an action to save a workGroup changes.
  */
  static clientQuotasCommit(clientQuotaVOs: ClientQuotaAHubVO[]): ActionClientQuotasAHubVOs {
    return {
      type: AHubActions.CLIENT_QUOTAS_COMMIT,
      clientQuotas: clientQuotaVOs
    }
  }

  /**
  * Action to return client configuration information for the given client id.
  */
  static clientConfigurationByClientIdFetch(clientId: number): ActionNumber {
    return {
      type: AHubActions.CLIENT_CONFIGURATION_BY_CLIENT_ID_FETCH,
      number: clientId
    }
  }

  /**
   * Action to set the client configuration in the store.
   */
  static clientConfigurationSet(clientConfiguration: ClientConfigurationAHubVO): ActionClientConfigurationAHubVO {
    return {
      type: AHubActions.CLIENT_CONFIGURATION_SET,
      clientConfiguration: clientConfiguration
    };
  }

  /**
   * Action to set the client configuration in the store.
   */
  static clientConfigurationCommit(clientConfiguration: ClientConfigurationAHubVO): ActionClientConfigurationAHubVO {
    return {
      type: AHubActions.CLIENT_CONFIGURATION_COMMIT,
      clientConfiguration: clientConfiguration
    };
  }

  /**
  * Create an action to register a user.
  */
  static registerUser(firstName, lastName, email): ActionStringArray {
    return {
      type: AHubActions.REGISTER_USER,
      strings: [firstName, lastName, email]
    }
  }

  /**
  * Create an action to verify a user.
  */
  static verifyUser(userEmail, userPassword, verificationCode): ActionStringArray {
    return {
      type: AHubActions.VERIFY_USER,
      strings: [userEmail, userPassword, verificationCode]
    }
  }

  /**
  * Create an action to verify an email address update
  */
  static sendEmailUpdateVerificationCode(updatedEmail, userId): ActionStringNumber {
    return {
      type: AHubActions.SEND_EMAIL_UPDATE_VERIFICATION_CODE,
      string: updatedEmail,
      number: userId
    }
  }

  /**
   * Allow system users to manually override user verification.
   * This method will 'verify' the specified user as if they had
   * correctly entered the verification code sent to their email address
   */
  static verifyUserManualOverride(userId): ActionNumber {
    return {
      type: AHubActions.VERIFY_USER_MANUAL_OVERRIDE,
      number: userId
    }
  }

  /**
  * Create an action to send an email to support about problems during verification
  */
  static verificationProblemsContactSupport(email): ActionString {
    return {
      type: AHubActions.VERIFICATION_PROBLEMS_CONTACT_SUPPORT,
      string: email
    }
  }

  /**
  * Create an action to send an email to support.
  */
  static sendVerificationCode(email): ActionString {
    return {
      type: AHubActions.SEND_VERIFICATION_CODE,
      string: email
    }
  }

  /**
  * Create an action to register a user.
  */
  static verifyEmailUpdate(userId, verificationCode): ActionStringNumber {
    return {
      type: AHubActions.VERIFY_EMAIL_UPDATE,
      string: verificationCode,
      number: userId
    }
  }

  /**
   * Get a list of the product class product counts.
   */
  static productClassProductCountsFetch(): ActionWork {
    return {
      type: AHubActions.PRODUCT_CLASS_PRODUCT_COUNTS_FETCH
    };
  }

  /**
   * Set the list of product class product counts.
   *
   * @param productClassProductCounts                 The counts that we need to set.
   */
  static productClassProductCountsSet(productClassProductCounts: ProductClassProductCount[]): ActionProductClassProductCountAHubVOs {
    return {
      type: AHubActions.PRODUCT_CLASS_PRODUCT_COUNTS_SET,
      productClassProductCounts: productClassProductCounts
    };
  }

  /**
   * Fetch the product class indexs
   */
  static productClassIndexsByClientIdFetch(): ActionWork {
    return {
      type: AHubActions.PRODUCT_CLASS_INDEXS_BY_CLIENT_ID_FETCH
    };
  }

  /**
   * Set the product class indexs
   */
  static productClassIndexsSet(productClassIndexs: ProductClassIndexAHubVO[]): ActionProductClassIndexAHubVOs {
    return {
      type: AHubActions.PRODUCT_CLASS_INDEXS_SET,
      productClassIndexs: productClassIndexs
    };
  }

  /**
   * Fetch the product class by the clientid and product class id supplied.
   */
  static productClassesFetch(productClassIds: number[]): ActionNumberArray {
    return {
      type: AHubActions.PRODUCT_CLASSES_FETCH,
      numbers: productClassIds
    };
  }

  /**
   * Set the product class indexs
   */
  static productClassesSet(productClasses: ProductClassAHubVO[]): ActionProductClassAHubVOs {
    return {
      type: AHubActions.PRODUCT_CLASSES_SET,
      productClasses: productClasses
    };
  }


  /**
   * Create an action to add a new product class to the aHub.
   */
  static productClassAdd(productClassVO: ProductClassAHubVO): ActionProductClassAHubVO {
    return {
      type: AHubActions.PRODUCT_CLASS_ADD,
      productClass: productClassVO
    }
  }

  /**
   * Create an action to commit a new product class to the aHub.
   */
  static productClassCommit(productClassVO: ProductClassAHubVO): ActionProductClassAHubVO {
    return {
      type: AHubActions.PRODUCT_CLASS_COMMIT,
      productClass: productClassVO
    }
  }

  /**
   * Create an action to delete product class from the aHub.
   */
  static productClassDelete(productClassId: number): ActionNumber {
    return {
      type: AHubActions.PRODUCT_CLASS_DELETE,
      number: productClassId
    }
  }

  /**
   * Create an action to delete product class from the aHub.
   */
  static productClassMove(productClassId: number, parentClassId): ActionNumberNumber {
    return {
      type: AHubActions.PRODUCT_CLASS_MOVE,
      number1: productClassId,
      number2: parentClassId
    }
  }


  /**
   * Create an action to remove an workGroup by its id from the store.
   */
  static productClassesRemove(productClassIds: number[]): ActionNumberArray {
    return {
      type: AHubActions.PRODUCT_CLASSES_REMOVE,
      numbers: productClassIds
    }
  }

  /**
   * Create an action to get the product property types.
   */
  static productPropertyTypesFetch(): ActionWork {
    return {
      type: AHubActions.PRODUCT_PROPERTY_TYPES_BY_CLIENT_ID_FETCH
    };
  }

  /**
   * Create an action to set the product property types.
   *
   * @param productPropertyTypes          The product property types to set too.
   */
  static productPropertyTypesSet(productPropertyTypes: ProductPropertyTypeAHubVO[]): ActionProductPropertyTypesAHubVOs {
    return {
      type: AHubActions.PRODUCT_PROPERTY_TYPES_SET,
      productPropertyTypes: productPropertyTypes
    };
  }

  /**
   * Fetch the product properties indexs
   */
  static productPropertyIndexsFetch(): ActionWork {
    return {
      type: AHubActions.PRODUCT_PROPERTY_INDEXS_BY_CLIENT_ID_FETCH
    };
  }

  /**
   * Set the product property indexs in the store
   */
  static productPropertyIndexsSet(productPropertyIndexs: ProductPropertyIndexAHubVO[]): ActionProductPropertyIndexAHubVOs {
    return {
      type: AHubActions.PRODUCT_PROPERTY_INDEXS_SET,
      productPropertyIndexes: productPropertyIndexs
    };
  }

  /**
   * Fetch the product property by the client id and product property ids supplied.
   */
  static productPropertyFetch(productPropertyIds: number[]): ActionNumberArray {
    return {
      type: AHubActions.PRODUCT_PROPERTY_FETCH,
      numbers: productPropertyIds
    };
  }

  /**
   * Set the product property
   */
  static productPropertySet(productProperties: ProductPropertyAHubVO[]): ActionProductPropertyAHubVOs {
    return {
      type: AHubActions.PRODUCT_PROPERTY_SET,
      productProperties: productProperties
    };
  }

  /**
   * Create an action to add a new product property to the aHub.
   */
  static productPropertyAdd(productPropertyVO: ProductPropertyAHubVO): ActionProductPropertyAHubVO {
    return {
      type: AHubActions.PRODUCT_PROPERTY_ADD,
      productProperty: productPropertyVO
    }
  }

  /**
   * Create an action to commit an existing product property to the aHub.
   */
  static productPropertyCommit(productPropertyVO: ProductPropertyAHubVO): ActionProductPropertyAHubVO {
    return {
      type: AHubActions.PRODUCT_PROPERTY_COMMIT,
      productProperty: productPropertyVO
    }
  }

  /**
   * Create an action to delete product property from the aHub.
   */
  static productPropertyDelete(productPropertyId: number): ActionNumber {
    return {
      type: AHubActions.PRODUCT_PROPERTY_DELETE,
      number: productPropertyId
    }
  }

  /**
   * Create an action to remove product properties by it ids from the store.
   */
  static productPropertyRemove(productPropertyIds: number[]): ActionNumberArray {
    return {
      type: AHubActions.PRODUCT_PROPERTY_REMOVE,
      numbers: productPropertyIds
    }
  }

  /**
   * Fetch the product properties indexs
   */
  static productPropertySectionIndexsFetch(): ActionWork {
    return {
      type: AHubActions.PRODUCT_PROPERTY_SECTION_INDEXS_BY_CLIENT_ID_FETCH
    };
  }

  /**
   * Set the product property indexs in the store
   */
  static productPropertySectionIndexsSet(productPropertySectionIndexs: ProductPropertySectionIndexAHubVO[]): ActionProductPropertySectionIndexAHubVOs {
    return {
      type: AHubActions.PRODUCT_PROPERTY_SECTION_INDEXS_SET,
      productPropertySectionIndexs: productPropertySectionIndexs
    };
  }


  /**
   * Fetch the product class by the clientid and product class id supplied.
   */
  static productPropertySectionsFetch(clientId: number, productPropertySectionIds: number[]): ActionNumberNumbersArray {
    return {
      type: AHubActions.PRODUCT_PROPERTY_SECTIONS_FETCH,
      number: clientId,
      numbers: productPropertySectionIds
    };
  }

  /**
   * Set the product property sections
   */
  static productPropertySectionsSet(productPropertySections: ProductPropertySectionAHubVO[]): ActionProductPropertySectionAHubVOs {
    return {
      type: AHubActions.PRODUCT_PROPERTY_SECTIONS_SET,
      productPropertySections: productPropertySections
    };
  }

  /**
   * Create an action to add a new product property section to the aHub.
   */
  static productPropertySectionAdd(productPropertySectionVO: ProductPropertySectionAHubVO): ActionProductPropertySectionAHubVO {
    return {
      type: AHubActions.PRODUCT_PROPERTY_SECTION_ADD,
      productPropertySection: productPropertySectionVO,
      sourceSectionId: null,
      deepCopy: false
    }
  }

  /**
   * Create an action to add a new product property section to the aHub
   * based on an existing section.
   */
  static productPropertySectionCopy(productPropertySectionVO: ProductPropertySectionAHubVO, sourceSectionId: number, deepCopy: boolean): ActionProductPropertySectionAHubVO {
    return {
      type: AHubActions.PRODUCT_PROPERTY_SECTION_COPY,
      productPropertySection: productPropertySectionVO,
      sourceSectionId: sourceSectionId,
      deepCopy: deepCopy
    }
  }

  /**
   * Create an action to commit an existing product property section to the aHub.
   */
  static productPropertySectionCommit(productPropertySectionVO: ProductPropertySectionAHubVO): ActionProductPropertySectionAHubVO {
    return {
      type: AHubActions.PRODUCT_PROPERTY_SECTION_COMMIT,
      productPropertySection: productPropertySectionVO,
      sourceSectionId: null,
      deepCopy: false
    }
  }

  /**
   * Create an action to delete product property section from the aHub.
   */
  static productPropertySectionDelete(productPropertySectionId: number): ActionNumber {
    return {
      type: AHubActions.PRODUCT_PROPERTY_SECTION_DELETE,
      number: productPropertySectionId
    }
  }

  /**
   * Create an action to remove product property sections by it ids from the store.
   */
  static productPropertySectionsRemove(productPropertySectionIds: number[]): ActionNumberArray {
    return {
      type: AHubActions.PRODUCT_PROPERTY_SECTIONS_REMOVE,
      numbers: productPropertySectionIds
    }
  }

  /**
   * Fetch the product property alloactions
   */
  static productPropertyAllocationsFetch(): ActionWork {
    return {
      type: AHubActions.PRODUCT_PROPERTY_ALLOCATION_INDEXS_FETCH
    };
  }

  /**
   * Set the product property allocations in the store
   */
  static productPropertyAllocationsSet(productPropertyAllocations: ProductPropertyAllocationAHubVO[]): ActionProductPropertyAllocationIndexAHubVOs {
    return {
      type: AHubActions.PRODUCT_PROPERTY_ALLOCATION_INDEXS_SET,
      productPropertyAllocations: productPropertyAllocations
    };
  }

  /**
   * Create an action to add a new product property allocation to the aHub.
   */
  static productPropertyAllocationsAdd(productPropertyAllocationVO: ProductPropertyAllocationAHubVO[]): ActionProductPropertyAllocationsAHubVO {
    return {
      type: AHubActions.PRODUCT_PROPERTY_ALLOCATIONS_ADD,
      productPropertyAllocations: productPropertyAllocationVO
    }
  }

  /**
   * Create an action to delete product property allocation from the aHub.
   */
  static productPropertyAllocationDelete(productPropertyAllocationId: number): ActionNumber {
    return {
      type: AHubActions.PRODUCT_PROPERTY_ALLOCATION_DELETE,
      number: productPropertyAllocationId
    }
  }

  /**
   * Create an action to get the product property allocation chain indexes.
   */
  static productPropertyAllocationChainIndexsFetch(): ActionWork {
    return {
      type: AHubActions.PRODUCT_PROPERTY_ALLOCATION_CHAIN_INDEXS_FETCH
    }
  }

  /**
   * Create an action to set the product property allocation chain indexes.
   *
   * @param productPropertyAllocationChainIndexs
   */
  static productPropertyAllocationChainIndexsSet(productPropertyAllocationChainIndexs: ProductPropertyAllocChainIndexAHubVO[]): ActionProductPropertyAllocationChainIndexAHubVOs {
    return {
      type: AHubActions.PRODUCT_PROPERTY_ALLOCATION_CHAIN_INDEXS_SET,
      productPropertyAllocationChainIndexs: productPropertyAllocationChainIndexs
    }
  }

  /**
   * Create an action to get a product property allocation chain.
   *
   * @param chainIds           The id's of the chains we want.
   */
  static productPropertyAllocationChainsFetch(chainIds: number[]): ActionNumberArray {
    return {
      type: AHubActions.PRODUCT_PROPERTY_ALLOCATION_CHAINS_FETCH,
      numbers: chainIds
    }
  }

  /**
   * Create an action to set the product property allocation chain.
   *
   * @param productPropertyAllocationChainIndexs      The is the list to set in the store.
   */
  static productPropertyAllocationChainsSet(productPropertyAllocationChains: ProductPropertyAllocChainAHubVO[]): ActionProductPropertyAllocationChainAHubVOs {
    return {
      type: AHubActions.PRODUCT_PROPERTY_ALLOCATION_CHAINS_SET,
      productPropertyAllocationChains: productPropertyAllocationChains
    }
  }

  /**
   * Create an action to add a new product property allocation chain to the aHub.
   *
   * @param productPropertyAllocationChain            The allocation chain to add to the aHub.
   */
  static productPropertyAllocationChainAdd(productPropertyAllocationChain: ProductPropertyAllocChainAHubVO): ActionProductPropertyAllocationChainAHubVO {
    return {
      type: AHubActions.PRODUCT_PROPERTY_ALLOCATION_CHAIN_ADD,
      productPropertyAllocationChain: productPropertyAllocationChain
    };
  }

  /**
   * Create an action to delete an product property allocation chains by their id's from the store.
   */
  static productPropertyAllocationChainDelete(productPropertyAllocationChainId: number): ActionNumber {
    return {
      type: AHubActions.PRODUCT_PROPERTY_ALLOCATION_CHAIN_DELETE,
      number: productPropertyAllocationChainId
    };
  }

  /**
   * Create an action to remove an product property allocation chains by their id's from the store.
   */
  static productPropertyAllocationChainsRemove(productPropertyAllocationChainIds: number[]): ActionNumberArray {
    return {
      type: AHubActions.PRODUCT_PROPERTY_ALLOCATION_CHAINS_REMOVE,
      numbers: productPropertyAllocationChainIds
    };
  }

  /**
   * Create an action to commit product property allocation chain values.
   *
   * @param productPropertyAllocationChainValues              The values to commit.
   */
  static productPropertyAllocationChainValuesCommit(productPropertyAllocationChainValues: ProductPropertyAllocChainValueCommitAHubVO[]): ActionProductPropertyAllocationChainValueCommitAHubVOs {
    return {
      type: AHubActions.PRODUCT_PROPERTY_ALLOCATION_CHAIN_VALUES_COMMIT,
      productPropertyAllocChainValueCommitAHubVOs: productPropertyAllocationChainValues
    };
  }

  /**
   * Create an action to validate a product property allocation chain.
   *
   * @param productPropertyAllocationChainId          The id of the product property allocation chain to validate.
   */
  static productPropertyAllocationChainValidate(productPropertyAllocationChainId: number): ActionNumber {
    return {
      type: AHubActions.PRODUCT_PROPERTY_ALLOCATION_CHAIN_VALIDATE,
      number: productPropertyAllocationChainId
    };
  }


  /**
   * Create an action to rebuild the values for a product property allocation chain.
   *
   * @param productPropertyAllocationChainId          The id of the product property allocation chain to rebuild.
   */
  static productPropertyAllocationChainRebuild(productPropertyAllocationChainId: number): ActionNumber {
    return {
      type: AHubActions.PRODUCT_PROPERTY_ALLOCATION_CHAIN_REBUILD,
      number: productPropertyAllocationChainId
    };
  }

  /**
   * Create an action to apply all of the values in a product property allocation chain to the library.
   * This will apply to products missing the property.
   *
   * @param productPropertyAllocationChainId          The id of the product property allocation chain to apply.
   */
  static productPropertyAllocationChainAllValuesApply(productPropertyAllocationChainId: number): ActionNumber {
    return {
      type: AHubActions.PRODUCT_PROPERTY_ALLOCATION_CHAIN_ALL_VALUES_APPLY,
      number: productPropertyAllocationChainId
    };
  }


  /**
   * Create an action to apply all of the values in a product property allocation chain to the library.
   * This will apply to ALL products.
   *
   * @param productPropertyAllocationChainId          The id of the product property allocation chain to apply.
   */
  static productPropertyAllocationChainAllValuesApplyToAll(productPropertyAllocationChainId: number): ActionNumber {
    return {
      type: AHubActions.PRODUCT_PROPERTY_ALLOCATION_CHAIN_ALL_VALUES_APPLY_TO_ALL,
      number: productPropertyAllocationChainId
    };
  }

  /**
   * Create an action to get the chained values for a chain value id.
   *
   * @param productPropertyAllocationChainValueId          The id pf the chain value who's chained values we want.
   */
  static productPropertyAllocationChainValueChainedValuesFetch(productPropertyAllocationChainValueId: number): ActionNumber {
    return {
      type: AHubActions.PRODUCT_PROPERTY_ALLOCATION_CHAIN_VALUE_CHAINED_VALUES_FETCH,
      number: productPropertyAllocationChainValueId
    };
  }

  /**
   * Create an action to apply a product property allocation chain value.
   *
   * @param productPropertyAllocationChainValueId         The id of the product property allocation chain value to apply.
   */
  static productPropertyAllocationChainValueApply(productPropertyAllocationChainValueId: number): ActionNumber {
    return {
      type: AHubActions.PRODUCT_PROPERTY_ALLOCATION_CHAIN_VALUE_APPLY,
      number: productPropertyAllocationChainValueId
    };
  }

  /**
   * Create an action for the applying of a chain value to ALL products.
   *
   * @param productPropertyAllocationChainValueId         The id of the product property allocation chain value to apply.
   */
  static productPropertyAllocationChainValueApplyToAll(productPropertyAllocationChainValueId: number): ActionNumber {
    return {
      type: AHubActions.PRODUCT_PROPERTY_ALLOCATION_CHAIN_VALUE_APPLY_TO_ALL,
      number: productPropertyAllocationChainValueId
    };
  }

  /**
   * Create and return an action to fetch the extract definitions.
   */
  static extractDefinitionIndexesFetch(): ActionWork {
    return {
      type: AHubActions.EXTRACT_DEFINITION_INDEXES_FETCH
    };
  }

  /**
   * Create an action to set the extract definition indexes.
   *
   * @param extractDefinitionIndexes           The list of extract definitions to set.
   */
  static extractDefinitionIndexesSet(extractDefinitionIndexes: ExtractDefinitionIndexAHubVO[]): ActionExtractDefinitionIndexAHubVOs {
    return {
      type: AHubActions.EXTRACT_DEFINITION_INDEXES_SET,
      extractDefinitionIndexes: extractDefinitionIndexes
    };
  }


  /**
   * Create and return an action to fetch the extract index for the extract definition supplied.
   */
  static extractDefinitionExtractIndexesFetch(extractDefinitionId: number): ActionNumber {
    return {
      type: AHubActions.EXTRACT_DEFINITION_EXTRACT_INDEXES_FETCH,
      number: extractDefinitionId
    };
  }

  /**
   * Create an action to add a new extract definition.
   *
   * @param extractDefinition              The extract definition to add.
   */
  static extractDefinitionAdd(extractDefinition: ExtractDefinitionAHubVO): ActionExtractDefinitionAHubVO {
    return {
      type: AHubActions.EXTRACT_DEFINITION_ADD,
      extractDefinition: extractDefinition
    };
  }

  /**
   * Create an action to commit a new extract definition.
   *
   * @param extractDefinition              The extract definition to commit.
   */
  static extractDefinitionCommit(extractDefinition: ExtractDefinitionAHubVO): ActionExtractDefinitionAHubVO {
    return {
      type: AHubActions.EXTRACT_DEFINITION_COMMIT,
      extractDefinition: extractDefinition
    };
  }

  /**
   * Create an action to fetch a list of extract definitions by ids.
   *
   * @param extractDefinitionIds           The list of ids to get.
   */
  static extractDefinitionsByIdFetch(extractDefinitionIds: number[]): ActionNumberArray {
    return {
      type: AHubActions.EXTRACT_DEFINITIONS_FETCH,
      numbers: extractDefinitionIds
    };
  }

  /**
   * Create an action to remove a list of extract definitions by ids.
   *
   * @param extractDefinitionIds           The list of ids to get.
   */
  static extractDefinitionsRemove(extractDefinitionIds: number[]): ActionNumberArray {
    return {
      type: AHubActions.EXTRACT_DEFINITIONS_REMOVE,
      numbers: extractDefinitionIds
    };
  }

  /**
   * Create an action to remove an extract definitions by id.
   *
   * @param extractDefinitionId           The id of the extract definition.
   */
  static extractDefinitionDelete(extractDefinitionId: number): ActionNumber {
    return {
      type: AHubActions.EXTRACT_DEFINITION_DELETE,
      number: extractDefinitionId
    };
  }

  /**
   * Set the product property sections
   */
  static extractDefinitionsSet(extractDefinitions: ExtractDefinitionAHubVO[]): ActionExtractDefinitionAHubVOs {
    return {
      type: AHubActions.EXTRACT_DEFINITION_SET,
      extractDefinitions: extractDefinitions
    };
  }

  /**
   * Action to get all of the extract definitions.
   */
  static extractIndexesFetch(): ActionWork {
    return {
      type: AHubActions.EXTRACT_INDEXES_FETCH
    };
  }

  /**
   * Create an action to set the extract indexes.
   *
   * @param extractIndexes           The list of extract to set.
   */
  static extractIndexesSet(extractIndexes: ExtractIndexAHubVO[]): ActionExtractIndexAHubVOs {
    return {
      type: AHubActions.EXTRACT_INDEXES_SET,
      extractIndexes: extractIndexes
    };
  }

  /**
   * Create an action to fetch a list of extract by ids.
   *
   * @param extractIds           The list of ids to get.
   */
  static extractsByIdFetch(extractIds: number[]): ActionNumberArray {
    return {
      type: AHubActions.EXTRACTS_FETCH,
      numbers: extractIds
    };
  }

  /**
   * Set the product property sections
   */
  static extractsSet(extracts: ExtractAHubVO[]): ActionExtractAHubVOs {
    return {
      type: AHubActions.EXTRACTS_SET,
      extracts: extracts
    };
  }

  /**
   * Create an action to add a new extract.
   *
   * @param extract              The extract to add.
   */
  static extractAdd(extract: ExtractAHubVO): ActionExtractAHubVO {
    return {
      type: AHubActions.EXTRACT_ADD,
      extract: extract
    };
  }

  /**
   * Create an action to commit a new extract.
   *
   * @param extract              The extract to commit.
   */
  static extractCommit(extract: ExtractAHubVO): ActionExtractAHubVO {
    return {
      type: AHubActions.EXTRACT_COMMIT,
      extract: extract
    };
  }

  /**
   * Create an action to commit a extract default value rules.
   *
   * @param defaultValueRules              The extract to commit.
   */
  static extractDefaultValueRulesCommit(defaultValueRules: ExtractDefaultValueRulesAHubVO, extractId: number): ActionExtractDefaultValueRulesAHubVO {
    return {
      type: AHubActions.EXTRACT_DEFAULT_VALUE_RULES_COMMIT,
      defaultValueRules: defaultValueRules,
      extractId: extractId
    };
  }

  /**
   * Create an action to remove a list of extracts by ids.
   *
   * @param extractIds           The list of ids to get.
   */
  static extractsRemove(extractIds: number[]): ActionNumberArray {
    return {
      type: AHubActions.EXTRACTS_REMOVE,
      numbers: extractIds
    };
  }

  /**
   * Create an action to remove an extract by is.
   *
   * @param extractId           The id of the extract.
   */
  static extractDelete(extractId: number): ActionNumber {
    return {
      type: AHubActions.EXTRACT_DELETE,
      number: extractId
    };
  }

  /**
   * Create an action to commit a set of extract filters.
   *
   * @param extractId                The id of the extract who's filters we want to commit.
   * @param productPropertyFilter    The filter to commit.
   */
  static extractCommitProductPropertyFilter(extractId: number, productPropertyFilter: ProductPropertyFilterAHubVO): ActionProductPropertyFilterAHubVONumber {
    return {
      type: AHubActions.EXTRACT_COMMIT_PRODUCT_PROPERTY_FILTER,
      productPropertyFilter: productPropertyFilter,
      number: extractId
    };
  }

  /**
   * Create an action to fetch conflicting extracts
   *
   * @param extractId                The id of the extract who we want the conflicts for
   */
  static extractConflictsFetch(extractId: number): ActionNumber {
    return {
      type: AHubActions.EXTRACTS_CONFLICTING_FETCH,
      number: extractId
    };
  }

  /**
   * Create an action to change the state of an extract to work in progress.
   *
   * @param extractId             The id of the extract we're working with.
   * @param userId                The id of the user to assign too.
   * @param comments              The comments that go with the change of state.
   */
  static extractStateWIP(extractId: number, userId: number, comments: string): ActionNumberNumberString {
    return {
      type: AHubActions.EXTRACT_STATE_WIP,
      number1: extractId,
      number2: userId,
      string: comments
    }
  }

  /**
   * Create an action to change the state of an extract to complete.
   *
   * @param extractId             The id of the extract we're working with.
   * @param comments              The comments that go with the change of state.
   */
  static extractStateComplete(extractId: number, comments: string): ActionStringNumber {
    return {
      type: AHubActions.EXTRACT_STATE_COMPLETE,
      number: extractId,
      string: comments
    };
  }

  /**
   * Create an action to change the state of an extract to archive.
   *
   * @param extractId             The id of the extract we're working with.
   * @param comments              The comments that go with the change of state.
   */
  static extractStateArchive(extractId: number, comments: string): ActionStringNumber {
    return {
      type: AHubActions.EXTRACT_STATE_ARCHIVE,
      number: extractId,
      string: comments
    };
  }

  /**
   * Create an action to retrieve the pagination page fetch
   *
   * @param extractId             The id of the extract we're working with.
   */
  static extractProductPropertyCompletenessPaginationFetch(extractId: number): ActionNumber {
    return {
      type: AHubActions.EXTRACT_PRODUCT_PROPERTY_COMPLETENESS_PAGINATION_PAGE_FETCH,
      number: extractId
    };
  }

  /**
   * Create an action to set the pagination page with it's extract id
   *
   * @param extractId             The id of the extract we're working with.
   */
  static extractProductPropertyCompletenessPaginationSet(extractId: number, paginationPage: PaginationIdBreakdownVO): ActionExtractProductPaginationPage {
    return {
      type: AHubActions.EXTRACT_PRODUCT_PROPERTY_COMPLETENESS_PAGINATION_PAGE_SET,
      extractId: extractId,
      productPaginationPage: paginationPage
    };
  }

  /**
   * Create an action to commit a set of extract document settings.
   * 
   * @param extractId                   The id of the extract that has the settings.
   * @param extractDocumentSettings     The extract document settings to commit.
   */
  static extractDocumentSettingsCommit(extractId: number, extractDocumentSettings: ExtractDocumentSettings[]): ActionExtractDocumentSettings {
    return {
      type: AHubActions.EXTRACT_DOCUMENT_SETTINGS_COMMIT,
      extractId: extractId,
      extractDocumentSettings: extractDocumentSettings
    }
  }

  /**
   * Create an action to retrieve the current state of product information completeness
   *
   * @param extractId             The id of the extract we're working with.
   */
  static extractProductPropertyCompletenessFetch(extractId: number, paginationPage: PaginationIdBreakdownVO): ActionExtractProductPaginationPage {
    return {
      type: AHubActions.EXTRACT_PRODUCT_PROPERTY_COMPLETENESS_FETCH,
      extractId: extractId,
      productPaginationPage: paginationPage
    };
  }

  /**
   * Create an action to retrieve the current state of product information completeness
   *
   * @param extractId             The id of the extract we're working with.
   */
  static extractProductPropertyCompletenessSet(extractId: number, productCompleteness: ProductPropertyCompletenessAHubVO): ActionProductPropertyCompletenessAHubVO {
    return {
      type: AHubActions.EXTRACT_PRODUCT_PROPERTY_COMPLETENESS_SET,
      number: extractId,
      productPropertyCompleteness: productCompleteness
    };
  }

  /**
   * Create an action to clear the current state of product information completeness
   *
   * @param extractId             The id of the extract we're working with.
   */
  static extractProductPropertyCompletenessDelete(extractId: number): ActionNumber {
    return {
      type: AHubActions.EXTRACT_PRODUCT_PROPERTY_COMPLETENESS_DELETE,
      number: extractId
    };
  }

  /**
   * Create an action to fetch the extract history indexes by the extract id
   *
   * @param extractId
   */
  static extractHistoryIndexFetch(extractId: number): ActionNumber {
    return {
      type: AHubActions.EXTRACT_HISTORY_INDEXES_FETCH,
      number: extractId
    }
  }

  /**
   * Create an action to set the extract history indexes
   *
   * @param extractId
   */
  static extractHistoryIndexSet(extractHistoryIndexs: ExtractHistoryIndexAHubVO[]): ActionExtractHistoryIndexAHubVOs {
    return {
      type: AHubActions.EXTRACT_HISTORY_INDEXES_SET,
      extractHistoryIndexes: extractHistoryIndexs
    }
  }

  /**
   * Create an action to download the extract history product source
   *
   * @param extractId             The extract id for the item
   * @param extractHistoryId      The extract history id
   */
  static extractHistoryProductSourceDownload(extractId: number, extractHistoryId: number): ActionNumberNumber {
    return {
      type: AHubActions.EXTRACT_HISTORY_PRODUCT_SOURCE_DOWNLOAD,
      number1: extractId,
      number2: extractHistoryId
    }
  }

  /**
   * Create an action to fetch a list of products by extract id.
   *
   * @param extractIds           The extract to get the products for.
   */
  static extractProductsFetch(extractId: number): ActionNumber {
    return {
      type: AHubActions.EXTRACT_PRODUCTS_FETCH,
      number: extractId
    };
  }

  /**
   *  Gets product data by ids for a given extract.
   */
  static extractProductsFetchByIds(extractId: number, productIds: number[]): ActionNumberNumbersArray {
    return {
      type: AHubActions.EXTRACT_PRODUCTS_FETCH_BY_IDS,
      number: extractId,
      numbers: productIds
    };
  }

  /**
   * Remove the extract products by id
   */
  static extractProductsRemoveByIds(extractId: number, productIds: number[]): ActionNumberNumbersArray {
    return {
      type: AHubActions.EXTRACT_PRODUCTS_REMOVE,
      number: extractId,
      numbers: productIds
    };
  }

  /**
   * Create an action to remove extract products from the local store which are older then the time specified
   *
   * @param extractId         The id of the extract which we want to remove products from
   * @param time              The time which we want to remove products which are older than
   */
  static extractProductsRemoveBeforeTime(extractId: number, time: number): ActionNumberNumber {
    return {
      type: AHubActions.EXTRACT_PRODUCTS_REMOVE_BEFORE_TIME,
      number1: extractId,
      number2: time
    }
  }

  /**
   * Create an action to clear the extract products from the store.
   *
   */
  static extractProductsClear(extractId: number): ActionNumber {

    // Construct the action.
    return {
      type: AHubActions.EXTRACT_PRODUCTS_CLEAR,
      number: extractId
    };
  }

  /**
   * Set the extract products
   */
  static extractProductsUpdateSet(payload: ActionPayloadLibraryNumberProductsNumberArray): ActionWorkPayload<ActionPayloadLibraryNumberProductsNumberArray> {
    return {
      type: AHubActions.EXTRACT_PRODUCTS_UPDATE_SET,
      payload: payload
    };
  }

  /**
  * Create an action to start the creation of a products extract document.
  *
  * @param extract              The extract to create from.
  * @param documentType         The type of document to create.
  */
  static extractProductDocumentCreate(extractId: number, documentType: DocumentProductFileTypeAHubEnum): ActionExtractDocumentProductFileTypeAHubVO {
    return {
      type: AHubActions.EXTRACT_PRODUCT_DOCUMENT_CREATE,
      extractId: extractId,
      documentType: documentType
    };
  }

  /**
  * Create an action to commit extract products objects without assets.
  *
  * @param extract              The extract to commit.
  */
  static extractProductsCommitObjectsOnly(extractId: number, extractProducts: ProductAHubVO[]): ActionExtractProductAHubVOs {
    return {
      type: AHubActions.EXTRACT_PRODUCTS_COMMIT_OBJECTS_ONLY,
      extractId: extractId,
      extractProducts: extractProducts
    };
  }

  /**
  * Create an action to commit extract products objects and assets.
  *
  * @param extract              The extract to commit.
  */
  static extractProductsCommitObjectsAndAssets(extractId: number, extractProducts: ProductAHubVO[], assetsUpload: ExtractProductAssetUploadAHubVO[], imageAssetTrimThreshold: number): ActionExtractProductAndAssetsUploadAHubVOs {
    return {
      type: AHubActions.EXTRACT_PRODUCTS_COMMIT_OBJECTS_AND_ASSETS,
      extractId: extractId,
      extractProducts: extractProducts,
      extractAssetUpload: assetsUpload,
      imageAssetTrimThreshold
    };
  }

  /**
  * Create an action to commit extract products objects and assets.
  *
  * @param extract              The extract to commit.
  */
  static extractProductsBulkUpload(extractId: number, productsUpload: RequestActionStatusUploadVO): ActionExtractProductBulkUploadAHubVOs {
    return {
      type: AHubActions.EXTRACT_PRODUCTS_BULK_UPLOAD,
      extractId: extractId,
      extractProductsUpload: productsUpload
    };
  }

  /**
   * Create an action to delete extract products.
   */
  static extractProductsDelete(extractId: number, productIds: number[]): ActionNumberNumbersArray {
    return {
      type: AHubActions.EXTRACT_PRODUCTS_DELETE,
      number: extractId,
      numbers: productIds
    }
  }

  /**
   * Create an action to match a list of files to the given extract
   *
   * @param extractId     The extract which we want to match the files too
   * @param files         Array of the file names which we want to match
   */
  static extractProductAssetMatch(extractId: number, files: string[]): ActionNumberStringArray {
    return {
      type: AHubActions.EXTRACT_PRODUCT_ASSET_MATCH,
      number: extractId,
      strings: files
    };
  }

  /**
   * Create an action to match a list of files to the given extract
   *
   * @param extractId     The extract which we want to match the files too
   * @param files         Array of the file names which we want to match
   */
  static extractProductAssetMatchSet(extractId: number, matchedFiles: ExtractAssetMatchAHubVO[]): ActionExtractAssetMatchArray {
    return {
      type: AHubActions.EXTRACT_PRODUCT_ASSET_MATCH_SET,
      number: extractId,
      matches: matchedFiles
    };
  }


  /**
   * Create an action to generate a zip for a specific product asset
   *
   * @param extractId         Id of the extract we want the asset from
   * @param assetId           Id of the asset we want to zip
   * @param assetLocation     Location of the asset we want!
   */
  static extractProductAssetZipGenerate(extractId: number, assetId: number, assetLocation: string): ActionNumberNumberString {
    return {
      type: AHubActions.EXTRACT_PRODUCT_ASSET_ZIP_GENERATE,
      number1: extractId,
      number2: assetId,
      string: assetLocation
    }
  }


  /**
  * Create an action to delete extract products asset.
  *
  */
  static extractProductSectionPropertyAssetDelete(extractId: number, productId: number, sectionId: number, propertyId: number): ActionExtractProductSectionPropertyAsset {
    return {
      type: AHubActions.EXTRACT_PRODUCT_SECTION_PROPERTY_ASSET_DELETE,
      extractId: extractId,
      productId: productId,
      sectionId: sectionId,
      propertyId: propertyId,
    };
  }

  /**
* Create an action to fetch product asset urls by extract id and product id.
*
* @param extractId           The list of ids to get.
* @param productIds           The list of ids to get.
*/
  static extractProductAssetUrlsFetch(extractId: number, productIds: number[]): ActionNumberNumbersArray {
    return {
      type: AHubActions.EXTRACT_PRODUCT_ASSET_URLS_FETCH,
      number: extractId,
      numbers: productIds
    };
  }


  /**
   * Set the extract products assets
   */
  static extractProductsAssetsSet(extractProductsAssets: ProductAssetAHubVO[]): ActionProductAssetAHubVOs {
    return {
      type: AHubActions.EXTRACT_PRODUCTS_ASSETS_SET,
      productAssets: extractProductsAssets
    };
  }


  /**
   * Create an action to get all of the resource pack indexes.
   */
  static resourcePackIndexesFetch(): ActionWork {
    return {
      type: AHubActions.RESOURCE_PACK_INDEXES_FETCH
    };
  }


  /**
   * Create an action to set the resource pack indexes.
   *
   * @param resourcePackIndexes               The resource pack indexes to set too.
   */
  static resourcePackIndexesSet(resourcePackIndexes: ResourcePackIndexAHubVO[]): ActionResourcePackIndexesAHubVO {
    return {
      type: AHubActions.RESOURCE_PACK_INDEXES_SET,
      resourcePackIndexes: resourcePackIndexes
    };
  }


  /**
   * Create an action to get a list of resource packs by id's.
   *
   * @param resourcePackIds           The id's of the resource packs to get.
   */
  static resourcePacksByIdFetch(resourcePackIds: number[]): ActionNumberArray {
    return {
      type: AHubActions.RESOURCE_PACKS_FETCH,
      numbers: resourcePackIds
    };
  }


  /**
   * Create an action to set the resource packs in the model.
   *
   * @param resourcePacks             The resource packs to set set too.
   */
  static resourcePacksSet(resourcePacks: ResourcePackAHubVO[]): ActionResourcePackAHubVOs {
    return {
      type: AHubActions.RESOURCE_PACKS_SET,
      resourcePacks: resourcePacks
    };
  }


  /**
   * Create an action to add a new resource pack to the aHub.
   *
   * @param resourcePack              The resource pack to add to the aHub.
   */
  static resourcePackAdd(resourcePack: ResourcePackAHubVO): ActionResourcePackAHubVO {
    return {
      type: AHubActions.RESOURCE_PACK_ADD,
      resourcePack: resourcePack
    };
  }


  /**
   * Create an action to commit changes to a resource pack up to the aHub.
   *
   * @param resourcePack              The resource pack that has changed.
   */
  static resourcePackCommit(resourcePack: ResourcePackAHubVO): ActionResourcePackAHubVO {
    return {
      type: AHubActions.RESOURCE_PACK_COMMIT,
      resourcePack: resourcePack
    };
  }


  /**
   * Create an action to delete a resource pack from the aHub.
   *
   * @param resourcePackId              The id of the resource pack to delete.
   */
  static resourcePackDelete(resourcePackId: number): ActionNumber {
    return {
      type: AHubActions.RESOURCE_PACK_DELETE,
      number: resourcePackId
    };
  }


  /**
   * Create an action to remove resource packs from the local store.
   *
   * @param resourcePackIds               The id's of the resource packs to remove.
   */
  static resourcePacksRemove(resourcePackIds: number[]): ActionNumberArray {
    return {
      type: AHubActions.RESOURCE_PACKS_REMOVE,
      numbers: resourcePackIds
    };
  }

  /**
   * Upload the files to the resource pack specified
   *
   * @param resourcePackId        Id of the resource pack we are uploading too
   * @param pathPrefix            The is is the prefix for all the files within the item
   * @param objectStoreIds        The id's of the files in the store which we want to upload
   */
  static resourcePackFilesUpload(resourcePackId: number, pathPrefix: string, objectStoreIds: string[]): ActionNumberStringStringArray {
    return {
      type: AHubActions.RESOURCE_PACK_FILES_UPLOAD,
      number: resourcePackId,
      string: pathPrefix,
      strings: objectStoreIds
    };
  }

  /**
   * Delete the files to the resource pack specified
   *
   * @param resourcePackId        Id of the resource pack we are uploading too
   * @param paths                 The paths of the objects which we want to delete
   */
  static resourcePackFilesDelete(resourcePackId: number, paths: string[]): ActionNumberStringArray {
    return {
      type: AHubActions.RESOURCE_PACK_FILES_DELETE,
      number: resourcePackId,
      strings: paths
    };
  }

  /**
   * Add new folder to the resource pack specified
   *
   * @param resourcePackId        Id of the resource pack we are uploading too
   * @param pathToNewFolder       The path of the new folder we want to add
   */
  static resourcePackFolderAdd(resourcePackId: number, pathToNewFolder: string): ActionStringNumber {
    return {
      type: AHubActions.RESOURCE_PACK_FOLDER_ADD,
      string: pathToNewFolder,
      number: resourcePackId
    };
  }

  /**
   * Rename folder in the resource pack specified
   *
   * @param resourcePackId        Id of the resource pack we are uploading too
   * @param folderPath          The path of folder we would like to rename
   * @param newFolderPath       renamed folder path
   */
  static resourcePackFolderRename(resourcePackId: number, folderPath: string, newFolderPath: string): ActionNumberStringString {
    return {
      type: AHubActions.RESOURCE_PACK_FOLDER_RENAME,
      string1: folderPath,
      string2: newFolderPath,
      number: resourcePackId
    };
  }


  /**
   * Generates and downloads a zip file of the resource pack files within the resource pack directory
   *
   * @param resourcePackId    Id of the resource pack we are uploading too
   * @param resourcePackDir   The path of the directory which we want to zip and download
   */
  static resourcePackFilesZipDownload(resourcePackId: number, resourcePackDir: string): ActionStringNumber {
    return {
      type: AHubActions.RESOURCE_PACK_FILES_ZIP_DOWNLOAD,
      number: resourcePackId,
      string: resourcePackDir
    };
  }

  /**
   * Generates and downloads a file from the resource pack
   *
   * @param resourcePackId    Id of the resource pack we are downloading from
   * @param resourcePackFileLocation   The path of the file which we want to download
   */
  static resourcePackFileDownload(resourcePackId: number, resourcePackFileLocation: string): ActionStringNumber {
    return {
      type: AHubActions.RESOURCE_PACK_FILE_DOWNLOAD,
      number: resourcePackId,
      string: resourcePackFileLocation
    };
  }

  /**
   * Make a request to get the client log for specified id.
   * 
   * @param clientLogId        The id of the client log we want to get.
   * @param 
   */
  static clientLogFetch(clientLogId: number, offset: number): ActionNumberNumber {
    return {
      type: AHubActions.CLIENT_LOG_FETCH,
      number1: clientLogId,
      number2: offset
    };
  }

  /**
   * Creates an action to store a list of client logs.
   * 
   * @param clientLogs        The client logs to store.
   */
  static clientLogSet(clientLog: ClientLogAHubVO): ActionClientLogAHubVO {
    return {
      type: AHubActions.CLIENT_LOG_SET,
      clientLog
    };
  }

  /**
   * Create an action to replace a client log.
   * 
   * @param clientLog         The client log to replace.
   */
  static clientLogReplace(clientLog: ClientLogAHubVO): ActionClientLogAHubVO {
    return {
      type: AHubActions.CLIENT_LOG_REPLACE,
      clientLog
    };
  }


  /**
   * Create an action to get the client log entries for a client log.
   * 
   * @param clientLog         The client log to get the entries from.
   */
  static clientLogEntriesFetch(clientLog: ClientLogAHubVO): ActionClientLogAHubVO {
    return {
      type: AHubActions.CLIENT_LOG_ENTRIES_FETCH,
      clientLog
    };
  }

  //--------------------------------------------------------
  //System Actions
  //--------------------------------------------------------

  /**
   * Turn on system maintance mode
   */
  static maintanceModeOn(): ActionWork {
    return { type: AHubActions.SYSTEM_MAINTANENCE_MODE_ON };
  }

  /**
   * Turn off system maintance mode
   */
  static maintanceModeOff(): ActionWork {
    return { type: AHubActions.SYSTEM_MAINTANENCE_MODE_OFF };
  }

  /**
   * Fetch the system status in the system
   */
  static systemStatusFetch(): ActionWork {
    return {
      type: AHubActions.SYSTEM_STATUS_FETCH
    }
  }

  /**
   * Set the System status into the system
   */
  static systemStatusSet(systemStatus: SystemStatusAHubVO[]): ActionSystemStatusArrayAHubVO {
    return {
      type: AHubActions.SYSTEM_STATUS_SET,
      systemStatus: systemStatus
    }
  }
};


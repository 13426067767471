
import { Component, ComponentFactory, ComponentFactoryResolver, Input, OnDestroy, OnInit } from '@angular/core';
/**
 * Services.
 */
import { DialogService } from 'modules/common/dialogs/dialog.service';
import { componentDestroyStream, Hark } from 'modules/common/hark.decorator';
import { Utils } from 'modules/common/utils';
import { ProductPropertyFilterOptionData } from 'modules/common/vo-render/product-property-filter/product-property-filter-option-data.vo';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
/**
 * Value objects.
 */
import { ProductPropertyFilterAHubVO } from 'valueObjects/ahub/library/product-property-filter.ahub.vo';
import { PropertyRuleAHubVO } from 'valueObjects/ahub/library/property-rule.ahub.vo';
/**
 * Components.
 */
import { PropertyTestDialogComponent, PropertyTestDialogVO } from './property-test-dialog/property-test-dialog.component';
import { PropertyTestByRuleIndexVO } from './property-rule/property-rule.component';

@Component({
  selector: 'app-product-property-filter',
  templateUrl: './product-property-filter.component.html',
  styleUrls: ['./product-property-filter.component.css']
})
@Hark()
export class ProductPropertyFilterComponent implements OnInit, OnDestroy {

  /**
   * This is the function that is called when the user updates the property filter.
   */
  @Input() propertyFilterUpdateFunction: Function;
  /**
   * The list of property section objects we can display.
   */
  @Input()
  productPropertyFilterOptions: ProductPropertyFilterOptionData[] = [];

  /**
   * The product filter we need to work with.
   */
  @Input()
  productPropertyFilterToDisplay: ProductPropertyFilterAHubVO = { propertyRules: [] };

  /**
   * Are we allowing the adding of property rules?
   */
  @Input()
  allowPropertyRuleToBeAdded: boolean;

  /**
   * Are we allowing property tests to be added?
   */
  @Input()
  allowPropertyTestsToBeAdded: boolean;

  /**
   * Stream which shows if the delete is busy.
   */
  @Input()
  propertyFilterUpdateBusy$: Observable<boolean> = undefined;


  constructor(
    private dialogueService: DialogService,
    private resolver: ComponentFactoryResolver) { }

  /**
   * Empty On Init to ensure @Hark decorator works for an AOT build
   */
  ngOnInit() {
    // This is intentional
  }

  /**
   * Empty On destroy to ensure @Hark decorator works for an AOT build
   */
  ngOnDestroy() { }

  /**
   * This function is called when the user clicks on the add property rule button.
   * This is the first level of interaction, you then add tests to the rule.
   */
  addPropertyRule() {

    // Make sure we have a set of product filters to display. If not, set some up.
    if (!this.productPropertyFilterToDisplay || !this.productPropertyFilterToDisplay.propertyRules) {
      this.productPropertyFilterToDisplay = { propertyRules: [] };
    }

    // Add the new property rules test (to the last element).
    this.addPropertyTest(this.productPropertyFilterToDisplay.propertyRules.length, true);
  }


  /**
   * This function is called when we want to create a new property test.
   */
  addPropertyTest(propertyRuleIndex, addingOr) {

    // Get the copmponent Factory we will use to create the component dialog
    // we will use to create a new export entry.
    let componentFactory: ComponentFactory<PropertyTestDialogComponent> = this.resolver.resolveComponentFactory(PropertyTestDialogComponent);

    // Create a new client.
    let dialogVO: PropertyTestDialogVO = {
      propertySectionObjects: this.productPropertyFilterOptions
    }

    // Open the custom component dialogue , using the component factory to create the component content, passing in a blank VO.
    let newDialogue = this.dialogueService.componentDialogOpen("Add property filter rule.", componentFactory, "dialogVO", dialogVO, "dialogIsValid$", "Add", "Cancel");

    //Subscribe to the new export dialogue, if we recive an export then we will add it
    newDialogue.subscribe((dialogVO) => {

      //If the new VO is undefined then we will bail out
      if (!dialogVO || dialogVO.propertyTest == undefined) {
        return;
      }



      // Make a copy of the filter that comes in and make amendments to that prior to sending to aHub
      // This way we only display what really comes back from aHub and not what adjustments (adds and deletes)
      // might have been made locally
      let productPropertyFilterToEdit: ProductPropertyFilterAHubVO = this.productPropertyFilterCloneGet();

      // Are we adding an or test?
      if (addingOr) {

        // Yes, so add a new or level property test.
        productPropertyFilterToEdit.propertyRules.push({ propertyTests: [] });
      }

      // Now Put the property test into the correct spot.
      productPropertyFilterToEdit.propertyRules[propertyRuleIndex].propertyTests.push(dialogVO.propertyTest);

      // Call the property test added function.
      if (this.propertyFilterUpdateFunction)
        this.propertyFilterUpdateFunction(productPropertyFilterToEdit);
    });

  }

  editPropertyTest(propertyTestByRuleIndex: PropertyTestByRuleIndexVO) {
    // Get the copmponent Factory we will use to create the component dialog
    // we will use to create a new export entry.
    let componentFactory: ComponentFactory<PropertyTestDialogComponent> = this.resolver.resolveComponentFactory(PropertyTestDialogComponent);

    // Create a new client.
    let dialogVO: PropertyTestDialogVO = {
      propertySectionObjects: this.productPropertyFilterOptions,
      propertyTest: propertyTestByRuleIndex.propertyTest
    }

    // Open the custom component dialogue , using the component factory to create the component content, passing in a blank VO.
    let newDialogue = this.dialogueService.componentDialogOpen("Edit Property Test.", componentFactory, "dialogVO", dialogVO, "dialogIsValid$", "Save", "Cancel");

    //Subscribe to the new export dialogue, if we recive an export then we will add it
    newDialogue.subscribe((dialogVO) => {

      //If the new VO is undefined then we will bail out
      if (!dialogVO || dialogVO.propertyTest == undefined) {
        return;
      }

      const filterToBeUpdated: ProductPropertyFilterAHubVO = this.productPropertyFilterCloneGet();

      filterToBeUpdated.propertyRules[propertyTestByRuleIndex.propertyRuleIndex].propertyTests.splice(propertyTestByRuleIndex.propertyTestIndex, 1, dialogVO.propertyTest);

      if (this.propertyFilterUpdateFunction) {
        this.propertyFilterUpdateFunction(filterToBeUpdated);
      }
    })
  }


  /**
   * This handler is called when the user clicks on the delete property test function.
   *
   * @param propertyTestByRuleIndex         The test to delete by rule and test index.
   */
  deletePropertyTest(propertyTestByRuleIndex: PropertyTestByRuleIndexVO) {
    // Make a copy of the filter that comes in and make amendments to that prior to sending to aHub
    // This way we only display what really comes back from aHub and not what adjustments (adds and deletes)
    // might have been made locally
    let productPropertyFilterToEdit: ProductPropertyFilterAHubVO = this.productPropertyFilterCloneGet();
    productPropertyFilterToEdit.propertyRules[propertyTestByRuleIndex.propertyRuleIndex].propertyTests.splice(propertyTestByRuleIndex.propertyTestIndex, 1);

    // Open a dialogue to check they want to delete the property.
    let dialogRef: Observable<boolean> = this.dialogueService.confirmDialogOpen("Delete filter?", "Are you sure you want to delete this filter?");

    // Subscribe to the result of the dialogue box.
    dialogRef.pipe(
      takeUntil(componentDestroyStream(this)))
      .subscribe(result => {

        // Was the delete confirmed?
        if (result === true) {

          // Call the property test deleted function.
          if (this.propertyFilterUpdateFunction)
            this.propertyFilterUpdateFunction(productPropertyFilterToEdit);
        }
      });
  }

  /**
   * This function is called when the user clicks on the delete all button.
   *
   * @param propertyRuleIndex       The index of the property rule to delete.
   */
  deleteTestAllRules(propertyRuleIndex: number) {

    // Get the test to delete.
    let testToDelete: PropertyRuleAHubVO = this.productPropertyFilterToDisplay.propertyRules[propertyRuleIndex];

    // Is the property rule empty?
    if (testToDelete.propertyTests.length == 0) {

      // Yes, so remove it from the display list.
      this.productPropertyFilterToDisplay.propertyRules.splice(propertyRuleIndex, 1);

      // Then stop here.
      return;
    }


    // Make a copy of the filter that comes in and make amendments to that prior to sending to aHub
    // This way we only display what really comes back from aHub and not what adjustments (adds and deletes)
    // might have been made locally
    let productPropertyFilterToEdit = this.productPropertyFilterCloneGet();
    productPropertyFilterToEdit.propertyRules.splice(propertyRuleIndex, 1);

    // Open a dialogue to check they want to delete the property.
    let dialogRef: Observable<boolean> = this.dialogueService.confirmDialogOpen("Delete all filters?", "Are you sure you want to delete all filters?");

    // Subscribe to the result of the dialogue box.
    dialogRef.pipe(
      takeUntil(componentDestroyStream(this)))
      .subscribe(result => {

        // Was the delete confirmed?
        if (result === true) {

          // Call the property test deleted function.
          if (this.propertyFilterUpdateFunction)
            this.propertyFilterUpdateFunction(productPropertyFilterToEdit);
        }
      });
  }


  /**
   * Get a clone of the product filters.
   */
  private productPropertyFilterCloneGet(): ProductPropertyFilterAHubVO {
    return Utils.clone(this.productPropertyFilterToDisplay);
  }
}

import { animate, style, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutingNavigation } from 'app/app-routing-navigation';
import { AHubActions } from 'app/store/actions/ahub.actions';
import { AppActions } from 'app/store/actions/app.actions';
import { aHubStatePermanentUserHasAViews } from 'app/store/selector/ahub/ahub-permanent.selector';
import { currentToolId } from 'app/store/selector/app.selector';
import { selectedRouteView } from 'app/store/selector/view/view-routes.selector';
import { ViewAvailableRoutesStream } from 'app/store/stream/view-available-routes.stream';
import { RouteViewVO } from 'app/valueObjects/view/route.view.vo';
import { ToolIDEnum } from 'app/valueObjects/view/tool-id.view.enum';
import { version } from 'assets/jenkins_consts';
import { environment } from 'environments/environment';
import { componentDestroyStream, Hark } from 'modules/common/hark.decorator';
import { Observable, timer } from 'rxjs';
import { delay, map, takeUntil } from 'rxjs/operators';
import { StoreAccess } from 'store/store-access';
import { DialogService } from '../common/dialogs/dialog.service';
import { Utils } from '../common/utils';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  animations: [
    trigger('newVersionAnimation', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)' }),
        animate('500ms ease-in', style({ transform: 'translateY(0%)' }))
      ]),
      transition(':leave', [
        animate('500ms ease-in', style({ transform: 'translateY(-100%)' }))
      ])
    ])
  ]
})
@Hark()
export class SidenavComponent implements OnInit, OnDestroy {

  showNewVersionTab = false;

  /**
   * Get an observable list of the routes
   */
  routes$ = ViewAvailableRoutesStream.availableRoutes().pipe(delay(0));

  /**
   * Get the current tool id.
   */
  currentToolId$: Observable<ToolIDEnum> = StoreAccess.dataGetObvs(currentToolId).pipe(delay(0));

  /**
   * The number of milliseconds between each call to check if the user has aViews or not.
   */
  private readonly USER_HAS_AVIEW_TIMEOUT = 10 * 60 * 1000;

  /**
   * Is the current tool the aHub?
   */
  currentToolIsAHub$: Observable<boolean> = this.currentToolId$
    .pipe(
      map(currentToolId => currentToolId === ToolIDEnum.AHUB)
    );

  /**
   * Is the current tool the aView?
   */
  currentToolIsAView$: Observable<boolean> = this.currentToolId$
    .pipe(
      map(currentToolId => currentToolId === ToolIDEnum.AVIEW)
    );


  /**
   * Does the user have access to aView publications?
   */
  userHasAViewPublications$: Observable<boolean> = StoreAccess.dataGetObvs(aHubStatePermanentUserHasAViews).pipe(delay(0));


  routeViewSelected$: Observable<RouteViewVO> = StoreAccess.dataGetObvs(selectedRouteView);

  titlePath = '';

  title = '';

  knowledgeBasePagePath: string;
  showKowledgeBaseIcon: boolean;

  version: string = version;
  newVersionNumber: string;

  constructor(private readonly http: HttpClient, private readonly router: Router,
    private readonly dialogueService: DialogService,) { }

  // Navigation Toggle
  active = false;
  toggleNav() {
    this.active = !this.active;
  }

  closeNav() {
    this.active = false;
  }

  ngOnInit() {

    // Set up a timer that runs straight away and then every x milliseconds to
    // see if the user is attached to any aViews.
    timer(0, this.USER_HAS_AVIEW_TIMEOUT)
      .pipe(
        takeUntil(componentDestroyStream(this))
      )
      .subscribe(timer => {

        // Make the call to update whether the user has aViews or not.
        StoreAccess.dispatch(AHubActions.userHasAViewsFetch(), true);
      });



    //Are we running on something other than a LOCAL deployment?
    if (environment && environment.name != 'LOCAL') {

      //If so we want to check to see if there is a new version avalible to download
      setInterval(() => {
        //Get the version from our relative location
        this.http.get('version.txt', {
          responseType: 'text'
        }).pipe(
          takeUntil(componentDestroyStream(this)))
          .subscribe(response => {

            //Grab the version
            this.newVersionNumber = response.valueOf().toString().trim();
            if (/^\d+\.\d+\.\d+$/.test(this.newVersionNumber) || /Revision: \d+/.test(this.newVersionNumber)) {
              //If the version is different to the new version number then we should display it
              this.showNewVersionTab = (version.toString().trim() != this.newVersionNumber);
            }

          }, error => {
            console.log('error: ', error);
          });
      }, 120000)
    }

    // Lets build a page title from the selected route view VO
    this.routeViewSelected$.pipe(
      Utils.isNotNullOrUndefined(),
      takeUntil(componentDestroyStream(this)))
      .subscribe(routerViewSelected => {
        // Clear previous values prior to figuring out where we are now
        this.title = '';
        this.titlePath = '';
        this.knowledgeBasePagePath = routerViewSelected.knowledgeBasePagePath
        this.showKowledgeBaseIcon = routerViewSelected.showKowledgeBaseIcon

        // Get the page title from the current routeView
        const pageTitleArray = routerViewSelected.pageTitle.split('>');
        this.title = pageTitleArray.pop();
        if (pageTitleArray.join('/') !== '') {
          this.titlePath = pageTitleArray.join('/') + ' /';
        }
      })

  }

  /**
   * This handler is called whent he user clicks on the switch to aView tool button.
   */
  toolSwitchAView() {

    // Stop here if we aren't changing tool id.
    if (StoreAccess.dataGet(currentToolId) === ToolIDEnum.AVIEW) {
      return;
    }

    // Switch to the aView screen.
    AppRoutingNavigation.navigateAView(this.router);

    // Make the call to switch to the aView tool.
    StoreAccess.dispatch(AppActions.currentToolIdSet(ToolIDEnum.AVIEW));
  }

  /**
   * This handler is called whent he user clicks on the switch to aHub tool button.
   */
  toolSwitchAHub() {

    // Stop here if we aren't changing tool id.
    if (StoreAccess.dataGet(currentToolId) === ToolIDEnum.AHUB) {
      return;
    }

    // Take the user back to the welcome screen.
    AppRoutingNavigation.navigateWelcome(this.router, false);

    // Make the call to switch to the aHub tool.
    StoreAccess.dispatch(AppActions.currentToolIdSet(ToolIDEnum.AHUB));
  }


  openTutorialWindow() {
    this.dialogueService.videoEmbedDialogOpen('Welcome to aView', 'https://www.youtube.com/embed/ho_LTnwwir4?autoplay=1', 'Close');
  }

  /**
   * Empty On destroy to ensure @Hark decorator works for an AOT build
   */
  ngOnDestroy() { }

  getNewVersion() {

    //Hide the tab
    this.showNewVersionTab = false;

    //Reset the path name to empty so that we are forced back to the root
    location.href = "/";
  }
}

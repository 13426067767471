import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Hark } from '../../hark.decorator';
import { MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';


@Component({
  selector: 'app-multiple-add-dialog',
  templateUrl: './multiple-add-dialog.component.html',
  styleUrls: ['./multiple-add-dialog.component.css']
})

@Hark()
export class MultipleAddDialogComponent implements OnInit {

  @ViewChild('addInput', { static: false }) addInput;

  title: string;
  content: string;
  confirmButtonLabel: string;
  cancelButtonLabel: string;

  @Input()
  public multiAddDialogVO: MultiAddDialogVO = null;
  addDialogForm: FormGroup;

  customValidationFunction: Function = undefined;

  listChanged$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    public readonly dialogRef: MatDialogRef<MultipleAddDialogComponent>,
    private readonly formBuilder: FormBuilder
  ) { }


  onCloseClick(): void {
    this.dialogRef.close();
  }
  onSaveClick(): void {
    this.dialogRef.close(true);
  }

  ngOnInit() {

    this.customValidationFunction = this.customValidation.bind(this);
    // Create the form with the regex validation
    this.addDialogForm = this.formBuilder.group({
      formInput: ["", [this.customValidationFunction]]
    });

  }

  ngOnDestroy() { }

  customValidation(control: FormControl) {
    const formValue = control.value;
    if (
      !this.multiAddDialogVO ||
      !this.multiAddDialogVO.inputOptions ||
      !this.multiAddDialogVO.inputOptions.validationFunction
    ) {
      return null;
    }
    const customValidation = this.multiAddDialogVO.inputOptions.validationFunction(formValue, this.multiAddDialogVO.data);
    if (!customValidation) {
      return null;
    }

    return {
      errorMessage: customValidation
    };
  }

}

export interface MultiAddDialogVO {
  inputOptions: InputOptions;
  data: string[];
  allowDragSort?: boolean;
}

export interface InputOptions {
  validationFunction: (inputValueString: string, addedList: string[]) => string;
  name: string;
}

import { ErrorHandler, Injectable, ComponentFactoryResolver } from '@angular/core';
import { DialogService } from './modules/common/dialogs/dialog.service';
import { ErrorDialogComponent, ErrorDialogParameters } from './modules/common/dialogs/error-dialog/error-dialog.component';

@Injectable()
export class AppGlobalErrorHandler implements ErrorHandler {

  /**
   * Do we currently have a dialog open?
   */
  dialogOpen = false;

  constructor(private readonly dialogueService: DialogService,
    private readonly resolver: ComponentFactoryResolver) { }


  handleError(error) {

    //Dialog is open then we will bail out!
    if (this.dialogOpen) {
      return;
    }



    //Construct a string array
    const message: string[] = [
      window.location.href
      , error.message];

    //Look at the error Type
    if (error instanceof Error) {
      message.push(error.stack);
    }
    else {

      //Unknown type stringify the error and add it to the message
      message.push(JSON.stringify(error));
    }

    //Create the error parameters object
    const errObject: ErrorDialogParameters = {
      message: `An error has occured in the portal, some features may not behave as intended. Would you like to continue or refresh to try to fix the
                issues unsaved work will be lost? If error persists please contact the aHub team.`,
      hiddenMessage: message
    }

    //Set it to open
    this.dialogOpen = true;

    //Open the dialogue with the parameters object
    this.dialogueService.componentDialogOpen("Application Error", this.resolver.resolveComponentFactory(ErrorDialogComponent),
      'dialogParams', errObject, undefined, "Refresh", "Continue")
      .subscribe((refresh) => {

        //It's closed set it to closed
        this.dialogOpen = false;

        //If we are not refreshing the page then we will bail out
        if (!refresh) {
          return;
        }

        //Reload the current page!
        location.reload();
      });

    //Try to log the error
    console.error(error);
  }
}

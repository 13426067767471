import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Hark } from '../../hark.decorator';


@Component({
  selector: 'app-rule-maker-chips',
  templateUrl: './rule-maker-chips.component.html',
  styleUrls: ['./rule-maker-chips.component.scss']
})
@Hark()
export class RuleMakerChipsComponent implements OnInit, OnDestroy {

  /**
   * Rule value buttons used to specifiying odd values in simple button way
   */
  @Input() ruleValueButtons: RuleValue[] = [];

  /**
   * Do we want to make the rule maker readonly
   */
  @Input() readOnly = false;

  @Input()
  set values(value: string[]) {

    //Set the rule string value
    this.ruleValueStringArray = value;

    //Update the value rules
    this.valueToValueRules();
  }

  @Input()
  title?: string;

  @Output() valueChanged: EventEmitter<string[]> = new EventEmitter();

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  ruleValues: RuleValue[] = [];
  ruleValueStringArray: string[];

  currentInputValue: string;

  constructor() {
    // This is intentional
  }

  ngOnInit() {

    //Update the value rules
    this.valueToValueRules();
  }

  ngOnDestroy() { }

  valueToValueRules() {

    //If we don't have any values then set an empty array and walk away
    if (!this.ruleValueStringArray) {
      this.ruleValues = [];
      return;
    }

    //Create a set of rule values from the rule values string
    this.ruleValues = this.ruleValueStringArray.map(value => {

      //Do we have a rule button value?
      const ruleButtonValue = this.ruleValueButtons.find(btn => btn.value === value);

      //If we have a rule button value we will use that
      if (ruleButtonValue) {
        return ruleButtonValue;
      }

      //If not create an object
      return {
        name: value,
        value
      }
    });
  }

  add(event: MatChipInputEvent): void {

    const input = event.input;
    const value = event.value ? event.value.trim() : undefined;

    // Add rule value
    if (value && value.length > 0) {
      this.ruleValues.push({ name: value, value });
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    //Call the update
    this.dispatchUpdate();
  }

  addStandardValue(ruleValue: RuleValue): void {

    //Add this to the list
    this.ruleValues.push(ruleValue);

    //Call the update
    this.dispatchUpdate();
  }

  remove(ruleIndex: number): void {

    //If found remove it from the list
    if (ruleIndex >= 0) {

      //Change the list
      this.ruleValues.splice(ruleIndex, 1);

      //Call the update
      this.dispatchUpdate();
    }
  }

  /**
   * Dispatch an update event so the calling class knows what to do
   */
  dispatchUpdate() {

    //Get the value from the rules
    const values = this.ruleValues && this.ruleValues.length > 0 ? this.ruleValues.map(a => a.value) : undefined;

    //Update the value supplied
    this.ruleValueStringArray = values;

    //Emit the value of the chip
    this.valueChanged.emit(values);
  }

  /**
   * Input used inside chips does not respond to backspace key events (intercepts for chip magic)
   * So we have to fake it (e.g. delete the last character)
   */
  removeLastCharacterOfCurrentInputValue() {

    // Do we have a current input value?
    if (this.currentInputValue) {

      // Yes, so remove the last character.
      this.currentInputValue = this.currentInputValue.slice(0, -1);
    }
    else {

      // If we get here then we need to remove the last item from the list
      // as the input is already empty.
      this.remove(this.ruleValues.length - 1);
    }
  }
}

export interface RuleValue {
  name: string;
  value: string;
}

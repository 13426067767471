import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'modules/shared/shared.module';

import { SidenavComponent } from './sidenav.component';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { ClientMenuComponent } from './client-menu/client-menu.component';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatChipsModule } from '@angular/material/chips';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatMenuModule,
    MatChipsModule,
    MatListModule,
    MatButtonModule,
    MatTooltipModule,
    SharedModule
  ],
  exports: [SidenavComponent],
  declarations: [SidenavComponent, UserMenuComponent, ClientMenuComponent]
})
export class SidenavModule { }

/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AhubAuthInterceptorService } from './ahub-auth-interceptor.service';
import { AhubContentTypeInterceptorService } from './ahub-content-type-interceptor.service';
import { AhubNoCacheForIE11InterceptorService } from './ahub-no-cache-for-ie11-interceptor.service';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: AhubAuthInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AhubContentTypeInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AhubNoCacheForIE11InterceptorService, multi: true },
];
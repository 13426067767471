
import { mergeMap, filter, takeUntil, debounceTime } from 'rxjs/operators';
import {
  Directive,
  Input,
  OnInit,
  OnDestroy,
  OnChanges
} from '@angular/core';

import { FormGroup } from '@angular/forms';

import { Observable } from 'rxjs';

import { StoreAccess } from 'store/store-access';
import { ViewActions } from 'actions/view.actions';
import { viewFormStateFormStatesForceReset } from 'selector/view/view-page-state.selector';

import { FormStateViewVO } from 'valueObjects/view/form-state.view.vo';

import { Hark, componentDestroyStream } from 'modules/common/hark.decorator';

/**
 * Tracked form directive decorator. A tracked form is one which we are storing state for in the redux store.
 * This allows us to prefom actions like check if there are any unsaved changes before we navigate away from the data
 */
@Directive({ selector: '[trackedForm]' })
@Hark()
export class TrackedFormDirective implements OnInit, OnDestroy {

  @Input()
  public trackedForm: FormGroup;

  /**
   * Generate an id for the form so we can track and replace its state as required
   */
  private formId: string = Math.random().toString(36).substr(2, 5);

  /**
   * Was the form data dirty last time we checked
   */
  private wasDataDirty: boolean = false;

  /**
   * The last pristine set of form data.
   */
  private formPristineData;

  /**
   * The last pristine set of form data as a string, used for comparison.
   */
  private formPristineDataString;

  /**
   * Are we resetting right now?
   */
  private isReseting: boolean = false;

  constructor() {
    // This is intentional
  }

  /**
   * Handel the initalisation
   */
  ngOnInit() {

    //Check that we have a form group
    if (!this.trackedForm) {
      console.log('Form will not be tracked as a form group hasn\'t been suppled');
    }
    else {

      //Subscribe to the value changes
      this.trackedForm.valueChanges.pipe(
        filter(() => this.wasDataDirty != this.trackedForm.dirty),
        takeUntil(componentDestroyStream(this)))
        .subscribe(() => {
          //Set the dirty state
          this.wasDataDirty = this.trackedForm.dirty;

          //Update the form state as it has changed
          this.updateFormState();
        });


      // Listen for all changes to the form values.
      this.trackedForm.valueChanges.pipe(
        debounceTime(200),
        takeUntil(componentDestroyStream(this)))
        .subscribe(formData => {

          // Record the pristine data.
          this.recordPristineData();

          // If an input form field was orignally empty (eg null) then a user enters some text,
          // then changes their mind and deletes their input, the form field does not return to the original null value
          // but rather an empty string (eg ""). This makes checking that the form has returned to its orignal state
          // problematic, the following is a cludge to get around this Material feature.
          // Empty (eg "") form fields will be removed, as if they had never been touched.
          let formDataString = JSON.stringify(formData).replace(/("[a-zA-Z]+":"",)/, '');

          // If the form has been returned to its original state we can mark it as pristine and allow navigation away
          if (formDataString === this.formPristineDataString) {
            this.trackedForm.markAsPristine();
            this.updateFormState();
          }

        });
    }

    //We will call this to set our form store state for the first time
    this.updateFormState();

    StoreAccess.dataGetObvs(viewFormStateFormStatesForceReset).pipe(
      takeUntil(componentDestroyStream(this)),
      mergeMap(forms => forms),
      filter(form => form.formId === this.formId),
      filter(form => form.forceReset))
      .subscribe(form => {

        // If we get here then we need to reset the form.
        this.formReset();
      });
  }

  /**
   * Handel the form destroy
   */
  ngOnDestroy() {

    //Remove the form from the store by its specfieid id
    StoreAccess.dispatch(ViewActions.formStateRemoveById(this.formId));
  }


  /**
   * This function is called when we want to reset the form.
   */
  private formReset() {

    // We are resetting.
    // We do this because the markAsPristine will end up calling the update form state
    // function which will then replace the last pristine data with the current not
    // prisine data!! Naughty I know !!!
    this.isReseting = true;

    // Call the reset form function.
    this.trackedForm.markAsPristine();

    // Reset the form to the last set of distrine data we recorded.
    this.trackedForm.reset(this.formPristineData);

    // Not any more.
    this.isReseting = false;
  }


  /**
   * This function is called when we want to record the pristine date.
   */
  private recordPristineData() {

    // Record the last set of pristine data if the form is pristine/
    if (!this.trackedForm.pristine || this.isReseting)
      return;

    this.formPristineData = this.trackedForm.value;
    this.formPristineDataString = JSON.stringify(this.trackedForm.value);
  }


  /**
   * Update the form state within the store
   */
  private updateFormState() {

    //Dispatch a form state set action to set the current state of the form in the store
    StoreAccess.dispatch(ViewActions.formStateSet({
      formId: this.formId,
      pristine: this.trackedForm.pristine,
      forceReset: false,
    }

    ));
  }
}

import { Component, ElementRef, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.scss']
})
export class ImageSliderComponent implements OnInit {

  sliderElement;
  sliderTimer;
  transitionTimer;

  @Input() pauseTime = 15000;
  @Input() transitionTime = 7000;


  constructor(
    private readonly slider: ElementRef
  ) { }

  ngOnInit() {
    this.sliderElement = this.slider.nativeElement.firstChild;
    //this.initSlider()
  }


  /**
  * Animates the slider to a selected slide
  */
  goToSlide(slideElement: HTMLElement, slideButtonElement: HTMLElement) {
    // Clear slider timers
    clearInterval(this.sliderTimer);
    clearTimeout(this.transitionTimer);
    // animate to selected slide and set states to active
    const elems = this.sliderElement.querySelectorAll(".slide, .sliderButton");
    elems.forEach(element => element.classList.remove("active"));
    slideButtonElement.classList.add('active')
    slideElement.classList.add('active')
    slideElement.scrollIntoView();
    // After a long pause re animate slider
    this.transitionTimer = setTimeout(() => {
      this.playSlider();
    }, this.pauseTime)
  }

  /**
  * Auto plays the slider
  */
  playSlider() {
    this.sliderTimer = setInterval(() => {
      const activeSlide = this.sliderElement.querySelectorAll(".slide.active")[0];
      if (activeSlide.nextElementSibling) {
        // Set next active slide
        activeSlide.classList.remove('active')
        activeSlide.nextElementSibling.classList.add('active');
        // Set next active button
        const activesliderButton = this.sliderElement.querySelectorAll(".sliderButton.active")[0];
        activesliderButton.classList.remove('active')
        activesliderButton.nextElementSibling.classList.add('active');
        // Animate to next slide
        activeSlide.nextElementSibling.scrollIntoView();
      } else {
        // Set first button and slide to active
        this.sliderElement.querySelectorAll(".slide, .sliderButton").forEach(elems => elems.classList.remove("active"));
        this.sliderElement.getElementsByClassName('slide')[0].classList.add('active');
        this.sliderElement.getElementsByClassName('sliderButton')[0].classList.add('active');
        this.sliderElement.querySelectorAll(".slide")[0].scrollIntoView();
      }
    }, this.transitionTime);
  }

  initSlider() {
    if (this.sliderElement.querySelectorAll(".slide").length === 1) {
      // No slider
    } else {
      this.playSlider();
    }
  }

}

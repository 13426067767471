
import { combineLatest, takeUntil, startWith } from 'rxjs/operators';
import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Observable, of } from 'rxjs';

import { PermissionsService } from 'services/permissions/permissions.service';

import { Hark, componentDestroyStream } from 'modules/common/hark.decorator';

// valueObjects
import { EntityPermissionAHubVO } from 'valueObjects/ahub/accounts/entity-permission.ahub.vo';

/**
 * Add the template content to the DOM unless current user does not have sufficient permissions.
 *
 * ### Syntax
 *
 * - `<div *visibleAs="accountsEditor">...</div>`
 * where accountsEditor is a local variable in the template's component
 * set from some convenient statics like so:
 *
 * import { EntityPermissions } from 'valueObjects/ahub/accounts/entity-permissions.ahub';
 *
 * accountsEditor = EntityPermissions.ACCOUNTS_EDITOR;
 *
 */
@Directive({ selector: '[visibleAs]' })
@Hark()
export class VisibleAs {

    /**
     * Entity permissions required to make a component visible.
     */
    private _visibleAs: EntityPermissionAHubVO;
    @Input("visibleAs")
    set visibleAs(value: EntityPermissionAHubVO) {
        this._visibleAs = value;
    }

    /**
     * If we want to let a user access a component that is associated to themselves
     * we may want to allow them, but with a different set of credentials.
     * The above credentials will override these if they are available.
     *
     * I.E If a user has Admin permissions on an entity but they only need User permissions
     * then we will let them have access regardless of the owner id.
     */

    /**
     * This is the id of the owner of the component. Does it match the current session user id?
     */
    private _ownerUserId$: Observable<number> = of(undefined);
    @Input()
    set visibleAsOwnerUserId(value: Observable<number>) {
        this._ownerUserId$ = value;
    }


    private _mustMatchUser: boolean = false;
    @Input()
    set visibleAsMustMatchUser(value: boolean) {
        this._mustMatchUser = value;
    }

    /**
     * Is the button already visible
     */
    alreadyVisible = false;

    constructor(
        private permissionsService: PermissionsService,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef) { }

    ngOnInit() {

        // Do we have a user id?
        if (this._ownerUserId$ != null) {

            // Yes, so combine with the user id stream.
            this.permissionsService.entityPermissions$.pipe(
                combineLatest(this._ownerUserId$.pipe(startWith(undefined)), (entityPermissions, ownerUserId) => ({ entityPermissions: entityPermissions, ownerUserId: ownerUserId })),
                takeUntil(componentDestroyStream(this)))
                .subscribe(data => {

                    //Do we have an entity permissions with a specified user id.
                    if (data.entityPermissions)
                        this.visabilityUpdate(data.ownerUserId);
                });
        }
        else {

            // Otherwise, just watch the entity permissions.
            this.permissionsService.entityPermissions$.pipe(
                takeUntil(componentDestroyStream(this)))
                .subscribe(entityPermissions => {

                    //Do we have an entity permissions with no user id.
                    if (entityPermissions)
                        this.visabilityUpdate(null);
                });
        }
    }

    /**
     * Empty On destroy to ensure @Hark decorator works for an AOT build
     */
    ngOnDestroy() { }

    /**
     * Update the visibility based on a user id passed in.
     *
     * @param ownerUserId           The user id to check.
     */
    private visabilityUpdate(ownerUserId) {

        //Does the user have permissions to view
        if (this.permissionsService.userHasPermissionToView(this._visibleAs, ownerUserId, this._mustMatchUser)) {

            //If the element isnt already visible, add it to the view container
            if (!this.alreadyVisible) {
                this.viewContainer.createEmbeddedView(this.templateRef);
                this.alreadyVisible = true;
            }

        } else {

            //Clear the view container and show its not currently visible
            this.viewContainer.clear();
            this.alreadyVisible = false;
        }
    }
}
/**
 * --------------------------------------------------
 * State Imports
 * --------------------------------------------------
 */
import { aHubState } from 'selector/ahub/ahub.selector';
import { AHubPermanentState } from 'reducers/ahub/ahub-permanent.reducer';

import { List } from 'store/list.vo';

/**
 * --------------------------------------------------
 * Value Object Imports
 * --------------------------------------------------
 */

import { ClientIndexAHubVO } from 'valueObjects/ahub/accounts/client-index.ahub.vo';
import { ExportTypeAHubVO } from 'valueObjects/ahub/accounts/export-type.ahub.vo';
import { UserAHubVO } from 'valueObjects/ahub/accounts/user.ahub.vo';
import { WorklogAHubVO } from 'valueObjects/ahub/work/worklog.ahub.vo';
import { WorklogSegmentAHubVO } from 'valueObjects/ahub/work/worklog-segment.ahub.vo';
import { WorkGroupAHubVO } from 'valueObjects/ahub/accounts/work-group.ahub.vo';
import { ClientStorageIndexAHubVo } from 'valueObjects/ahub/accounts/client-storage-index.ahub.vo';
import { ExportGeneratorAHubVO } from 'valueObjects/ahub/accounts/export-generator.ahub.vo';

/**
 * --------------------------------------------------
 * Core Selectors
 * --------------------------------------------------
 */

/**
 * Selector to get the aHub permanent state from the ahub
 */
const aHubStatePermanent = (state): AHubPermanentState => aHubState(state).permanent;

/**
 * --------------------------------------------------
 * Selectors
 * --------------------------------------------------
 */

/**
 * Selector to get the users from the permanent ahub state
 */
export const aHubStatePermanentUserList = (state): List<UserAHubVO> => aHubStatePermanent(state).users;

/**
 * Selector to get the users from the permanent ahub state
 */
export const aHubStatePermanentUsers = (state): UserAHubVO[] => aHubStatePermanentUserList(state).data;

/**
 * Returns a list of the current users client index objects.
 */
export const aHubStatePermanentUserClientIndexs = (state): ClientIndexAHubVO[] => aHubStatePermanent(state).clientIndexs;

/**
 * Does the current user have any aViews attached to it?
 */
export const aHubStatePermanentUserHasAViews = (state): boolean => aHubStatePermanent(state).userHasAViews;

/**
 * Returns a list of the current users client index objects.
 */
export const aHubStatePermanentClientStorageIndexes = (state): ClientStorageIndexAHubVo[] => aHubStatePermanent(state).clientStorageIndexes;

/**
 * Selector to get the export types from the permanent ahub state
 */
export const aHubStatePermanentExportTypes = (state): ExportTypeAHubVO[] => aHubStatePermanent(state).exportTypes;

/**
 * Selector to get the export generators from the permanent ahub state
 */
export const aHubStatePermanentExportGenerators = (state): ExportGeneratorAHubVO[] => aHubStatePermanent(state).exportGenerators;

/**
 * Selector to get the export types from the permanent ahub state
 */
export const aHubStatePermanentWorkGroupList = (state): List<WorkGroupAHubVO> => aHubStatePermanent(state).workGroups;

/**
 * Selector to get the export types from the permanent ahub state
 */
export const aHubStatePermanentWorkGroups = (state): WorkGroupAHubVO[] => aHubStatePermanentWorkGroupList(state).data;

/**
 * Selector to get the count of out outstanding worklogs requests. If this is more than 0 we are waiting for requests
 */
export const aHubStatePermanentWorklogOutstandingCount = (state): number => aHubStatePermanent(state).worklogRequestOutstanding;

/**
 * Selector for the managed list of worklogs tracking changes done in the aHub.
 */
export const aHubStatePermanentWorklogs = (state): WorklogAHubVO[] => aHubStatePermanent(state).worklogs.data;

/**
 * Selects the last worklog segment retrieved form the aHub.
 */
export const aHubStatePermanentWorklogSegmentLast = (state): WorklogSegmentAHubVO => aHubStatePermanent(state).worklogSegmentLast;

/**
 * Selects the flag indicating the connection error status.
 */
export const aHubStatePermanentIsConnected = (state): boolean => aHubStatePermanent(state).isConnected;

import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-grid-renderer-material-icon',
  templateUrl: './grid-renderer-material-icon.component.html',
  styleUrls: ['./grid-renderer-material-icon.component.css']
})
export class GridRendererMaterialIconComponent implements AgRendererComponent {

  /**
   * Parameters for the renderer
   */
  params: GridRendererMaterialIconParams = undefined;

  /**
   * Do we want to put the icon to the left of the label
   */
  iconLeftOfLabel = true;

  /**
   * Name of the icon we are going to display
   */
  iconName: string = undefined;

  /**
   * Colour of the icon we are displaying
   */
  iconColour: string = undefined;

  /**
   * Label for the icon which we want to display
   */
  iconLabel: string = undefined;

  constructor() {
    // This is intentional
  }

  /**
   * Initalise the ag componenet
   */
  agInit(params) {

    //Set the parameters property ready for use
    this.params = params as GridRendererMaterialIconParams;

    //Call the refresh function
    this.refresh(params);
  }

  /**
   * Action to be called if the cell needs to be re-rendered
   */
  refresh(params: any): boolean {

    //Evaluate which icon should be displayed
    this.evaluateIcon(params.value);
    this.evaluateIconColour(params);
    this.evaluateIconLabel(params.value);

    //If this is defined then we will set it
    if (this.params.iconLeftOfLabel !== undefined && this.params.iconLeftOfLabel !== null) {
      this.iconLeftOfLabel = this.params.iconLeftOfLabel;
    }

    //We have updated successfully
    return true;
  }

  /**
   * Function will sort out which icon to display
   */
  evaluateIcon(value) {

    //If we don't have a material get function bail out
    if (!this.params || !this.params.matIconGetFunc) {
      return;
    }

    //Get the icon name from the function supplied
    this.iconName = this.params.matIconGetFunc(value);
  }

  /**
   * Function will sort out the colour of the icon to display
   */
  evaluateIconColour(row) {

    //If we don't have a material get function bail out
    if (!this.params || !this.params.matIconColourGetFunc) {
      return;
    }

    //Get the icon name from the function supplied
    this.iconColour = this.params.matIconColourGetFunc(row);
  }

  /**
   * Function will sort out a label for the icon
   */
  evaluateIconLabel(value) {

    //If we don't have a material get function bail out
    if (!this.params || !this.params.iconLabelGetFunc) {
      return;
    }

    //Get the icon label for the item
    this.iconLabel = this.params.iconLabelGetFunc(value);
  }
}

/**
 * Parameters for the grid render
 */
export interface GridRendererMaterialIconParams extends ICellRendererParams {
  matIconGetFunc: (val: any) => string;
  matIconColourGetFunc?: (row: any) => string;
  iconLabelGetFunc?: (val: any) => string;
  iconLeftOfLabel?: boolean;
}

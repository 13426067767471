
import { combineReducers } from 'redux';
import { AHubState, AHubReducer } from 'reducers/ahub/ahub.reducer';
import { AppState, AppReducer } from 'reducers/app.reducer';
import { ViewState, ViewReducer } from 'reducers/view/view.reducer';
import { ComponentState, ComponentReducer } from 'reducers/component/component.reducer';
import { AViewState, AViewReducer } from './aview.reducer';

export interface State {
  ahub?: AHubState,
  app?: AppState,
  view?: ViewState,
  component?: ComponentState,
  aView?: AViewState
}

export const rootReducer = combineReducers<State>({
  ahub: AHubReducer,
  app: AppReducer,
  view: ViewReducer,
  component: ComponentReducer,
  aView: AViewReducer
});
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-knowledge-base-dialog',
  templateUrl: './knowledge-base-dialog.component.html',
  styleUrls: ['./knowledge-base-dialog.component.css']
})
export class KnowledgeBaseDialogComponent implements OnInit {

  /**
 * This is the title and content to display in this knowledge base dialog.
 */
  public title: string;
  public knowledgeBasePageLocation: string;

  supportSiteIframe: string;

  constructor() {
    // This is intentional
  }

  ngOnInit() {
    this.supportSiteIframe = this.knowledgeBasePageLocation
  }

}

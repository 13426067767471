import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Hark, componentDestroyStream } from '../../hark.decorator';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-image-resize-dialog',
  templateUrl: './image-resize-dialog.component.html',
  styleUrls: ['./image-resize-dialog.component.css']
})
@Hark()
export class ImageResizeDialogComponent implements OnInit {

  /**** REQUIRED FOR USE IN A DIALOGUE  ****/
  /**
   * The VO that is passed in / out.
   */
  public dialogVO: ImageResizeParameters = null;

  // A observable that can be used to check if the dialog is valid.
  public dialogIsValid$: Subject<boolean> = new Subject<boolean>();

  aspectRatio: number; // max width / max height

  resizeForm: FormGroup = this.buildForm();

  displayCustomFields = false;

  displayWarningNotice = false;

  // Sizes
  small = 256;
  medium = 1024;
  large = 2048;

  constructor(private readonly formBuilder: FormBuilder) { }

  ngOnInit() {
    this.dialogVO.resizedHeight = Math.floor(this.dialogVO.maxHeight / 2)
    this.dialogVO.resizedWidth = Math.floor(this.dialogVO.maxWidth / 2)
    this.aspectRatio = this.dialogVO.maxWidth / this.dialogVO.maxHeight;
    this.dialogVO.resizeDefaults = "LARGE";
    this.dialogVO.imageType = "PNG";

    this.resizeForm.valueChanges
      .pipe(
        takeUntil(componentDestroyStream(this)))
      .subscribe(data => {
        this.dialogVO = data;
        this.dialogIsValid$.next(this.resizeForm.valid);
      });

    // Unfortunate dialog validation timing issue which requires that the initial form value be set after a mere jiffy
    setTimeout(() => {
      this.resizeForm.setValue(this.dialogVO);
    }, 5);

    // As the height and width are adjusted we should make sure we retain the aspect ratio of the original asset
    this.resizeForm.controls.resizedHeight.valueChanges
      .pipe(
        takeUntil(componentDestroyStream(this)))
      .subscribe(height => {
        this.displayWarningNotice = (height > this.dialogVO.maxHeight) ? true : false;
        this.resizeForm.controls.resizedWidth.setValue(Math.floor(height * this.aspectRatio), { emitEvent: false })
        this.dialogIsValid$.next(this.resizeForm.valid);
      });

    this.resizeForm.controls.resizedWidth.valueChanges
      .pipe(
        takeUntil(componentDestroyStream(this)))
      .subscribe(width => {
        this.displayWarningNotice = (width > this.dialogVO.maxWidth) ? true : false;
        this.resizeForm.controls.resizedHeight.setValue(Math.floor(width / this.aspectRatio), { emitEvent: false });
        this.dialogIsValid$.next(this.resizeForm.valid);
      });



    this.resizeForm.controls.resizeDefaults.valueChanges
      .pipe(
        takeUntil(componentDestroyStream(this)))
      .subscribe(value => {


        this.displayCustomFields = false;
        switch (value) {
          case "SMALL":
            this.showWarningIfImageStretch(this.small);
            this.resizeForm.controls.resizedHeight.setValue(this.small);
            this.resizeForm.controls.resizedWidth.setValue(this.small);
            break;
          case "MEDIUM":
            this.showWarningIfImageStretch(this.medium);
            this.resizeForm.controls.resizedHeight.setValue(this.medium);
            this.resizeForm.controls.resizedWidth.setValue(this.medium);
            break;
          case "LARGE":
            this.showWarningIfImageStretch(this.large);
            this.resizeForm.controls.resizedHeight.setValue(this.large);
            this.resizeForm.controls.resizedWidth.setValue(this.large);
            break;
          case "ORIGINAL":
            this.resizeForm.controls.resizedHeight.setValue(this.dialogVO.maxHeight);
            this.resizeForm.controls.resizedWidth.setValue(this.dialogVO.maxWidth);
            break;
          default:
            this.displayCustomFields = true;
            this.displayWarningNotice = false;
            const width = this.resizeForm.controls.resizedWidth.value;
            this.resizeForm.controls.resizedHeight.setValue(Math.floor(width / this.aspectRatio), { emitEvent: false });
            const height = this.resizeForm.controls.resizedHeight.value;
            this.resizeForm.controls.resizedWidth.setValue(Math.floor(height * this.aspectRatio), { emitEvent: false })
            break;
        }
        this.dialogIsValid$.next(this.resizeForm.valid);
      });
  }

  showWarningIfImageStretch(size) {
    this.displayWarningNotice = (size > this.dialogVO.maxHeight || size > this.dialogVO.maxWidth) ? true : false;
  }

  buildForm(): FormGroup {
    return this.formBuilder.group({
      resizedWidth: ["", Validators.min(25)],
      resizedHeight: ["", Validators.min(25)],
      resizeDefaults: [],
      maxWidth: [],
      maxHeight: [],
      imageType: []
    });
  }

  ngOnDestroy() { }

}

export interface ImageResizeParameters {
  maxWidth: number;
  maxHeight: number;
  resizeDefaults: string;
  resizedWidth: number;
  resizedHeight: number;
  imageType: string;
}

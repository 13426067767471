import { PropertyAllocationObjectVO } from 'app/valueObjects/stream/product-allocation-object-stream.vo';
import { ProductClassIndexAHubVO } from 'app/valueObjects/ahub/library/product-class-index.ahub.vo';
import { ProductMatrixDefAHubVO } from 'app/valueObjects/ahub/library/product-matrix-def.ahub.vo';
import { ProductAHubVO } from 'app/valueObjects/ahub/library/product.ahub.vo';
import { PropertySectionView } from './product-view-full.component';

export class ProductViewFullUtil {


    /**
     * Create the rows for the matrix data onscreen
     *
     * @param product       Product which we want to create the rows for
     * @param sectionView   The section view which we are creating the rows for
     */
    public static sectionViewMatrixGridOptionRowsCreate(product: ProductAHubVO, sectionView: PropertySectionView): MatrixGridRow[] {

        const dataRows: MatrixGridRow[] = [];

        //For every section in the sections view we need to find all the values & all the properties we need to display
        sectionView.sections.forEach(section => {

            //Find the correct section for this product view
            const matrixSections = product.productMatrixSectionValues.find(matrixProductSection => matrixProductSection.sectionId === section.id);

            //Bail out if we have no section or no data for this section
            if (!matrixSections || !matrixSections.productSectionPropertyValues) {
                return;
            }

            matrixSections.productSectionPropertyValues.forEach(matrixValues => {

                if (!matrixValues.data) return

                matrixValues.data.forEach(value => {

                    let existingRow = dataRows.find(row => JSON.stringify(row.id) === JSON.stringify(value.id));

                    if (!existingRow) {
                        existingRow = {
                            id: value.id,
                            values: []
                        };
                        dataRows.push(existingRow);
                    }

                    existingRow.values.push({
                        sectionId: section.id,
                        propertyId: matrixValues.propertyId,
                        value: value.value
                    });
                });
            });
        });

        //Return the data rows
        return dataRows;
    }

    /**
     * Sort function for the allocations on this page
     *
     * @param allocationA         Allocation a which we will be sorting
     * @param allocationB         Allocation b which we will be sorting
     * @param sectionOrderIds     Sections which need to be in the correct order
     */
    public static allocationSortFunction(allocationA: PropertyAllocationObjectVO, allocationB: PropertyAllocationObjectVO, sectionOrderIds: number[]): number {

        //Null checks to stop a boom
        if (!allocationA || !allocationA.property) {
            return -1;
        }
        else if (!allocationB || !allocationB.property) {
            return 1;
        }

        //Sort based on the allocation
        if (allocationA.property.label > allocationB.property.label) {
            return 1;
        } else if (allocationA.property.label < allocationB.property.label) {
            return -1;
        }

        //Null checks to stop a boom
        if (!allocationA.section) {
            return -1;
        }
        else if (!allocationB.section) {
            return 1;
        }

        const aSectionIndex = sectionOrderIds.findIndex((id) => id === allocationA.section.id);
        const bSectionIndex = sectionOrderIds.findIndex((id) => id === allocationB.section.id);

        return aSectionIndex - bSectionIndex;
    }

    /**
     * Find the closeset possible matrix definition for a given class id
     *
     * @param productClassId            Id of the class we want the matrix for
     * @param productClassIndexes       Class indexes which have the matrixes within them
     */
    public static productMatrixDefinitionForClassId(productClassId: number, productClassIndexes: ProductClassIndexAHubVO[]): ProductMatrixDefAHubVO {

        let matrixDefinition: ProductMatrixDefAHubVO = undefined;
        let productClass = productClassId;

        //Whilst we havent found our matix and we still have a parent keep looking
        while (productClass > 0 && !matrixDefinition) {

            //Get the parent for the partent id
            const parent = productClassIndexes.find(clazz => clazz.id === productClass);

            //If we found the parent we will set the properties and move on
            if (parent) {

                //If we have a matrix at this level we will return it now
                if (parent.productMatrixDefinition) {
                    matrixDefinition = parent.productMatrixDefinition;
                }

                //Lets extract the product class id
                const parentParts = (parent.ancestry) ? parent.ancestry.split(",") : [];
                productClass = (parentParts.length > 0) ? Number(parentParts[parentParts.length - 2]) : -1;
            }
            else {
                productClass = -1;
            }
        }

        return matrixDefinition;
    }

    /**
     * Create a sorted array of the possible options for the product in order as defined by the martrix
     *
     * e.g. D1 = S,M, L   D2 = Round Neck, VNeck.
     * S-Round Neck, S- VNeck, M-Round, M-VNeck, L-Round, L-VNeck
     *
     *
     * @param matrixDefinition    Matrix which we want to creator the options for
     * @param seperator           Seperator for the various options
     */
    public static productMatrixOptionSorted(matrixDefinition: ProductMatrixDefAHubVO, seperator: string): string[] {

        //Defined a recursive function which we can use to build the option strings in order
        const recursiveOptionBuildFunction = (dimensionIndex, existingData: string[]): string[] => {

            //Define a new order array we will create the new content in this rather then edit the source
            let newOrder = [];

            //Is there any data in the dimension we are looking for?
            if (matrixDefinition.dimensions.length > dimensionIndex) {

                //Get the current dimension
                const dimension = matrixDefinition.dimensions[dimensionIndex];

                //Do we have one with options?
                if (dimension && dimension.options) {

                    //Do we have existing data? If so then we will be creating composite values
                    if (existingData.length > 0) {

                        //Loop through all the existing options and we will create a new option which is a combination of an existing option and the current one
                        //e.g.  D1 = S,M, L   D2 = Round Neck, V-Neck.    We will start with an array with S,M,L This will run and create  S-Round Neck, S- VNeck, M-Round etc..
                        existingData.forEach(existing => {
                            dimension.options.forEach(option => newOrder.push(existing + seperator + option.id));
                        });
                    }
                    else {

                        //We had no existing data so there is no need to do any option colapsing
                        newOrder = dimension.options.map(option => option.id);
                    }
                }

                //Are there more dimentions to be discovered? If so go and mine for them for the next set of data
                if (matrixDefinition.dimensions.length > dimensionIndex + 1) {
                    newOrder = recursiveOptionBuildFunction(dimensionIndex + 1, newOrder);
                }
            }

            //Return the new order
            return newOrder;
        }

        //Call the recursive function to generate our options
        return recursiveOptionBuildFunction(0, []);
    }
}

export interface MatrixGridRow {
    id: string[],
    values: MatrixGridRowData[]
}

export interface MatrixGridRowData {
    sectionId: number,
    propertyId: number,
    value: string
}

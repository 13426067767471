import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';


/**
 * The string must only contain a valid json array.
 */
export function jsonArrayValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        let jsonArrayValid = false;
        try {
            JSON.parse(control.value)
            jsonArrayValid = true
        } catch (error) {

        }
        return jsonArrayValid ? null : { 'jsonArrayValid': { value: control.value } };
    };
}

@Directive({
    selector: '[jsonArrayValid]',
    providers: [{ provide: NG_VALIDATORS, useExisting: JsonArrayValidValidatorDirective, multi: true }]
})
export class JsonArrayValidValidatorDirective implements Validator {

    validate(control: AbstractControl): { [key: string]: any } {
        return jsonArrayValidator()(control);
    }
}

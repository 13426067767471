
import { takeUntil, map, filter, combineLatest, startWith, delay } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AppRoutingNavigation } from 'app/app-routing-navigation';

import { Hark, componentDestroyStream } from 'modules/common/hark.decorator';

import { StoreAccess } from 'store/store-access';

import { sessionUserId, sessionClientId } from 'selector/app.selector';
import { aHubStatePermanentUserClientIndexs } from 'selector/ahub/ahub-permanent.selector';

import { ClientIndexAHubVO } from 'valueObjects/ahub/accounts/client-index.ahub.vo';

import { DialogService } from 'modules/common/dialogs/dialog.service';


/**
 * Actions
 */
import { AHubActions } from 'actions/ahub.actions';
import { AppActions } from 'actions/app.actions';
import { ComponentActions } from 'app/store/actions/component.actions';

@Component({
  selector: 'app-client-menu',
  templateUrl: './client-menu.component.html',
  styleUrls: ['./client-menu.component.css']
})
@Hark()
export class ClientMenuComponent implements OnInit, OnDestroy {

  /**
   * Create an observable on the current user id.
   */
  currentUserId$: Observable<number> = StoreAccess.dataGetObvs(sessionUserId);

  /**
   * Create a observable watching the current client id.
   */
  currentClientId$: Observable<number> = StoreAccess.dataGetObvs(sessionClientId);

  /**
   * Create an observable to watch all of the client indexes.
   */
  clientIndexs$: Observable<ClientIndexAHubVO[]> = StoreAccess.dataGetObvs(aHubStatePermanentUserClientIndexs).pipe(
    map(indexes => { return indexes.sort((indexA, indexB) => { return (indexA.name > indexB.name) ? 1 : -1; }); }));

  /**
   * Watch the length of the client index stream.
   */
  clientIndexsLength$: Observable<number> = this.clientIndexs$
    .pipe(
      /**
      * delay(0) Added in order to prevent ExpressionChangedAfterChecked errors!
      * Followed advice from: https://blog.angular-university.io/angular-debugging/
      */
      delay(0),
      map(clientIndexArray => clientIndexArray.length)
    );


  /**
   * This is the current client object.
   */
  currentClientIndex$: Observable<ClientIndexAHubVO> =
    this.clientIndexs$.pipe(filter(clientIndexes => clientIndexes != undefined && clientIndexes.length > 0),
      combineLatest(this.currentClientId$.pipe(startWith(-1)), (clientIndexList, currentClientId) => ({ clientIndexList, currentClientId })),
      /**
       * delay(0) Added in order to prevent ExpressionChangedAfterChecked errors!
       * Followed advice from: https://blog.angular-university.io/angular-debugging/
       */
      delay(0),
      map(({ clientIndexList, currentClientId }) => clientIndexList != undefined && clientIndexList.length > 0 ?
        this.clientIndexFind(clientIndexList, currentClientId)
        : undefined));



  constructor(
    private router: Router,
    private dialogService: DialogService
  ) { }


  /**
   * Empty On init to ensure @Hark decorator works for an AOT build
   */
  ngOnInit() {
    // This is intentional
  }

  /**
   * Empty On destroy to ensure @Hark decorator works for an AOT build
   */
  ngOnDestroy() { }

  showClientDetails() { }

  /**
   * Function which will give the user the option to select a new client
   */
  switchClient() {

    //Get the current indexes
    let indexes: ClientIndexAHubVO[] = StoreAccess.dataGet(aHubStatePermanentUserClientIndexs);

    //If we have not indexes or the user only has a single client we won't let them swap!
    if (!indexes || indexes.length < 2) return;

    // We'll run a fecth to get the latest client index.
    // Normally stuff like this is updated.. but if its a new client it won't get picked up, as no
    // worklogs for unselected clients are picked up, and it dos'nt always have this user down as an affected id.
    StoreAccess.dispatch(AHubActions.userClientIndexsFetch(StoreAccess.dataGet(sessionUserId)));

    // Open a new list dialog to get the correct export to assign the new distribution too.
    let dialogRef: Observable<any> = this.dialogService.selectSingleListModalDialogOpen("Switch client to manage", "Clients", this.clientIndexs$, StoreAccess.dataGet(sessionClientId), undefined, undefined, undefined, false);

    // Listen for the users selection.
    dialogRef.pipe(
      takeUntil(componentDestroyStream(this)))
      .subscribe(result => {

        // Did the user select any data? If not, then stop here.
        if (result === undefined)
          return;

        // Now we need to get the users selected client id.
        let selectedClientId: number = result;

        // Stop here if the user has actually not changed the client.
        if (selectedClientId == StoreAccess.dataGet(sessionClientId))
          return;

        // Clear the bulk transfers.
        StoreAccess.dispatch(ComponentActions.componentBulkTransferRequestsDataClear());

        // Call the action to set the current client.
        StoreAccess.dispatch(AppActions.sessionClientIdSet(selectedClientId));

        // Go get the entity permissions for the new client/user
        // StoreAccess.dispatch(AppActions.entityPermissionsFetch());

        // Return back to the homepage so the user can start again.
        AppRoutingNavigation.navigateWelcome(this.router, false);
      })
  }

  /**
   * Find a client index from a list.
   */
  private clientIndexFind(clientIndexList: ClientIndexAHubVO[], clientId: number): ClientIndexAHubVO {

    // Basically find the first instance and return it.
    return clientIndexList.find((clientIndex: ClientIndexAHubVO) => clientIndex.id == clientId)
  }
}

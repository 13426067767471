import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';


/**
 * The string cannot be empty.
 */
export function noWhitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true };
    };
}

@Directive({
    selector: '[noWhitespace]',
    providers: [{ provide: NG_VALIDATORS, useExisting: NoWhitespaceValidatorDirective, multi: true }]
})
export class NoWhitespaceValidatorDirective implements Validator {

    validate(control: AbstractControl): { [key: string]: any } {
        return noWhitespaceValidator()(control);
    }
}
import { Observable ,  of } from 'rxjs';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Injectable } from '@angular/core';

import { StoreAccess } from 'store/store-access';
import { DialogService } from 'modules/common/dialogs/dialog.service';
import { viewFormStateFormStatesDirty, viewComponentStateFormStatesDirty } from 'selector/view/view-page-state.selector';
import { ViewActions } from 'actions/view.actions';

@Injectable()
/**
 * Unsaved changes guard which can check if there are uncomitted form changes before we perform some code
 */
export class UnsavedChangesComponentCheck {

    constructor(private dialogService: DialogService) { }

    /**
     * This function will check if there are unsaved changes.
     * An observable is responded with true if the action can continue as there is no dirty data or the user has confirmed to continue.
     * False if the data is dirty and should not be action
     */
    public unsavedChangesGuard(): Observable<boolean> {

        //Get the dirty forms which are currently on view
        let dirtyForms = StoreAccess.dataGet(viewFormStateFormStatesDirty);
        let dirtyComponenets = StoreAccess.dataGet(viewComponentStateFormStatesDirty);

        // Is the currently selected distribution groups data pristine?
        if (dirtyForms.length == 0 || dirtyComponenets.length == 0) {

            //We have no changes to we will return an observable of false
            if ((!dirtyForms || dirtyForms.length <= 0) && (!dirtyComponenets || dirtyComponenets.length <= 0))
                return of(true);
        }

        // If we get here then we need to ask the user if they want to leave without changes we will just return the observable
        let dialogueResultObservable$ = this.dialogService.confirmDialogOpen("Discard Changes?", 'There are unsaved changes do you want discard changes?', 'Discard', 'Cancel');

        //Listen for the dialogue close so that we can allow other routes to call this
        dialogueResultObservable$.subscribe((result) => {

            // Did the user click "Yes"/"Discard"?
            if (result) {

                // Get a list of the ids we need to reset.
                let resetIds: string[] = dirtyForms.map(form => form.formId);

                // Now make the call to force the reset of the forms.
                StoreAccess.dispatch(ViewActions.formStateForceResetByIds(resetIds));
            }
        });

        //Return the dialogue result observable
        return dialogueResultObservable$;
    }
}

/**
 * Holds the state of the common tree component.
 * By having a seperate component state it can be re-used across views.
 * Whilst keeping the view state values seperate prevent cross view contamination.
 *
 * Currently the store only supports single tree storage, this could be expanded with an index to allow for multiple tree component use.
 */

import { ComponentActions } from 'actions/component.actions';
import { Utils } from 'app/modules/common/utils';
import { ActionString, ActionStringNumber } from 'app/store/actions/types/common.action-types';
import { ActionComponentDataSetProductSearchIdsSet } from 'app/store/actions/types/component.action-types';
import { MapStorageUtil } from 'app/store/map-storage.util';
import { MapStorage } from 'app/store/map-storage.vo';
import { DataSetProductsComponentsVO } from 'app/valueObjects/component/data-set-products.component.vo';
import { tassign } from 'modules/common/type-assign.util';
import { createReducer } from 'reducers/reducer-util';
import { Reducer } from 'redux';

/**
 * ----------------------------------
 * View State interface
 * ----------------------------------
 */
export interface ComponentDataSetProductsState {
    componentMap: MapStorage<DataSetProductsComponentsVO>;
}

/**
 * ----------------------------------
 * Initial State
 * ----------------------------------
 */
export const ComponentDataSetProductsInitialState: ComponentDataSetProductsState = {
    componentMap: MapStorageUtil.mapStorageCreate()
};

/**
 * ----------------------------------
 * View State Reducer
 * ----------------------------------
 */


/**
* Set the data set id for the component specified
*/
export const dataSetProductComponentRemove = (state: ComponentDataSetProductsState, action: ActionString) => {

    //Get the component id from the action
    const componentId = action.string;

    //Set the data set item into this list
    return tassign(state, { componentMap: MapStorageUtil.mapStorageRemove(state.componentMap, componentId) });
}

/**
* Set the data set category id for the component specified
*/
export const dataSetProductComponentDataSetIdSet = (state: ComponentDataSetProductsState, action: ActionStringNumber) => {

    //Get the component id from the action
    const componentId = action.string;

    //Get the data set product item
    const dataSetProductItem = dataSetProductComponentById(state.componentMap, componentId);

    //Set the data set id
    dataSetProductItem.dataSetId = action.number;

    //Set the data set item into this list
    return tassign(state, { componentMap: MapStorageUtil.mapStorageSet(state.componentMap, componentId, dataSetProductItem) });
}

/**
* Set the data set category id for the component specified
*/
export const dataSetProductComponentDataSetCategoryIdSet = (state: ComponentDataSetProductsState, action: ActionStringNumber) => {

    //Get the component id from the action
    const componentId = action.string;

    //Get the data set product item
    const dataSetProductItem = dataSetProductComponentById(state.componentMap, componentId);

    //Set the data set id
    dataSetProductItem.dataSetCategoryId = action.number;

    //Set the data set item into this list
    return tassign(state, { componentMap: MapStorageUtil.mapStorageSet(state.componentMap, componentId, dataSetProductItem) });
}

/**
* Set the data set category selected product id for the component specified
*/
export const dataSetProductComponentDataSetCategorySelectedProductIdSet = (state: ComponentDataSetProductsState, action: ActionStringNumber) => {

    //Get the component id from the action
    const componentId = action.string;

    //Get the data set product item
    const dataSetProductItem = dataSetProductComponentById(state.componentMap, componentId);

    //Set the data set id
    dataSetProductItem.dataSetCategorySelectedProductId = action.number;

    //Set the data set item into this list
    return tassign(state, { componentMap: MapStorageUtil.mapStorageSet(state.componentMap, componentId, dataSetProductItem) });
}


/**
 * Set the product search results for a data set products component
 */
export const dataSetProductComponentSearchSet = (state: ComponentDataSetProductsState, action: ActionComponentDataSetProductSearchIdsSet) => {

    //Get the component id from the action
    const componentId = action.componentId;

    //Get the data set product item
    const dataSetProductItem = dataSetProductComponentById(state.componentMap, componentId);

    //Update the search products
    dataSetProductItem.dataSetSearchProducts = action.dataSetProductSearch;

    //Set the data set item into this list
    return tassign(state, { componentMap: MapStorageUtil.mapStorageSet(state.componentMap, componentId, dataSetProductItem) });
}

/**
 * Remove the product search results for a data set
 */
export const dataSetProductComponentSearchDelete = (state: ComponentDataSetProductsState, action: ActionString) => {

    //Get the component id from the action
    const componentId = action.string;

    //Get the data set product item
    const dataSetProductItem = dataSetProductComponentById(state.componentMap, componentId);

    //Update the search products
    dataSetProductItem.dataSetSearchProducts = undefined;

    //Set the data set item into this list
    return tassign(state, { componentMap: MapStorageUtil.mapStorageSet(state.componentMap, componentId, dataSetProductItem) });
}


/**
 * ----------------------------------
 * Utils
 * ----------------------------------
 */

/**
 * Get the object from the list with the id, if we don't have one we will create one
 *
 * @param map
 * @param id
 */
const dataSetProductComponentById = (map: MapStorage<DataSetProductsComponentsVO>, id: string): DataSetProductsComponentsVO => {

    //Get the value from the map
    let valueFromMap = map ? MapStorageUtil.mapStorageGet(map, id) : undefined;

    //No map
    if (!valueFromMap) {

        //Create a new object with default values set
        valueFromMap = {
            componentId: id,
            dataSetId: -1,
            dataSetCategoryId: -1,
            dataSetCategorySelectedProductId: undefined,
            dataSetSearchProducts: undefined
        };
    }
    else {
        valueFromMap = Utils.clone(valueFromMap); //Clone the object so we don't affct the orginal object
    }

    //Return the value from the map
    return valueFromMap;
}


/**
 * ----------------------------------
 * Reducers Mapping
 * ----------------------------------
 */

/**
 * Reducers handlers object ... match actions to the handler functions
 */
const reducerHandlers = {};

/**
 * Map the actions to the reducer functions this will allow us to call the reducer
 */
reducerHandlers[ComponentActions.COMPONENT_DATA_SET_PRODUCTS_REMOVE] = dataSetProductComponentRemove;
reducerHandlers[ComponentActions.COMPONENT_DATA_SET_PRODUCTS_DATA_SET_ID_SET] = dataSetProductComponentDataSetIdSet;
reducerHandlers[ComponentActions.COMPONENT_DATA_SET_PRODUCTS_DATA_SET_CATEGORY_ID_SET] = dataSetProductComponentDataSetCategoryIdSet;
reducerHandlers[ComponentActions.COMPONENT_DATA_SET_PRODUCTS_DATA_SET_CATEGORY_SELECTED_PRODUCT_ID_SET] = dataSetProductComponentDataSetCategorySelectedProductIdSet;
reducerHandlers[ComponentActions.COMPONENT_DATA_SET_PRODUCTS_SEARCH_SET] = dataSetProductComponentSearchSet;
reducerHandlers[ComponentActions.COMPONENT_DATA_SET_PRODUCTS_SEARCH_REMOVE] = dataSetProductComponentSearchDelete;


/**
 * Create a reducers based on the reducers handlers
 */
export const ComponentDataSetProductsStateReducer: Reducer<ComponentDataSetProductsState> = createReducer(ComponentDataSetProductsInitialState, reducerHandlers);

/**
 * Check if this reducer can handle the function specified
 */
export const ComponentDataSetProductsStateHasHandler = (actionType: string): boolean => reducerHandlers.hasOwnProperty(actionType);

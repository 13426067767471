import { AppGlobalErrorHandler } from 'app/app-global-error-handler';

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  name: "ALPHA",
  envName: "ALPHA",
  production: false,
  aHubApi: {
    domain: 'https://api.alpha.ahub.harksolutions.net',
    basePath: '/api/latest',
    aworkbookApiBasePath: '/app'
  },
  knowledgeBasePath: 'https://support.ahub.cloud',
  errorHandler: AppGlobalErrorHandler
};

console.log('ALPHA');

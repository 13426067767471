import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn, Validators } from '@angular/forms';


/**
 * The string cannot start with a number.
 */
export function lowerCaseStartValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        const forbidden = /^[A-Z]+/g.test(control.value);
        return forbidden ? { 'lowerCaseStart': { value: control.value } } : null;
    };
}

@Directive({
    selector: '[lowerCaseStart]',
    providers: [{ provide: NG_VALIDATORS, useExisting: LowerCaseStartValidatorDirective, multi: true }]
})
export class LowerCaseStartValidatorDirective implements Validator {

    validate(control: AbstractControl): { [key: string]: any } {
        return lowerCaseStartValidator()(control);
    }
}
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ComponentFactory } from '@angular/core';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { filter, takeUntil, delay } from 'rxjs/operators';
import { componentDestroyStream, Hark } from '../../hark.decorator';
import { Utils } from '../../utils';

@Component({
  selector: 'app-list-tree',
  templateUrl: './list-tree.component.html',
  styleUrls: ['./list-tree.component.css']
})
@Hark()
export class ListTreeComponent implements OnInit {

  @ViewChild('tree', {
    static: true
  }) tree;




  // sourceList items must have an 'ancestry' property (e.g. ancestry: ',1,12') and a label property
  @Input() sourceList$: Observable<any[]>;

  @Input() rootNodeLabel?: string;

  /**
   * The name of the node label property to display.
   */
  @Input() nodeLabelProperty = "label";

  // tree direction (e.g. ltr: left to right OR rtl: right to left)
  @Input() treeDirection = 'ltr';

  // Lets have a function which would allows us to sort our list tree sibling nodes
  @Input() siblingSortFunction?: (list: any[]) => any[];

  @Input() externallySelectItemById$?: BehaviorSubject<number> = null;

  // Component factory that generates component for displaying objects in our list - use instead of LabelFunction.
  @Input() componentFactory?: ComponentFactory<any>;

  // Parameter name on the generated component to which to pass the item in - use instead of Label Function.
  @Input() componentVOParamName?: string;

  /**
   * Emitter an item from the list
   */
  @Output()
  selectedItem: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Currently selected node
   */
  selectedNode: ListFlatNode = undefined;

  /**
   * The parent nodes for the selected node.
   */
  parentNodes: ListFlatNode[] = undefined;

  nestedNodeMap = new Map<ListNode, ListFlatNode>();

  /**
   * Control for the tree
   */
  flatTreeControl: FlatTreeControl<ListFlatNode>;

  treeFlattener: MatTreeFlattener<ListNode, ListFlatNode>;

  //Function which will identify if a category is the root or not
  isRootFunc = (listItem: any) => (!listItem.ancestry || listItem.ancestry.length === 0);

  /**
   * Control for the data source
   */
  flatDataSource: MatTreeFlatDataSource<ListNode, ListFlatNode>;

  private readonly transformer = (node: ListNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);

    const flatNode = existingNode && existingNode.listItem && existingNode.listItem.id === node.listItem.id
      ? existingNode
      : new ListFlatNode(false, node.listItem, 0, false, false);

    flatNode.listItem = node.listItem;
    flatNode.level = level;
    flatNode.expandable = !!node.children && node.children.length > 0;
    flatNode.isEmpty = node.listItem != null;

    this.nestedNodeMap.set(node, flatNode);

    return flatNode;
  }

  hasChild = (_: number, node: ListFlatNode) => node.expandable;
  constructor() {

    this.flatTreeControl = new FlatTreeControl<ListFlatNode>(node => node.level, node => node.expandable);

    this.treeFlattener = new MatTreeFlattener(this.transformer, node => node.level, node => node.expandable, node => node.children);

    this.flatDataSource = new MatTreeFlatDataSource(this.flatTreeControl, this.treeFlattener);

  }

  treeReady$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  ngOnInit() {

    //Subscribe to the list of categories
    this.sourceList$.pipe(
      Utils.isNotNullOrUndefined(),
      filter(sourceList => sourceList.length > 0),
      takeUntil(componentDestroyStream(this))
    ).subscribe(sourceList => {

      //Sort the categories into ancestry order, this way we will be able to construct our tree in a sensible way
      sourceList.sort((a, b) => a.ancestry === b.ancestry ? 0 : (a.ancestry > b.ancestry) ? 1 : -1);

      // Do we have some sorting to do for the siblings within
      if (this.siblingSortFunction) {
        sourceList = this.siblingSortFunction(sourceList);
      }

      //Find the root category
      const rootItem: any = sourceList.find(listItem => this.isRootFunc(listItem));

      if (rootItem) {
        rootItem.label = (this.rootNodeLabel) ? this.rootNodeLabel : rootItem.label;
      }

      // Build the tree from the source list.
      this.buildTreeFromAncestry(sourceList, rootItem)

      // The tree is now ready.
      this.treeReady$.next(true);
    });

    // Watch for when the tree is ready.
    this.treeReady$.pipe(
      Utils.isNotNullOrUndefined(),
      takeUntil(componentDestroyStream(this)),
      filter(ready => ready === true),
      delay(50)
    ).subscribe(ready => {

      // Expan so we can see all of the selected nodes parent nodes.
      this.parentNodes.forEach(parentNode => this.tree.treeControl.expand(parentNode));
    });

    /**
     * Has the externallySelectItemById been set by the parent?
     */
    if (this.externallySelectItemById$) {

      // Lets keep an eye on it and set the selected node accordingly
      this.externallySelectItemById$.pipe(
        Utils.isNotNullOrUndefined(),
        takeUntil(componentDestroyStream(this)),
      ).subscribe(selectId => {
        const selectedTreeNode: ListNode = this.getNodeByItemId(selectId, this.flatDataSource.data);
        this.selectedNode = this.nestedNodeMap.get(selectedTreeNode);
      })
    }
  }

  /**
   * Recursively search tree for node matching the specified id
   */
  getNodeByItemId(selectId: number, nodesToSearch: ListNode[]): ListNode {
    let foundNode: ListNode;
    nodesToSearch.some(node => {
      if (node.listItem.id === selectId) {
        foundNode = node;
        return true;
      }
      foundNode = this.getNodeByItemId(selectId, node.children);
      return (foundNode);
    })

    return foundNode;
  }


  /**
   * Create the tree structure from the source list we have been passed in.
   * 
   * @param sourceList      The source list to create the structure from.
   * @param rootItem        The root item in the tree.
   */
  buildTreeFromAncestry(sourceList: any[], rootItem: any) {

    //Get the root category node
    const rootListItemNode: ListNode = { listItem: rootItem, children: [] };


    // Set up the note to select. Default to the root node.
    let nodeToSelect = rootListItemNode;

    // Set up the parent node id's. This is defaulted to the root id
    // so that we at least open the top level if nothing else selected.
    let selectedParentNodeIds = [rootItem.id];

    // Set up a map of the nodes created so we can open the correct nodes later.
    const nodes = new Map<number, ListNode>();

    // Add the root node to the list.
    nodes.set(rootItem.id, rootListItemNode);

    //Loop through each of the categories, filtering out the root
    sourceList
      .filter(listItem => !this.isRootFunc(listItem))
      .forEach(listItem => {

        //sourceList items must have an ancestry and label property...lets check and error if we dont
        if (!listItem.ancestry || !listItem.label) {
          throwError('Objects used to build generic list tree must have ancestry and label properties')
        }

        //Split this into multiple parts
        const ancestryParts = listItem.ancestry.split(",").filter(part => part.length > 0);

        // Get the last ancestry part. This is the parents id.
        const parentId = parseInt(ancestryParts[ancestryParts.length - 1]);

        //This is the parent node which the data will be held in
        const parentNode: ListNode = this.findParentItem(rootListItemNode, parentId);

        // Create a new node
        const newNode: ListNode = {
          listItem,
          children: []
        };

        //If this is the currently selected item, set it
        if (this.externallySelectItemById$ && this.externallySelectItemById$.getValue() === listItem.id) {
          nodeToSelect = newNode;
          selectedParentNodeIds = ancestryParts;
        }

        // Add the node to the list.
        nodes.set(listItem.id, newNode);

        //Add the node to the parents children
        parentNode.children.push(newNode);
      });

    // Put the root list node into the data source.
    this.flatDataSource.data = [rootListItemNode];

    // Get the selected node.
    const selectedNode = this.nestedNodeMap.get(nodeToSelect);

    // Set up the list of parent nodes.
    this.parentNodes = selectedParentNodeIds.map(parentId => this.nestedNodeMap.get(nodes.get(parseInt(parentId))));

    //Set the selected node
    this.selectNodeSet(selectedNode);
  }

  ngOnDestroy() { };


  /**
   * Get the label for a node.
   * 
   * @param node      The node whos label we want.
   */
  nodeNameGet(node): string {
    return node.listItem[this.nodeLabelProperty];
  }

  /**
 * Set the currently selected node
 *
 * @param node    Node to select
 */
  selectNodeSet(node: ListFlatNode) {

    if (node && node.listItem) {
      this.selectedItem.emit(node.listItem);
    }

    // If the parent is using the this.externallySelectItemById$ we should update that too
    if (this.externallySelectItemById$) {
      this.externallySelectItemById$.next(node.listItem.id);
    }

    //Set the passed node
    this.selectedNode = node;
  }

  /**
   * This function will find a parent category from a category tree.
   *
   * @param parentNode        The parent category to look through.
   * @param parentId          The id of the parent to look for.
   */
  private findParentItem(parentNode: ListNode, parentId: number): ListNode {

    // Return the parent node passed in if it's null, or the id matches.
    if (!parentNode || parentNode.listItem.id === parentId) {
      return parentNode;
    }

    //OK it's not in our list then we will look in our children
    for (const childNode of parentNode.children) {
      //Get the child node from the parent and ask recursivly for the specific parent id which we are asking about
      const foundChildNode = this.findParentItem(childNode, parentId);

      //We found the node we were looking for? splendid return it!
      if (foundChildNode) {
        return foundChildNode;
      }
    }
  }
}


/**
 * Category node which represents a node within the tree
 */
class ListNode {
  listItem: any;
  children: ListNode[];
}

/**
 * Flat node with expandable and level information
 */
export class ListFlatNode {
  constructor(
    public expandable: boolean,
    public listItem: any,
    public level: number,
    public isEmpty: boolean,
    public selected: false) { }
}

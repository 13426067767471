import { unionBy } from 'lodash';
/**
 * Action Types
 */
import { ActionBoolean, ActionNumber, ActionNumberArray, ActionStringNumber, ActionBooleanNumber } from 'actions/types/common.action-types';
import { ActionExtractAHubVOs, ActionExtractDefinitionPropertyAHubVOs, ActionExtractIndexAHubVOs, ActionProductAHubVOs, ActionProductAssetViewVO, ActionExtractDefinitionPropertyAHubVO, ActionExtractDefinitionPropertyAllocationObjectVOArray } from 'actions/types/library.action-types';
/**
 * Actions
 */
import { ViewActions } from 'actions/view.actions';
import { tassign } from 'modules/common/type-assign.util';
import { createReducer } from 'reducers/reducer-util';
import { Action, Reducer } from 'redux';
import { IdListUtil } from 'store/id-list.util';
import { ListUtil } from 'store/list.util';
import { List } from 'store/list.vo';
/**
 * Value objects
 */
import { ExtractDefinitionPropertyAHubVO } from 'valueObjects/ahub/library/extract-definition-property.ahub.vo';
import { ExtractIndexAHubVO } from 'valueObjects/ahub/library/extract-index.ahub.vo';
import { ExtractAHubVO } from 'valueObjects/ahub/library/extract.ahub.vo';
import { ProductAHubVO } from 'valueObjects/ahub/library/product.ahub.vo';
import { ProductAssetViewVO } from 'valueObjects/view/product-asset-view.vo';
import { ExtractDefinitionPropertyAllocationObjectVO } from 'app/valueObjects/view/extract-definition-property-allocation.view.vo';
import { Utils } from 'app/modules/common/utils';


/**
 * ----------------------------------
 * View State interface
 * ----------------------------------
 */

export interface ViewLibraryExtractsState {
    viewLibraryExtractsDefinitionId: number;
    extractDefinitionPropertyId: number;
    extractDefinitionProperties: ExtractDefinitionPropertyAHubVO[];
    extractDefinitionProductIdentifierProperty: ExtractDefinitionPropertyAHubVO;
    extractDefinitionPropertiesEdited: boolean;
    viewLibraryExtractsId: number;
    productSelectedIds: number[];
    displayedColumns: ExtractDefinitionPropertyAllocationObjectVO[];
    showAssetsState: boolean;
    showColumnsState: boolean;
    assetSwapSource: ProductAssetViewVO;
    extractProductsChangeSet: List<ProductAHubVO>;
    extractDefinitionExtractIndex: ExtractIndexAHubVO[];
    extractConflicts: ExtractAHubVO[];
    extractAllocationId: number;
    extractDocumentSettingsAllocationIdOpen: number[]
};

/**
 * ----------------------------------
 * Initial State
 * ----------------------------------
 */

export const ViewLibraryExtractsInitialState: ViewLibraryExtractsState = {
    viewLibraryExtractsDefinitionId: -1,
    extractDefinitionPropertyId: -1,
    extractDefinitionProperties: [],
    extractDefinitionProductIdentifierProperty: undefined,
    extractDefinitionPropertiesEdited: false,
    viewLibraryExtractsId: -1,
    productSelectedIds: [],
    displayedColumns: [],
    showAssetsState: false,
    showColumnsState: false,
    assetSwapSource: undefined,
    extractProductsChangeSet: ListUtil.listCreateEmpty(),
    extractDefinitionExtractIndex: [],
    extractConflicts: [],
    extractAllocationId: -1,
    extractDocumentSettingsAllocationIdOpen: []
};

/**
 * ----------------------------------
 * View State Reducer
 * ----------------------------------
 */

/**
 * Set the selected extract definition id.
 */
export const extractDefinitionSelectedIdSet = (state: ViewLibraryExtractsState, action: ActionNumber) => {
    return tassign(state, { viewLibraryExtractsDefinitionId: action.number });
}

/**
 * Set the selected extract definition id.
 */
export const extractDefinitionPropertySelectedIdSet = (state: ViewLibraryExtractsState, action: ActionNumber) => {
    return tassign(state, { extractDefinitionPropertyId: action.number });
}


/**
 * Set the extract definition properties.
 */
export const extractDefinitionPropertySet = (state: ViewLibraryExtractsState, action: ActionExtractDefinitionPropertyAHubVOs) => {

    //Update the extract definition properties in the store
    return tassign(state, { extractDefinitionProperties: action.extractDefinitionProperties, extractDefinitionPropertiesEdited: false });
}

/**
 * Set the extract definition properties.
 */
export const extractDefinitionProductIdentifierPropertySet = (state: ViewLibraryExtractsState, action: ActionExtractDefinitionPropertyAHubVO) => {

    //Update the extract definition properties in the store
    return tassign(state, { extractDefinitionProductIdentifierProperty: action.extractDefinitionProperty, extractDefinitionPropertiesEdited: false });
}

/**
 * Set the extract definition properties
 */
export const extractDefinitionPropertyUpdate = (state: ViewLibraryExtractsState, action: ActionExtractDefinitionPropertyAHubVO) => {

    //Update the extract definition properties in the store
    return tassign(state, { extractDefinitionProperties: unionBy([action.extractDefinitionProperty], state.extractDefinitionProperties, 'productPropertyAllocId'), extractDefinitionPropertiesEdited: true });
}

/**
 * Set the extract definition properties
 */
export const extractDefinitionPropertiesUpdate = (state: ViewLibraryExtractsState, action: ActionExtractDefinitionPropertyAHubVOs) => {
    return tassign(state, { extractDefinitionProperties: action.extractDefinitionProperties, extractDefinitionPropertiesEdited: true });
}

/**
 * Delete the extract definition properties
 */
export const extractDefinitionPropertyDelete = (state: ViewLibraryExtractsState, action: ActionExtractDefinitionPropertyAHubVOs) => {

    //Take the current state and filter out the properties witin the action
    let updated = state.extractDefinitionProperties.filter(property => {

        //We Will look through the items in the action to see if this item is on the to remove list
        let item = action.extractDefinitionProperties.find(existing => (existing.productPropertyAllocId == property.productPropertyAllocId &&
            existing.extractDefinitionId == property.extractDefinitionId));

        //If item is undefined it means it wasn't on the to remove list ... therefore we want to keep it
        return !item;
    });

    //Set new new list with the requested items filtered out
    return tassign(state, { extractDefinitionProperties: updated, extractDefinitionPropertiesEdited: true });
}


/**
 * Set the selected extract id.
 */
export const extractSelectedIdSet = (state: ViewLibraryExtractsState, action: ActionNumber) => {
    return tassign(state, { viewLibraryExtractsId: action.number });
}

/**
 * Set the extract definition extract indexes
 */
export const extractDefinitionExtractIndexes = (state: ViewLibraryExtractsState, action: ActionExtractIndexAHubVOs) => {
    return tassign(state, { extractDefinitionExtractIndex: action.extractIndexes })
}

/**
 * Set the extract conflicts
 */
export const extractConflictsSet = (state: ViewLibraryExtractsState, action: ActionExtractAHubVOs) => {
    return tassign(state, { extractConflicts: action.extracts });
}

/**
 * Set the property selected id set
 */
export const productSelectedIdsSet = (state: ViewLibraryExtractsState, action: ActionNumberArray) => {
    return tassign(state, { productSelectedIds: action.numbers });
}

/**
 * Set the displayed columns
 */
export const displayedColumnsSet = (state: ViewLibraryExtractsState, action: ActionExtractDefinitionPropertyAllocationObjectVOArray) => {
    return tassign(state, { displayedColumns: action.allocs });
}

/**
 * Set the extract show assets state.
 */
export const extractShowAssetsStateSet = (state: ViewLibraryExtractsState, action: ActionBoolean) => {
    return tassign(state, { showAssetsState: action.boolean });
}

/**
 * Set the extract show columns state.
 */
export const extractShowColumnsStateSet = (state: ViewLibraryExtractsState, action: ActionBoolean) => {
    return tassign(state, { showColumnsState: action.boolean });
}

/**
 * Set the asset swap pending flag.
 */
export const assetsSwapSourceSet = (state: ViewLibraryExtractsState, action: ActionProductAssetViewVO) => {
    return tassign(state, { assetSwapSource: action.productAsset });
}

/**
 * Set the extract products change set list.
 */
const extractProductsChangeSetSet = (state: ViewLibraryExtractsState, action: ActionProductAHubVOs) => {
    return tassign(state, { extractProductsChangeSet: IdListUtil.listAppend(state.extractProductsChangeSet, action.products) });
}

/**
 * Clear extract products change set list.
 */
const extractProductsChangeSetClear = (state: ViewLibraryExtractsState, action: Action) => {
    return tassign(state, { extractProductsChangeSet: IdListUtil.listCreateEmpty() });
}

/**
 * Remove products from product change set by ids.
 */
const extractProductChangeSetRemoveByIds = (state: ViewLibraryExtractsState, action: ActionNumberArray) => {

    // Get existing store list, filter out any items that have a matching object id in the list supplied.
    return tassign(state, {
        extractProductsChangeSet: IdListUtil.listRemove(state.extractProductsChangeSet, action.numbers)
    });
}

/**
 * Set the extract allocation id.
 */
const extractAllocationIdSet = (state: ViewLibraryExtractsState, action: ActionNumber) => {

    // Set the extract allocation id in the store.
    return tassign(state, { extractAllocationId: action.number });
}

/**
 * Set the open state  of the id supplied
 */
const extractDocumentSettingsAllocationIdOpen = (state: ViewLibraryExtractsState, action: ActionBooleanNumber) => {

    const changedId = action.number;

    //Id's of the open things
    let openIds = Utils.clone(state.extractDocumentSettingsAllocationIdOpen) as number[];

    //Get all the id's without the specified id
    openIds = openIds.filter(id => id !== changedId);

    //Is this open? Then add it to the list
    if (action.boolean) {
        openIds.push(changedId);
    }

    //Set the list of open ids
    return tassign(state, { extractDocumentSettingsAllocationIdOpen: openIds });
}

/**
 * ----------------------------------
 * Reducers Mapping
 * ----------------------------------
 */

/**
 * Reducers handlers object ... match actions to the handler functions
 */
let reducerHandlers = {};

/**
 * Map the actions to the reducer functions this will allow us to call the reducer.
 */
reducerHandlers[ViewActions.LIBRARY_EXTRACTS_DEFINITION_SELECTED_ID_SET] = extractDefinitionSelectedIdSet;
reducerHandlers[ViewActions.LIBRARY_EXTRACT_DEFINITION_PROPERTY_SELECTED_ID_SET] = extractDefinitionPropertySelectedIdSet;
reducerHandlers[ViewActions.LIBRARY_EXTRACT_DEFINITION_PROPERTY_SET] = extractDefinitionPropertySet;
reducerHandlers[ViewActions.LIBRARY_EXTRACT_DEFINITION_PRODUCT_IDENTIFIER_PROPERTY_SET] = extractDefinitionProductIdentifierPropertySet;
reducerHandlers[ViewActions.LIBRARY_EXTRACT_DEFINITION_PROPERTY_UPDATE] = extractDefinitionPropertyUpdate;
reducerHandlers[ViewActions.LIBRARY_EXTRACT_DEFINITION_PROPERTIES_UPDATE] = extractDefinitionPropertiesUpdate;
reducerHandlers[ViewActions.LIBRARY_EXTRACT_DEFINITION_PROPERTY_DELETE] = extractDefinitionPropertyDelete;
reducerHandlers[ViewActions.LIBRARY_EXTRACTS_SELECTED_ID_SET] = extractSelectedIdSet;
reducerHandlers[ViewActions.LIBRARY_EXTRACT_DEFINITION_EXTRACT_INDEX_SET] = extractDefinitionExtractIndexes;
reducerHandlers[ViewActions.LIBRARY_EXTRACT_CONFLICTS_SET] = extractConflictsSet;
reducerHandlers[ViewActions.LIBRARY_EXTRACT_PRODUCT_SELECTED_IDS_SET] = productSelectedIdsSet;
reducerHandlers[ViewActions.LIBRARY_EXTRACT_DISPLAYED_COLUMNS_SET] = displayedColumnsSet;
reducerHandlers[ViewActions.LIBRARY_EXTRACT_SHOW_ASSETS_SET] = extractShowAssetsStateSet;
reducerHandlers[ViewActions.LIBRARY_EXTRACT_SHOW_COLUMNS_SET] = extractShowColumnsStateSet;
reducerHandlers[ViewActions.LIBRARY_EXTRACT_ASSET_SWAP_SOURCE_SET] = assetsSwapSourceSet;
reducerHandlers[ViewActions.EXTRACT_PRODUCTS_CHANGE_SET_SET] = extractProductsChangeSetSet;
reducerHandlers[ViewActions.EXTRACT_PRODUCTS_CHANGE_SET_CLEAR] = extractProductsChangeSetClear;
reducerHandlers[ViewActions.EXTRACT_PRODUCTS_CHANGE_SET_REMOVE_BY_IDS] = extractProductChangeSetRemoveByIds;
reducerHandlers[ViewActions.LIBRARY_EXTRACT_ALLOCATION_ID_SET] = extractAllocationIdSet;
reducerHandlers[ViewActions.LIBRARY_EXTRACT_ALLOCATION_DOCUMENT_SETTING_OPEN_CLOSE] = extractDocumentSettingsAllocationIdOpen;


/**
 * Create a reducers based on the reducers handlers
 */
export const ViewLibraryExtractsStateReducer: Reducer<ViewLibraryExtractsState> = createReducer(ViewLibraryExtractsInitialState, reducerHandlers);

/**
 * Check if this reducers can handel the function specified
 */
export const ViewLibraryExtractsStateHasHandler = (actionType: string): boolean => reducerHandlers.hasOwnProperty(actionType);

import { Component, ComponentFactory, ViewContainerRef, ViewChild, ComponentRef, AfterViewInit } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { GridEditorParamsInterface } from '../grid-editor-params-interface';

@Component({
  selector: 'app-grid-editor-component',
  template: '<div #placeholder></div>',
  styleUrls: ['./grid-editor-component.component.css']
})
export class GridEditorComponentComponent implements ICellEditorAngularComp, AfterViewInit {


  @ViewChild('placeholder', {
    read: ViewContainerRef,
    static: true
  }) placeholder: ViewContainerRef;

  /**
   * Parameters for the component
   */
  params: ICellEditorParams = undefined;

  /**
   * Parameters for the component
   */
  paramsWithCustom: GridEditorComponentParams = undefined;

  /**
   * The component factory passed in to create the components for object display.
   */
  componentFactory: ComponentFactory<ICellEditorAngularComp>; //Class?

  /**
   * The created component in the wrapper.
   */
  public createdComponent: ComponentRef<ICellEditorAngularComp>;

  /**
   * Consturctor with the view container in
   */
  constructor() {
    // This is intentional
  }

  /**
   * Init of the renderer
   */
  agInit(params: any): void {

    //Set the parameters
    this.params = params as ICellEditorParams;
    this.paramsWithCustom = params as GridEditorComponentParams;
  }

  ngAfterViewInit(): void {

    // Create a component using the factory supplied in our wrapper.
    if (this.paramsWithCustom.factoryObject) {

      //Set the factory
      this.componentFactory = this.paramsWithCustom.factoryObject;

      //Create the component
      this.createdComponent = this.placeholder.createComponent(this.componentFactory);
    }

    //Do we have a created component
    if (this.createdComponent && this.createdComponent.instance && this.createdComponent.instance.agInit) {

      //Call the agInit with its parameters
      this.createdComponent.instance.agInit(this.params);
    }
  }

  /**
   * Get the value from the componenet
   */
  getValue() {

    //If we have a created componenet then we will will return the value from the component
    return (this.createdComponent && this.createdComponent.instance && this.createdComponent.instance.getValue) ? this.createdComponent.instance.getValue() : undefined;
  }

  isPopup?(): boolean {

    //If we have a created componenet then we will will return the value from the component
    return (this.createdComponent && this.createdComponent.instance && this.createdComponent.instance.isPopup) ? this.createdComponent.instance.isPopup() : false;
  }

  /**
   * Are we preventing editing before it starts?
   */
  isCancelBeforeStart?(): boolean {

    //If we have a created componenet then we will will return the value from the component
    return (this.createdComponent &&
      this.createdComponent.instance &&
      this.createdComponent.instance.isCancelBeforeStart) ? this.createdComponent.instance.isCancelBeforeStart() : false;
  }

  /**
   * Are we preventing editing after is happened
   */
  isCancelAfterEnd?(): boolean {

    //If we have a created componenet then we will will return the value from the component
    return (this.createdComponent &&
      this.createdComponent.instance &&
      this.createdComponent.instance.isCancelAfterEnd) ? this.createdComponent.instance.isCancelAfterEnd() : false;
  }

  /**
   * For single row editing what to do when we gain focus
   */
  focusIn?(): void {

    //If we have a created componenet then we will will call the focus function
    if (this.createdComponent &&
      this.createdComponent.instance &&
      this.createdComponent.instance.focusIn) this.createdComponent.instance.focusIn();
  }


  /**
   * For single row editing
   */
  focusOut?(): void {

    //If we have a created componenet then we will will call the focus function
    if (this.createdComponent &&
      this.createdComponent.instance &&
      this.createdComponent.instance.focusOut) {
      this.createdComponent.instance.focusOut();
    }
  }
}

/**
 * Parameters interface used to type the parameters passed to this class. This class should be extended if the user wishes to supply their own parameters
 * to their custom editor
 */
export interface GridEditorComponentParams extends GridEditorParamsInterface {
  factoryObject: ComponentFactory<ICellEditorAngularComp>;
}

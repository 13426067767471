import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, } from '@angular/common/http';
import { take, map } from 'rxjs/operators';
import { parseString } from 'xml2js';
import { RssReaderItemVO } from './rss-reader-item.vo';


const RESPONSE_TYPE_XML = 'text';


/**
 * This service allows the getting of items from an RSS Feed.
 */
@Injectable()
export class RssReaderService {


    constructor(private http: HttpClient) { }

    /**
     * This function will get an observable list of items from a RSS URL.
     * 
     * @param url               The URL to get the RSS feed from.
     */
    rssFeedGet(url: string): Observable<RssReaderItemVO[]> {

        // Set up the empty options to send.
        let options = {};

        // This ensures that we get the full response including headers.. not just the body which is what we get by default.
        options['observe'] = 'response';
        options['responseType'] = RESPONSE_TYPE_XML;

        // Make the request to get the RSS feed from the URL specified.
        return this.http.request<HttpResponse<string>>("GET", url, options)
            .pipe(
                take(1),
                map(response => response.body),
                map(data => {

                    // Set up the items array to return.
                    let items: any[];

                    // Parse the string we have recived.
                    parseString(data, { explicitArray: false }, (error, result) => {

                        // Did we get an error?
                        if (error) {

                            // Yes, so throw it.
                            throw new Error(error);
                        } else {

                            // Otherwise, get the rss channel items if we can.
                            items = (result != null && result.rss != null && result.rss.channel != null) ? result.rss.channel.item : [];
                        }
                    });

                    // Finally return the items we have found.
                    return items.map(item => {

                        // Get the content from the item.
                        let doc = new DOMParser().parseFromString(item["content:encoded"], 'text/html');

                        // Strip out the content from the HTML.
                        let content = doc.body.textContent || "";

                        // Create a new item to return.
                        let returnItem: RssReaderItemVO = {
                            category: item.category,
                            publishedDate: new Date(item.pubDate),
                            content: content,
                            creator: item["dc:creator"],
                            description: item.description,
                            link: item.link,
                            title: item.title
                        };

                        // Then return the item.
                        return returnItem;
                    })
                })
            );
    }
}
import { Component, OnInit, DoCheck, ViewChild, Output, Input, ComponentFactory, EventEmitter } from '@angular/core';
import { Subject, timer, Observable, of, BehaviorSubject } from 'rxjs';
import { takeUntil, filter, tap, take, map } from 'rxjs/operators';
import { componentDestroyStream, Hark } from '../../hark.decorator';
import { StoreAccess } from 'app/store/store-access';
import { viewNumberOfItemsPerRow } from 'app/store/selector/view/view-content-viewer.selector';

@Component({
  selector: 'app-infinity-scroller',
  templateUrl: './infinity-scroller.component.html',
  styleUrls: ['./infinity-scroller.component.css']
})
@Hark()
export class InfinityScrollerComponent implements OnInit {

  // Visible sub component containig the scrolling section.
  @ViewChild('infiniteList', { static: true }) infiniteListComponent: HTMLDivElement;
  @ViewChild('infiniteListContainer', { static: true }) infiniteListContainerComponent: HTMLDivElement;

  // Grid Size
  @Input()
  public largeGrid$: BehaviorSubject<boolean>;

  /**
   * Data array for our infinity scroller
   */
  @Input()
  public dataArray: Observable<any[]> = of([]);

  /**
   * Component factory for the renderer
   */
  @Input()
  public componentFactory: ComponentFactory<any>;

  /**
   * Name of the property in the component in the generated component
   */
  @Input()
  public componentVOParamName: string;

  /**
   * Name of the property in the data object which is the unique id!
   */
  @Input()
  public dataObjectIdProperty = "id";

  // Content direction Grid or Column
  @Input()
  isGrid = false;


  gridItemsPerRow$ = StoreAccess.dataGetObvs(viewNumberOfItemsPerRow);

  /**
   * Emitter to transmit changes
   */
  @Output()
  public dataInBufferChange = new EventEmitter<any[]>();

  /**
   * Count of the amount of elements which are currently in the row
   */
  elementsInRow = 0;

  /**
   * Viewable margin to preload when scrolling.
   */
  loadMargin = 1000;

  viewStart = 0;
  viewEnd = 1;
  indexCount = 0; // This is a reloction of a counting observable, used for display purposes - had issues with share.
  autoScroll = false; // Set to true when we are taking control of the scrolling, to prevent us scrolling trigging the scrolling functions!

  /**
   * Raw data, this will allow us to reflect it back later!
   */
  rawData: any[] = [];

  constructor() {
    // This is intentional
  }

  ngOnInit() {

    this.dataArray.pipe(
      takeUntil(componentDestroyStream(this)),
      map(a => a ? a.length : 0),
      filter(itemCount => itemCount > 0), // Once we get a positive value through ( > 0 ) we will use it to initilisae the scoll view
      take(1)) // Only do this once.
      .subscribe(itemCount => {
        this.indexCount = itemCount;
        this.viewEnd = Math.min(this.indexCount + 1, 50);
      });

    //Subscribe to the data stream so we can keep it all up to date!
    this.dataArray.pipe(takeUntil(componentDestroyStream(this)))
      .subscribe(dataRaw => {

        //Data has changed!

        //Update the index count for our items
        this.indexCount = dataRaw ? dataRaw.length : 0;

        //Set the raw data so we can get at it again later
        this.rawData = dataRaw;

        //The core data has changed so we should emit the buffer
        //to ensure any component relying on the buffer data has the most recent data
        this.emitCurrentBuffer();

        //Update the scroller view
        this.updateScrollView();
      });


    const scrollTidyStop: Subject<void> = new Subject<void>();
    this.autoScroll = true;

    timer(150, 150).pipe(
      takeUntil(scrollTidyStop),
      takeUntil(componentDestroyStream(this)),
      filter(timer => (this.infiniteListContainerComponent["nativeElement"].clientHeight !== 0)), // Make sure we have the container.
      tap(count => {
        if (count >= 499) { this.autoScroll = false }
      }), // Switch back on normal scrolling if we don;t fininsh auto scroll.
      take(500)) // Limit in case we somehow don't finish tidying ?
      .subscribe(subTimer => {

        //If the update view did'nt do anything, then its complete,  stop.
        if (!this.updateScrollView()) {
          scrollTidyStop.next();
          this.autoScroll = false;
        }
      });
  }

  /**
   * Fired whenever the screen is checked!
   */
  ngDoCheck() {

    //Update how many elements are in a row!
    this.updateElementsInRow();
  }

  ngOnDestroy() { }

  /**
   * Reset the scroller
   */
  public scrollerReset() {

    //Reset the start and end positions of the view
    this.viewStart = 0;
    this.viewEnd = Math.min(this.indexCount + 1, 50);

    //Call the update
    this.updateScrollView();

    //Reset the current scroll position
    this.infiniteListContainerComponent["nativeElement"].scrollTop = 0;
  }

  // Updates the number of items that are drawn on the component.. this improves performance of the app when dealing with 1000s of items.
  // We pre load items at the beginning and end of the list, and remove those which are too far beyond the visible portion.
  // Returns true if the view has been updated, consider repeating calls whilst true is returned, until false.
  updateScrollView(fromEvent = false): boolean {

    // If we are controlling the scroll , we can ignore on screen scroll events.
    if (fromEvent && this.autoScroll) {
      return false; // Ignore event.
    }

    //Update how many elements we have in a row
    this.updateElementsInRow();

    // Obtain list component.
    const nativeInfiniteList = this.infiniteListComponent["nativeElement"];
    const nativeInfiniteListContainer = this.infiniteListContainerComponent["nativeElement"];

    // Are we showing all the items in the list.
    if ((this.viewStart - this.viewEnd) >= this.indexCount) {
      return false;
    }

    const orgViewStart = this.viewStart;
    const orgViewEnd = this.viewEnd;

    const loadableAreaBegin: number = this.loadMargin;
    const unloadableAreaBegin: number = this.loadMargin * 4; // Start loading when less than 1000 , start unloading when more than 4000.


    //Get the top location of the scroller this will act as our visible start
    const topOfScoller = nativeInfiniteListContainer.scrollTop;
    const scrollHeight = nativeInfiniteList.scrollHeight;

    const loadableAreaEnd: number = topOfScoller + nativeInfiniteListContainer.clientHeight + this.loadMargin;
    const unloadAreaEnd: number = topOfScoller + nativeInfiniteListContainer.clientHeight + (this.loadMargin * 4);


    // CALCULATE THE START OF VIEW -------------------------------------------------------

    //Has the top of the scroller started entering into the space where we want to start unloading
    //items ( e.g. we have scrolled down the page and the first few things start to fall off the page )
    if (topOfScoller > unloadableAreaBegin) {

      //Increase the start index to start unloading the first row
      this.viewStart = this.viewStart + this.elementsInRow;
    }

    //Has the top of the scroller started entering into the space where we want to start loading items back in
    //( e.g. we have scrolled down the page and items have been removed from the start of the list  )
    if (topOfScoller < loadableAreaBegin) {

      //Decreace the view start so that we can load those item in
      this.viewStart = Math.max(this.viewStart - this.elementsInRow, 0);
    }


    // CALCULATE THE END OF VIEW -------------------------------------------------------

    // We are not showing everything... Does the content finish (too) close to the viewable area ?
    if (loadableAreaEnd > scrollHeight) {

      // We should show more.
      this.viewEnd = Math.max(Math.min(this.indexCount, this.viewEnd + this.elementsInRow), 50);
    }

    // We are not showing everything... Does the content finish (too) close to the viewable area ?
    if (unloadAreaEnd < scrollHeight) {

      // We should show more.
      this.viewEnd = Math.max(Math.min(this.indexCount, this.viewEnd - this.elementsInRow), 50);
    }



    //Change Detection --------------------------------------------------------------

    //Did the buffer change
    const bufferChanged = ((orgViewStart !== this.viewStart) || (orgViewEnd !== this.viewEnd));

    //The current buffer has changed so we might want to emit the changes!
    if (bufferChanged) {
      this.emitCurrentBuffer();
    }

    // Return an indication if the scroll area has changed.
    // True if it has changed.
    return bufferChanged;
  }

  /**
   * Calculate how many renderers are in the current row and update the value
   */
  updateElementsInRow() {

    //Calculate the current element count
    const newElementCount = this.calculateElementsInRow();

    //No change then we will bail out!
    if (newElementCount === this.elementsInRow) {
      return;
    }

    //Set the amount of elements in the row
    this.elementsInRow = newElementCount;

    //We will need to update the scroller view
    this.updateScrollView();
  }

  calculateElementsInRow(): number {

    //We don't have the component so we can't work it out!
    if (!this.infiniteListComponent) {
      return 0;
    }

    //Get the current children for the list
    const children = this.infiniteListComponent["nativeElement"].children;

    //No children no can do!
    if (!children) {
      return 0;
    }

    //Create two values one to keep a count and one for the x position which
    //we will use to see how many are in this row
    let elements = 0;
    let firstX = 0;

    //Loop through all the children
    for (let i = 0; i < children.length; i++) {

      //Get the current child
      const child = children[i];

      //Get the offset from the top of the page
      const offsetX = child.offsetTop;

      //If this is the first value then we will set it as our default offset
      if (i === 0) {
        firstX = offsetX;
      }

      //If the offset isn't the same as the first one this it is on a different row then the otherone!
      if (firstX !== offsetX) {
        break;
      }

      //This means the offset was the same and therefor it was in the same row!
      elements++;
    }

    return elements;
  }

  /**
   * We want to emmit the current buffer so the caller can react as they wish
   */
  emitCurrentBuffer() {

    //No data then bail out
    if (!this.rawData) {
      return;
    }

    //Get the start and end make sure it's within the bounds!
    const start: number = Math.max(0, Math.min(this.viewStart, this.rawData.length));
    const end: number = Math.min(this.rawData.length, this.viewEnd);

    //Emit an event which indicates that the current buffer has changed with the values which are in that buffer!
    this.dataInBufferChange.emit(this.rawData.slice(start, end));
  }

  /**
   * Track the item by id
   *
   * @param index
   * @param indexItem
   */
  trackById(index, indexItem) {

    //Do we have an id property. then get it from the object
    if (this.dataObjectIdProperty) {
      return indexItem[this.dataObjectIdProperty];
    }

    //Return the index item
    return indexItem;
  }
}

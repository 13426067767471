
import { Component, ComponentFactory, ComponentFactoryResolver, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { AHubActions } from 'actions/ahub.actions';
import { ViewActions } from 'actions/view.actions';
import { RequestActionMonitorService } from 'app/services/request-action-monitor/request-action-monitor.service';
import { WorklogObservable } from 'app/services/request-action-monitor/worklog-observable';
import { sessionClientId } from 'app/store/selector/app.selector';
import { viewSelectedUser } from 'app/store/selector/view/view-distribution-groups.selector';
import { ExportTypeCodeAHubEnum } from 'app/valueObjects/ahub/accounts/export-type-code.ahub.enum';
import { componentDestroyStream, Hark } from 'modules/common/hark.decorator';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, publishReplay, refCount, switchMap, takeUntil } from 'rxjs/operators';
import { aHubStateTemporaryExportDistributionList } from 'selector/ahub/ahub-temporary.selector';
import { viewSelectedExportDistribution, viewSelectedExportDistributionId } from 'selector/view/view-exports.selector';
import { StoreAccess } from 'store/store-access';
import { ExportDistributionAHubVO } from 'valueObjects/ahub/accounts/export-distribution.ahub.vo';
import { Utils } from '../../utils';
import { ExportDistributionIndexItemComponent } from '../export-distribution-index-item/export-distribution-index-item.component';

@Component({
  selector: 'app-user-export-distributions-tab',
  templateUrl: './user-export-distributions-tab.component.html',
  styleUrls: ['./user-export-distributions-tab.component.css']
})
@Hark()
export class UserExportDistributionsTabComponent implements OnInit, OnDestroy {

  /**
   * Component factory to generate components for the index.
   */
  public componentFactory: ComponentFactory<ExportDistributionIndexItemComponent> = this.resolver.resolveComponentFactory(ExportDistributionIndexItemComponent);

  /**
 * The currently selected User
 */
  selectedUser$ = StoreAccess.dataGetObvs(viewSelectedUser);


  /**
   * Get the selected user id from the store.
   */
  @Input() selectedUserId$: Observable<number>;

  /**
   * Get the export distributions from the store.
   */
  exportDistributions$ = combineLatest([
    StoreAccess.dataGetObvs(aHubStateTemporaryExportDistributionList).pipe(
      map(list => list.data)),
    this.route.paramMap.pipe(
      filter(paraMap => paraMap !== undefined),
    )]
  ).pipe(
    takeUntil(componentDestroyStream(this)),
    map(([exportDistributions, paramMap]) => {
      if (this.isAdminView(paramMap)) {
        return exportDistributions;
      } else {
        return exportDistributions.filter(exportDistribution => exportDistribution.clientId === StoreAccess.dataGet(sessionClientId));
      }
    }),
    publishReplay(1),
    refCount()
  )


  /**
   * Get the export distributions from the store.
   */
  exportDistribution$ = StoreAccess.dataGetObvs(viewSelectedExportDistribution);

  /**
   * Get the selected dataSet id from the store.
   */
  selectedExportDistributionId$ = StoreAccess.dataGetObvs(viewSelectedExportDistributionId);

  exportDistribution: ExportDistributionAHubVO;
  adminView: boolean;


  /**
   * Set up an obsverable stream to get all of the add work logs for this user that are working with distribution groups.
   */
  selectedUserUpdated$: Observable<number>;

  constructor(
    private readonly requestActionMonitorService: RequestActionMonitorService,
    private readonly route: ActivatedRoute,
    private readonly resolver: ComponentFactoryResolver,
  ) { }

  ngOnInit() {

    this.selectedUserUpdated$ = this.selectedUserId$.pipe(
      switchMap(userId => {
        return this.requestActionMonitorService.worklogObservableSuccess()
          .worklogTypeFilter(WorklogObservable.WORK_LOG_TYPES_USER)
          .observable()
          .pipe(
            filter(worklog => worklog.workAffectedIds && worklog.workAffectedIds.includes(userId)),
            map(worklogForSelectedUser => userId)
          )

      })
    )

    /**
     * Set up an obsverable stream to get all Distribution worklog updates.
     */
    combineLatest([
      this.requestActionMonitorService.worklogObservableSuccess()
        .worklogTypeFilter(WorklogObservable.WORK_LOG_TYPES_DISTRIBUTION)
        .observable()
        .pipe(
          Utils.isNotNullOrUndefined()
        ),
      this.selectedUserId$]).subscribe(([exportsUpdated, selectedUserId]) => {
        // Exports changed, let refresh the distributions for the currently selected user
        this.exportDistributionsFetch(selectedUserId);
      })

    this.selectedUserUpdated$.pipe(
    ).subscribe(updateForUserId => {
      this.exportDistributionsFetch(updateForUserId);
      StoreAccess.dispatch(AHubActions.distributionGroupIndexesByUserIdFetch(updateForUserId));
    });

    // Listening for the index change we can then dispatch a fetch event to get the full client information.
    this.selectedUserId$
      .pipe(takeUntil(componentDestroyStream(this)))
      .subscribe(userId => {
        if (!userId) {
          return;
        }
        this.exportDistributionsFetch(userId);
      });

  }

  /**
   * Empty On destroy to ensure @Hark decorator works for an AOT build
   */
  ngOnDestroy() { }

  /**
   * Gets the name to display on the index list.
   */
  listIndexLabelFunction(index) {

    // Return the name from the index property.
    return index.catalogueName;
  }


  /**
   * This function will get all of the export distributions for a user id.
   * 
   * @param userId        The user id who's export distributions we want.
   */
  private exportDistributionsFetch(userId: number) {
    StoreAccess.dispatch(AHubActions.exportDistributionsFetch([
      ExportTypeCodeAHubEnum.AWORKBOOK_CATALOGUE,
      ExportTypeCodeAHubEnum.AWORKBOOK_CATALOGUE_JSON,
      ExportTypeCodeAHubEnum.AVIEW
    ], userId));
  }

  /**
  * Handler for the workGroup handler select.
  */
  exportDistributionSelectHandler(exportDistribution: ExportDistributionAHubVO) {
    // Do we have an workGroup index, if so we can select it as the current id, otherwise -1.
    this.currentExportDistributionSelectId((exportDistribution) ? exportDistribution.exportId : -1);
  }

  /**
   * Set the currently selected workGroup id in the store.
   */
  currentExportDistributionSelectId(exportDistributionId: number) {
    StoreAccess.dispatch(ViewActions.exportDistributionSelectedIdSet(exportDistributionId));
  }

  isAdminView(paramMap: ParamMap) {
    return Boolean(JSON.parse(paramMap.get('adminView')));
  }

}

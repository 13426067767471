
import { take } from 'rxjs/operators';
import { Component, OnInit, Input, Output, ElementRef, ViewChild, EventEmitter } from '@angular/core';
// Form imports for search.
import { FormGroup, FormControl, FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';

import { Observable, timer } from 'rxjs';

@Component({
  selector: 'app-callout-input',
  templateUrl: './callout-input.component.html',
  styleUrls: ['./callout-input.component.css']
})
export class CalloutInputComponent implements OnInit {
  /**
   * Link to the add item input - Note the function as viewChild, this is because the component is ngIf needs to be set after init.
   */
  itemInput: ElementRef;

  @ViewChild('itemInput', { static: false }) set content(content: ElementRef) {
    this.itemInput = content;
  }
  /**
   * Add Prompt
   */
  @Input() itemPrompt: string;

  /**
   * The icon to use for fab button.
   */
  @Input() itemIcon: string;

  /**
   * Are we showing the item input? No by default
   */
  @Input() itemInputShow = false;

  /**
   * Regexp pattern used to validate added items
   */
  _pattern: string;

  @Input()
  set pattern(pattern: string) {
    this._pattern = pattern;
    this.itemInputControlForm.controls.itemInput.setValidators(Validators.pattern(this._pattern));
  };

  /**
   * Regexp pattern used to validate added items
   */
  @Input() patternFailMessage: string;

  /**
   * Boolean which lets up know weather the item is busy or not
   */
  @Input() isBusy = false;

  /**
   * Boolean which lets up know wether the item should be disabled.
   */
  @Input() isDisabled = false;

  /**
   * Shall we make it a mini fab button?.
   */
  @Input() mini = false;

  /**
   * Specify the position of the input box relative to the add button. left by default
   */
  @Input() calloutPosition = 'left';

  /**
 * Emit the value to be actioned
 */
  @Output() itemValue: EventEmitter<any> = new EventEmitter();

  /**
   * Form to control the 'add' input box
   */
  itemInputControlForm: FormGroup = this.formBuilder.group({
    itemInput: ['']
  });

  constructor(private readonly formBuilder: FormBuilder) { }

  ngOnInit() {
    this.itemInputControlForm.controls.itemInput.setValidators(Validators.pattern(this._pattern));
  }


  /**
 * Called when the item button is clicked.
 */
  itemButtonClickHander() {
    // We either show the callout, or if the callout is already open,
    // we try and action the input.
    if (!this.itemInputShow) {
      this.itemInputShow = true;
      timer(500, 500).pipe(take(1)).subscribe(timer => { if (this.itemInput) this.itemInput.nativeElement.focus(); });
      return;
    }

    // Stop the action when the value entered is invalid.
    if (!this.itemInputControlForm.valid) {
      return;
    }

    if (this.itemInputControlForm.controls.itemInput.value) {
      this.itemValue.emit(this.itemInputControlForm.controls.itemInput.value);
    }
    this.itemInputShow = false;
    //Reset the item add input value
    this.itemInputControlForm.controls.itemInput.setValue('');
  }


  /**
   * Cancels the input dialog.
   */
  itemCancel() {
    this.itemInputShow = false;
    this.itemInputControlForm.controls.itemInput.setValue('');
  }

}

import { Component, ComponentFactory, ComponentFactoryResolver, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FileSaverService } from 'app/services/file-saver/file-saver.service';
import { RequestActionMonitorService } from 'app/services/request-action-monitor/request-action-monitor.service';
import { ClientIndexStream } from 'app/store/stream/client-index.stream';
import { ClientIndexAHubVO } from 'app/valueObjects/ahub/accounts/client-index.ahub.vo';
import { EntityPermissions } from 'app/valueObjects/ahub/accounts/entity-permissions.ahub';
import { UserGroupAHubVO } from 'app/valueObjects/ahub/accounts/user-group.ahub.vo';
import { UserIndexAHubVO } from 'app/valueObjects/ahub/accounts/user-index.ahub.vo';
import { SortOptionVO } from 'app/valueObjects/view/sort-option.view.vo';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, takeUntil, tap, startWith } from 'rxjs/operators';
import { DialogService } from '../../dialogs/dialog.service';
import { FileSaveDialogComponent } from '../../dialogs/file-save-dialog/file-save-dialog.component';
import { MultiAddDialogVO } from '../../dialogs/multiple-add-dialog/multiple-add-dialog.component';
import { componentDestroyStream, Hark } from '../../hark.decorator';
import { sortAlphanumericAtoZ } from '../../sort.util';
import { tassign } from '../../type-assign.util';
import { Utils } from '../../utils';
import { UserIndexItemComponent } from '../user-index-item/user-index-item.component';
import { InputDialogVO, InputDialogComponent } from '../../dialogs/input-dialog/input-dialog.component';
import { AddPermittedDomainDialogComponent } from './add-permitted-domain-dialog/add-permitted-domain-dialog.component';
import { EntityPermissionAHubVO } from 'app/valueObjects/ahub/accounts/entity-permission.ahub.vo';

@Component({
  selector: 'app-user-group',
  templateUrl: './user-group.component.html',
  styleUrls: ['./user-group.component.css']
})
@Hark()
export class UserGroupComponent implements OnInit {

  @Input() userGroup$: BehaviorSubject<UserGroupAHubVO> = new BehaviorSubject(undefined);
  userGroupSource: UserGroupAHubVO;

  @Input() registeredUserList$: BehaviorSubject<UserIndexAHubVO[]> = new BehaviorSubject(undefined);

  @Input() addRegisteredUserFunction: Function;

  @Input() deleteRegisteredUsersFunction: Function;

  @Input() selectedGroupName = 'NAME_NOT_SET';

  @Input() isMaster = false;

  @Input() saveActionBusy$: Observable<boolean>;

  @Input() editableAs: EntityPermissionAHubVO = EntityPermissions.ACCOUNTS_EDITOR;

  @Input() visibleAsPermission: EntityPermissionAHubVO = EntityPermissions.ACCOUNTS_EDITOR;

  @Output() saveUserGroup: EventEmitter<void> = new EventEmitter<void>();

  inclusionList$: BehaviorSubject<string[]> = new BehaviorSubject(undefined);
  exclusionList$: BehaviorSubject<string[]> = new BehaviorSubject(undefined);
  joiningKeyRestrictionList$: BehaviorSubject<string[]> = new BehaviorSubject([]);

  /**
 * flag to programatically expand/collapse joiningKeyRestrictionList panel
 */
  joiningKeyRestrictionListOpened = false;
  /*
  * As the workGroup data is managed on different tab we are managing each part of that data in different forms
  * this should make it clear whats doing what
  */
  public selectedUserGroupForm: FormGroup = this.formBuilder.group({
    joiningKey: [],
    joiningKeyExpiryDate: [],
  });

  somethingWorthSaving$: Observable<boolean> = of(false);


  inclusionListLabel = "Include Users";
  exclusionListLabel = "Exclude Users";
  userListLabel = "Users In Group";

  /**
    * The sort options to use.
    */
  sortOptions: SortOptionVO[] = [];

  /**
* Component factory to generate components for the index.
*/
  public componentFactory: ComponentFactory<UserIndexItemComponent> = this.resolver.resolveComponentFactory(UserIndexItemComponent);

  /**
   * The current joining key.
   */
  joiningKey: string;

  /**
   * get current client index
   */
  currentClientIndex$: Observable<ClientIndexAHubVO> = new ClientIndexStream().getClientIndexByCurrentClientId();

  /**
   * The current clients code.
   */
  currentClientCode: string;
  currentClientName: string;

  copyIconColour = "black";

  dateMustBeGreaterThanOrEqualToToday = (d: Date): boolean => {

    return d >= new Date();
  }

  /**
 * Stream which we will use to indicate if we are busy performing an action
 */
  actionRequestActionStatus$ = undefined;

  constructor(
    private readonly fileSaverService: FileSaverService,
    private readonly dialogService: DialogService,
    private readonly resolver: ComponentFactoryResolver,
    private readonly formBuilder: FormBuilder,
    private readonly requestActionMonitorService: RequestActionMonitorService
  ) { }

  ngOnInit() {

    //Subscribe to the work group changes so we can update our form
    this.userGroup$.pipe(
      Utils.isNotNullOrUndefined(),
      takeUntil(componentDestroyStream(this)))
      .subscribe(userGroup => {

        // Lets begin with our joiningKeyRestrictionList panel closed
        this.joiningKeyRestrictionListOpened = false;

        this.inclusionList$.next(userGroup.inclusionList);
        this.exclusionList$.next(userGroup.exclusionList);
        this.joiningKeyRestrictionList$.next(userGroup.joiningKeyRestrictionList);
        this.userGroupSource = Utils.clone(userGroup);
        this.formsSet(userGroup);
      });

    this.selectedUserGroupForm.valueChanges.pipe(
      takeUntil(componentDestroyStream(this)))
      .subscribe(data => {
        if (this.selectedUserGroupForm.controls.joiningKey.value) {
          this.buildJoiningKey();
        }
      });

    // Listen to the current client index so we know when the client code changes.
    this.currentClientIndex$.pipe(
      takeUntil(componentDestroyStream(this)),
      Utils.isNotNullOrUndefined())
      .subscribe(clientIndex => {
        this.currentClientCode = clientIndex.code;
        this.currentClientName = clientIndex.name;
      });

    // Set up user list sort options.
    this.sortOptionCreate('Sort by first name', 'firstName', false);
    this.sortOptionCreate('Sort by last name', 'lastName', false);
    this.sortOptionCreate('Sort by email', 'email', false);
    this.sortOptionCreate('Sort by Registration Date', 'createdDate', false);

    this.somethingWorthSaving$ = combineLatest([
      this.inclusionList$,
      this.exclusionList$,
      this.joiningKeyRestrictionList$,
      this.selectedUserGroupForm.valueChanges.pipe(startWith({})),
      this.userGroup$.pipe(
        Utils.isNotNullOrUndefined()
      )
    ]).pipe(
      map(([inclusionList, exclusionList, joiningKeyRestrictionList, formValueChange, userGroup]) => {

        return JSON.stringify(this.userGroupSource.inclusionList) !== JSON.stringify(inclusionList) ||
          JSON.stringify(this.userGroupSource.exclusionList) !== JSON.stringify(exclusionList) ||
          JSON.stringify(this.userGroupSource.joiningKeyRestrictionList) !== JSON.stringify(joiningKeyRestrictionList) ||
          this.selectedUserGroupForm.dirty;
      }),
      takeUntil(componentDestroyStream(this))
    );

  }

  ngOnDestroy() { }

  /**
   * Set the workGroup into our forms which are currently using it
   */
  private formsSet(selectedUserGroup: UserGroupAHubVO) {

    //Set the workGroup as generic form data
    let formData: any = selectedUserGroup;

    //If the data is undefined then we will set the form to empty
    if (!formData) {
      formData = {};
    }

    //Update the form data
    this.selectedUserGroupForm.markAsPristine();
    this.selectedUserGroupForm.reset(formData);

    // Build the joining key.
    this.buildJoiningKey();

  }

  buildJoiningKey() {

    // Get the group joining key.
    const joiningKey = this.selectedUserGroupForm.controls.joiningKey.value;

    // Now set the joining key, making sure we ignore null.
    this.joiningKey = joiningKey ? this.currentClientCode + '-' + joiningKey : null;
  }

  /**
  * Create and add a new sort option.
  *
  * @param label               The label to display.
  * @param sortPropertyName    The property to sort on.
  * @param invertSort          Are we inverting the sort. I.E Z-A not A-Z.
  */
  sortOptionCreate(label: string, sortPropertyName: string, invertSort: boolean) {

    // Create a new sort option.
    const option: SortOptionVO = { label, sortProperty: sortPropertyName, invertSort };

    // Add the sort option.
    this.sortOptions.push(option);
  }

  removejoiningKeyExpiryDate() {
    this.selectedUserGroupForm.markAsDirty();
    this.selectedUserGroupForm.controls.joiningKeyExpiryDate.setValue(null);
  }

  /**
   * Gets the name to display on the index list.
   */
  listIndexLabelFunction(index) {
    // Return the name from the index property.
    return index;
  }

  listDeleteHandler(list$: BehaviorSubject<string[]>, listTitle: string) {
    //Get the item array
    const itemArray = list$.getValue();
    //We need to set the index for each item
    let index = 0;

    //The popup expects an object and to accuretly delete the items we need to store an index to the item
    //this will allow us to delete the item from the list later. Er must also have an id property for this
    //object or the popup will not work. So our index will be our id
    const createdObjects = itemArray.map(item => {

      //Create a new object with the index and the data item
      const newObj = { id: index, data: item };

      //Increase the item
      index++;

      //Return the new object
      return newObj;
    });

    // Open a new list dialog to get the correct distribution groups to assign the new distribution too.
    const dialogRef = this.dialogService
      .selectMultiListDialogOpen("Select item to Remove", undefined, listTitle, of(createdObjects), [], 'data', true, 'Remove').pipe(
        takeUntil(componentDestroyStream(this)));

    //Subscribe the dialogue for the results of the dialogue
    dialogRef.subscribe(results => {

      //Of we don't have results then bail out
      if (!results) {
        return;
      }

      //OK so we have selected some things to removed we will map this back to a list
      //of indexes to remove from the list
      const indexes = (results).map(result => result.id);

      for (let i = indexes.length - 1; i >= 0; i--) {
        itemArray.splice(indexes[i], 1);
      }

      list$.next(itemArray);
    });
  }

  joiningKeyValidation(validationValue: string, newValuesList: string[]) {

    if (newValuesList && newValuesList.includes(validationValue)) {
      return "Joining Key already exists in list";
    }

    return null;
  }

  /**
   * User search value passed back to index list to allow search against returned value
   */
  userSearchValue = (user: UserIndexAHubVO) => {
    return `${user.email} ${user.firstName} ${user.lastName}`;
  }

  userListIndexLabelFunction(index) {
    // Return the name from the index property.
    return index;
  }


  /**
  * This handler is called when the user clicks on inclustion list menu add button
  */
  userListAddEmailHandler() {

    const multiAddDialogVO: MultiAddDialogVO = {
      inputOptions: {
        validationFunction: this.emailListValidation,
        name: "Email"
      },
      data: []
    }

    if (!this.isMaster) {

      const multiAddDialog = this.dialogService.multipleAddDialogOpen(this.userListLabel, null, multiAddDialogVO, "Add", "Cancel");

      multiAddDialog.pipe(
        takeUntil(componentDestroyStream(this))
      ).subscribe(result => {
        if (!result) { return; }

        const existingInclusionList: string[] = (this.inclusionList$.getValue()) ?
          this.inclusionList$.getValue() :
          [];

        const newlyAddedUsers: string[] = (multiAddDialogVO.data) ? multiAddDialogVO.data : [];

        const dedupedInclusionListWithNewlyAddedUsers = [...new Set([...existingInclusionList, ...newlyAddedUsers])];

        this.addRegisteredUserFunction(dedupedInclusionListWithNewlyAddedUsers);

      });
    } else {

      const inputDialogVO: InputDialogVO = {
        inputValue: '',
        inputLabel: 'Email '
      }

      const addDomainDialog: Observable<any> = this.dialogService.componentDialogOpen(
        "Add User",
        this.resolver.resolveComponentFactory(InputDialogComponent),
        'inputDialogVO',
        inputDialogVO,
        null,
        "Add");

      addDomainDialog.pipe(takeUntil(componentDestroyStream(this))).subscribe(result => {
        if (!result || !result.inputValue) {
          return;
        }

        this.addRegisteredUserFunction([result.inputValue])
      });
    }

  }

  /**
 * This handler is called when the user clicks on the delete users button.
 */
  userListDeleteHandler() {

    // Open a new list dialog to get the correct distribution groups to assign the new distribution too.
    const dialogRef = this.dialogService
      .selectMultiListDialogOpen('Select Users to Remove', undefined, 'Users', this.registeredUserList$, [], 'email', true, 'Remove').pipe(

        takeUntil(componentDestroyStream(this)));

    // Listen for the users selection.
    dialogRef.subscribe(results => {

      // Did the user select any data? If not, then stop here.
      if (!results || results.length === 0) {
        return;
      }

      this.deleteRegisteredUsersFunction(results.map(result => result.id));
    });
  }

  /**
    * This handler is called when the user clicks on the user list export button.
    */
  public registeredUserListExportListHandler() {

    const registeredUserEmailsList: string[] = this.registeredUserList$.getValue().map(user => user.email);

    // Save the user list.
    this.formListExport(registeredUserEmailsList, 'Registered Users');
  }

  /**
   * This function is used to save a form property list.
   *
   * @param listSource      The source of the list we are setting
   */
  private formListExport(listSource: string[], fileNamePostFix: string) {
    // Get the copmponent Factory we will use to create the component dialog
    // we will use to create a new export entry.
    const fileSaveDialogComponentFactory: ComponentFactory<FileSaveDialogComponent> = this.resolver.resolveComponentFactory(FileSaveDialogComponent);

    const suggestedFileName = `${this.currentClientName}-${this.selectedGroupName}-${fileNamePostFix}`;

    // Create a new client.
    const dialogVO = {
      fileName: suggestedFileName
    }
    // Open the custom component dialogue , using the component factory to create the component content, passing in a blank VO.
    const newDialogue = this.dialogService.componentDialogOpen("Save As", fileSaveDialogComponentFactory, "dialogVO", dialogVO, null, "Save", "Cancel");

    //Subscribe to the new export dialogue, if we recive an export then we will add it
    newDialogue.subscribe((dialogVO) => {

      //If the new VO is undefined then we will bail out
      if (!dialogVO) {
        return;
      }

      // Now we want to construct the save string from this list. We will do this by joining the
      // parts with a return.
      const data = listSource.map(fileLine => fileLine.trim()).join("\r\n");

      // Now save the file.
      this.fileSaverService.fileStringDataSave(data, dialogVO.fileName + ".csv", "application/csv");
    });
  }

  /**
   * This handler is called when the user clicks on the inclusion import button.
   *
   * @param replaceList   Are we replacing the current list?
   */
  emailListImportHandler(emailList$: BehaviorSubject<string[]>, listTitle: string, replaceList = false) {

    //Create a dialogue box for the delete confirmation
    const dialogRef: Observable<any[]> = this.dialogService.fileSelectDialogOpen(listTitle,
      "Select one or more return separated files.", true, [".csv"]);
    //Subscribe to the result of the dialogue box
    dialogRef.pipe(
      takeUntil(componentDestroyStream(this)))
      .subscribe(result => {
        // Call the handle file list function.
        this.fileListResultsHandle(result, emailList$, replaceList);
      });
  }

  /**
 * This function will load a list of files and place the data into the relevant property in the form.
 *
 * @param fileList            The file list to handle.
 * @param listSource          The source of the list we will be setting
 * @param replaceList         Are we replacing the current list.
 */
  private fileListResultsHandle(fileList: File[], listSource: BehaviorSubject<string[]>, replaceList: boolean) {

    // We will need to load all of the email addresses from each of the files returned.
    // So create a new variable to hold the emails.
    let userEmails: string[] = (replaceList) ? [] : listSource.getValue();
    //let userEmails: string[] = [];
    // Make sure the list is a list.
    if (!userEmails) {
      userEmails = [];
    }


    // Stop here if the result is empty.
    if (!fileList || fileList.length === 0) {
      return;
    }

    // Now we need to repeat through each of the file objects returned.
    for (let file of fileList) {

      // Create a new reader for each file.
      const reader: FileReader = new FileReader();

      // Wait for the loading of the data.
      reader.onloadend = (event) => {

        // Get the data from the file.
        const fileContent: string = reader.result as string;

        // Now split them by the return character.
        const fileLine: string[] = fileContent.split("\r\n");
        const fileEmails = fileLine
          .map(line => line.split(',')[0].trim())
          // Remove 'Email' incase we are importing from a userlist export
          .filter(email => email !== 'Email');

        // Loop through all of the email addresses and look for new ones.
        fileEmails.forEach(emailAddress => {

          // Skip any empty email addresses.
          if (emailAddress === "") {
            return;
          }

          // Do we have the email address in the final list? If so, move on.
          if (userEmails.indexOf(emailAddress) > -1) {
            return;
          }

          // If we get here then add the email address to the final list.
          userEmails.push(emailAddress);

        });

        // Call the sort function to ensure that the user emails are sorted.
        userEmails = this.stringSort(userEmails);

        // Pass the the user email addresses into the list.
        listSource.next(userEmails);

      }

      // Read the data in.
      reader.readAsText(file);
    }
  }

  /**
  * This function will sort a list of strings.
  */
  private stringSort(stringList: string[]): string[] {
    // Do we have a string list? If not, return it now.
    if (stringList === undefined) {
      return stringList;
    }
    // Sort the list passed in.
    return stringList.sort((string1, string2) => sortAlphanumericAtoZ(string1, string2));
  }

  /**
   * This handler is called when the user clicks on the inclusion list export button.
   */
  public emailListExportHandler(emailList$: BehaviorSubject<string[]>, listTitle: string) {
    // Save the inclusion list.
    this.formListExport(emailList$.getValue(), listTitle);
  }

  /**
   * This handler is called when the user clicks on inclustion list menu add button
   */
  addToInclusionOrExclusionListHandler(emailList$: BehaviorSubject<string[]>, listTitle: string) {

    const dialogVO: MultiAddDialogVO = {
      inputOptions: {
        validationFunction: this.emailListValidation,
        name: "Email"
      },
      data: (emailList$.getValue()) ? emailList$.getValue() : []
    }

    const multiAddDialog = this.dialogService.multipleAddDialogOpen(listTitle, null, dialogVO, "Add", "Cancel");

    multiAddDialog.pipe(takeUntil(componentDestroyStream(this))).subscribe(result => {

      if (!result) { return; }

      emailList$.next(dialogVO.data);
    });
  }

  /**
   * Save handler to handel the user group save
   */
  userGroupSaveHandler() {

    let userGroupToSave: UserGroupAHubVO = this.userGroup$.getValue();

    userGroupToSave.inclusionList = this.inclusionList$.getValue();
    userGroupToSave.exclusionList = this.exclusionList$.getValue();
    userGroupToSave.joiningKeyRestrictionList = this.joiningKeyRestrictionList$.getValue();

    userGroupToSave = tassign(userGroupToSave, this.selectedUserGroupForm.value);

    this.userGroup$.next(userGroupToSave);
    this.saveUserGroup.emit();
  }

  emailListValidation(validationValue: string, newValuesList: string[]) {
    // Validate email address
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(validationValue)) {
      return "Please enter a valid email address";
    }
    // Check to see if the list already contains the email
    if (newValuesList.includes(validationValue)) {
      return "Email address already exists in list";
    }
    return null;
  }

  openJoiningKeyDialog(event) {
    const multiAddDialogVO: MultiAddDialogVO = {
      inputOptions: {
        validationFunction: this.joiningKeyValidation,
        name: "Domain or email address"
      },
      data: (this.joiningKeyRestrictionList$.getValue()) ? this.joiningKeyRestrictionList$.getValue() : []
    }

    const multiAddDialog = this.dialogService.multipleAddDialogOpen('Joining Key Whitelist', null, multiAddDialogVO, "Done", "Cancel");

    multiAddDialog.pipe(
      takeUntil(componentDestroyStream(this))
    ).subscribe(result => {

      if (!result) { return; }

      this.joiningKeyRestrictionList$.next(multiAddDialogVO.data);
    });
  }

  addPermittedDomainToInclusionListHandler(list: BehaviorSubject<string[]>) {

    //Open the dialogue with the parameters object
    this.dialogService.componentDialogOpen("Add Domain", this.resolver.resolveComponentFactory(AddPermittedDomainDialogComponent),
      'dialogVO', undefined, undefined, "Add", "Cancel")
      .subscribe((result) => {

        // no data, no point
        if (!result) {
          return;
        }

        // lets turn the raw domain into its email format (e.g. blah.com becomes *@blah.com)
        const wildcardEmailFormattedDomain: string = '*@'.concat(result);

        //Get current inclusion list
        let inclusionList: string[] = this.inclusionList$.getValue();

        //If the list is empty then we will create an empty array
        if (!inclusionList) {
          inclusionList = [];
        }

        //Add the new item into the list
        inclusionList.push(wildcardEmailFormattedDomain);

        //Lets dedupe
        inclusionList = Array.from(new Set(inclusionList));

        //Post the update to the inclusions list!
        this.inclusionList$.next(inclusionList);
      });
  }

}

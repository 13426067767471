import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { selectedRouteView } from 'app/store/selector/view/view-routes.selector';
import { StoreAccess } from 'app/store/store-access';
import { RouteViewVO } from 'app/valueObjects/view/route.view.vo';
import { Observable } from 'rxjs';
import { viewFormStateFormStatesDirty, viewComponentStateFormStatesDirty } from 'app/store/selector/view/view-page-state.selector';

@Component({
  selector: 'app-sidenav-route',
  templateUrl: './sidenav-route.component.html',
  styleUrls: ['./sidenav-route.component.css']
})
export class SidenavRouteComponent implements OnInit {

  @Input() routes: RouteViewVO[];

  @Input() depth: number;
  @Output() closeNav = new EventEmitter();

  selectedRouteView$: Observable<RouteViewVO> = StoreAccess.dataGetObvs(selectedRouteView);

  constructor() {
    // This is intentional
  }

  ngOnInit() {
  }


  closeNavEmit(event) {
    this.closeNav.emit()
  }

  closeNavClick(event) {
    this.closeNav.emit()
  }

  // pageTransition(event){
  //      //Get the dirty forms which are currently on view
  //      let dirtyForms = StoreAccess.dataGet(viewFormStateFormStatesDirty);
  //      let dirtyComponenets = StoreAccess.dataGet(viewComponentStateFormStatesDirty);
  //     //We have no changes to we will return an observable of false
  //     if ((!dirtyForms || dirtyForms.length <= 0) && (!dirtyComponenets || dirtyComponenets.length <= 0)){
  //       document.querySelector('body').classList.remove('loaded');
  //     }
  // }
}

import { ParameterAHubVO } from 'valueObjects/ahub/accounts/parameter.vo';
import { FormGroup, FormControl, Validators } from '@angular/forms';

/**
 * This is the class full of distribution utilities.
 */
export class DistributionUtils {

    /**
       * The Regular expression used to validate a URL.
       */
    private static urlPatternRegexp = '^(http(s)?(:\/\/))?(www\.)?[a-zA-Z0-9-_\.]+(\.[a-zA-Z0-9]{2,})([-a-zA-Z0-9:%_\+.~#?&//=]*)$';

    /**
      * This function will add a form control to the current distribution form.
      *
      * @param name            The name of the form. This will be what is set in the HTML.
      * @param value           The default value to set the form too.
      * @param disabled        (Optional) Is the form control disabled?
      * @param validators      (Optional) Any validators that need to be added to the form control.
      */
    public static formControlAdd(formGroup: FormGroup, name: string, value, disabled?: boolean, validators?) {

        // Creat the form with the default value and validators.
        const formControl = new FormControl(value, validators);

        // Then disable it if we need too.
        if (disabled) {
            formControl.disable();
        }

        // Finally, add the form control to the form.
        formGroup.addControl(name, formControl);
    }

    /**
     * Add the standard aWorkbook export type parameters to a form.
     *
     * @param formGroup             The form group to add the controls to.
     */
    public static awExportTypeParametersAddToForm(formGroup: FormGroup) {
        DistributionUtils.formControlAdd(formGroup, "skuFilters", []);
        DistributionUtils.formControlAdd(formGroup, "priceList", []);
        DistributionUtils.formControlAdd(formGroup, "resourceFilters", []);
        DistributionUtils.formControlAdd(formGroup, "assortmentFilters", []);
        DistributionUtils.formControlAdd(formGroup, "presentationFilters", []);
        DistributionUtils.formControlAdd(formGroup, "preSavedAssortmentFilters", []);
        DistributionUtils.formControlAdd(formGroup, "tabOrderDisable", []);
        DistributionUtils.formControlAdd(formGroup, "tabPresentationDisable", []);
        DistributionUtils.formControlAdd(formGroup, "productRetailPriceDisable", []);
        DistributionUtils.formControlAdd(formGroup, "productRetailPriceOutputDisable", []);
        DistributionUtils.formControlAdd(formGroup, "productWholesalePriceDisable", []);
        DistributionUtils.formControlAdd(formGroup, "productWholesalePriceOutputDisable", []);
        DistributionUtils.formControlAdd(formGroup, "productFilterOutOfStock", []);
        DistributionUtils.formControlAdd(formGroup, "stockServerUrl", '', false, [Validators.pattern(DistributionUtils.urlPatternRegexp)]);
        DistributionUtils.formControlAdd(formGroup, "stockServerSessionKey", '');
        DistributionUtils.formControlAdd(formGroup, "customOrderDataProviderServerUrl", '', false, [Validators.pattern(DistributionUtils.urlPatternRegexp)]);
        DistributionUtils.formControlAdd(formGroup, "customOrderDataProviderServerSessionKey", '');
        DistributionUtils.formControlAdd(formGroup, "orderSubmissionServerType", []);
        DistributionUtils.formControlAdd(formGroup, "orderSubmissionServerUrl", '', false, [Validators.pattern(this.urlPatternRegexp)]);
        DistributionUtils.formControlAdd(formGroup, "orderSubmissionServerKey", []);
        DistributionUtils.formControlAdd(formGroup, "alternativeCoverAssetId", []);
    }

    /**
     * Add the standard aWorkbook export type inherit parameters to a form.
     *
     * @param formGroup             The form group to add the controls to.
     */
    public static awExportTypeInheritParametersAddToForm(formGroup: FormGroup) {
        DistributionUtils.formControlAdd(formGroup, "skuFiltersInherit", []);
        DistributionUtils.formControlAdd(formGroup, "priceListInherit", []);
        DistributionUtils.formControlAdd(formGroup, "resourceFiltersInherit", []);
        DistributionUtils.formControlAdd(formGroup, "assortmentFiltersInherit", []);
        DistributionUtils.formControlAdd(formGroup, "presentationFiltersInherit", []);
        DistributionUtils.formControlAdd(formGroup, "preSavedAssortmentFiltersInherit", []);
        DistributionUtils.formControlAdd(formGroup, "tabOrderDisableInherit", []);
        DistributionUtils.formControlAdd(formGroup, "tabPresentationDisableInherit", []);
        DistributionUtils.formControlAdd(formGroup, "productRetailPriceDisableInherit", []);
        DistributionUtils.formControlAdd(formGroup, "productRetailPriceOutputDisableInherit", []);
        DistributionUtils.formControlAdd(formGroup, "productWholesalePriceDisableInherit", []);
        DistributionUtils.formControlAdd(formGroup, "productWholesalePriceOutputDisableInherit", []);
        DistributionUtils.formControlAdd(formGroup, "productFilterOutOfStockInherit", []);
        DistributionUtils.formControlAdd(formGroup, "stockServerUrlInherit", []);
        DistributionUtils.formControlAdd(formGroup, "stockServerSessionKeyInherit", '');
        DistributionUtils.formControlAdd(formGroup, "customOrderDataProviderServerUrlInherit", '');
        DistributionUtils.formControlAdd(formGroup, "customOrderDataProviderServerSessionKeyInherit", '');
        DistributionUtils.formControlAdd(formGroup, "orderSubmissionServerInherit", []);
        DistributionUtils.formControlAdd(formGroup, "alternativeCoverAssetIdInherit", []);
    }

    /**
     * Add the standard aWorkbook export type group parameters to a form.
     *
     * @param formGroup             The form group to add the controls to.
     */
    public static awExportTypeGroupParametersAddToForm(formGroup: FormGroup) {
        DistributionUtils.formControlAdd(formGroup, "groupSkuFilters", '', true);
        DistributionUtils.formControlAdd(formGroup, "groupPriceListFilter", '', true);
        DistributionUtils.formControlAdd(formGroup, "groupResourceFilters", '', true);
        DistributionUtils.formControlAdd(formGroup, "groupAssortmentFilters", '', true);
        DistributionUtils.formControlAdd(formGroup, "groupPresentationFilters", '', true);
        DistributionUtils.formControlAdd(formGroup, "groupPreSavedAssortmentFilters", '', true);
        DistributionUtils.formControlAdd(formGroup, "groupTabOrderDisable", '', true);
        DistributionUtils.formControlAdd(formGroup, "groupTabPresentationDisable", '', true);
        DistributionUtils.formControlAdd(formGroup, "groupProductRetailPriceDisable", '', true);
        DistributionUtils.formControlAdd(formGroup, "groupProductRetailPriceOutputDisable", '', true);
        DistributionUtils.formControlAdd(formGroup, "groupProductWholesalePriceDisable", '', true);
        DistributionUtils.formControlAdd(formGroup, "groupProductWholesalePriceOutputDisable", '', true);
        DistributionUtils.formControlAdd(formGroup, "groupProductFilterOutOfStock", '', true);
        DistributionUtils.formControlAdd(formGroup, "groupStockServerUrl", '', true, [Validators.pattern(this.urlPatternRegexp)]);
        DistributionUtils.formControlAdd(formGroup, "groupStockServerSessionKey", '', true);
        DistributionUtils.formControlAdd(formGroup, "groupCustomOrderDataProviderServerUrl", '', true, [Validators.pattern(this.urlPatternRegexp)]);
        DistributionUtils.formControlAdd(formGroup, "groupCustomOrderDataProviderServerSessionKey", '', true);
        DistributionUtils.formControlAdd(formGroup, "groupOrderSubmissionServerType", '', true);
        DistributionUtils.formControlAdd(formGroup, "groupOrderSubmissionServerUrl", '', true, [Validators.pattern(this.urlPatternRegexp)]);
        DistributionUtils.formControlAdd(formGroup, "groupOrderSubmissionServerKey", '', true);
        DistributionUtils.formControlAdd(formGroup, "groupAlternativeCoverAssetId", '', true);
    }


    /**
     * Function to get the first value named with that parameter, if the parameter doesn't exist then its undefined
     */
    public static distributionParamValueSingleBoolean(params: ParameterAHubVO[], parameterName: string, defaultValue: boolean): boolean {

        //Get the values array
        const values: string[] = this.distributionParamValuesArrayFind(params, parameterName);

        //Return the first value from the list
        return (values && values.length > 0) ? (values[0].toLowerCase() === 'true') : defaultValue;
    }

    /**
     * Function to get the first value named with that parameter, if the parameter doesn't exist then its undefined
     */
    public static distributionParamValueSingleBooleanFalse(params: ParameterAHubVO[], parameterName: string, defaultValue: boolean): boolean {

        //Get the values array
        const values: string[] = this.distributionParamValuesArrayFind(params, parameterName);

        //Return the first value from the list
        return (values && values.length > 0) ? values.some(value => value.toLowerCase() === 'false') : defaultValue;
    }

    /**
     * Function to get the values from the distribution parameters based on name
     */
    public static distributionParamValuesArrayFind(params: ParameterAHubVO[], parameterName: string): string[] {

        //If we don't have a parameters array we will return undefined
        if (!params) {
            return undefined;
        }

        //Find the parameter with the correct name
        const parameter: ParameterAHubVO = params.find(param => param.id.toLowerCase() === parameterName.toLowerCase());

        //If we have a parameter then we will return the values if not then we will return undefined
        return (parameter) ? parameter.values : undefined;
    }

    /**
     * Function to get the values from the distribution parameters based on name
     */
    public static distributionParamValuesSingleFind(params: ParameterAHubVO[], parameterName: string): string {

        const values = this.distributionParamValuesArrayFind(params, parameterName);

        //If we have a parameter then we will return the values if not then we will return undefined
        return (values) ? values[0] : undefined;
    }

    /**
     * Pass the aWorkbook distribution parameters into a form.
     *
     * @param distributionParams                The distribution parameters to pass into the form.
     * @param form                              The form to set the values in.
     */
    public static setAWDistributionParamsToForm(distributionParams: ParameterAHubVO[], form: FormGroup) {

        // Do we have paramters associated with this distribution? If so we will
        // override some of the flags set by the distribution group
        if (distributionParams) {

            //We will also need to set parts of the form manually
            form.patchValue({
                skuFilters: this.distributionParamValuesArrayFind(distributionParams, 'skuFilters'),
                priceList: this.distributionParamValuesArrayFind(distributionParams, 'priceList'),
                resourceFilters: this.distributionParamValuesArrayFind(distributionParams, 'resourceFilters'),
                assortmentFilters: this.distributionParamValuesArrayFind(distributionParams, 'assortmentFilters'),
                presentationFilters: this.distributionParamValuesArrayFind(distributionParams, 'presentationFilters'),
                preSavedAssortmentFilters: this.distributionParamValuesArrayFind(distributionParams, 'preSavedAssortmentFilters'),
                stockServerUrl: this.distributionParamValuesSingleFind(distributionParams, 'stockServerUrl'),
                stockServerSessionKey: this.distributionParamValuesSingleFind(distributionParams, 'stockServerSessionKey'),
                customOrderDataProviderServerUrl: this.distributionParamValuesSingleFind(distributionParams, 'customOrderDataProviderServerUrl'),
                customOrderDataProviderServerSessionKey: this.distributionParamValuesSingleFind(distributionParams, 'customOrderDataProviderServerSessionKey'),
                orderSubmissionServerType: this.distributionParamValuesSingleFind(distributionParams, 'orderSubmissionServerType'),
                orderSubmissionServerUrl: this.distributionParamValuesSingleFind(distributionParams, 'orderSubmissionServerUrl'),
                orderSubmissionServerKey: this.distributionParamValuesSingleFind(distributionParams, 'orderSubmissionServerKey'),
                alternativeCoverAssetId: this.distributionParamValuesSingleFind(distributionParams, "alternativeCoverAssetId")
            });
        }
        form.patchValue({
            tabOrderDisable: this.distributionParamValueSingleBooleanFalse(distributionParams, 'tabOrderDisable', true),
            tabPresentationDisable: this.distributionParamValueSingleBooleanFalse(distributionParams, 'tabPresentationDisable', true),
            productRetailPriceDisable: this.distributionParamValueSingleBooleanFalse(distributionParams, 'productRetailPriceDisable', false),
            productRetailPriceOutputDisable: this.distributionParamValueSingleBooleanFalse(distributionParams, 'productRetailPriceOutputDisable', false),
            productWholesalePriceDisable: this.distributionParamValueSingleBooleanFalse(distributionParams, 'productWholesalePriceDisable', false),
            productWholesalePriceOutputDisable: this.distributionParamValueSingleBooleanFalse(distributionParams, 'productWholesalePriceOutputDisable', false),
            productFilterOutOfStock: this.distributionParamValueSingleBoolean(distributionParams, 'productFilterOutOfStock', false),
        });
    }

    /**
     * Pass aWorkbook inherited distribution group parameters into a form.
     *
     * @param distributionGroupParams               The distribution group parameters to pass in.
     * @param form                                  The form to fill with values.
     */
    public static setAWDistributionParamsGroupToForm(distributionGroupParams: ParameterAHubVO[], form: FormGroup) {

        // Set any flags or filters from the distribution group level.
        if (distributionGroupParams) {
            form.patchValue({
                groupSkuFilters: DistributionUtils.distributionParamValuesArrayFind(distributionGroupParams, "skuFilters"),
                groupPriceListFilter: DistributionUtils.distributionParamValuesArrayFind(distributionGroupParams, "priceList"),
                groupResourceFilters: DistributionUtils.distributionParamValuesArrayFind(distributionGroupParams, "resourceFilters"),
                groupAssortmentFilters: DistributionUtils.distributionParamValuesArrayFind(distributionGroupParams, "assortmentFilters"),
                groupPresentationFilters: DistributionUtils.distributionParamValuesArrayFind(distributionGroupParams, "presentationFilters"),
                groupPreSavedAssortmentFilters: DistributionUtils.distributionParamValuesArrayFind(distributionGroupParams, "preSavedAssortmentFilters"),
                groupStockServerUrl: DistributionUtils.distributionParamValuesArrayFind(distributionGroupParams, "stockServerUrl"),
                groupStockServerSessionKey: DistributionUtils.distributionParamValuesArrayFind(distributionGroupParams, "stockServerSessionKey"),
                groupCustomOrderDataProviderServerUrl: DistributionUtils.distributionParamValuesArrayFind(distributionGroupParams, "customOrderDataProviderServerUrl"),
                groupCustomOrderDataProviderServerSessionKey: DistributionUtils.distributionParamValuesArrayFind(distributionGroupParams, "customOrderDataProviderServerSessionKey"),
                groupOrderSubmissionServerType: DistributionUtils.distributionParamValuesSingleFind(distributionGroupParams, "orderSubmissionServerType"),
                groupOrderSubmissionServerUrl: DistributionUtils.distributionParamValuesSingleFind(distributionGroupParams, "orderSubmissionServerUrl"),
                groupOrderSubmissionServerKey: DistributionUtils.distributionParamValuesSingleFind(distributionGroupParams, "orderSubmissionServerKey"),
                groupAlternativeCoverAssetId: DistributionUtils.distributionParamValuesSingleFind(distributionGroupParams, "alternativeCoverAssetId"),
            });
        }

        form.patchValue({
            groupTabOrderDisable: !DistributionUtils.distributionParamValueSingleBoolean(distributionGroupParams, "tabOrderDisable", false),
            groupTabPresentationDisable: !DistributionUtils.distributionParamValueSingleBoolean(distributionGroupParams, "tabPresentationDisable", false),
            groupProductRetailPriceDisable: !DistributionUtils.distributionParamValueSingleBoolean(distributionGroupParams, "productRetailPriceDisable", true),
            groupProductRetailPriceOutputDisable: !DistributionUtils.distributionParamValueSingleBoolean(distributionGroupParams, "productRetailPriceOutputDisable", true),
            groupProductWholesalePriceDisable: !DistributionUtils.distributionParamValueSingleBoolean(distributionGroupParams, "productWholesalePriceDisable", true),
            groupProductWholesalePriceOutputDisable: !DistributionUtils.distributionParamValueSingleBoolean(distributionGroupParams, "productWholesalePriceOutputDisable", true),
            groupProductFilterOutOfStock: DistributionUtils.distributionParamValueSingleBoolean(distributionGroupParams, "productFilterOutOfStock", false),
        });
    }

    /**
     * Pass aWorkbook inherited distribution parameters into a form.
     *
     * @param distributionParams                The distribution parameters to pass in.
     * @param form                              The form to fill with values.
     */
    public static setAWDistributionParamsInheritToForm(distributionParams: ParameterAHubVO[], form: FormGroup) {

        form.patchValue({
            skuFiltersInherit: DistributionUtils.distributionParamValueSingleBoolean(distributionParams, "skuFiltersInherit", true),
            priceListInherit: DistributionUtils.distributionParamValueSingleBoolean(distributionParams, "priceListInherit", true),
            resourceFiltersInherit: DistributionUtils.distributionParamValueSingleBoolean(distributionParams, "resourceFiltersInherit", true),
            assortmentFiltersInherit: DistributionUtils.distributionParamValueSingleBoolean(distributionParams, "assortmentFiltersInherit", true),
            presentationFiltersInherit: DistributionUtils.distributionParamValueSingleBoolean(distributionParams, "presentationFiltersInherit", true),
            preSavedAssortmentFiltersInherit: DistributionUtils.distributionParamValueSingleBoolean(distributionParams, "preSavedAssortmentFiltersInherit", true),
            tabOrderDisableInherit: DistributionUtils.distributionParamValueSingleBoolean(distributionParams, "tabOrderDisableInherit", true),
            tabPresentationDisableInherit: DistributionUtils.distributionParamValueSingleBoolean(distributionParams, "tabPresentationDisableInherit", true),
            productRetailPriceDisableInherit: DistributionUtils.distributionParamValueSingleBoolean(distributionParams, "productRetailPriceDisableInherit", true),
            productRetailPriceOutputDisableInherit: DistributionUtils.distributionParamValueSingleBoolean(distributionParams, "productRetailPriceOutputDisableInherit", true),
            productWholesalePriceDisableInherit: DistributionUtils.distributionParamValueSingleBoolean(distributionParams, "productWholesalePriceDisableInherit", true),
            productWholesalePriceOutputDisableInherit: DistributionUtils.distributionParamValueSingleBoolean(distributionParams, "productWholesalePriceOutputDisableInherit", true),
            productFilterOutOfStockInherit: DistributionUtils.distributionParamValueSingleBoolean(distributionParams, "productFilterOutOfStockInherit", true),
            stockServerUrlInherit: DistributionUtils.distributionParamValueSingleBoolean(distributionParams, "stockServerUrlInherit", true),
            stockServerSessionKeyInherit: DistributionUtils.distributionParamValueSingleBoolean(distributionParams, "stockServerSessionKeyInherit", true),
            customOrderDataProviderServerUrlInherit: DistributionUtils.distributionParamValueSingleBoolean(distributionParams, "customOrderDataProviderServerUrlInherit", true),
            customOrderDataProviderServerSessionKeyInherit: DistributionUtils.distributionParamValueSingleBoolean(distributionParams, "customOrderDataProviderServerSessionKeyInherit", true),
            alternativeCoverAssetIdInherit: DistributionUtils.distributionParamValueSingleBoolean(distributionParams, "alternativeCoverAssetIdInherit", true),

            // OK this item is slightly special. We need a inherite property for
            // 'orderSubmissionServerTypeInherit', 'orderSubmissionServerUrlInherit', 'orderSubmissionServerKeyInherit'
            // they should also be the same. so we will use one of the values from the params to set the values and have it represented by a single form value
            // when we then read it all back out we will set both props based on this form
            orderSubmissionServerInherit: DistributionUtils.distributionParamValueSingleBoolean(distributionParams, "orderSubmissionServerTypeInherit", true)
        });
    }

    /**
     * This function will build the distribution params from a form group.
     *
     * @param form      The form to get the parameters from.
     */
    public static buildDistributionParamsFromForm(form: FormGroup, onlyDirtyControls?: boolean): ParameterAHubVO[] {

        // Create a new set of parameters to fill.
        const parameters: Set<ParameterAHubVO> = new Set();

        // Get the list of keys in the controls, then repeat through them.
        Object.keys(form.controls).forEach(controlName => {

            // Get each control.
            const formControl = form.get(controlName);

            // Skip un-dirty controls when we want too.
            if (!formControl.dirty && onlyDirtyControls) {
                return;
            }

            // Is this our agregated order submission inherit? If so we will need to convert this back into our two proper
            // parameter types with the same values.
            if (controlName === "orderSubmissionServerInherit") {

                // Value we want to set,
                const orderInheritValToSet = this.buildParamValues(formControl as FormControl, controlName);

                // Add these items to the list.
                parameters.add({ id: "orderSubmissionServerTypeInherit", values: orderInheritValToSet });
                parameters.add({ id: "orderSubmissionServerUrlInherit", values: orderInheritValToSet });
                parameters.add({ id: "orderSubmissionServerKeyInherit", values: orderInheritValToSet });
            }

            //We only want to put away parameter values specific to this distribution
            else if (!controlName.includes('group') && !controlName.includes('distributionNotes')) {

                // Add the value after we build the param values from the form control.
                parameters.add({ id: controlName, values: DistributionUtils.buildParamValues(formControl as FormControl, controlName) });
            }
            else {
                return;
            }
        });

        // Convert the set of parameters into an array and return.
        return Array.from(parameters)
    }

    /**
     * This function will take a form control and
     *
     * @param formControl           The form contol who's value we want to convert into param values.
     * @param controlName           The name of the form control.
     */
    private static buildParamValues(formControl: FormControl, controlName: string) {

        // Is the form controls value already an array? If so, just return it.
        if (Array.isArray(formControl.value)) {
            return formControl.value;
        }

        // Is the form control value true or false?
        if (formControl.value === true || formControl.value === false) {

            // If so, does it end with "Disable" if so we want to invert the value, convert into a string and put it in a new array.
            if (controlName.endsWith('Disable')) {
                return [(!formControl.value).toString()];
            }

            // Otherwise just convert the boolean value into a string, and put it into a new array.
            return [formControl.value.toString()];
        }

        // If we get here then just put the value into a new array.
        return [formControl.value];
    }
}
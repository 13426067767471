
/**
 * Actions.
 */
import { AHubActions } from 'actions/ahub.actions';
import { ProductPropertyFilterOptionData } from "modules/common/vo-render/product-property-filter/product-property-filter-option-data.vo";
import { Observable } from "rxjs";
import { combineLatest, filter, map } from 'rxjs/operators';
import {
  aHubStateTemporaryProductClassList, aHubStateTemporaryProductPropertyList,
  aHubStateTemporaryProductPropertySectionList
} from 'selector/ahub/ahub-temporary.selector';
/**
 * Selectors.
 */
import { sessionClientId } from 'selector/app.selector';
/**
 * Utilities.
 */
import { ListUtil } from 'store/list.util';
/**
 * Store access.
 */
import { StoreAccess } from 'store/store-access';
/**
 * Value objects.
 */
import { ProductPropertyAllocationAHubVO } from "valueObjects/ahub/library/product-property-allocation.ahub.vo";
import { PropertyAllocationObjectVO } from "valueObjects/stream/product-allocation-object-stream.vo";
import { Utils } from 'app/modules/common/utils';

/**
 * This class will get streams of section property allocation objects.
 */
export class SectionPropertyAllocationsStream {


  /**
   * This stream function will get all of the product filter options for the property allocations passed in.
   *
   * @param propertyAllocations$              The product property allocations who's data we want.
   */
  static productPropertyFilterOptionDataGet(propertyAllocations$: Observable<ProductPropertyAllocationAHubVO[]>): Observable<ProductPropertyFilterOptionData[]> {
    return SectionPropertyAllocationsStream.productAllocationObjectStreamDataGet(propertyAllocations$);
  }

  /**
   * Get the product property allocation stream objects.
   *
   * @param propertyAllocations$              Based on this set of product property allocations.
   */
  static productAllocationObjectStreamDataGet(propertyAllocations$: Observable<ProductPropertyAllocationAHubVO[]>): Observable<PropertyAllocationObjectVO[]> {

    let propertyAllocationObjectStream: Observable<PropertyAllocationObjectVO[]>;

    if (propertyAllocations$) {

      // Run through all of the property allocations that were passed in.
      propertyAllocationObjectStream = propertyAllocations$.pipe(
        Utils.isNotNullOrUndefined(),
        map(propertyAllocs => {

          // Get the list of section, property and class ids we want.
          let sectionIds = new Set();
          let classIds = new Set();
          let propertyIds = new Set()

          propertyAllocs.forEach(propertyAlloc => {

            // No property allocation then bail out (shouldn't happen but did get an uncaught error)
            if (!propertyAlloc) {
              return;
            }

            // Make the request to get the prduct property by id.
            propertyIds.add(propertyAlloc.productPropertyId);

            // Make the request to get the sections.
            sectionIds.add(propertyAlloc.productSectionId);

            // Make the request to get the classes.
            classIds.add(propertyAlloc.productClassId);
          })

          // Make the request to get the prduct property by id.
          StoreAccess.dispatch(AHubActions.productPropertyFetch(<number[]>[...propertyIds]));

          // Make the request to get the sections.
          StoreAccess.dispatch(AHubActions.productPropertySectionsFetch(StoreAccess.dataGet(sessionClientId), <number[]>[...sectionIds]));

          // Make the request to get the classes.
          StoreAccess.dispatch(AHubActions.productClassesFetch(<number[]>[...classIds]));

          // Return the allocations to the next level.
          return propertyAllocs;
        }),
        combineLatest(StoreAccess.dataGetObvs(aHubStateTemporaryProductPropertySectionList).pipe(
          filter(list => list !== undefined && list.size > 0)), (allocations, sectionList) => ({ allocations: allocations, sectionList: sectionList })),
        combineLatest(StoreAccess.dataGetObvs(aHubStateTemporaryProductPropertyList).pipe(
          filter(list => list !== undefined && list.size > 0)), (data, propertyList) => ({ allocations: data.allocations, sectionList: data.sectionList, propertyList: propertyList })),
        combineLatest(StoreAccess.dataGetObvs(aHubStateTemporaryProductClassList).pipe(
          filter(list => list !== undefined && list.size > 0)), (data, classList) => ({ allocations: data.allocations, sectionList: data.sectionList, propertyList: data.propertyList, classList: classList })),
        map(data => {

          // Get all of the properies we need.
          let sectionList = data.sectionList;
          let propertyList = data.propertyList;
          let allocations = data.allocations;
          let classes = data.classList;

          // Now create a new object with the data we want added.
          return allocations.map(alloc => {

            // Skip null or undefined allocations.
            if (!alloc) {
              return undefined;
            }

            // Get the section and property for this allocation.
            let section = ListUtil.listDataItemGet(sectionList, alloc.productSectionId);
            let property = ListUtil.listDataItemGet(propertyList, alloc.productPropertyId);
            let productClass = ListUtil.listDataItemGet(classes, alloc.productClassId);

            // We do not want to add any data if we don't have everything we need.
            if (!section || !property || !productClass) return null;

            // Create the new object to return.
            return {
              id: alloc.id,
              section: section,
              property: property,
              productClass: productClass,
            };
          }
          )
            .filter(object => object);
        }));
    }

    return propertyAllocationObjectStream;

  }
}

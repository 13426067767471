import {
  Component,
  OnInit,
  ViewChild,
  EventEmitter,
  Directive,
  Output,
  ElementRef,
  Input
} from '@angular/core';


@Directive({ selector: '[makeDraggable]' })
export class MakeDraggableDirective {

  /**
   * This is the data that is draggable in the component.
   */
  @Input('makeDraggable') data: any;

  //**********
  // Styles
  //***********/

  /**
   * This is the name of the drag source style.
   */
  private static dragSourceStyleName: string = "dragSource";


  //**********
  // Event names
  //***********/

  /**
   * The name of the drag start event.
   */
  private static dragStartEventName: string = "dragstart";

  /**
   * The name of the drag end event.
   */
  private static dragEndEventName: string = "dragend";

  //**********
  // Functions
  //***********/

  constructor(private _elementRef: ElementRef) { }


  ngOnInit() {

    // Get the current element.
    let nativeElement = this._elementRef.nativeElement;

    // Set the draggable attribute to the element.
    nativeElement.draggable = 'true';


    // Set up the dragstart event and add the drag source CSS class 
    // to change the visual appearance. Set the current todo as the data
    // payload by stringifying the object first
    nativeElement.addEventListener(MakeDraggableDirective.dragStartEventName, (event) => {

      // Change to the source style.
      nativeElement.classList.add(MakeDraggableDirective.dragSourceStyleName)

      // Use the move effect.
      event.dataTransfer.effectAllowed = 'move';

      // Pass the data into the data transfer object.
      event.dataTransfer.setData("text", this.data);
    });

    // Remove the drag source class
    nativeElement.addEventListener(MakeDraggableDirective.dragEndEventName, (event) => {

      // Stop the default action.
      event.preventDefault();

      // Now remove the drag source style from the class list.
      nativeElement.classList.remove(MakeDraggableDirective.dragSourceStyleName)
    });
  }
}
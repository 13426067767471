import { Utils } from 'app/modules/common/utils';
import { RouteViewVO } from 'app/valueObjects/view/route.view.vo';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { currentToolId } from '../selector/app.selector';
import { availableRoutes } from '../selector/view/view-routes.selector';
import { StoreAccess } from '../store-access';

export class ViewAvailableRoutesStream {


    public static availableRoutes(): Observable<RouteViewVO[]> {

        return combineLatest([
            StoreAccess.dataGetObvs(currentToolId),
            StoreAccess.dataGetObvs(availableRoutes)
                .pipe(Utils.isNotNullOrUndefined())
        ])
            .pipe(
                map(([currentToolId, routes]) => routes.filter(route => !currentToolId || !route.toolIds || route.toolIds.includes(currentToolId)))
            );
    }
}
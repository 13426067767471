import { List } from 'store/list.vo';
import { ListUtil } from 'store/list.util';

/**
 * This is a set of list utilitys specifically for value objects which have an 'id' property
 * this id must uniquly identify the value object as in the inbuilt functions it will be used to
 * index, update and delete the data.
 */
export class IdListUtil {

    /**
     * Static property which identifys the id property of the list
     */
    static ID_PROPERTY: string = 'id';

    /**
     *  Create an empty list
     */
    public static listCreateEmpty() {

        //Create an empty list based on empty array
        return IdListUtil.listCreate([]);
    }

    /**
     * Create a new list based on the data items supplied
     */
    public static listCreate<T>(dataItems: T[]): List<T> {

        //Create a new list with the data supplied
        return ListUtil.listCreate(dataItems, IdListUtil.ID_PROPERTY);
    }

    /**
     * Will create a new list with the list of items appended. If an item
     * with the same id exists in the list then new item will replace the old one
     */
    public static listAppend<T>(list: List<T>, appendItems: T[]): List<T> {

        //Call the list append in the list util with our id property
        return ListUtil.listAppend(list, appendItems, IdListUtil.ID_PROPERTY);
    }

    /**
     * Will remove items from the list which have the matching ids.
     * A new re-index list will be returned
     */
    public static listRemove<T>(list: List<T>, ids: any[]): List<T> {

        //Use the list to remove the items with the specified ids
        let listData: T[] = ListUtil.listDataRemoveIds(list, ids);

        //Create a new list from the data items less the items we want to remove
        return ListUtil.listCreate(listData, IdListUtil.ID_PROPERTY);
    }

    /**
     * Will create a new list and apply a filter to the data associated to it
     */
    public static listFilter<T>(list: List<T>, dataItemFilter: (item: T) => boolean): List<T> {

        //Start with a placeholder for list data
        let listData: T[] = [];

        //If we have list data then get it
        if (list && list.data)
            listData = list.data;

        //If a data filter has been passed in then we will filter the list
        //if not we will just take a copy of the data and use it in our new list
        if (dataItemFilter != undefined)
            listData = listData.filter(dataItemFilter);
        else
            listData = listData.concat();

        //Create a new list from the filtered data and return it
        return ListUtil.listCreate(listData, IdListUtil.ID_PROPERTY);
    }
}

import { ProductViewClassConfigAllocationModel, ProductViewClassConfigModel } from 'app/modules/common/vo-render/product-view-class-config/product-view-class-config.component';
import { AppActions } from 'app/store/actions/app.actions';
import { viewPropertyIconMap } from 'app/store/selector/view/view-library-classification-class.selector';
import { StoreAccess } from 'app/store/store-access';
import { ExportOutputAssetAHubVO } from 'app/valueObjects/ahub/accounts/export-output-asset.ahub.vo';
import { ProductSectionPropertyValueAHubVO } from 'app/valueObjects/ahub/library/product-section-property-value.ahub.vo';
import { ProductSectionValueAHubVO } from 'app/valueObjects/ahub/library/product-section-value.ahub.vo';
import { mergeWith } from 'lodash';
import { ProductAssetAViewParamsVO, ProductAssetAViewVO, ProductAssetViewParamsMenuButtonAviewVO } from '../aview-product-asset/aview-product-asset.component';
import { ClassAViewVO } from '../valueObjects/class.aview.vo';
import { ProductViewClassConfigWithInheritanceAHubVO } from '../valueObjects/product-view-class-config-with-inheritance.aview.vo';
import { ProductViewClassConfigAViewVO } from '../valueObjects/product-view-class-config.aview.vo';
import { ProductViewConfigAViewVO } from '../valueObjects/product-view-config.aview.vo';
import { ProductAViewVO } from '../valueObjects/product.aview.vo';
import { PropertyAllocationObjectAViewVO } from '../valueObjects/property-allocation-object.aview.vo';

export class AViewUtils {

    /**
     * Background images for the asset.
     */
    private static readonly ASSET_BACKGROUND: string = "/assets/images/transparent-background.png";

    /**
     * The path to replace in the preivew URL.
     */
    private static readonly PREVIEW_REPLACE = '[width]x[height]/preview.png';

    /**
     * The preview name to use.
     */
    private static readonly PREVIEW_FILENAME = "/preview.jpg";

    /**
     * This method will return a 'merged' class product config for the selected class being passed in. The
     * Merging will take each the parent class's config, filling in any blanks for each child, with a preference for 
     * nearer parent. e.g. selected class has main image specified, but nothing else, immediate parent class has 
     * different main image property specified and short description specified. grandparent class has product
     * identifier specied. The resultant config for the selected class should show: main image from the selected 
     * class short description from the parent and product identifier from the grandparent
     *  
     * @param productViewClassConfig 
     * @param productViewConfig 
     * @param classIndex
     */
    public static buildMergedClassProductViewConfigWithInheritanceFromClassAncestry(
        productViewClassConfig: ProductViewClassConfigAViewVO,
        productViewConfig: ProductViewConfigAViewVO,
        classIndex: ClassAViewVO
    ): ProductViewClassConfigWithInheritanceAHubVO {
        let mergedLibraryViewClassConfig: ProductViewClassConfigWithInheritanceAHubVO = productViewClassConfig;

        if (!classIndex || !productViewConfig ||
            !productViewConfig.productViewClassConfigs ||
            productViewConfig.productViewClassConfigs.length === 0) {
            return mergedLibraryViewClassConfig
        }


        const allClassLibraryViewConfigs = productViewConfig.productViewClassConfigs;

        const parentClassIds: number[] = AViewUtils.getAncestryAsNumberArray(classIndex.ancestry)

        // Lets make a map container for inhertance of info allocs <allocId, (parent)classId>)
        const infoPropertyInheritanceMap: Map<number, number> = new Map();

        // As we would like to prioritise closer parent config,
        // we should reverse the default sort order of the ancestry
        parentClassIds.reverse();

        parentClassIds.forEach(parentClassId => {
            const parentClassConfig = allClassLibraryViewConfigs.find(classConfig => classConfig.classId === parentClassId);
            if (parentClassConfig) {
                const configsKeysFromParent: string[] = []
                mergedLibraryViewClassConfig = mergeWith(mergedLibraryViewClassConfig, parentClassConfig, (mergedValue, parentValue, key) => {

                    switch (key) {
                        case 'productIdentifierPropertyAlloc':
                        case 'productMainImagePropertyAlloc':
                            // Lets inherit our parents config if we're empty and make a note of where the config came from
                            if (!mergedValue) {
                                mergedValue = parentValue;
                            }
                            break;
                        case 'productInfoPropertyAllocs':
                            // For the info alloc array we should 'uniquely' merge the arrays from 
                            // this class config and our parents
                            if (parentValue) {
                                mergedValue = (mergedValue) ? mergedValue : []
                                mergedValue = Array.from(new Set([...mergedValue, ...parentValue]));
                                parentValue.forEach(val => {
                                    infoPropertyInheritanceMap.set(val, parentClassId);
                                });
                                configsKeysFromParent.push(key);
                            }
                            break;
                    }

                    return mergedValue;
                });

                configsKeysFromParent.forEach(configKey => {
                    switch (configKey) {
                        case 'productIdentifierPropertyAlloc':
                        case 'productMainImagePropertyAlloc':

                            mergedLibraryViewClassConfig[`${configKey}InheritedFromClassId`] = parentClassId;
                            break;
                        case 'productInfoPropertyAllocs':
                            if (infoPropertyInheritanceMap && infoPropertyInheritanceMap.size > 0) {
                                mergedLibraryViewClassConfig['productInfoPropertyAllocsInheritanceMap'] = infoPropertyInheritanceMap;
                            }
                            break;
                    }
                });
            }
        });

        // Did we inherit any info properties from a parent?
        if (mergedLibraryViewClassConfig && mergedLibraryViewClassConfig.productInfoPropertyAllocsInheritanceMap) {
            // Lets sort the config so that inherited info properties appear first (highest ancestor first)
            mergedLibraryViewClassConfig = this.sortInfoPropertiesIntoInheritedFirst(mergedLibraryViewClassConfig)
        }

        return mergedLibraryViewClassConfig;
    }

    /**
     * This method will return a 'merged' class product config for the selected class being passed in. The
     * Merging will take each the parent class's config, filling in any blanks for each child, with a preference for 
     * nearer parent. e.g. selected class has main image specified, but nothing else, immediate parent class has 
     * different main image property specified and short description specified. grandparent class has product
     * identifier specied. The resultant config for the selected class should show: main image from the selected 
     * class short description from the parent and product identifier from the grandparent
     *  
     * @param productViewClassConfig 
     * @param productViewConfig 
     * @param classIndex
     */
    public static buildProductViewClassConfigModelWithInheritance(
        productViewClassConfig: ProductViewClassConfigAViewVO,
        productViewConfig: ProductViewConfigAViewVO,
        classIndex: ClassAViewVO
    ): ProductViewClassConfigModel {
        let productViewClassConfigModel: ProductViewClassConfigModel = AViewUtils.buildProductViewClassConfigModel(productViewClassConfig, classIndex.id);

        if (!classIndex || !productViewConfig ||
            !productViewConfig.productViewClassConfigs ||
            productViewConfig.productViewClassConfigs.length === 0) {
            return productViewClassConfigModel
        }

        const allClassLibraryViewConfigs = productViewConfig.productViewClassConfigs;

        const parentClassIds: number[] = AViewUtils.getAncestryAsNumberArray(classIndex.ancestry)

        // As we would like to prioritise closer parent config,
        // we should reverse the default sort order of the ancestry
        parentClassIds.reverse();

        parentClassIds.forEach(parentClassId => {
            const parentClassConfig = allClassLibraryViewConfigs.find(classConfig => classConfig.classId === parentClassId);
            if (parentClassConfig) {
                productViewClassConfigModel = mergeWith(productViewClassConfigModel, parentClassConfig, (mergedValue, parentValue, key) => {
                    return AViewUtils.mergeClassConfigPropertyIntoConfigModel(key, mergedValue, parentValue, parentClassId);
                });

            }
        });

        return productViewClassConfigModel;
    }

    private static mergeClassConfigPropertyIntoConfigModel(propertyName: string, mergedValue, parentValue, parentClassId): any {
        switch (propertyName) {
            case 'productIdentifierPropertyAlloc':
            case 'productMainImagePropertyAlloc':
                // Lets inherit our parents config if we're empty and make a note of where the config came from
                if (!mergedValue && parentValue) {
                    mergedValue = {
                        allocationId: parentValue,
                        inheritedFrom: parentClassId
                    };
                    return mergedValue;
                }
                break;
            case 'productInfoPropertyAllocs':
                // For the info alloc array we should 'uniquely' merge the arrays from 
                // this class config and our parents
                if (parentValue && parentValue.length > 0) {
                    mergedValue = (mergedValue) ? mergedValue : []
                    parentValue.forEach(parentInfoProperty => {
                        const parentInfoPropertyModel: ProductViewClassConfigAllocationModel = {
                            allocationId: parentInfoProperty,
                            inheritedFrom: parentClassId
                        }

                        mergedValue.push(parentInfoPropertyModel)
                    });

                }
                break;

        }
        return mergedValue;

    }

    /**
     * Builds ProductViewClassConfigModel (used for view/editor) from supplied ProductViewClassConfigAViewVO
     */
    static buildProductViewClassConfigModel(productViewClassConfig: ProductViewClassConfigAViewVO, classId: number): ProductViewClassConfigModel {

        // Lets begin with an empty model
        const productViewClassConfigModel: ProductViewClassConfigModel = {
            classId: classId,
            productIdentifierPropertyAlloc: undefined,
            productMainImagePropertyAlloc: undefined,
            productInfoPropertyAllocs: undefined
        };

        if (productViewClassConfig && productViewClassConfig.classId) {
            productViewClassConfigModel.classId = productViewClassConfig.classId;
        }

        if (productViewClassConfig && productViewClassConfig.productIdentifierPropertyAlloc) {
            productViewClassConfigModel.productIdentifierPropertyAlloc = {
                allocationId: productViewClassConfig.productIdentifierPropertyAlloc
            }
        }

        if (productViewClassConfig && productViewClassConfig.productMainImagePropertyAlloc) {
            productViewClassConfigModel.productMainImagePropertyAlloc = {
                allocationId: productViewClassConfig.productMainImagePropertyAlloc
            }
        }

        if (productViewClassConfig && productViewClassConfig.productInfoPropertyAllocs) {

            const infoProperties: ProductViewClassConfigAllocationModel[] = [];

            productViewClassConfig.productInfoPropertyAllocs.forEach(infoAlloc => {
                infoProperties.push({
                    allocationId: infoAlloc
                })
            });

            productViewClassConfigModel.productInfoPropertyAllocs = infoProperties
        }

        return productViewClassConfigModel;
    }

    static sortModelInfoPropertiesIntoInheritedFirst(productInfoPropertyAllocs: ProductViewClassConfigAllocationModel[]): ProductViewClassConfigAllocationModel[] {
        const inheritedInfoProperties: ProductViewClassConfigAllocationModel[] = productInfoPropertyAllocs.filter(infoProperty => infoProperty.inheritedFrom);
        const infoPropertiesNotInherited: ProductViewClassConfigAllocationModel[] = productInfoPropertyAllocs.filter(infoProperty => !infoProperty.inheritedFrom);

        inheritedInfoProperties.sort((a, b) => a.inheritedFrom < b.inheritedFrom ? -1 : 0)

        return [...inheritedInfoProperties, ...infoPropertiesNotInherited];
    }

    static sortInfoPropertiesIntoInheritedFirst(productViewConfigWithInheritance: ProductViewClassConfigWithInheritanceAHubVO): ProductViewClassConfigWithInheritanceAHubVO {

        const infoProperties = productViewConfigWithInheritance.productInfoPropertyAllocs;
        const infoPropertyInheritanceMap: Map<number, number> = productViewConfigWithInheritance.productInfoPropertyAllocsInheritanceMap

        const inheritedInfoProperties: number[] = Array.from(infoPropertyInheritanceMap.keys());

        const nonInheritedInfoProperties: number[] = infoProperties.filter(infoProperty => !inheritedInfoProperties.includes(infoProperty))

        productViewConfigWithInheritance.productInfoPropertyAllocs = [...inheritedInfoProperties, ...nonInheritedInfoProperties];

        return productViewConfigWithInheritance;
    }

    /**
     * Converts ProductViewClassConfigModel to ProductViewClassConfig
     * @param productViewClassConfigModel
     */
    public static convertToProductViewClassConfig(productViewClassConfigModel: ProductViewClassConfigModel): ProductViewClassConfigAViewVO {
        const productViewClassConfig: ProductViewClassConfigAViewVO = {
            classId: productViewClassConfigModel.classId
        }

        if (productViewClassConfigModel.productIdentifierPropertyAlloc) {
            productViewClassConfig.productIdentifierPropertyAlloc = productViewClassConfigModel.productIdentifierPropertyAlloc.allocationId;
        }

        if (productViewClassConfigModel.productMainImagePropertyAlloc) {
            productViewClassConfig.productMainImagePropertyAlloc = productViewClassConfigModel.productMainImagePropertyAlloc.allocationId;
        }

        if (productViewClassConfigModel.productInfoPropertyAllocs && productViewClassConfigModel.productInfoPropertyAllocs.length > 0) {
            productViewClassConfig.productInfoPropertyAllocs = productViewClassConfigModel.productInfoPropertyAllocs.map(infoAlloc => infoAlloc.allocationId);
        }

        return productViewClassConfig
    }

    public static getAncestryAsNumberArray(ancestry: string): number[] {
        return (ancestry) ?
            ancestry
                .split(',')
                .filter(element => (element))
                .map(Number) : []
    }

    /**
 * 
 * @param productViewConfig 
 * @param classIdOfTheConfigWeWant 
 * @param classIndex 
 * 
 * Returns the product view class config for the specified class, or its nearest parent, or undefined
 */
    public static getNearestLibraryViewClassConfigByClassId(productViewConfig: ProductViewConfigAViewVO, classIdOfTheConfigWeWant: number, classIndex: ClassAViewVO[]): ProductViewClassConfigAViewVO {
        let productViewClassConfig: ProductViewClassConfigAViewVO;

        if (!productViewConfig || !productViewConfig.productViewClassConfigs) {
            return productViewClassConfig;
        }

        // Maybe the desired classId has a config, lets try to get that first
        productViewClassConfig = productViewConfig.productViewClassConfigs.find(classConfig => classConfig.classId === classIdOfTheConfigWeWant)

        if (!productViewClassConfig) {
            // Ok we didnt get lucky, lets walk the ancestry looking for a config
            const classWeWant: ClassAViewVO = classIndex.find(clazz => clazz.id === classIdOfTheConfigWeWant);

            if (classWeWant) {
                const ancestryOfClassIdsOfTheClassWeWantSortedByNearestParentFirst: number[] = this.getAncestryAsNumberArray(classWeWant.ancestry).reverse();

                for (const classId of ancestryOfClassIdsOfTheClassWeWantSortedByNearestParentFirst) {
                    productViewClassConfig = productViewConfig.productViewClassConfigs.find(classConfig => classConfig.classId === classId);
                    // Did we find a suitable libreay view class config?
                    if (productViewClassConfig) {
                        // We did, cool lets foxtrot oscar
                        break;
                    }
                }
            }
        }


        return productViewClassConfig;
    }

    /**
     * Single function to generate renderer for simple single file asset types e.g. images / video's / pdf's
     * This method may be called to generate a renderer for either extract OR dataset product assets (different download call for each)
     * @param productId
     * @param allocObject
     * @param assetData
     * @param assetTypeName
     * @param assetPreviewImgUrls
     * @param fullScreenAssetClickHandlerFunc
     */
    public static assetRendererCreate(
        productid: number,
        allocObject: PropertyAllocationObjectAViewVO,
        asset: ExportOutputAssetAHubVO,
        assetTypeName: string,
        assetPreviewImgUrls: string[],
        fullScreenAssetClickHandlerFunc: (params: ProductAssetAViewParamsVO) => void,
        hideAssetDetails: boolean,
        downloadRequestedHandler?: (params: ProductAssetAViewParamsVO) => void
    ): ProductAssetAViewParamsVO {


        //We will create an object which store the
        const assetRendererDataObject: ProductAssetAViewVO = {
            productId: productid,
            allocationId: allocObject.id,
            sectionId: allocObject.section.id,
            propertyId: allocObject.property.id,
            type: allocObject.property.typeReference,
            assetId: asset.assetId
        }

        //Function which decides if we have any existing data
        const hasDataButtonVisibility = (params: ProductAssetAViewParamsVO) => {
            return true;
        }


        const downloadButtons = this.buildDownloadButtonsByAssetType(
            asset,
            hasDataButtonVisibility,
            downloadRequestedHandler)

        /**
         * Icons for the asset types
         */
        const propertyIconMap = StoreAccess.dataGet(viewPropertyIconMap);

        return {
            backgroundImageGetRelativePathFunc: (data) => AViewUtils.ASSET_BACKGROUND,
            data: assetRendererDataObject,
            leftIcon: propertyIconMap[allocObject.property.typeReference],
            leftIconTooltip: assetTypeName,
            leftIconClickFunc: (params) => fullScreenAssetClickHandlerFunc(params),
            rightIcon: undefined,
            rightIconTooltip: undefined,
            assetImagePreviewURL: assetPreviewImgUrls,
            sectionName: allocObject.section.label,
            sectionColour: allocObject.section.colour,
            propertyName: allocObject.property.label,
            componentClickHandler: (params) => fullScreenAssetClickHandlerFunc(params),
            busyIndicatorState: 'none',
            menuButtonCheckVisible: (data) => downloadButtons.length > 0,
            menuButtonDisplay: 'menu',
            hideAssetDetails: hideAssetDetails,
            menuButtons: downloadButtons,
        };
    }

    /**
     * Lets create some 'buttons' for this asset that will allow for downloading whichever type the asset is
     * @param assetData
     * @param assetTypeName
     * @param dataSetId
     * @param extractId
     * @param requestActionMonitor
     * @param hasDataButtonVisibility
     */
    static buildDownloadButtonsByAssetType(
        assetData: ExportOutputAssetAHubVO,
        hasDataButtonVisibility: (params: ProductAssetAViewParamsVO) => boolean,
        downloadRequestedHandler?: (params: ProductAssetAViewParamsVO) => void): ProductAssetViewParamsMenuButtonAviewVO[] {

        if (!assetData) {
            return [];
        }

        const downloadButtons: ProductAssetViewParamsMenuButtonAviewVO[] = [];

        // Lets make a convenient map to help with providing asset sizes for image assets
        const assetInfoMap: Map<string, string> = this.buildAssetInfoMapFromAssetData(assetData);

        switch (assetData.assetType) {
            case 'IMAGE':

                //Add in the download specified sized image function
                downloadButtons.push({
                    buttonName: this.buildButtonNameToIncludeAssetDimensions('Asset', assetInfoMap),
                    buttonIcon: 'file_download',
                    buttonTooltip: 'Download Image to specified dimensions',
                    buttonCheckVisible: hasDataButtonVisibility,
                    buttonClickFunc: (params) => {
                        downloadRequestedHandler(params)
                    }
                });
                break;

            case 'VIDEO': {

                //Add in the download SD video asset function
                const sdVideoAssetUrl = this.assetURLGet(assetData, assetData.assetFiles.find(url => url.includes('sd')));
                downloadButtons.push({
                    buttonName: this.buildButtonNameToIncludeAssetDimensions('SD', assetInfoMap),
                    buttonIcon: 'file_download',
                    buttonTooltip: 'Download SD Video',
                    buttonCheckVisible: hasDataButtonVisibility,
                    buttonClickFunc: (params) => {
                        downloadRequestedHandler(params);
                        this.downloadAssetFromURL(sdVideoAssetUrl);
                    }
                });

                //Add in the download HD video asset function
                const hdVideoAssetUrl = this.assetURLGet(assetData, assetData.assetFiles.find(url => url.includes('hd')));
                downloadButtons.push({
                    buttonName: this.buildButtonNameToIncludeAssetDimensions('HD', assetInfoMap),
                    buttonIcon: 'file_download',
                    buttonTooltip: "Download HD Video",
                    buttonCheckVisible: hasDataButtonVisibility,
                    buttonClickFunc: (params) => {
                        downloadRequestedHandler(params);
                        this.downloadAssetFromURL(hdVideoAssetUrl)
                    }
                });

                break;
            }
            case 'PDF': {

                //Add in the download original asset function
                const pdfAssetURL = this.assetURLGet(assetData, assetData.assetFiles.find(url => url.includes(".pdf")));
                downloadButtons.push({
                    buttonName: `Download PDF`,
                    buttonIcon: 'file_download',
                    buttonTooltip: `Download PDF`,
                    buttonCheckVisible: hasDataButtonVisibility,
                    buttonClickFunc: (params) => {
                        downloadRequestedHandler(params);
                        this.downloadAssetFromURL(pdfAssetURL)
                    }
                });

                break;
            }
        }

        // Return the download buttons.
        return downloadButtons;
    }

    /**
     * * Get the URL to an preview asset.
     * 
     * @param assetData               The asset data to get the URL for.
     * @param widthHeightTarget       The target width and height text.
     */
    static previewAssetURLGet(assetData: ExportOutputAssetAHubVO, widthHeightTarget: string): string {

        // Replace the widthxheight value with the string we want.
        return (assetData && assetData.previewAssetPresignedURL) ?
            assetData.previewAssetPresignedURL.signedUrl.replace(this.PREVIEW_REPLACE, widthHeightTarget + this.PREVIEW_FILENAME) :
            null;
    }


    /**
     * Get the URL for a file path for an asset.
     * 
     * @param assetData         The asset data we want to get the path for.
     * @param filePath          The file we want the URL for.
     */
    static assetURLGet(assetData: ExportOutputAssetAHubVO, filePath: string): string {

        // Stop here if the file path is null or undefined.
        if (!filePath) {
            return undefined;
        }

        // Now replace the * in the signed URL with the file path we want.
        return assetData.assetPresignedURL.signedUrl.replace("*", filePath);
    }

    /**
     * Simple function to start an asset download
     */
    private static downloadAssetFromURL(assetURL: string) {
        StoreAccess.dispatch(AppActions.fileDownloadConfigSet([{ url: assetURL }]));
    }

    /**
     * Get the build asset info from an asset data object.
     * 
     * @param assetData         The asset data we want to get the info from.
     */
    static buildAssetInfoMapFromAssetData(assetData: ExportOutputAssetAHubVO): Map<string, string> {
        return (assetData && assetData.typeSpecificInfo && assetData.typeSpecificInfo.entry)
            ? new Map(assetData.typeSpecificInfo.entry.map(entry => [entry.key, entry.value]))
            : new Map<string, string>();
    }

    /**
     * Builds useful button names containing more information about what will be downloaded if it were clicked
     * 
     * @param assetSizeType
     * @param assetInfoMap
     */
    static buildButtonNameToIncludeAssetDimensions(assetSizeType: string, assetInfoMap: Map<string, string>): string {
        let buttonName = `Download ${assetSizeType}`;

        switch (assetSizeType) {
            case 'Original': {
                const assetWidth = (assetInfoMap.get('standardWidthPx')) ? assetInfoMap.get('standardWidthPx') : assetInfoMap.get('sourceWidthPx');
                const assetHeight = (assetInfoMap.get('standardHeightPx')) ? assetInfoMap.get('standardHeightPx') : assetInfoMap.get('sourceHeightPx');
                if (assetWidth && assetHeight) {
                    buttonName += ` (${assetWidth} x ${assetHeight})`
                }
            }
                break;
            case 'Resized': {
                const assetWidth = assetInfoMap.get('processedWidthPx');
                const assetHeight = assetInfoMap.get('processedHeightPx');
                if (assetWidth && assetHeight) {
                    buttonName += ` (${assetWidth} x ${assetHeight})`
                }
            }
                break;
            case 'SD': {
                const fileSize = assetInfoMap.get('SDFileSizeBytes');
                if (fileSize) {
                    buttonName += ` (${this.formatBytes(fileSize)})`
                }
            }
                break;
            case 'HD': {
                const fileSize = assetInfoMap.get('HDFileSizeBytes');
                if (fileSize) {
                    buttonName += ` (${this.formatBytes(fileSize)})`
                }
            }

                break;

            default:

                break;
        }

        return buttonName
    }

    public static formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
}

/**
 * This function will return the value of the property of a product by alloc.
 *
 * @param product   Product containing property we wish to find
 * @param alloc     alloc describing section/property we want to get
 */
export const getProductPropertyValueByAViewAlloc = (product: ProductAViewVO, alloc: PropertyAllocationObjectAViewVO): string => {
    const propertyToBeReturned = getProductPropertyByAviewAlloc(product, alloc);
    return (propertyToBeReturned) ? propertyToBeReturned.value : undefined
}

/**
 * This function will return the property of a product by alloc.
 *
 * @param product   Product containing property we wish to find
 * @param alloc     alloc describing section/property we want to get
 */
export const getProductPropertyByAviewAlloc = (product: ProductAViewVO, alloc: PropertyAllocationObjectAViewVO): ProductSectionPropertyValueAHubVO => {
    let propertyToBeReturned: ProductSectionPropertyValueAHubVO;
    if (product &&
        alloc &&
        product.productSectionValues &&
        product.productSectionValues.length > 0) {
        const matchingProductSection: ProductSectionValueAHubVO = product.productSectionValues
            .find(section => section.sectionId === alloc.section.id)
        if (matchingProductSection && matchingProductSection.productSectionPropertyValues && matchingProductSection.productSectionPropertyValues.length > 0) {
            const matchingProperty = matchingProductSection.productSectionPropertyValues
                .find(property => property.propertyId === alloc.property.id)
            if (matchingProperty) {
                propertyToBeReturned = matchingProperty;
            }
        }
    }
    return propertyToBeReturned;
}
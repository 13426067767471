import { Directive, Input } from '@angular/core';
import { MatTooltip, TooltipPosition } from '@angular/material/tooltip';


/**
 * This directive is used when we want to show a tool tip with no translation.
 */
@Directive({ selector: '[noTranslateToolTip]' })
export class NoTranslateToolTipDirective extends MatTooltip {


    /**
     * By using the inputs name and then passing it to the message property
     * we actually pass the message string down to the parent tool tip class.
     */
    @Input('noTranslateToolTip') message: string;

    /**
     * Add the notranslate tool tip class.
     */
    tooltipClass: string = "notranslate";
}
import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-remove-chip-button',
  templateUrl: './remove-chip-button.component.html',
  styleUrls: ['./remove-chip-button.component.css']
})
export class RemoveChipButtonComponent implements OnInit {

  constructor() {
    // This is intentional
  }
  @Output()
  /**
   * Clicked event emitter
   */
  clicked: EventEmitter<void> = new EventEmitter<void>();

  ngOnInit() {
  }
  /**
   * Click handler for the action button
   */
  actionButtonClickHandler($event) {

    //Emit an on click event
    this.clicked.emit($event);
  }
}

import { Component } from '@angular/core';

import { IDoesFilterPassParams, RowNode, IFilterParams } from "ag-grid-community";
import { IFilterAngularComp } from "ag-grid-angular";
import {
  GridFilterSelectParams,
  GridFilterSelectOption,
  GridFilterSelectModel,
  getSelectedObjectFromValue
} from 'modules/common/components/ag-grid/filters/grid-filter-select-base';

@Component({
  selector: 'app-grid-filter-select',
  templateUrl: './grid-filter-select.component.html',
  styleUrls: ['./grid-filter-select.component.css']
})
export class GridFilterSelectComponent implements IFilterAngularComp {

  /**
   * Parameters
   */
  params: IFilterParams;
  paramsCutsom: GridFilterSelectParams;

  /**
   * Value Getter
   */
  valueGetter: (rowNode: RowNode) => any;

  /**
   * Selected object for this filter
   */
  selectedObject: GridFilterSelectOption = undefined;

  constructor() {
    // This is intentional
  }

  /**
   * Initalise the parameters
   */
  agInit(params: any): void {

    //Set parameters and set the value getter function
    this.params = params as IFilterParams;
    this.paramsCutsom = params as GridFilterSelectParams;

    //Get the value getter
    this.valueGetter = params.valueGetter;

    //Set the selected object from the value supplied
    this.setSelectedObjectFromValue(this.paramsCutsom.defaultSelectedFilterValue);
  }

  /**
   * Return if the filter is currently active
   */
  isFilterActive(): boolean {

    //If we have a selected object see if its an active filter. or not
    return this.selectedObject && this.selectedObject.activeFilter;
  }

  /**
   * Called by each row to check if this row matches this filter
   */
  doesFilterPass(params: IDoesFilterPassParams): boolean {

    //If the filter is not active then everything is included
    //If we have no selected object then we will not be able to filter the data
    if (!this.isFilterActive() || !this.selectedObject) {
      return true;
    }

    //Get the value for this row from the value getter
    const rowValue = this.valueGetter(params.node);

    //Do we have a dedicated filter function for this option?
    if (this.selectedObject.filterFunction) {

      //Call the designed filter function with the row value
      return this.selectedObject.filterFunction(params.node, rowValue);
    }

    //Does the row value match that of the selected value
    return (rowValue === this.selectedObject.value);
  }

  /**
   * Get the current model for the filter
   */
  getModel(): GridFilterSelectModel {

    //Get the selected value from the selected object
    const selectedValue = (this.selectedObject) ? this.selectedObject.value : undefined;

    //Return the instance of the mode
    return { selectedValue };
  }

  /**
   * Set the current filter model
   */
  setModel(model: GridFilterSelectModel): void {

    //Selected object current undefined
    if (model) {
      this.setSelectedObjectFromValue(model.selectedValue);
    }

    //Filter changed so we will call the callback to re-evaluate
    this.params.filterChangedCallback();
  }

  /**
   * Handel the onClick
   */
  onChange(value): void {

    //Set the selected object from the value supplied
    this.setSelectedObjectFromValue(value);

    //Filter changed so we will call the callback to re-evaluate
    this.params.filterChangedCallback();
  }

  /**
   * Set the currently selected object based on the passed value
   */
  setSelectedObjectFromValue(value) {
    this.selectedObject = getSelectedObjectFromValue(value, this.paramsCutsom);
  }
}

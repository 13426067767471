import { DataSetLibraryViewClassConfigAHubVO } from './dataset-library-view-class-config.ahub.vo';

export interface DataSetLibraryViewConfigAHubVO {
    dataSetId: number;
    libraryViewClassConfigs: DataSetLibraryViewClassConfigAHubVO[];
}

export const EMPTY_DATASET_LIBRARY_VIEW_CONFIG: DataSetLibraryViewConfigAHubVO = {
    dataSetId: undefined,
    libraryViewClassConfigs: [],
}

/**
 * A Tree node renderer designed to render product class information.
 */
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TreeConfiguration, TreeNodeVO } from 'modules/common/components/tree/tree.component';
import { componentDestroyStream, Hark } from 'modules/common/hark.decorator';
import { from, Observable } from 'rxjs';
import { combineLatest, map, startWith, takeUntil } from 'rxjs/operators';
import { aHubStateTemporaryProductClassProductCounts, aHubStateTemporaryProductPropertyAllocationIndexes } from 'selector/ahub/ahub-temporary.selector';
/**
 * Store access and control actions.
 */
import { StoreAccess } from 'store/store-access';
/** Value Objects  **/
import { ProductPropertySectionIndexAHubVO } from 'valueObjects/ahub/library/product-property-section-index.ahub.vo';

@Component({
  selector: 'app-product-class-node',
  templateUrl: './product-class-node.component.html',
  styleUrls: ['./product-class-node.component.css']
})
@Hark()
export class ProductClassNodeComponent implements OnInit, OnDestroy {

  @ViewChild('hoverable', { static: false }) hoverable: ElementRef;

  /**
   * The data to be rendered.
   */
  @Input() node: TreeNodeVO;

  /**
   * Tree configuration which discribes how teh tree should be rendered.
   */
  @Input() treeConfig: TreeConfiguration = new TreeConfiguration();

  /**
   * The colour of the current selected section.
   */
  productSectionIndexSelectedColour$: Observable<String>;

  /**
   * The current productSectionIndex we should be basing the content of our nodes on. ( in conjection with source data - classes)
   */
  productSectionIndex$: Observable<ProductPropertySectionIndexAHubVO>;

  /**
   * Count for the product class property
   */
  productClassPropertyCount$: Observable<number> = undefined;

  /**
   * The count of products associated to this product class.
   */
  productClassProductCount$: Observable<number> = undefined;

  /**
   * Node dimensions for drawing purposes.
   */
  nodeWidth: number = 100;
  nodeHeight: number = 75;
  toolTipText: string;

  constructor() {
    // This is intentional
  }

  ngOnInit() {

    // Observe the node parameters passed in by the tree, and update our contents accordingly.
    if (this.treeConfig.nodeParams$) {
      // Extract the current section we should use passed in via teh node params.
      this.productSectionIndex$ = this.treeConfig.nodeParams$.pipe(
        takeUntil(componentDestroyStream(this)),
        map(nodeParams => nodeParams.productSectionIndex));

      // Change the colour used based on the section in the params.
      this.productSectionIndexSelectedColour$ = this.treeConfig.nodeParams$.pipe(
        takeUntil(componentDestroyStream(this)),
        map(nodeParams => (nodeParams.productSectionIndex != undefined) ? nodeParams.productSectionIndex.colour : "#af8ac1"));
    }
    else {
      // We hav'nt been given a configuration - use defaults.
      this.productSectionIndex$ = from([undefined]);
      this.productSectionIndexSelectedColour$ = from(["#af8ac1"]);
    }


    //Create an observable while will allow us to count up the amount of properties associated to this class
    this.productClassPropertyCount$ = StoreAccess.dataGetObvs(aHubStateTemporaryProductPropertyAllocationIndexes).pipe(
      takeUntil(componentDestroyStream(this)),
      map(allocations => (allocations != undefined) ? allocations : []),
      map(allocations => allocations.filter(allocation => allocation.productClassId == this.node.data.productClassIndexAHubVO.id)),
      combineLatest(this.productSectionIndex$.pipe(startWith(undefined)), (allocations, section) => { return { allocations: allocations, section: section } }),
      map(({ allocations, section }) => (section != undefined) ? allocations.filter(allocation => allocation.productSectionId == section.id) : []),
      map(productClassAllocations => productClassAllocations.length));


    // Get the number of products in this product class.
    this.productClassProductCount$ = StoreAccess.dataGetObvs(aHubStateTemporaryProductClassProductCounts).pipe(
      takeUntil(componentDestroyStream(this)),
      map(productClassProductCounts => (productClassProductCounts != undefined) ? productClassProductCounts : []),
      map(productClassProductCounts => productClassProductCounts.find(productClassProductCount => productClassProductCount.productClassId === this.node.data.productClassIndexAHubVO.id)),
      map(productClassProductCount => (productClassProductCount) ? productClassProductCount.productCount : 0));


  }

  /**
   * Empty On destroy to ensure @Hark decorator works for an AOT build
   */
  ngOnDestroy() { }

  mouseEnterForTooltip($event) {
    this.toolTipText = $event.srcElement['id'];
  }

  mouseLeaveForTooltip($event) {
    this.toolTipText = undefined
  }

  selectClass() {
    this.treeConfig.nodeSelectFunction(this.node);
  }
}

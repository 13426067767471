import { Component, ComponentFactoryResolver, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AHubActions } from 'actions/ahub.actions';
import { DialogService } from 'modules/common/dialogs/dialog.service';
import { RequestActionMonitorService } from 'services/request-action-monitor/request-action-monitor.service';
import { StoreAccess } from 'store/store-access';
import { ClientQuotaAHubVO } from 'valueObjects/ahub/accounts/client-quota.ahub.vo';
import { EntityPermissions } from 'valueObjects/ahub/accounts/entity-permissions.ahub';
import { ClientQuotaHistoryDialogComponent } from './client-quota-history-dialog/client-quota-history-dialog.component';
import { ClientQuotaInfoDialogComponent } from './client-quota-info-dialog/client-quota-info-dialog.component';

@Component({
  selector: 'app-client-quota',
  templateUrl: './client-quota-card.component.html',
  styleUrls: ['./client-quota-card.component.css']
})
export class ClientQuotaCardComponent implements OnInit {
  unlimitedCheckedValue: boolean = false;

  @Input() clientQuota: ClientQuotaAHubVO;

  displayNames = {
    DATA_SETS: 'Content Sets',
    DATA_SIZE_BYTES: 'Data',
    DG_USERS: 'Distribution Group Users',
    DISTRIBUTIONS: 'Distributions',
    DIST_GROUPS: 'Distribution Groups',
    EXPORTS: 'Publications',
    WG_USERS: 'Workgroup Users',
    WORK_GROUPS: 'Workgroups',
    EXPORTS_SIZE_BYTES: 'Publications Total Size',
    LIBRARY_ASSET_COUNT: 'Library Assets',
    LIBRARY_ASSET_BYTES: 'Library Assets Size',
    LIBRARY_PRODUCTS: 'Library Products'
  };

  /**
   * Action request
   */
  actionRequestActionStatus$ = undefined;

  quotaForm = this.fb.group({
    id: [''],
    clientId: [''],
    lastUsageUpdate: [''],
    quotaType: [''],
    usage: [''],
    limit: [''],
    useDefaultLimit: ['']
  });

  /**
   * System level permissions which we will use for showing and hiding elements on the view
   */
  systemUser = EntityPermissions.SYSTEM_USER;

  /**
   * Tooltips for the wheel
   */
  usedTooltip: string = "";
  disabledTooltip: string = "";

  constructor(private fb: FormBuilder,
    private dialogueService: DialogService,
    private requestActionMonitorService: RequestActionMonitorService,
    private resolver: ComponentFactoryResolver) { }

  ngOnInit() {

    //Do we have a quota object?
    if (this.clientQuota) {

      //Is this extract unlimited
      this.unlimitedCheckedValue = this.clientQuota.limit == -1;

      //Setup the form with the quota info
      this.quotaForm.reset(this.clientQuota);

      //Setup the tool tips
      this.usedTooltip = 'Used: ' + (this.clientQuota.usage - this.clientQuota.discounted);
      this.disabledTooltip = 'Discounted: ' + this.clientQuota.discounted;
    }
  }

  /**
   * Open the history dialog for the quote supplied
   */
  openHistory(clientQuota: ClientQuotaAHubVO) {

    // Open the custom component dialogue , using the component factory to create the component content, passing in a simple VO.
    this.dialogueService.componentDialogOpen("Usage History", this.resolver.resolveComponentFactory(ClientQuotaHistoryDialogComponent), 'dialogVO', clientQuota, null, "OK");
  }

  /**
   * Open the info dialogue
   */
  openInfo(event) {

    let dialogVO = {
      quotaType: this.displayNames[this.quotaForm.controls.quotaType.value],
    };

    // Open the custom component dialogue , using the component factory to create the component content, passing in a simple VO.
    this.dialogueService.componentDialogOpen("Usage Info", this.resolver.resolveComponentFactory(ClientQuotaInfoDialogComponent), 'dialogVO', dialogVO, null, "OK");
  }

  /**
   * Handel the toggle of the unlimited tick box
   */
  unlimitedHandler() {

    this.quotaForm.markAsDirty();
    this.unlimitedCheckedValue = !this.unlimitedCheckedValue;

    if (this.unlimitedCheckedValue) this.quotaForm.controls['limit'].setValue(-1);
    else this.quotaForm.controls['limit'].setValue(0);
  }

  /**
   * Reset the form ready for re-use
   */
  resetHandler() {
    this.unlimitedCheckedValue = this.clientQuota.limit === -1 ? true : false;
    this.quotaForm.reset(this.clientQuota);
  }

  /**
  * Save handler to handel the client quota form save
  */
  clientQuotaSaveHandler() {

    const clientQuotaArray: ClientQuotaAHubVO[] = [this.quotaForm.value];

    // Save the distribution group.
    let saveActionId = StoreAccess.dispatch(AHubActions.clientQuotasCommit(clientQuotaArray));

    // Get an Observable which will indicate if this action is busy or not
    this.actionRequestActionStatus$ = this.requestActionMonitorService.requestActionStatusObservableByActionIdBusy(saveActionId);
    this.quotaForm.markAsPristine();
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'asteriskToAll'
})
export class AsteriskToAllPipe implements PipeTransform {

  transform(value: string, args?: any): any {
    if (value) {
      return value.replace('*', '<strong> any </strong>');
    }
  }

}

import { ColDef, ICellEditorParams } from 'ag-grid-community';
import { ProductPropertyTypeAHubVO } from 'valueObjects/ahub/library/product-property-type.ahub.vo';
import { ProductPropertyAHubVO } from 'valueObjects/ahub/library/product-property.ahub.vo';
import {
  ProductPropertyGridColumnReadUtil, ProductPropertyColumnReadParameters
} from 'modules/common/components/ag-grid/product-grid/utils/product-property-grid-column-read-util';
import { GridEditorComponentComponent } from 'modules/common/components/ag-grid/editors/grid-editor-component/grid-editor-component.component';
import { GridEditorSelectComponent, GridEditorSelectComponentParams } from 'modules/common/components/ag-grid/editors/grid-editor-select/grid-editor-select.component';
import { GridEditorBooleanComponent } from 'modules/common/components/ag-grid/editors/grid-editor-boolean/grid-editor-boolean.component';
import {
  GridEditorTextValidatedComponent,
  GridEditorTextValidatedParams
} from 'modules/common/components/ag-grid/editors/grid-editor-text-validated/grid-editor-text-validated.component';
import {
  ProductPropertyGridColumnMatrixUtil, ProductPropertyColumnWriteMatrixParameters
} from 'modules/common/components/ag-grid/product-grid/utils/product-property-gird-column-matrix-util';
import { GridEditorTextListComponent, GridEditorTextListParamsInterface } from '../../editors/grid-editor-text-list/grid-editor-text-list.component';
import { GridEditorMultiSelectComponent } from '../../editors/grid-editor-multi-select/grid-editor-multi-select.component';
import { ProductPropertyTableTypeAHubVO } from 'app/valueObjects/ahub/library/product-property-table-type.ahub.vo';
import { ProductDataMatrixDataAHubVO } from 'app/valueObjects/ahub/library/product-data-matrix-data.ahub.vo';
import { GridEditorDateComponent } from '../../editors/grid-editor-date/grid-editor-date.component';
import { GridEditorParamsInterface } from '../../editors/grid-editor-params-interface';

/**
 * Util class for the product property grid
 */
export class ProductPropertyGridColumnWriteUtil {

  /**
   * Defines the shape of the common framework components shared between the main grid and child grids
   */
  public static frameworkComponents() {

    // Add the write columns onto the stack required for the read
    return Object.assign(ProductPropertyGridColumnReadUtil.frameworkComponents(), {
      componentEditor: GridEditorComponentComponent,
      selectEditor: GridEditorSelectComponent,
      multiSelectEditor: GridEditorMultiSelectComponent,
      booleanEditor: GridEditorBooleanComponent,
      textValidatedEditor: GridEditorTextValidatedComponent,
      textListEditor: GridEditorTextListComponent,
      dateEditor: GridEditorDateComponent
    });
  }

  /**
   * Get a basic cell render with filters specificed for a property
   */
  public static columnWriteFilterForProperty(params: ProductPropertyColumnWriteParameters): ColDef {


    // Create a basic read column which we will expand upon to make it an editable column
    let colDef: ColDef = ProductPropertyGridColumnReadUtil.columnReadFilterForProperty(params);

    // Setup the column to use the validator editor
    colDef = ProductPropertyGridColumnWriteUtil.columnSetupEditorValidator(colDef, params);


    // Switch on each type to setup the property specific data
    switch (params.propertyType.reference) {

      case 'BOOLEAN':

        // Setup the boolean editor
        colDef.cellEditor = 'booleanEditor';

        // When editing cells we will prevent the click happening on the edit. The renderer has control for starting the edit
        // but not making the edit happen, the editor is in charge of editing the value
        colDef.onCellClicked = (event) => {
          event.api.stopEditing(true);
        };

        break;

      case 'TEXTLINE':
      case 'TOKEN':
      case 'WORD':

        // Do we have enum options
        if (params.property.enumOptions) {

          // Default editor parameters (used by enum options)
          const editorParams: GridEditorSelectComponentParams = ProductPropertyGridColumnWriteUtil.selectComponetForEnums(params);

          // Set the renderer and editors
          colDef.cellEditor = 'selectEditor';
          colDef.cellEditorParams = editorParams;
        }

        break;

      case 'TEXTLIST':
      case 'TOKENLIST':

        //Call the function to create the definition for this property type
        colDef = this.columnWriteFilterForPropertyListsColumnCreate(colDef, params);

        break;

      case 'DATE_TIME':

        //Set the date picker as the prefered format
        colDef.cellEditor = 'dateEditor';
        colDef.cellEditorParams = {
          canEditCellFunc: params.canEditCellFunc
        } as GridEditorParamsInterface;

        break;

      default:
        break;
    }


    return colDef;
  }

  /**
   * Create a column definition for a product property which represents a matrix
   *
   * @param params    Parameters for the column matrix renderer
   */
  public static columnWriteFilterForPropertyMatrix(params: ProductPropertyColumnWriteMatrixParameters): ColDef {
    return ProductPropertyGridColumnMatrixUtil.columnPropertyMatrix(params);
  }

  /**
   * Validates the data supplied against its type and property
   *
   * @param value                     Value which we want to evaluate
   * @param validateMatrixValue       We will evaluate the value as the property of the matrix not the value as the matrix as a whole
   * @param singleListValue           If this property is a list set true if you wish to just validate a single entry of the list
   */
  public static dataValueValidation(
    property: ProductPropertyAHubVO,
    propertyType: ProductPropertyTypeAHubVO,
    value: string | ProductDataMatrixDataAHubVO[],
    validateMatrixValue: boolean,
    singleListValue = false): string {

    // If the value is undefined then there is no error
    if (!value) {
      return undefined;
    }

    try {

      // MATRIX REGEX VALIDATION ----------------------------------------------------------------

      // Is this property a matrix?
      if (property.matrix && !validateMatrixValue) {

        //Call the matrix validation function
        ProductPropertyGridColumnWriteUtil.dataValueValidationMatrix(value);
        return;
      }

      // Trim the value
      const trimmedValue = (value as string).trim();


      // PROPERTY  REGEX VALIDATION ----------------------------------------------------------------

      //Make the call to validate the list
      ProductPropertyGridColumnWriteUtil.dataValueValidationPropertyRegEx(trimmedValue, propertyType, property, singleListValue);


      // NUMBER VALIDATION -----------------------------------------------------------------------

      // If the primitive type is a number then we will check limits
      if (propertyType.primitiveType === 'NUMBER') {
        ProductPropertyGridColumnWriteUtil.dataValueValidationNumber(trimmedValue, property);
      }


      // TABLE VALIDATION -----------------------------------------------------------------------

      // If the primitive type is a number then we will check limits
      if (propertyType.primitiveType === 'TABLE') {
        ProductPropertyGridColumnWriteUtil.dataValueValidationTable(trimmedValue);
      }


      // ENUM VALIDATION -----------------------------------------------------------------------

      // Does this property have enumOptions
      if (property.enumOptions && property.enumOptions.length > 0) {
        ProductPropertyGridColumnWriteUtil.dataValueValidationEnumOptions(trimmedValue, property, singleListValue)
      }

    } catch (error) {

      //The various validation functions will throw an error if the content is wrong
      //we will will return the error they genetated
      return error.message;
    }

    //It all looks ok so return undefined
    return undefined;
  }

  /**
   * ----------------------------------------------------------
   * Private fuctions
   * ----------------------------------------------------------
   */

  /**
   * Create the select component parameters for a property with enums
   *
   * @param params              Parameters for the column creation
   */
  private static selectComponetForEnums(params: ProductPropertyColumnWriteParameters): GridEditorSelectComponentParams {

    const property = params.property;

    // Default editor parameters (used by enum options)
    const editorParams = {
      values: [],
      canEditCellFunc: params.canEditCellFunc
    } as GridEditorSelectComponentParams;

    // Do we have enum options
    if (property.enumOptions) {

      // Its a 'LIST' type property so we should allow for multi selection from the drop down

      // Loop through all the enum options so that we can setup our editors and filters
      property.enumOptions.forEach((option, i) => {

        // Generate a display label for this option
        let displayVal = option;

        // If we have a display value then we will set it and use that
        if (property.enumDisplay && property.enumDisplay.length > i) {
          displayVal = property.enumDisplay[i];
        }

        // Add an option to the editor
        editorParams.values.push({
          label: (option === displayVal) ? option : `${option}: ${displayVal}`,
          value: option
        });
      });
    }
    return editorParams;
  }

  /**
   * Create the column definition for the list like columns
   *
   * @param colDef              Existing definition of the column
   * @param params              Parameters for the column creation
   */
  private static columnWriteFilterForPropertyListsColumnCreate(colDef: ColDef, params: ProductPropertyColumnWriteParameters) {

    // Do we have enum options
    if (params.property.enumOptions) {

      // Default editor parameters (used by enum options)
      const editorParams: GridEditorSelectComponentParams = ProductPropertyGridColumnWriteUtil.selectComponetForEnums(params);

      // Set the renderer and editors
      colDef.cellEditor = 'multiSelectEditor';
      colDef.cellEditorParams = editorParams;

    } else {

      colDef.cellEditor = 'textListEditor';
      colDef.cellEditorParams = {
        canEditCellFunc: params.canEditCellFunc,
        addItemRegex: params.propertyType.regExValid,
        addItemRegexError: `Input does not match Type Regular Expression: ${params.propertyType.regExValid}`
      } as GridEditorTextListParamsInterface;
    }

    //Return the defined column
    return colDef;
  }

  /**
   * Setup a column defininition for the editor value validator. This will validate the value against its property definition
   */
  private static columnSetupEditorValidator(
    colDef: ColDef,
    params: ProductPropertyColumnWriteParameters): ColDef {

    // Validation function which will take the value we wish to validate and return the first error it finds
    const dataValidationFunction = (value: string) =>
      ProductPropertyGridColumnWriteUtil.dataValueValidation(params.property, params.propertyType, value, true);

    // Setup the cell editor for the
    colDef.cellEditor = 'textValidatedEditor';

    colDef.cellEditorParams = {
      dataValidationFunc: (value) => (dataValidationFunction(value) !== undefined),
      dataValidationTooltipFunc: (value) => dataValidationFunction(value),
      canEditCellFunc: params.canEditCellFunc
    } as GridEditorTextValidatedParams;

    // Return the definition
    return colDef;
  }

  /**
   * Validate the matrix data
   *
   * @param value   matrix value to validate
   */
  private static dataValueValidationMatrix(value) {

    // We will validate that the value is a JSON object, if not we will throw the error
    let validMatrix = false;

    try {
      const o = value as ProductDataMatrixDataAHubVO[];
      validMatrix = (o !== undefined && o != null) && (typeof o === 'object' || Array.isArray(o));
    } catch (e) { console.error('dataValueValidation(): ', e); }

    // The matrix is not valid so we will only return the string
    if (!validMatrix) {
      throw new Error('Matrix value invalid');
    }
  }

  /**
   * Validate the value against the property type
   *
   * @param value             list value to validate
   * @param propertyType      type of proprty for the value
   * @param property          The property type of this item
   * @param listSingleValue   If the type is a list are we validating the list or treating as a single value
   *
   * @throws                   error validation message if it's invalid
   */
  private static dataValueValidationPropertyRegEx(value: string, propertyType: ProductPropertyTypeAHubVO, property: ProductPropertyAHubVO, listSingleValue: boolean) {

    // VALIDATE PROPERTY TYPE REGEX ----------------------------------------------

    // If this property is a LIST are we are not supplying a single list value
    if (propertyType.primitiveType === 'LIST' && !listSingleValue) {

      //Validate the property type as a list
      ProductPropertyGridColumnWriteUtil.dataValueValidationPropertyRegExList(value, propertyType);

    } else {

      // Create a regex for this primitive type
      const primitiveRegEx = RegExp(propertyType.regExValid);

      // If the regular expression didn't match then we will bail out
      if (!primitiveRegEx.test(value)) {
        throw new Error(`Invalid value: ${propertyType.description}`);
      }
    }


    // VALIDATE PROPERTY REGEX ----------------------------------------------

    // Do we have a regular expression for the property? If so we will need to test that aswell
    if (property.regExValid && property.regExValid.trim().length > 0) {

      // Create a regular expression we can use to test the value
      const propertyRegEx = RegExp(property.regExValid);

      // If the value doesn't match the regex then we will return an error
      if (!propertyRegEx.test(value)) {
        throw new Error(`Invalid value for: ${property.regExValid}`);
      }
    }
  }

  /**
   * Validate the property regex for a list type
   *
   * @param value             Value to validate
   * @param propertyType      Property type
   */
  private static dataValueValidationPropertyRegExList(value: string, propertyType: ProductPropertyTypeAHubVO) {

    let propertyValues: string[];

    try {
      propertyValues = JSON.parse(value);
    } catch (error) {
      throw new Error(`Property value: ${value} is not a JSON array! (e.g. ["foo","baa"])`);
    }

    // Loop though all of the values so we can validate them one by one
    for (const propertyValue of propertyValues) {

      // Get the property value from the list
      const trimmedListValue = propertyValue ? propertyValue.trim() : '';

      // Create a regex for this primitive type
      const primitiveRegEx = RegExp(propertyType.regExValid);

      // If the regular expression didn't match then we will bail out
      if (!primitiveRegEx.test(trimmedListValue)) {
        throw new Error(`Invalid value: ${propertyType.description}`);
      }
    }
  }

  /**
   * Validate the number
   *
   * @param value         value to validate
   * @param property      proprty for the value
   *
   * @throws              error validation message if it's invalid
   */
  private static dataValueValidationNumber(value: string, property: ProductPropertyAHubVO) {

    const upper = property.upperLimit;
    const lower = property.lowerLimit;
    const valueAsNumber: number = Number(value);
    if (upper && lower && (upper > lower)) {
      if (valueAsNumber > upper) {
        throw new Error(`${valueAsNumber} is greater than upper limit \'${upper}\'`);
      }
      if (valueAsNumber < lower) {
        throw new Error(`${valueAsNumber} is less than lower limit \'${lower}\'`);
      }
    }
  }

  /**
   * Validate the table
   *
   * @param value         value to validate
   *
   * @throws              error validation message if it's invalid
   */
  private static dataValueValidationTable(value: string) {

    try {
      const isItATable: ProductPropertyTableTypeAHubVO = JSON.parse(value);
    } catch (error) {

      throw new Error('property value is not a valid table e.g. ' +
        '\n{' +
        '\n\t"headerRow": ["header1", "header2"],' +
        '\n\t"rows": [' +
        '\n\t\t["foo", "baa"],' +
        '\n\t\t["baa", "foo"],' +
        '\n\t]' +
        '\n}');
    }
  }


  /**
   * Validate the enum options
   *
   * @param value         value to validate
   * @param property      property which are using to validate
   * @param singleValue   If the type is a list are we validating the list or treating as a single value
   *
   * @throws              error validation message if it's invalid
   */
  private static dataValueValidationEnumOptions(value: string, property: ProductPropertyAHubVO, singleValue: boolean) {

    let propertyValues: string[] = [];
    if (property.primitiveType === 'LIST' && !singleValue) {

      try {
        propertyValues = JSON.parse(value);
      } catch (error) {
        throw new Error(`Property value:${value} is not a JSON array! (e.g. ["foo","baa"])`);
      }

      for (const propertyValue of propertyValues) {

        // Do we have this option
        const haveOption = (property.enumOptions && property.enumOptions.indexOf(propertyValue) >= 0);

        // If we don't have the option then we will return an error
        if (!haveOption) {
          throw new Error(`${propertyValue} is not a valid option ${property.enumOptions}`);
        }
      }
    } else {

      // Do we have this option
      const haveOption = (property.enumOptions && property.enumOptions.indexOf(value) >= 0);

      // If we don't have the option then we will return an error
      if (!haveOption) {
        throw new Error(`${value} is not a valid option ${property.enumOptions}`);
      }
    }
  }


}

export interface ProductPropertyColumnWriteParameters extends ProductPropertyColumnReadParameters {
  propertyType: ProductPropertyTypeAHubVO,
  canEditCellFunc?: (params: ICellEditorParams) => boolean
}

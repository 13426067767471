import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'zeroToNone'
})
export class ZeroToNonePipe implements PipeTransform {

    transform(value: string, args?: any): any {
        if (value) {
            return value.replace(/^0/, 'None');
        }
    }

}

import { Component, ComponentFactoryResolver, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AHubActions } from 'app/store/actions/ahub.actions';
import { ComponentActions } from 'app/store/actions/component.actions';
import { ListUtil } from 'app/store/list.util';
import { List } from 'app/store/list.vo';
import { MapStorageUtil } from 'app/store/map-storage.util';
import {
  aHubStateTemporaryDataSetCategoryProductIdSorted,
  aHubStateTemporaryDataSetProductAssets,
  aHubStateTemporaryDataSetProducts,
  aHubStateTemporaryProductPropertyAllocationIndexes,
  aHubStateTemporaryProductClassIndexes
} from 'app/store/selector/ahub/ahub-temporary.selector';
import { componentDataSetProductStateMap } from 'app/store/selector/component/component-data-set-products.selector';
import { StoreAccess } from 'app/store/store-access';
import { SectionPropertyAllocationsStream } from 'app/store/stream/section-property-allocations.stream';
import { DataSetCategoryIndexAHubVO } from 'app/valueObjects/ahub/library/dataset-category-index.ahub.vo';
import { DataSetAHubVO } from 'app/valueObjects/ahub/library/dataset.ahub.vo';
import { ProductAssetAHubVO } from 'app/valueObjects/ahub/library/product-asset.ahub.vo';
import { ProductAHubVO } from 'app/valueObjects/ahub/library/product.ahub.vo';
import { PropertyAllocationObjectVO } from 'app/valueObjects/stream/product-allocation-object-stream.vo';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, publishReplay, refCount, takeUntil, tap, delay } from 'rxjs/operators';
import { DialogService } from '../../dialogs/dialog.service';
import { componentDestroyStream, Hark } from '../../hark.decorator';
import { Utils } from '../../utils';
import { CategoryProductsViewComponent } from '../../vo-render/category-products-view/category-products-view.component';
import { ProductViewData } from '../../vo-render/product-view/product-view.component';
import { DataSetLibraryViewConfigAHubVO } from 'app/valueObjects/ahub/library/dataset-library-view-config.ahub.vo';
import { getProductPropertyValueByAlloc } from '../../product-utils';
import { LibraryViewUtils } from '../../library-view-utils';
import { ProductClassIndexAHubVO } from 'app/valueObjects/ahub/library/product-class-index.ahub.vo';

@Component({
  selector: 'app-dataset-product-view',
  templateUrl: './dataset-product-view.component.html',
  styleUrls: ['./dataset-product-view.component.css']
})
@Hark()
export class DatasetProductViewComponent implements OnInit, OnDestroy {

  /**
   * Get the category product view
   */
  @ViewChild('catProductView', { static: false })
  catProductView: CategoryProductsViewComponent;

  /**
   * Generate a random string for the search id!
   */
  @Input()
  public uniqueId: string = undefined;

  /**
   * Label to be used as the root of the category list tree
   */
  readonly rootNodeLabel = 'Root Category';

  /**
   * Data set which we are currently viewing
   */
  @Input()
  public dataSet$: Observable<DataSetAHubVO>;

  /**
   * merged library view config observable for product view full
   */
  mergedLibraryViewConfig$: Observable<DataSetLibraryViewConfigAHubVO>;

  libraryViewConfigNotSet$: Observable<boolean>;

  /**
   * The id of the current dataset being viewed, based on above.
   */
  dataSetIdCurrent = -1;

  /**
   * Should we horizontally flip the layout?
   */
  @Input()
  public flipLayout = false;

  /**
   * Product View Full Open
   */
  @Input()
  public productViewFullOpen$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /**
   * the product count so parent can detect quantity of products
   */
  @Input()
  public productCountForParent$: BehaviorSubject<number> = new BehaviorSubject(0);

  /**
     * Config click
     */
  @Output()
  public configClick: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Data Set Product Map
   */
  dataSetProductMap$: Observable<List<ProductAHubVO>>;

  // Grid Size
  largeGrid$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  /**
   * Watch the state of this component
   */
  componentState$ = StoreAccess.dataGetObvs(componentDataSetProductStateMap)
    .pipe(
      map(stateMap => MapStorageUtil.mapStorageGet(stateMap, this.uniqueId)),
      filter(item => item !== undefined)
    );

  /**
   * Stream to watch the current data set category id
   */
  dataSetCategoryId$ = this.componentState$.pipe(
    map(state => state.dataSetCategoryId),
    distinctUntilChanged());

  /**
 * Stream to watch the current data set category selected product id
 */
  dataSetCategorySelectedProductId$: Observable<number> = this.componentState$.pipe(
    map(state => state.dataSetCategorySelectedProductId),
    distinctUntilChanged()
  );

  /**
 * Stream to watch the current data set category selected product
 */
  dataSetCategorySelectedProduct$: Observable<ProductViewData>;

  /**
   * Stream for the search results for the given category
   */
  searchForCategory$ = this.componentState$.pipe(
    map(state => state.dataSetSearchProducts ? state.dataSetSearchProducts : undefined)
  );

  /**
   * Data set categories for the
   */
  dataSetCategories$: BehaviorSubject<DataSetCategoryIndexAHubVO[]> = new BehaviorSubject([]);

  /**
   * Id of the currently selected data set for the category
   */
  private dataSetCategoryId = -1;

  /**
   * Product id's for the current category
   */
  productIds$: Observable<number[]> = StoreAccess.dataGetObvs(aHubStateTemporaryDataSetCategoryProductIdSorted)
    .pipe(
      map(dataSetProductIdMap => MapStorageUtil.mapStorageGet(dataSetProductIdMap, this.dataSetCategoryId.toString())),
      map(ids => ids ? ids : []));

  /**
   * Map for the product data which relates to our current data set
   */
  productDataMap$: BehaviorSubject<List<ProductAHubVO>> = new BehaviorSubject(undefined);

  /**
   * Data for the selected product, used to fill the 'product details' page
   */
  selectedProductViewData$: BehaviorSubject<ProductViewData> = new BehaviorSubject(undefined);

  /**
   * Title contructed for the selected product (used by the product view full)
   */
  selectedProductTitle: string = '';

  /**
   * Observable version of the above which is also shared
   */
  productDataMapObv$: Observable<List<ProductAHubVO>> = this.productDataMap$
    .asObservable().pipe(publishReplay(1), refCount());

  /**
   * Map for the product assets which relates to our current data set
   */
  productAssetsMap$: BehaviorSubject<List<ProductAssetAHubVO>> = new BehaviorSubject(undefined);

  /**
   * Observable version of the above which is also shared
   */
  productAssetsMapObv$: Observable<List<ProductAssetAHubVO>> = this.productAssetsMap$
    .asObservable().pipe(publishReplay(1), refCount());

  /**
   * Product id's which we want to be displayed, this could be filtered to a search
   */
  productIdsSearched$: Observable<number[]> = combineLatest([
    this.productIds$,
    this.searchForCategory$
  ])
    .pipe(map(([productIds, searchResults]) => {

      //If we have search results for this category then we will return the search results
      if (searchResults && searchResults.dataSetCategoryId === this.dataSetCategoryId) {
        return searchResults.productIds;
      }

      //We didn't have searcg data so use the regular id list
      return productIds;
    }));

  /**
   * Product Count
   */
  updatingProductCount$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  productCount$: Observable<number> = this.productIdsSearched$.pipe(
    tap(productIds => {
      setTimeout(() => this.updatingProductCount$.next(true))
      setTimeout(() => {
        this.updatingProductCount$.next(false);
      }, 1000)
    }),
    map(productIds => productIds.length)
  );


  selectedDataSetProductPropertyAllocationStreamObjects$: Observable<PropertyAllocationObjectVO[]>;

  // We fetch them once in content-view
  allocIndex$ = StoreAccess.dataGetObvs(aHubStateTemporaryProductPropertyAllocationIndexes).pipe(publishReplay(1), refCount());
  selectedCategory: DataSetCategoryIndexAHubVO;

  // Lets have a function which can sort our tree siblings by priority.
  // We assume that tree siblings will have a common 'ancestry'
  sortSiblingsByPriority = (list: DataSetCategoryIndexAHubVO[]) => {

    // Firstly, lets group our categories by ancestry
    const ancestryGrouped: Map<string, DataSetCategoryIndexAHubVO[]> = new Map();

    list.forEach(category => {

      if (ancestryGrouped.has(category.ancestry)) {
        const ancestryGroupDatasets = ancestryGrouped.get(category.ancestry);
        ancestryGroupDatasets.push(category);
      } else {
        ancestryGrouped.set(category.ancestry, [category]);
      }

    });

    // Now we can sort each group of 'siblings' by priority
    ancestryGrouped.forEach((categories, key) => {
      categories.sort((a, b) => a.priority === b.priority ? 0 : a.priority > b.priority ? 1 : -1)
    })

    return [].concat.apply([], (Array.from(ancestryGrouped.values())));
  }

  productsPerRow = 6;
  selectedCategoryId$: BehaviorSubject<number> = new BehaviorSubject(0);

  constructor(private readonly dialogueService: DialogService,
    private readonly resolver: ComponentFactoryResolver) { }


  ngOnInit() {

    this.productCount$.pipe(
      takeUntil(componentDestroyStream(this)),
      // handle expressionchangedafterithasbeencheckederror
      delay(0)
    ).subscribe(count => {
      this.productCountForParent$.next(count)
    });

    this.productViewFullOpen$.pipe(
      takeUntil(componentDestroyStream(this)),
      filter(open => open === false)
    ).subscribe(open => {
      StoreAccess.dispatch(ComponentActions.componentDataSetProductsDataSetCategorySelectProductIdSet(this.uniqueId, undefined))
    });

    this.dataSetCategorySelectedProductId$.pipe(
      takeUntil(componentDestroyStream(this)),
      filter(productID => productID !== undefined)
    ).subscribe(open => {
      this.productViewFullOpen$.next(true);
    });

    // We need to record what dataset ID we are curently using, for later service requests.
    this.dataSet$.pipe(
      takeUntil(componentDestroyStream(this))
    ).subscribe(dataSet => { // Set to -1 if dataset not suppled.
      this.dataSetIdCurrent = (dataSet === undefined) ? -1 : dataSet.id;

      // We should make sure the full product view is closed if the whole dataset is changed...makes no sense keeping it open
      StoreAccess.dispatch(ComponentActions.componentDataSetProductsDataSetCategorySelectProductIdSet(this.uniqueId, undefined));
    });


    //Watch the data set we need to set the categories into our behaviour subject so we can update our tree
    this.dataSet$.pipe(
      // map(dataSet => dataSet ? dataSet.dataSetCategories : undefined),
      // map(datasetCategories => datasetCategories ? datasetCategories : []),
      takeUntil(componentDestroyStream(this))
    )
      .subscribe(dataSet => {
        const dataSetCats: DataSetCategoryIndexAHubVO[] = (dataSet && dataSet.dataSetCategories) ? dataSet.dataSetCategories : [];
        this.dataSetCategories$.next(dataSetCats);
      });

    //Watch for the category change, we will need to get the product id's for these categories
    this.dataSetCategoryId$
      .pipe(
        takeUntil(componentDestroyStream(this)),
        filter(categoryId => categoryId !== undefined && categoryId > 0))
      .subscribe(categoryId => {

        //Set the id locally for easy use
        this.dataSetCategoryId = categoryId;

        this.selectedCategoryId$.next(categoryId);

        //Dispatch an action to fetch the product id's
        StoreAccess.dispatch(AHubActions.dataSetCategoryProductIdSortedFetch(categoryId));

        //Remove the search data
        this.removeSearch();

        //Reset the scrolling when removing the search info
        this.categoryProductViewScrollerReset();
      });



    //Watch for changes to the product assets or data set!
    combineLatest([
      StoreAccess.dataGetObvs(aHubStateTemporaryDataSetProductAssets).pipe(
      ),
      this.dataSet$.pipe(filter(d => d !== undefined))
    ])
      .pipe(
        map(([dataMap, dataSet]) => MapStorageUtil.mapStorageGet(dataMap, dataSet.id.toString())),
        takeUntil(componentDestroyStream(this))
      )
      .subscribe(data => this.productAssetsMap$.next(data));

    this.dataSetProductMap$ = combineLatest([
      StoreAccess.dataGetObvs(aHubStateTemporaryDataSetProducts),
      this.dataSet$.pipe(filter(d => d !== undefined))
    ])
      .pipe(
        map(([dataMap, dataSet]) => MapStorageUtil.mapStorageGet(dataMap, dataSet.id.toString())),
        takeUntil(componentDestroyStream(this))
      )

    //Create a stream for the allocation id's
    this.selectedDataSetProductPropertyAllocationStreamObjects$ =
      combineLatest([
        SectionPropertyAllocationsStream.productAllocationObjectStreamDataGet(this.allocIndex$),
        this.dataSet$,
      ]).pipe(
        takeUntil(componentDestroyStream(this)),
        map(([allocs, dataSet]) => {

          //No data then return an empty array
          if (!allocs || !dataSet || !dataSet.sections) {
            return [];
          }

          //Limit the allocations to the sections we care about as part of this data set
          return allocs.filter(alloc => dataSet.sections.find(sectionId => alloc.section.id === sectionId));
        }));

    //Watch for changes to the product data or data set!
    this.dataSetProductMap$.pipe(
      Utils.isNotNullOrUndefined(),
      takeUntil(componentDestroyStream(this)),
    ).subscribe(productMap => this.productDataMap$.next(productMap))

    this.mergedLibraryViewConfig$ = this.dataSet$.pipe(
      Utils.isNotNullOrUndefined(),
      map(dataset => dataset.libraryViewConfig)
    )

    this.dataSetCategorySelectedProduct$ = combineLatest([
      this.dataSetCategorySelectedProductId$,
      this.dataSetCategoryId$,
      this.mergedLibraryViewConfig$,
      StoreAccess.dataGetObvs(aHubStateTemporaryProductClassIndexes).pipe(
        Utils.isNotNullOrUndefined(),
        filter(classIndexes => classIndexes.length > 0)
      ),
    ]).pipe(
      map(([dataSetCategorySelectedProductId, dataSetCategorySelectedId, mergedLibraryViewConfig, productClassIndexes]) => {
        // If the product view full is closed, we set the selected product id to undefined,
        // Lets just return undefined for dataSetCategorySelectedProduct$, effectively closing the details panel
        if ((!dataSetCategorySelectedProductId) || (dataSetCategorySelectedId === -1)) {
          return undefined;
        }

        // Lets get the category index for the selected category id so we could pass it into the
        // product-view-full
        const datasetCategories: DataSetCategoryIndexAHubVO[] = this.dataSetCategories$.getValue();

        if (datasetCategories) {
          this.selectedCategory = datasetCategories.find(category => category.id === dataSetCategorySelectedId)
        }

        // Has a product been selected to view in more detail?
        // Welll we should make it fill the area of this panel
        const productMap = this.productDataMap$.getValue();
        const selectedProduct: ProductAHubVO = ListUtil.listDataItemGet(productMap, dataSetCategorySelectedProductId);

        return {
          id: dataSetCategorySelectedProductId,
          product$: of(selectedProduct),
          productAssets$: this.productAssetsMapObv$.pipe(map(list => ListUtil.listDataItemGet(list, dataSetCategorySelectedProductId.toString()))),
          allocationStream$: this.selectedDataSetProductPropertyAllocationStreamObjects$,
          libraryConfig$: this.mergedLibraryViewConfig$,
          componentId: this.uniqueId,
          dataSetId: this.dataSetIdCurrent,
          largeGrid$: this.largeGrid$,
          productClassIndexes: productClassIndexes
        }
      }),
      takeUntil(componentDestroyStream(this))
      // TODO: I think this is being called multiple times.

    )

    // Listed for changes in the product selected from the dataset, so we can request information on related assets of
    // the product we are looking at in detail.
    this.dataSetCategorySelectedProductId$.pipe(
      takeUntil(componentDestroyStream(this)),
      filter(value => value !== undefined)
    ).subscribe(dataSetCategorySelectedProductId => {
      StoreAccess.dispatch(AHubActions.dataSetProductAssetsByIdsFetch(this.dataSetIdCurrent, [dataSetCategorySelectedProductId]));
    })

    this.libraryViewConfigNotSet$ = this.dataSet$.pipe(
      map(dataSet => (
        !dataSet ||
        !dataSet.libraryViewConfig ||
        !dataSet.libraryViewConfig.libraryViewClassConfigs ||
        dataSet.libraryViewConfig.libraryViewClassConfigs.length === 0)
      )
    )



    // Lets construct a title for a selected product (product full view)
    combineLatest([
      this.mergedLibraryViewConfig$.pipe(
        Utils.isNotNullOrUndefined()
      ),
      this.selectedDataSetProductPropertyAllocationStreamObjects$,
      this.dataSetCategorySelectedProductId$.pipe(
        Utils.isNotNullOrUndefined()
      ),
      StoreAccess.dataGetObvs(aHubStateTemporaryProductClassIndexes).pipe(
        Utils.isNotNullOrUndefined()
      )]).pipe(
        map(([libraryConfig, allocs, dataSetCategorySelectedProductId, classIndex]) => {
          // Lets create a suitable title for this product
          return this.createProductTitle(libraryConfig, dataSetCategorySelectedProductId, allocs, classIndex);
        })
      ).subscribe(productTitle => this.selectedProductTitle = productTitle);

  }

  /**
   * Create a product title to display
   *
   * @param mergedLibraryViewConfig
   * @param dataSetCategorySelectedProductId
   * @param allocs
   * @param classIndex
   */
  createProductTitle(mergedLibraryViewConfig: DataSetLibraryViewConfigAHubVO, dataSetCategorySelectedProductId: number,
    allocs: PropertyAllocationObjectVO[], classIndex: ProductClassIndexAHubVO[]): string {

    let productTitle = '...';

    // grab the selected product data
    const productMap = this.productDataMap$.getValue();
    const selectedProduct: ProductAHubVO = ListUtil.listDataItemGet(productMap, dataSetCategorySelectedProductId);

    //We have no selected product, then we will have to bail out
    if (!selectedProduct) {
      return productTitle
    }

    // Lets default our product title to the one thing we can assume about a product...its ahub id
    productTitle = `aHub Product Id: ${selectedProduct.id}`;

    // grab the library config appropriate (matching class id) for this product

    // Lets get the config for this product class (or its nearest ancestor)
    const libraryViewClassConfig = LibraryViewUtils.getNearestLibraryViewClassConfigByClassId(mergedLibraryViewConfig, selectedProduct.productClassId, classIndex);

    if (!libraryViewClassConfig) {
      return productTitle;
    }

    const productIdentifierAllocId = libraryViewClassConfig.productIdentifierPropertyAlloc;

    const productIdentifierAlloc: PropertyAllocationObjectVO = allocs.find(alloc => alloc.id === productIdentifierAllocId)

    const productValueForAlloc: string = getProductPropertyValueByAlloc(selectedProduct, productIdentifierAlloc);

    if (productValueForAlloc) {
      productTitle = productValueForAlloc;
    }

    return productTitle;
  }

  ngOnDestroy() {

    //Dispatch an action so that we loose all the component data!
    StoreAccess.dispatch(ComponentActions.componentDataSetProductsRemove(this.uniqueId));
  }

  /**
   * Handel the category change event from the tree list
   *
   * @param category
 */
  categoryTreeSelectItem(category) {

    //If we have a category object we will dispatch an event setting it into the store
    if (category) {
      StoreAccess.dispatch(ComponentActions.componentDataSetProductsDataSetCategoryIdSet(this.uniqueId, category.id));
    }
  }

  /**
   * Remove the search based on the search id
   */
  removeSearch() {

    //Call to delete the search by it's given id
    StoreAccess.dispatch(ComponentActions.componentDataSetProductSearchDelete(this.uniqueId));

    //Reset the scrolling when removing the search info
    this.categoryProductViewScrollerReset();
  }

  closeClick() {
    this.productViewFullOpen$.next(false);
  }

  /**
   * Function to handle the click on the config box
   */
  clickConfig() {
    this.configClick.emit();
  }

  /**
   * Reset the scrolling position for the category product view
   */
  categoryProductViewScrollerReset() {

    //If we have a category scroller
    if (this.catProductView) {
      this.catProductView.scrollerReset();
    }
  }

  gridSize() {
    this.largeGrid$.next(!this.largeGrid$.getValue());
  }

}

import { Component, ComponentFactory, ViewContainerRef, ViewChild, ComponentRef } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-grid-renderer-component',
  template: '<div #placeholder></div>',
  styleUrls: ['./grid-renderer-component.component.css']
})
export class GridRendererComponentComponent implements ICellRendererAngularComp {

  @ViewChild('placeholder', {
    read: ViewContainerRef,
    static: true
  }) placeholder: ViewContainerRef;

  /**
   * Parameters for the component
   */
  params: ICellRendererParams = undefined;

  /**
   * Parameters for the component
   */
  paramsWithCustom: GridRendererComponentParams = undefined;

  /**
   * The component factory passed in to create the components for object display.
   */
  componentFactory: ComponentFactory<ICellRendererAngularComp>; //Class?

  /**
   * The created component in the wrapper.
   */
  public createdComponent: ComponentRef<ICellRendererAngularComp>;


  constructor() {
    // This is intentional
  }

  /**
  * Call after the init function
  */
  agInit(params: any): void {

    //Set the parameters
    this.params = params as ICellRendererParams;
    this.paramsWithCustom = params as GridRendererComponentParams;
  }

  /**
   * Call the refresh
   */
  refresh(params: any): boolean {

    //Para
    this.params = params as ICellRendererParams;

    //Call the refresh with the parameters
    return (this.createdComponent && this.createdComponent.instance && this.createdComponent.instance.refresh) ? this.createdComponent.instance.refresh(params) : false;
  }

  /**
   * Wait for after the view has initalise
   */
  ngAfterViewInit(): void {

    //Create a component using the factory supplied in our wrapper.
    if (this.paramsWithCustom.factory) {

      //Set the factory
      this.componentFactory = this.paramsWithCustom.factory;

      //Create the component
      this.createdComponent = this.placeholder.createComponent(this.componentFactory);
    }

    //Do we have a created component
    if (this.createdComponent && this.createdComponent.instance && this.createdComponent.instance.agInit) {

      //Call the agInit with its parameters
      this.createdComponent.instance.agInit(this.params);
    }
  }
}

/**
 * Parameters interface used to type the parameters passed to this class. This class should be extended if the user wishes to supply their own parameters
 * to their custom editor
 */
export interface GridRendererComponentParams {
  factory: ComponentFactory<ICellRendererAngularComp>
}

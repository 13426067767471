import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'bytesToMegaBytes'
})
export class BytesToMegaBytesPipe implements PipeTransform {

    transform(value: number, args?: any): any {
        if (value == 0) {
            return '';
        }
        const k = 1024,
            dm = 0,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(value) / Math.log(k));
        return parseFloat((value / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

}

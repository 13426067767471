
import { takeUntil } from 'rxjs/operators';
import { Directive, ElementRef, Input, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { PermissionsService } from 'services/permissions/permissions.service';

// valueObjects
import { EntityPermissionAHubVO } from 'valueObjects/ahub/accounts/entity-permission.ahub.vo';

// Hark component management
import { Hark, componentDestroyStream } from 'modules/common/hark.decorator';

/**
 * Element should be editable (not readonly) unless current user does not have sufficient permissions.
 *
 * ### Syntax
 *
 * - `<div *editableAs="accountsEditor">...</div>`
 * where accountsEditor is a local variable in the template's component
 * set from some convenient statics like so:
 *
 * import { EntityPermissions } from 'valueObjects/ahub/accounts/entity-permissions.ahub';
 *
 * accountsEditor = EntityPermissions.ACCOUNTS_EDITOR;
 *
 */
@Directive({
    selector: '[editableAs]'
})
@Hark()
export class EditableAs implements OnInit, OnDestroy {

    @Input('editableAs') editableAs: EntityPermissionAHubVO;

    constructor(private _el: ElementRef, private permissionsService: PermissionsService, private render: Renderer2) { }

    ngOnInit() {

        this.permissionsService.entityPermissions$.pipe(
            takeUntil(componentDestroyStream(this)))
            .subscribe(entityPermissions => {
                if (entityPermissions) {

                    (!this.permissionsService.userHasPermissionToView(this.editableAs)) ? this.render.addClass(this._el.nativeElement, 'disabled') : this.render.removeClass(this._el.nativeElement, 'disabled');
                }
            });
    }

    /**
     * Empty On destroy to ensure @Hark decorator works for an AOT build
     */
    ngOnDestroy() { }
}
import { RelationshipAHubVO } from 'valueObjects/ahub/relationship.ahub.vo';

/**
 * Utility class for managing lists
 */
export class SelectorUtil {

    static relationships = <T>(state, selector: (data) => RelationshipAHubVO<T>): T[] => {

        if (!selector) {
            return undefined;
        }

        const relationship = selector(state);

        return (relationship) ? relationship.relationships : undefined;
    }
}
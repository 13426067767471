import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.css']
})
export class ErrorDialogComponent implements OnInit {

  /**
   * Parameters for the dialogue (must be null or property isn't found!)
   */
  public dialogParams: ErrorDialogParameters = null;

  /**
   * Is the hidden message being displayed
   */
  hiddenMessageOpen: boolean = false;

  constructor() {
    // This is intentional
  }

  ngOnInit() {
  }

}

export interface ErrorDialogParameters {
  message: string,
  hiddenMessage: string[]
}

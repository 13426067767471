import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-time-display',
  templateUrl: './time-display.component.html',
  styleUrls: ['./time-display.component.css']
})
export class TimeDisplayComponent implements OnInit {

  constructor() {
    // This is intentional
  }

  @Input()
  /**
   * Start Date:
   * If no start date has been added this component will default
   * to displaying the time between now and the end date.
   */
  startDate: Date;

  @Input()
  /**
   * End Date.
   */
  endDate: Date;

  @Input()
  /**
   * Expiry Message:
   * If no expiry message has been added the element will disapear.
   */
  expiryMessage: string;

  @Input()
  /**
   * Expiry Range:
   * The date range that the expiry message turns red.
   * If not set the text will turn red once the date starts expiring.
   */
  expiryRange: string;

  ngOnInit() {
    this.remainingTime()
  }
  /**
   * Displays the date string in the view.
   */
  remainingTime(): string {

    const now = new Date().getTime();
    const startDate = new Date(this.startDate).getTime();

    if (this.endDate) {

      const end = new Date(this.endDate).getTime();

      // Check if expired if so return only the expired message.
      if (now >= end) {
        return this.expiryMessage;
      }

      // Is time between before or after the start date?
      const timeBetween = (this.startDate && startDate >= now) ? new Date(startDate - now) : new Date(end - now);

      // Calculate the remaining time and format to a string.
      let timeArray = [];
      timeArray.push(this.formatTimeString((timeBetween.getUTCFullYear() - 1970), "year"));
      timeArray.push(this.formatTimeString(timeBetween.getUTCMonth(), "month"));
      timeArray.push(this.formatTimeString((timeBetween.getUTCDate() - 1), "day"));
      timeArray.push(this.formatTimeString(timeBetween.getUTCHours(), "hour"));
      timeArray.push(this.formatTimeString(timeBetween.getUTCMinutes(), "minute"));

      // Filter out blank values
      timeArray = timeArray.filter(Boolean);

      // Use only the two greatest values
      const timeString = `${timeArray[0]}${(timeArray[1]) ? ", " + timeArray[1] : ''}`;

      // Return a string of days remaining
      return (startDate >= now) ? `Begins in ${timeString}` : `${timeString} remaining`;
    }

    return '';
  }
  /**
   * Check if time has entered expiry range for styling
   */
  expiryRangeActive(): boolean {

    const startDate = new Date(this.startDate).getTime();
    const now = new Date().getTime();
    const end = new Date(this.endDate).getTime();
    let isExpiring = (now >= startDate);

    if (this.expiryRange) {

      const timeBetween = new Date(end - now);

      switch (this.expiryRange) {
        case "MONTH":
          isExpiring = (timeBetween.getUTCMonth() < 1);
          break;
        case "WEEK":
          isExpiring = ((timeBetween.getUTCDate() - 1) < 7)
          break;
        default:
          break;
      }
    }

    return isExpiring;
  }
  /**
   * Detect if prior to or expiring for template styling
   */
  isPriorTo(): boolean {
    const now = new Date().getTime();
    const startDate = new Date(this.startDate).getTime();
    return this.startDate && startDate >= now;
  }
  /**
   * Formats the single time unit string:
   * Detects if the time unit is plural
   */
  formatTimeString(timeUnit: number, unitTitle: string) {
    let timeString = "";
    if (timeUnit !== 0) {
      // Check unit is plural
      unitTitle = (timeUnit > 1) ? `${unitTitle}s` : unitTitle;
      // Add unit seperator to final output
      timeString = `${timeUnit} ${unitTitle}`;
    }
    return timeString;
  }
}

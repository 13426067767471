import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CdkTableModule } from '@angular/cdk/table';
// ag-grid
import { AgGridModule } from "ag-grid-angular";
import { FabIconBusyButtonComponent } from 'modules/common/buttons/fab-icon-busy-button/fab-icon-busy-button.component';
import { RaisedBusyButtonComponent } from 'modules/common/buttons/raised-busy-button/raised-busy-button.component';
import { GridEditorBooleanComponent } from 'modules/common/components/ag-grid/editors/grid-editor-boolean/grid-editor-boolean.component';
import { GridEditorComponentComponent } from 'modules/common/components/ag-grid/editors/grid-editor-component/grid-editor-component.component';
import { GridEditorDateComponent } from 'modules/common/components/ag-grid/editors/grid-editor-date/grid-editor-date.component';
import { GridEditorMultiSelectComponent } from 'modules/common/components/ag-grid/editors/grid-editor-multi-select/grid-editor-multi-select.component';
import { GridEditorSelectComponent } from 'modules/common/components/ag-grid/editors/grid-editor-select/grid-editor-select.component';
import { GridEditorTextListComponent } from 'modules/common/components/ag-grid/editors/grid-editor-text-list/grid-editor-text-list.component';
import { GridEditorTextValidatedComponent } from 'modules/common/components/ag-grid/editors/grid-editor-text-validated/grid-editor-text-validated.component';
import { GridFilterComponentComponent } from 'modules/common/components/ag-grid/filters/grid-filter-component/grid-filter-component.component';
import { GridFilterFloatingComponentComponent } from 'modules/common/components/ag-grid/filters/grid-filter-floating-component/grid-filter-floating-component.component';
import { GridFilterSelectFloatingComponent } from 'modules/common/components/ag-grid/filters/grid-filter-select-floating/grid-filter-select-floating.component';
import { GridFilterSelectComponent } from 'modules/common/components/ag-grid/filters/grid-filter-select/grid-filter-select.component';
import { GridGroupHeaderCheckboxComponent } from 'modules/common/components/ag-grid/headers/grid-group-header-checkbox/grid-group-header-checkbox.component';
import { GridGroupHeaderMenuComponent } from '../common/components/ag-grid/headers/grid-group-header-menu/grid-group-header-menu.component';
import { GridGroupHeaderCountComponent } from 'modules/common/components/ag-grid/headers/grid-group-header-count/grid-group-header-count.component';
import { GridHeaderCountComponent } from 'modules/common/components/grid-header-count/grid-header-count.component';
import { ProductPropertyMatrixEditorComponent } from 'modules/common/components/ag-grid/product-grid/component/product-property-matrix-editor/product-property-matrix-editor.component';
import { GridRendererBooleanComponent } from 'modules/common/components/ag-grid/renderer/grid-renderer-boolean/grid-renderer-boolean.component';
//AG-Grid compoenents
import { GridRendererComponentComponent } from 'modules/common/components/ag-grid/renderer/grid-renderer-component/grid-renderer-component.component';
import { GridRendererDateComponent } from 'modules/common/components/ag-grid/renderer/grid-renderer-date/grid-renderer-date.component';
import { GridRendererMaterialIconComponent } from 'modules/common/components/ag-grid/renderer/grid-renderer-material-icon/grid-renderer-material-icon.component';
import { GridRendererTextCopyClipboardComponent } from 'modules/common/components/ag-grid/renderer/grid-renderer-text-copy-clipboard/grid-renderer-text-copy-clipboard.component';
import { CalloutInputComponent } from 'modules/common/components/callout-input/callout-input.component';
import { FormListComponent } from 'modules/common/components/form-list/form-list.component';
import { IndexListComponent } from 'modules/common/components/index-list/index-list.component';

//Components
import { JsonFormControlErrorDisplayComponent } from 'modules/common/components/json-form-control-error-display/json-form-control-error-display.component';
import { ListAddInputComponent } from 'modules/common/components/list-add-input/list-add-input.component';
import { ListSearchHeaderComponent } from 'modules/common/components/list-search-header/list-search-header.component';
import { PercentageCircleSlicedComponent } from 'modules/common/components/percentage-circles/percentage-circle-sliced/percentage-circle-sliced.component';
import { PercentageCircleComponent } from 'modules/common/components/percentage-circles/percentage-circle/percentage-circle.component';
import { NotificationDetailDialogComponent } from 'modules/common/components/system-health/notification-detail-dialog/notification-detail-dialog.component';
import { NotificationRecordComponent } from 'modules/common/components/system-health/notification-record/notification-record.component';
// system heath and notification history, common component.
import { SystemHealthComponent } from 'modules/common/components/system-health/system-health.component';
import { NodeDetailsComponent } from 'modules/common/components/tree/node-details/node-details.component';
import { NodeWrapperComponent } from 'modules/common/components/tree/node-wrapper/node-wrapper.component';
import { SubtreeComponent } from 'modules/common/components/tree/subtree/subtree.component';
import { TreeComponent } from 'modules/common/components/tree/tree.component';
import { MakeDraggableDirective } from 'modules/common/directives/dragDrop/makeDraggable.directive';
//Directives
import { MakeDroppableDirective } from 'modules/common/directives/dragDrop/makeDroppable.directive';
import { EditableAs } from 'modules/common/directives/entity-permission-directives/editable-as.directive';
import { VisibleAs } from 'modules/common/directives/entity-permission-directives/visible-as.directive';
import { TrackedFormDirective } from 'modules/common/directives/form/tracked-form';
import { MouseWheelDirective } from 'modules/common/directives/mousewheel/mousewheel.directive';
import { ScrollToIdDirective } from 'modules/common/directives/scrollToId/scrollToId.directive';
import { IdToolTipDirective } from 'modules/common/directives/tool-tips/id-tool-tip.directive';
import { NoTranslateToolTipDirective } from 'modules/common/directives/tool-tips/no-translate-tool-tip.directive';
import { AlphaNumericValidatorDirective } from 'modules/common/directives/validators/alpha-numeric-validator.directive';
import { LowerCaseStartValidatorDirective } from 'modules/common/directives/validators/lower-case-start-validator.directive';
import { NumericalStartValidatorDirective } from 'modules/common/directives/validators/numerical-start-validator.directive';
import { JsonArrayValidValidatorDirective } from 'modules/common/directives/validators/json-array-validator.directive';
import { NoWhitespaceValidatorDirective } from 'modules/common/directives/validators/no-whitespace-validator.directive';
import { DataSetCategoryNodeComponent } from 'modules/common/vo-render/data-set-category-node/data-set-category-node.component';
// Publishing
import { DistributionRulesFormChipsComponent } from 'modules/common/vo-render/distribution-rules-form-chips/distribution-rules-form-chips.component';
import { AddEditProductClassRuleDialogComponent } from 'modules/common/vo-render/product-class-filter/add-edit-product-class-rule-dialog/add-edit-product-class-rule-dialog.component';
import { ProductClassFilterComponent } from 'modules/common/vo-render/product-class-filter/product-class-filter.component';
import { ProductClassIdComponent } from 'modules/common/vo-render/product-class-filter/product-class-rule/product-class-id/product-class-id.component';
import { ProductClassRuleComponent } from 'modules/common/vo-render/product-class-filter/product-class-rule/product-class-rule.component';
import { ProductClassNodeComponent } from 'modules/common/vo-render/product-class-node/product-class-node.component';
import { ProductPropertyFilterComponent } from 'modules/common/vo-render/product-property-filter/product-property-filter.component';
import { PropertyRuleComponent } from 'modules/common/vo-render/product-property-filter/property-rule/property-rule.component';
import { PropertyTestComponent } from 'modules/common/vo-render/product-property-filter/property-rule/property-test/property-test.component';
import { ProductPropertySectionIndexComponent } from 'modules/common/vo-render/product-property-section-index/product-property-section-index.component';
import { UserProfileComponent } from 'modules/common/vo-render/user-profile/user-profile.component';
import { UserSpotComponent } from 'modules/common/vo-render/user-spot/user-spot.component';
//Modules
import { PipesModule } from 'modules/pipes/pipes.module';
import { ExtractIndexListItemComponent } from 'modules/routes/library/extract-index-list-item/extract-index-list-item.component';
import { ChartsModule } from 'ng2-charts';
import { ClipboardModule } from 'ngx-clipboard';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ClientConfigurationCard } from 'vo-render/client-configuration-card/client-configuration-card.component';
// Value Object Render
import { ClientQuotaCardComponent } from 'vo-render/client-quota-card/client-quota-card.component';
import { ClientQuotaHistoryDialogComponent } from 'vo-render/client-quota-card/client-quota-history-dialog/client-quota-history-dialog.component';
import { ClientQuotaInfoDialogComponent } from 'vo-render/client-quota-card/client-quota-info-dialog/client-quota-info-dialog.component';
import { LoadingSpinnerComponent } from 'vo-render/loading-spinner/loading-spinner.component';
import { ProductCompletenessComponent } from 'vo-render/product-completeness/product-completeness.component';
import { PropertyTestDialogComponent } from 'vo-render/product-property-filter/property-test-dialog/property-test-dialog.component';
import { ExportVersionBallComponent } from '../common/components/export-version-ball/export-version-ball.component';
import { ProductAssetViewComponent } from '../common/components/product-asset-view/product-asset-view.component';
import { HiddenAs } from '../common/directives/entity-permission-directives/hidden-as.directive';
import { DatasetCategorySortingInfoDialogComponent } from 'vo-render/dataset-category-sorting-info-dialog/dataset-category-sorting-info-dialog.component';
import { ExportVersionStateBallsComponent } from 'vo-render/export-version-state-balls/export-version-state-balls.component';
import { UserExportDistributionAwJSONComponent } from 'vo-render/user-export-distribution/user-export-distribution-aw-json/user-export-distribution-aw-json.component';
import { UserExportDistributionAwLegacyComponent } from 'vo-render/user-export-distribution/user-export-distribution-aw-legacy/user-export-distribution-aw-legacy.component';
import { UserExportDistributionComponent } from 'vo-render/user-export-distribution/user-export-distribution.component';
import { UserIndexItemComponent } from 'vo-render/user-index-item/user-index-item.component';
import { ProductPropertyEditorComponent } from 'vo-render/product-property-editor/product-property-editor.component';
import { ProductPropertyTextListEditorComponent } from 'vo-render/product-property-text-list-editor/product-property-text-list-editor.component';
import { ProductPropertyOptionListEditorComponent } from 'vo-render/product-property-option-list-editor/product-property-option-list-editor.component';
import { UserExportDistributionsTabComponent } from 'vo-render/user-export-distributions-tab/user-export-distributions-tab.component';
import { InjectedWrapperComponent } from '../common/components/injected-wrapper/injected-wrapper.component';
import { MatTreeModule } from '@angular/material/tree';
import { LibraryViewClassConfigComponent } from 'vo-render/library-view-class-config/library-view-class-config.component';
import { ListTreeComponent } from 'vo-render/list-tree/list-tree.component';
import { CategoryProductsViewComponent } from 'vo-render/category-products-view/category-products-view.component';
import { ProductViewComponent } from 'vo-render/product-view/product-view.component';
import { InfinityScrollerComponent } from '../common/components/infinity-scroller/infinity-scroller.component';
import { ProductViewFullComponent } from 'vo-render/product-view-full/product-view-full.component';
import { MatTableModule } from '@angular/material/table';
import { DatasetProductsViewSearchDialogComponent } from '../common/components/dataset-product-view/dataset-products-view-search-dialog/dataset-products-view-search-dialog.component';
import { DatasetProductViewComponent } from '../common/components/dataset-product-view/dataset-product-view.component';
import { SidenavRouteComponent } from 'vo-render/sidenav-route/sidenav-route.component';
import { RouterModule } from '@angular/router';
import { ExportDistributionIndexItemComponent } from 'vo-render/export-distribution-index-item/export-distribution-index-item.component';
import { UserDetailsDistributionGroupIndexItemComponent } from 'vo-render/user-details-distribution-group-index-item/user-details-distribution-group-index-item.component';
import { TimeDisplayComponent } from '../common/components/time-display/time-display.component';
import { SelectWithSearchComponent } from 'vo-render/select-with-search/select-with-search.component';
import { KnowledgeBaseHelperIconComponent } from '../common/components/knowledge-base-helper-icon/knowledge-base-helper-icon.component';
import { UserGroupComponent } from 'vo-render/user-group/user-group.component';
import { PropertyFilterRuleIndexComponent } from 'vo-render/property-filter-rule-index/property-filter-rule-index.component';
import { AddPermittedDomainDialogComponent } from 'vo-render/user-group/add-permitted-domain-dialog/add-permitted-domain-dialog.component';
import { ExtractAllocationsItemComponent } from '../routes/library/products/product-extract-data/product-extract/extract-data-in-out-tab/extract-allocations-item/extract-allocations-item.component';
import { MatrixPropertyEditorComponent } from 'vo-render/matrix-property-editor/matrix-property-editor.component';
import { ClientLogComponent } from 'vo-render/client-log/client-log.component'
import { PropertyChipComponent } from '../common/vo-render/property-chip/property-chip.component';
import { RuleMakerChipsComponent } from '../common/components/rule-maker-chips/rule-maker-chips.component';
import { ClientLogEntryComponent } from '../common/vo-render/client-log/client-log-entry/client-log-entry.component';
import { PropertyTableRendererComponent } from '../common/vo-render/property-table-renderer/property-table-renderer.component';
import { ImageResizeDialogComponent } from '../common/dialogs/image-resize-dialog/image-resize-dialog.component';
import { MatSliderModule } from '@angular/material/slider';
import { MultipleAddInputComponent } from '../common/vo-render/multiple-add-input/multiple-add-input.component';
import { RemoveChipButtonComponent } from '../common/buttons/remove-chip-button/remove-chip-button.component';
import { NoContentPlaceholderComponent } from '../common/components/no-content-placeholder/no-content-placeholder.component';
import { ProductViewConfigComponent } from '../common/vo-render/product-view-config/product-view-config.component';
import { ProductViewClassConfigComponent } from '../common/vo-render/product-view-class-config/product-view-class-config.component';
import { ProductViewConfigClassTreeItemComponent } from '../common/vo-render/product-view-config-class-tree-item/product-view-config-class-tree-item.component';
import { ToastComponent } from '../common/toasts/toast/toast.component';
import { ImageSliderComponent } from '../common/components/image-slider/image-slider.component';

@NgModule({
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  ],
  imports: [
    MatSlideToggleModule,
    MatChipsModule,
    MatListModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
    MatPaginatorModule,
    CdkTableModule,
    MatCheckboxModule,
    MatRadioModule,
    MatExpansionModule,
    MatButtonModule,
    MatInputModule,
    MatButtonToggleModule,
    MatCardModule,
    MatSelectModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatBadgeModule,
    MatToolbarModule,
    MatTabsModule,
    MatSidenavModule,
    MatTreeModule,
    MatTableModule,
    MatSliderModule,
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AgGridModule.withComponents([
      GridEditorComponentComponent, GridRendererComponentComponent, GridFilterComponentComponent, GridFilterFloatingComponentComponent,
      GridEditorSelectComponent, GridEditorMultiSelectComponent,
      GridFilterSelectComponent, GridFilterSelectFloatingComponent,
      GridEditorBooleanComponent, GridRendererBooleanComponent,
      GridRendererMaterialIconComponent, GridEditorDateComponent,
      GridEditorTextValidatedComponent, GridEditorTextListComponent,
      GridRendererDateComponent, GridRendererTextCopyClipboardComponent,
      GridGroupHeaderCountComponent, GridGroupHeaderCheckboxComponent, GridGroupHeaderMenuComponent]),
    InfiniteScrollModule,
    PipesModule,
    ChartsModule,
    ClipboardModule,
    DragDropModule,
  ],
  declarations: [
    RuleMakerChipsComponent,
    PropertyChipComponent,
    IndexListComponent,
    FabIconBusyButtonComponent,
    RaisedBusyButtonComponent,
    RemoveChipButtonComponent,
    TrackedFormDirective,
    ScrollToIdDirective,
    MakeDroppableDirective,
    MakeDraggableDirective,
    EditableAs,
    VisibleAs,
    HiddenAs,
    FormListComponent,
    ListAddInputComponent,
    CalloutInputComponent,
    ListSearchHeaderComponent,
    ClientQuotaCardComponent,
    ClientConfigurationCard,
    ClientQuotaInfoDialogComponent,
    ClientQuotaHistoryDialogComponent,
    TreeComponent,
    ProductClassNodeComponent,
    DataSetCategoryNodeComponent,
    NodeDetailsComponent,
    SubtreeComponent,
    NodeWrapperComponent,
    AlphaNumericValidatorDirective,
    NumericalStartValidatorDirective,
    JsonArrayValidValidatorDirective,
    LowerCaseStartValidatorDirective,
    NoWhitespaceValidatorDirective,
    MouseWheelDirective,
    IdToolTipDirective,
    NoTranslateToolTipDirective,
    ProductPropertySectionIndexComponent,
    SystemHealthComponent,
    NotificationRecordComponent,
    JsonFormControlErrorDisplayComponent,
    PercentageCircleComponent,
    PercentageCircleSlicedComponent,
    NotificationDetailDialogComponent,
    UserSpotComponent,
    UserProfileComponent,
    DistributionRulesFormChipsComponent,
    LoadingSpinnerComponent,
    GridRendererComponentComponent,
    GridEditorComponentComponent,
    GridFilterComponentComponent,
    GridFilterFloatingComponentComponent,
    GridEditorSelectComponent,
    GridEditorDateComponent,
    GridEditorMultiSelectComponent,
    GridFilterSelectComponent,
    GridFilterSelectFloatingComponent,
    GridRendererBooleanComponent,
    GridEditorBooleanComponent,
    GridRendererMaterialIconComponent,
    GridEditorTextValidatedComponent,
    GridEditorTextListComponent,
    GridRendererTextCopyClipboardComponent,
    GridRendererDateComponent,
    GridGroupHeaderCountComponent,
    GridGroupHeaderCheckboxComponent,
    GridGroupHeaderMenuComponent,
    ProductPropertyMatrixEditorComponent,
    ExtractIndexListItemComponent,
    ExtractAllocationsItemComponent,
    PropertyTestDialogComponent,
    ProductPropertyFilterComponent,
    PropertyRuleComponent,
    PropertyTestComponent,
    ProductClassFilterComponent,
    ProductClassRuleComponent,
    ProductClassIdComponent,
    ProductCompletenessComponent,
    AddEditProductClassRuleDialogComponent,
    ExportVersionBallComponent,
    DatasetCategorySortingInfoDialogComponent,
    UserIndexItemComponent,
    UserExportDistributionComponent,
    UserExportDistributionAwJSONComponent,
    UserExportDistributionAwLegacyComponent,
    ProductAssetViewComponent,
    ExportVersionStateBallsComponent,
    ProductPropertyEditorComponent,
    ProductPropertyTextListEditorComponent,
    ProductPropertyOptionListEditorComponent,
    UserExportDistributionsTabComponent,
    InjectedWrapperComponent,
    LibraryViewClassConfigComponent,
    ListTreeComponent,
    CategoryProductsViewComponent,
    ProductViewComponent,
    InfinityScrollerComponent,
    ProductViewFullComponent,
    DatasetProductsViewSearchDialogComponent,
    DatasetProductViewComponent,
    SidenavRouteComponent,
    ExportDistributionIndexItemComponent,
    PropertyFilterRuleIndexComponent,
    KnowledgeBaseHelperIconComponent,
    UserDetailsDistributionGroupIndexItemComponent,
    TimeDisplayComponent,
    SelectWithSearchComponent,
    UserGroupComponent,
    AddPermittedDomainDialogComponent,
    MatrixPropertyEditorComponent,
    PropertyTableRendererComponent,
    ClientLogComponent,
    ClientLogEntryComponent,
    ImageResizeDialogComponent,
    MultipleAddInputComponent,
    NoContentPlaceholderComponent,
    GridHeaderCountComponent,
    ProductViewConfigComponent,
    ProductViewClassConfigComponent,
    ProductViewConfigClassTreeItemComponent,
    ToastComponent,
    ImageSliderComponent
  ],
  exports: [
    RuleMakerChipsComponent,
    PropertyChipComponent,
    IndexListComponent,
    DragDropModule,
    InfiniteScrollModule,
    FabIconBusyButtonComponent,
    RaisedBusyButtonComponent,
    RemoveChipButtonComponent,
    AgGridModule,
    PipesModule,
    ChartsModule,
    ClipboardModule,
    TrackedFormDirective,
    ScrollToIdDirective,
    MakeDroppableDirective,
    MakeDraggableDirective,
    EditableAs,
    VisibleAs,
    HiddenAs,
    FormListComponent,
    ListAddInputComponent,
    CalloutInputComponent,
    ListSearchHeaderComponent,
    ClientQuotaCardComponent,
    ClientConfigurationCard,
    ClientQuotaInfoDialogComponent,
    ClientQuotaHistoryDialogComponent,
    TreeComponent,
    ProductClassNodeComponent,
    DataSetCategoryNodeComponent,
    NodeDetailsComponent,
    NodeWrapperComponent,
    AlphaNumericValidatorDirective,
    NumericalStartValidatorDirective,
    JsonArrayValidValidatorDirective,
    LowerCaseStartValidatorDirective,
    NoWhitespaceValidatorDirective,
    MouseWheelDirective,
    IdToolTipDirective,
    NoTranslateToolTipDirective,
    ProductPropertySectionIndexComponent,
    SystemHealthComponent,
    NotificationRecordComponent,
    JsonFormControlErrorDisplayComponent,
    PercentageCircleComponent,
    PercentageCircleSlicedComponent,
    NotificationDetailDialogComponent,
    UserSpotComponent,
    UserProfileComponent,
    DistributionRulesFormChipsComponent,
    LoadingSpinnerComponent,
    ExtractIndexListItemComponent,
    ExtractAllocationsItemComponent,
    PropertyTestDialogComponent,
    ProductPropertyFilterComponent,
    PropertyRuleComponent,
    PropertyTestComponent,
    ProductClassFilterComponent,
    ProductClassRuleComponent,
    ProductClassIdComponent,
    ProductCompletenessComponent,
    MatButtonToggleModule,
    ExportVersionBallComponent,
    DatasetCategorySortingInfoDialogComponent,
    UserIndexItemComponent,
    UserExportDistributionComponent,
    UserExportDistributionAwJSONComponent,
    UserExportDistributionAwLegacyComponent,
    ProductAssetViewComponent,
    ExportVersionStateBallsComponent,
    ProductPropertyEditorComponent,
    UserExportDistributionsTabComponent,
    InjectedWrapperComponent,
    LibraryViewClassConfigComponent,
    ListTreeComponent,
    CategoryProductsViewComponent,
    ProductViewComponent,
    ProductViewFullComponent,
    DatasetProductViewComponent,
    SidenavRouteComponent,
    ExportDistributionIndexItemComponent,
    PropertyFilterRuleIndexComponent,
    KnowledgeBaseHelperIconComponent,
    TimeDisplayComponent,
    SelectWithSearchComponent,
    UserGroupComponent,
    AddPermittedDomainDialogComponent,
    MatrixPropertyEditorComponent,
    PropertyTableRendererComponent,
    ClientLogComponent,
    ClientLogEntryComponent,
    MultipleAddInputComponent,
    NoContentPlaceholderComponent,
    GridHeaderCountComponent,
    InfinityScrollerComponent,
    ProductViewConfigComponent,
    ToastComponent,
    ImageSliderComponent
  ],
  entryComponents: [
    RuleMakerChipsComponent,
    PropertyChipComponent,
    IndexListComponent,
    FabIconBusyButtonComponent,
    RaisedBusyButtonComponent,
    RemoveChipButtonComponent,
    ClientQuotaInfoDialogComponent,
    ClientQuotaHistoryDialogComponent,
    TreeComponent,
    ProductClassNodeComponent,
    DataSetCategoryNodeComponent,
    NodeWrapperComponent,
    ProductPropertySectionIndexComponent,
    NotificationDetailDialogComponent,
    UserProfileComponent,
    ExtractIndexListItemComponent,
    ExtractAllocationsItemComponent,
    PropertyTestDialogComponent,
    AddEditProductClassRuleDialogComponent,
    ProductPropertyMatrixEditorComponent,
    DatasetCategorySortingInfoDialogComponent,
    UserIndexItemComponent,
    ProductAssetViewComponent,
    ProductViewComponent,
    ProductViewFullComponent,
    DatasetProductsViewSearchDialogComponent,
    ExportDistributionIndexItemComponent,
    PropertyFilterRuleIndexComponent,
    UserDetailsDistributionGroupIndexItemComponent,
    AddPermittedDomainDialogComponent,
    ClientLogEntryComponent,
    NoContentPlaceholderComponent,
    ImageResizeDialogComponent,
    ProductViewConfigComponent,
    ProductViewConfigClassTreeItemComponent,
    ToastComponent,
    ImageSliderComponent
  ]
})
export class SharedModule { }

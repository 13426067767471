import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-user-details-distribution-group-index-item',
  templateUrl: './user-details-distribution-group-index-item.component.html',
  styleUrls: ['./user-details-distribution-group-index-item.component.css']
})
export class UserDetailsDistributionGroupIndexItemComponent implements OnInit {

  constructor() {
    // This is intentional
  }

  @Input() public userDetailsDistributionVO: any = null;

  ngOnInit() {
  }

}

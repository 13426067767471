import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { AHubActions } from 'actions/ahub.actions';
import { Observable, of } from 'rxjs';
/**
 * Store access
 */
import { StoreAccess } from 'store/store-access';


@Injectable()
export class StoreClearResolver implements Resolve<any> {

    constructor() {
        // This is intentional
    }

    /**
     * Resolve the current route
     */
    resolve(route: ActivatedRouteSnapshot): Observable<any> {

        //Dispatch a store clear event
        StoreAccess.dispatch(AHubActions.storeTemporaryClear());

        //Return an observable of true
        return of(true);
    }
}

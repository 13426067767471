import { concat as observableConcat, of, from, Observable, Subject, BehaviorSubject } from 'rxjs';
import { last, reduce, merge, filter, mergeMap, switchMap, concatMap, map, catchError, tap } from 'rxjs/operators';

import { AppRoutingNavigation } from 'app/app-routing-navigation';
import { Router } from '@angular/router';

/**
 * Actions
 */
import { AHubActions } from 'actions/ahub.actions';
import { AppActions } from 'actions/app.actions';


/**
 * Value objects
 */
import {
  ActionClientAHubVO, ActionClientConfigurationAHubVO,
  ActionClientLibraryVersionAHubVO, ActionClientQuotasAHubVOs, ActionDistributionAHubVO,
  ActionDistributionAHubVOs, ActionDistributionGroupAHubVO, ActionExportAHubVO, ActionExporterAHubVO,
  ActionExportIdExportVersionNumber, ActionExportVersionAHubVO, ActionExportVersionWithdraw, ActionWorkGroupAHubVO, ActionExportGeneratorExporterMappingAHubVO, ActionUserAHubVO, ActionExporterExportGeneratorSettingsAHubVO
} from 'actions/types/ahub-accounts.action-types';

/**
 * Action Types
 */
import {
  ActionDate, ActionNumber, ActionNumberArray, ActionNumberNumber,
  ActionNumberNumbersArray, ActionNumberNumberString,
  ActionNumberStringArray, ActionString, ActionStringArray, ActionStringNumber, ActionNumberStringStringArray, ActionNumberStringString, ActionStringNumberBoolean, ActionNumberNumberNumber, ActionNumberNumberNumbersArray
} from 'actions/types/common.action-types';

import {
  ActionDataSetAHubVO, ActionDataSetCategoryAHubVO, ActionExtractAHubVO,
  ActionExtractDefinitionAHubVO, ActionExtractDocumentProductFileTypeAHubVO, ActionExtractProductAHubVOs,
  ActionExtractProductAndAssetsUploadAHubVOs, ActionExtractProductBulkUploadAHubVOs, ActionExtractProductSectionPropertyAsset,
  ActionProductAssetViewVOs, ActionProductClassAHubVO, ActionProductClassFilterAHubVONumber,
  ActionProductPropertyAHubVO, ActionProductPropertyAllocationsAHubVO, ActionProductPropertyAllocationChainAHubVO,
  ActionProductPropertyAllocationChainValueCommitAHubVOs, ActionProductPropertyFilterAHubVONumber,
  ActionProductPropertySectionAHubVO, ActionResourcePackAHubVO, ActionProductSortAHubVONumber,
  ActionExtractProductPaginationPage, ActionExtractDefaultValueRulesAHubVO, ActionExtractDocumentSettings, ActionExporterBlueprintFetch,
  ActionClientLogAHubVO
} from 'actions/types/library.action-types';

import {
  ActionSystemClientLibraryId, ActionSystemClientLibrary
} from '../actions/types/ahub-system.action-types';

import { ViewActions } from 'actions/view.actions';
import { Epic } from 'epics/epic';
import { EpicsBase } from 'epics/epics-base';
import { Utils } from "modules/common/utils";
import { requestActionStatuses } from "selector/app.selector";

/**
 * Services
 */
import { AHubService } from 'services/ahub/ahub.service';
import { ObjectStoreService } from "services/object-store/object-store.service";
import { UploadService } from 'services/upload/upload.service';
import { ActionWork, ActionWorkCancel } from 'store/actions/types/work.action-types';
import { StoreAccess } from 'store/store-access';
import { PresignedUrlAHubVO } from "valueObjects/ahub/presigned-url.ahub.vo";
import { RequestTicketAHubVO } from "valueObjects/ahub/work/request-ticket.ahub.vo";
import { RequestActionStatusUploadVO } from "valueObjects/app/request-action-status-upload.vo";
import { UploadDataAppVO } from 'app/valueObjects/app/upload-data.app.vo';
import { WorkflowDownloadFileAHubVO } from 'app/valueObjects/ahub/work/workflow-download-file.ahub.vo';
import { ActionFileDownloadConfigVO } from '../actions/types/app.action-types';
import { FileDownloadConfigVO } from 'app/valueObjects/app/file-download-config.app.vo';
import { ActionExportTypeCodesAndNumber } from '../actions/types/hark.action-types';
import { ProductAssetUploadManifestAHubVO, ProductAssetUploadManifestProductAHubVO } from 'app/valueObjects/ahub/library/product-asset-upload-manifest.ahub.vo';
import { HttpClient } from '@angular/common/http';
import { ClientLogEntryAHubVO } from 'app/valueObjects/ahub/library/client-log-entry.ahub.vo';
import { ClientLogAHubVO } from 'app/valueObjects/ahub/library/client-log.ahub.vo';



/**
 * Class for the aHub epic functions
 */
export class AHubEpics extends EpicsBase implements Epic {

  /**
   * NOOP action for this actions which don't require a subsequent action as a result of the epic
   */

  private readonly NOOP_ACTION: ActionWork = { actionId: -1, type: "NOOP" };

  constructor(
    private readonly router: Router,
    private readonly aHubService: AHubService,
    private readonly uploadService: UploadService,
    private readonly objectStoreService: ObjectStoreService,
    private readonly http: HttpClient) {
    super();
  }

  /**
   * Returns a list of the epic function avalible from this class
   * If a new epic is added to the class it must be added to this list
   */
  epicMethods(): any[] {
    return [
      this.systemDatabaseValidationFilesCreate,
      this.loginTokenFetch,
      this.logUserValidate,
      this.loginUrlFetch,
      this.loginTokenAuthorize,
      this.loginIdentifierProviderTokenValidate,
      this.loginIdentifierProviderTokenRegister,
      this.sessionTokenFetch,
      this.userSessionNewFromToken,
      this.userIdBySessionFetch,
      this.userHasAviewsFetch,
      this.sessionInfoFetch,
      this.sessionInvalidate,
      this.userCommit,
      this.sessionUsersByIdsFetch,
      this.usersByIdsFetch,
      this.userIndexsFullFetch,
      this.userIndexesByClientIdFetch,
      this.userIndexesByIdsFetch,
      this.userExtendedsByIdsFetch,
      this.userClientIndexsFetch,
      this.dataSetIndexsFetch,
      this.dataSetsByIdsFetch,
      this.dataSetAdd,
      this.dataSetSave,
      this.dataSetDelete,
      this.dataSetCopy,
      this.dataSetSwapSectionProperties,
      this.dataSetProductsByIdsFetch,
      this.dataSetProductAssetsByIdsFetch,
      this.dataSetProductAssetZipGenerate,
      this.dataSetCategoriesFetchById,
      this.dataSetCategoryAdd,
      this.dataSetCategoriesCopy,
      this.dataSetCategoryCommit,
      this.dataSetCategoryCommitProductPropertyFilter,
      this.dataSetCategoryCommitProductClassFilter,
      this.dataSetCategoryCommitProductSort,
      this.dataSetCategoryCommitPriority,
      this.dataSetCategoryDelete,
      this.dataSetCategoryMove,
      this.dataSetCategoryProductsFetch,
      this.dataSetCategoryProductsByIdFetch,
      this.dataSetCategoryProductIdsSortedFetch,
      this.clientIndexsFetch,
      this.clientsByIdsFetch,
      this.clientAdd,
      this.clientSave,
      this.clientDisable,
      this.clientDelete,
      this.clientEnable,
      this.clientLibraryVersionIndexsFetch,
      this.clientLibraryVersionsFetchById,
      this.clientLibraryVersionSaveBuildFrom,
      this.clientLibraryVersionModelGenerate,
      this.clientLibraryVersionModelPresignedUrlFetch,
      this.clientLibraryVersionAdd,
      this.clientLibraryVersionCommit,
      this.clientLibraryVersionValidate,
      this.clientLibraryVersionDelete,
      this.clientLibraryVersionsDiscover,
      this.clientLibraryIndexesFetch,
      this.clientStorageIndexesFetch,
      this.clientLibrariesByIdFetch,
      this.clientLibraryUpgradeAndUse,
      this.clientLibraryAdd,
      this.clientLibraryDelete,
      this.clientLibraryUse,
      this.clientLibrarySync,
      this.clientLibrarySyncFrom,
      this.clientLibraryCommit,
      this.distributionGroupIndexsFetch,
      this.distributionGroupIndexsByClientIdFetch,
      this.distributionGroupUserIndexsFetch,
      this.distributionGroupIndexesByUserIdFetch,
      this.distributionGroupIndexesByClientIdAndUserIdFetch,
      this.distributionGroupDistributionIndexsFetch,
      this.distributionGroupsFetch,
      this.distributionGroupAdd,
      this.distributionGroupSave,
      this.distributionGroupDelete,
      this.distributionGroupUserEmailAdd,
      this.distributionGroupUserJoinByKey,
      this.distributionGroupUserIdRemove,
      this.distributionGroupUserIdsRemove,
      this.distributionGroupsUserIdRemove,
      this.distributionGroupsUserIdAdd,
      this.distributionsAdd,
      this.distributionSave,
      this.distributionDelete,
      this.distributionsDelete,
      this.distributionsDisable,
      this.distributionsEnable,
      this.distributionsFetch,
      this.distributionParametersCopy,
      this.exportTypesAllFetch,
      this.exportGeneratorsAllFetch,
      this.exportGeneratorMappingBlueprintUrlFetch,
      this.worklogSegmentFetch,
      this.worklogFileDownload,
      this.workflowDownloadFiles,
      this.exportIndexsFetch,
      this.exportsByIdsFetch,
      this.exportDistributionIndexsFetch,
      this.exportAdd,
      this.exportSave,
      this.exportDelete,
      this.exportUpdateCancel,
      this.exportPreviewImageUrlFetch,
      this.exportLatestVersionPathUrlFetch,
      this.exportVersionPathUrlFetch,
      this.exportLegacyRefFetch,
      this.exportLegacyRefCommit,
      this.exportVersionCommit,
      this.exportVersionDelete,
      this.exportVersionWithdraw,
      this.exportVersionStateDisableSave,
      this.exportVersionStateInternalSave,
      this.exportVersionStatePreviewSave,
      this.exportVersionStateLimitedReleaseSave,
      this.exportVersionStateReleaseSave,
      this.exportVersionFilesZipDownload,
      this.exportDistributionsFetch,
      this.exporterIndexesFetch,
      this.exportersByIdsFetch,
      this.exporterBuildHistoryIndexesById,
      this.exporterBuildHistoryByIds,
      this.exporterAdd,
      this.exporterSave,
      this.exporterDelete,
      this.exporterCopy,
      this.exporterBuild,
      this.exporterCancelBuild,
      this.exporterMappingCommit,
      this.exporterSettingsCommit,
      this.workGroupIndexsFetch,
      this.workGroupsFetch,
      this.workGroupAdd,
      this.workGroupSave,
      this.workGroupDelete,
      this.workGroupUserIndexsFetch,
      this.workGroupsByUserAndClientFetch,
      this.workGroupUserEmailAdd,
      this.workGroupUserJoinByKey,
      this.workGroupUserIdRemove,
      this.workGroupUserIdsRemove,
      this.entityPermissionsFetch,
      this.clientQuotasByClientIdFetch,
      this.clientQuotasHistoryByClientIdFetch,
      this.clientQuotaByIdsFetch,
      this.clientQuotasCommit,
      this.clientConfigurationByClientIdFetch,
      this.clientConfigurationCommit,
      this.sessionClientConfigurationFetch,
      this.registerUser,
      this.verifyUser,
      this.verifyEmailUpdate,
      this.verifyUserManualOverride,
      this.sendVerificationCode,
      this.verificationProblemsContactSupport,
      this.sendEmailUpdateVerificationCode,
      this.productClassProductCountFetch,
      this.productClassIndexsByClientIdFetch,
      this.productClassesFetch,
      this.productClassAdd,
      this.productClassCommit,
      this.productClassDelete,
      this.productClassMove,
      this.productPropertyTypesFetch,
      this.productPropertyIndexsFetch,
      this.productPropertyFetch,
      this.productPropertyAdd,
      this.productPropertyCommit,
      this.productPropertyDelete,
      this.productPropertySectionIndexsFetch,
      this.productPropertySectionAdd,
      this.productPropertySectionCopy,
      this.productPropertySectionCommit,
      this.productPropertySectionDelete,
      this.productPropertyAllocationIndexsFetch,
      this.productPropertySectionByIds,
      this.productPropertyAllocationAdd,
      this.productPropertyAllocationDelete,
      this.productPropertyAllocationChainIndexFetch,
      this.productPropertyAllocationChainsFetch,
      this.productPropertyAllocationChainAdd,
      this.productPropertyAllocationChainDelete,
      this.productPropertyAllocationChainValuesCommit,
      this.productPropertyAllocationChainValidate,
      this.productPropertyAllocationChainRebuild,
      this.productPropertyAllocationChainAllValuesApply,
      this.productPropertyAllocationChainAllValuesApplyToAll,
      this.productPropertyAllocationChainValueChainedValuesFetch,
      this.productPropertyAllocationChainValueApply,
      this.productPropertyAllocationChainValueApplyToAll,
      this.extractDefinitionIndexesFetch,
      this.extractDefinitionAdd,
      this.extractDefinitionCommit,
      this.extractDefinitionsByIds,
      this.extractDefinitionDelete,
      this.extractIndexesFetch,
      this.extractIndexesByExtractDefinitionId,
      this.extractsByIds,
      this.extractAdd,
      this.extractCommit,
      this.extractDefaultValueRulesCommit,
      this.extractDelete,
      this.extractDocumentSettingsCommit,
      this.extractCommitFilter,
      this.extractConflicts,
      this.extractStateWIP,
      this.extractStateComplete,
      this.extractStateArchive,
      this.extractCompletenessProductPaginationPageFetch,
      this.extractProductPropertiesCompletenessFetch,
      this.extractHistoryIndexesByExtractIdFetch,
      this.extractHistoryProductSourceDownload,
      this.extractProductsFetch,
      this.extractProductsFetchByIds,
      this.extractProductDocumentCreate,
      this.extractProductsUpload,
      this.extractProductsCommitObjectsOnly,
      this.extractProductsCommitObjectAndAssets,
      this.extractProductsDelete,
      this.extractProductAssetMatch,
      this.extractProductSectionPropertyAssetDelete,
      this.extractProductAssetZipGenerate,
      this.extractProductAssetUrlsFetch,
      this.extractProductAssetsSwap,
      this.resourcePackIndexesFetch,
      this.resourcePacksByIdFetch,
      this.resourcePackAdd,
      this.resourcePackCommit,
      this.resourcePackDelete,
      this.resourcePackFilesUpload,
      this.resourcePackFilesDelete,
      this.resourcePackFolderAdd,
      this.resourcePackFolderRename,
      this.resourcePackFilesZipDownload,
      this.resourcePackFileDownload,
      this.clientLogFetch,
      this.clientLogEntriesFetch,
      this.serverTimeFetch,
      this.maintanceModeOn,
      this.maintanceModeOff,
      this.systemStatusFetch
    ];
  }

  /**
  * -----------------------------------------------------
  * Action Management
  * -----------------------------------------------------
  */

  cancelAction = (action$: Observable<ActionWorkCancel>, id) => {
    return action$.pipe(filter(({ type }) => type === AppActions.ACTIONS_WORK_CANCEL),
      filter(action => action.cancelActionId == id))
  }

  /**
   * -----------------------------------------------------
   * System
   * -----------------------------------------------------
   */
  systemDatabaseValidationFilesCreate = (action$: Observable<ActionWork>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.SYSTEM_DATABASE_VALIDATION_FILES_CREATE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.systemDatabaseValidationFilesCreate(), action)))
  }

  /**
   * -----------------------------------------------------
   * Accounts
   * -----------------------------------------------------
   */

  loginTokenFetch = (action$: Observable<ActionString>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.LOGIN_TOKEN_FETCH),
      mergeMap(action => this.requestSingleDataToAction(this.aHubService.loginToken(action.string), action, AppActions.loginTokenSet)));
  }

  logUserValidate = (action$: Observable<ActionStringArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.LOGIN_USER_VALIDATE),
      mergeMap(action => this.requestSingleDataToAction(this.aHubService.logUserValidate(action.strings[0], action.strings[1], action.strings[2]), action, ViewActions.loginTokenValidatedSet)));
  }

  loginUrlFetch = (action$: Observable<ActionString>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.LOGIN_URL_FETCH),
      mergeMap(action => this.requestSingleDataToAction(this.aHubService.loginUrl(action.string), action, ViewActions.loginURLSet)));
  }

  loginIdentifierProviderTokenValidate = (action$: Observable<ActionStringArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.LOGIN_IDENTIFIER_PROVIDER_TOKEN_VALIDATE),
      mergeMap(action => this.requestSingleDataToAction(
        this.aHubService.loginIdentifierProviderTokenValidate(action.strings[0], action.strings[1]),
        action,
        ViewActions.loginTokenValidatedSet,
        AHubActions.loginIdentifierProviderTokenRegister,
        [action.strings[0], action.strings[1]]
      )));
  }

  loginTokenAuthorize = (action$: Observable<ActionString>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.LOGIN_TOKEN_AUTHORIZE),
      mergeMap(action => this.aHubService.loginTokenAuthorize(action.string)),
      map(result => this.NOOP_ACTION));
  }

  loginIdentifierProviderTokenRegister = (action$: Observable<ActionStringArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.LOGIN_IDENTIFIER_PROVIDER_TOKEN_REGISTER),
      mergeMap(action => this.requestSingleDataToAction(this.aHubService.loginIdentifierProviderTokenRegister(action.strings[0], action.strings[1]), action, ViewActions.loginTokenValidatedSet)));
  }

  sessionTokenFetch = (action$: Observable<ActionString>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.SESSION_TOKEN_FETCH),
      mergeMap(action => this.requestSingleDataToAction(this.aHubService.sessionToken(action.string), action, AppActions.sessionTokenSet)));
  }


  userSessionNewFromToken = (action$: Observable<ActionString>) => {
    return action$
      .pipe(
        filter(({ type }) => type === AHubActions.USER_SESSION_NEW_FROM_TOKEN),
        switchMap(action => this.aHubService.userSessionNewFromToken(action.string)
          .pipe(
            concatMap((result) => of(AppActions.sessionUserSessionCredentialsSet(result), AHubActions.userIdBySessionFetch(result)))
          )
        )
      )
  }

  userIdBySessionFetch = (action$: Observable<ActionWork>) => {
    return action$.
      pipe(
        filter(({ type }) => type === AHubActions.USER_ID_BY_SESSION_FETCH),
        switchMap(action => this.aHubService.userIdBySession()
          .pipe(
            concatMap(result => of(
              AppActions.sessionUserIdSet(result),
              AHubActions.sessionUsersByIdsFetch([result]),
              AHubActions.userClientIndexsFetch(result))
            ),
            catchError((error: any, caught: Observable<ActionWork>) => {

              // Navigate to logout.
              AppRoutingNavigation.navigateLogout(this.router);

              // Clear the session data if the session has expired or refused. or genrally broken.
              return this.actionStatusGenericError("Unable to get user from session info.");
            }))

        ));
  }


  userHasAviewsFetch = (action$: Observable<ActionWork>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.USER_HAS_AVIEWS_FETCH),
      mergeMap(action => this.requestSingleDataToAction(this.aHubService.exportDistributionAviews().pipe(
        map(data => data
          .filter(exportDistribution => exportDistribution &&
            exportDistribution.distributionExportVersions &&
            exportDistribution.distributionExportVersions.length > 0)
          .length > 0)
      ), action, AHubActions.userHasAViewsSet)));
  }

  sessionInfoFetch = (action$: Observable<ActionWork>) => {
    return action$.pipe(filter(({ type }) => type === AppActions.SESSION_INFO_FETCH),
      mergeMap(action => this.aHubService.sessionInfo().pipe(mergeMap((result) => of(AppActions.sessionUserSessionCredentialsSet(result))))));
  }

  sessionInvalidate = (action$: Observable<ActionWork>) => {
    return action$.pipe(filter(({ type }) => type === AppActions.SESSION_INVALIDATE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.sessionInvalidate(), action)));
  }


  userCommit = (action$: Observable<ActionUserAHubVO>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.USER_COMMIT),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.userCommit(action.user), action)));
  }

  sessionUsersByIdsFetch = (action$: Observable<ActionNumberArray>) => {
    return action$
      .pipe(
        filter(({ type }) => type === AHubActions.SESSION_USERS_BY_IDS_FETCH),
        mergeMap(action => {
          return this.requestDataToAction(this.aHubService.usersByIds(action.numbers), action, AHubActions.sessionUsersSet);
        })
      )
  }

  usersByIdsFetch = (action$: Observable<ActionNumberArray>) => {
    return action$
      .pipe(
        filter(({ type }) => type === AHubActions.USERS_BY_IDS_FETCH),
        mergeMap(action => {
          return this.requestDataToAction(this.aHubService.usersByIds(action.numbers), action, AHubActions.usersSet);
        })
      )
  }

  userIndexesByIdsFetch = (action$: Observable<ActionNumberArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.USER_INDEXES_BY_IDS_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.userIndexByIds(action.numbers), action, AHubActions.userIndexesSet)));
  }

  userIndexsFullFetch = (action$: Observable<ActionWork>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.USER_INDEXES_FULL_FETCH),
      // .mergeMap(action => this.aHubService.userIndexFull().map(a => AHubActions.userIndexsPaginatedSet(a)));
      // POSSIBLE NEW WAY BELOW ?
      mergeMap(action =>
        this.requestIncrementalDataToAction(this.aHubService.userIndexFull(this.cancelAction(action$, action.actionId)), action, AHubActions.userIndexsPaginatedSet)));
  }

  userIndexesByClientIdFetch = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.USER_INDEXES_BY_CLIENT_ID_FETCH),
      // .mergeMap(action => this.aHubService.userIndexFull().map(a => AHubActions.userIndexsPaginatedSet(a)));
      // POSSIBLE NEW WAY BELOW ?
      mergeMap(action =>
        this.requestIncrementalDataToAction(this.aHubService.userIndexesByClientIdFetch(
          action.number,
          this.cancelAction(action$, action.actionId)), action, AHubActions.userIndexsPaginatedSet)));
  }

  userExtendedsByIdsFetch = (action$: Observable<ActionNumberArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.USER_EXTENDEDS_BY_IDS_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.userExtendedsByIds(action.numbers), action, AHubActions.userExtendedsSet)));
  }

  userClientIndexsFetch = (action$: Observable<ActionNumber>) => {
    return action$
      .pipe(
        filter(({ type }) => type === AHubActions.USER_CLIENTS_INDEXS_FETCH),
        mergeMap(action => {
          return this.requestDataToAction(this.aHubService.clientIndexsByUserId(action.number), action, AHubActions.userClientIndexsSet)
        })
      )
  }

  dataSetIndexsFetch = (action$: Observable<ActionWork>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DATASET_INDEXS_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.dataSetIndexs(), action, AHubActions.dataSetIndexsSet)));
  }

  dataSetsByIdsFetch = (action$: Observable<ActionNumberArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DATASETS_BY_ID_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.dataSetsByIds(action.numbers), action, AHubActions.dataSetsSet)));
  }

  dataSetAdd = (action$: Observable<ActionDataSetAHubVO>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DATASET_ADD),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.dataSetAdd(action.dataSet), action)))
  }

  dataSetSave = (action$: Observable<ActionDataSetAHubVO>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DATASET_SAVE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.dataSetSave(action.dataSet), action)))
  }

  dataSetDelete = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DATASET_DELETE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.dataSetDelete(action.number), action)))
  }

  dataSetCopy = (action$: Observable<ActionStringNumberBoolean>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DATASET_COPY),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.dataSetCopy(action.number, action.string, action.boolean), action)))
  }

  dataSetSwapSectionProperties = (action$: Observable<ActionNumberNumberNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DATASET_SECTION_PROPERTIES_SWAP),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.dataSetSwapSectionProperties(action.number1, action.number2, action.number3), action)))
  }

  dataSetProductsByIdsFetch = (action$: Observable<ActionNumberNumbersArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DATA_SET_PRODUCTS_BY_IDS_FETCH),
      mergeMap(action => {
        return this.requestDataToAction(this.aHubService.dataSetProductsByIds(action.number, action.numbers), action, (res) => AHubActions.dataSetProductsSet(action.number, res))
      }));
  }

  dataSetProductAssetsByIdsFetch = (action$: Observable<ActionNumberNumbersArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DATA_SET_PRODUCT_ASSETS_BY_IDS_FETCH),
      mergeMap(action => {
        return this.requestDataToAction(
          this.aHubService.dataSetProductAssetsByIds(action.number, action.numbers), action, (res) => AHubActions.dataSetSpecificProductsAssetsSet(action.number, res)
        )
      }));
  }

  dataSetProductAssetZipGenerate = (action$: Observable<ActionNumberNumberString>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DATA_SET_PRODUCT_ASSET_ZIP_GENERATE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.dataSetProductAssetZipGenerate(action.number1, action.number2, action.string), action)))
  }

  dataSetCategoriesFetchById = (action$: Observable<ActionNumberArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DATA_SET_CATEGORIES_BY_ID_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.dataSetCategoriesByIds(action.numbers), action, AHubActions.dataSetCategoriesSet)));
  }

  dataSetCategoryAdd = (action$: Observable<ActionDataSetCategoryAHubVO>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DATA_SET_CATEGORY_ADD),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.dataSetCategoryAdd(action.dataSetCategory), action)))
  }

  dataSetCategoriesCopy = (action$: Observable<ActionNumberNumberNumbersArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DATA_SET_CATEGORIES_COPY),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.dataSetCopyCategories(action.number1, action.numbers, action.number2), action)))
  }

  dataSetCategoryCommit = (action$: Observable<ActionDataSetCategoryAHubVO>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DATA_SET_CATEGORY_COMMIT),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.dataSetCategoryCommit(action.dataSetCategory), action)))
  }

  dataSetCategoryCommitProductPropertyFilter = (action$: Observable<ActionProductPropertyFilterAHubVONumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DATA_SET_CATEGORY_COMMIT_PRODUCT_PROPERTY_FILTER),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.dataSetCategoryCommitProductPropertyFilter(action.number, action.productPropertyFilter), action)))
  }

  dataSetCategoryCommitProductClassFilter = (action$: Observable<ActionProductClassFilterAHubVONumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DATA_SET_CATEGORY_COMMIT_PRODUCT_CLASS_FILTER),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.dataSetCategoryCommitProductClassFilter(action.number, action.productClassFilter), action)))
  }

  dataSetCategoryCommitProductSort = (action$: Observable<ActionProductSortAHubVONumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DATA_SET_CATEGORY_COMMIT_PRODUCT_SORT),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.dataSetCategoryCommitProductSort(action.number, action.productSort), action)))
  }

  dataSetCategoryCommitPriority = (action$: Observable<ActionNumberNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DATA_SET_CATEGORY_COMMIT_PRIORITY),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.dataSetCategoryCommitPriority(action.number1, action.number2), action)))
  }

  dataSetCategoryDelete = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DATA_SET_CATEGORY_DELETE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.dataSetCategoryDelete(action.number), action)))
  }

  dataSetCategoryMove = (action$: Observable<ActionNumberArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DATA_SET_CATEGORY_MOVE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.dataSetCategoryMove(action.numbers[0], action.numbers[1]), action)))
  }

  dataSetCategoryProductsFetch = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DATA_SET_CATEGORY_PRODUCTS_FETCH),
      mergeMap(action => {

        //Get the current time before any of this requests nonsence
        let now = new Date().getTime();

        //Get the category id
        let categoryId = action.number;

        //Create a new request subject
        let requestSubject: Subject<ActionWork> = new Subject();

        let req = this.aHubService.dataSetCategoryProducts(
          categoryId,
          this.cancelAction(action$, action.actionId)
        ).pipe(
          map(prod => { return { number: action.number, products: prod } })
        );

        //Make the request to get all the product data for this category
        this.requestIncrementalDataToAction(
          req,
          action,
          AHubActions.dataSetCategoryProductsAppendAndRemove).subscribe(action => {

            //Publish the action to the request subject
            requestSubject.next(action);
          },
            () => undefined,
            () => {

              //Now we have finished collating all the data, we want to go and remove any data which existed before we
              //started collating it! This will remove old data which should no longer be in there
              requestSubject.next(AHubActions.dataSetCategoryProductsRemoveBeforeTime(categoryId, now));


              //We also we want to call the aHub and ask for the sorted product id order, this will allow us to sort the products correctly
              requestSubject.next(AHubActions.dataSetCategoryProductIdSortedFetch(categoryId));

              //Complete the stream
              requestSubject.complete();
            });

        //Return an observable of the request subject
        return requestSubject.asObservable();
      }));
  }

  dataSetCategoryProductsByIdFetch = (action$: Observable<ActionNumberNumbersArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DATA_SET_CATEGORY_PRODUCTS_FETCH_BY_ID),
      mergeMap(action =>
        this.requestSingleDataToAction(this.aHubService.dataSetCategoryProductsFetchByIds(action.number, action.numbers).pipe(
          reduce((a, v) => a.concat(v), []),
          last(),
          map(products => {

            //Generate a list of product id's based on the returned data
            let productIds = products.map(prod => prod.id);

            //Get the list of the product ids which we asked for but no products were not returned for. This suggests they don't belong
            //to this extract so we will colate the missing ids so we can report them
            let missingIds = (productIds.length > 0) ? action.numbers.filter(id => productIds.indexOf(id) == -1) : action.numbers;

            //Return an object which which has the products and the missing id's
            return { number: action.number, products: products, numbers: missingIds };

          })), action, AHubActions.dataSetCategoryProductsAppendAndRemove)));
  }

  dataSetCategoryProductIdsSortedFetch = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DATA_SET_CATEGORY_PRODUCT_ID_SORTED_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.dataSetCategoryProductIdsSortOrder(action.number), action,
        (data) => AHubActions.dataSetCategoryProductIdSortedSet(action.number, data))));
  }

  clientIndexsFetch = (action$: Observable<ActionWork>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.CLIENT_INDEXS_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.clientIndexs(), action, AHubActions.clientIndexsSet)));
  }

  clientsByIdsFetch = (action$: Observable<ActionNumberArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.CLIENTS_BY_ID_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.clientsByIds(action.numbers), action, AHubActions.clientsSet)));
  }

  clientAdd = (action$: Observable<ActionClientAHubVO>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.CLIENT_ADD),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.clientAdd(action.client), action)))
  }

  clientSave = (action$: Observable<ActionClientAHubVO>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.CLIENT_SAVE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.clientSave(action.client), action)))
  }

  clientDisable = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.CLIENT_DISABLE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.clientDisable(action.number), action)))
  }

  clientDelete = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.CLIENT_DELETE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.clientDelete(action.number), action)))
  }

  clientEnable = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.CLIENT_ENABLE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.clientEnable(action.number), action)))
  }

  clientLibraryVersionIndexsFetch = (action$: Observable<ActionWork>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.CLIENT_LIBRARY_VERSION_INDEXS_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.clientLibraryVersionIndex(), action, AHubActions.clientLibraryVersionIndexSet)));
  }

  clientLibraryVersionsFetchById = (action$: Observable<ActionNumberArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.CLIENTS_LIBRARY_VERSION_BY_ID_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.clientLibraryVersion(action.numbers), action, AHubActions.clientLibraryVersionSet)));
  }

  clientLibraryVersionSaveBuildFrom = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.CLIENTS_LIBRARY_VERSION_SAVE_BUILD_FROM),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.clientLibraryVersionSaveBuildFrom(action.number), action)));
  }

  clientLibraryVersionModelGenerate = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.CLIENT_LIBRARY_VERSION_MODEL_GENERATE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.clientLibraryVersionModelGenerate(action.number), action)));
  }

  clientLibraryVersionModelPresignedUrlFetch = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.CLIENT_LIBRARY_VESION_MODEL_URL_FETCH),
      mergeMap(action => this.requestIndexSingleDataToAction(this.aHubService.clientLibraryVersionModelPresignedUrlFetch(action.number), action, AHubActions.clientLibraryVersionModelUrlsSet)));
  }

  clientLibraryVersionAdd = (action$: Observable<ActionClientLibraryVersionAHubVO>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.CLIENT_LIBRARY_VERSION_ADD),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.clientLibraryVersionAdd(action.clientLibraryVersion), action)));
  }

  clientLibraryVersionCommit = (action$: Observable<ActionClientLibraryVersionAHubVO>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.CLIENT_LIBRARY_VERSION_COMMIT),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.clientLibraryVersionCommit(action.clientLibraryVersion), action)));
  }

  clientLibraryVersionValidate = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.CLIENT_LIBRARY_VERSION_VALIDATE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.clientLibraryVersionValidate(action.number), action)));
  }

  clientLibraryVersionDelete = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.CLIENT_LIBRARY_VERSION_DELETE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.clientLibraryVersionDelete(action.number), action)));
  }

  clientLibraryVersionsDiscover = (action$: Observable<ActionWork>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.CLIENT_LIBRARY_VERSIONS_DISCOVER),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.clientLibraryVersionsDiscover(), action)));
  }

  clientStorageIndexesFetch = (action$: Observable<ActionWork>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.CLIENT_STORAGE_INDEXES_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.clientStorageIndexes(), action, AHubActions.clientStorageIndexesSet)));
  }

  clientLibraryIndexesFetch = (action$: Observable<ActionWork>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.CLIENT_LIBRARY_INDEXES_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.clientLibraryIndexes(), action, AHubActions.clientLibraryIndexesSet)));
  }

  clientLibrariesByIdFetch = (action$: Observable<ActionNumberArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.CLIENTS_LIBRARIES_BY_ID_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.clientLibrariesById(action.numbers), action, AHubActions.clientLibrariesSet)));
  }

  clientLibraryUpgradeAndUse = (action$: Observable<ActionSystemClientLibraryId>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.CLIENT_LIBRARY_UPGRADE_AND_USE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.clientLibraryUpgradeAndUse(action.clientId, action.libraryUseId, action.libraryWithId), action)));
  }

  clientLibraryAdd = (action$: Observable<ActionNumberNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.CLIENT_LIBRARY_ADD),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.clientLibraryAdd(action.number1, action.number2), action)));
  }

  clientLibraryDelete = (action$: Observable<ActionSystemClientLibraryId>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.CLIENT_LIBRARY_DELETE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.clientLibraryDelete(action.clientId, action.libraryUseId), action)));
  }

  clientLibraryUse = (action$: Observable<ActionSystemClientLibraryId>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.CLIENT_LIBRARY_USE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.clientLibraryUse(action.clientId, action.libraryUseId, action.libraryWithId), action)));
  }

  clientLibrarySync = (action$: Observable<ActionSystemClientLibraryId>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.CLIENT_LIBRARY_SYNC),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.clientLibrarySync(action.clientId, action.libraryUseId), action)));
  }

  clientLibrarySyncFrom = (action$: Observable<ActionSystemClientLibraryId>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.CLIENT_LIBRARY_SYNC_FROM),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.clientLibrarySyncFrom(action.clientId, action.libraryUseId, action.libraryWithId), action)));
  }

  clientLibraryCommit = (action$: Observable<ActionSystemClientLibrary>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.CLIENT_LIBRARY_COMMIT),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.clientLibraryCommit(action.clientId, action.clientLibrary), action)));
  }

  distributionGroupIndexsFetch = (action$: Observable<ActionWork>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DISTRIBUTION_GROUP_INDEXS_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.distributionGroupIndexsBySessionClientId(), action, AHubActions.distributionGroupIndexsSet)));
  }

  distributionGroupIndexsByClientIdFetch = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DISTRIBUTION_GROUP_INDEXES_BY_CLIENT_ID_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.distributionGroupIndexsByClientId(action.number), action, AHubActions.distributionGroupIndexsSet)));
  }

  distributionGroupIndexsByClientIdAppend = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DISTRIBUTION_GROUP_INDEXES_BY_CLIENT_ID_APPEND),
      mergeMap(action => this.requestDataToAction(this.aHubService.distributionGroupIndexsByClientId(action.number), action, AHubActions.distributionGroupIndexsAppend)));
  }

  distributionGroupUserIndexsFetch = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DISTRIBUTION_GROUP_USER_INDEXS_FETCH),
      mergeMap(action => this.requestIndexDataToAction(this.aHubService.distributionGroupUserIndex(action.number), action, AHubActions.distributionGroupUserIndexsSet)));
  }

  distributionGroupIndexesByUserIdFetch = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DISTRIBUTION_GROUP_INDEXES_BY_USER_ID_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.distributionGroupIndexesByUserId(action.number), action, ViewActions.selectedUserDistributionGroupIndexesSet)));
  }

  distributionGroupIndexesByClientIdAndUserIdFetch = (action$: Observable<ActionNumberNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DISTRIBUTION_GROUP_INDEXES_BY_CLIENT_ID_AND_USER_ID_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.distributionGroupIndexesByClientIdAndUserId(action.number1, action.number2), action, ViewActions.selectedUserDistributionGroupIndexesSet)));
  }

  distributionGroupDistributionIndexsFetch = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DISTRIBUTION_GROUP_DISTRIBUTION_INDEXS_FETCH),
      mergeMap(action => this.requestIndexDataToAction(this.aHubService.distributionGroupDistributionIndex(action.number), action, AHubActions.distributionGroupDistributionIndexsSet)));
  }

  distributionGroupsFetch = (action$: Observable<ActionNumberArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DISTRIBUTION_GROUPS_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.distributionGroupsByIds(action.numbers), action, AHubActions.distributionGroupSet)));
  }

  distributionsFetch = (action$: Observable<ActionNumberArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DISTRIBUTIONS_BY_IDS_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.distributionsByIds(action.numbers), action, AHubActions.distributionsSet)));
  }

  distributionGroupAdd = (action$: Observable<ActionDistributionGroupAHubVO>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DISTRIBUTION_GROUP_ADD),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.distributionGroupAdd(action.distributionGroup), action)))
  }

  distributionGroupSave = (action$: Observable<ActionDistributionGroupAHubVO>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DISTRIBUTION_GROUP_SAVE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.distributionGroupSave(action.distributionGroup), action)))
  }


  distributionGroupDelete = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DISTRIBUTION_GROUP_DELETE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.distributionGroupDelete(action.number), action)))
  }


  distributionGroupUserEmailAdd = (action$: Observable<ActionStringNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DISTRIBUTION_GROUP_USER_EMAIL_ADD),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.distributionGroupUserEmailAdd(action.string, action.number), action)))
  }

  distributionGroupUserJoinByKey = (action$: Observable<ActionStringNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DISTRIBUTION_GROUP_USER_JOIN_BY_KEY),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.distributionGroupUserJoinByKey(action.string, action.number), action)))
  }

  distributionGroupUserIdRemove = (action$: Observable<ActionNumberArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DISTRIBUTION_GROUP_USER_ID_REMOVE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.distributionGroupUserIdRemove(action.numbers[0], action.numbers[1]), action)))
  }

  distributionGroupUserIdsRemove = (action$: Observable<ActionNumberNumbersArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DISTRIBUTION_GROUP_USER_IDS_REMOVE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.distributionGroupUserIdsRemove(action.number, action.numbers), action)))
  }

  distributionGroupsUserIdRemove = (action$: Observable<ActionNumberNumbersArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DISTRIBUTION_GROUPS_USER_ID_REMOVE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.distributionGroupsUserIdRemove(action.number, action.numbers), action)))
  }

  distributionGroupsUserIdAdd = (action$: Observable<ActionNumberNumbersArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DISTRIBUTION_GROUPS_USER_ID_ADD),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.distributionGroupsUserIdAdd(action.number, action.numbers), action)))
  }

  distributionsAdd = (action$: Observable<ActionDistributionAHubVOs>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DISTRIBUTION_ADD),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.distributionAdd(action.distributions), action)))
  }

  distributionSave = (action$: Observable<ActionDistributionAHubVO>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DISTRIBUTION_SAVE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.distributionSave(action.distribution), action)))
  }

  distributionDelete = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DISTRIBUTION_DELETE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.distributionDelete(action.number), action)))
  }

  distributionsDelete = (action$: Observable<ActionNumberArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DISTRIBUTIONS_DELETE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.distributionsDelete(action.numbers), action)))
  }

  distributionsDisable = (action$: Observable<ActionNumberArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DISTRIBUTIONS_DISABLE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.distributionsDisable(action.numbers), action)))
  }

  distributionsEnable = (action$: Observable<ActionNumberArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DISTRIBUTIONS_ENABLE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.distributionsEnable(action.numbers), action)))
  }

  distributionParametersCopy = (action$: Observable<ActionNumberNumbersArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.DISTRIBUTION_PARAMETERS_COPY),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.distributionParametersCopy(action.number, action.numbers), action)))
  }

  exportTypesAllFetch = (action$: Observable<ActionWork>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXPORT_TYPES_ALL_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.exportTypesAll(), action, AHubActions.exportTypesAllSet)));
  }

  exportGeneratorsAllFetch = (action$: Observable<ActionWork>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXPORT_GENERATORS_ALL_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.exportGeneratorsAll(), action, AHubActions.exportGeneratorsAllSet)));
  }

  exportGeneratorMappingBlueprintUrlFetch = (action$: Observable<ActionExporterBlueprintFetch>) => {
    return action$.pipe(
      filter(({ type }) => type === AHubActions.EXPORT_GENERATOR_MAPPING_BLUEPRINT_URL_FETCH),
      mergeMap(action => this.requestSingleDataToAction(
        this.aHubService.exportGeneratorMappingBlueprintUrl(action.exporterGeneratorId, action.exporterGeneratorMajorVersion), action, (res) => {
          return AHubActions.exportGeneratorMappingBlueprintURLSet(action.exporterId, res);
        })));
  }

  exportIndexsFetch = (action$: Observable<ActionWork>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXPORT_INDEXS_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.exportIndexesByClientId(), action, AHubActions.exportIndexsSet)));
  }

  exportsByIdsFetch = (action$: Observable<ActionNumberArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXPORTS_BY_ID_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.exportsByIds(action.numbers), action, AHubActions.exportsSet)));
  }

  exportDistributionIndexsFetch = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXPORT_DISTRIBUTION_INDEXS_FETCH),
      mergeMap(action => this.requestIndexDataToAction(this.aHubService.exportDistributionIndex(action.number), action, AHubActions.exportDistributionIndexsSet)));
  }

  exportAdd = (action$: Observable<ActionExportAHubVO>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXPORT_ADD),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.exportAdd(action.export), action)))
  }

  exportSave = (action$: Observable<ActionExportAHubVO>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXPORT_SAVE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.exportSave(action.export), action)))
  }

  exportDelete = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXPORT_DELETE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.exportDelete(action.number), action)))
  }

  exportPreviewImageUrlFetch = (action$: Observable<ActionNumberNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXPORT_PREVIEWIMAGE_URL_FETCH),
      mergeMap(action => this.requestIndexSingleDataToAction(this.aHubService.exportPreviewImageUrl(action.number1, action.number2),
        {
          actionId: action.actionId,
          number: action.number1,
          type: action.type
        },
        AHubActions.exportPreviewImageURLSet)));
  }

  exportLatestVersionPathUrlFetch = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXPORT_LATEST_VERSION_PATH_URL_FETCH),
      mergeMap(action => this.requestIndexSingleDataToAction(this.aHubService.exportLatestVersionPathUrl(action.number), action, AHubActions.exportLatestVersionPathURLSet)));
  }

  exportVersionPathUrlFetch = (action$: Observable<ActionNumberNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXPORT_VERSION_PATH_URL_FETCH),
      mergeMap(action => this.requestIndexSingleDataToAction(
        this.aHubService.exportVersionPathUrl(action.number1, action.number2), {
        actionId: action.actionId,
        number: action.number1,
        type: action.type
      }, AHubActions.exportVersionPathURLSet)));
  }

  exportLegacyRefFetch = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXPORT_LEGACY_REF_FETCH),
      mergeMap(action => this.requestIndexSingleDataToAction(this.aHubService.exportLegacyRefs(action.number), action, AHubActions.exportLegacyRefSet)));
  }

  exportLegacyRefCommit = (action$: Observable<ActionNumberStringArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXPORT_LEGACY_REF_COMMIT),
      mergeMap(action => this.requestIndexSingleDataToAction(this.aHubService.exportLegacyRefsCommit(action.number, action.strings), action, AHubActions.exportLegacyRefFetch)));
  }

  exportVersionCommit = (action$: Observable<ActionExportVersionAHubVO>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXPORT_VERSION_COMMIT),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.exportVersionCommit(action.exportVersion), action)))
  }

  exportVersionDelete = (action$: Observable<ActionExportIdExportVersionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXPORT_VERSION_DELETE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.exportVersionDelete(action.exportId, action.exportVersionNumber), action)))
  }

  exportVersionWithdraw = (action$: Observable<ActionExportVersionWithdraw>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXPORT_VERSION_WITHDRAW),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.exportVersionWithdraw(action.exportId, action.exportVersionNumber, action.withdraw), action)))
  }

  exportVersionStateDisableSave = (action$: Observable<ActionExportIdExportVersionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXPORT_VERSION_STATE_HIDDEN_SAVE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.exportVersionHidden(action.exportId, action.exportVersionNumber), action)))
  }

  exportVersionStateInternalSave = (action$: Observable<ActionExportIdExportVersionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXPORT_VERSION_STATE_INTERNAL_SAVE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.exportVersionInternal(action.exportId, action.exportVersionNumber), action)))
  }

  exportVersionStatePreviewSave = (action$: Observable<ActionExportIdExportVersionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXPORT_VERSION_STATE_PREVIEW_SAVE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.exportVersionPreview(action.exportId, action.exportVersionNumber), action)))
  }

  exportVersionStateLimitedReleaseSave = (action$: Observable<ActionExportIdExportVersionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXPORT_VERSION_STATE_LIMITED_RELEASE_SAVE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.exportVersionLimitedRelease(action.exportId, action.exportVersionNumber), action)))
  }

  exportVersionStateReleaseSave = (action$: Observable<ActionExportIdExportVersionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXPORT_VERSION_STATE_RELEASE_SAVE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.exportVersionRelease(action.exportId, action.exportVersionNumber), action)))
  }

  exportVersionFilesZipDownload = (action$: Observable<ActionExportIdExportVersionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXPORT_VERSION_FILES_ZIP_DOWNLOAD),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.exportVersionFileZipDownload(action.exportId, action.exportVersionNumber), action)))
  }

  exportDistributionsFetch = (action$: Observable<ActionExportTypeCodesAndNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXPORT_DISTRIBUTIONS_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.exportDistributions(action.number, action.exporTypeCodes), action, AHubActions.exportDistributionsSet)));
  }

  exporterIndexesFetch = (action$: Observable<ActionWork>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXPORTER_INDEXES_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.exporterIndexesByClientId(), action, AHubActions.exporterIndexesSet)));
  }

  exportersByIdsFetch = (action$: Observable<ActionNumberArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXPORTERS_BY_ID_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.exportersByIds(action.numbers), action, AHubActions.exportersSet)));
  }

  exporterAdd = (action$: Observable<ActionExporterAHubVO>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXPORTER_ADD),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.exporterAdd(action.exporter), action)))
  }

  exporterSave = (action$: Observable<ActionExporterAHubVO>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXPORTER_SAVE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.exporterSave(action.exporter), action)))
  }

  exporterDelete = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXPORTER_DELETE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.exporterDelete(action.number), action)))
  }

  exporterCopy = (action$: Observable<ActionStringNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXPORTER_COPY),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.exporterCopy(action.number, action.string), action)))
  }

  exporterBuildHistoryIndexesById = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXPORTER_BUILD_HISTORY_INDEX_FETCH),
      mergeMap(action => this.requestIndexDataToAction(this.aHubService.exporterBuildHistoryIndexesById(action.number), action, (actionId, exporterBuildHistorys) => AHubActions.exporterBuildHistoryIndexesSet(action.number, exporterBuildHistorys))));
  }

  exporterBuildHistoryByIds = (action$: Observable<ActionNumberArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXPORTER_BUILD_HISTORYS_BY_IDS_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.exporterBuildHistoryByIds(action.numbers), action, AHubActions.exporterBuildHistorysSet)));
  }

  exporterBuild = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXPORTER_BUILD),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.exporterBuild(action.number), action)))
  }

  exporterCancelBuild = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXPORTER_CANCEL_BUILD),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.exporterCancelBuild(action.number), action)))
  }

  exporterMappingCommit = (action$: Observable<ActionExportGeneratorExporterMappingAHubVO>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXPORTER_MAPPING_COMMIT),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.exporterMappingCommit(action.exporterId, action.exportGeneratorExporterMapping), action)))
  }

  exporterSettingsCommit = (action$: Observable<ActionExporterExportGeneratorSettingsAHubVO>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXPORTER_SETTINGS_COMMIT),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.exporterSettingsCommit(action.exporterId, action.exporterExportGeneratorSettings), action)))
  }

  workGroupIndexsFetch = (action$: Observable<ActionWork>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.WORK_GROUP_INDEXS_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.workGroupIndexsByClientId(), action, AHubActions.workGroupIndexsSet)));
  }

  workGroupsFetch = (action$: Observable<ActionNumberArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.WORK_GROUPS_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.workGroupsByIds(action.numbers), action, AHubActions.workGroupsSet)));
  }

  workGroupAdd = (action$: Observable<ActionWorkGroupAHubVO>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.WORK_GROUP_ADD),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.workGroupAdd(action.workGroup), action)))
  }

  workGroupSave = (action$: Observable<ActionWorkGroupAHubVO>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.WORK_GROUP_SAVE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.workGroupSave(action.workGroup), action)))
  }

  workGroupDelete = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.WORK_GROUP_DELETE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.workGroupDelete(action.number), action)))
  }

  workGroupUserIndexsFetch = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.WORK_GROUP_USER_INDEXS_FETCH),
      mergeMap(action => this.requestIndexDataToAction(this.aHubService.workGroupUserIndex(action.number), action, AHubActions.workGroupUserIndexsSet)));
  }

  workGroupUserEmailAdd = (action$: Observable<ActionStringNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.WORK_GROUP_USER_EMAIL_ADD),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.workGroupUserEmailAdd(action.string, action.number), action)))
  }

  workGroupUserJoinByKey = (action$: Observable<ActionStringNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.WORK_GROUP_USER_JOIN_BY_KEY),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.workGroupUserJoinByKey(action.string, action.number), action)))
  }

  workGroupUserIdRemove = (action$: Observable<ActionNumberArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.WORK_GROUP_USER_ID_REMOVE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.workGroupUserIdRemove(action.numbers[0], action.numbers[1]), action)))
  }

  workGroupUserIdsRemove = (action$: Observable<ActionNumberNumbersArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.WORK_GROUP_USER_IDS_REMOVE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.workGroupUserIdsRemove(action.number, action.numbers), action)))
  }

  workGroupsByUserAndClientFetch = (action$: Observable<ActionWork>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.WORK_GROUPS_BY_USER_AND_CLIENT_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.workGroupsByUserAndClientId(), action, AHubActions.workGroupsPermanentSet)));
  }

  entityPermissionsFetch = (action$: Observable<ActionWork>) => {
    return action$.pipe(filter(({ type }) => type === AppActions.ENTITY_PERMISSIONS_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.entityPermissionsByUserAndClientId(), action, AppActions.entityPermissionsSet)));
  }


  clientQuotasByClientIdFetch = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.CLIENT_QUOTAS_BY_CLIENT_ID_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.clientQuotasByClientId(action.number), action, AHubActions.clientQuotasSet)));
  }

  clientQuotasHistoryByClientIdFetch = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.CLIENT_QUOTAS_HISTORY_BY_CLIENT_ID_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.clientQuotasHistoryByClientId(action.number), action, AHubActions.clientQuotasHistorySet)));
  }

  clientQuotaByIdsFetch = (action$: Observable<ActionNumberArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.CLIENT_QUOTA_BY_CLIENT_QUOTA_ID_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.clientQuotaByIds(action.numbers), action, AHubActions.clientQuotaSet)));
  }

  clientQuotasCommit = (action$: Observable<ActionClientQuotasAHubVOs>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.CLIENT_QUOTAS_COMMIT),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.clientQuotasCommit(action.clientQuotas), action)));
  }

  clientConfigurationByClientIdFetch = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.CLIENT_CONFIGURATION_BY_CLIENT_ID_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.clientConfigurationByClientId(action.number), action, AHubActions.clientConfigurationSet)));
  }

  clientConfigurationCommit = (action$: Observable<ActionClientConfigurationAHubVO>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.CLIENT_CONFIGURATION_COMMIT),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.clientConfigurationCommit(action.clientConfiguration), action)));
  }

  sessionClientConfigurationFetch = (action$: Observable<ActionWork>) => {
    return action$.pipe(filter(({ type }) => type === AppActions.SESSION_CLIENT_CONFIG_FETCH),
      mergeMap(action => this.requestSingleDataToAction(this.aHubService.sessionClientConfiguration(), action, AppActions.sessionClientConfigSet)));
  }

  registerUser = (action$: Observable<ActionStringArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.REGISTER_USER),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.registerUser(action.strings[0], action.strings[1], action.strings[2]), action)))
  }

  verifyUser = (action$: Observable<ActionStringArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.VERIFY_USER),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.verifyUser(action.strings[0], action.strings[1], action.strings[2]), action)))
  }

  verifyEmailUpdate = (action$: Observable<ActionStringNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.VERIFY_EMAIL_UPDATE),
      mergeMap(action => this.requestTicketToActionStatusVO(
        this.aHubService.verifyEmailUpdate(action.number, action.string), action)));
  }

  verifyUserManualOverride = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.VERIFY_USER_MANUAL_OVERRIDE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.verifyUserManualOverride(action.number), action)))
  }

  sendVerificationCode = (action$: Observable<ActionString>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.SEND_VERIFICATION_CODE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.sendVerificationCode(action.string), action)))
  }

  verificationProblemsContactSupport = (action$: Observable<ActionString>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.VERIFICATION_PROBLEMS_CONTACT_SUPPORT),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.verificationProblemsContactSupport(action.string), action)))
  }

  sendEmailUpdateVerificationCode = (action$: Observable<ActionStringNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.SEND_EMAIL_UPDATE_VERIFICATION_CODE),
      mergeMap(action => this.requestTicketToActionStatusVO(
        this.aHubService.sendEmailUpdateVerificationCode(action.number, action.string), action)));
  }
  /**
   * -----------------------------------------------------
   * Library
   * -----------------------------------------------------
   */

  exportUpdateCancel = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXPORT_UPDATE_CANCEL_REMOVE),
      mergeMap(action => this.aHubService.exportUpdateCancel(action.number)),
      map(result => this.NOOP_ACTION));
  }

  productClassProductCountFetch = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.PRODUCT_CLASS_PRODUCT_COUNTS_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.productClassProductCountGet(), action, AHubActions.productClassProductCountsSet)));
  }

  productClassIndexsByClientIdFetch = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.PRODUCT_CLASS_INDEXS_BY_CLIENT_ID_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.productClassIndexsByClientId(), action, AHubActions.productClassIndexsSet)));
  }

  productClassesFetch = (action$: Observable<ActionNumberNumbersArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.PRODUCT_CLASSES_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.productClasses(action.numbers), action, AHubActions.productClassesSet)));
  }

  productClassAdd = (action$: Observable<ActionProductClassAHubVO>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.PRODUCT_CLASS_ADD),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.productClassAdd(action.productClass), action)));
  }

  productClassCommit = (action$: Observable<ActionProductClassAHubVO>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.PRODUCT_CLASS_COMMIT),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.productClassCommit(action.productClass), action)));
  }

  productClassDelete = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.PRODUCT_CLASS_DELETE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.productClassDelete(action.number), action)));
  }

  productClassMove = (action$: Observable<ActionNumberNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.PRODUCT_CLASS_MOVE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.productClassMove(action.number1, action.number2), action)));
  }

  productPropertyTypesFetch = (action$: Observable<ActionWork>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.PRODUCT_PROPERTY_TYPES_BY_CLIENT_ID_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.productPropertyTypes(), action, AHubActions.productPropertyTypesSet)));
  }

  productPropertyIndexsFetch = (action$: Observable<ActionWork>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.PRODUCT_PROPERTY_INDEXS_BY_CLIENT_ID_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.productPropertyIndexs(), action, AHubActions.productPropertyIndexsSet)));
  }

  productPropertyFetch = (action$: Observable<ActionNumberArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.PRODUCT_PROPERTY_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.productProperty(action.numbers), action, AHubActions.productPropertySet)));
  }

  productPropertyAdd = (action$: Observable<ActionProductPropertyAHubVO>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.PRODUCT_PROPERTY_ADD),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.productPropertyAdd(action.productProperty), action)))
  }

  productPropertyCommit = (action$: Observable<ActionProductPropertyAHubVO>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.PRODUCT_PROPERTY_COMMIT),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.productPropertyCommit(action.productProperty), action)))
  }

  productPropertyDelete = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.PRODUCT_PROPERTY_DELETE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.productPropertyDelete(action.number), action)))
  }

  productPropertySectionIndexsFetch = (action$: Observable<ActionWork>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.PRODUCT_PROPERTY_SECTION_INDEXS_BY_CLIENT_ID_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.productPropertySectionIndexs(), action, AHubActions.productPropertySectionIndexsSet)));
  }

  productPropertySectionByIds = (action$: Observable<ActionNumberArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.PRODUCT_PROPERTY_SECTIONS_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.productPropertySectionByIds(action.numbers), action, AHubActions.productPropertySectionsSet)));
  }

  productPropertySectionAdd = (action$: Observable<ActionProductPropertySectionAHubVO>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.PRODUCT_PROPERTY_SECTION_ADD),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.productPropertySectionAdd(action.productPropertySection), action)))
  }

  productPropertySectionCopy = (action$: Observable<ActionProductPropertySectionAHubVO>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.PRODUCT_PROPERTY_SECTION_COPY),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.productPropertySectionCopy(action.productPropertySection, action.sourceSectionId, action.deepCopy), action)))
  }

  productPropertySectionCommit = (action$: Observable<ActionProductPropertySectionAHubVO>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.PRODUCT_PROPERTY_SECTION_COMMIT),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.productPropertySectionCommit(action.productPropertySection), action)))
  }

  productPropertySectionDelete = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.PRODUCT_PROPERTY_SECTION_DELETE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.productPropertySectionDelete(action.number), action)))
  }

  productPropertyAllocationIndexsFetch = (action$: Observable<ActionWork>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.PRODUCT_PROPERTY_ALLOCATION_INDEXS_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.productPropertyAllocationIndexs(), action, AHubActions.productPropertyAllocationsSet)));
  }

  productPropertyAllocationAdd = (action$: Observable<ActionProductPropertyAllocationsAHubVO>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.PRODUCT_PROPERTY_ALLOCATIONS_ADD),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.productPropertyAllocationsAdd(action.productPropertyAllocations), action)))
  }

  productPropertyAllocationDelete = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.PRODUCT_PROPERTY_ALLOCATION_DELETE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.productPropertyAllocationDelete(action.number), action)))
  }

  productPropertyAllocationChainIndexFetch = (action$: Observable<ActionWork>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.PRODUCT_PROPERTY_ALLOCATION_CHAIN_INDEXS_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.productPropertyAllocationChainIndexes(), action, AHubActions.productPropertyAllocationChainIndexsSet)));
  }

  productPropertyAllocationChainsFetch = (action$: Observable<ActionNumberArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.PRODUCT_PROPERTY_ALLOCATION_CHAINS_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.productPropertyAllocationChainsByIds(action.numbers), action, AHubActions.productPropertyAllocationChainsSet)));
  }

  productPropertyAllocationChainAdd = (action$: Observable<ActionProductPropertyAllocationChainAHubVO>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.PRODUCT_PROPERTY_ALLOCATION_CHAIN_ADD),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.productPropertyAllocationChainAdd(action.productPropertyAllocationChain), action)))
  }

  productPropertyAllocationChainDelete = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.PRODUCT_PROPERTY_ALLOCATION_CHAIN_DELETE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.productPropertyAllocationChainDelete(action.number), action)))
  }

  productPropertyAllocationChainValuesCommit = (action$: Observable<ActionProductPropertyAllocationChainValueCommitAHubVOs>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.PRODUCT_PROPERTY_ALLOCATION_CHAIN_VALUES_COMMIT),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.productPropertyAllocationChainValuesCommit(action.productPropertyAllocChainValueCommitAHubVOs), action)))
  }

  productPropertyAllocationChainValidate = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.PRODUCT_PROPERTY_ALLOCATION_CHAIN_VALIDATE),
      mergeMap(action => this.requestSingleDataToAction(this.aHubService.productPropertyAllocationChainValidate(action.number), action, ViewActions.libraryConfigProductsChainingProductAllocChainValidateReportSet)))
  }

  productPropertyAllocationChainRebuild = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.PRODUCT_PROPERTY_ALLOCATION_CHAIN_REBUILD),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.productPropertyAllocationChainRebuild(action.number), action)))
  }

  productPropertyAllocationChainAllValuesApply = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.PRODUCT_PROPERTY_ALLOCATION_CHAIN_ALL_VALUES_APPLY),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.productPropertyAllocationChainAllValuesApply(action.number, false), action)))
  }

  productPropertyAllocationChainAllValuesApplyToAll = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.PRODUCT_PROPERTY_ALLOCATION_CHAIN_ALL_VALUES_APPLY_TO_ALL),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.productPropertyAllocationChainAllValuesApply(action.number, true), action)))
  }

  productPropertyAllocationChainValueChainedValuesFetch = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.PRODUCT_PROPERTY_ALLOCATION_CHAIN_VALUE_CHAINED_VALUES_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.productPropertyAllocationChainValueChainedValuesFetch(action.number), action, ViewActions.libraryConfigResolveProductPropertyAllocationChainValueValuesSet)));
  }

  productPropertyAllocationChainValueApply = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.PRODUCT_PROPERTY_ALLOCATION_CHAIN_VALUE_APPLY),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.productPropertyAllocationChainValueApply(action.number, false), action)))
  }

  productPropertyAllocationChainValueApplyToAll = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.PRODUCT_PROPERTY_ALLOCATION_CHAIN_VALUE_APPLY_TO_ALL),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.productPropertyAllocationChainValueApply(action.number, true), action)))
  }

  extractDefinitionIndexesFetch = (action$: Observable<ActionWork>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXTRACT_DEFINITION_INDEXES_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.extractDefinitionIndexes(), action, AHubActions.extractDefinitionIndexesSet)));
  }

  extractDefinitionAdd = (action$: Observable<ActionExtractDefinitionAHubVO>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXTRACT_DEFINITION_ADD),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.extractDefinitionAdd(action.extractDefinition), action)))
  }

  extractDefinitionCommit = (action$: Observable<ActionExtractDefinitionAHubVO>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXTRACT_DEFINITION_COMMIT),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.extractDefinitionCommit(action.extractDefinition), action)))
  }

  extractDefinitionsByIds = (action$: Observable<ActionNumberArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXTRACT_DEFINITIONS_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.extractDefinitionByIds(action.numbers), action, AHubActions.extractDefinitionsSet)));
  }

  extractDefinitionDelete = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXTRACT_DEFINITION_DELETE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.extractDefinitionDelete(action.number), action)))
  }

  extractIndexesFetch = (action$: Observable<ActionWork>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXTRACT_INDEXES_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.extractIndexes(), action, AHubActions.extractIndexesSet)));
  }

  extractIndexesByExtractDefinitionId = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXTRACT_DEFINITION_EXTRACT_INDEXES_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.extractIndexesByExtractDefinitionId(action.number), action, ViewActions.libraryExtractsDefinitionExtractIndexSet)));
  }

  extractsByIds = (action$: Observable<ActionNumberArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXTRACTS_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.extractByIds(action.numbers), action, AHubActions.extractsSet)));
  }

  extractAdd = (action$: Observable<ActionExtractAHubVO>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXTRACT_ADD),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.extractAdd(action.extract), action)))
  }

  extractCommit = (action$: Observable<ActionExtractAHubVO>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXTRACT_COMMIT),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.extractCommit(action.extract), action)))
  }

  extractDefaultValueRulesCommit = (action$: Observable<ActionExtractDefaultValueRulesAHubVO>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXTRACT_DEFAULT_VALUE_RULES_COMMIT),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.extractDefaultValueRulesCommit(action.defaultValueRules, action.extractId), action)))
  }

  extractDelete = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXTRACT_DELETE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.extractDelete(action.number), action)))
  }

  extractDocumentSettingsCommit = (action$: Observable<ActionExtractDocumentSettings>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXTRACT_DOCUMENT_SETTINGS_COMMIT),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.extractDocumentSettingsCommit(action.extractId, action.extractDocumentSettings), action)))
  }

  extractCommitFilter = (action$: Observable<ActionProductPropertyFilterAHubVONumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXTRACT_COMMIT_PRODUCT_PROPERTY_FILTER),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.extractCommitProductPropertyFilter(action.number, action.productPropertyFilter), action)))
  }

  extractConflicts = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXTRACTS_CONFLICTING_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.extractConflicting(action.number), action, ViewActions.extractConflictsSet)));
  }

  extractStateWIP = (action$: Observable<ActionNumberNumberString>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXTRACT_STATE_WIP),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.extractStateWIP(action.number1, action.number2, action.string), action)))
  }

  extractStateComplete = (action$: Observable<ActionStringNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXTRACT_STATE_COMPLETE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.extractStateComplete(action.number, action.string), action)))
  }

  extractStateArchive = (action$: Observable<ActionStringNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXTRACT_STATE_ARCHIVE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.extractStateArchive(action.number, action.string), action)))
  }

  extractProductAssetZipGenerate = (action$: Observable<ActionNumberNumberString>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXTRACT_PRODUCT_ASSET_ZIP_GENERATE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.extractProductAssetZipGenerate(action.number1, action.number2, action.string), action)))
  }

  extractCompletenessProductPaginationPageFetch = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXTRACT_PRODUCT_PROPERTY_COMPLETENESS_PAGINATION_PAGE_FETCH),
      mergeMap(action => this.requestSingleDataToAction(this.aHubService.extractProductPaginationFetch(action.number, 2000), action, (payload) => AHubActions.extractProductPropertyCompletenessPaginationSet(action.number, payload))));
  }

  extractProductPropertiesCompletenessFetch = (action$: Observable<ActionExtractProductPaginationPage>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXTRACT_PRODUCT_PROPERTY_COMPLETENESS_FETCH),
      mergeMap(action => {

        //Get the category id
        let extractId = action.extractId;

        //Create a new request behaviour subject start with the action to clear the existing data
        let requestSubject: BehaviorSubject<ActionWork> = new BehaviorSubject<ActionWork>(AHubActions.extractProductPropertyCompletenessDelete(extractId));

        //Start requesting the data
        this.requestIncrementalDataToAction(this.aHubService.extractProductPropertyCompleteness(extractId, action.productPaginationPage, this.cancelAction(action$, action.actionId)),
          action, (payload) => AHubActions.extractProductPropertyCompletenessSet(extractId, payload))
          .subscribe((action) => {
            requestSubject.next(action);
          });

        //Return the request subject
        return requestSubject.asObservable();
      }))
  }

  extractHistoryIndexesByExtractIdFetch = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXTRACT_HISTORY_INDEXES_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.extractHistoryIndexByExtractId(action.number), action, AHubActions.extractHistoryIndexSet)))
  }

  extractHistoryProductSourceDownload = (action$: Observable<ActionNumberNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXTRACT_HISTORY_PRODUCT_SOURCE_DOWNLOAD),
      mergeMap(action => this.aHubService.extractHistoryProductSourceDownloadURL(action.number1, action.number2)),
      map(presigned => AppActions.fileDownloadConfigSet([{
        url: presigned.signedUrl,
      }])));
  }


  extractProductsFetch = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXTRACT_PRODUCTS_FETCH),
      mergeMap(action => {


        //Get the current time before any of this requests nonsence
        let now = new Date().getTime();

        //Get the extract id
        let extractId = action.number;

        //Create a new request subject
        let requestSubject: Subject<ActionWork> = new Subject();

        //Make the request to get all the product data for this category
        this.requestIncrementalDataToAction(
          this.aHubService.extractProductsFetch(
            extractId,
            this.cancelAction(action$, action.actionId)
          ).pipe(
            map(prod => { return { number: action.number, products: prod } })),
          action,
          AHubActions.extractProductsUpdateSet).subscribe(action => {

            //Publish the action to the request subject
            requestSubject.next(action);
          },
            () => undefined,
            () => {

              //Now we have finished collating all the data, we want to go and remove any data which existed before we
              //started collating it! This will remove old data which should no longer be in there
              requestSubject.next(AHubActions.extractProductsRemoveBeforeTime(extractId, now));

              //Complete the stream
              requestSubject.complete();
            });

        //Return the observable to fill the store
        return requestSubject.asObservable();
      }));
  }

  extractProductsFetchByIds = (action$: Observable<ActionNumberNumbersArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXTRACT_PRODUCTS_FETCH_BY_IDS),
      mergeMap(action =>
        this.requestSingleDataToAction(this.aHubService.extractProductsFetchByIds(action.number, action.numbers).pipe(
          reduce((a, v) => a.concat(v), []),
          last(),
          map(products => {

            //Generate a list of product id's based on the returned data
            let productIds = products.map(prod => prod.id);

            //Get the list of the product ids which we asked for but no products were not returned for. This suggests they don't belong
            //to this extract so we will colate the missing ids so we can report them
            let missingIds = (productIds.length > 0) ? action.numbers.filter(id => productIds.indexOf(id) == -1) : action.numbers;

            //Return an object which which has the products and the missing id's
            return { number: action.number, products: products, numbers: missingIds };

          })), action, AHubActions.extractProductsUpdateSet)));
  }

  extractProductDocumentCreate = (action$: Observable<ActionExtractDocumentProductFileTypeAHubVO>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXTRACT_PRODUCT_DOCUMENT_CREATE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.extractProductDocumentCreate(action.extractId, action.documentType), action)))
  }

  extractProductsUpload = (action$: Observable<ActionExtractProductBulkUploadAHubVOs>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXTRACT_PRODUCTS_BULK_UPLOAD),
      mergeMap(action => {
        return this.worklogFilesUpload(action, this.aHubService.extractProductsUpload(action.extractId), action.extractProductsUpload);
      }));
  }

  extractProductsCommitObjectsOnly = (action$: Observable<ActionExtractProductAHubVOs>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXTRACT_PRODUCTS_COMMIT_OBJECTS_ONLY),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.extractProductsCommitObjectsOnly(action.extractId, action.extractProducts), action)))
  }

  extractProductsCommitObjectAndAssets = (action$: Observable<ActionExtractProductAndAssetsUploadAHubVOs>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXTRACT_PRODUCTS_COMMIT_OBJECTS_AND_ASSETS),
      mergeMap(action => {

        //Upload request object ready for use
        let uploadRequest: RequestActionStatusUploadVO = {
          uploadData: []
        };

        //Manifest items
        let productAssetManifest: ProductAssetUploadManifestAHubVO[] = [];

        //For each of the upload items we need to do some setup
        action.extractAssetUpload.forEach(extractAssetUpload => {

          //Add all the files into the upload data
          uploadRequest.uploadData.push(...extractAssetUpload.productAssetFiles);

          //Add a record into the manifest for this asset, along with all it's associations
          productAssetManifest.push({
            assetId: extractAssetUpload.assetId,
            assetProductAllocations: extractAssetUpload.productAllocations.map(data => <ProductAssetUploadManifestProductAHubVO>{
              productId: data.productId,
              allocationIds: data.allocationIds
            })
          });
        });


        //We will then add the manifiest to the object store and get the reference back!
        let manifestObjectStoreId = this.objectStoreService.store(JSON.stringify(productAssetManifest));

        //Add the manfiest to the end of the uploads, this should then upload the manifest last!
        uploadRequest.uploadData.push({
          uploadPath: "asset-manifest.json",
          objectStoreId: manifestObjectStoreId
        });

        // Actual Service Calls ----------------------------------------


        //Call the service for executing the product commit workflow
        let productCommitWorkflowStart = this.aHubService.extractProductsCommitObjectsAndAssets(action.extractId, action.extractProducts, action.imageAssetTrimThreshold);

        //Call the file upload with our load of assets to upload
        return this.worklogFilesUpload(action, productCommitWorkflowStart, uploadRequest);
      }));
  }

  extractProductsDelete = (action$: Observable<ActionNumberNumbersArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXTRACT_PRODUCTS_DELETE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.extractProductsDelete(action.number, action.numbers), action)))
  }

  extractProductAssetMatch = (action$: Observable<ActionNumberStringArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXTRACT_PRODUCT_ASSET_MATCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.extractProductAssetMatch(action.number, action.strings), action, (result) => AHubActions.extractProductAssetMatchSet(action.number, result))
      ))
  }

  extractProductSectionPropertyAssetDelete = (action$: Observable<ActionExtractProductSectionPropertyAsset>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXTRACT_PRODUCT_SECTION_PROPERTY_ASSET_DELETE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.extractProductSectionPropertyAssetDelete(action.extractId, action.productId, action.sectionId, action.propertyId), action)))
  }

  extractProductAssetUrlsFetch = (action$: Observable<ActionNumberNumbersArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.EXTRACT_PRODUCT_ASSET_URLS_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.extractProductAssetUrlsByExtractAndProductIds(action.number, action.numbers), action, AHubActions.extractProductsAssetsSet)))
  }

  extractProductAssetsSwap = (action$: Observable<ActionProductAssetViewVOs>) => {
    return action$.pipe(filter(({ type }) => type === ViewActions.LIBRARY_EXTRACT_ASSET_SWAP),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.extractProductAssetsSwap(action.extractId, action.productAssets), action)))
  }


  resourcePackIndexesFetch = (action$: Observable<ActionWork>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.RESOURCE_PACK_INDEXES_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.resourcePackIndexes(), action, AHubActions.resourcePackIndexesSet)));
  }

  resourcePacksByIdFetch = (action$: Observable<ActionNumberArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.RESOURCE_PACKS_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.resourcePacksById(action.numbers), action, AHubActions.resourcePacksSet)));
  }

  resourcePackAdd = (action$: Observable<ActionResourcePackAHubVO>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.RESOURCE_PACK_ADD),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.resourcePackAdd(action.resourcePack), action)))
  }

  resourcePackCommit = (action$: Observable<ActionResourcePackAHubVO>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.RESOURCE_PACK_COMMIT),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.resourcePackCommit(action.resourcePack), action)))
  }

  resourcePackDelete = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.RESOURCE_PACK_DELETE),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.resourcePackDelete(action.number), action)))
  }

  resourcePackFilesUpload = (action$: Observable<ActionNumberStringStringArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.RESOURCE_PACK_FILES_UPLOAD),
      mergeMap(action => {

        //Get the path prefix
        let prefix = action.string;

        //Map the object id's into the upload items
        let uploadDataItems: UploadDataAppVO[] = action.strings.map(objectStoreId => {

          //Get the object from the store
          let object = this.objectStoreService.get(objectStoreId);

          //Start off with just the object name if nothing else we will use this
          let pathURL: string = object.name;

          //Do we have a relative path? If so it suggests we uploaded via a folder selection
          //if so we want to use the path but not include the root folder as part of the path
          if (object.webkitRelativePath && object.webkitRelativePath.length > 0) {

            //Set the URL
            pathURL = object.webkitRelativePath;

            //Get the index of the first slash
            let pathSlashIndex = pathURL.indexOf("/");

            //Remove everything before the slash
            if (pathSlashIndex > -1) pathURL = pathURL.substring(pathSlashIndex + 1, pathURL.length);
          }

          //Add the prefix to the begining of the path
          if (prefix && prefix.trim().length > 0)
            pathURL = prefix + (prefix.charAt(prefix.length - 1) == "/" ? "" : "/") + pathURL;

          //Add the items to the upload list
          return {
            uploadId: objectStoreId,
            objectStoreId: objectStoreId,
            uploadPath: pathURL
          };
        });

        //Create a upload data object which we can use to upload the data
        let uploadData = {
          uploadData: uploadDataItems
        };

        //Call the worklog files upload. With our action the service call to start the request and the data to upload
        return this.worklogFilesUpload(action,
          this.aHubService.resourcePackFilesUpload(action.number),
          uploadData
        );
      }));
  }

  /**
   * Deletes the specified files from the resource packs
   */
  resourcePackFilesDelete = (action$: Observable<ActionNumberStringArray>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.RESOURCE_PACK_FILES_DELETE),
      mergeMap(action =>
        this.requestTicketToActionStatusVO(this.aHubService.resourcePackFilesDelete(action.number, action.strings), action)
      ));
  }

  /**
   * Adds the specified folder (path) to the resource pack
   */
  resourcePackFolderAdd = (action$: Observable<ActionStringNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.RESOURCE_PACK_FOLDER_ADD),
      mergeMap(action =>
        this.requestTicketToActionStatusVO(this.aHubService.resourcePackFolderAdd(action.number, action.string), action)
      ));
  }

  /**
   * Rename the specified folder (path) in the resource pack
   */
  resourcePackFolderRename = (action$: Observable<ActionNumberStringString>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.RESOURCE_PACK_FOLDER_RENAME),
      mergeMap(action =>
        this.requestTicketToActionStatusVO(this.aHubService.resourcePackFolderRename(action.number, action.string1, action.string2), action)
      ));
  }

  /**
   * Generates a zip files for a selection of files in the resource packs
   */
  resourcePackFilesZipDownload = (action$: Observable<ActionStringNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.RESOURCE_PACK_FILES_ZIP_DOWNLOAD),
      mergeMap(action =>
        this.requestTicketToActionStatusVO(this.aHubService.resourcePackFilesZipDownload(action.number, action.string), action)
      ));
  }

  /**
   * Download (commences on worklog complete receipt) file from the resource packs
   */
  resourcePackFileDownload = (action$: Observable<ActionStringNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.RESOURCE_PACK_FILE_DOWNLOAD),
      mergeMap(action =>
        this.requestTicketToActionStatusVO(this.aHubService.resourcePackFileDownload(action.number, action.string), action)
      ));
  }

  /**
   * Get a list of client logs based on the id's passed in.
   */
  clientLogFetch = (action$: Observable<ActionNumberNumber>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.CLIENT_LOG_FETCH),
      mergeMap(action => this.requestSingleDataToAction(this.aHubService.clientLogsByIds(action.number1, action.number2), action, AHubActions.clientLogSet)));
  }

  /**
   * This function will get the client log entries for a client log.
   * 
   * @param action$ 
   */
  clientLogEntriesFetch = (action$: Observable<ActionClientLogAHubVO>) => {
    return action$.pipe(
      filter(({ type }) => type === AHubActions.CLIENT_LOG_ENTRIES_FETCH),
      mergeMap(action => {

        // Call the function to download the client log entries data.
        return this.requestSingleDataToAction(
          this.clientLogEntriesDownload(action.clientLog),
          action,
          AHubActions.clientLogReplace
        );
      }));
  }


  /**
   * This function will download the client log entries for a client log.
   * 
   * @param clientLog       The client log who's entries we want to download.
   */
  private clientLogEntriesDownload(clientLog: ClientLogAHubVO): Observable<ClientLogAHubVO> {

    // Get the client log entries and set in the client log.
    return this.http
      .get(clientLog.clientLogCompletedFile.signedUrl, { responseType: 'json' })
      .pipe(map(data => {

        // Set the client log entries.
        clientLog.entries = data as ClientLogEntryAHubVO[];

        // Make sure the updated property is a date object.
        clientLog.updated = new Date(clientLog.updated);

        /// Make sure we have some client log entries.
        if (!clientLog.entries) {
          clientLog.entries = [];
        }

        // Make sure all of the logged times are date objects.
        clientLog.entries = clientLog.entries.map(clientLogEntry => {

          // Correct each logged time.
          clientLogEntry.loggedTime = new Date(clientLogEntry.loggedTime);

          // Then return the entry.
          return clientLogEntry;
        });

        // Now return the client log.
        return clientLog;
      }));
  }

  serverTimeFetch = (action$: Observable<ActionNumber>) => {
    return action$.pipe(filter(({ type }) => type === AppActions.SERVER_TIME_FETCH),
      mergeMap(action => this.requestSingleDataToAction(this.aHubService.serverTimeFetch(), action, AppActions.serverTimeSet)));
  }

  /**
   * -----------------------------------------------------
   * System
   * -----------------------------------------------------
   */

  maintanceModeOn = (action$: Observable<ActionWork>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.SYSTEM_MAINTANENCE_MODE_ON),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.maintenanceModeOn(), action)));
  }

  maintanceModeOff = (action$: Observable<ActionWork>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.SYSTEM_MAINTANENCE_MODE_OFF),
      mergeMap(action => this.requestTicketToActionStatusVO(this.aHubService.maintenanceModeOff(), action)));
  }

  systemStatusFetch = (action$: Observable<ActionWork>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.SYSTEM_STATUS_FETCH),
      mergeMap(action => this.requestDataToAction(this.aHubService.systemStatusFetch(), action, AHubActions.systemStatusSet)));
  }

  /**
   * -----------------------------------------------------
   * Work
   * -----------------------------------------------------
   */

  worklogSegmentFetch = (action$: Observable<ActionDate>) => {
    return action$.pipe(filter(({ type }) => type === AHubActions.WORKLOG_SEGMENT_FETCH),
      mergeMap(action => { // Not using the generic "requestSingleDataToAction" as we want a special catch.
        return this.aHubService.workLogsAllByUserIdOrClientIdAfterEPOCH(action.date).pipe(
          last(), // everything in
          map((results: any): ActionWork => {
            return AHubActions.worklogSegmentSet(results);
          }),  // turn the results into an action

          catchError((error: any, caught: Observable<ActionWork>): Observable<ActionWork> => {
            return observableConcat(
              of(AHubActions.worklogSegmentFetchError()),
              of(AppActions.sessionrequestActionStatusAppend(this.requestActionStatusCreateError(action, error)))
            );

          }))
      }))
  }

  /**
   * Get the pre-signed URL's for a worklogs file.
   */
  worklogFileDownload = (action$: Observable<ActionStringArray>) => {
    let saveFilename;
    return action$.pipe(filter(({ type }) => type === AHubActions.WORKLOG_FILE_DOWNLOAD),
      tap(action => saveFilename = action.strings[2]),
      mergeMap((action) => this.aHubService.workflowDownloadSignedUrlsGet(action.strings[0], [action.strings[1]])),
      map(presigned => {
        return AppActions.fileDownloadConfigSet([{
          url: presigned[0].signedUrl,
          fileName: saveFilename ? saveFilename : 'worklog.file'
        }]);
      }
      ));
  }

  /**
  * Gets presigned urls for the filenames specified
  * NB: we must batch the requests in order not to breach the url character limit of 2083
  */
  presignedUrls = (prefixes, workflowReference): Observable<PresignedUrlAHubVO[]> => {


    let batchedPrefixes = [];
    let prefixBatch = [];
    let prefixBatchSize = 0;
    prefixes.forEach(prefix => {
      prefixBatchSize += prefix.length;

      if (prefixBatchSize > 2000) {
        batchedPrefixes.push(Utils.clone(prefixBatch))
        prefixBatchSize = 0;
        prefixBatch = [];
      }

      prefixBatch.push(prefix)
    });

    // Flush out the remaining filepaths
    batchedPrefixes.push(Utils.clone(prefixBatch));

    return from(batchedPrefixes).pipe(
      mergeMap(batchOfPrefixes => {

        return this.aHubService.workflowUploadSignedUrlsGet(workflowReference, batchOfPrefixes)
      }),
      reduce((acc: any[], val) => acc.concat(val), []));
  }

  /**
   * Function to download all the files associated with a specified workflow
   */
  workflowDownloadFiles = (action$: Observable<ActionString>): Observable<any> => {
    return action$.pipe(filter(({ type }) => type === AHubActions.WORKFLOW_FILES_DOWNLOAD),
      mergeMap(action =>
        this.aHubService.workflowDownloadFiles(action.string).pipe(
          map(workflowFiles => this.workflowDownloadFilesActionCreate(workflowFiles))
        )
      ));
  }

  /**
   * Creates an action to download files based on workflow download files
   */
  workflowDownloadFilesActionCreate = (toDownload: WorkflowDownloadFileAHubVO[]): ActionFileDownloadConfigVO => {

    //Default the toDownload property to some data
    toDownload = toDownload ? toDownload : [];

    let downloadConfig: FileDownloadConfigVO[] = toDownload.map(workflowDownloadFile => {

      //Download name for the file which we want to download
      let downloadName = undefined;

      //Do we have a path?
      if (workflowDownloadFile.path) {

        //Set the download name to the path as a default
        downloadName = workflowDownloadFile.path;

        //Does the file have directory names in it? If do we will strip it back to the name
        if (workflowDownloadFile.path.indexOf("/") >= 0) downloadName = workflowDownloadFile.path.split("/").pop();
      }

      //Create and return the download config objects
      return <FileDownloadConfigVO>{
        fileName: downloadName,
        url: workflowDownloadFile.url
      }
    });

    //Return an action which will allow the download of these files
    return AppActions.fileDownloadConfigSet(downloadConfig);
  }

  /**
   * Called to upload a series of files to a workflow
   */
  worklogFilesUpload = (action: ActionWork, requestTicketObvs$: Observable<RequestTicketAHubVO>, upload: RequestActionStatusUploadVO) => {

    //Here is the list of id's we are using for a file, we cannot have multiple of the same id so we will sanities it.
    //Worst this will do is unlink the upload from the view but thats the callers fault we want to prevent the errors

    //Loop through all our items making sure they have an id and a priority. We will prioritize
    //into the negitive numbers so that these items will always appear at the end of lists
    upload.uploadData.forEach((item, index) => {

      //Whilst we don't have an id, or its already on the list we will generate a new one
      while (item.uploadId === undefined) {
        item.uploadId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
      }

      //Set the priority if we don't have one
      if (item.priority == undefined) item.priority = 0 - index;
    });

    //Take the request ticket stream and appen our upload stream to it
    return requestTicketObvs$.pipe(
      mergeMap(requestTicket => {

        //Create a status action event. We will use this to link the action to the request ticket. This will be the first thing to fire
        let newStatusAction = AppActions.sessionrequestActionStatusAppend(this.requestActionStatusUploadCreate(requestTicket, action, upload));

        //Create a new progress stream. We will use this stream to report the progress of a file
        let progressStream$ = new Subject();

        //Generate a list of the prefixes for the upload, we will loop through getting the requested path for each
        let prefixes = upload.uploadData.map(uploadData => uploadData.uploadPath);

        //This will be our primary stream to carry out the upload. This will take the requested data and upload the data one  at a time.
        //First things first get presigned URL's for the prefiexs which were part of the parameters
        let uploadStream$: Observable<ActionString> = this.presignedUrls(prefixes, requestTicket.workflowReference)
          .pipe(
            mergeMap(signedUploadUrls => {

              //We will pair up our presigned URL's with there data object request to make the next stage easier
              //if we can't find it forwhatever reason the object will not be uploaded
              let uploadObjs = signedUploadUrls.map(signedURL => {

                //Find the data object based on the resource path requested by the user
                let dataObject = upload.uploadData.find(uploadData => signedURL.request == uploadData.uploadPath);

                //No data object, can't upload sorry!
                if (!dataObject) return undefined;

                //Create a simple non specific object to go with the URL
                return {
                  id: dataObject.uploadId,
                  objectStoreId: dataObject.objectStoreId,
                  presignedURL: signedURL,
                  priority: dataObject.priority
                }
              })
                .filter(d => d != undefined)
                .sort((a, b) => a.priority == b.priority ? 0 : a.priority > b.priority ? -1 : 1);

              //Using concatMap we will call our objects one at a time to upload to the server.
              //we must include the reduce on the end so we will not trigger our complete untill all items are complete
              return from(uploadObjs)
                .pipe(
                  concatMap(uploadObject => {

                    //Get the data object we want to upload. We will get the item our of the store, we are also flagging the
                    //item for removal from the store at this point.
                    let dataObject = this.objectStoreService.get(uploadObject.objectStoreId);

                    //Last reported percentage value
                    let lastPercentage = 0;

                    //Define a progress callback
                    let progressCallback = (percentage) => {

                      //Add the progress action to the progress stream. Only is the percentage is different to the last one
                      if (lastPercentage != percentage) progressStream$.next(AppActions.requestActionStatusUploadProgressUpdate(requestTicket.workflowReference, uploadObject.id, percentage));

                      //Set the last percentage value
                      lastPercentage = percentage;
                    }

                    //Call the upload service with all the data we need, including a progress listener
                    return this.uploadService.upload(uploadObject.presignedURL.signedUrl, dataObject, progressCallback);
                  }),
                  last(),
                );
            }),
            //All the uploads have finished, we want to signal the workflow that we have finished the upload
            mergeMap(() => this.aHubService.workflowUploadComplete(requestTicket.workflowReference)),
            map(() => AppActions.requestActionStatusUploadCompleteSignalUpdate(requestTicket.workflowReference))
          );

        //OK so finally lets take our stream and merge them together, this will dispatch our variety of actions
        //out of our epic to the store sending all progress, action statuses and others as required
        return progressStream$.pipe(merge(uploadStream$), merge(of(newStatusAction)))
      }),
      catchError(err => {

        //Get the action with the matching action id
        let requestActionStatus = StoreAccess.dataGet(requestActionStatuses).find(status => status.actionId == action.actionId);

        //Return the observable message based on a previous one, or new one if we don't have one
        if (requestActionStatus) {

          //Observable for the the cancel request, if we do it
          let workflowUploadCancel: Observable<any> = undefined;

          //Action we want to submit to the store
          let actionForStore = AppActions.sessionrequestActionStatusAppend(this.requestActionStatusCreateErrorFromRequestAction(action, err, requestActionStatus));

          //If we have a workflow id for our request action status, then we will cancel the request as we will be unable to finish the upload
          //and as a result not complete the task
          if (requestActionStatus.workflowReference != undefined) {
            workflowUploadCancel = this.aHubService.workflowUploadCancel(requestActionStatus.workflowReference);
          }

          //If we are canceling the workflow we will return that with a map to our new event. If not just the new error event
          return (workflowUploadCancel) ? workflowUploadCancel.pipe(map(result => actionForStore)) : of(actionForStore);

        } else {

          //Return an observable of a generic error
          return of(AppActions.sessionrequestActionStatusAppend(this.requestActionStatusCreateError(action, err)));
        }
      }));
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AmpersandToAndPipe } from './ampersand-to-and.pipe';
import { PipeToOrPipe } from './pipe-to-or.pipe';
import { ExclamationToNotPipe } from './excalamtion-to-not.pipe';
import { NullToEmptyPipe } from './null-to-empty.pipe';
import { AsteriskToAllPipe } from './asterisk-to-all.pipe';
import { HideDisabledPrefixPipe } from './hide-disabled-prefix.pipe';
import { DateTimeOrYearPipe } from './date-time-or-year.pipe';
import { IntegerToPostfixMagnitudePipe } from './integer-to-postfix-magnitude.pipe';
import { PermissionToSliderNumberValuePipe } from './permission-to-slider-number-value.pipe';
import { TitleCasePipe } from './title-case.pipe';
import { CountryCodeToLanguagePipe } from './country-code-to-language.pipe';
import { TrustedContentPipe } from './trusted-content.pipe';
import { PropertySort } from './property-sort.pipe';
import { TextToOperatorPipe } from './text-to-operator.pipe';
import { ZeroToNonePipe } from './zero-to-none.pipe';
import { FontToLabelPipe } from './font-to-label.pipe';
import { BooleanToStringPipe } from './boolean-to-string.pipe';
import { GenderToStringPipe } from './gender-to-string.pipe';
import { NumberLessThanZeroToTextNewPipe } from './number-less-than-zero-to-text-new.pipe'
import { BytesToMegaBytesPipe } from './bytes-to-mega-bytes.pipe'
import { TidyNodeIdPipe } from './tidy-node-id.pipe';
import { IntegerFixedStringZeroPadding } from './integer-fixed-string-zero-padding.pipe';
import { ListLabelFunctionPipe } from './list-label-function.pipe';
import { ExportVersionStateToTextPipe } from './export-version-state-to-text.pipe';
import { FriendlySpacePipe } from './friendly-space.pipe';
import { TruncateLongLabelPipe } from './truncate-long-label.pipe';
import { DocumentProductFileTypeToLabelPipe } from './document-product-file-type-to-label.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    AmpersandToAndPipe,
    PipeToOrPipe,
    ExclamationToNotPipe,
    NullToEmptyPipe,
    AsteriskToAllPipe,
    DateTimeOrYearPipe,
    IntegerToPostfixMagnitudePipe,
    PermissionToSliderNumberValuePipe,
    TitleCasePipe,
    TrustedContentPipe,
    HideDisabledPrefixPipe,
    PropertySort,
    CountryCodeToLanguagePipe,
    TextToOperatorPipe,
    ZeroToNonePipe,
    FontToLabelPipe,
    BooleanToStringPipe,
    GenderToStringPipe,
    NumberLessThanZeroToTextNewPipe,
    BytesToMegaBytesPipe,
    TidyNodeIdPipe,
    IntegerFixedStringZeroPadding,
    ListLabelFunctionPipe,
    ExportVersionStateToTextPipe,
    FriendlySpacePipe,
    TruncateLongLabelPipe,
    DocumentProductFileTypeToLabelPipe
  ],
  exports: [
    AmpersandToAndPipe,
    PipeToOrPipe,
    ExclamationToNotPipe,
    NullToEmptyPipe,
    AsteriskToAllPipe,
    DateTimeOrYearPipe,
    IntegerToPostfixMagnitudePipe,
    PermissionToSliderNumberValuePipe,
    TitleCasePipe,
    TrustedContentPipe,
    HideDisabledPrefixPipe,
    PropertySort,
    CountryCodeToLanguagePipe,
    TextToOperatorPipe,
    ZeroToNonePipe,
    FontToLabelPipe,
    BooleanToStringPipe,
    GenderToStringPipe,
    NumberLessThanZeroToTextNewPipe,
    BytesToMegaBytesPipe,
    TidyNodeIdPipe,
    IntegerFixedStringZeroPadding,
    ListLabelFunctionPipe,
    ExportVersionStateToTextPipe,
    FriendlySpacePipe,
    TruncateLongLabelPipe,
    DocumentProductFileTypeToLabelPipe
  ]
})
export class PipesModule { }

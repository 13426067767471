/**
 * --------------------------------------------------
 * State Imports
 * --------------------------------------------------
 */

import { AViewState } from 'app/store/reducers/aview.reducer';
import { List } from '../list.vo';
import { IdItemVO } from '../id-item.vo';
import { PresignedUrlAHubVO } from 'app/valueObjects/ahub/presigned-url.ahub.vo';
import { AViewVO } from 'app/modules/routes/aview/valueObjects/aview.aview.vo';
import { KeyAViewVO } from 'app/modules/routes/aview/valueObjects/key.aview.vo';
import { MapStorage } from '../map-storage.vo';
import { ComponentsAViewVO } from 'app/modules/routes/aview/valueObjects/components.aview.vo';
import { ProductViewConfigAViewVO } from 'app/modules/routes/aview/valueObjects/product-view-config.aview.vo';
import { MapStorageUtil } from '../map-storage.util';
import { AviewProductFullData } from 'app/modules/routes/aview/aview-product/aview-product.component';
import { ExportOutputAssetAHubVO } from 'app/valueObjects/ahub/accounts/export-output-asset.ahub.vo';


/**
 * --------------------------------------------------
 * Value Object Imports
 * --------------------------------------------------
 */


/**
 * --------------------------------------------------
 * Core Selectors
 * --------------------------------------------------
 */

/**
 * Get the aview section from the current view state.
 */
const aViewState = (state): AViewState => state.aView;

/**
 * --------------------------------------------------
 * Selectors
 * --------------------------------------------------
 */

/**
 * Get the selected publication edition path list
 */
export const publicationEditionPathUrlsList = (state): List<IdItemVO<PresignedUrlAHubVO>> => aViewState(state).publicationPathUrls;

/**
 * Get the aviews
 */
export const aViews = (state): AViewVO[] => aViewState(state).aViews;

/**
 * Get the aviews
 */
export const aViewKeys = (state): KeyAViewVO[] => aViewState(state).aViews.map(aView => {
  const key: KeyAViewVO = {
    publicationId: aView.publicationId,
    edition: aView.edition
  }
  return key;
});

/**
 * Get component state map
 */
export const aViewComponentMap = (state): MapStorage<ComponentsAViewVO> => aViewState(state).componentMap;

export const aViewComponentMapKeys = (state) => {
  return (aViewState(state).componentMap) ? aViewState(state).componentMap.keys : undefined;
};

/**
 *
 *
 * @param componentId
 */
export const aViewComponent = (componentId: string): (state) => ComponentsAViewVO => {
  return (state): ComponentsAViewVO => MapStorageUtil.mapStorageGet(aViewComponentMap(state), componentId);
}

/**
 *
 *
 * @param componentId
 */
export const aViewComponentAssets = (componentId: string): (state) => ExportOutputAssetAHubVO[] => {
  return (state): ExportOutputAssetAHubVO[] => {
    const component = MapStorageUtil.mapStorageGet(aViewComponentMap(state), componentId);
    return (component) ? component.assets : undefined;
  }
}

/**
 *
 *
 * @param componentId
 */
export const aViewComponentSelectedProductId = (componentId: string): (state) => number => {
  return (state): number => {
    const component = MapStorageUtil.mapStorageGet(aViewComponentMap(state), componentId);
    return (component) ? component.selectedProductId : -1;
  }
}

/**
 *
 *
 * @param componentId
 */
export const aViewComponentSelectedCategoryId = (componentId: string): (state) => number => {
  return (state): number => {
    const component = MapStorageUtil.mapStorageGet(aViewComponentMap(state), componentId);
    return (component) ? component.selectedCategoryId : -1;
  }
}

/**
 *
 *
 * @param componentId
 */
export const aViewComponentSelectedProductViewData = (componentId: string): (state) => AviewProductFullData => {
  return (state): AviewProductFullData => {
    const component = MapStorageUtil.mapStorageGet(aViewComponentMap(state), componentId);
    return (component) ? component.selectedProductViewData : undefined;
  }
}


/**
 *
 *
 * @param componentId
 */
export const aViewComponentFilteredProductIds = (componentId: string): (state) => number[] => {
  return (state): number[] => {
    const component = MapStorageUtil.mapStorageGet(aViewComponentMap(state), componentId);
    return (component) ? component.filteredProductIds : undefined;
  }
}


/**
 * Get the current product count
 *
 * @param componentId
 */
export const aViewComponentProductCount = (componentId: string): (state) => number => {
  return (state): number => {
    const component = MapStorageUtil.mapStorageGet(aViewComponentMap(state), componentId);
    return (component) ? component.productCount : 0;
  }
}


/**
 * This is a function which will generate a selector meaning we get exactly the item from the list we want
 *
 * @param publicationId
 * @param editionNumber
 */
export const aViewGet = (publicationId: number, editionNumber: number): (state) => AViewVO => {
  return (state): AViewVO => aViewState(state).aViews.find(aView => aView.publicationId === publicationId && aView.edition === editionNumber);
}

/**
 * Selects library view config by edition id
 */
export const aViewProductViewConfig = (state): MapStorage<ProductViewConfigAViewVO> => aViewState(state).productViewConfigByEdition;

/**
 * Selects library view config map storage by publication id
 */
export const aViewProductViewConfigMapStorage = (state): MapStorage<MapStorage<ProductViewConfigAViewVO>> => aViewState(state).productViewConfigMapStorageByPublicationId;


export const aViewDisplaySetting = (state): string => aViewState(state).displaySetting;


export const globalSidebarStateActive = (state): boolean => aViewState(state).globalSidebarStateActive;

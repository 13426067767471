/**
 * --------------------------------------------------
 * State Imports
 * --------------------------------------------------
 */

import { viewState } from 'selector/view/view.selector';
import { ViewAccountManagementState } from 'reducers/view/view-accounts-management.reducer';

import {
  aHubStateTemporaryClientList,
  aHubStateTemporaryClientConfigurationList,
  aHubStateTemporaryClientLibrariesList,
  aHubStateTemporaryUserExtendedList,
  aHubStateTemporaryUserIndexList,
} from 'selector/ahub/ahub-temporary.selector';


import { ListUtil } from 'store/list.util';
import { SelectorUtil } from 'store/selector.util';

/**
 * --------------------------------------------------
 * Value Object Imports
 * --------------------------------------------------
 */

import { ClientAHubVO } from 'valueObjects/ahub/accounts/client.ahub.vo';
import { ClientConfigurationAHubVO } from 'valueObjects/ahub/accounts/client-configuration.ahub.vo';
import { ClientLibraryAHubVO } from 'valueObjects/ahub/accounts/client-library.ahub.vo';
import { UserExtendedAHubVO } from 'valueObjects/ahub/accounts/user-extended.ahub.vo';
import { DistributionGroupIndexAHubVO } from 'app/valueObjects/ahub/accounts/distribution-group-index.ahub.vo';
import { UserIndexAHubVO } from 'app/valueObjects/ahub/accounts/user-index.ahub.vo';


/**
 * Root selector to get the clients view state from the store state
 */
const viewAccountManagementState = (state): ViewAccountManagementState => viewState(state).accountManagement;

/**
 * --------------------------------------------------
 * Selectors
 * --------------------------------------------------


/**
 * Currently selected client id
 */
export const viewSelectedClientId = (state): number => viewAccountManagementState(state).selectedClientId;


/**
 * A selector which combines an aHub selector and the currently selected item too
 */
export const viewSelectedClient = (state): ClientAHubVO => ListUtil.listDataItemGet(aHubStateTemporaryClientList(state), viewSelectedClientId(state));


/**
 * A selector which combines an aHub selector and the currently selected item too
 */
export const viewSelectedClientConfiguration = (state): ClientConfigurationAHubVO => ListUtil.listDataItemGet(aHubStateTemporaryClientConfigurationList(state), viewSelectedClientId(state));

/**
 * A selector which combines an aHub selector and the currently selected item too
 */
export const viewSelectedClientLibrary = (state): ClientLibraryAHubVO => ListUtil.listDataItemGet(aHubStateTemporaryClientLibrariesList(state), viewSelectedClientId(state));


/**
 * A selector to get the currently selected user id.
 */
export const viewSelectedUserId = (state): number => viewAccountManagementState(state).selectedUserId;

/**
 * A selector to get the currently selected user id.
 */
export const viewSelectedUserIndex = (state): UserIndexAHubVO => ListUtil.listDataItemGet(aHubStateTemporaryUserIndexList(state), viewSelectedUserId(state));

/**
 * A selector which combines an aHub selector and the currently selected user id.
 */
export const viewSelectedUserExtended = (state): UserExtendedAHubVO => ListUtil.listDataItemGet(aHubStateTemporaryUserExtendedList(state), viewSelectedUserId(state));

/**
 * Selector to get the distribution groups from the temporary ahub state
 */
export const viewSelectedUserDistributionGroupIndexes = (state): DistributionGroupIndexAHubVO[] =>
  viewAccountManagementState(state).selectedUserDistributionGroupIndexes;

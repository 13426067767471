import { Action, Reducer } from 'redux';
import { createReducer } from 'reducers/reducer-util';

import { tassign } from 'modules/common/type-assign.util';

/**
 * Action Types
 */
import {
  ActionNumber,
  ActionBoolean
} from 'actions/types/common.action-types'

/**
 * Actions
 */
import { ViewActions } from 'actions/view.actions';

/**
 * ----------------------------------
 * App State interface
 * ----------------------------------
 */
export interface ViewDistributionGroupState {
  selectedDistributionGroupId: number,
  selectedDistributionId: number,
  selectedExportTypeId: number,
  selectedUserId: number
};

/**
 * ----------------------------------
 * Initial State
 * ----------------------------------
 */
export const ViewDistributionGroupInitialState: ViewDistributionGroupState = {
  selectedDistributionGroupId: -1,
  selectedDistributionId: -1,
  selectedExportTypeId: -1,
  selectedUserId: -1
};


/**
 * ----------------------------------
 * View State Reducer
 * ----------------------------------
 */

/**
 * Set the selected distribution group id
 */
export const distributionGroupSelectedIdSet = (state: ViewDistributionGroupState, action: ActionNumber) => {

  //Set the selectedDistributionGroupId to the number passed in
  return tassign(state, { selectedDistributionGroupId: action.number });
}

/**
 * Set the selected user id
 */
export const distributionGroupUserSelectedIdSet = (state: ViewDistributionGroupState, action: ActionNumber) => {

  //Set the selectedUserId to the number passed in
  return tassign(state, { selectedUserId: action.number });
}

/**
 * Set the selected distribution id
 */
export const distributionSelectedIdSet = (state: ViewDistributionGroupState, action: ActionNumber) => {

  //Set the selectedDistributionId to the number passed in
  return tassign(state, { selectedDistributionId: action.number });
}

/**
 * Set the selected export type id to the one passed in.
 */
export const distributionGroupExportTypeSelectedIdSet = (state: ViewDistributionGroupState, action: ActionNumber) => {

  // Set the selected export type id to the one passed in.
  return tassign(state, { selectedExportTypeId: action.number });
}

/**
 * ----------------------------------
 * Reducers Mapping
 * ----------------------------------
 */

/**
 * Reducers handlers object ... match actions to the handler functions
 */
let reducerHandlers = {};

/**
 * Map the actions to the reducer functions this will allow us to
 */
reducerHandlers[ViewActions.DISTRIBUTION_GROUP_SELECTED_ID_SET] = distributionGroupSelectedIdSet;
reducerHandlers[ViewActions.DISTRIBUTION_GROUP_USER_SELECTED_ID_SET] = distributionGroupUserSelectedIdSet;
reducerHandlers[ViewActions.DISTRIBUTION_GROUP_DISTRIBUTION_SELECTED_ID_SET] = distributionSelectedIdSet;
reducerHandlers[ViewActions.DISTRIBUTION_GROUP_EXPORT_TYPE_SELECTED_ID] = distributionGroupExportTypeSelectedIdSet;

/**
 * Create a reducers based on the reducers handlers
 */
export const ViewDistributionGroupReducer: Reducer<ViewDistributionGroupState> = createReducer(ViewDistributionGroupInitialState, reducerHandlers);

/**
 * Check if this reducers can handel the function specified
 */
export const ViewDistributionGroupReducerHasHandler = (actionType: string): boolean => reducerHandlers.hasOwnProperty(actionType);

/**
 * Holds the state of the common tree component.
 * By having a seperate component state it can be re-used across views.
 * Whilst keeping the view state values seperate prevent cross view contamination.
 * 
 * Currently the store only supports single tree storage, this could be expanded with an index to allow for multiple tree component use.
 */

import { Action, Reducer } from 'redux';
import { createReducer } from 'reducers/reducer-util';
import { tassign } from 'modules/common/type-assign.util';


/**
 * Action Types
 */
import {
    ActionNumber
} from 'actions/types/common.action-types'

import {
    ActionComponentTreeDataVOs
} from 'actions/types/component.action-types'

/**
 * Actions
 */
import { ComponentActions } from 'actions/component.actions';

/**
 * Value objects
 */
import { TreeNodeVO } from 'modules/common/components/tree/tree.component';


/**
 * ----------------------------------
 * View State interface
 * ----------------------------------
 */
export interface ComponentTreeState {
    treeData: TreeNodeVO[];
};

/**
 * ----------------------------------
 * Initial State
 * ----------------------------------
 */
export const ComponentTreeInitialState: ComponentTreeState = {
    treeData: []
};

/**
 * ----------------------------------
 * View State Reducer
 * ----------------------------------
 */


/**
 * Set the tree data
 */
export const treeDataSet = (state: ComponentTreeState, action: ActionComponentTreeDataVOs) => {
    return tassign(state, { treeData: action.treeData });
}

/**
 * Update the tree data
 */
export const treeDataUpdate = (state: ComponentTreeState, action: ActionComponentTreeDataVOs) => {

    //Create an array of the new ids
    let newIds = action.treeData.map(treeNodeVO => treeNodeVO.id);

    //Get a copy of the current array with the copy
    let updated = state.treeData.filter(existing => (newIds.indexOf(existing.id) == -1));

    //Update the product classes
    updated = updated.concat(action.treeData);

    return tassign(state, { treeData: updated });
}

/**
 * ----------------------------------
 * Reducers Mapping
 * ----------------------------------
 */

/**
 * Reducers handlers object ... match actions to the handler functions
 */
let reducerHandlers = {};

/**
 * Map the actions to the reducer functions this will allow us to call the reducer
 */
reducerHandlers[ComponentActions.COMPONENT_TREE_DATA_SET] = treeDataSet;
reducerHandlers[ComponentActions.COMPONENT_TREE_DATA_UPDATE] = treeDataUpdate;


/**
 * Create a reducers based on the reducers handlers
 */
export const ComponentTreeStateReducer: Reducer<ComponentTreeState> = createReducer(ComponentTreeInitialState, reducerHandlers);

/**
 * Check if this reducer can handle the function specified
 */
export const ComponentTreeStateHasHandler = (actionType: string): boolean => reducerHandlers.hasOwnProperty(actionType);

import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseChartDirective } from "ng2-charts";

/**
 * Store
 */
import { StoreAccess } from 'store/store-access';
import { AHubActions } from 'actions/ahub.actions';
import { ClientQuotasStream } from 'stream/client-quotas.stream';

import { ClientQuotaAHubVO } from 'valueObjects/ahub/accounts/client-quota.ahub.vo';

import { Hark, componentDestroyStream } from 'modules/common/hark.decorator';
import { Utils } from 'modules/common/utils';

// Light weight date utils.
import * as moment from 'moment';

@Component({
  selector: 'app-client-quota-history-dialog',
  templateUrl: './client-quota-history-dialog.component.html',
  styleUrls: ['./client-quota-history-dialog.component.css']
})
@Hark()
export class ClientQuotaHistoryDialogComponent implements OnInit, OnDestroy {

  // ONLY HERE TO FIX ng2-charts issue with labels not being able to change
  @ViewChild(BaseChartDirective, { static: false }) chart: BaseChartDirective;

  NUMBER_OF_DATA_POINTS_TO_DISPLAY: number = 24;

  /**** REQUIRED FOR USE IN A DIALOGUE  ****/
  /**
   * The VO that is passed in / out.
   */
  public dialogVO: ClientQuotaAHubVO = null;

  /**
   * Observe the client quotas history so we can display its data.
   */
  clientQuotasHistoryByClientId$: Observable<ClientQuotaAHubVO[]>;
  clientQuotaHistory: ClientQuotaAHubVO[] = [];
  reducedClientQuotaHistory: ClientQuotaAHubVO[] = [];

  combinedDataSet = new Array();
  usageDataColours = [];
  disabledDataColours = [];
  limitColour = 'rgba(207,216,220, 0.9)';
  usageColourUnlimited = 'rgba(76,175,80,0.7)';
  disabledColourUnlimited = 'rgba(165,214,167,0.7)';
  usageColourBelowLimit = "rgba(48,63,159,0.7)";
  disabledColourBelowLimit = "rgba(159,168,218,0.7)";
  usageColourAboveLimit = "rgba(239,83,80,0.7)";
  disabledColourAboveLimit = "rgba(255,205,210,0.7)";
  title = '';
  types = ['bar', 'line'];

  chartColors: any[] = [
    {
      backgroundColor: this.usageDataColours,
      borderColor: this.usageDataColours,
      hoverBackgroundColor: this.usageDataColours,
      hoverBorderColor: this.usageDataColours
    },
    {
      backgroundColor: this.disabledDataColours,
      borderColor: this.disabledDataColours,
      hoverBackgroundColor: this.disabledDataColours,
      hoverBorderColor: this.disabledDataColours
    },
    {
      backgroundColor: this.limitColour,
      borderColor: this.limitColour,
    },
  ];

  chartType = this.types[0];
  stepSizes: Array<string> = ['hours', 'days', 'weeks', 'months'];
  stepSize: string = this.stepSizes[3];
  dateOfInterest: Date = new Date();
  options = {};
  labels = [];

  displayNames = {
    DATA_SETS: 'Data Sets',
    DATA_SIZE_BYTES: 'Data',
    DG_USERS: 'Distribution Group Users',
    DISTRIBUTIONS: 'Distributions',
    DIST_GROUPS: 'Distribution Groups',
    EXPORTS: 'Exports',
    WG_USERS: 'Work Group Users',
    WORK_GROUPS: 'Work Groups',
    EXPORTS_SIZE_BYTES: 'Exports Total Size',
    LIBRARY_ASSET_COUNT: 'Library Assets',
    LIBRARY_ASSET_BYTES: 'Library Assets Size',
    LIBRARY_PRODUCTS: 'Library Products'
  };

  showAllHistory = true;

  today = new Date().toJSON().split('T')[0];

  readonly DAY_DATE_FORMAT = 'Do MMM YY';
  readonly HOUR_DATE_FORMAT = 'ha';
  tickDateFormat = this.DAY_DATE_FORMAT;

  isDataAvailable: boolean = false;

  constructor() {
  }

  /**
   * Empty On Init to ensure @Hark decorator works for an AOT build
   */
  ngOnInit() {

    StoreAccess.dispatch(AHubActions.clientQuotasHistoryByClientIdFetch(this.dialogVO.clientId));
    this.clientQuotasHistoryByClientId$ = new ClientQuotasStream().getClientQuotasHistoryByClientId(this.dialogVO.clientId);
    this.clientQuotasHistoryByClientId$.pipe(
      takeUntil(componentDestroyStream(this)))
      .subscribe((clientQuotaHistory) => {

        if (clientQuotaHistory.length > 0) {
          this.clientQuotaHistory = clientQuotaHistory.filter((item) => {
            return item['quotaType'] === this.dialogVO.quotaType;

          }).sort((a, b) => {
            if (a.id < b.id)
              return -1;
            if (a.id > b.id)
              return 1;
            return 0;
          });
          this.title = this.displayNames[this.dialogVO.quotaType.toString()]

          this.setChartOptions();

          let stuffedClientQuotaHistory = this.stuffDataSetWithExtraPoints(this.clientQuotaHistory, 1);
          this.reducedClientQuotaHistory = this.reduceDataIntoEasilyViewedEvenlySpacedDataPoints(stuffedClientQuotaHistory, this.NUMBER_OF_DATA_POINTS_TO_DISPLAY);
          this.isDataAvailable = true;
          this.updateChart();
        }
      });
  }

  /**
   * Empty On destroy to ensure @Hark decorator works for an AOT build
   */
  ngOnDestroy() { }

  updateChartToShowEverything() {
    this.buildGraphDataSet(this.reducedClientQuotaHistory);
  }

  updateChartForStepSize($event) {
    this.stepSize = $event.value;
    if (this.stepSize == 'hours') {
      this.tickDateFormat = this.HOUR_DATE_FORMAT;
    } else {
      this.tickDateFormat = this.DAY_DATE_FORMAT;
    }
    this.updateChart();
  }

  updateChartForDateTime($event) {
    this.dateOfInterest = $event;
    this.updateChart();
  }

  updateChart() {
    if (this.showAllHistory) {
      this.updateChartToShowEverything();

    } else {
      let dateTimeDataPoints = this.buildDateTimeDataPoints();
      let clientQuotaHistoryRange = this.buildClientQuotaHistoryRange(dateTimeDataPoints);

      this.buildGraphDataSet(clientQuotaHistoryRange);
    }
  }

  private stuffDataSetWithExtraPoints(clientQuotaHistory: ClientQuotaAHubVO[], stepSizeInDays: number) {

    //Default this value to empt if we have nothing
    clientQuotaHistory = clientQuotaHistory ? clientQuotaHistory : [];

    const sampleDate: Date = clientQuotaHistory.length > 0 ? new Date(clientQuotaHistory[0].lastUsageUpdate) : new Date();
    sampleDate.setDate(sampleDate.getDate() + stepSizeInDays);

    const stuffedClientQuotaHistory: ClientQuotaAHubVO[] = [];

    if (clientQuotaHistory.length > 0) {
      stuffedClientQuotaHistory.push(clientQuotaHistory[0]);
    }

    clientQuotaHistory.forEach(clientQuota => {

      const currentRealDataTime = (clientQuota.lastUsageUpdate ? new Date(clientQuota.lastUsageUpdate) : new Date()).getTime()
      while (currentRealDataTime > sampleDate.getTime()) {
        let stuffableClientQuota: ClientQuotaAHubVO = JSON.parse(JSON.stringify(clientQuota));
        stuffableClientQuota.lastUsageUpdate = new Date(sampleDate);
        stuffedClientQuotaHistory.push(stuffableClientQuota);
        sampleDate.setDate(sampleDate.getDate() + stepSizeInDays);
      }
    });

    return stuffedClientQuotaHistory;
  }

  private reduceDataIntoEasilyViewedEvenlySpacedDataPoints(clientQuotaHistory: ClientQuotaAHubVO[], numberOfDataPoints: number) {

    let stepSize = Math.ceil(clientQuotaHistory.length / numberOfDataPoints);
    let reducedClientQuotaHistory: ClientQuotaAHubVO[] = [];
    for (let i = 0; i < clientQuotaHistory.length; i = i + stepSize) {
      reducedClientQuotaHistory.push(clientQuotaHistory[i]);
    }
    return reducedClientQuotaHistory;
  }

  private buildGraphDataSet(clientQuotaHistory: ClientQuotaAHubVO[]) {
    this.combinedDataSet = new Array();
    this.labels = new Array();
    let usageDataSet = {
      label: 'Used',
      stack: 0,
      data: []
    }
    let disabledDataSet = {
      label: 'Disabled',
      stack: 0,
      data: []
    }
    let limitDataSet = {
      label: 'Limit',
      type: 'line',
      fill: true,
      spanGaps: false,
      pointBackgroundColor: this.limitColour,
      borderWidth: '0px',
      pointRadius: 0,
      pointHitRadius: 5,
      // steppedLine: true,
      data: []
    }

    // clientQuotaHistory = clientQuotaHistory.slice(-30);
    clientQuotaHistory.forEach((dataPoint, index, array) => {
      if (dataPoint) {
        let used = dataPoint.usage - dataPoint.discounted;
        this.labels.push(dataPoint.lastUsageUpdate);
        usageDataSet.data.push(used);
        disabledDataSet.data.push(dataPoint.discounted);

        limitDataSet.data.push(dataPoint.limit === -1 ? null : dataPoint.limit);
        if (dataPoint.limit === -1) {

          this.usageDataColours.push(this.usageColourUnlimited);
          this.disabledDataColours.push(this.disabledColourUnlimited);
        } else {

          this.usageDataColours.push(used <= dataPoint.limit ? this.usageColourBelowLimit : this.usageColourAboveLimit);
          this.disabledDataColours.push(dataPoint.usage <= dataPoint.limit ? this.disabledColourBelowLimit : this.disabledColourAboveLimit);
        }

      }
    });
    // Add data point for current state (in case history has not yet been calculated)
    this.labels.push('now');
    usageDataSet.data.push(this.dialogVO.usage - this.dialogVO.discounted);
    disabledDataSet.data.push(this.dialogVO.discounted);
    limitDataSet.data.push(this.dialogVO.limit === -1 ? 0 : this.dialogVO.limit);
    if (this.dialogVO.limit === -1) {

      this.usageDataColours.push(this.usageColourUnlimited);
      this.disabledDataColours.push(this.disabledColourUnlimited);
    } else {

      this.usageDataColours.push((this.dialogVO.usage - this.dialogVO.discounted) <= this.dialogVO.limit ? this.usageColourBelowLimit : this.usageColourAboveLimit);
      this.disabledDataColours.push(this.dialogVO.usage <= this.dialogVO.limit ? this.disabledColourBelowLimit : this.disabledColourAboveLimit);
    }
    this.combinedDataSet.push(usageDataSet);
    this.combinedDataSet.push(disabledDataSet);
    this.combinedDataSet.push(limitDataSet);
    if (this.chart && this.chart.chart && this.chart.chart.config) {
      this.chart.chart.config.data.labels = this.labels;
      this.chart.chart.update();
    }
  }

  buildDateTimeDataPoints(): Array<moment.Moment> {
    let dateTimePoints: Array<moment.Moment> = [];
    let momentOfInterest = this.offsetDateOfInterestIfCloseToToday(moment(this.dateOfInterest), this.stepSize);
    let startingPoint = momentOfInterest.clone().subtract(this.NUMBER_OF_DATA_POINTS_TO_DISPLAY / 2, this.stepSize as moment.unitOfTime.DurationConstructor)
    this.dateOfInterest = momentOfInterest.toDate();

    for (var index = 0; index < this.NUMBER_OF_DATA_POINTS_TO_DISPLAY; index++) {
      dateTimePoints.push(moment(startingPoint).add(index, this.stepSize as moment.unitOfTime.DurationConstructor));
    }

    return dateTimePoints;
  }

  offsetDateOfInterestIfCloseToToday(momentOfInterest: moment.Moment, stepSize: string): moment.Moment {
    let endPoint = momentOfInterest.clone().add((this.NUMBER_OF_DATA_POINTS_TO_DISPLAY / 2), stepSize as moment.unitOfTime.DurationConstructor)
    while (endPoint.isAfter(moment())) {
      momentOfInterest = momentOfInterest.clone().subtract(1, stepSize as moment.unitOfTime.DurationConstructor);
      endPoint = momentOfInterest.clone().add((this.NUMBER_OF_DATA_POINTS_TO_DISPLAY / 2), stepSize as moment.unitOfTime.DurationConstructor)
    }
    return momentOfInterest;
  }

  buildClientQuotaHistoryRange(dateTimeDataPoints: moment.Moment[]): ClientQuotaAHubVO[] {
    let clientQuotaHistoryRange: ClientQuotaAHubVO[] = [];
    dateTimeDataPoints.forEach(dataPoint => {
      clientQuotaHistoryRange.push(this.getClosestPriorRealData(dataPoint))
    });
    return clientQuotaHistoryRange;
  }

  getClosestPriorRealData(dataPoint: moment.Moment): ClientQuotaAHubVO {
    let clientQuotaHistoryDataPoint: ClientQuotaAHubVO = undefined;
    let previousRealDataPoint = {
      id: 0,
      clientId: 0,
      lastUsageUpdate: new Date(),
      limit: 0,
      usage: 0
    };
    for (let realDataPoint of this.clientQuotaHistory) {



      if (dataPoint.isBefore(realDataPoint.lastUsageUpdate)) {
        clientQuotaHistoryDataPoint = Utils.clone(previousRealDataPoint);
        clientQuotaHistoryDataPoint.lastUsageUpdate = dataPoint.toDate();
        break;
      }
      previousRealDataPoint = realDataPoint;
    };
    return clientQuotaHistoryDataPoint;
  }

  shiftHistory(directection: string) {
    switch (directection) {
      case 'left':
        this.dateOfInterest = moment(this.dateOfInterest)
          .subtract(1, this.stepSize as moment.unitOfTime.DurationConstructor)
          .toDate();
        this.updateChart();
        break;

      case 'right':
        this.dateOfInterest = moment(this.dateOfInterest)
          .add(1, this.stepSize as moment.unitOfTime.DurationConstructor)
          .toDate();
        this.updateChart();
        break;

      default:
        break;
    }
  }

  // toggleChartType() {
  //   if (this.chartType === 'bar') {
  //     this.chartType = 'line';
  //     this.setChartOptions();
  //   } else {
  //     this.chartType = 'bar';
  //     this.setChartOptions();
  //   }
  // }

  setChartOptions() {
    let self = this;
    this.options = {
      title: {
        display: true,
        text: this.title,
        fontFamily: 'Roboto',
        fontSize: 20,
      },
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          fontFamily: "Roboto",
        }
      },

      scales: {
        xAxes: [{
          stacked: this.chartType === 'bar',
          ticks: {
            callback: function (value, index, values) {
              if (value === 'now') {
                return 'Now';
              }

              if (moment(value).hour() == 0 && self.stepSize == 'hours') {
                return moment(value).format('ha Do MMM YY');
              }
              return moment(value).format(self.tickDateFormat);
            }
          }
        }],
        yAxes: [{
          stacked: this.chartType === 'bar',
          display: true,
          ticks: {
            suggestedMin: 0,    // minimum will be 0, unless there is a lower value.
          }
        }]
      },
    };
  }
}
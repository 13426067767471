import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-percentage-circle-sliced',
  templateUrl: './percentage-circle-sliced.component.html',
  styleUrls: ['./percentage-circle-sliced.component.css']
})
export class PercentageCircleSlicedComponent implements OnInit {

  @Input() total: number = 100;    // Total circle value.
  @Input() values: number[] = [60];     // Primary Value.
  @Input() valueIndexLabel: number = 0; //Value which we want to display on the display
  @Input() valueLabel: string = ""; // Label for the value.
  @Input() valueLabels: string[] = []; // Labels for the values.
  @Input() underlayValueLabel: string = ""; // Label for the unlderlying value.
  @Input() valueColours: string[] = ["DarkGreen"];
  @Input() strokeWidthFraction: number = 0.025 // Stroke width as a fraction of the radius 1/10.
  @Input() strokeOpacity: number = 1; // Ability to fade the stroke colour, can be useful on small circles as it heps with anti-aliasing.
  @Input() valueDisplay: string = "fraction"; // Options are none ( or any unmatched value ) , fraction, value , percentage
  @Input() strokeOffset: number = 0.25;
  @Input() usedCircumference: number = 1.0; // How much of the circumference should we use.
  @Input() unitsLabel: string = ""; // Units label to use, disables aggregation ie.. K and M.


  valuePercentage: number = 0; // Calculated percentage.
  valuePercentageDisplay: string; // Calculated percentage formatted for display.

  // Visual circle dimensions.
  circleRadius = 28;
  circleCircumference = this.circleRadius * 2 * Math.PI;

  // Colours to use and dash array ( which is used to dash part of the circle to make it partially fill. )
  strokeWidth: number;
  strokeStart: number;
  valueStrokeDashArray = [0, this.circleCircumference];
  trackStrokeDashArray = [this.circleCircumference, 0];
  fromStrokeDashArray = [0, this.circleCircumference];

  // value tool tips.
  valueTooltip: string;

  //Array of the value, with there associated index position specified.
  //we will do this as they will end up in an order which sorts the view
  //but the index needs to be maintained to keep it all happy
  valueArrayIndex: { value: number, index: number }[] = []

  constructor() {
    // This is intentional
  }

  ngOnInit() {

    // Compute display properties
    this.strokeWidth = this.strokeWidthFraction * this.circleRadius;
    this.strokeStart = this.circleCircumference * this.strokeOffset;
    this.trackStrokeDashArray = [this.circleCircumference * this.usedCircumference, this.circleCircumference - (this.circleCircumference * this.usedCircumference)];

    // Set the tooltip values.
    this.valueTooltip = this.valueLabel;

    // Refresh teh display. not just colours but shape too!
    this.setSpinnerColours();

  }

  ngOnChanges(changes) {
    this.setSpinnerColours();



  }

  setSpinnerColours() {

    //Generate our new value array index
    this.valueArrayIndex = (this.values) ? this.values.map((val, index) => { return { value: val, index: index } }).reverse() : [];

    //Get the display value from the array
    let value = (this.values && this.values.length > 0 && this.valueIndexLabel >= 0 && this.valueIndexLabel < this.values.length) ? this.values[this.valueIndexLabel] : 0;

    this.valuePercentage = Math.max(0, Math.min(1, (this.total > 0) ? (value / this.total) : ((this.total <= 0) ? 1 : 0)));
    this.valuePercentageDisplay = (Math.round(this.valuePercentage * 100)) + "%";

    // Set the dash pattern to make teh correct line length around the circle.
    let valueFillArc = Math.abs(this.valuePercentage * this.circleCircumference * this.usedCircumference);
    this.valueStrokeDashArray = [valueFillArc, this.circleCircumference - valueFillArc];
  }

  /**
   * Formats the displayed value, 9999 , 9.99K , 10.5k , 100k etc..
   */
  valueFormat(value: number): string {

    if (isNaN(value))
      value = 0;

    if (value == null)
      value = 0;

    if (this.unitsLabel == "") {

      if (value < 1000)
        return value.toString();

      if (value < 9999)
        return Math.round(value / 10) / 100 + "K";

      if (value < 99999)
        return Math.round(value / 100) / 10 + "K";

      if (value < 999999)
        return Math.round(value / 1000) + "K";

      return Math.round(value / 10000) / 100 + "M";
    }

    return value.toString() + this.unitsLabel;
  }

  /**
   * Determines the array for display
   */
  getArcDashArray(index: number) {

    //Start with a value of zero
    var value = 0;

    while (index >= 0) {
      value += this.values[index];
      index--;
    }

    //Generate a value percentage for the current value
    let valuePercentage = Math.max(0, Math.min(1, (this.total > 0) ? (value / this.total) : ((this.total <= 0) ? 1 : 0)));

    //Set the dash pattern to make teh correct line length around the circle.
    let valueFillArc = Math.abs(valuePercentage * this.circleCircumference * this.usedCircumference);

    //Return our arc
    return [valueFillArc, this.circleCircumference - valueFillArc];
  }

  /**
   * Determines the stoke colour based on the arc amount, and limit values.
   */
  getArcColour(index: number) {

    //Get the colour for this index
    if (this.valueColours && this.valueColours.length > 0 && index >= 0 && index < this.valueColours.length) {
      return this.valueColours[index];
    }

    //No colour to return
    return undefined;
  }

  /**
   * Gets the tooltip for the segment which it represents
   */
  getArcToolTip(index: number) {

    //Get the tooltip for this index
    if (this.valueLabels && this.valueLabels.length > 0 && index >= 0 && index < this.valueLabels.length) {
      return this.valueLabels[index];
    }

    //Default to the value label
    return this.valueLabel;
  }
}



import { take } from 'rxjs/operators';
import { Component, OnInit, Input, Output, ElementRef, ViewChild, EventEmitter } from '@angular/core';
// Form imports for search.
import { FormGroup, FormControl, FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';

import { Observable, timer } from 'rxjs';
import { EntityPermissions } from 'app/valueObjects/ahub/accounts/entity-permissions.ahub';

@Component({
  selector: 'app-list-add-input',
  templateUrl: './list-add-input.component.html',
  styleUrls: ['./list-add-input.component.css']
})
export class ListAddInputComponent implements OnInit {
  /**
   * Link to the add item text input - Note the function as viewChild, this is because the component is ngIf needs to be set after init.
   */
  itemAddInput: ElementRef;

  @ViewChild('itemAddInput', { static: false }) set content(content: ElementRef) {
    this.itemAddInput = content;
  }

  /**
   * These are the permissions required to make this button visible.
   */
  @Input()
  visiblePermission: EntityPermissions;

  /**
   * Add Prompt
   */
  @Input() itemAddPrompt: string;

  /**
   * Are we showing the add item input? No by default
   */
  @Input() itemAddInputShow: boolean = false;

  /**
   * Regexp pattern used to validate added items
   */
  _pattern: string;
  @Input()
  set pattern(pattern: string) {
    this._pattern = pattern;
    let regex = new RegExp(pattern);
    this.addInputControlForm.controls.addInput.setValidators(Validators.pattern(regex));
  };

  /**
   * Regexp pattern used to validate added items
   */
  @Input() patternFailMessage: string;

  /**
   * Boolean Observable which lets up know weather the item is busy or not
   */
  @Input() isBusy: boolean = false;

  /**
   * Specify the position of the input box relative to the add button. left by default
   */
  @Input() calloutPosition: string = 'left';

  /**
 * Emit the value to be added to the list
 */
  @Output() addValue: EventEmitter<any> = new EventEmitter();

  /**
   * Form to control the 'add' input box
   */
  addInputControlForm: FormGroup = this.formBuilder.group({
    addInput: ['']
  });

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.addInputControlForm.controls.addInput.setValidators(Validators.pattern(this._pattern));
  }


  /**
 * Called when the add item button is clicked.
 */
  itemAddButtonClickHander() {
    // We either show the add callout, or if the add callout is already open,
    // we try and add the user.
    if (!this.itemAddInputShow) {
      this.itemAddInputShow = true;
      timer(500, 500).pipe(take(1)).subscribe(timer => { if (this.itemAddInput) this.itemAddInput.nativeElement.focus(); });
      return;
    }

    // Stop the adding of items when the value entered is invalid.
    if (!this.addInputControlForm.valid)
      return;

    if (this.addInputControlForm.controls.addInput.value) {
      this.addValue.emit(this.addInputControlForm.controls.addInput.value);
    }
    this.itemAddInputShow = false;
    //Reset the item add input value
    this.addInputControlForm.controls.addInput.setValue('');
  }


  /**
   * Cancels the input dialog.
   */
  itemAddCancel() {
    this.itemAddInputShow = false;
    this.addInputControlForm.controls.addInput.setValue('');
  }

}

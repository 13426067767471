/**
 * ----------------------------------------------------------
 * Action Types
 * ----------------------------------------------------------
 */
import { PresignedUrlAHubVO } from 'app/valueObjects/ahub/presigned-url.ahub.vo';
import {
  ActionAViewVO, ActionComponentPublicationEditionAssets,
  ActionLibraryViewConfigsAHubVO as ActionProductViewConfigsAHubVO,
  ActionLibraryViewConfigByEditionAHubVO as ActionProductViewConfigByEditionAHubVO,
  ActionLibraryViewConfigMapStorageByPublicationIdAHubVO as ActionProductViewConfigMapStorageByPublicationIdAHubVO,
  ActionAViewAssetFetch, ActionAViewProductFullDataVO
} from './types/aview.action-types';
import {
  ActionIdItem, ActionNumberNumber, ActionStringNumber, ActionNumberNumberString,
  ActionStringNumberArray, ActionString, ActionBoolean
} from './types/common.action-types';
import { AViewVO } from 'app/modules/routes/aview/valueObjects/aview.aview.vo';
import { ProductViewConfigAViewVO } from 'app/modules/routes/aview/valueObjects/product-view-config.aview.vo';
import { MapStorage } from '../map-storage.vo';
import { ActionWork } from './types/work.action-types';
import { ExportOutputAssetAHubVO } from 'app/valueObjects/ahub/accounts/export-output-asset.ahub.vo';
import { AviewProductFullData } from 'app/modules/routes/aview/aview-product/aview-product.component';

/**
 * View Actions class
 */
export class AViewActions {

  static AVIEW_PUBLICATION_EDITION_PATH_URL_FETCH = "[AVIEW] AVIEW_PUBLICATION_EDITION_PATH_URL_FETCH";
  static AVIEW_PUBLICATION_EDITION_PATH_URL_SET = "[AVIEW] AVIEW_PUBLICATION_EDITION_PATH_URL_SET";
  static AVIEW_PUBLICATION_EDITION_ASSETS_FETCH = "[AVIEW] AVIEW_PUBLICATION_EDITION_ASSETS_FETCH";
  static AVIEW_PUBLICATION_EDITION_ASSETS_BY_IDS_FETCH = "[AVIEW] AVIEW_PUBLICATION_EDITION_ASSETS_BY_IDS_FETCH";
  static AVIEW_SET = "[AVIEW] AVIEW_SET";
  static AVIEW_REMOVE = "[AVIEW] AVIEW_REMOVE";
  static AVIEW_CLEAR = "[AVIEW] AVIEW_CLEAR";
  static AVIEW_FETCH = "[AVIEW] AVIEW_FETCH";
  static AVIEW_COMPONENT_ID_SET = "[AVIEW] AVIEW_COMPONENT_ID_SET";
  static AVIEW_COMPONENT_DELETE = "[AVIEW] AVIEW_COMPONENT_DELETE";
  static AVIEW_COMPONENT_PRODUCT_COUNT_SET = "[AVIEW] AVIEW_COMPONENT_PRODUCT_COUNT_SET";
  static AVIEW_COMPONENT_SELECTED_PRODUCT_ID_SET = "[AVIEW] AVIEW_COMPONENT_SELECTED_PRODUCT_ID_SET";
  static AVIEW_COMPONENT_SELECTED_PRODUCT_VIEW_DATA_SET = "[AVIEW] AVIEW_COMPONENT_SELECTED_PRODUCT_VIEW_DATA_SET";
  static AVIEW_COMPONENT_SELECTED_CATEGORY_ID_SET = "[AVIEW] AVIEW_COMPONENT_SELECTED_CATEGORY_ID_SET";
  static AVIEW_COMPONENT_ASSETS_SET = "[AVIEW] AVIEW_COMPONENT_ASSETS_SET";
  static AVIEW_COMPONENT_ASSETS_APPEND = "[AVIEW] AVIEW_COMPONENT_ASSETS_APPEND";
  static AVIEW_COMPONENT_PUBLICATION_EDITION_SET = '[AHUB] AVIEW_COMPONENT_PUBLICATION_EDITION_SET';
  static AVIEW_PRODUCT_VIEW_CONFIG_SET = "[AVIEW] AVIEW_PRODUCT_VIEW_CONFIG_SET";
  static AVIEW_PRODUCT_VIEW_CONFIG_BY_EDITION_SET = "[AVIEW] AVIEW_PRODUCT_VIEW_CONFIG_BY_EDITION_SET";
  static AVIEW_PRODUCT_VIEW_CONFIGS_BY_PUBLICATION_ID_SET = "[AVIEW] AVIEW_PRODUCT_VIEW_CONFIGS_BY_PUBLICATION_ID_SET";
  static AVIEW_PRODUCT_VIEW_CONFIGS_CLEAR = "[AVIEW] AVIEW_PRODUCT_VIEW_CONFIGS_CLEAR";
  static AVIEW_FILTERED_PRODUCT_IDS_SET = "[AVIEW] AVIEW_FILTERED_PRODUCT_IDS_SET";
  static AVIEW_DISPLAY_SETTING_SET = "[AVIEW] AVIEW_DISPLAY_SETTING_SET";
  static AVIEW_GLOBAL_SIDEBAR_STATE_ACTIVE_SET = "[AVIEW] AVIEW_GLOBAL_SIDEBAR_STATE_ACTIVE_SET";

  /**
   * Fetches the path URL export specified version.
   */
  static publicationEditionPathURLFetch(publicationId: number, edition: number): ActionNumberNumber {
    return {
      type: AViewActions.AVIEW_PUBLICATION_EDITION_PATH_URL_FETCH,
      number1: publicationId,
      number2: edition
    }
  }

  /**
   * Store the path URL export version.
   */
  static publicationEditionPathURLSet(publicationId: number, publicationEditionPathUrl: PresignedUrlAHubVO): ActionIdItem<PresignedUrlAHubVO> {
    return {
      type: AViewActions.AVIEW_PUBLICATION_EDITION_PATH_URL_SET,
      idItem: { id: publicationId, item: publicationEditionPathUrl }
    }
  }


  static publicationEditionAssetsFetch(publicationId: number, editionNumber: number, componentId: string): ActionNumberNumberString {
    return {
      type: AViewActions.AVIEW_PUBLICATION_EDITION_ASSETS_FETCH,
      number1: publicationId,
      number2: editionNumber,
      string: componentId
    }
  }

  /**
   * Create the action type for the asset fetch by id's action
   */
  static publicationEditionAssetsFetchByAssetIds(publicationId: number, editionNumber: number, componentId: string, assetIds: number[]): ActionAViewAssetFetch {
    return {
      type: AViewActions.AVIEW_PUBLICATION_EDITION_ASSETS_BY_IDS_FETCH,
      publicationId,
      editionNumber,
      componentId,
      assetIds
    }
  }

  /**
   * Store the AView.
   */
  static aViewSet(aView: AViewVO): ActionAViewVO {
    return {
      type: AViewActions.AVIEW_SET,
      aView: aView
    }
  }

  /**
   * Clear AView by publicationId and edition
   */
  static aViewRemove(publicationId: number, edition: number): ActionNumberNumber {
    return {
      type: AViewActions.AVIEW_REMOVE,
      number1: publicationId,
      number2: edition
    }
  }

  /**
   * Create a new action that will clear all of the aViews.
   */
  static aViewClear(): ActionWork {
    return {
      type: AViewActions.AVIEW_CLEAR
    }
  }

  /**
   * Fetch the AView by publicationId and edition.
   */
  static aViewFetch(publicationId: number, edition: number): ActionNumberNumber {
    return {
      type: AViewActions.AVIEW_FETCH,
      number1: publicationId,
      number2: edition
    }
  }

  /**
   * Set the component id for the component map
   *
   * @param componentId            Component id which this is in reference too
   */
  static aViewComponentIdSet(componentId: string): ActionString {
    return {
      type: AViewActions.AVIEW_COMPONENT_ID_SET,
      string: componentId,
    }
  }

  /**
   * Delete component map by id
   *
   * @param componentId            Component id which this is in reference to
   */
  static aViewComponentDelete(componentId: string): ActionString {
    return {
      type: AViewActions.AVIEW_COMPONENT_DELETE,
      string: componentId,
    }
  }

  /**
   * Set the selected product id for the given aview products component
   *
   * @param componentId            Component id which this is in reference too
   * @param productCount           Number of products being displayed in this aview component
   */
  static aViewComponentProductCountSet(componentId: string, productCount: number): ActionStringNumber {
    return {
      type: AViewActions.AVIEW_COMPONENT_PRODUCT_COUNT_SET,
      string: componentId,
      number: productCount
    }
  }

  /**
   * Set the selected product id for the given aview products component
   *
   * @param componentId            Component id which this is in reference too
   * @param selectedProductId      Data set category selected product id which we want to set
   */
  static aViewComponentSelectedProductIdSet(componentId: string, selectedProductId: number): ActionStringNumber {
    return {
      type: AViewActions.AVIEW_COMPONENT_SELECTED_PRODUCT_ID_SET,
      string: componentId,
      number: selectedProductId
    }
  }

  /**
   * Set the selected product id for the given aview products component
   *
   * @param componentId            Component id which this is in reference too
   * @param selectedProductId      Data set category selected product id which we want to set
   */
  static aViewComponentSelectedProductViewDataSet(componentId: string, selectedProductViewData: AviewProductFullData): ActionAViewProductFullDataVO {
    return {
      type: AViewActions.AVIEW_COMPONENT_SELECTED_PRODUCT_VIEW_DATA_SET,
      componentId: componentId,
      aViewProductFullData: selectedProductViewData
    }
  }

  /**
   * Set the selected category id for the given aview products component
   *
   * @param componentId            Component id which this is in reference too
   * @param selectedCategoryId     Selected category id which we want to set
   */
  static aViewComponentSelectedCategoryIdSet(componentId: string, selectedCategoryId: number): ActionStringNumber {
    return {
      type: AViewActions.AVIEW_COMPONENT_SELECTED_CATEGORY_ID_SET,
      string: componentId,
      number: selectedCategoryId
    }
  }

  /**
   * Set the content set id for the publication in this component
   *
   * @param componentId            Component id which this is in reference too
   * @param assets           assets for (used to create) the publication
   */
  static aViewComponentPublicationEditionAssetsSet(
    componentId: string,
    publicationId: number,
    edition: number,
    assets: ExportOutputAssetAHubVO[]): ActionComponentPublicationEditionAssets {
    return {
      type: AViewActions.AVIEW_COMPONENT_ASSETS_SET,
      componentId,
      publicationId,
      edition,
      assets: assets
    }
  }

  /**
   * Append the assets to the suppled component
   *
   * @param componentId      Component id which this is in reference too
   * @param assets           assets for (used to create) the publication
   */
  static aViewComponentPublicationEditionAssetsAppend(
    componentId: string,
    publicationId: number,
    edition: number,
    assets: ExportOutputAssetAHubVO[]): ActionComponentPublicationEditionAssets {
    return {
      type: AViewActions.AVIEW_COMPONENT_ASSETS_APPEND,
      componentId,
      publicationId,
      edition,
      assets: assets
    }
  }

  /**
   * Create an action to se the components publication and editions.
   *
   * @param componentId             The component who's publication and edition have changed.
   * @param publicationId           The id of the publication now selected.
   * @param edition                 The edition that has now been selected.
   */
  static aViewComponentPublicationEditionSet(componentId: string, publicationId: number, edition: number): ActionNumberNumberString {
    return {
      type: AViewActions.AVIEW_COMPONENT_PUBLICATION_EDITION_SET,
      number1: publicationId,
      number2: edition,
      string: componentId
    };
  }


  /**
 * Set the library view config
 */
  static productViewConfigSet(productViewConfigs: MapStorage<MapStorage<ProductViewConfigAViewVO>>): ActionProductViewConfigsAHubVO {

    //Constuct action with the specified interface
    return {
      type: AViewActions.AVIEW_PRODUCT_VIEW_CONFIG_SET,
      productViewConfigs: productViewConfigs,
    };
  }

  /**
  * Set the library view config by dataset id set in the permanent part of the store
  */
  static productViewConfigByEditionSet(productViewConfig: ProductViewConfigAViewVO, edition: number): ActionProductViewConfigByEditionAHubVO {

    //Constuct action with the specified interface
    return {
      type: AViewActions.AVIEW_PRODUCT_VIEW_CONFIG_BY_EDITION_SET,
      productViewConfig: productViewConfig,
      edition
    };
  }

  /**
  * Set the library view config by publication id set in the permanent part of the store
  */
  static productViewConfigMapStorageByPublicationIdSet(
    productViewConfigMapStorage: MapStorage<ProductViewConfigAViewVO>,
    publicationId: number): ActionProductViewConfigMapStorageByPublicationIdAHubVO {

    //Constuct action with the specified interface
    return {
      type: AViewActions.AVIEW_PRODUCT_VIEW_CONFIGS_BY_PUBLICATION_ID_SET,
      productViewConfigMapStorage: productViewConfigMapStorage,
      publicationId: publicationId
    };
  }

  /**
   * Create an action to clear the library view config from the store.
   *
   */
  static productViewConfigClear(): ActionWork {

    // Construct the action.
    return {
      type: AViewActions.AVIEW_PRODUCT_VIEW_CONFIGS_CLEAR,
    };
  }

  /**
   * Create an action to store the remaining product ids after filtering has been applied .
   *
   */
  static filteredProductIdsSet(componentId: string, filteredProductIds: number[]): ActionStringNumberArray {

    // Construct the action.
    return {
      type: AViewActions.AVIEW_FILTERED_PRODUCT_IDS_SET,
      string: componentId,
      numbers: filteredProductIds
    };
  }

  /**
   * Create an action to store view state of aView eg. Compare mode/ Detail View/ Standard.
   *
   */
  static displaySettingSet(displaySetting: string): ActionString {

    // Construct the action.
    return {
      type: AViewActions.AVIEW_DISPLAY_SETTING_SET,
      string: displaySetting
    };
  }

  /**
   * Create an action to store the remaining product ids after filtering has been applied .
   *
   */
  static globalSidebarStateActive(globalSidebarStateActive: boolean): ActionBoolean {

    // Construct the action.
    return {
      type: AViewActions.AVIEW_GLOBAL_SIDEBAR_STATE_ACTIVE_SET,
      boolean: globalSidebarStateActive
    };
  }


}

import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-knowledge-base-helper-icon',
  templateUrl: './knowledge-base-helper-icon.component.html',
  styleUrls: ['./knowledge-base-helper-icon.component.css']
})
export class KnowledgeBaseHelperIconComponent implements OnInit {


  /**
   * Knowledge base URL
   */
  @Input() knowledgeBasePagePath: string;
  /**
  * Tool Tip
  */
  @Input() toolTip = 'Knowledge Base';

  knowledgeBaseUrl: string = environment.knowledgeBasePath

  constructor() {
    // This is intentional
  }

  ngOnInit() {
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RequestActionMonitorService } from 'app/services/request-action-monitor/request-action-monitor.service';
import { aHubStatePermanentUsers } from 'app/store/selector/ahub/ahub-permanent.selector';
import { sessionUserId } from 'app/store/selector/app.selector';
import { StoreAccess } from 'app/store/store-access';
import { UserAHubVO } from 'app/valueObjects/ahub/accounts/user.ahub.vo';
import { NotificationObjectRequestActionStatusVO, NotificationObjectWorkflow, NotificationRecordVO } from 'app/valueObjects/app/notification-record.vo';
import { RequestActionStatusUploadVO } from 'app/valueObjects/app/request-action-status-upload.vo';
import { combineLatest, Observable, of } from 'rxjs';
import { delay, filter, map, takeUntil } from 'rxjs/operators';
import { componentDestroyStream, Hark } from '../../hark.decorator';
import { Utils } from '../../utils';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
@Hark()

export class ToastComponent implements OnInit {

  @Input() notification$: Observable<NotificationRecordVO>;

  /**
   * An emitter for the close icon
   */
  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  // If the notification record type is action , then we will cast its payload here.
  requestActionStatus: NotificationObjectRequestActionStatusVO;

  // If the notification record type is workflow , then we will cast its payload here.
  workflowStatus: NotificationObjectWorkflow;
  //Main upload stream
  notificationUploadStream$: Observable<RequestActionStatusUploadVO> = of(undefined);
  notificationUploadCurrentDataStream$: Observable<{ objectName: string, progress: number }> = of(undefined);
  notificationUploadDataTotal$: Observable<number> = of(0);
  notificationUploadDataComplete$: Observable<number> = of(0);

  /**
  * General booleans used to toggle UX/UI emements
  */
  toastDisabled = false;
  showQueueIcon = false;

  /**
   * This is the current session user.
   */
  sessionUser$: Observable<UserAHubVO> =
    combineLatest([
      StoreAccess.dataGetObvs(sessionUserId)
        .pipe(
          filter(sessionUserId => sessionUserId !== undefined)
        ),
      StoreAccess.dataGetObvs(aHubStatePermanentUsers)])
      .pipe(
        delay(0),
        map(([sessionUserId, users]) => users.find(user => user.id === sessionUserId))
      );


  constructor(
    private readonly requestActionMonitor: RequestActionMonitorService
  ) { }

  ngOnInit() {
    this.watchNotification();
  }

  /**
   * Empty On destroy to ensure @Hark decorator works for an AOT build
   */
  ngOnDestroy() {
    // Empty On destroy to ensure @Hark decorator works for an AOT build
  }


  /**
   * Watch the notification so we can keep the toast updated
   */
  watchNotification() {

    this.notification$.pipe(
      Utils.isNotNullOrUndefined(),
      takeUntil(componentDestroyStream(this))
    ).subscribe(notification => {

      // Depending on the type of notification, we'll cast the notificationObject payload, to make it easy to get properties.
      switch (notification.notificationType) {

        case "General": {

          /**
           * Emit and close toast (the delay is to account for the animaton)
           */
          setTimeout(() => {
            this.closeToast();
          }, 3500);
          break;
        }

        case "Workflow": {
          this.setupWorkflowNotification(notification);
          break;
        }

        default:
          break;
      }


      /**
       * Emit if complete and close toast (the delay is to account for the animaton)
       */
      if (notification.notificationObject.complete || notification.notificationObject.fault) {
        setTimeout(() => {
          this.closeToast();
        }, 3500);
      }
    });
  }

  /**
   * Setup the workflow notification for the toast
   *
   * @param notification    Notification which we want to del with
   */
  setupWorkflowNotification(notification: NotificationRecordVO) {

    this.workflowStatus = notification.notificationObject as NotificationObjectWorkflow;

    //Get the upload for this worklog, we may not have one in which case the stream will not fire. Also construct the current upload stream
    this.notificationUploadStream$ = this.requestActionMonitor.requestActionStatusUploadObservableByWF(this.workflowStatus.workflowExecutionId)
      .pipe(filter(upload => upload !== undefined));

    this.notificationUploadCurrentDataStream$ = this.notificationUploadStream$.pipe(
      map(upload => {
        return ((upload.uploadData) ? upload.uploadData
          .sort((a, b) => a.priority > b.priority ? -1 : 1)
          .find(a => a.progress < 100 || a.progress === undefined) : undefined);
      }),
      map(upload => {
        return (upload) ? { objectName: upload.uploadPath, progress: (upload.progress === undefined) ? 0 : upload.progress } : undefined
      }));

    //Cretae a list of how many uploads we have
    this.notificationUploadDataTotal$ = this.notificationUploadStream$.pipe(
      filter(upload => upload !== undefined),
      map(upload => (upload.uploadData) ? upload.uploadData.length : 0)
    );

    //How many are complete
    this.notificationUploadDataComplete$ = this.notificationUploadStream$.pipe(
      filter(upload => upload !== undefined),
      map(upload => (upload.uploadData) ? upload.uploadData.filter(o => o.progress === 100).length : 0)
    );

    // Show queue icon when no start time
    if (notification.notificationObject.worklogs !== undefined) {
      this.showQueueIcon = (notification.notificationObject.worklogs.find(worklog => worklog.startTime === undefined)) ? true : false;
    }
  }

  /**
   * Styles the toast depending on the notification state
   */
  successState(notification): string {

    if (!notification) {
      return undefined;
    }
    else if (notification.notificationObject.complete && notification.notificationObject.failed || notification.notificationObject.fault) {
      return "FAILED";
    }
    else if (notification.notificationObject.complete && !notification.notificationObject.failed) {
      return "SUCCESS";
    }

    return undefined;
  }

  /**
   * Closes the toast and emits to let parent know (the delay is to account for the animaton)
   */
  closeToast() {
    this.toastDisabled = !this.toastDisabled;
    setTimeout(() => {
      this.close.emit();
    }, 500);
  }

  /**
  * Get an observable version of the user.
  *
  * @param userId        The user id to covert.
  */
  userIdObservableGet(userId: number): Observable<number> {
    return of(userId);
  }
}

import { PipeTransform, Pipe } from "@angular/core";

/*
Used to reduce change detection churn. Passing a function to an input which gets used to display a value on screen
creates lots of recalling of the function even if values are unchanged. This problem can be address by using a
pure function pipe which can be passed the label function.
(see https://stackoverflow.com/questions/55161881/why-angular-2-innerhtml-is-calling-method-multiple-times-in-a-single-statement)
*/
@Pipe({ name: 'listLabelFunction' })
export class ListLabelFunctionPipe implements PipeTransform {
  transform(func: any, ...args: any[]): any {
    return func(...args);
  }
}

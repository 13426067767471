import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateTimeOrYear'
})
export class DateTimeOrYearPipe implements PipeTransform {

    transform(value: Date, args?: any): any {

        //Do we have a date value
        if (value) {

            //Create a epoch time for now
            let nowEpoch: number = new Date().getTime();

            let valueAsDate: Date = new Date(value);

            //Work out the milisecond difference between the two dates
            let timeDifference = nowEpoch - valueAsDate.getTime();

            //If the date is over a 6 months old then we will return the year if not then we will return the date
            if (timeDifference > (31556952000 / 2))
                return valueAsDate.getFullYear();
            else
                return valueAsDate.toLocaleTimeString('en-US');
        }

        //Return empty if we get this far
        return '';
    }

}

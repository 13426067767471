
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { PermissionsService } from 'services/permissions/permissions.service';

@Injectable()
/**
 * Class which will guard against navigating when not logged in.
 */
export class PermissionGuard implements CanActivate {

  constructor(private readonly router: Router, private readonly permissionsService: PermissionsService) { }

  /**
   * Can we activate this route?
   */
  canActivate(destinationRouteSnapshot: ActivatedRouteSnapshot): Observable<boolean> {
    return this.permissionsService.entityPermissions$.pipe(
      filter(entityPermissions => entityPermissions !== undefined),
      map(entityPermissions => {
        return this.permissionsService.canNavigateTo(destinationRouteSnapshot.url.toString());
      }));
  }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'countryCodeToLanguage' })
export class CountryCodeToLanguagePipe implements PipeTransform {

    languages = {
        en: 'English en',
        de: 'German de',
        fr: 'French fr',
        es: 'Spanish es',
        it: 'Italian it',
        // 'Japanese ja',
        // 'Chinese zh',
        // 'Dutch nl',
        // 'Portuguese pt',
        // 'Finnish fi',
        // 'Swedish sv',
        // 'Norwegian no',
        // 'Danish da',
        // 'Korean ko',
        // 'Polish pl',
        // 'Russian ru',
        // 'Hebrew he',
        // 'Hungarian hu',
        // 'Greek el',
        // 'Turkish tr',
        // 'Czech cs',
        // 'Thai th',
        // 'Arabic ar',
        // 'Icelandic is'
    }

    public transform(input: string): string {
        return (this.languages[input]) ? this.languages[input] : 'COUNTRY NOT FOUND';
    }

}
import { Subject } from 'rxjs';
import { TreeComponent, TreeNodeVO, TreeSize, ViewboxArea, ViewboxPoint } from './tree.component';
import { count } from 'rxjs/operators';

export class TreeStructure {
  treeComponent: TreeComponent; // The master Tree.
  id: string;
  childrenShow: boolean;
  node: TreeNodeVO; // This is the tree complaint data, refered internally as the node.
  nodeChanged$: Subject<void>; // Event emits when content has changed.
  sizeDisplayed: TreeSize; // The width we are using for display.. used by the visual component.
  sizeDisplayedStart: TreeSize; // Where the width transition starts.. used by the visual component.
  sizeDisplayedTransitionStart: number; // time the transitions started... used by the visual component.
  size: TreeSize; // The actual width, used for calculations. This is immediate and not animated.
  absolutePosition$: Subject<ViewboxPoint>  // An updated absolute position for the root of this part of the tree structure, used to update nodeViewboxArea.
  nodeViewboxArea: ViewboxArea; // This is updated by the view to indicate the area covered by the node of this subtree. Should not be used for display. ( that would be circular )
  subTrees: TreeStructure[];
  parentTree: TreeStructure;
  posCalc$: Subject<string>; // emits when some thing has happened which should trigger a recalculation of position.
  isSelected: boolean;
  isHighlighted: boolean;
  isDropTarget: boolean;


  // Takes a tree structure and adds it this one.
  static subTreeAdd(tree: TreeStructure, parentTree: TreeStructure) {

    if ((tree === null || tree === undefined) || (parentTree === null || parentTree === undefined)) {
      return;
    }

    tree.parentTree = parentTree;
    parentTree.subTrees.push(tree);
    // parentTree.subTrees.sort((a, b) => {
    //     if ((a.node.hasOwnProperty("label")) && (b.node.hasOwnProperty("label"))) {
    //         return (a.node.label < b.node.label) ? -1 : 1;
    //     }

    //     return (a.node.id < b.node.id) ? -1 : 1;
    // })



    // We would hope that the ancestors property will have been already set, but we'll revist them anyway.
    TreeStructure.applyFunctionSubTrees(tree, (tree: TreeStructure) => {
      if ((tree.node != null) && (tree.parentTree != null) && (tree.parentTree.node != null)) {
        if (tree.parentTree.node.ancestry.charAt(0) !== ',') {
          tree.node.ancestry = `,${tree.parentTree.node.ancestry} ${tree.parentTree.node.id},`;
        } else {
          tree.node.ancestry = `${tree.parentTree.node.ancestry} ${tree.parentTree.node.id},`;
        }
      }
    }, true)

  }

  // Takes a tree id and removes it from this trees subTrees.
  static subTreeRemove(subTreeIdToRemove: string, tree: TreeStructure) {

    if (tree === null || tree === undefined) {
      return;
    }

    // Loop through and remove.. filtering dos'nt work as it makes a copy !
    tree.subTrees.forEach((subTree, index) => {

      if (subTree.id == subTreeIdToRemove) {
        subTree.parentTree = null;
        subTree.node.ancestry = ',';
        tree.subTrees.splice(index, 1);

        // Reset ancestry.
        TreeStructure.applyFunctionSubTrees(subTree, (tree: TreeStructure) => {

          if ((tree.node != null) && (tree.parentTree != null) && (tree.parentTree.node != null)) {
            tree.node.ancestry = `${tree.parentTree.node.ancestry}${tree.parentTree.node.id},`;
          }
        }, true)
      }
    });
  }

  static TRANTIME: number = 1000; // How long transitions take.

  // Requests a tree structure to recalculate its position.
  static reposition(tree: TreeStructure) {
    if (tree) {
      tree.posCalc$.next("");
    }
  }

  /**
  * Requests a tree structure to recalculate its position.
  * But excluding the id passed.
   */
  static repositionExcluding(tree: TreeStructure, excluding: string) {
    if (tree) {
      tree.posCalc$.next(excluding);
    }
  }

  /**
   * Applies a method to this tree and the trees ancestors.
   * From the bottom up.
   */
  static applyFunctionAncestors(tree: TreeStructure, applyFunction: Function) {
    if (tree.parentTree != null) {
      TreeStructure.applyFunctionAncestors(tree.parentTree, applyFunction);
    }
    applyFunction(tree);
  }

  /**
 * Applies the function to this tree and all the child sub trees.
 * From the bottom up.
 */
  static applyFunctionSubTrees(tree: TreeStructure, applyFunction: Function, topDown?: boolean) {

    // Default to bottom up application of function.
    if (topDown === undefined || topDown === null) {
      topDown = false;
    }

    // For Easy reading.
    const bottomUp = !topDown;

    // Check something to operate on.
    if (tree === null || tree === undefined) return;

    if (topDown) { applyFunction(tree); }

    tree.subTrees.forEach(subTree => {
      if (topDown) { applyFunction(subTree); }
      TreeStructure.applyFunctionSubTrees(subTree, applyFunction);
      if (bottomUp) { applyFunction(subTree); }
    });

    if (bottomUp) { applyFunction(tree); };
  }

  /**
  * Applies the function to this tree subtrees ONLY NOT INCLUDEing originalting tree.
  */
  static applyFunctionSubTreesOnly(tree: TreeStructure, applyFunction: Function, topDown?: boolean) {

    // Default to bottom up application of function.
    if (topDown === undefined || topDown === null) {
      topDown = false;
    }

    // Default to bottom up application of function.
    // For Easy reading.
    const bottomUp = !topDown;

    tree.subTrees.forEach(subTree => {
      if (topDown) { applyFunction(subTree); }
      TreeStructure.applyFunctionSubTrees(subTree, applyFunction);
      if (bottomUp) { applyFunction(subTree); }
    });
  }

  /**
* Applies the function to every part of the tree..
*/
  static applyFunctionEverywhere(tree: TreeStructure, applyFunction: Function) {

    if (!tree) {
      return;
    }
    // Go back up to root.
    if (tree.parentTree != null) {
      TreeStructure.applyFunctionEverywhere(tree.parentTree, applyFunction);
    }
    else { // Then apply to us and all children , bottom up.

      tree.subTrees.forEach(subTree => {
        TreeStructure.applyFunctionSubTrees(subTree, applyFunction);
        applyFunction(subTree);
      });
      applyFunction(tree);
    }
  }

  /**
   * Takes a tree structure and returns the tree node VO for highlighted items.
   * @param tree
   */
  static nodesHighlighted(tree: TreeStructure): TreeNodeVO[] {
    // Create a empty node array as  basis to return.
    const highlightedNodes: TreeNodeVO[] = [];

    // If his node is highlighted add it to the list.
    if (tree.isHighlighted) {

      highlightedNodes.push(tree.node);

      // Small optimisation , here making the assumption that children can only be highlighted
      // if we the parent are highlighted.
      tree.subTrees.forEach(subTree => {
        const highlightedChildNodes = TreeStructure.nodesHighlighted(subTree);
        highlightedChildNodes.forEach(highlightedChildNode => { highlightedNodes.push(highlightedChildNode); })
      });

    };

    return highlightedNodes;
  }

  static treeStructureByNodeId(treeStructure: TreeStructure, nodeId: string): TreeStructure {
    let treeStructureWithNodeId: TreeStructure;
    // Is this a tree structure with the desired node id?
    if (treeStructure.id === nodeId) {
      treeStructureWithNodeId = treeStructure;
    } else { // Lets drill down and check the kids
      let found = treeStructure.subTrees.some(subTree => {
        treeStructureWithNodeId = TreeStructure.treeStructureByNodeId(subTree, nodeId);
        return treeStructureWithNodeId !== undefined;
      })
    }
    return treeStructureWithNodeId
  }

}

import { Component, Input, OnDestroy, OnInit, ComponentFactory, ComponentFactoryResolver, ViewChild } from '@angular/core';
import { Observable, interval, combineLatest } from 'rxjs';
import { takeUntil, filter, map, refCount, publishReplay, debounceTime, startWith, pairwise, take } from 'rxjs/operators';
import { componentDestroyStream, Hark } from 'modules/common/hark.decorator';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import * as moment from 'moment';

/**
 * Services.
 */
import { FileSaverService } from 'services/file-saver/file-saver.service';
import { DialogService } from 'dialogs/dialog.service';
import { SearchUtils } from 'modules/common/search-utils';
import { PermissionsService } from 'services/permissions/permissions.service';
import { sortDateOldest } from 'modules/common/sort.util';

/**
 * Store access.
 */
import { StoreAccess } from 'store/store-access';

/**
 * Actions.
 */
import { AHubActions } from 'store/actions/ahub.actions';
import { ViewActions } from 'app/store/actions/view.actions';

/**
 * Selectors.
 */
import { aHubStateTemporaryClientLogs } from 'store/selector/ahub/ahub-temporary.selector';

/**
 * Value objects.
 */
import { ClientLogAHubVO } from 'valueObjects/ahub/library/client-log.ahub.vo';
import { ClientLogEntryAHubVO } from 'valueObjects/ahub/library/client-log-entry.ahub.vo';
import { ClientLogEntryTypeAHubEnum } from 'valueObjects/ahub/library/client-log-entry-type.ahub.enum'
import { EntityRefAHubEnum } from 'valueObjects/ahub/system/entity-ref.ahub.enum';

/**
 * Components.
 */
import { ClientLogEntryComponent } from './client-log-entry/client-log-entry.component';
import { InfinityScrollerComponent } from '../../components/infinity-scroller/infinity-scroller.component';

/**
 * Dialogues.
 */
import { FileSaveDialogComponent } from 'dialogs/file-save-dialog/file-save-dialog.component';


@Component({
  selector: 'app-client-log',
  templateUrl: './client-log.component.html',
  styleUrls: ['./client-log.component.css']
})
@Hark()
export class ClientLogComponent implements OnInit, OnDestroy {

  /**
   * The number of milliseconds between each client log refresh.
   */
  private readonly CLIENT_LOG_REFRESH_TIMEOUT = 10000;

  /**
   * Link to the infinty scroller component
   */
  @ViewChild('infinityScroller', { static: false })
  infinityScroller: InfinityScrollerComponent;

  /**
   * This is the title to display at the top of the component.
   */
  @Input()
  title = "";

  /**
   * This is the id of the client log we are displaying.
   */
  @Input()
  clientLogId = -1;

  /**
   * This to toggle the additional information
   */
  activeToggle = false;

  /**
   * Is the current user a system user
   */
  isSystemUser = false;

  /**
   * The client log to display.
   */
  clientLog$: Observable<ClientLogAHubVO>;

  /**
   * The client log entries in the current client log.
   */
  clientLogEntries$: Observable<ClientLogEntryAHubVO[]>;

  /**
   * A list of the group names in the client log entries.
   */
  clientLogEntryGroupNames: string[];

  /**
   * The filtered list of client logs to display.
   */
  clientLogEntriesFiltered$: Observable<ClientLogEntryAHubVO[]>;

  /**
   * The client log entries count
   */
  clientLogEntryCount$: Observable<number>;

  /**
   * The client log entries filered count.
   */
  clientLogEntriesFilteredCount$: Observable<number>;

  /**
   * Is the client log completed?
   */
  clientLogIsCompleted$: Observable<boolean>;

  /**
   * All of the log types we can display.
   */
  logTypes: ClientLogEntryTypeAHubEnum[] = [];

  /**
   * This is the form to control the searching.
   */
  searchForm: FormGroup = this.formBuilder.group({
    searchFormControl: new FormControl('')
  });

  /**
   * These are the changes to the search terms entered.
   */
  searchFormValues$: Observable<any> = this.searchForm.valueChanges
    .pipe(
      debounceTime(200)
    );

  /**
   * This form controls the all log group filters option and is seperate
   * to the other filters so that it doesn't get picked up above.
   */
  allGroupsFiltersForm: FormGroup = this.formBuilder.group({
    "All": [true]
  });

  /**
   * The form controling the log group filter options.
   */
  groupsFilterForm: FormGroup = this.formBuilder.group({});

  /**
   * Create a stream which represents the log group filter form value changes.
   */
  groupsFilterFormValues$: Observable<any> = this.groupsFilterForm.valueChanges
    .pipe(
      debounceTime(200)
    );

  /**
   * This form controls the all log type filters option and is seperate
   * to the other filters so that it doesn't get picked up above.
   */
  allTypesFiltersForm: FormGroup = this.formBuilder.group({
    "All": [true]
  });

  /**
   * The form controling the log type filter options.
   */
  typesFilterForm: FormGroup = this.formBuilder.group({});

  /**
   * Create a stream which represents the log type filter form value changes.
   */
  typesFilterFormValues$: Observable<any> = this.typesFilterForm.valueChanges
    .pipe(
      debounceTime(200)
    );

  /**
   * No froup for the undefined names
   */
  readonly NO_GROUP_NAME = "No Group";

  /*
   * Component factory to be passed to the infinite list to generate components for the index.
   */
  readonly componentFactory: ComponentFactory<ClientLogEntryComponent> = this.resolver.resolveComponentFactory(ClientLogEntryComponent);

  constructor(
    private readonly resolver: ComponentFactoryResolver,
    private readonly dialogService: DialogService,
    private readonly fileSaverService: FileSaverService,
    private readonly formBuilder: FormBuilder,
    private permissionsService: PermissionsService
  ) { }

  ngOnInit() {

    // The first thing to do is make sure we get the client log we want to display.
    StoreAccess.dispatch(AHubActions.clientLogFetch(this.clientLogId, 0));

    // Make sure the grid is set correctly.
    StoreAccess.dispatch(ViewActions.gridViewItemsPerRowSet(1))

    //Is this user a system user?
    this.isSystemUser = this.permissionsService.currentEntityPermissions.find((permissions) =>
      permissions.entityRef === EntityRefAHubEnum.SYSTEM && permissions.permissionType !== 'NONE') !== undefined;


    //Set the log types, a user needs to be a system user to see the debug log
    this.logTypes = ClientLogEntryTypeAHubEnum.ALL_TYPES.filter(logType => logType !== ClientLogEntryTypeAHubEnum.DEBUG || this.isSystemUser);

    // Add the log types to the form.
    this.logTypes.forEach(logType => {
      this.typesFilterForm.addControl(logType.toString(), new FormControl(true))
    });

    // Now watch the client log we want.
    this.clientLog$ = StoreAccess.dataListItemGetObvs(aHubStateTemporaryClientLogs, this.clientLogId);

    // Listen for the client log to complete so we can make a request to get the data.
    this.clientLog$
      .pipe(
        takeUntil(componentDestroyStream(this)),
        filter(clientLog => clientLog && clientLog.completed),
        take(1)
      )
      .subscribe(clientLog => {

        // If we get here then ask to get the client log entries.
        StoreAccess.dispatch(AHubActions.clientLogEntriesFetch(clientLog));
      })

    //Get the client log entries
    this.clientLogEntries$ = this.clientLog$.pipe(
      map(log => (log && log.entries) ? log.entries : []),
      publishReplay(1),
      refCount()
    );

    // Listen for changes in the client log.
    this.clientLogEntries$
      .pipe(takeUntil(componentDestroyStream(this)))
      .subscribe(clientLogEntries => {

        // Update the client logs.
        this.clientLogEntriesUpdate(clientLogEntries);
      });

    // Use the filters available to reduce the log entries being displayed.
    this.clientLogEntriesFiltered$ = combineLatest([
      this.clientLogEntries$.pipe(debounceTime(25)), // Prevents Expression changed after initialisation error, also prevents mass clicking causing unnecessay traffic.,
      this.typesFilterFormValues$.pipe(startWith({})),
      this.groupsFilterFormValues$.pipe(startWith({})),
      this.searchFormValues$.pipe(startWith({}))])
      .pipe(
        debounceTime(200),
        map(([logEntries, typeFilterForm, groupFilterForm, searchForm]) => {

          //If we dont have an index list so return an empty array
          if (logEntries === undefined || logEntries === null) {
            return [];
          }

          // Get the current group selected options.
          const currentGroupFilters: string[] = this.clientLogEntryGroupNames.filter(groupName => this.groupsFilterForm.controls[groupName].value);

          // Get the current selected options.
          const currentTypeFilters: ClientLogEntryTypeAHubEnum[] = this.logTypes.filter(logLevel => this.typesFilterForm.controls[logLevel.toString()].value);

          // If we have no filters on but we do have some filters then hide this item index.
          if (currentTypeFilters.length === 0 && this.logTypes.length > 0 &&
            currentTypeFilters.length === 0 && this.clientLogEntryGroupNames.length > 0) {
            return [];
          }

          // Do the filtering of the log entries with the current filters list and return the list.
          // if the log is a debug entry is the user a system user
          return logEntries
            .filter(item => item !== null && item !== undefined)
            .filter(item => item.type !== ClientLogEntryTypeAHubEnum.DEBUG || this.isSystemUser)
            .filter(item => this.indexFilter(item, currentTypeFilters, currentGroupFilters, searchForm.searchFormControl));
        }),
        takeUntil(componentDestroyStream(this)),
        publishReplay(1),
        refCount()
      );

    //Setup the stream for the count
    this.clientLogEntryCount$ = this.clientLogEntries$.pipe(map(a => a.length));

    // Make sure we watch the length of the filtered count.
    this.clientLogEntriesFilteredCount$ = this.clientLogEntriesFiltered$.pipe(map(a => a.length));

    // Watch the client log and check it's completed or not.
    this.clientLogIsCompleted$ = this.clientLog$
      .pipe(
        takeUntil(componentDestroyStream(this)),
        filter(clientLog => clientLog !== undefined && clientLog !== null),
        map(clientLog => clientLog.completed)
      );


    // Check every x milliseconds that the client log has completed.
    // If it hasn't then we need to get the client log again.
    // If it has completed then stop checking.
    interval(this.CLIENT_LOG_REFRESH_TIMEOUT)
      .pipe(
        takeUntil(componentDestroyStream(this)),
        takeUntil(this.clientLogIsCompleted$
          .pipe(filter(isCompleted => isCompleted)))
      )
      .subscribe(data => {

        // Only make a request to get more info about the current client log if we actually
        // have data for it already. That way we aren't trying to get "completed yet" info
        // on a client log that is massive and hasn't been downloaded into memory yet.
        // This is because we were making many many calls to get more info a client log that
        // was still downloading, this then caused the user to be kicked out of the portal.
        const clientLog = StoreAccess.dataListItemGet(aHubStateTemporaryClientLogs, this.clientLogId);
        if (clientLog) {

          // Get the request offset.
          const requestOffset = (clientLog.entries) ? clientLog.entries.length : 0;

          // Now make the call to get the client log.
          StoreAccess.dispatch(AHubActions.clientLogFetch(this.clientLogId, requestOffset));
        }
      });


    //Watch the filters for change if it changes we will reset the scrolling, as if you are in the middle of the list and then you filter down to
    //just a few you need to start back at the front of the list
    this.clientLogEntriesFiltered$
      .pipe(
        pairwise(),
        filter(([listA, listB]) => (!listA || !listB || listA.length > listB.length))
      )
      .subscribe(() => {
        if (this.infinityScroller) {
          this.infinityScroller.scrollerReset();
        }
      });

    // Make sure we update the all filters option.
    this.updateAllFiltersValue();
  }

  /**
   * Empty On destroy to ensure @Hark decorator works for an AOT build
   */
  ngOnDestroy() { }

  moreInfoToggle() {
    this.activeToggle = !this.activeToggle;
  }


  /**
   * Update the client log entries with those passed in.
   * 
   * @param clientLogEntries        The entries to update too.
   */
  clientLogEntriesUpdate(clientLogEntries: ClientLogEntryAHubVO[]) {

    // Do we have a list of group names? If so, remove all of the current controls.
    if (this.clientLogEntryGroupNames) {
      this.clientLogEntryGroupNames.forEach(logName => this.groupsFilterForm.removeControl(logName));
    }

    // Clear down the group names.
    this.clientLogEntryGroupNames = [];

    // Stop here if we have no client entries yet.
    if (!clientLogEntries) {
      return;
    }

    // Now create the new form controls.
    clientLogEntries.sort((clientLogA, clientLogB) => sortDateOldest(clientLogA.loggedTime, clientLogB.loggedTime));

    // Repeat through the client log entries.
    clientLogEntries
      .filter(clientLogEntry => clientLogEntry !== null && clientLogEntry !== undefined)
      .forEach(clientLogEntry => {

        // Get the group name, or no group if we don't have one.
        const name = clientLogEntry.groupName ? clientLogEntry.groupName : this.NO_GROUP_NAME;

        // Don't add duplicates.
        if (this.clientLogEntryGroupNames.find(groupName => groupName === name)) {
          return;
        }

        // Add the name to the list.
        this.clientLogEntryGroupNames.push(name);

        // Add the control to the form.
        this.groupsFilterForm.addControl(name, new FormControl(true));
      });
  }

  /**
   * This function will return true if the log entry should remain on screen.
   *
   * @param logEntry                  The log entry to check.
   * @param currentTypeFilters        The currently selected type filters.
   * @param toSearchOn                The string to search on.
   */
  private indexFilter(logEntry: ClientLogEntryAHubVO, currentTypeFilters: ClientLogEntryTypeAHubEnum[], currentGroupFilters: string[], toSearchOn: string): boolean {

    // If the log entries type isn't on the list then we return return false.
    if (currentTypeFilters.indexOf(logEntry.type) === -1) {
      return false;
    }

    // If the log entries type isn't on the list then we return return false.
    if ((!logEntry.groupName && currentGroupFilters.indexOf(this.NO_GROUP_NAME) === -1) ||
      (logEntry.groupName && currentGroupFilters.indexOf(logEntry.groupName) === -1)) {
      return false;
    }

    // Create a string for the log entry to search with.
    const searchString = logEntry.groupName + " " + logEntry.subGroupName + " " + logEntry.message;

    // If we get here then call the search utilies to do the work.
    return SearchUtils.stringSearch(toSearchOn, [searchString]);
  }

  /**
   * This handler is called when the user clicks the download button.
   */
  downloadHandler() {

    // Start by getting the client log.
    const clientLog = StoreAccess.dataListItemGet(aHubStateTemporaryClientLogs, this.clientLogId)

    // Get the client log entries.
    let clientLogEntries = (clientLog) ? clientLog.entries : [];

    // Now stop here if the entries list is empty.
    if (!clientLogEntries || clientLogEntries.length === 0) {
      return;
    }

    // Filter out the entries that are not in the log types we are allowing the user to view.
    clientLogEntries = clientLogEntries.filter(clientLogEntry => this.logTypes.find(logType => logType === clientLogEntry.type));

    // Get the factory to create a new dialogue to allow the user to choose a file name.
    const fileSaveDialogComponentFactory: ComponentFactory<FileSaveDialogComponent> = this.resolver.resolveComponentFactory(FileSaveDialogComponent);

    // Create a suggested file name.
    const suggestedFileName = `${clientLog.id}-${clientLog.processName}-${"log"}`;

    // Create a new client.
    const dialogVO = {
      fileName: suggestedFileName,
      fileExtension: "txt"
    }
    // Open the custom component dialogue , using the component factory to create the component content, passing in a blank VO.
    const newDialogue = this.dialogService.componentDialogOpen("Save As", fileSaveDialogComponentFactory, "dialogVO", dialogVO, null, "Save", "Cancel");

    //Subscribe to the new export dialogue, if we recive an export then we will add it
    newDialogue.subscribe((dialogVO) => {

      //If the new VO is undefined then we will bail out
      if (!dialogVO) {
        return;
      }

      // Set up the time options.
      const options = { hour12: false };

      // Now we need to construct a string that will be saved out locally.
      const saveString = clientLogEntries
        .filter(clientLogEntry => clientLogEntry !== undefined && clientLogEntry !== null)
        .map(clientLogEntry =>
          clientLogEntry.type + " " +
          moment(clientLogEntry.loggedTime).format("D MMM YYYY") + " " +
          clientLogEntry.loggedTime.toLocaleTimeString('en-US', options) + " - " +
          clientLogEntry.groupName + " - " +
          clientLogEntry.subGroupName + " - " +
          clientLogEntry.message).join("\r\n");

      // Now save out the client log entries string.
      // Now save the file.
      this.fileSaverService.fileStringDataSave(saveString, dialogVO.fileName + ".txt", "application/txt");
    });
  }

  /**
   * This function will turn on all of the filters if one or more are unselected,
   * otherwise it will turn them all off.
   */
  filterAllTypesToggle(event) {

    // Are there any filter options turned off?
    const turnedOffFilter = this.logTypes.find(logType => (this.typesFilterForm.controls[logType.toString()].value === false));

    // If so, we want to turn them all on, otherwise off.
    const targetValue: boolean = turnedOffFilter !== undefined;

    // Set the filter on to true on all of the filter options.
    if (this.logTypes && this.logTypes.length > 0) {
      this.logTypes.forEach(logType => this.typesFilterForm.controls[logType.toString()].setValue(targetValue));
    }
    // Stop the box from closing.
    event.stopPropagation();
  }

  /**
   * This function will turn on all of the group filters if one or more are unselected,
   * otherwise it will turn them all off.
   */
  filterAllGroupsToggle(event) {

    // Are there any filter options turned off?
    const turnedOffFilter = this.clientLogEntryGroupNames.find(groupName => (this.groupsFilterForm.controls[groupName].value === false));

    // If so, we want to turn them all on, otherwise off.
    const targetValue: boolean = turnedOffFilter !== undefined;

    // Set the filter on to true on all of the filter options.
    if (this.clientLogEntryGroupNames && this.clientLogEntryGroupNames.length > 0) {
      this.clientLogEntryGroupNames.forEach(groupName => this.groupsFilterForm.controls[groupName].setValue(targetValue));
    }
    // Stop the box from closing.
    event.stopPropagation();
  }

  /**
   * This function is called when the user clicks on the filter option.
   *
   * @param event       The event that caused this handler to be called.
   */
  filterOptionClick(event) {

    // Stop the box from closing.
    event.stopPropagation();
  }

  /**
   * This function is called when the toggle option value changes.
   */
  filterOptionChangeHandler() {

    // Make sure we update the all filters option.
    this.updateAllFiltersValue();
  }

  /**
   * This function will update the all filters value.
   */
  private updateAllFiltersValue() {

    // Look for an instance of a types filter that's been turned off.
    const turnedOffTypesFilter = this.logTypes.find(logType => (this.typesFilterForm.controls[logType.toString()].value === false));

    // Turn the All filter if we cannot find any that are off.
    this.allTypesFiltersForm.controls["All"].setValue(turnedOffTypesFilter === undefined);

    // Do we have log entry group names?
    if (this.clientLogEntryGroupNames) {

      //  Look for an instance of a groups filter that's been turned off.
      const turnedOffGroupsFilter = this.clientLogEntryGroupNames.find(logGroupName => (this.groupsFilterForm.controls[logGroupName].value === false));

      // Turn the All filter if we cannot find any that are off.
      this.allGroupsFiltersForm.controls["All"].setValue(turnedOffGroupsFilter === undefined);
    }
  }

  /**
   * Convert the type to a friendlier label
   *
   * @param logType
   */
  logTypeFilterLabel(logType: string) {
    return ClientLogEntryTypeAHubEnum.typeToLabel(logType);
  }
}

import { DevToolsExtension, NgRedux, NgReduxModule } from '@angular-redux/store';
import { CommonModule, Location } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import {
  MatSliderModule // TEMP WORKAROUND: https://github.com/angular/material2/issues/4595
} from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from 'environments/environment';
import { RootEpic } from 'epics';
import { EmptyComponent } from 'modules/common/components/empty-component/empty.component';
import { DialogModule } from 'modules/common/dialogs/dialog.module';
import { DialogService } from 'modules/common/dialogs/dialog.service';
import { UnsavedChangesComponentCheck } from 'modules/common/unsaved-changes-component-check';
import { SharedModule } from 'modules/shared/shared.module';
import { rootReducer, State } from 'reducers';
import { LoggedInGuard } from 'router/guards/logged-in-guard';
import { NotLoggedInGuard } from 'router/guards/not-logged-in-guard';
import { PermissionGuard } from 'router/guards/permission-guard';
import { UnsavedChangesGuard } from 'router/guards/unsaved-changes.guard';
import { StoreClearResolver } from 'router/resolver/store-clear.resolver';
import { FileSaverService } from 'services/file-saver/file-saver.service';
import { httpInterceptorProviders } from 'services/http/interceptors';
import { AhubAuthInterceptorUtilsService } from 'services/http/interceptors/ahub-auth-interceptor-utils.service';
import { ObjectStoreService } from 'services/object-store/object-store.service';
import { PermissionsService } from 'services/permissions/permissions.service';
import { RequestActionMonitorService } from 'services/request-action-monitor/request-action-monitor.service';
/**
 * Services
 */
import { RouterService } from 'services/router/router.service';
import { RssReaderService } from 'services/rss-reader/rss-reader.service';
import { StoreLocalStorage } from 'store/store-local-storage/store-local-storage';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterModule } from './modules/footer/footer.module';
import { SidenavModule } from './modules/sidenav/sidenav.module';
import { ExternalRedirectGuard } from './router/guards/external-redirect-guard';
/**
 * Guards & Resolvers
 */
import { LoginTokenManagerGuard } from './router/guards/login-token-manager.guard';
import { UrlRedirectGuard } from './router/guards/url-redirect.guard';

import { CookieService } from 'ngx-cookie-service';

@NgModule({
  declarations: [
    AppComponent,
    EmptyComponent
  ],
  imports: [
    CommonModule,
    NgReduxModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SidenavModule,
    FooterModule,
    DialogModule,
    MatSidenavModule,
    MatDialogModule,
    MatSnackBarModule,
    MatSliderModule,
    MatIconModule,
    SharedModule,
    BrowserAnimationsModule,
    AppRoutingModule,
  ],
  entryComponents: [],

  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    Location,
    RootEpic,
    RouterService, RequestActionMonitorService, PermissionsService, /** Services */
    DialogService,
    FileSaverService,
    RssReaderService,
    ObjectStoreService,
    LoginTokenManagerGuard,
    UnsavedChangesGuard,
    LoggedInGuard,
    NotLoggedInGuard,
    PermissionGuard,
    StoreClearResolver,
    UrlRedirectGuard,
    ExternalRedirectGuard, /**Guards & Resolvers */
    UnsavedChangesComponentCheck,
    AhubAuthInterceptorUtilsService,
    httpInterceptorProviders,
    CookieService,
    { provide: ErrorHandler, useClass: environment.errorHandler }
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule {

  /**
   * This is the instance of the store local storage class that will
   * maintain a record of the necessary store properties in the local
   * storage.
   */
  private readonly storelocalStorage: StoreLocalStorage;

  constructor(
    private readonly ngRedux: NgRedux<State>,
    private readonly rootEpic: RootEpic,
    private readonly devTool: DevToolsExtension,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer
  ) {

    //Configure the store
    this.storeConfiguration();

    // Create an instance of the store local storage now because the store was created
    // on the line above. This also means that the local storage is loaded in before the
    // logged-in guard is called.
    this.storelocalStorage = new StoreLocalStorage();

    // Additional Mat Icons
    matIconRegistry.addSvgIcon(
      `hark_filter`,
      domSanitizer.bypassSecurityTrustResourceUrl("./assets/mat_icon_extentions/filter.svg")
    );
    matIconRegistry.addSvgIcon(
      `compare`,
      domSanitizer.bypassSecurityTrustResourceUrl("./assets/mat_icon_extentions/compare.svg")
    );
    matIconRegistry.addSvgIcon(
      `file-settings`,
      domSanitizer.bypassSecurityTrustResourceUrl("./assets/mat_icon_extentions/file-settings.svg")
    );
    matIconRegistry.addSvgIcon(
      `distribution`,
      domSanitizer.bypassSecurityTrustResourceUrl("./assets/mat_icon_extentions/distribution-icon.svg")
    );
    matIconRegistry.addSvgIcon(
      `usage`,
      domSanitizer.bypassSecurityTrustResourceUrl("./assets/mat_icon_extentions/usage.svg")
    );
    matIconRegistry.addSvgIcon(
      `account`,
      domSanitizer.bypassSecurityTrustResourceUrl("./assets/mat_icon_extentions/account.svg")
    );
    matIconRegistry.addSvgIcon(
      `client-library`,
      domSanitizer.bypassSecurityTrustResourceUrl("./assets/mat_icon_extentions/client-library.svg")
    );

    matIconRegistry.addSvgIcon(
      `knowledgebase`,
      domSanitizer.bypassSecurityTrustResourceUrl("./assets/mat_icon_extentions/knowledgebase.svg")
    );

    matIconRegistry.addSvgIcon(
      `file-tree`,
      domSanitizer.bypassSecurityTrustResourceUrl("./assets/mat_icon_extentions/file-tree.svg")
    );

    matIconRegistry.addSvgIcon(
      `collapse`,
      domSanitizer.bypassSecurityTrustResourceUrl("./assets/mat_icon_extentions/collapse.svg")
    );
    matIconRegistry.addSvgIcon(
      `queue`,
      domSanitizer.bypassSecurityTrustResourceUrl("./assets/mat_icon_extentions/queue.svg")
    );
    matIconRegistry.addSvgIcon(
      `clock-fast`,
      domSanitizer.bypassSecurityTrustResourceUrl("./assets/mat_icon_extentions/clock-fast.svg")
    );
  }



  /**
   * Configure the store ready for use by the application
   */
  private storeConfiguration() {

    /**
     * Configure the ng-redux store for the application
     *
     * 1 - Root reducer - this should link to all the reduxers
     * 2 - Initial application State
     * 3 - Array of redux-middleware
     * 4 - Enhancers for the store
     */
    const epicMiddleware = this.rootEpic.epicMiddlewareGet();

    this.ngRedux.configureStore(
      rootReducer,
      {},
      [epicMiddleware],
      [this.devTool.isEnabled() ? this.devTool.enhancer() : f => f]);

    this.rootEpic.epicMiddlewareInitialise(epicMiddleware);
  }

}

import { Injectable } from "@angular/core";
import { StoreAccess } from "store/store-access";
import { actionsQueue } from "selector/app.selector";
import { ActionWork } from "actions/types/work.action-types";
import { AppActions } from "actions/app.actions";
import { RequestActionMonitorService } from "services/request-action-monitor/request-action-monitor.service";


@Injectable()
export class ActionQueueService {

    /**
     * Maximum amount of simultanious executing
     */
    private MAX_QUEUED_ACTION: number = 2;

    /**
     * Currently active actions
     */
    private activeActions: ActionWork[] = [];

    /**
     * Represents the current queue from the store.
     * we could include this as part of a stream but its easier
     * to do it like this
     */
    private currentQueue: ActionWork[] = [];

    /**
     * Last time we sent an action
     */
    private lastActionSentTime: number = 0;

    constructor(private requestActionMonitorService: RequestActionMonitorService) {

        //Call the monitor queued actions
        this.monitorQueuedActions();
    }

    /**
     * Monitor the queued actions
     */
    private monitorQueuedActions() {

        //Subscribe to the store for the action queue
        StoreAccess.dataGetObvs(actionsQueue)
            .subscribe(queue => {

                //Set the current queue
                this.currentQueue = queue;

                //Check the queue
                this.checkQueue();
            });
    }

    /**
     * Check the queue to see if we can start any new actions
     */
    private checkQueue() {

        //If the current active actions are at capacity or there is nothing to do ... bail
        if (this.activeActions.length >= this.MAX_QUEUED_ACTION || this.currentQueue.length == 0) return;

        //Take the first item off a list
        let nextAction = this.currentQueue.shift();

        //Add the item to the list
        this.activeActions.push(nextAction);

        //Remove this action from the queue
        StoreAccess.dispatch(AppActions.actionQueuedRemove([nextAction.actionId]));

        //Dispatch the next action
        StoreAccess.dispatch(nextAction);

        //Get the worklog stream by the action id but we will filter this down to item which have started
        let actionStream$ = this.requestActionMonitorService.requestActionStatusObservableByActionId(nextAction.actionId);

        //We will watch the action for having started. Once it has started we will consider it done and remove it from our action queue
        let actionStreamSub = actionStream$
            .subscribe(action => {

                //Do we have a fault?
                //Has we flagged the upload complete signal?
                if (action.fault || (action.upload && action.upload.uploadCompleteSignaled)) {

                    //OK we are done with this action so we will remove it from the stream
                    this.activeActions = this.activeActions.filter(act => act.actionId != action.actionId);

                    //Un-sub from the stream
                    actionStreamSub.unsubscribe();

                    //As we have more room lets check for some actions to execute
                    this.checkQueue();
                }

            }, (err) => console.log("Err, ", err),
                () => console.log("Stream Done!!!"));

        //We could have more room to execute more workflow's so lets check
        this.checkQueue();
    }
}
import { Component, AfterViewInit, ViewContainerRef, ViewChild } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { GridEditorParamsInterface } from '../grid-editor-params-interface';

@Component({
  selector: 'app-grid-editor-text-validated',
  templateUrl: './grid-editor-text-validated.component.html',
  styleUrls: ['./grid-editor-text-validated.component.css']
})
export class GridEditorTextValidatedComponent implements ICellEditorAngularComp, AfterViewInit {

  /**
   * Value set
   */
  @ViewChild('valueInput', { read: ViewContainerRef, static: true }) public valueInput;

  /**
   * Paramteres for this renderer
   */
  params: ICellEditorParams = undefined;
  paramsCustom: GridEditorTextValidatedParams = undefined;

  /**
   * The original value passed to this function
   */
  originalValue;

  /**
   * Value which is part of the input
   */
  value: string;

  /**
   * Is the data currently in an errored state
   */
  isError = false;

  /**
   * Tooltip for the error message
   */
  errorTooltip = undefined;

  constructor() {
    // This is intentional
  }

  /**
   * After the view has been initalised we want to gain focus
   */
  ngAfterViewInit() {

    //After a timeout we will focus on the input
    setTimeout(() => {
      this.valueInput.element.nativeElement.focus();
    }, 200);
  }

  /**
   * Init of the renderer
   */
  agInit(params: any): void {

    //Set the parameters
    this.params = params as ICellEditorParams;
    this.paramsCustom = params as GridEditorTextValidatedParams;

    //Set the value which is currently set
    this.originalValue = this.params.value;
    this.value = this.params.value;

    //Evaluate if we have an error or not
    this.evaluateError();
  }

  /**
   * Get the currently selected value
   */
  getValue(): any {

    //When we call the get value if its errored we will just return the original value
    //if its valid then its all good
    return (this.isError) ? this.originalValue : this.value;
  }

  /**
   * This is a popup so thats that
   */
  isPopup(): boolean {
    return false;
  }

  /**
   * If the value of the input has changed we need to re-evaluate the input
   */
  onKeyUpHandler(event) {

    //Evaluate if we have an error or not
    this.evaluateError();
  }

  /**
   * We wish to override the default behaviour of the
   * enter button during cell editing
   */
  enterHandler(event) {

    //Evaluate if we have an error or not
    this.evaluateError();

    //If the data is errored then we will prevent propigation
    //as this value is not currently valid and therefor should not be commited
    if (this.isError) {
      event.stopPropagation();
    }
  }

  /**
   * Is called when editing is about to start. Giving
   * us an opertunity to stop the editing before it happens
   */
  isCancelBeforeStart(): boolean {

    //Do we have a supplied cancel function in the parameters
    if (this.paramsCustom.canEditCellFunc) {
      return !this.paramsCustom.canEditCellFunc(this.params);
    }

    //No is cancel function so no cancel behaviour
    return false;
  }

  /**
   * Event triggered by the arrow
   */
  onKeyDownHandler(event) {

    //Key code which we want from the event
    const keyCode = event.code;

    //If we press the arrow keys then we want to stop propergation or we will not
    //be able to scroll within the editor
    if (keyCode === "ArrowLeft" || keyCode === "ArrowRight" || keyCode === "Home" || keyCode === "End") {
      event.stopPropagation();
    }
  }

  /**
   * Evaluate if we currently have an error in the data
   */
  evaluateError() {

    //No parameters bail out
    if (!this.paramsCustom) {
      return;
    }

    //If we have a validation function then we will call the function to evaluate it
    if (this.paramsCustom.dataValidationFunc) {
      this.isError = this.paramsCustom.dataValidationFunc(this.value);
    }

    //If we have an tool tip function call it
    if (this.paramsCustom.dataValidationTooltipFunc) {
      this.errorTooltip = this.paramsCustom.dataValidationTooltipFunc(this.value);
    }
  }
}

/**
 * Parameters class which shows what we can pass into the editor
 */
export interface GridEditorTextValidatedParams extends GridEditorParamsInterface {
  dataValidationFunc: (val: any) => boolean;
  dataValidationTooltipFunc: (val: any) => string;
}

import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.css']
})
export class AlertDialogComponent implements OnInit {

  /**
   * This is the title and content to display in this confirmation dialog.
   */
  public title: string;
  public content: string;

  /**
   * These are the labels to be used on the buttons.
   */
  public confirmButtonLabel: string;


  constructor(public dialogRef: MatDialogRef<AlertDialogComponent>) {
  }

  ngOnInit() {
  }

}

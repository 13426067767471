import { Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren, Output, EventEmitter } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Hark } from 'modules/common/hark.decorator';
import { combineLatest, fromEvent, interval, Observable, BehaviorSubject } from 'rxjs';
import { filter, repeat, takeUntil, tap, switchMap } from 'rxjs/operators';
import { isEqual } from 'lodash';

@Component({
  selector: 'app-product-asset-view',
  templateUrl: './product-asset-view.component.html',
  styleUrls: ['./product-asset-view.component.scss']
})
@Hark()
export class ProductAssetViewComponent implements OnInit {

  @ViewChild(MatMenuTrigger, { static: false }) menuTrigger: MatMenuTrigger;
  @ViewChildren("previewImage") previewImageQueryList: QueryList<any>;
  @ViewChild('hoverable', { static: false }) hoverable: ElementRef;


  imageNotAvailableImage = '/assets/images/imageNotAvailable.png';

  // Local variable for passed in asset view params
  _productAssetViewParam: ProductAssetViewParamsVO;

  // Flag to let us know that the asset urls are now multiple, so we should change how we display them (if we are hovering ;)
  imagePreviewUrlsUpdatedToArray$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  imageLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  @Input() tooltip = '';

  /**
   * Input which describes the values required to view all the data
   */
  @Input() set productAssetViewParam(productAssetViewParam: ProductAssetViewParamsVO) {


    this.setNoImageAvailableOrDraggableUploadDefaultImage(productAssetViewParam, this.draggableUpload);

    // This must be a flickbook so lets get ready to be able to cycle the images when the user hovers
    if (productAssetViewParam &&
      productAssetViewParam.assetImagePreviewURL &&
      productAssetViewParam.assetImagePreviewURL.length > 1 &&
      productAssetViewParam.assetImagePreviewURL) {
      this.imagePreviewUrlsUpdatedToArray$.next(true);
    }

    this._productAssetViewParam = productAssetViewParam;

  }

  @Input() draggableUpload = false;

  @Output() hovering: EventEmitter<any> = new EventEmitter(false);

  noPreviewImage = false;


  /**
   * Count of how many times we have tried to load the image
   */
  private imageLoadRetryCount = 0;

  /**
   * Index of the current asset which we are displaying
   */
  private assetImageIndex = 0;

  // Triggers when user is 'hovering' this component if this is a multi image asset
  mouseEnterMultiImageAsset$;
  mouseLeaveMulitImageAsset$;

  constructor() {
    // This is intentional
  }

  ngOnInit() {

    this.setNoImageAvailableOrDraggableUploadDefaultImage(this._productAssetViewParam, this.draggableUpload);

  }

  /**
   * Re-evalutae this after it's been checked
   */
  ngAfterViewInit(): void {

    // this.mouseEnter$ = fromEvent(this.hoverable.nativeElement, "mouseenter");
    this.mouseEnterMultiImageAsset$ = combineLatest([
      fromEvent(this.hoverable.nativeElement, "mouseenter").pipe(
        tap(hovering => this.hovering.emit(true))
      ),
      this.imagePreviewUrlsUpdatedToArray$.pipe(
        filter(weHaveMultipleAssetUrls => weHaveMultipleAssetUrls)
      )
    ]);

    this.mouseLeaveMulitImageAsset$ = fromEvent(this.hoverable.nativeElement, "mouseleave").pipe(
      tap(hovering => this.hovering.emit(false))
    );

    this.mouseEnterMultiImageAsset$.pipe(
      switchMap(() => interval(Math.max(200, this._productAssetViewParam.assetImageChangeIntervalMil ? this._productAssetViewParam.assetImageChangeIntervalMil : 0) /* ms */)),
      takeUntil(this.mouseLeaveMulitImageAsset$),
      repeat(),
    ).subscribe(event => {
      //Up the asset image index
      this.assetImageIndex++;

      //If the asset index has gone off the end then we will reset the index to zero
      if (this.assetImageIndex >= this._productAssetViewParam.assetImagePreviewURL.length) {
        this.assetImageIndex = 0;
      }

      //Re-evalutate the current visible image
      this.evaluateVisibleImage();
    });



  }

  /**
   * Empty On destroy to ensure @Hark decorator works for an AOT build
   */
  ngOnDestroy() { }

  /**
   * Handler for the left icon click handler
   */
  leftIconButtonClickHandler() {

    //Stop event propogation
    event.stopPropagation();

    //Do we have all the data to call the callback function
    if (this._productAssetViewParam && this._productAssetViewParam.leftIconClickFunc) {
      this._productAssetViewParam.leftIconClickFunc(this._productAssetViewParam);
    }
  }


  /**
   * Handler for the right icon click handler
   */
  rightIconButtonClickHandler() {

    //Stop event propogation
    event.stopPropagation();

    //Do we have all the data to call the callback function
    if (this._productAssetViewParam && this._productAssetViewParam.rightIconClickFunc) {
      this._productAssetViewParam.rightIconClickFunc(this._productAssetViewParam);
    }
  }

  /**
   * Close the menu!
   */
  closeMenu() {
    this.menuTrigger.closeMenu();
  }

  /**
   * Evaluate the currently visible asset
   */
  evaluateVisibleImage() {

    //Convert the query list into an array
    let elementsAsArray = this.previewImageQueryList.toArray();

    //Make all the asset hidden
    elementsAsArray.forEach(previewImage => {
      if (previewImage.nativeElement) {
        previewImage.nativeElement.style.display = 'none';
      }
    });

    //Set the specific asset to hidden
    if (elementsAsArray[this.assetImageIndex] && elementsAsArray[this.assetImageIndex].nativeElement) {
      elementsAsArray[this.assetImageIndex].nativeElement.style.display = 'block';
    }
  }

  /**
   * Handel the drop of content on the asset item render
   */
  dragdropHandler(event) {

    //If we don't have any parameters then we need to bail out
    if (!this._productAssetViewParam || !this._productAssetViewParam.dragdropHandler) {
      return;
    }

    //Call the drop handler with our drop event and data
    this._productAssetViewParam.dragdropHandler(event, this._productAssetViewParam);
  }

  /**
   * Handel the click of the menu button
   */
  menuButtonClickHandler(event) {

    //Stop propigation
    event.stopPropagation();

    //Do we have a menu button click function, then we should call it
    if (this._productAssetViewParam.menuButtonClickButtonModeFunc) {
      this._productAssetViewParam.menuButtonClickButtonModeFunc(this._productAssetViewParam);
    }
  }

  /**
   * Function to check the button visibility
   *
   * @param button
   */
  checkButtonVisability(button: ProductAssetViewParamsMenuButtonsVO): boolean {

    //Check the visibility for this button
    if (button && button.buttonCheckVisible) {
      return button.buttonCheckVisible(this._productAssetViewParam);
    }

    //Value is good
    return true;
  }

  /**
   * Handels the components click event
   */
  componentClickHandler(event) {

    //Stop propigation
    event.stopPropagation();

    //Do we have a component click handler
    if (this._productAssetViewParam && this._productAssetViewParam.componentClickHandler) {

      this._productAssetViewParam.componentClickHandler(event, this._productAssetViewParam);
    }
  }

  setNoImageAvailableOrDraggableUploadDefaultImage(productAssetViewParam: ProductAssetViewParamsVO, draggableUpload: boolean) {

    if (!draggableUpload && productAssetViewParam && (!productAssetViewParam.assetImagePreviewURL || productAssetViewParam.assetImagePreviewURL.length === 0)) {
      productAssetViewParam.assetImagePreviewURL = ['/assets/images/imageNotAvailable.png'];
      this.noPreviewImage = true;
    }

    if (draggableUpload && productAssetViewParam && (!productAssetViewParam.assetImagePreviewURL || productAssetViewParam.assetImagePreviewURL.length === 0)) {
      productAssetViewParam.assetImagePreviewURL = ['/assets/images/imageNotAvailableUpload.png'];
      this.noPreviewImage = true;
    }

    // If This has image assets, lets show a loading spinner until the image(s) loaded
    // If theres no change to the urls for the preview images, we need not go any further
    if (productAssetViewParam.assetImagePreviewURL.length > 0) {

      if (this._productAssetViewParam && isEqual(productAssetViewParam.assetImagePreviewURL, this._productAssetViewParam.assetImagePreviewURL)) {
        return;
      } else {
        this.imageLoading$.next(true);
      }

    }

  }

  /**
   * Triggers when the img element has successfully loaded
   */
  imageLoaded() {
    this.imageLoading$.next(false);
  }


  /**
   * Failed to load image ... then we will try again
   *
   * @param event
   */
  imageLoadFailed(event) {

    //Check we haven't tired too many times
    if (this.imageLoadRetryCount > 10) {
      return;
    }

    //Increase the image retry count
    this.imageLoadRetryCount++;

    //Get the image source
    const originalImageSrc = event.target.src;

    //Set to the no image image to improve the view
    event.target.src = this.imageNotAvailableImage;

    setTimeout(() => {
      event.target.src = originalImageSrc;
    }, this.imageLoadRetryCount * 1000);
  }

  /**
   * Get the loading type we want to use for the images
   *
   * @param imageIndex
   */
  getImageLoadType(imageIndex: number) {
    return (imageIndex === 0) ? 'lazy' : 'auto';
  }
}

/**
 * Definition of what the asset renderer would like to display
 */
export interface ProductAssetViewParamsVO {
  backgroundImageGetRelativePathFunc: (assetData: ProductAssetViewParamsVO) => string;
  leftIcon?: string;
  leftIconColour?: string;
  leftIconTooltip?: string;
  leftIconClickFunc?: (assetData: ProductAssetViewParamsVO) => void;
  rightIcon?: string;
  rightIconColour?: string;
  rightIconTooltip?: string;
  rightIconClickFunc?: (assetData: ProductAssetViewParamsVO) => void;
  assetImagePreviewURL: string[];
  assetImageChangeIntervalMil?: number;
  componentClickHandler?: (event, assetData: ProductAssetViewParamsVO) => void;
  dragdropHandler?: (event, assetData: ProductAssetViewParamsVO) => void;
  busyIndicatorState: 'none' | 'determinate' | 'indeterminate';
  busyIndicatorIcon?: string;
  busyIndicatorProgress?: Observable<number>;
  sectionName: string;
  sectionColour: string;
  propertyName: string;
  data?: any;
  menuButtonCheckVisible: (assetData: ProductAssetViewParamsVO) => boolean;
  menuButtonDisplay: 'none' | 'menu' | 'button';
  menuButtonIcon?: string;
  menuButtonClickButtonModeFunc?: (assetData: ProductAssetViewParamsVO) => void;
  menuButtons?: ProductAssetViewParamsMenuButtonsVO[];
  hideAssetDetails?: boolean;
  alwaysShowRotationalImages?: boolean;
}

/**
 * Definition of an asset button
 */
export interface ProductAssetViewParamsMenuButtonsVO {
  buttonName: string;
  buttonIcon: string;
  buttonTooltip?: string;
  buttonDisabled?: boolean;
  buttonCheckVisible?: (assetData: ProductAssetViewParamsVO) => boolean;
  buttonClickFunc?: (assetData: ProductAssetViewParamsVO) => void;
}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-no-content-placeholder',
  templateUrl: './no-content-placeholder.component.html',
  styleUrls: ['./no-content-placeholder.component.scss']
})
export class NoContentPlaceholderComponent implements OnInit {

  constructor() {
    // This is intentional
  }

  @Input() placeHolder = "No Data To Display.";

  ngOnInit() {
  }

}

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AppRoutingNavigation } from 'app/app-routing-navigation';
import { sessionUserSessionCredentials } from 'selector/app.selector';
/**
 * Store access
 */
import { StoreAccess } from 'store/store-access';
/**
 * Value objects.
 */
import { UserSessionCredentialsAHubVO } from 'valueObjects/ahub/accounts/user-session-credentials.ahub.vo';

@Injectable()
/**
 * Class which will guard against navigating when not logged in.
 */
export class NotLoggedInGuard implements CanActivate {

    constructor(private readonly router: Router) { }

    /**
     * Can we activate this route?
     */
    canActivate() {

        // Are we logged in?
        const loggedIn = this.loggedInTest();

        // Are we logged in? If not, send the user to the log in route.
        if (loggedIn) {
            AppRoutingNavigation.navigateWelcome(this.router, false);
        }

        // Now return the logged in state.
        return !loggedIn;
    }


    /**
     * Are we logged in?
     */
    private loggedInTest(): boolean {

        // Get the current session information.
        const sessionUserCredentials: UserSessionCredentialsAHubVO = StoreAccess.dataGet(sessionUserSessionCredentials);

        // Are the credentials missing any properties? If so, return false.
        if (!sessionUserCredentials ||
            sessionUserCredentials.sessionId === undefined ||
            sessionUserCredentials.sessionKey === undefined ||
            sessionUserCredentials.userEmail === undefined ||
            sessionUserCredentials.sessionExpiry === undefined) {
            return false;
        }

        // If we get here then return true as we must be logged in.
        return true;
    }
}

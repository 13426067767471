/**
 *----------------------------------------
 * Core + Util Imports
 *----------------------------------------
 */

/**
 *----------------------------------------
 * Action Types Imports
 *----------------------------------------
 */
import { AHubActions } from 'actions/ahub.actions';
import { ActionSystemStatusArrayAHubVO } from 'actions/types/ahub-system.action-types';
import { ActionIdItem, ActionNumberArray, ActionNumber, ActionNumberNumbersArray, ActionNumberNumber, ActionStringNumber } from 'actions/types/common.action-types';
import {
  ActionClientAHubVOs,
  ActionClientConfigurationAHubVO,
  ActionClientIndexAHubVOs,
  ActionClientLibraryAHubVOs,
  ActionClientLibraryIndexAHubVOs,
  ActionClientLibraryVersionAHubVOs,
  ActionClientLibraryVersionIndexAHubVOs,
  ActionClientQuotaAHubVO,
  ActionClientQuotasAHubVOs,
  ActionDistributionAHubVOs,
  ActionDistributionGroupAHubVOs,
  ActionDistributionGroupIndexAHubVOs,
  ActionExportAHubVOs,
  ActionExportDistributionAHubVOs,
  ActionExportIndexAHubVOs,
  ActionRelationshipAHubVO,
  ActionUserExtendedAHubVOs,
  ActionUserIndexAHubVO,
  ActionWorkGroupAHubVOs,
  ActionWorkGroupIndexAHubVOs,
  ActionExporterIndexAHubVOs,
  ActionExporterAHubVOs,
  ActionUserAHubVOs
} from 'actions/types/ahub-accounts.action-types';
import {
  ActionDataSetAHubVOs,
  ActionDataSetIndexAHubVOs,
  ActionExtractAHubVOs,
  ActionExtractDefinitionAHubVOs,
  ActionExtractDefinitionIndexAHubVOs,
  ActionExtractHistoryIndexAHubVOs,
  ActionExtractIndexAHubVOs,
  ActionProductAssetAHubVOs,
  ActionProductClassAHubVOs,
  ActionProductClassIndexAHubVOs,
  ActionProductClassProductCountAHubVOs,
  ActionProductPropertyAHubVOs,
  ActionProductPropertyAllocationIndexAHubVOs,
  ActionProductPropertyIndexAHubVOs,
  ActionProductPropertySectionAHubVOs,
  ActionProductPropertySectionIndexAHubVOs,
  ActionProductPropertyTypesAHubVOs,
  ActionProductPropertyAllocationChainIndexAHubVOs,
  ActionProductPropertyAllocationChainAHubVOs,
  ActionDataSetCategoryAHubVOs,
  ActionNumberProductNumbersArrayAHubVOs,
  ActionResourcePackIndexesAHubVO,
  ActionResourcePackAHubVOs,
  ActionExporterBuildHistoryIndexesAHubVO,
  ActionExporterBuildHistoryAHubVOs,
  ActionExtractAssetMatchArray,
  ActionExtractProductPaginationPage,
  ActionProductPropertyCompletenessAHubVO,
  ActionProductVOsWithId,
  ActionProductAssetVOsWithId,
  ActionClientLogAHubVO
} from 'actions/types/library.action-types';
import { unionBy } from 'lodash';
import { tassign } from 'modules/common/type-assign.util';
import { createReducer } from 'reducers/reducer-util';
import { Action, Reducer } from 'redux';
import { IdItemVO } from 'store/id-item.vo';
import { IdListUtil } from 'store/id-list.util';
import { ListUtil } from 'store/list.util';
import { List } from 'store/list.vo';
import { ClientConfigurationAHubVO } from 'valueObjects/ahub/accounts/client-configuration.ahub.vo';
import { ClientIndexAHubVO } from 'valueObjects/ahub/accounts/client-index.ahub.vo';
import { ClientLibraryIndexAHubVO } from 'valueObjects/ahub/accounts/client-library-index.ahub.vo';
import { ClientLibraryVersionIndexAHubVO } from 'valueObjects/ahub/accounts/client-library-version-index.ahub.vo';
import { ClientLibraryVersionAHubVO } from 'valueObjects/ahub/accounts/client-library-version.ahub.vo';
import { ClientLibraryAHubVO } from 'valueObjects/ahub/accounts/client-library.ahub.vo';
import { ClientQuotaAHubVO } from 'valueObjects/ahub/accounts/client-quota.ahub.vo';
import { ClientAHubVO } from 'valueObjects/ahub/accounts/client.ahub.vo';
import { DataSetIndexAHubVO } from 'valueObjects/ahub/library/dataset-index.ahub.vo';
import { DataSetAHubVO } from 'valueObjects/ahub/library/dataset.ahub.vo';
import { DistributionGroupIndexAHubVO } from 'valueObjects/ahub/accounts/distribution-group-index.ahub.vo';
import { DistributionGroupAHubVO } from 'valueObjects/ahub/accounts/distribution-group.ahub.vo';
import { DistributionIndexAHubVO } from 'valueObjects/ahub/accounts/distribution-index.ahub.vo';
import { DistributionAHubVO } from 'valueObjects/ahub/accounts/distribution.ahub.vo';
import { ExportDistributionAHubVO } from 'valueObjects/ahub/accounts/export-distribution.ahub.vo';
import { ExportIndexAHubVO } from 'valueObjects/ahub/accounts/export-index.ahub.vo';
import { ExportAHubVO } from 'valueObjects/ahub/accounts/export.ahub.vo';
import { UserExtendedAHubVO } from 'valueObjects/ahub/accounts/user-extended.ahub.vo';
import { UserIndexAHubVO } from 'valueObjects/ahub/accounts/user-index.ahub.vo';
import { UserAHubVO } from 'valueObjects/ahub/accounts/user.ahub.vo';
import { WorkGroupIndexAHubVO } from 'valueObjects/ahub/accounts/work-group-index.ahub.vo';
import { WorkGroupAHubVO } from 'valueObjects/ahub/accounts/work-group.ahub.vo';
import { ExtractDefinitionIndexAHubVO } from 'valueObjects/ahub/library/extract-definition-index.ahub.vo';
import { ExtractDefinitionAHubVO } from 'valueObjects/ahub/library/extract-definition.ahub.vo';
import { ExtractHistoryIndexAHubVO } from 'valueObjects/ahub/library/extract-history-index.ahub.vo';
import { ExtractIndexAHubVO } from 'valueObjects/ahub/library/extract-index.ahub.vo';
import { ExtractAHubVO } from 'valueObjects/ahub/library/extract.ahub.vo';
import { ProductAssetAHubVO } from 'valueObjects/ahub/library/product-asset.ahub.vo';
import { ProductClassIndexAHubVO } from 'valueObjects/ahub/library/product-class-index.ahub.vo';
import { ProductClassProductCount } from 'valueObjects/ahub/library/product-class-product-count.ahub.vo';
import { ProductClassAHubVO } from 'valueObjects/ahub/library/product-class.ahub.vo';
import { ProductPropertyAllocationAHubVO } from 'valueObjects/ahub/library/product-property-allocation.ahub.vo';
import { ProductPropertyIndexAHubVO } from 'valueObjects/ahub/library/product-property-index.ahub.vo';
import { ProductPropertySectionIndexAHubVO } from 'valueObjects/ahub/library/product-property-section-index.ahub.vo';
import { ProductPropertySectionAHubVO } from 'valueObjects/ahub/library/product-property-section.ahub.vo';
import { ProductPropertyTypeAHubVO } from 'valueObjects/ahub/library/product-property-type.ahub.vo';
import { ProductPropertyAHubVO } from 'valueObjects/ahub/library/product-property.ahub.vo';
import { ProductAHubVO } from 'valueObjects/ahub/library/product.ahub.vo';
import { ResourcePackIndexAHubVO } from 'valueObjects/ahub/library/resource-pack-index.ahub.vo';
import { ResourcePackAHubVO } from 'valueObjects/ahub/library/resource-pack.ahub.vo';

/**
 *----------------------------------------
 * Value Objects Imports
 *----------------------------------------
 */
import { PresignedUrlAHubVO } from 'valueObjects/ahub/presigned-url.ahub.vo';
import { RelationshipAHubVO } from 'valueObjects/ahub/relationship.ahub.vo';
import { SystemStatusAHubVO } from 'valueObjects/ahub/system/system-status.ahub.vo';
import { ProductPropertyAllocChainIndexAHubVO } from 'valueObjects/ahub/library/product-property-alloc-chain-index.ahub.vo';
import { ProductPropertyAllocChainAHubVO } from 'valueObjects/ahub/library/product-property-alloc-chain.ahub.vo';
import { DataSetCategoryAHubVO } from 'valueObjects/ahub/library/dataset-category.ahub.vo';
import { ExporterIndexAHubVO } from 'valueObjects/ahub/library/exporter-index.ahub.vo';
import { ExporterAHubVO } from 'valueObjects/ahub/library/exporter.ahub.vo';
import { MapStorage } from 'store/map-storage.vo';
import { MapStorageUtil } from 'store/map-storage.util';
import { ActionWorkPayload } from 'actions/types/work.action-types';
import { ActionPayloadLibraryNumberProductsNumberArray } from 'actions/payloads/ahub-action-library.payload.vo';
import { IdHashedMapUtil } from 'store/id-hashed-map-storage.util';
import { HashedMapStorage } from 'store/hashed-map-storage.vo';
import { HashedMapUtil } from 'store/hashed-map-storage.util';
import { ExporterBuildHistoryIndexAHubVO } from 'app/valueObjects/ahub/library/exporter-build-history-index.ahub.vo';
import { ExporterBuildHistoryAHubVO } from 'app/valueObjects/ahub/library/exporter-build-history.ahub.vo';
import { ExtractAssetMatchAHubVO } from 'app/valueObjects/ahub/library/extract-asset-match.vo';
import { PaginationIdBreakdownVO } from 'app/valueObjects/ahub/system/pagination-id-breakdown.ahub.vo';
import { ProductPropertyCompletenessAHubVO } from 'app/valueObjects/ahub/library/product-property-completeness.ahub.vo';
import { ClientLogAHubVO } from 'valueObjects/ahub/library/client-log.ahub.vo';

/**
 * ----------------------------------
 * aHub Permenant State interface
 * ----------------------------------
 */
export interface AHubTemporaryState {
  userIndexList: List<UserIndexAHubVO>;
  userIndexs: UserIndexAHubVO[]; // System Administration full user index !
  userList: List<UserAHubVO>;
  userExtendedList: List<UserExtendedAHubVO>;
  dataSetIndexs: DataSetIndexAHubVO[];
  dataSets: List<DataSetAHubVO>;
  dataSetProducts: MapStorage<List<ProductAHubVO>>;
  dataSetProductAssets: MapStorage<List<ProductAssetAHubVO>>;
  dataSetCategories: List<DataSetCategoryAHubVO>;
  dataSetCategoryProducts: MapStorage<HashedMapStorage<ProductAHubVO>>;
  dataSetCategoryProductIdSorted: MapStorage<number[]>;
  exportGeneratorBlueprintURLByExporterIdMap: MapStorage<string>
  exporterIndexes: ExporterIndexAHubVO[];
  exporters: List<ExporterAHubVO>;
  exporterBuildHistoryIndexMap: MapStorage<ExporterBuildHistoryIndexAHubVO[]>,
  exporterBuildHistories: List<ExporterBuildHistoryAHubVO>;
  distributions: List<DistributionAHubVO>;
  distributionGroupIndexs: DistributionGroupIndexAHubVO[];
  distributionGroups: List<DistributionGroupAHubVO>;
  distributionGroupDistributionIndexList: List<RelationshipAHubVO<DistributionIndexAHubVO>>;
  distributionGroupUserIndexList: List<RelationshipAHubVO<UserIndexAHubVO>>;
  exportIndexs: ExportIndexAHubVO[];
  exports: List<ExportAHubVO>;
  exportDistributions: List<ExportDistributionAHubVO>;
  exportDistributionIndexList: List<RelationshipAHubVO<DistributionIndexAHubVO>>;
  exportPreviewImageUrls: List<IdItemVO<PresignedUrlAHubVO>>;
  exportPathUrls: List<IdItemVO<PresignedUrlAHubVO>>;
  exportLegacyRefs: List<IdItemVO<string[]>>;
  clientIndexs: ClientIndexAHubVO[];
  clients: List<ClientAHubVO>;
  clientLibraryVersionIndexs: ClientLibraryVersionIndexAHubVO[];
  clientLibraryVersions: List<ClientLibraryVersionAHubVO>;
  clientLibraryVersionModelUrls: List<IdItemVO<PresignedUrlAHubVO>>;
  clientLibraryIndexes: ClientLibraryIndexAHubVO[];
  clientLibraries: List<ClientLibraryAHubVO>;
  users: List<UserAHubVO>;
  workGroupIndexs: WorkGroupIndexAHubVO[];
  workGroups: List<WorkGroupAHubVO>;
  workGroupUserIndexList: List<RelationshipAHubVO<UserIndexAHubVO>>;
  clientQuotas: List<ClientQuotaAHubVO>;
  clientConfigurations: List<ClientConfigurationAHubVO>;
  clientQuotasHistory: List<ClientQuotaAHubVO>;
  productClassProductCounts: ProductClassProductCount[];
  productClassIndexs: ProductClassIndexAHubVO[];
  productClasses: List<ProductClassAHubVO>;
  productProperyTypes: ProductPropertyTypeAHubVO[];
  productPropertyIndexs: ProductPropertyIndexAHubVO[];
  productProperties: List<ProductPropertyAHubVO>;
  productPropertySectionIndexs: ProductPropertySectionIndexAHubVO[];
  productPropertySections: List<ProductPropertySectionAHubVO>;
  productPropertyAllocations: ProductPropertyAllocationAHubVO[];
  productPropertyAllocationChainIndexs: ProductPropertyAllocChainIndexAHubVO[];
  productPropertyAllocationChains: List<ProductPropertyAllocChainAHubVO>;
  extractDefinitionIndexes: ExtractDefinitionIndexAHubVO[];
  extractDefinitions: List<ExtractDefinitionAHubVO>;
  extractIndexes: ExtractIndexAHubVO[];
  extracts: List<ExtractAHubVO>;
  extractHistoryIndexes: ExtractHistoryIndexAHubVO[],
  extractProductCompletenessPaginationMap: MapStorage<PaginationIdBreakdownVO>,
  extractProductCompletenessMap: MapStorage<ProductPropertyCompletenessAHubVO[]>,
  extractProducts: MapStorage<HashedMapStorage<ProductAHubVO>>,
  extractProductsAssets: List<ProductAssetAHubVO>,
  extractAssetMatches: ExtractAssetMatchAHubVO[],
  resourcePackIndexes: ResourcePackIndexAHubVO[],
  resourcePacks: List<ResourcePackAHubVO>,
  systemStatus: SystemStatusAHubVO[],
  clientLogs: List<ClientLogAHubVO>
};

/**
 * ----------------------------------
 * Initial State
 * ----------------------------------
 */

//Index arrays start their life out as null objects, when they are fetched they will
//set as arrays. Even if the array is empty. This helps us determine if have made a fetch
//for this data yet and as a result if we need to keep it upto date.
export const AHubTemporaryStateInitial: AHubTemporaryState = {
  userIndexList: IdListUtil.listCreateEmpty(),
  userIndexs: null,
  userList: IdListUtil.listCreateEmpty(),
  userExtendedList: IdListUtil.listCreateEmpty(),
  dataSetIndexs: null,
  dataSets: IdListUtil.listCreateEmpty(),
  dataSetProducts: MapStorageUtil.mapStorageCreate(),
  dataSetProductAssets: MapStorageUtil.mapStorageCreate(),
  dataSetCategories: IdListUtil.listCreateEmpty(),
  dataSetCategoryProducts: MapStorageUtil.mapStorageCreate(),
  dataSetCategoryProductIdSorted: MapStorageUtil.mapStorageCreate(),
  exportGeneratorBlueprintURLByExporterIdMap: MapStorageUtil.mapStorageCreate(),
  exporterIndexes: null,
  exporters: IdListUtil.listCreateEmpty(),
  exporterBuildHistoryIndexMap: MapStorageUtil.mapStorageCreate(),
  exporterBuildHistories: IdListUtil.listCreateEmpty(),
  distributions: IdListUtil.listCreateEmpty(),
  distributionGroupIndexs: null,
  distributionGroups: IdListUtil.listCreateEmpty(),
  distributionGroupDistributionIndexList: IdListUtil.listCreateEmpty(),
  distributionGroupUserIndexList: IdListUtil.listCreateEmpty(),
  exportIndexs: null,
  exports: IdListUtil.listCreateEmpty(),
  exportDistributions: ListUtil.listCreateEmpty('exportId'),
  exportDistributionIndexList: IdListUtil.listCreateEmpty(),
  exportPreviewImageUrls: IdListUtil.listCreateEmpty(),
  exportPathUrls: IdListUtil.listCreateEmpty(),
  exportLegacyRefs: IdListUtil.listCreateEmpty(),
  clientIndexs: null,
  clients: IdListUtil.listCreateEmpty(),
  clientLibraryVersionIndexs: null,
  clientLibraryVersions: ListUtil.listCreateEmpty('version'),
  clientLibraryVersionModelUrls: IdListUtil.listCreateEmpty(),
  clientLibraryIndexes: null,
  clientLibraries: ListUtil.listCreateEmpty(),
  users: IdListUtil.listCreateEmpty(), // come back to this.
  workGroupIndexs: null,
  workGroups: IdListUtil.listCreateEmpty(),
  workGroupUserIndexList: IdListUtil.listCreateEmpty(),
  clientQuotas: IdListUtil.listCreateEmpty(),
  clientConfigurations: IdListUtil.listCreateEmpty(),
  clientQuotasHistory: IdListUtil.listCreateEmpty(),
  productClassProductCounts: null,
  productClassIndexs: null,
  productClasses: IdListUtil.listCreateEmpty(),
  productProperyTypes: null,
  productPropertyIndexs: null,
  productProperties: IdListUtil.listCreateEmpty(),
  productPropertySectionIndexs: null,
  productPropertySections: IdListUtil.listCreateEmpty(),
  productPropertyAllocations: null,
  productPropertyAllocationChainIndexs: null,
  productPropertyAllocationChains: IdListUtil.listCreateEmpty(),
  extractDefinitionIndexes: null,
  extractDefinitions: IdListUtil.listCreateEmpty(),
  extractIndexes: null,
  extracts: IdListUtil.listCreateEmpty(),
  extractHistoryIndexes: null,
  extractProductCompletenessPaginationMap: MapStorageUtil.mapStorageCreate(),
  extractProductCompletenessMap: MapStorageUtil.mapStorageCreate(),
  extractProducts: MapStorageUtil.mapStorageCreate(),
  extractProductsAssets: IdListUtil.listCreateEmpty(),
  extractAssetMatches: [],
  resourcePackIndexes: null,
  resourcePacks: IdListUtil.listCreateEmpty(),
  systemStatus: null,
  clientLogs: IdListUtil.listCreateEmpty(),
};

/**
 * ----------------------------------
 * aHub State Reducer
 * ----------------------------------
 */

/**
 * Reset this temporary section of the store
 */
const temporaryStoreClear = (state: AHubTemporaryState, action: Action) => {

  //Return the initial state of the temporary store this will reset it to default
  return AHubTemporaryStateInitial;
}

/**
 * Set the user index list.
 *
 * @param state             The state to change.
 * @param action            The action we are working with.
 */
const userIndexesSet = (state: AHubTemporaryState, action: ActionUserIndexAHubVO) => {

  // Append the user indexes to the list.
  return tassign(state, {
    userIndexList: IdListUtil.listAppend(state.userIndexList, action.userIndexs)
  });
}

/**
 * Remove the user indexes by ids from the list.
 */
const userIndexesRemove = (state: AHubTemporaryState, action: ActionNumberArray) => {

  // Remove the user indexes by ids.
  return tassign(state, {
    userIndexList: IdListUtil.listRemove(state.userIndexList, action.numbers)
  });
}

/**
 * Remove the user indexes by ids from the list.
 */
const userIndexesClear = (state: AHubTemporaryState, action: Action) => {

  // Remove the user indexes by ids.
  return { ...state, userIndexs: [] }
}

/**
 * Set the FULL Userindexs .. dont confuse with the UserIndexList (Index List of Indexes) used for other stuff.
 * This is the full userIndex Array for system administration.
 */
const userIndexesFullSet = (state: AHubTemporaryState, action: ActionUserIndexAHubVO) => {

  //Set the dataSet indexes, note no merging.. we are getting the full list.
  return tassign(state, { userIndexs: action.userIndexs });
}

/**
 * Set the FULL Userindexs .. dont confuse with the UserIndexList (Index List of Indexes) used for other stuff.
 * This is the full userIndex Array for system administration.
 */
const userIndexesPaginatedSet = (state: AHubTemporaryState, action: ActionUserIndexAHubVO) => {

  //Set the dataSet indexes, note no merging.. we are getting the full list.
  return { ...state, userIndexs: unionBy(state.userIndexs, action.userIndexs, 'id') }
}

/**
 * Pass in the extended users into the store.
 */
const usersSet = (state: AHubTemporaryState, action: ActionUserAHubVOs) => {

  // Append the user indexes to the list.
  return tassign(state, { userList: IdListUtil.listAppend(state.userList, action.users) });
}

/**
 * Pass in the extended users into the store.
 */
const userExtendedsSet = (state: AHubTemporaryState, action: ActionUserExtendedAHubVOs) => {

  // Append the user indexes to the list.
  return tassign(state, { userExtendedList: IdListUtil.listAppend(state.userExtendedList, action.userExtendeds) });
}

/**
 * Set the dataSet indexs
 */
const dataSetIndexsSet = (state: AHubTemporaryState, action: ActionDataSetIndexAHubVOs) => {

  //Set the dataSet indexes
  return tassign(state, { dataSetIndexs: action.dataSetIndexs });
}

/**
 * Set the datasets.
 */
const dataSetsSet = (state: AHubTemporaryState, action: ActionDataSetAHubVOs) => {

  //Set the dataSets
  return tassign(state, {
    dataSets: IdListUtil.listAppend(state.dataSets, action.dataSets)
  });
}

/**
 * Remove from the dataSets list.
 */
const dataSetsRemove = (state: AHubTemporaryState, action: ActionNumberArray) => {

  //Remove the dataSets by the ids supplied
  return tassign(state, {
    dataSets: IdListUtil.listRemove(state.dataSets, action.numbers)
  });
}

/**
 * Set the data set products assets.
 */
const dataSetProductsSet = (state: AHubTemporaryState, action: ActionProductVOsWithId) => {

  //Get the data set id
  const dataSetId: string = action.id.toString();

  //Get the current map for this data set
  let existingList: List<ProductAHubVO> = MapStorageUtil.mapStorageGet(state.dataSetProducts, dataSetId)

  //No list then we will create one
  if (!existingList) {
    existingList = ListUtil.listCreateEmpty();
  }

  //Append the products onto our existing list
  existingList = IdListUtil.listAppend(existingList, action.products);

  // Set the data set products.
  return tassign(state, {
    dataSetProducts: MapStorageUtil.mapStorageSet(state.dataSetProducts, dataSetId, existingList)
  });
}

/**
 * Set the data set products assets.
 */
const dataSetProductAssetsSet = (state: AHubTemporaryState, action: ActionProductAssetVOsWithId) => {

  //Get the data set id
  const dataSetId: string = action.id.toString();

  //Get the current map for this data set
  let existingList: List<ProductAssetAHubVO> = MapStorageUtil.mapStorageGet(state.dataSetProductAssets, dataSetId)

  //No list then we will create one
  if (!existingList) {
    existingList = ListUtil.listCreateEmpty();
  }

  //Append the products onto our existing list
  existingList = IdListUtil.listAppend(existingList, action.productAssets);

  // Set the data set products.
  return tassign(state, {
    dataSetProductAssets: MapStorageUtil.mapStorageSet(state.dataSetProductAssets, dataSetId, existingList)
  });
}

/**
 * Add data set categories to the list.
 */
const dataSetCategoriesSet = (state: AHubTemporaryState, action: ActionDataSetCategoryAHubVOs) => {

  // Set the data set categories.
  return tassign(state, {
    dataSetCategories: IdListUtil.listAppend(state.dataSetCategories, action.dataSetCategories)
  });
}

/**
 * Remove data set categories from the local store.
 */
const dataSetCategoriesRemove = (state: AHubTemporaryState, action: ActionNumberArray) => {

  // Remove the data set categories by the ids supplied.
  return tassign(state, {
    dataSetCategories: IdListUtil.listRemove(state.dataSetCategories, action.numbers)
  });
}

/**
 * Update the data set category products to include and delete the specified products
 */
const dataSetCategoryProductsAppendAndRemove = (state: AHubTemporaryState, action: ActionWorkPayload<ActionPayloadLibraryNumberProductsNumberArray>) => {
  return tassign(state, {
    dataSetCategoryProducts: MapStorageUtil.itemsMapAppendAndRemove(state.dataSetCategoryProducts, action.payload.number, action.payload.products, action.payload.numbers)
  });
}

/**
 * Set the current list of data set category products.
 */
const dataSetCategoryProductsRemove = (state: AHubTemporaryState, action: ActionNumberNumbersArray) => {
  return tassign(state, {
    dataSetCategoryProducts: MapStorageUtil.itemsMapAppendAndRemove(state.dataSetCategoryProducts, action.number, [], action.numbers)
  });
}

/**
 * Remove all the old objects which were last updated before the time specified
 */
const dataSetCategoryProductsRemoveBeforeTime = (state: AHubTemporaryState, action: ActionNumberNumber) => {
  return tassign(state, {
    dataSetCategoryProducts: productsMapRemoveBeforeTime(state.dataSetCategoryProducts, action.number1, action.number2)
  });
}


/**
 * Cleat the data set category products listing.
 */
const dataSetCategoryProductsClear = (state: AHubTemporaryState, action: ActionNumberArray) => {

  //Get the category ids from the action
  let categoryIds = (action.numbers) ? action.numbers.map(v => v.toString()) : undefined;

  //No ids return the state unchanged
  if (!categoryIds || categoryIds.length == 0) return state;

  //Set the data set category products
  return tassign(state, {
    dataSetCategoryProducts: MapStorageUtil.mapStorageRemoveMulti(state.dataSetCategoryProducts, categoryIds)
  });
}

/**
 * Set the list of the sorted product id's
 */
const dataSetCategoryProductIdSortedSet = (state: AHubTemporaryState, action: ActionNumberNumbersArray) => {

  // Set the data set categories.
  return tassign(state, {
    dataSetCategoryProductIdSorted: MapStorageUtil.mapStorageSet(state.dataSetCategoryProductIdSorted, action.number.toString(), action.numbers)
  });
}

/**
 * Remove a list of distributions.
 */
const distributionsRemove = (state: AHubTemporaryState, action: ActionNumberArray) => {

  // Get existing store list, filter out any items that have a matching object id in the list supplied.
  return tassign(state, {
    distributions: IdListUtil.listRemove(state.distributions, action.numbers)
  });
}

/**
 * Set the distributions list
 */
const distributionsSet = (state: AHubTemporaryState, action: ActionDistributionAHubVOs) => {

  //Set the distribution group indexes
  return tassign(state, {
    distributions: IdListUtil.listAppend(state.distributions, action.distributions)
  });
}

/**
 * Set the distribution group indexes object
 */
const distributionGroupIndexesSet = (state: AHubTemporaryState, action: ActionDistributionGroupIndexAHubVOs) => {

  //Set the distribution group indexes
  return tassign(state, { distributionGroupIndexs: action.distributionGroupIndexs });
}

/**
 * Append to the distribution group indexes object
 */
const distributionGroupIndexesAppend = (state: AHubTemporaryState, action: ActionDistributionGroupIndexAHubVOs) => {

  //Set the distribution group indexes
  return { ...state, distributionGroupIndexs: unionBy(state.distributionGroupIndexs, action.distributionGroupIndexs, 'id') };
  // return { ...state, userIndexs: unionBy(state.userIndexs, action.userIndexs, 'id') }
}

/**
 * Set the distribution group user indexes object
 */
const distributionGroupUserIndexesSet = (state: AHubTemporaryState, action: ActionRelationshipAHubVO<UserIndexAHubVO>) => {

  //Update the distribution group user indexes list with the new relationship
  return tassign(state, {
    distributionGroupUserIndexList: IdListUtil.listAppend(state.distributionGroupUserIndexList, [action.relationship])
  });
}

/**
 * Set the distribution group distribution indexes object
 */
const distributionGroupDistributionIndexesSet = (state: AHubTemporaryState, action: ActionRelationshipAHubVO<DistributionIndexAHubVO>) => {

  //Update the distribution group distribution indexes list with the new relationship
  return tassign(state, {
    distributionGroupDistributionIndexList: IdListUtil.listAppend(state.distributionGroupDistributionIndexList, [action.relationship])
  });
}

/**
 * Clear the distribution group distribution indexes
 */
const distributionGroupDistributionIndexesClear = (state: AHubTemporaryState, action: Action) => {

  //Update the distribution group distribution indexes list with the new relationship
  return tassign(state, {
    distributionGroupDistributionIndexList: IdListUtil.listCreateEmpty()
  });
}

/**
 * Set the distribution group index objects into the store
 */
const distributionGroupsSet = (state: AHubTemporaryState, action: ActionDistributionGroupAHubVOs) => {

  // Get existing store list, filter out any items that have a matching object id in the list supplied, then add the supplied list.
  return tassign(state, {
    distributionGroups: IdListUtil.listAppend(state.distributionGroups, action.distributionGroups)
  });
}

/**
 * Remove the distribution groups by the ids
 */
const distributionGroupsRemove = (state: AHubTemporaryState, action: ActionNumberArray) => {

  // Get existing store list, filter out any items that have a matching object id in the list supplied.
  return tassign(state, {
    distributionGroups: IdListUtil.listRemove(state.distributionGroups, action.numbers)
  });
}

/**
 * Set the export indexes object
 */
const exportIndexsSet = (state: AHubTemporaryState, action: ActionExportIndexAHubVOs) => {

  //Set the export indexes
  return tassign(state, { exportIndexs: action.exportIndexs });
}

/**
 * Set the export generator mapping blueprint url for a specific export generator
 */
const exportGeneratorMappingBlueprintUrlSet = (state: AHubTemporaryState, action: ActionStringNumber) => {

  //Update the map with the value supplied
  let newMap = MapStorageUtil.mapStorageSet(state.exportGeneratorBlueprintURLByExporterIdMap, action.number.toString(), action.string);

  //Set the new map into the export generor property
  return tassign(state, { exportGeneratorBlueprintURLByExporterIdMap: newMap });
}

/**
 * Set the exporter indexes object
 */
const exporterIndexesSet = (state: AHubTemporaryState, action: ActionExporterIndexAHubVOs) => {

  //Set the exporter indexes
  return tassign(state, { exporterIndexes: action.exporterIndexes });
}

/**
 * Set the distributions list
 */
const exportsSet = (state: AHubTemporaryState, action: ActionExportAHubVOs) => {

  //Set the exports
  return tassign(state, {
    exports: IdListUtil.listAppend(state.exports, action.exports)
  });
}

/**
 * Set exporters
 */
const exportersSet = (state: AHubTemporaryState, action: ActionExporterAHubVOs) => {

  //Set the exports
  return tassign(state, {
    exporters: IdListUtil.listAppend(state.exporters, action.exporters)
  });
}

/**
 * Set the exporters build history indexs
 */
const exporterBuildHistoryIndexesSet = (state: AHubTemporaryState, action: ActionExporterBuildHistoryIndexesAHubVO) => {

  //Get the new value based on our action
  let newValue = action.buildHistoryIndexes ? MapStorageUtil.mapStorageSet(state.exporterBuildHistoryIndexMap, action.exporterId.toString(), action.buildHistoryIndexes)
    : MapStorageUtil.mapStorageRemove(state.exporterBuildHistoryIndexMap, action.exporterId.toString());

  //Set the new value in the store
  return tassign(state, {
    exporterBuildHistoryIndexMap: newValue
  });
}

/**
 * Set the export build histories in the store.
 */
const exporterBuildHistoriesSet = (state: AHubTemporaryState, action: ActionExporterBuildHistoryAHubVOs) => {

  // Append the exporter build histories to the list.
  return tassign(state, {
    exporterBuildHistories: IdListUtil.listAppend(state.exporterBuildHistories, action.exporterBuildHistories)
  });
}

/**
 * Remove exporter build histories by the id's specified.
 */
const exporterBuildHistoriesRemove = (state: AHubTemporaryState, action: ActionNumberArray) => {

  // Remove the exporter build histories by the ids supplied.
  return tassign(state, {
    exporterBuildHistories: IdListUtil.listRemove(state.exporterBuildHistories, action.numbers)
  });
}

/**
 * Set the export distribution indexes object
 */
const exportDistributionIndexesSet = (state: AHubTemporaryState, action: ActionRelationshipAHubVO<DistributionIndexAHubVO>) => {

  //Update the export distribution indexes list with the new relationship
  return tassign(state, {
    exportDistributionIndexList: IdListUtil.listAppend(state.exportDistributionIndexList, [action.relationship])
  });
}

/**
 * Clear the distribution group distribution indexes
 */
const exportDistributionIndexesClear = (state: AHubTemporaryState, action: Action) => {

  //Update the distribution group distribution indexes list with the new relationship
  return tassign(state, {
    exportDistributionIndexList: IdListUtil.listCreateEmpty()
  });
}

/**
 * Set the export distributions list
 */
const exportDistributionsSet = (state: AHubTemporaryState, action: ActionExportDistributionAHubVOs) => {

  //Set the exports
  return tassign(state, {
    exportDistributions: ListUtil.listCreate(action.exportDistributions, 'exportId')
  });
}

/**
 * Set the distributions list
 */
const exportsRemove = (state: AHubTemporaryState, action: ActionNumberArray) => {

  //Remove the exports by the ids supplied
  return tassign(state, {
    exports: IdListUtil.listRemove(state.exports, action.numbers)
  });
}

/**
 * Set the distributions list
 */
const exportersRemove = (state: AHubTemporaryState, action: ActionNumberArray) => {

  //Remove the exporters by the ids supplied
  return tassign(state, {
    exporters: IdListUtil.listRemove(state.exporters, action.numbers)
  });
}

/**
 * Store the url of a preview image for an export by the export id.
 */
const exportPreviewImageUrlSet = (state: AHubTemporaryState, action: ActionIdItem<PresignedUrlAHubVO>) => {
  return tassign(state, {
    exportPreviewImageUrls: IdListUtil.listAppend(state.exportPreviewImageUrls, [action.idItem])
  });
}

/**
 * Store the path url of latest export version.
 */
const exportLatestVersionPathUrlSet = (state: AHubTemporaryState, action: ActionIdItem<PresignedUrlAHubVO>) => {
  return tassign(state, {
    exportPathUrls: IdListUtil.listAppend(state.exportPathUrls, [action.idItem])
  });
}

/**
 * Store the path url of specified export version.
 */
const exportVersionPathUrlSet = (state: AHubTemporaryState, action: ActionIdItem<PresignedUrlAHubVO>) => {
  return tassign(state, {
    exportPathUrls: IdListUtil.listAppend(state.exportPathUrls, [action.idItem])
  });
}

/**
 * Store the legacy refs for an export by the export id.
 */
const exportLegacyRefSet = (state: AHubTemporaryState, action: ActionIdItem<string[]>) => {
  return tassign(state, {
    exportLegacyRefs: IdListUtil.listAppend(state.exportLegacyRefs, [action.idItem])
  });
}

/**
 * Set the work group indexes object
 */
const workGroupIndexesSet = (state: AHubTemporaryState, action: ActionWorkGroupIndexAHubVOs) => {

  //Set the work group indexes
  return tassign(state, { workGroupIndexs: action.workGroupIndexs });
}

/**
 * Set the datasets.
 */
const workGroupsSet = (state: AHubTemporaryState, action: ActionWorkGroupAHubVOs) => {

  //Set the dataSets
  return tassign(state, {
    workGroups: IdListUtil.listAppend(state.workGroups, action.workGroups)
  });
}

/**
 * Remove from the dataSets list.
 */
const workGroupsRemove = (state: AHubTemporaryState, action: ActionNumberArray) => {

  //Remove the dataSets by the ids supplied
  return tassign(state, {
    workGroups: IdListUtil.listRemove(state.workGroups, action.numbers)
  });
}

/**
 * Set the work group user indexes object
 */
const workGroupUserIndexesSet = (state: AHubTemporaryState, action: ActionRelationshipAHubVO<UserIndexAHubVO>) => {

  //Update the work group user indexes list with the new relationship
  return tassign(state, {
    workGroupUserIndexList: IdListUtil.listAppend(state.workGroupUserIndexList, [action.relationship])
  });
}

/**
 * Set the client indexes list
 */
const clientIndexesSet = (state: AHubTemporaryState, action: ActionClientIndexAHubVOs) => {

  return tassign(state, {
    clientIndexs: action.clientIndexs
  });
}

/**
 * Set the clients information in the store
 */
const clientsSet = (state: AHubTemporaryState, action: ActionClientAHubVOs) => {

  return tassign(state, {
    clients: IdListUtil.listAppend(state.clients, action.clients)
  });
}

/**
 * Set the client library version indexes list
 */
const clientLibraryVersionIndexesSet = (state: AHubTemporaryState, action: ActionClientLibraryVersionIndexAHubVOs) => {
  return tassign(state, {
    clientLibraryVersionIndexs: action.clientLibraryVersionIndexs
  });
}

/**
 * Set the clients information in the store
 */
const clientLibraryVersionSet = (state: AHubTemporaryState, action: ActionClientLibraryVersionAHubVOs) => {
  return tassign(state, {
    clientLibraryVersions: ListUtil.listAppend(state.clientLibraryVersions, action.clientLibraryVersions, 'version')
  });
}

/**
 * Set the client library version model URLs set.
 */
const clientLibraryVersionModelUrlsSet = (state: AHubTemporaryState, action: ActionIdItem<PresignedUrlAHubVO>) => {
  return tassign(state, {
    clientLibraryVersionModelUrls: IdListUtil.listAppend(state.clientLibraryVersionModelUrls, [action.idItem])
  });
}

/**
 * Set the client library indexes.
 */
const clientLibraryIndexesSet = (state: AHubTemporaryState, action: ActionClientLibraryIndexAHubVOs) => {
  return tassign(state, {
    clientLibraryIndexes: action.clientLibraryIndexes
  });
}

/**
 * Set the client libraries.
 */
const clientLibrariesSet = (state: AHubTemporaryState, action: ActionClientLibraryAHubVOs) => {
  return tassign(state, {
    clientLibraries: IdListUtil.listAppend(state.clientLibraries, action.clientLibraries)
  });
}

/**
 * Remove a set of client libraries from the store.
 */
const clientLibrariesRemove = (state: AHubTemporaryState, action: ActionNumberArray) => {
  return tassign(state, {
    clientLibraries: IdListUtil.listRemove(state.clientLibraries, action.numbers)
  });
}

/**
 * Set the client quota information in the store
 */
const clientQuotasSet = (state: AHubTemporaryState, action: ActionClientQuotasAHubVOs) => {

  return tassign(state, {
    clientQuotas: IdListUtil.listAppend(state.clientQuotas, action.clientQuotas)
  });
}

/**
 * Set the client quota information in the store
 */
const clientQuotasHistorySet = (state: AHubTemporaryState, action: ActionClientQuotasAHubVOs) => {

  return tassign(state, {
    clientQuotasHistory: IdListUtil.listAppend(state.clientQuotasHistory, action.clientQuotas)
  });
}

/**
 * Set the client quota information in the store
 */
const clientQuotaSet = (state: AHubTemporaryState, action: ActionClientQuotaAHubVO) => {

  let clientQuotas: ClientQuotaAHubVO[] = []

  clientQuotas.push(action.clientQuota[0]);
  return tassign(state, {
    // clientQuotas: action.clientQuotas
    clientQuotas: IdListUtil.listAppend(state.clientQuotas, clientQuotas)
  });
}

/**
 * Set the client configuration information in the store
 */
const clientConfigurationSet = (state: AHubTemporaryState, action: ActionClientConfigurationAHubVO) => {

  return tassign(state, {
    clientConfigurations: IdListUtil.listAppend(state.clientConfigurations, [action.clientConfiguration[0]])
  });
}

/**
 * Set the product class product counts
 */
const productClassProductCountsSet = (state: AHubTemporaryState, action: ActionProductClassProductCountAHubVOs) => {

  //Set the product class indexes
  return tassign(state, { productClassProductCounts: action.productClassProductCounts });
}

/**
 * Set the product class indexes object
 */
const productClassIndexsSet = (state: AHubTemporaryState, action: ActionProductClassIndexAHubVOs) => {

  //Set the product class indexes
  return tassign(state, { productClassIndexs: action.productClassIndexs });
}

/**
 * Set the product class objects
 */
const productClassesSet = (state: AHubTemporaryState, action: ActionProductClassAHubVOs) => {

  //Set the dataSets
  return tassign(state, {
    productClasses: IdListUtil.listAppend(state.productClasses, action.productClasses)
  });
}


/**
 * Remove from the product classes list.
 */
const productClassesRemove = (state: AHubTemporaryState, action: ActionNumberArray) => {

  //Remove the product classes by the ids supplied
  return tassign(state, {
    productClasses: IdListUtil.listRemove(state.productClasses, action.numbers)
  });
}


/**
 * Set the current list of product property types.
 *
 * @param state             The state to change.
 * @param action            The action to change with.
 */
const productPropertyTypesSet = (state: AHubTemporaryState, action: ActionProductPropertyTypesAHubVOs) => {

  //Set the product properties indexes
  return tassign(state, { productProperyTypes: action.productPropertyTypes });
}


/**
 * Set the product property indexes object
 */
const productPropertyIndexsSet = (state: AHubTemporaryState, action: ActionProductPropertyIndexAHubVOs) => {

  //Set the product properties indexes
  return tassign(state, { productPropertyIndexs: action.productPropertyIndexes });
}

/**
 * Set the product property objects
 */
const productPropertySet = (state: AHubTemporaryState, action: ActionProductPropertyAHubVOs) => {

  //Set the product properties
  return tassign(state, {
    productProperties: IdListUtil.listAppend(state.productProperties, action.productProperties)
  });
}

/**
 * Remove from the product properties from the list.
 */
const productPropertyRemove = (state: AHubTemporaryState, action: ActionNumberArray) => {

  //Remove the product properties by the ids supplied
  return tassign(state, {
    productProperties: IdListUtil.listRemove(state.productProperties, action.numbers)
  });
}

/**
 * Set the product property indexes object
 */
const productPropertySectionIndexsSet = (state: AHubTemporaryState, action: ActionProductPropertySectionIndexAHubVOs) => {

  //Set the product properties indexes
  return tassign(state, { productPropertySectionIndexs: action.productPropertySectionIndexs });
}

/**
 * Set the product property sections object
 */
const productPropertySectionsSet = (state: AHubTemporaryState, action: ActionProductPropertySectionAHubVOs) => {

  //Set the product property sections
  return tassign(state, {
    productPropertySections: IdListUtil.listAppend(state.productPropertySections, action.productPropertySections)
  });
}

/**
 * Remove from the product classes list.
 */
const productPropertySectionsRemove = (state: AHubTemporaryState, action: ActionNumberArray) => {

  //Remove the product property sections by the ids supplied
  return tassign(state, {
    productPropertySections: IdListUtil.listRemove(state.productPropertySections, action.numbers)
  });
}

/**
 * Set the product property allocations object
 */
const productPropertyAllocationIndexsSet = (state: AHubTemporaryState, action: ActionProductPropertyAllocationIndexAHubVOs) => {

  //Set the product property allocations
  return tassign(state, { productPropertyAllocations: action.productPropertyAllocations });
}


/**
 * Set the product property allocation chain indexes.
 */
const productPropertyAllocationChainIndexsSet = (state: AHubTemporaryState, action: ActionProductPropertyAllocationChainIndexAHubVOs) => {

  //Set the product property allocation chain indexes.
  return tassign(state, { productPropertyAllocationChainIndexs: action.productPropertyAllocationChainIndexs });
}


/**
 * Set the product property allocation chains.
 */
const productPropertyAllocationChainsSet = (state: AHubTemporaryState, action: ActionProductPropertyAllocationChainAHubVOs) => {

  //Set the product property allocation chains.
  return tassign(state, {
    productPropertyAllocationChains: IdListUtil.listAppend(state.productPropertyAllocationChains, action.productPropertyAllocationChains)
  });
}

/**
 * Remove product property allocation chains.
 */
const productPropertyAllocationChainsRemove = (state: AHubTemporaryState, action: ActionNumberArray) => {

  // Remove the product property allocation chains by the ids supplied
  return tassign(state, {
    productPropertyAllocationChains: IdListUtil.listRemove(state.productPropertyAllocationChains, action.numbers)
  });
}

/**
 * Set the extract definition list.
 */
const extractDefinitionIndexesSet = (state: AHubTemporaryState, action: ActionExtractDefinitionIndexAHubVOs) => {

  // Set the extract definitions.
  return tassign(state, { extractDefinitionIndexes: action.extractDefinitionIndexes });
}


/**
 * Remove from the extract definition list.
 */
const extractDefinitionsRemove = (state: AHubTemporaryState, action: ActionNumberArray) => {

  // Remove the extract definition by the ids supplied.
  return tassign(state, {
    extractDefinitions: IdListUtil.listRemove(state.extractDefinitions, action.numbers)
  });
}


/**
 * Set the extract definition list.
 */
const extractDefinitionsSet = (state: AHubTemporaryState, action: ActionExtractDefinitionAHubVOs) => {

  // Set the extract definitions.
  return tassign(state, {
    extractDefinitions: IdListUtil.listAppend(state.extractDefinitions, action.extractDefinitions)
  });
}


/**
 * Set the extract index list.
 */
const extractIndexesSet = (state: AHubTemporaryState, action: ActionExtractIndexAHubVOs) => {

  // Set the extract indexes.
  return tassign(state, { extractIndexes: action.extractIndexes });
}

/**
 * Set the extract list.
 */
const extractsSet = (state: AHubTemporaryState, action: ActionExtractAHubVOs) => {

  // Set the extracts.
  return tassign(state, {
    extracts: IdListUtil.listAppend(state.extracts, action.extracts)
  });
}

/**
 * Remove from the extract list.
 */
const extractsRemove = (state: AHubTemporaryState, action: ActionNumberArray) => {

  // Remove the extract by the ids supplied.
  return tassign(state, {
    extracts: IdListUtil.listRemove(state.extracts, action.numbers)
  });
}


/**
 * Set the extract completness product report
 */
const extractProductCompletnessSet = (state: AHubTemporaryState, action: ActionProductPropertyCompletenessAHubVO) => {

  //Get the existing data
  let existingData: ProductPropertyCompletenessAHubVO[] = MapStorageUtil.mapStorageGet(state.extractProductCompletenessMap, action.number.toString());

  //No existing data then default to nothing
  if (!existingData) existingData = [];

  //Take a copy of the array then we will append the item
  existingData = existingData.slice();
  existingData.push(action.productPropertyCompleteness);

  //Set the existing data into the map
  let map = MapStorageUtil.mapStorageSet(state.extractProductCompletenessMap, action.number.toString(), existingData);

  //Set the map back into the store
  return tassign(state, { extractProductCompletenessMap: map });
}

/**
 * Delete the extract completness product report
 */
const extractProductCompletnessDelete = (state: AHubTemporaryState, action: ActionNumber) => {

  //Set the map back into the store
  return tassign(state, { extractProductCompletenessMap: MapStorageUtil.mapStorageRemove(state.extractProductCompletenessMap, action.number.toString()) });
}

/**
 * Set the extract completness product pagination
 */
const extractProductCompletnessPaginationSet = (state: AHubTemporaryState, action: ActionExtractProductPaginationPage) => {
  return tassign(state, { extractProductCompletenessPaginationMap: MapStorageUtil.mapStorageSet(state.extractProductCompletenessPaginationMap, action.extractId.toString(), action.productPaginationPage) });
}


/**
 * Set the extract history index
 */
const extractHistoryIndexesSet = (state: AHubTemporaryState, action: ActionExtractHistoryIndexAHubVOs) => {

  // Set the extract history indexes.
  return tassign(state, { extractHistoryIndexes: action.extractHistoryIndexes });
}

/**
 * Update the extract products to contain the new products sent
 */
const extractProductsUpdateSet = (state: AHubTemporaryState, action: ActionNumberProductNumbersArrayAHubVOs) => {

  //Get the extract id
  let extractId = (action.payload.number) ? action.payload.number : undefined;

  //If the extract id isn't set, there is nothing we can do
  if (!extractId) return state;

  // Set the extract products.
  return tassign(state, {
    extractProducts: MapStorageUtil.itemsMapAppendAndRemove(state.extractProducts, extractId, action.payload.products, action.payload.numbers, true)
  });
}

/**
 * Remove the products by id's by their extract id
 */
const extractProductsRemove = (state: AHubTemporaryState, action: ActionNumberNumbersArray) => {

  //Get the extract id
  let extractId = (action.number) ? action.number : undefined;

  //If the extract id isn't set, there is nothing we can do
  if (!extractId) return state;

  //Set the extract products
  return tassign(state, {
    extractProducts: MapStorageUtil.itemsMapAppendAndRemove(state.extractProducts, extractId, [], action.numbers)
  });
}


/**
 * Remove all the old objects which were last updated before the time specified
 */
const extractProductsRemoveBeforeTime = (state: AHubTemporaryState, action: ActionNumberNumber) => {
  return tassign(state, {
    extractProducts: productsMapRemoveBeforeTime(state.extractProducts, action.number1, action.number2)
  });
}


/**
 * Remove the products for a particular extract in one swoop
 */
const extractProductsClear = (state: AHubTemporaryState, action: ActionNumber) => {

  //Get the extract id
  let extractId = (action.number) ? action.number.toString() : undefined;

  //If the extract id isn't set, there is nothing we can do
  if (!extractId) return state;

  //Call the remove function to remove all the data from the map
  return tassign(state, {
    extractProducts: MapStorageUtil.mapStorageRemove(state.extractProducts, extractId.toString())
  });
}

/**
 * Set the extract products assets.
 */
const extractProductsAssetsSet = (state: AHubTemporaryState, action: ActionProductAssetAHubVOs) => {

  // Set the extracts.
  return tassign(state, {
    extractProductsAssets: IdListUtil.listAppend(state.extractProductsAssets, action.productAssets)
  });
}

/**
 * Set the extract asset matches
 */
const extractAssetMatchesSet = (state: AHubTemporaryState, action: ActionExtractAssetMatchArray) => {

  // Set the extracts.
  return tassign(state, {
    extractAssetMatches: action.matches
  });
}

/**
 * This function will update the resource pack indexes with the ones passed in.
 *
 * @param state                 The state to make changes too.
 * @param action                The action that occured that requires changes to the state.
 */
const resourcePackIndexesSet = (state: AHubTemporaryState, action: ActionResourcePackIndexesAHubVO) => {

  // Set the resource pack indexes.
  return tassign(state, { resourcePackIndexes: action.resourcePackIndexes });
}


/**
 * This function will add the resource packs passed in to the state.
 *
 * @param state                 The state to make changes too.
 * @param action                The action that occured that requires changes to the state.
 */
const resourcePacksSet = (state: AHubTemporaryState, action: ActionResourcePackAHubVOs) => {

  // Update the resource packs.
  return tassign(state, {
    resourcePacks: IdListUtil.listAppend(state.resourcePacks, action.resourcePacks)
  });
}


/**
 * This function will remove resource packs from the passed in state.
 *
 * @param state                 The state to make changes too.
 * @param action                The action that occured that requires changes to the state.
 */
const resourcePacksRemove = (state: AHubTemporaryState, action: ActionNumberArray) => {

  // Remove the resource packs based on the id's passed in.
  return tassign(state, {
    resourcePacks: IdListUtil.listRemove(state.resourcePacks, action.numbers)
  });
}

/**
 * This function will add client log to the state.
 * 
 * @param state                 The state to make changes too.
 * @param action                The action that occured that requires changes to the state.
 */
const clientLogSet = (state: AHubTemporaryState, action: ActionClientLogAHubVO) => {

  // Set the current client log.
  const clientLog = action.clientLog;

  // So we have a new/existing client log. Get the current log.
  const existingLog = state.clientLogs.data.find(stateLog => clientLog.id === stateLog.id);

  // Do we know about the client log?
  if (existingLog) {

    // If we get here then we've seen this client log before and it's not completed.
    // So we need to add the entries from the previous entry.
    const currentEntries = (existingLog.entries) ? existingLog.entries : [];
    clientLog.entries = currentEntries.concat(clientLog.entries);
  }

  // We need to replace the client log we have been give with one passed in.
  // So get the list of client logs minus this one.
  const clientLogs = state.clientLogs.data.filter(existingLog => existingLog.id !== clientLog.id);

  // Now add the new client log.
  clientLogs.push(clientLog);

  // Update the client logs state.
  return tassign(state, {
    clientLogs: IdListUtil.listAppend(state.clientLogs, clientLogs)
  });
}

/**
 * 
 * 
 * @param state                 The state to make changes too.
 * @param action                The action that occured that requires changes to the state.
 */
const clientLogReplace = (state: AHubTemporaryState, action: ActionClientLogAHubVO) => {

  // We need to replace the client log we have been give with one passed in.
  // So get the list of client logs minus this one.
  const clientLogs = state.clientLogs.data.filter(existingLog => existingLog.id !== action.clientLog.id);

  // Now add the new client log.
  clientLogs.push(action.clientLog);

  // Update the client logs state.
  return tassign(state, {
    clientLogs: IdListUtil.listAppend(state.clientLogs, clientLogs)
  });
}


/**
 * Set the system status.
 */
const systemStatusSet = (state: AHubTemporaryState, action: ActionSystemStatusArrayAHubVO) => {
  return tassign(state, { systemStatus: action.systemStatus });
}

/**
 * -------------------------------------------
 * Reducer utility functions
 * -------------------------------------------
 */

/**
 * Utility function for the product reducers as they will be stored in an identical way
 *
 * @param productsByParentIdMap         Parent map which contains all our products for all our parents
 * @param parentKey                     Key to the parent we actually wish to affect
 * @param productsToAppend              Products which we wish to add the the product storage under the parent specified above
 * @param productsToRemoveIds           Products which we wish to remove from the product storage under the parent key specified above
 *
 * @returns                             A map the same as the one passed in with the changes applied. If no changes needed to be applied
 *                                      due to the absense of data etc the map passed in will be returned
 */
const productsMapAppendAndRemove = (productsByParentIdMap: MapStorage<HashedMapStorage<ProductAHubVO>>, parentKey: number, productsToAppend: ProductAHubVO[], productsToRemoveIds: number[]): MapStorage<HashedMapStorage<ProductAHubVO>> => {

  //If we are missing the master map or its key just return the map back to the caller
  if (!productsByParentIdMap || !parentKey) return productsByParentIdMap;

  //Decide what we have to do
  let hasProductsToAppend = (productsToAppend && productsToAppend.length > 0);
  let hasProductsToRemove = (productsToRemoveIds && productsToRemoveIds.length > 0);

  //No changes to apply ... then return the map as it was!
  if (!hasProductsToAppend && !hasProductsToRemove) return productsByParentIdMap;

  //Get the key for the
  let parentKeyString = parentKey.toString();

  //Get the source hash map from the map
  let sourceProductsHashMap = MapStorageUtil.mapStorageGet(productsByParentIdMap, parentKeyString);

  //Is this item missing from the parent list? this would mean it never existed
  if (!sourceProductsHashMap) {

    //We have no products to append (nothing to do) so return the map straight back
    if (!hasProductsToAppend) return productsByParentIdMap;

    //OK well we had no map before, so we will create a new one with our new items in and set it into our products map
    return MapStorageUtil.mapStorageSet(productsByParentIdMap, parentKeyString, IdHashedMapUtil.hashMapCreate(productsToAppend));
  }

  //If we only have products to remove we will do a cheaky little step before to see if we need to do anything at all
  if (!hasProductsToAppend && hasProductsToRemove) {

    //I think we should do this what should be a quick check as it will save the copy of the map ... which i feel could be more labour intensive
    //and this is a quick check!

    //If we are only removing products we will only have to do this if there is something to remove for this id. So lets go through looking
    //for the first data item with this id, if we find something thats good enough
    let itemToRemove = productsToRemoveIds.find(id => HashedMapUtil.hashMapGetItem(sourceProductsHashMap, id.toString()) != undefined);

    //OK if we didn't find any items then there were no id's that affected us!
    if (!itemToRemove) return productsByParentIdMap;
  }

  //Right so if we are here we have an existing set of values and we know we either want to append or remove some items
  //so first off we will make a shallow clone of the map which will allow us to change it without affecting the orginal object
  let clonedProductHashMap = HashedMapUtil.hashedMapClone(sourceProductsHashMap);

  //Now we have our clone we can affect it however we want!

  //If we have products to append lets append them!
  if (hasProductsToAppend) {
    IdHashedMapUtil.hashMapAppendItems(clonedProductHashMap, productsToAppend)
  }

  //If we have products to remove lets remove them!
  if (hasProductsToRemove) {
    HashedMapUtil.hashMapRemoveItems(clonedProductHashMap, productsToRemoveIds.map(id => id.toString()));
  }

  //Finally we will set the cloned hash map into our products map
  return MapStorageUtil.mapStorageSet(productsByParentIdMap, parentKeyString, clonedProductHashMap);
}

/**
 * Utility function to remove items from the specified map which were added or updated before the time specified
 *
 * @param productsByParentIdMap     Map which contains the products indexed by some key
 * @param parentKey                 The key of the above map which we want to affect
 * @param removeBeforeTime          The time which we want to remove them items before
 */
const productsMapRemoveBeforeTime = (productsByParentIdMap: MapStorage<HashedMapStorage<ProductAHubVO>>, parentKey: number, removeBeforeTime: number): MapStorage<HashedMapStorage<ProductAHubVO>> => {

  //If we are missing the master map or its key just return the map back to the caller
  if (!productsByParentIdMap || !parentKey || removeBeforeTime <= 0) return productsByParentIdMap;

  //Get the key for the
  let parentKeyString = parentKey.toString();

  //Get the source hash map from the map
  let sourceProductsHashMap = MapStorageUtil.mapStorageGet(productsByParentIdMap, parentKeyString);

  //Get the item id's from the map
  let oldItemIds = HashedMapUtil.hashMapItemIdsOlderThan(sourceProductsHashMap, removeBeforeTime);

  //If we have no old items, there is nothing to delete just return the map back as is :)
  if (oldItemIds.length == 0) return productsByParentIdMap;

  //Well there is some old items to remove, so lets clone the hashed map so we can remove it without affecting
  //the existing object
  let clonedProductHashMap = HashedMapUtil.hashedMapClone(sourceProductsHashMap);

  //Remove the old items
  HashedMapUtil.hashMapRemoveItems(clonedProductHashMap, oldItemIds);

  //Finally we will set the cloned hash map into our products map
  return MapStorageUtil.mapStorageSet(productsByParentIdMap, parentKeyString, clonedProductHashMap);
}

/**
 * ----------------------------------
 * Reducers Mapping
 * ----------------------------------
 */

/**
 * Reducers handlers object ... match actions to the handler functions
 */
let reducerHandlers = {};

/**
 * Map the actions to the reducer functions this will allow us to
 */
reducerHandlers[AHubActions.USER_INDEXES_SET] = userIndexesSet;
reducerHandlers[AHubActions.USER_INDEXES_REMOVE] = userIndexesRemove;
reducerHandlers[AHubActions.USER_INDEXES_CLEAR] = userIndexesClear;
reducerHandlers[AHubActions.USER_INDEXES_FULL_SET] = userIndexesFullSet;
reducerHandlers[AHubActions.USER_INDEXES_PAGINATED_SET] = userIndexesPaginatedSet;
reducerHandlers[AHubActions.USER_EXTENDEDS_SET] = userExtendedsSet;
reducerHandlers[AHubActions.USERS_SET] = usersSet;

reducerHandlers[AHubActions.DATASET_INDEXS_SET] = dataSetIndexsSet;
reducerHandlers[AHubActions.DATASETS_SET] = dataSetsSet;
reducerHandlers[AHubActions.DATASETS_REMOVE] = dataSetsRemove;
reducerHandlers[AHubActions.DATA_SET_PRODUCTS_SET] = dataSetProductsSet;
reducerHandlers[AHubActions.DATA_SET_PRODUCT_ASSETS_SET] = dataSetProductAssetsSet;
reducerHandlers[AHubActions.DATA_SET_CATEGORIES_SET] = dataSetCategoriesSet;
reducerHandlers[AHubActions.DATA_SET_CATEGORY_REMOVE] = dataSetCategoriesRemove;
reducerHandlers[AHubActions.DATA_SET_CATEGORY_PRODUCTS_APPEND_AND_REMOVE] = dataSetCategoryProductsAppendAndRemove;
reducerHandlers[AHubActions.DATA_SET_CATEGORY_PRODUCTS_REMOVE_BEFORE_TIME] = dataSetCategoryProductsRemoveBeforeTime;
reducerHandlers[AHubActions.DATA_SET_CATEGORY_PRODUCTS_REMOVE] = dataSetCategoryProductsRemove;
reducerHandlers[AHubActions.DATA_SET_CATEGORY_PRODUCTS_CLEAR] = dataSetCategoryProductsClear;
reducerHandlers[AHubActions.DATA_SET_CATEGORY_PRODUCT_ID_SORTED_SET] = dataSetCategoryProductIdSortedSet;


reducerHandlers[AHubActions.CLIENT_INDEXS_SET] = clientIndexesSet;
reducerHandlers[AHubActions.CLIENTS_SET] = clientsSet;

reducerHandlers[AHubActions.CLIENT_LIBRARY_VERSION_INDEXS_SET] = clientLibraryVersionIndexesSet;
reducerHandlers[AHubActions.CLIENTS_LIBRARY_VERSION_SET] = clientLibraryVersionSet;
reducerHandlers[AHubActions.CLIENT_LIBRARY_VESION_MODEL_URLS_SET] = clientLibraryVersionModelUrlsSet;
reducerHandlers[AHubActions.CLIENTS_LIBRARY_INDEXES_SET] = clientLibraryIndexesSet;
reducerHandlers[AHubActions.CLIENTS_LIBRARIES_SET] = clientLibrariesSet;
reducerHandlers[AHubActions.CLIENTS_LIBRARIES_REMOVE] = clientLibrariesRemove;

reducerHandlers[AHubActions.DISTRIBUTIONS_SET] = distributionsSet;
reducerHandlers[AHubActions.DISTRIBUTIONS_REMOVE] = distributionsRemove;
reducerHandlers[AHubActions.DISTRIBUTION_GROUP_INDEXS_SET] = distributionGroupIndexesSet;
reducerHandlers[AHubActions.DISTRIBUTION_GROUP_INDEXS_APPEND] = distributionGroupIndexesAppend;
reducerHandlers[AHubActions.DISTRIBUTION_GROUPS_SET] = distributionGroupsSet;
reducerHandlers[AHubActions.DISTRIBUTION_GROUPS_REMOVE] = distributionGroupsRemove;
reducerHandlers[AHubActions.DISTRIBUTION_GROUP_DISTRIBUTION_INDEXS_SET] = distributionGroupDistributionIndexesSet;
reducerHandlers[AHubActions.DISTRIBUTION_GROUP_DISTRIBUTION_INDEXES_CLEAR] = distributionGroupDistributionIndexesClear;
reducerHandlers[AHubActions.DISTRIBUTION_GROUP_USER_INDEXS_SET] = distributionGroupUserIndexesSet;

reducerHandlers[AHubActions.EXPORT_INDEXS_SET] = exportIndexsSet;
reducerHandlers[AHubActions.EXPORT_DISTRIBUTIONS_SET] = exportDistributionsSet;
reducerHandlers[AHubActions.EXPORT_DISTRIBUTION_INDEXS_SET] = exportDistributionIndexesSet;
reducerHandlers[AHubActions.EXPORT_DISTRIBUTION_INDEXES_CLEAR] = exportDistributionIndexesClear;
reducerHandlers[AHubActions.EXPORT_PREVIEWIMAGE_URL_SET] = exportPreviewImageUrlSet;
reducerHandlers[AHubActions.EXPORT_LATEST_VERSION_PATH_URL_SET] = exportLatestVersionPathUrlSet;
reducerHandlers[AHubActions.EXPORT_VERSION_PATH_URL_SET] = exportVersionPathUrlSet;
reducerHandlers[AHubActions.EXPORT_LEGACY_REF_SET] = exportLegacyRefSet;

reducerHandlers[AHubActions.STORE_TEMPORARY_CLEAR] = temporaryStoreClear;

reducerHandlers[AHubActions.WORK_GROUP_INDEXS_SET] = workGroupIndexesSet;
reducerHandlers[AHubActions.WORK_GROUPS_SET] = workGroupsSet;
reducerHandlers[AHubActions.WORK_GROUPS_REMOVE] = workGroupsRemove;
reducerHandlers[AHubActions.WORK_GROUP_USER_INDEXS_SET] = workGroupUserIndexesSet;

reducerHandlers[AHubActions.CLIENT_QUOTAS_SET] = clientQuotasSet;
reducerHandlers[AHubActions.CLIENT_QUOTAS_HISTORY_SET] = clientQuotasHistorySet;
reducerHandlers[AHubActions.CLIENT_QUOTA_SET] = clientQuotaSet;

reducerHandlers[AHubActions.CLIENT_CONFIGURATION_SET] = clientConfigurationSet;

reducerHandlers[AHubActions.PRODUCT_CLASS_PRODUCT_COUNTS_SET] = productClassProductCountsSet;
reducerHandlers[AHubActions.PRODUCT_CLASS_INDEXS_SET] = productClassIndexsSet;
reducerHandlers[AHubActions.PRODUCT_CLASSES_SET] = productClassesSet;
reducerHandlers[AHubActions.PRODUCT_CLASSES_REMOVE] = productClassesRemove;

reducerHandlers[AHubActions.PRODUCT_PROPERTY_TYPES_SET] = productPropertyTypesSet;
reducerHandlers[AHubActions.PRODUCT_PROPERTY_INDEXS_SET] = productPropertyIndexsSet;
reducerHandlers[AHubActions.PRODUCT_PROPERTY_SET] = productPropertySet;
reducerHandlers[AHubActions.PRODUCT_PROPERTY_REMOVE] = productPropertyRemove;
reducerHandlers[AHubActions.PRODUCT_PROPERTY_SECTION_INDEXS_SET] = productPropertySectionIndexsSet;
reducerHandlers[AHubActions.PRODUCT_PROPERTY_SECTIONS_SET] = productPropertySectionsSet;
reducerHandlers[AHubActions.PRODUCT_PROPERTY_SECTIONS_REMOVE] = productPropertySectionsRemove;
reducerHandlers[AHubActions.PRODUCT_PROPERTY_ALLOCATION_INDEXS_SET] = productPropertyAllocationIndexsSet;
reducerHandlers[AHubActions.PRODUCT_PROPERTY_ALLOCATION_CHAIN_INDEXS_SET] = productPropertyAllocationChainIndexsSet;
reducerHandlers[AHubActions.PRODUCT_PROPERTY_ALLOCATION_CHAINS_SET] = productPropertyAllocationChainsSet;
reducerHandlers[AHubActions.PRODUCT_PROPERTY_ALLOCATION_CHAINS_REMOVE] = productPropertyAllocationChainsRemove;

reducerHandlers[AHubActions.EXTRACT_DEFINITION_INDEXES_SET] = extractDefinitionIndexesSet;
reducerHandlers[AHubActions.EXTRACT_DEFINITION_SET] = extractDefinitionsSet;
reducerHandlers[AHubActions.EXTRACT_DEFINITIONS_REMOVE] = extractDefinitionsRemove;
reducerHandlers[AHubActions.EXTRACT_INDEXES_SET] = extractIndexesSet;
reducerHandlers[AHubActions.EXTRACTS_SET] = extractsSet;
reducerHandlers[AHubActions.EXTRACTS_REMOVE] = extractsRemove;
reducerHandlers[AHubActions.EXTRACT_PRODUCT_PROPERTY_COMPLETENESS_SET] = extractProductCompletnessSet;
reducerHandlers[AHubActions.EXTRACT_PRODUCT_PROPERTY_COMPLETENESS_DELETE] = extractProductCompletnessDelete;
reducerHandlers[AHubActions.EXTRACT_PRODUCT_PROPERTY_COMPLETENESS_PAGINATION_PAGE_SET] = extractProductCompletnessPaginationSet;
reducerHandlers[AHubActions.EXTRACT_HISTORY_INDEXES_SET] = extractHistoryIndexesSet;
reducerHandlers[AHubActions.EXTRACT_PRODUCTS_UPDATE_SET] = extractProductsUpdateSet;
reducerHandlers[AHubActions.EXTRACT_PRODUCTS_REMOVE] = extractProductsRemove;
reducerHandlers[AHubActions.EXTRACT_PRODUCTS_REMOVE_BEFORE_TIME] = extractProductsRemoveBeforeTime;
reducerHandlers[AHubActions.EXTRACT_PRODUCTS_CLEAR] = extractProductsClear;
reducerHandlers[AHubActions.EXTRACT_PRODUCTS_ASSETS_SET] = extractProductsAssetsSet;
reducerHandlers[AHubActions.EXTRACT_PRODUCT_ASSET_MATCH_SET] = extractAssetMatchesSet;

reducerHandlers[AHubActions.EXPORT_GENERATOR_MAPPING_BLUEPRINT_URL_SET] = exportGeneratorMappingBlueprintUrlSet;

reducerHandlers[AHubActions.EXPORTER_INDEXES_SET] = exporterIndexesSet;
reducerHandlers[AHubActions.EXPORTS_SET] = exportsSet;
reducerHandlers[AHubActions.EXPORTERS_SET] = exportersSet;
reducerHandlers[AHubActions.EXPORTS_REMOVE] = exportsRemove;
reducerHandlers[AHubActions.EXPORTERS_REMOVE] = exportersRemove;

reducerHandlers[AHubActions.EXPORTER_BUILD_HISTORY_INDEX_SET] = exporterBuildHistoryIndexesSet;
reducerHandlers[AHubActions.EXPORTER_BUILD_HISTORYS_SET] = exporterBuildHistoriesSet;
reducerHandlers[AHubActions.EXPORTER_BUILD_HISTORYS_REMOVE] = exporterBuildHistoriesRemove;

reducerHandlers[AHubActions.RESOURCE_PACK_INDEXES_SET] = resourcePackIndexesSet;
reducerHandlers[AHubActions.RESOURCE_PACKS_SET] = resourcePacksSet;
reducerHandlers[AHubActions.RESOURCE_PACKS_REMOVE] = resourcePacksRemove;

reducerHandlers[AHubActions.CLIENT_LOG_SET] = clientLogSet;
reducerHandlers[AHubActions.CLIENT_LOG_REPLACE] = clientLogReplace;


reducerHandlers[AHubActions.SYSTEM_STATUS_SET] = systemStatusSet;

//Create a reducers based on the reducers handlers
export const AHubTemporaryReducer: Reducer<AHubTemporaryState> = createReducer(AHubTemporaryStateInitial, reducerHandlers);

//Check if this reducers can handel the function specified
export const AHubTemporaryReducerHasHandler = (actionType: string): boolean => reducerHandlers.hasOwnProperty(actionType);

import { Component, OnInit, Input, ComponentFactoryResolver } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { Observable, of } from 'rxjs';

import { RequestActionMonitorService } from 'services/request-action-monitor/request-action-monitor.service';
/**
 * Store
 */
import { StoreAccess } from 'store/store-access';
import { AHubActions } from 'actions/ahub.actions';
import { Hark, componentDestroyStream } from 'modules/common/hark.decorator';

import { EntityPermissions } from 'valueObjects/ahub/accounts/entity-permissions.ahub';
import { ClientConfigurationAHubVO } from 'valueObjects/ahub/accounts/client-configuration.ahub.vo';
import { Utils } from 'modules/common/utils';
import { map, takeUntil } from 'rxjs/operators';
import { DialogService } from '../../dialogs/dialog.service';
import { InputDialogVO, InputDialogComponent } from '../../dialogs/input-dialog/input-dialog.component';
import { ClientAHubVO } from 'app/valueObjects/ahub/accounts/client.ahub.vo';


@Component({
  selector: 'app-client-configuration-card',
  templateUrl: './client-configuration-card.component.html',
  styleUrls: ['./client-configuration-card.component.css']
})
@Hark()
export class ClientConfigurationCard implements OnInit {

  @Input() clientConfig$: Observable<ClientConfigurationAHubVO>;
  clientConfig: ClientConfigurationAHubVO;

  @Input() selectedClient: Observable<ClientAHubVO>;
  /**
   * Define the configuration form
   */
  configForm: FormGroup = this.fb.group({
    id: [],
    permittedInclusionListDomains: [],
    trimThreshold: []
  })

  /**
   *  Subscribe to the request action status object that has an action id that matches this current id.
   */
  actionRequestActionStatus$: Observable<boolean> = undefined;

  /**
   * This is the number of permitted domains.
   */
  permittedDomainsLength$: Observable<number>;

  clientConfigUntouchedByHumanHands: ClientConfigurationAHubVO;

  systemUser = EntityPermissions.SYSTEM_USER;

  constructor(
    private fb: FormBuilder,
    private requestActionMonitorService: RequestActionMonitorService,
    private dialogService: DialogService,
    private readonly resolver: ComponentFactoryResolver,
  ) { }

  /**
 * Empty On Init to ensure @Hark decorator works for an AOT build
 */
  ngOnInit() {


    this.clientConfig$.pipe(
      takeUntil(componentDestroyStream(this)))
      .subscribe((clientConfig) => {

        this.clientConfigUntouchedByHumanHands = Utils.clone(clientConfig);
        this.clientConfig = clientConfig;
        this.formsSet(clientConfig);
        this.configForm.markAsPristine();
      });

    // Watch the length of the permitted domains.
    this.permittedDomainsLength$ = this.configForm.valueChanges
      .pipe(
        takeUntil(componentDestroyStream(this)),
        map(data => (data.permittedInclusionListDomains) ? data.permittedInclusionListDomains.length : 0)
      );
  }

  /**
   * Empty On destroy to ensure @Hark decorator works for an AOT build
   */
  ngOnDestroy() { }

  /**
 *  Reset the form back to the original state
 */
  resetHandler() {

    // Take a copy of the client so we can reset it to the source object.
    let clientConfig: ClientConfigurationAHubVO = Utils.clone(this.clientConfigUntouchedByHumanHands);

    // Now reset it.
    this.formsSet(clientConfig);
    this.configForm.markAsPristine();
  }


  /**
   * Set the client into our forms which are currently using it
   */
  private formsSet(selectedClient: ClientConfigurationAHubVO) {

    //Set the client as generic form data
    let formData: any = selectedClient;

    //If the data is undefined then we will set the form to empty
    if (!formData)
      formData = {};

    //We are resetting the form data so we will mark the data as prestine
    // this.configForm.markAsPristine();

    //Update the form data
    this.configForm.reset(formData);
  }

  /**
   * Call the client congiguration save handler
   */
  clientConfigSaveHandler() {

    // Populate the original object with the values from the form. This way we dont over write (eg blank out) any
    // properties not mentioned in this form
    Object.keys(this.configForm.controls)
      .forEach(key => {
        this.clientConfig[key] = this.configForm.get(key).value
      })

    // Save the distribution group.
    const saveActionId = StoreAccess.dispatch(AHubActions.clientConfigurationCommit(this.clientConfig));

    //Get an Observable which will indicate if this action is busy or not
    this.actionRequestActionStatus$ = this.requestActionMonitorService.requestActionStatusObservableByActionIdBusy(saveActionId);

    //Action request status
    this.actionRequestActionStatus$
      .pipe(takeUntil(componentDestroyStream(this)))
      .subscribe((actionRequestActionStatus) => {
        if (actionRequestActionStatus) {
          this.configForm.markAsPristine();
        }
      });
  }

  /**
   * Open the add domain dialog
   */
  addPermittedDomainClick($event) {

    let inputDialogVO: InputDialogVO = {
      inputValue: '',
      inputLabel: 'Domain'
    }
    let addDomainDialog: Observable<any> = this.dialogService.componentDialogOpen(
      "Add permitted domain",
      this.resolver.resolveComponentFactory(InputDialogComponent),
      'inputDialogVO',
      inputDialogVO,
      null,
      "Add");

    addDomainDialog.pipe(takeUntil(componentDestroyStream(this))).subscribe(result => {
      if (!result || !result.inputValue) {
        return;
      }

      if (!this.clientConfig.permittedInclusionListDomains) {
        this.clientConfig.permittedInclusionListDomains = [];
      }
      this.clientConfig.permittedInclusionListDomains.push(result.inputValue)
      this.formsSet(this.clientConfig);
      this.configForm.markAsDirty();
    });
  }

  /**
   * This handler is called when the user clicks on the delete button.
   */
  itemsDeleteHandler($event) {

    //Get the item array
    let itemArray: any[] = this.clientConfig.permittedInclusionListDomains;

    //We need to set the index for each item
    let index = 0;

    //The popup expects an object and to accuretly delete the items we need to store an index to the item
    //this will allow us to delete the item from the list later. Er must also have an id property for this
    //object or the popup will not work. So our index will be our id
    let createdObjects = itemArray.map(item => {

      //Create a new object with the index and the data item
      let newObj = { id: index, data: item };

      //Increase the item
      index++;

      //Return the new object
      return newObj;
    });

    // Open a new list dialog to get the correct distribution groups to assign the new distribution too.
    let dialogRef = this.dialogService
      .selectMultiListDialogOpen("Select Permitted Domains Inclusion List to Remove", undefined, "Permitted Domains Inclusion List", of(createdObjects), [], 'data', true, 'Remove').pipe(
        takeUntil(componentDestroyStream(this)));

    //Subscribe the dialogue for the results of the dialogue
    dialogRef.subscribe(results => {

      //Of we don't have results then bail out
      if (!results) return;

      //OK so we have selected some things to removed we will map this back to a list
      //of indexes to remove from the list
      let indexs = (results as any[]).map(result => result.id);

      //Remove the items
      this.itemsRemove(indexs);
    });
  }


  /**
   * Remove the item at the specified index from the list
   */
  itemsRemove(indexes: number[]) {

    //Get the item array
    let itemArray: any[] = this.clientConfig.permittedInclusionListDomains;

    //If the index is off either bounds then we cannot remove the item
    if (indexes == undefined || indexes.length <= 0 || itemArray == undefined)
      return;

    //Sort the indexes backwards so we can remove from the list without breaking the original list
    indexes.sort((a, b) => a > b ? -1 : ((a == b) ? 0 : 1));

    //Loop through each of the indexes
    indexes.forEach((index) => {

      //If the index is out of range if so skip over
      if (index < 0 || index >= itemArray.length)
        return;

      //Remove the item from the item array
      itemArray.splice(index, 1);
    });


    //Set the altered list of values in the form control
    this.clientConfig.permittedInclusionListDomains = itemArray;
    this.formsSet(this.clientConfig);

    //Mark the form as dirty
    this.configForm.markAsDirty();

  }



}

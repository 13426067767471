import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Hark, componentDestroyStream } from '../../hark.decorator';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.css']
})
@Hark()
export class InputDialogComponent implements OnInit {

  constructor(private readonly dialogRef: MatDialogRef<InputDialogComponent>) { }

  @Input()
  public inputDialogVO: InputDialogVO = null;

  /**
 * Boolean stream should emit values to indicate whether dialog is valid.
 */
  public dialogIsValid$: Subject<boolean> = new Subject<boolean>();

  inputDialogForm = new FormGroup({
    formInput: new FormControl()
  });

  ngOnInit() {

    this.inputDialogForm.controls.formInput.setValue(this.inputDialogVO.inputValue);

    if (this.inputDialogVO.inputRegex) {
      this.inputDialogForm.controls.formInput.setValidators(Validators.pattern(this.inputDialogVO.inputRegex));
    }

    this.inputDialogForm.controls.formInput.valueChanges.pipe(
      takeUntil(componentDestroyStream(this)))
      .subscribe(formInput => {
        //Update the form state as it has changed
        this.inputDialogVO.inputValue = formInput;
        this.dialogIsValid$.next(this.inputDialogForm.valid);
      });
  }

  ngOnDestroy() {

  }

  closeDialog() {
    if (this.inputDialogForm.valid) {
      this.dialogRef.close(this.inputDialogVO);
    }
  }
}


export interface InputDialogVO {
  inputValue: string;
  inputLabel: string;
  inputRegex?: RegExp
}
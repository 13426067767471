import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PropertyRuleAHubVO } from 'valueObjects/ahub/library/property-rule.ahub.vo';
import { Observable } from 'rxjs';
import { PropertyTestAHubVO } from 'app/valueObjects/ahub/library/property-test.ahub.vo';

@Component({
  selector: 'app-property-rule',
  templateUrl: './property-rule.component.html',
  styleUrls: ['./property-rule.component.css']
})
export class PropertyRuleComponent implements OnInit {

  @Input() propertyRule: PropertyRuleAHubVO;
  @Input() propertyRuleIndex: number;
  @Input() allowPropertyTestsToBeAdded: boolean;
  @Input() busy$: Observable<boolean>;
  @Output() addPropertyTest: EventEmitter<number> = new EventEmitter<number>();
  @Output() editPropertyTest: EventEmitter<PropertyTestByRuleIndexVO> = new EventEmitter<PropertyTestByRuleIndexVO>();
  @Output() deletePropertyTest: EventEmitter<PropertyTestByRuleIndexVO> = new EventEmitter<PropertyTestByRuleIndexVO>();
  @Output() deleteAllTests: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    // This is intentional
  }

  ngOnInit() {
  }

  addPropertyTestClickHandler() {
    this.addPropertyTest.emit(this.propertyRuleIndex);
  }

  propertyTestDelete(propertyTestIndex: number) {

    const propertyTestByRuleIndex: PropertyTestByRuleIndexVO = {
      propertyRuleIndex: this.propertyRuleIndex,
      propertyTestIndex: propertyTestIndex
    }

    this.deletePropertyTest.emit(propertyTestByRuleIndex);
  }

  editPropertyTestHandler(propertyTestIndex: number, propertyTestToBeEdited: PropertyTestAHubVO) {

    const propertyTestByRuleIndex: PropertyTestByRuleIndexVO = {
      propertyRuleIndex: this.propertyRuleIndex,
      propertyTestIndex: propertyTestIndex,
      propertyTest: propertyTestToBeEdited
    }

    this.editPropertyTest.emit(propertyTestByRuleIndex);
  }

  deleteAllTestsClickHandler() {

    // Dispatch the delete all tests event.
    this.deleteAllTests.emit(this.propertyRuleIndex);
  }
}

export interface PropertyTestByRuleIndexVO {
  propertyRuleIndex: number,
  propertyTestIndex: number,
  propertyTest?: PropertyTestAHubVO
}

import { AfterViewInit, Component, ViewChild, ViewContainerRef } from "@angular/core";

import { ICellEditorAngularComp } from "ag-grid-angular";
import { GridEditorParamsInterface } from '../grid-editor-params-interface';
import { ICellEditorParams } from 'ag-grid-community';

@Component({
  selector: 'app-grid-editor-select',
  templateUrl: './grid-editor-select.component.html',
  styleUrls: ['./grid-editor-select.component.css']
})
export class GridEditorSelectComponent implements ICellEditorAngularComp, AfterViewInit {

  @ViewChild('container', { read: ViewContainerRef, static: true }) public container;

  /**
   * Paramteres for this renderer
   */
  params: ICellEditorParams;

  /**
   * Params with our custom values, we seperate them as the the default value has a load of options which
   * we don't really want to concerne our selves with
   */
  paramsCustom: GridEditorSelectComponentParams

  /**
   * List of the value options for the form
   */
  valueOptions: GridEditorSelectComponentOption[];

  /**
   * The currently selected value
   */
  selectedValue: any = undefined;

  /**
   * Label for the currently selected item
   */
  selectedValueLabel: string = undefined;

  /**
   * Currently selected value in the renderer
   */
  selectedIndex = -1;

  /**
   * After the view has initalised then we will focus the element
   */
  ngAfterViewInit() {
    setTimeout(() => {
      this.container.element.nativeElement.focus();
    }, 200)
  }

  /**
   * Init of the renderer
   */
  agInit(params: any): void {

    //Set the parameters
    this.params = params;
    this.paramsCustom = params as GridEditorSelectComponentParams;

    //Set the values list
    this.valueOptions = (this.paramsCustom.values) ? this.paramsCustom.values : [];

    //Set the selected value
    this.selectedValue = this.params.value;
    this.selectedValueLabel = this.selectedValue;

    //Loop through looking for the currently selected item
    for (let i = 0; i < this.valueOptions.length; i++) {
      if (this.valueOptions[i].value === this.selectedValue) {
        this.selectedIndex = i;
        if (this.valueOptions[i].label) {
          this.selectedValueLabel = this.valueOptions[i].label;
        }
        break;
      }
    }
  }

  /**
   * Get the currently selected value
   */
  getValue(): any {
    return this.selectedValue;
  }

  /**
   * Is called when editing is about to start. Giving
   * us an opertunity to stop the editing before it happens
   */
  isCancelBeforeStart(): boolean {

    //Do we have a supplied cancel function in the parameters
    if (this.paramsCustom.canEditCellFunc) {
      return !this.paramsCustom.canEditCellFunc(this.params);
    }

    //No is cancel function so no cancel behaviour
    return false;
  }

  /**
   * This is a popup so thats that
   */
  isPopup(): boolean {
    return true;
  }

  /**
   * Set the selected value on click and prevent the editing
   */
  onClick(valueIndex: number) {

    //Set the selected value
    this.selectedIndex = valueIndex;

    //If we have a valid number we will get the currently selected value
    if (this.selectedIndex !== undefined && this.selectedIndex !== null && this.selectedIndex > -1 && this.selectedIndex < this.valueOptions.length) {
      this.selectedValue = this.valueOptions[this.selectedIndex].value;
    }

    //Option has been selected so we are done
    if (this.params && this.params.api) {
      this.params.api.stopEditing();
    }
  }
}

/**
 * Get the parameters
 */
export interface GridEditorSelectComponentParams extends GridEditorParamsInterface {
  values: GridEditorSelectComponentOption[];
}

/**
 * Get the grid editor
 */
export interface GridEditorSelectComponentOption {
  value: any;
  label: string;
}

import {
  Component, AfterViewInit, ComponentFactory,
  ComponentRef, ViewChild, ViewContainerRef
} from '@angular/core';
import { IFilterAngularComp } from 'ag-grid-angular';
import { IDoesFilterPassParams } from 'ag-grid-community';

@Component({
  selector: 'app-grid-filter-component',
  template: '<div #placeholder></div>',
  styleUrls: ['./grid-filter-component.component.css']
})
export class GridFilterComponentComponent implements IFilterAngularComp, AfterViewInit {

  @ViewChild('placeholder', {
    read: ViewContainerRef,
    static: true
  }) placeholder: ViewContainerRef;

  /**
   * Parameters for the component
   */
  params = undefined;

  /**
   * Parameters for the component
   */
  paramsWithCustom: GridFilterComponentParams = undefined;

  /**
   * The component factory passed in to create the components for object display.
   */
  componentFactory: ComponentFactory<IFilterAngularComp>;

  /**
   * The created component in the wrapper.
   */
  createdComponent: ComponentRef<IFilterAngularComp>;

  constructor() {
    // This is intentional
  }

  /**
   * Initalise the parameters
   */
  agInit(params: any): void {

    //Set the parameters
    this.params = params;
    this.paramsWithCustom = params as GridFilterComponentParams;
  }

  ngAfterViewInit(): void {

    // Create a component using the factory supplied in our wrapper.
    if (this.paramsWithCustom.factory) {

      //Set the factory
      this.componentFactory = this.paramsWithCustom.factory;

      //Create the component
      this.createdComponent = this.placeholder.createComponent(this.componentFactory);
    }

    //Do we have a created component
    if (this.createdComponent && this.createdComponent.instance && this.createdComponent.instance.agInit) {

      //Call the agInit with its parameters
      this.createdComponent.instance.agInit(this.params);
    }
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    return (this.createdComponent && this.createdComponent.instance &&
      this.createdComponent.instance.doesFilterPass) ? this.createdComponent.instance.doesFilterPass(params) : true;
  }

  isFilterActive(): boolean {
    return (this.createdComponent && this.createdComponent.instance &&
      this.createdComponent.instance.isFilterActive) ? this.createdComponent.instance.isFilterActive() : false;
  }

  getModel() {
    return (this.createdComponent && this.createdComponent.instance &&
      this.createdComponent.instance.getModel) ? this.createdComponent.instance.getModel() : undefined;
  }

  setModel(model: any): void {
    if (this.createdComponent && this.createdComponent.instance && this.createdComponent.instance.setModel) {
      this.createdComponent.instance.setModel(model);
    }
  }

  onNewRowsLoaded?(): void {
    if (this.createdComponent && this.createdComponent.instance && this.createdComponent.instance.onNewRowsLoaded) {
      this.createdComponent.instance.onNewRowsLoaded();
    }
  }


  getModelAsString?(model: any): string {
    return (this.createdComponent && this.createdComponent.instance &&
      this.createdComponent.instance.getModelAsString) ? this.createdComponent.instance.getModelAsString(model) : undefined;
  }
}

/**
 * Custom interface for the grid filter factory
 */
export interface GridFilterComponentParams {
  factory: ComponentFactory<IFilterAngularComp>;
}

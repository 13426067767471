/**
 * --------------------------------------------------
 * State Imports
 * --------------------------------------------------
 */

import { ComponentState } from 'reducers/component/component.reducer';

/**
 * --------------------------------------------------
 * Value Object Imports
 * --------------------------------------------------
 */

/**
 * --------------------------------------------------
 * Core Selectors
 * --------------------------------------------------
 */

/**
 * Root selector to get the component state from the store state
 */
export const componentState = (state): ComponentState => state.component;

/**
 * --------------------------------------------------
 * Selectors
 * --------------------------------------------------
 */

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-product-property-section-index',
  templateUrl: './product-property-section-index.component.html',
  styleUrls: ['./product-property-section-index.component.css']
})
export class ProductPropertySectionIndexComponent {

  @Input() productPropertySectionIndexAHubVO: any = {
    id: 0,
    label: "",
    tag: "",
    description: ""
  }

  constructor() {
    // This is intentional
  }
}

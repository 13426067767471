import { Reducer } from 'redux';
import { tassign } from 'modules/common/type-assign.util';

import {
    ComponentTreeStateReducer,
    ComponentTreeState,
    ComponentTreeInitialState,
    ComponentTreeStateHasHandler
} from 'reducers/component/component-tree.reducer';

import {
    ComponentBulkTransferStateReducer,
    ComponentBulkTransferState,
    ComponentBulkTransferInitialState,
    ComponentBulkTransferStateHasHandler
} from 'reducers/component/component-bulk-transfers.reducer';

import {
    ComponentDataSetProductsInitialState, ComponentDataSetProductsState,
    ComponentDataSetProductsStateReducer, ComponentDataSetProductsStateHasHandler
} from './component-data-set-products.reducer';

/**
 * ----------------------------------
 * State interface
 * ----------------------------------
 */
export interface ComponentState {
    tree: ComponentTreeState;
    bulkTransfer: ComponentBulkTransferState;
    dataSetProducts: ComponentDataSetProductsState;
};

/**
 * ----------------------------------
 * Initial State
 * ----------------------------------
 */
const initialState: ComponentState = {
    tree: ComponentTreeInitialState,
    bulkTransfer: ComponentBulkTransferInitialState,
    dataSetProducts: ComponentDataSetProductsInitialState
};

/**
 * ----------------------------------
 * State Reducer
 * ----------------------------------
 */
export const ComponentReducer: Reducer<ComponentState> = (state: ComponentState = initialState, action): ComponentState => {

    //We will test each branch of the tree to see if the reducer down that branch can the supplied action

    //Does the tree component reducer of the store have a handler for this action type
    if (ComponentTreeStateHasHandler(action.type)) {
        return tassign(state, { tree: ComponentTreeStateReducer(state.tree, action) });
    }

    //Does the bulk transfer component reducer of the store have a handler for this action type
    if (ComponentBulkTransferStateHasHandler(action.type)) {
        return tassign(state, { bulkTransfer: ComponentBulkTransferStateReducer(state.bulkTransfer, action) });
    }

    //Add in a handler for the data set products
    if (ComponentDataSetProductsStateHasHandler(action.type)) {
        return tassign(state, { dataSetProducts: ComponentDataSetProductsStateReducer(state.dataSetProducts, action) });
    }

    //Nothing can handel this action return the action state unchanged
    return state;
};
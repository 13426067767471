import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
// import { MaterialModule } from '@angular/material';

import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'modules/shared/shared.module';
import { PipesModule } from 'modules/pipes/pipes.module';
import { DialogService } from 'modules/common/dialogs/dialog.service';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { ComponentDialogComponent } from './component-dialog/component-dialog.component';
import { ComponentDialogInjectedWrapperComponent } from './component-dialog/component-dialog.injected-wrapper.component';
import { InfoDialogComponent } from './info-dialog/info-dialog.component';
import { SelectMultiListDialogComponent } from './select-multi-list-dialog/select-multi-list-dialog.component';
import { SelectSingleListDialogComponent } from './select-single-list-dialog/select-single-list-dialog.component';
import { FileSelectDialogComponent } from './file-select-dialog/file-select-dialog.component';
import { FileSaveDialogComponent } from './file-save-dialog/file-save-dialog.component';
import { DistributionRulesDialogComponent } from './distribution-rules-dialog/distribution-rules-dialog.component';

import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { KnowledgeBaseDialogComponent } from './knowledge-base-dialog/knowledge-base-dialog.component';
import { ContactSupportDialogComponent } from './contact-support-dialog/contact-support-dialog.component';
import { ProductAssetViewDialogImageComponent } from './product-asset-view-dialog/product-asset-view-dialog-image/product-asset-view-dialog-image.component';
import { ProductAssetViewDialogVideoComponent } from './product-asset-view-dialog/product-asset-view-dialog-video/product-asset-view-dialog-video.component';
import { ProductAssetViewDialogFlickbookComponent } from './product-asset-view-dialog/product-asset-view-dialog-flickbook/product-asset-view-dialog-flickbook.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { InputDialogComponent } from './input-dialog/input-dialog.component';
import { MultipleAddDialogComponent } from './multiple-add-dialog/multiple-add-dialog.component';
import { ClientLogDialogComponent } from './client-log-dialog/client-log-dialog.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ConfigureLibraryViewDialogComponent } from './configure-library-view-dialog/configure-library-view-dialog.component';
import { VideoEmbedDialogComponent } from './video-embed-dialog/video-embed-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatCheckboxModule,
    MatRadioModule,
    MatChipsModule,
    MatButtonModule,
    MatListModule,
    MatDialogModule,
    MatInputModule,
    MatTooltipModule,
    MatExpansionModule,
    // MaterialModule,
    SharedModule,
    PipesModule,
    MatSlideToggleModule
  ],
  exports: [
    AlertDialogComponent,
    ConfirmDialogComponent,
    ComponentDialogComponent,
    ComponentDialogInjectedWrapperComponent,
    InfoDialogComponent,
    KnowledgeBaseDialogComponent,
    SelectMultiListDialogComponent,
    SelectSingleListDialogComponent,
    FileSelectDialogComponent,
    FileSaveDialogComponent,
    DistributionRulesDialogComponent,
    ContactSupportDialogComponent,
    ProductAssetViewDialogImageComponent
  ],
  providers: [
    DialogService,
  ],
  declarations: [
    AlertDialogComponent,
    ConfirmDialogComponent,
    ComponentDialogComponent,
    ComponentDialogInjectedWrapperComponent,
    InfoDialogComponent,
    KnowledgeBaseDialogComponent,
    SelectMultiListDialogComponent,
    SelectSingleListDialogComponent,
    FileSelectDialogComponent,
    AlertDialogComponent,
    ComponentDialogComponent,
    FileSaveDialogComponent,
    DistributionRulesDialogComponent,
    KnowledgeBaseDialogComponent,
    ContactSupportDialogComponent,
    ProductAssetViewDialogImageComponent,
    ProductAssetViewDialogVideoComponent,
    ProductAssetViewDialogFlickbookComponent,
    ErrorDialogComponent,
    InputDialogComponent,
    MultipleAddDialogComponent,
    ConfigureLibraryViewDialogComponent,
    ClientLogDialogComponent,
    ConfigureLibraryViewDialogComponent,
    VideoEmbedDialogComponent,
  ],
  entryComponents: [
    VideoEmbedDialogComponent,
    AlertDialogComponent,
    ConfirmDialogComponent,
    ComponentDialogComponent,
    InfoDialogComponent,
    KnowledgeBaseDialogComponent,
    SelectMultiListDialogComponent,
    SelectSingleListDialogComponent,
    FileSelectDialogComponent,
    FileSaveDialogComponent,
    DistributionRulesDialogComponent,
    ContactSupportDialogComponent,
    ProductAssetViewDialogImageComponent,
    ProductAssetViewDialogVideoComponent,
    ProductAssetViewDialogFlickbookComponent,
    ErrorDialogComponent,
    InputDialogComponent,
    ConfigureLibraryViewDialogComponent,
    MultipleAddDialogComponent,
    ClientLogDialogComponent,
  ]
})
export class DialogModule { }

import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '../common/utils';

@Pipe({
  name: 'friendlySpacePipe'
})
export class FriendlySpacePipe implements PipeTransform {

  transform(value: string, args?: any): any {
    if (value) {
      return value.replace(/&nbsp;/g, '<SPACE>');
    }
  }

}

/**
 * Wrapper for the component that is passed in to display
 * within the dialog.
 */
import { Component, ComponentFactory, Input, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
// Hark component management
import { componentDestroyStream, Hark } from 'modules/common/hark.decorator';
import { Observable, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * Complete is initilised with a placeholder <div>
 */
@Component({
    selector: 'app-component-dialog-injected-wrapper',
    template: '<div #placeHolder></div>',
    styleUrls: ['./component-dialog.component.css'],
    host: {
        'class': 'PopUpDialog'
    }
})
@Hark()
export class ComponentDialogInjectedWrapperComponent implements OnInit, OnDestroy {

    // The component factory passed in to create the components for object display.
    @Input() componentFactory: ComponentFactory<any>; //Class?

    // The Value object to be redered.
    @Input() dataVO: any;

    // The parameter of the created component to which to pass the VO data.
    @Input() paramNameVO: string;

    // The param name for teh boolean stream which will indicated if the dialog is valid
    // and can "OKed".
    @Input() paramNameDialogValid: string;

    //The configuration parameter name so we can pass the configuration object
    @Input() configurationParameter: string;

    //The configuration object we will pass to the parameter specified above
    @Input() configurationObject: string;

    //The dialog configuration object used to config the whole dialog container
    @Input() dialogConfig: MatDialogConfig;

    // The created component in the wrapper.
    public createdComponent: any;

    // A observable that can be used to check if the dialog is valid.
    public dialogValid$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(private readonly viewContainerRef: ViewContainerRef) { }

    ngOnInit() {

        // Create a component using the factory supplied in our wrapper.
        this.createdComponent = this.viewContainerRef.createComponent(this.componentFactory);

        // Apply VO data to the component for display ( if the insertion parameter exists. )
        if (this.createdComponent.instance.hasOwnProperty(this.paramNameVO)) {
            this.createdComponent.instance[this.paramNameVO] = this.dataVO;
        }
        else {

            //We will only display an error if the parameter has been defined
            if (this.paramNameVO !== undefined) {
                console.log("Dialog Component Wrapper: Cant find property " + this.paramNameVO +
                    " in component to pass in VO. Check it exists in component and its set to something" +
                    " , even null so we know it exists.");
            }
        }

        // Apply configuration data to the component ( if the insertion parameter exists. )
        if (this.configurationParameter !== null && this.createdComponent.instance.hasOwnProperty(this.configurationParameter)) {
            this.createdComponent.instance[this.configurationParameter] = this.configurationObject;
        }
        else {

            //We will only display an error if the parameter has been defined
            if (this.configurationParameter !== null) {
                console.log("Dialog Component Wrapper: Cant find property " + this.configurationParameter +
                    " in component to pass dialog configuration. Check it exists in component and its set to something" +
                    " , even null so we know it exists.");
            }
        }

        // Subscribe to the to created component to watch for validity for ok'ing.
        if (this.createdComponent.instance.hasOwnProperty(this.paramNameDialogValid)) {

            // Any changes to the component validity re-emit ourselves.
            const validStream: Observable<boolean> = this.createdComponent.instance[this.paramNameDialogValid];

            //Subscribe to the stream so we can set our valid stream
            validStream.pipe(takeUntil(componentDestroyStream(this)))
                .subscribe(valid => this.dialogValid$.next(valid));
        }
    }

    /**
     * Empty On destroy to ensure @Hark decorator works for an AOT build
     */
    ngOnDestroy() { }

    /**
     * Returns the VO passed into the created component which has now been potentially modified ( or replaced. )
     */
    public getVO(): any {
        return this.createdComponent.instance[this.paramNameVO];
    }

    /**
     * Returns the configuration passed into the created component which has now been potentially modified ( or replaced. )
     */
    public getConfiguration(): any {
        return this.createdComponent.instance[this.configurationParameter];
    }
}
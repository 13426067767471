import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { AppActions } from 'actions/app.actions';
import { StoreAccess } from 'store/store-access';

@Component({
  selector: 'app-grid-renderer-text-copy-clipboard',
  templateUrl: './grid-renderer-text-copy-clipboard.component.html',
  styleUrls: ['./grid-renderer-text-copy-clipboard.component.css']
})
export class GridRendererTextCopyClipboardComponent implements AgRendererComponent {

  /**
   * Parameters for the render
   */
  params: ICellRendererParams;

  /**
   * Value which we want to display
   */
  valueToDisplay: any;

  /**
   * Display the copy button or not
   */
  displayCopyButton = false;

  constructor() {
    // This is intentional
  }

  /**
   * Call after the init function
   */
  agInit(params: ICellRendererParams): void {

    //Set the parameters for the render
    this.params = params;

    //Update the value to reflect the value of the cell
    this.updateValue();
  }

  /**
   * Refresh the renderer
   */
  refresh(params: ICellRendererParams): boolean {

    //Set the new set of parameters
    this.params = params;

    this.displayCopyButtonHandler(false);

    //Update the value to reflect the value of the cell
    this.updateValue();

    return true;
  }

  /**
   * Update the value we are going to display
   */
  updateValue() {

    //Set the value to display
    this.valueToDisplay = (this.params.getValue) ? this.params.getValue() : this.params.value;
  }

  /**
   * Function to be called if we want to display the copy button
   */
  displayCopyButtonHandler(display) {

    //Display the copy button
    this.displayCopyButton = display;
  }

  /**
   * Copy the value to the clipboad
   */
  copyValueToClipboard() {

    //Distpatch a copy to the clipboard action
    StoreAccess.dispatch(AppActions.clipboardTextCopySet(this.valueToDisplay));
  }
}

export class ArrayUtils {


    /**
     * This function will merge an array of arrays and remove all duplicates.
     * 
     * @param arrays            The arrays to merge.
     */
    public static mergeNoDuplicates(arrays: any[][]): any[] {

        // Create a set to add the values too.
        const set = new Set();

        // Now add each part of the each arrays.
        arrays.forEach(array => array.forEach(item => set.add(item)));

        // Now return the array.
        return Array.from(set);
    }
}

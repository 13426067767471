import { Pipe, PipeTransform } from '@angular/core';
import { ExportVersionStateAHubEnum } from 'app/valueObjects/ahub/accounts/export-version-state.ahub.enum';

@Pipe({
    name: 'exportVersionStateToText'
})
export class ExportVersionStateToTextPipe implements PipeTransform {

    transform(value: string, args?: any): any {

        // Create the string to return.
        let returnValue: string = "";

        // What's the export version state.
        switch (value) {

            // Is it the limited release state?
            case ExportVersionStateAHubEnum.LIMITED_RELEASE:

                // Yes, so tidy it up.
                returnValue = "LIMITED RELEASE";

                // Job done.
                break;

            // All other states.
            default:

                // Use the current state.
                returnValue = value;

                break;
        }

        // Return the value created.
        return returnValue;
    }
}

/**
 * ----------------------------------------------------------
 * Action Types
 * ----------------------------------------------------------
 */
import {
  ActionBoolean, ActionNumber,
  ActionNumberArray, ActionObject,
  ActionString, ActionStringArray, ActionBooleanNumber
} from "actions/types/common.action-types";
/**
 * Value Objects
 */
import {
  ActionDataSetCategoryIndexAHubVOs, ActionExporterBlueprintMappingNodesAHubVO,
  ActionExporterMappingClassOptionAHubVOs, ActionExtractAHubVOs, ActionExtractDefinitionPropertyAHubVO,
  ActionExtractDefinitionPropertyAHubVOs, ActionExtractIndexAHubVOs, ActionProductAHubVOs,
  ActionProductAssetViewVO, ActionProductAssetViewVOs, ActionProductClassIndexAHubVOs,
  ActionProductPropertyAllocationChainValidateReportAHubVO,
  ActionProductPropertyAllocationChainValueCommitAHubVOs,
  ActionProductPropertyIndexAHubVOs,
  ActionExtractDefinitionPropertyAllocationObjectVOArray
} from "actions/types/library.action-types";
import { ActionComponentStateViewVO, ActionFormState, ActionRouteViewVOs, ActionRouteViewVO, ActionPropertyAllocationObjectAViewVOs } from "actions/types/view.action-types";
import { ActionWork, ActionWorkPayload } from "actions/types/work.action-types";
import { DistributionGroupIndexAHubVO } from "app/valueObjects/ahub/accounts/distribution-group-index.ahub.vo";
import { ExporterMappingClassOptionAHubVO } from "app/valueObjects/ahub/library/exporter-mapping-class-option.ahub.vo";
import { ProductPropertyIndexAHubVO } from "app/valueObjects/ahub/library/product-property-index.ahub.vo";
import { BlueprintMappingNode } from "app/valueObjects/view/blueprint-mapping-node.ahub.vo";
import { DataSetCategoryIndexAHubVO } from "valueObjects/ahub/library/dataset-category-index.ahub.vo";
import { ExtractDefinitionPropertyAHubVO } from "valueObjects/ahub/library/extract-definition-property.ahub.vo";
import { ExtractIndexAHubVO } from "valueObjects/ahub/library/extract-index.ahub.vo";
import { ExtractAHubVO } from "valueObjects/ahub/library/extract.ahub.vo";
import { ProductClassIndexAHubVO } from "valueObjects/ahub/library/product-class-index.ahub.vo";
import { ProductPropertyAllocChainValidateReportAHubVO } from "valueObjects/ahub/library/product-property-alloc-chain-validate-report.ahub.vo";
import { ProductPropertyAllocChainValueCommitAHubVO } from "valueObjects/ahub/library/product-property-alloc-chain-value-commit.ahub";
import { ProductAHubVO } from "valueObjects/ahub/library/product.ahub.vo";
import { ComponentStateViewVO } from "valueObjects/view/component-state.view.vo";
import { FormStateViewVO } from "valueObjects/view/form-state.view.vo";
import { ProductAssetViewVO } from "valueObjects/view/product-asset-view.vo";
import { RouteViewVO } from "valueObjects/view/route.view.vo";
import { ActionDistributionGroupIndexAHubVOs, ActionExportVersionAHubVO } from "./types/ahub-accounts.action-types";
import { ExtractDefinitionPropertyAllocationObjectVO } from 'app/valueObjects/view/extract-definition-property-allocation.view.vo';
import { ExportVersionAHubVO } from 'app/valueObjects/ahub/accounts/export-version.ahub.vo';
import { PropertyAllocationObjectVO } from 'app/valueObjects/stream/product-allocation-object-stream.vo';
import { PropertyAllocationObjectAViewVO } from 'app/modules/routes/aview/valueObjects/property-allocation-object.aview.vo';

/**
 * View Actions class
 */
export class ViewActions {

  /**
   * Event Type statics
   */
  static LOGIN_CLEAR = "[VIEW] LOGIN_CLEAR";
  static LOGIN_URL_SET = "[VIEW] LOGIN_URL_SET";
  static LOGIN_TOKEN_VALIDATED_SET = "[VIEW] LOGIN_AUTH_TOKEN_VALIDATED_SET";
  static DISTRIBUTION_GROUP_SELECTED_ID_SET = "[VIEW] DISTRIBUTION_GROUP_SELECTED_ID_SET";
  static DISTRIBUTION_GROUP_USER_SELECTED_ID_SET = "[VIEW] DISTRIBUTION_GROUP_USER_SELECTED_ID_SET";
  static DISTRIBUTION_GROUP_DISTRIBUTION_SELECTED_ID_SET = "[VIEW] DISTRIBUTION_GROUP_DISTRIBUTION_SELECTED_ID_SET";
  static DISTRIBUTION_GROUP_EXPORT_TYPE_SELECTED_ID = "[VIEW] DISTRIBUTION_GROUP_EXPORT_TYPE_SELECTED_ID";
  static EXPORT_SELECTED_ID_SET = "[VIEW] EXPORT_SELECTED_ID_SET";
  static EXPORT_DISTRIBUTION_SELECTED_ID_SET =
    "[VIEW] EXPORT_DISTRIBUTION_SELECTED_ID_SET";
  static EXPORT_VERSION_SELECTED_ID_SET =
    "[VIEW] EXPORT_VERSION_SELECTED_ID_SET";
  static EXPORT_VERSIONS_HIDE_STATES = "[VIEW] EXPORT_VERSIONS_HIDE_STATES";
  static DISTRIBUTION_SELECTED_ID_SET = "[VIEW] DISTRIBUTION_SELECTED_ID_SET";

  static LIBRARY_PUBLISHING_DATASET_SELECTED_ID_SET =
    "[VIEW] LIBRARY_PUBLISHING_DATASET_SELECTED_ID_SET";
  static LIBRARY_PUBLISHING_DATASET_CATEGORY_INDEXES_SELECTED_SET =
    "[VIEW] LIBRARY_PUBLISHING_DATASET_CATEGORIES_SELECTED_SET";

  static LIBRARY_PUBLISHING_EXPORTER_SELECTED_ID_SET =
    "[VIEW] LIBRARY_PUBLISHING_EXPORTER_SELECTED_ID_SET";
  static LIBRARY_PUBLISHING_EXPORTER_DESTINATION_SELECTED_ID_SET =
    "[VIEW] LIBRARY_PUBLISHING_EXPORTER_DELIVERY_TARGET_SELECTED_ID_SET";
  static LIBRARY_PUBLISHING_EXPORTER_BUILD_HISTORY_SELECTED_ID_SET =
    "[VIEW] LIBRARY_PUBLISHING_EXPORTER_BUILD_HISTORY_SELECTED_ID_SET";
  static LIBRARY_PUBLISHING_SELECTED_EXPORTER_ALLOCS_SET =
    "[VIEW] LIBRARY_PUBLISHING_SELECTED_EXPORTER_ALLOCS_SET";

  static LIBRARY_PUBLISHING_RESOURCE_PACK_SELECTED_ID_SET =
    "[VIEW] LIBRARY_PUBLISHING_RESOURCE_PACK_SELECTED_ID_SET";

  static LIBRARY_PUBLISHING_EXPORTER_BLUEPRINT_TREE_NODE_SELECTED_PATH_SET =
    "[VIEW] LIBRARY_PUBLISHING_EXPORTER_BLUEPRINT_TREE_NODE_SELECTED_PATH_SET";
  static LIBRARY_PUBLISHING_EXPORTER_BLUEPRINT_TREE_NODE_SELECTED_PATH_CLEAR =
    "[VIEW] LIBRARY_PUBLISHING_EXPORTER_BLUEPRINT_TREE_NODE_SELECTED_PATH_CLEAR";
  static LIBRARY_PUBLISHING_EXPORTER_BLUEPRINT_MAPPING_NODES_SET =
    "[VIEW] LIBRARY_PUBLISHING_EXPORTER_BLUEPRINT_MAPPING_NODES_SET";
  static LIBRARY_PUBLISHING_EXPORTER_BLUEPRINT_MAPPING_NODES_CLEAR =
    "[VIEW] LIBRARY_PUBLISHING_EXPORTER_BLUEPRINT_MAPPING_NODES_CLEAR";
  static LIBRARY_PUBLISHING_EXPORTER_MAPPING_CLASS_OPTIONS_SET =
    "[VIEW] LIBRARY_PUBLISHING_EXPORTER_CLASS_OPTIONS_SET";
  static LIBRARY_PUBLISHING_EXPORTER_MAPPING_CLASS_OPTIONS_CLEAR =
    "[VIEW] LIBRARY_PUBLISHING_EXPORTER_MAPPING_CLASS_OPTIONS_CLEAR";
  static LIBRARY_PUBLISHING_EXPORTER_MAPPING_CLASS_ID_SELECTED_SET =
    "[VIEW] LIBRARY_PUBLISHING_EXPORTER_CLASS_ID_SELECTED_SET";

  static CLIENT_LIBRARY_VERSION_SELECTED_SET =
    "[VIEW] CLIENT_LIBRARY_VERSION_SELECTED_SET";
  static WORK_GROUP_SELECTED_ID_SET = "[VIEW] WORK_GROUP_SELECTED_ID_SET";
  static ROUTES_ROUTER_LINK_SET = "[VIEW] ROUTES_ROUTER_LINK_SET";
  static AVAILABLE_ROUTES_SET = "[VIEW] AVAILABLE_ROUTES_SET";
  static SELECTED_ROUTE_VIEW_SET = "[VIEW] SELECTED_ROUTE_VIEW_SET";

  static ACCOUNT_MANAGEMENT_CLIENT_SELECTED_ID_SET = "[VIEW] ACCOUNT_MANAGEMENT_CLIENT_SELECTED_ID_SET";
  static ACCOUNT_MANAGEMENT_USER_SELECTED_ID_SET = "[VIEW] ACCOUNT_MANAGEMENT_USER_SELECTED_ID_SET";
  static SELECTED_USER_DISTRIBUTION_GROUP_INDEXS_SET = '[AHUB] SELECTED_USER_DISTRIBUTION_GROUP_INDEXS_SET';


  static PAGE_FORM_STATE_SET = "[VIEW] PAGE_FORM_STATE_SET";
  static PAGE_FORM_STATE_FORCE_RESET_BY_IDS =
    "[VIEW] PAGE_FORM_STATE_FORCE_RESET_BY_IDS";
  static PAGE_FORM_STATE_REMOVE_BY_ID = "[VIEW] PAGE_FORM_STATE_REMOVE_BY_ID";

  static PAGE_COMPONENT_STATE_SET = "[VIEW] PAGE_COMPONENT_STATE_SET";
  static PAGE_COMPONENT_STATE_REMOVE_BY_ID =
    "[VIEW] PAGE_COMPONENT_STATE_REMOVE_BY_ID";

  static CLIENT_QUOTA_SELECTED_ID_SET = "[VIEW] CLIENT_QUOTA_SELECTED_ID_SET";
  static LIBRARY_CLASSIFICATION_PRODUCT_SECTION_ID_SET =
    "[VIEW] LIBRARY_CLASSIFICATION_PRODUCT_SECTION_ID_SET";
  static LIBRARY_CLASSIFICATION_PRODUCT_CLASSES_SELECTED_SET =
    "[VIEW] LIBRARY_CLASSIFICATION_PRODUCT_CLASSES_SELECTED_SET";
  static LIBRARY_CLASSIFICATION_PRODUCT_PROPERTY_INDEXES_SET =
    "[VIEW] LIBRARY_CLASSIFICATION_ALLOC_INDEXES_SET";

  static LIBRARY_CONFIG_PRODUCTS_CHAINING_PRODUCT_CLASSES_SELECTED_SET =
    "[VIEW] LIBRARY_CONFIG_PRODUCTS_CHAINING_PRODUCT_CLASSES_SELECTED_SET";
  static LIBRARY_CONFIG_PRODUCTS_CHAINING_PRODUCT_ALLOC_CHAIN_SELECTED_ID_SET =
    "[VIEW] LIBRARY_CONFIG_PRODUCTS_CHAINING_PRODUCT_ALLOC_CHAIN_SELECTED_ID_SET";
  static LIBRARY_CONFIG_PRODUCTS_CHAINING_PRODUCT_ALLOC_CHAIN_SELECTED_VALUE_IDS_SET =
    "[VIEW] LIBRARY_CONFIG_PRODUCTS_CHAINING_PRODUCT_ALLOC_CHAIN_SELECTED_VALUE_IDS_SET";
  static LIBRARY_CONFIG_PRODUCTS_CHAINING_PRODUCT_ALLOC_CHAIN_SELECTED_VALUE_IDS_CLEAR =
    "[VIEW] LIBRARY_CONFIG_PRODUCTS_CHAINING_PRODUCT_ALLOC_CHAIN_SELECTED_VALUE_IDS_CLEAR";
  static LIBRARY_CONFIG_PRODUCTS_CHAINING_PRODUCT_ALLOC_CHAIN_CHANGED_VALUES_SET =
    "[VIEW] LIBRARY_CONFIG_PRODUCTS_CHAINING_PRODUCT_ALLOC_CHAIN_CHANGED_VALUES_SET";
  static LIBRARY_CONFIG_PRODUCTS_CHAINING_PRODUCT_ALLOC_CHAIN_CHANGED_VALUES_REMOVE =
    "[VIEW] LIBRARY_CONFIG_PRODUCTS_CHAINING_PRODUCT_ALLOC_CHAIN_CHANGED_VALUES_REMOVE";
  static LIBRARY_CONFIG_PRODUCTS_CHAINING_PRODUCT_ALLOC_CHAIN_CHANGED_VALUES_CLEAR =
    "[VIEW] LIBRARY_CONFIG_PRODUCTS_CHAINING_PRODUCT_ALLOC_CHAIN_CHANGED_VALUES_CLEAR";
  static LIBRARY_CONFIG_PRODUCT_PROPERTY_ALLOCATION_CHAIN_VALUE_VALUES_SET =
    "[VIEW] LIBRARY_CONFIG_PRODUCT_PROPERTY_ALLOCATION_CHAIN_VALUE_VALUES_SET";
  static LIBRARY_CONFIG_PRODUCT_PROPERTY_ALLOCATION_CHAIN_VALIDATE_REPORT_SET =
    "[VIEW] LIBRARY_CONFIG_PRODUCT_PROPERTY_ALLOCATION_CHAIN_VALIDATE_REPORT_SET";

  static LIBRARY_EXTRACTS_DEFINITION_SELECTED_ID_SET =
    "[VIEW] LIBRARY_EXTRACTS_DEFINITION_SELECTED_ID_SET";
  static LIBRARY_EXTRACTS_PRODUCT_CLASS_WRAPPER_SET =
    "[VIEW] LIBRARY_EXTRACTS_PRODUCT_CLASS_WRAPPER_SET";
  static LIBRARY_EXTRACTS_PRODUCT_CLASS_WRAPPER_UPDATE =
    "[VIEW] LIBRARY_EXTRACTS_PRODUCT_CLASS_WRAPPER_UPDATE";
  static LIBRARY_EXTRACT_DEFINITION_PROPERTY_SELECTED_ID_SET =
    "[VIEW] LIBRARY_EXTRACT_DEFINITION_PROPERTY_SELECTED_ID_SET";
  static LIBRARY_EXTRACT_DEFINITION_EXTRACT_INDEX_SET =
    "[VIEW] LIBRARY_EXTRACT_DEFINITION_EXTRACT_INDEX_SET";
  static LIBRARY_EXTRACT_DEFINITION_PROPERTY_SET =
    "[VIEW] LIBRARY_EXTRACT_DEFINITION_PROPERTY_SET";
  static LIBRARY_EXTRACT_DEFINITION_PRODUCT_IDENTIFIER_PROPERTY_SET =
    "[VIEW] LIBRARY_EXTRACT_DEFINITION_PRODUCT_IDENTIFIER_PROPERTY_SET";
  static LIBRARY_EXTRACT_DEFINITION_PROPERTY_UPDATE =
    "[VIEW] LIBRARY_EXTRACT_DEFINITION_PROPERTY_UPDATE";
  static LIBRARY_EXTRACT_DEFINITION_PROPERTIES_UPDATE =
    "[VIEW] LIBRARY_EXTRACT_DEFINITION_PROPERTIES_UPDATE";
  static LIBRARY_EXTRACT_DEFINITION_PROPERTY_DELETE =
    "[VIEW] LIBRARY_EXTRACT_DEFINITION_PROPERTY_DELETE";
  static LIBRARY_EXTRACT_SHOW_ASSETS_SET =
    "[VIEW] LIBRARY_EXTRACT_SHOW_ASSETS_SET";
  static LIBRARY_EXTRACT_SHOW_COLUMNS_SET =
    "[VIEW] LIBRARY_EXTRACT_SHOW_COLUMNS_SET";
  static LIBRARY_EXTRACT_ASSET_SWAP_SOURCE_SET =
    "[VIEW] LIBRARY_EXTRACT_ASSET_SWAP_SOURCE_SET";
  static LIBRARY_EXTRACT_ASSET_SWAP = "[VIEW] LIBRARY_EXTRACT_ASSET_SWAP";
  static LIBRARY_EXTRACT_PRODUCT_SELECTED_IDS_SET =
    "[VIEW] LIBRARY_EXTRACT_PRODUCT_SELECTED_IDS_SET";
  static LIBRARY_EXTRACT_DISPLAYED_COLUMNS_SET =
    "[VIEW] LIBRARY_EXTRACT_DISPLAYED_COLUMNS_SET";

  static LIBRARY_EXTRACTS_SELECTED_ID_SET =
    "[AHUB] LIBRARY_EXTRACTS_SELECTED_ID_SET";
  static LIBRARY_EXTRACT_CONFLICTS_SET = "[VIEW] LIBRARY_EXTRACT_CONFLICTS_SET";

  static DRAGGED_OBJECT_SET = "[VIEW] DRAGGED_OBJECT_SET";
  static DRAGGED_OBJECT_CLEAR = "[VIEW] DRAGGED_OBJECT_CLEAR";
  static DEBUG_REQUEST_SEND = "[VIEW] DEBUG_REQUEST_SEND";
  static DEBUG_RESPONCE_APPEND = "[VIEW] DEBUG_RESPONCE_APPEND";
  static PROPERTY_SELECTED_ID_SET = "[VIEW] PROPERTY_SELECTED_ID_SET";
  static PRODUCT_SELECTED_SECTION_PROPERTY_ID_SET =
    "[VIEW] PRODUCT_SELECTED_SECTION_PROPERTY_ID_SET";
  static EXTRACT_PRODUCTS_CHANGE_SET_SET =
    "[AHUB] EXTRACT_PRODUCTS_CHANGE_SET_SET";
  static EXTRACT_PRODUCTS_CHANGE_SET_CLEAR =
    "[AHUB] EXTRACT_PRODUCTS_CHANGE_SET_CLEAR";
  static EXTRACT_PRODUCTS_CHANGE_SET_REMOVE_BY_IDS =
    "[AHUB] EXTRACT_PRODUCTS_CHANGE_SET_REMOVE_BY_IDS";
  static LIBRARY_EXTRACT_ALLOCATION_ID_SET = "[VIEW] LIBRARY_EXTRACT_ALLOCATION_ID_SET";
  static LIBRARY_EXTRACT_ALLOCATION_DOCUMENT_SETTING_OPEN_CLOSE = "[VIEW] LIBRARY_EXTRACT_ALLOCATION_DOCUMENT_SETTING_OPEN_CLOSE";

  static GRID_VIEW_ITEMS_PER_ROW_SET = "[VIEW] GRID_VIEW_ITEMS_PER_ROW_SET";
  static INFINITY_SCROLLER_CURRENT_VIEW_START = "[VIEW] INFINITY_SCROLLER_CURRENT_VIEW_START";

  // This action will clear the login view state.
  static loginClear(): ActionWork {
    // Constuct action with the specified interface.
    return {
      type: ViewActions.LOGIN_CLEAR
    };
  }

  /**
   * Set login identifier provider URL.
   */
  static loginURLSet(loginURL: string): ActionString {
    //Constuct action with the specified interface
    return {
      type: ViewActions.LOGIN_URL_SET,
      string: loginURL
    };
  }

  /**
   * Create an instance of the action to set the auth token validated property to true.
   */
  static loginTokenValidatedSet(): ActionWork {
    //Constuct action with the specified interface
    return { type: ViewActions.LOGIN_TOKEN_VALIDATED_SET };
  }

  /**
   * Set the id of the currently selected distribution group
   */
  static distributionGroupSelectedIdSet(
    distributionGroupId: number
  ): ActionNumber {
    //Constuct action with the specified interface
    return {
      type: ViewActions.DISTRIBUTION_GROUP_SELECTED_ID_SET,
      number: distributionGroupId
    };
  }

  /**
   * Set the id of the currently selected distribution group
   */
  static distributionGroupUserSelectedIdSet(
    userId: number
  ): ActionNumber {
    //Constuct action with the specified interface
    return {
      type: ViewActions.DISTRIBUTION_GROUP_USER_SELECTED_ID_SET,
      number: userId
    };
  }

  /**
   * Set the id of the currently selected distribution
   */
  static distributionGroupDistributionSelectedIdSet(
    distributionId: number
  ): ActionNumber {
    //Constuct action with the specified interface
    return {
      type: ViewActions.DISTRIBUTION_GROUP_DISTRIBUTION_SELECTED_ID_SET,
      number: distributionId
    };
  }

  /**
   * Create an action that selects the currently selected export type by id.
   *
   * @param exportTypeId              The id of the currently selected export type.
   */
  static distributionGroupExportTypeSelectedIdSet(
    exportTypeId: number
  ): ActionNumber {
    return {
      type: ViewActions.DISTRIBUTION_GROUP_EXPORT_TYPE_SELECTED_ID,
      number: exportTypeId
    };
  }

  /**
   * Set the id of the currently selected export
   */
  static exportSelectedIdSet(exportId: number): ActionNumber {
    //Constuct action with the specified interface
    return {
      type: ViewActions.EXPORT_SELECTED_ID_SET,
      number: exportId
    };
  }

  /**
   * Set the id of the currently selected export version
   */
  static exportVersionSelectedIdSet(exportVersionId: number): ActionNumber {
    //Constuct action with the specified interface
    return {
      type: ViewActions.EXPORT_VERSION_SELECTED_ID_SET,
      number: exportVersionId
    };
  }

  /**
   * Set the id of the currently selected export version
   */
  static exportVersionHideStatesSet(
    hideVersionStates: string[]
  ): ActionStringArray {
    //Constuct action with the specified interface
    return {
      type: ViewActions.EXPORT_VERSIONS_HIDE_STATES,
      strings: hideVersionStates
    };
  }

  /**
   * Set the id of the currently selected distribution
   */
  static distributionSelectedIdSet(distributionId: number): ActionNumber {
    //Constuct action with the specified interface
    return {
      type: ViewActions.DISTRIBUTION_SELECTED_ID_SET,
      number: distributionId
    };
  }

  /**
   * Set the id of the currently selected distribution
   */
  static exportDistributionSelectedIdSet(distributionId: number): ActionNumber {
    //Constuct action with the specified interface
    return {
      type: ViewActions.EXPORT_DISTRIBUTION_SELECTED_ID_SET,
      number: distributionId
    };
  }

  /**
   * Set the id of the currently selected dataSet
   */
  static libraryPublishingDataSetSelectedIdSet(
    dataSetId: number
  ): ActionNumber {
    //Constuct action with the specified interface
    return {
      type: ViewActions.LIBRARY_PUBLISHING_DATASET_SELECTED_ID_SET,
      number: dataSetId
    };
  }

  /**
   * Create an action so we can select a list of data set category indexes.
   *
   * @param dataSetCategoryIndexes    The data set category indexes to set.
   */
  static libraryPublishingDataSetCategoryIndexesSelectedSet(
    dataSetCategoryIndexes: DataSetCategoryIndexAHubVO[]
  ): ActionDataSetCategoryIndexAHubVOs {
    //Constuct action with the specified interface
    return {
      type:
        ViewActions.LIBRARY_PUBLISHING_DATASET_CATEGORY_INDEXES_SELECTED_SET,
      dataSetCategoryIndexes: dataSetCategoryIndexes
    };
  }

  /**
   * Set the id of the currently selected exporter
   */
  static libraryPublishingExporterSelectedIdSet(
    exporterId: number
  ): ActionNumber {
    //Constuct action with the specified interface
    return {
      type: ViewActions.LIBRARY_PUBLISHING_EXPORTER_SELECTED_ID_SET,
      number: exporterId
    };
  }

  /**
   * Set the id of the currently selected exporter delivery target
   */
  static libraryPublishingExporterDestinationSelectedIdSet(
    exporterDestinationId: number
  ): ActionNumber {
    //Constuct action with the specified interface
    return {
      type: ViewActions.LIBRARY_PUBLISHING_EXPORTER_DESTINATION_SELECTED_ID_SET,
      number: exporterDestinationId
    };
  }

  /**
   * Set the id of the currently selected exporter build history item
   */
  static libraryPublishingExporterBuildHistorySelectedIdSet(
    exporterId: number
  ): ActionNumber {
    //Constuct action with the specified interface
    return {
      type:
        ViewActions.LIBRARY_PUBLISHING_EXPORTER_BUILD_HISTORY_SELECTED_ID_SET,
      number: exporterId
    };
  }

  /**
   * Set the id of the currently selected exporter build history item
   */
  static libraryPublishingSelectedExporterAllocsSet(
    selectedExporterAllocs: PropertyAllocationObjectAViewVO[]
  ): ActionPropertyAllocationObjectAViewVOs {
    //Constuct action with the specified interface
    return {
      type:
        ViewActions.LIBRARY_PUBLISHING_SELECTED_EXPORTER_ALLOCS_SET,
      allocs: selectedExporterAllocs
    };
  }

  /**
   * Create an action to set the id of the selected resource pack.
   *
   * @param resourcePackId            The id of the selected resource pack.
   */
  static libraryPublishingResourcePackSelectedIdSet(
    resourcePackId: number
  ): ActionNumber {
    return {
      type: ViewActions.LIBRARY_PUBLISHING_RESOURCE_PACK_SELECTED_ID_SET,
      number: resourcePackId
    };
  }

  /**
   * Set the id of the currently selected client
   */
  static accountManagementClientSelectedIdSet(clientId: number): ActionNumber {
    //Constuct action with the specified interface
    return {
      type: ViewActions.ACCOUNT_MANAGEMENT_CLIENT_SELECTED_ID_SET,
      number: clientId
    };
  }

  /**
   * Set the id of the currently selected user ( view nothing to do with session. )
   */
  static accountManagementUserSelectedIdSet(userId: number): ActionNumber {
    //Constuct action with the specified interface
    return {
      type: ViewActions.ACCOUNT_MANAGEMENT_USER_SELECTED_ID_SET,
      number: userId
    };
  }

  /**
   * Set the index for the distribution groups in the store
   */
  static selectedUserDistributionGroupIndexesSet(distributionGroupIndexs: DistributionGroupIndexAHubVO[]): ActionDistributionGroupIndexAHubVOs {
    return {
      type: ViewActions.SELECTED_USER_DISTRIBUTION_GROUP_INDEXS_SET,
      distributionGroupIndexs: distributionGroupIndexs
    }
  }

  /**
   * Set the id of the currently selected client library version
   */
  static clientLibraryVersionSelectedSet(version: number): ActionNumber {
    //Constuct action with the specified interface
    return {
      type: ViewActions.CLIENT_LIBRARY_VERSION_SELECTED_SET,
      number: version
    };
  }

  /**
   * Set the id of the currently selected workGroup
   */
  static workGroupSelectedIdSet(workGroupId: number): ActionNumber {
    //Constuct action with the specified interface
    return {
      type: ViewActions.WORK_GROUP_SELECTED_ID_SET,
      number: workGroupId
    };
  }

  /**
   * Set the route view available (eg in the sidenav)
   */
  static availableRoutesSet(
    availableRoutes: RouteViewVO[]
  ): ActionRouteViewVOs {
    //Constuct action with the specified interface
    return {
      type: ViewActions.AVAILABLE_ROUTES_SET,
      routeViews: availableRoutes
    };
  }

  /**
   * Set the selected route view (eg in the sidenav)
   */
  static selectedRouteViewSet(
    selectedRouteView: RouteViewVO
  ): ActionRouteViewVO {
    //Constuct action with the specified interface
    return {
      type: ViewActions.SELECTED_ROUTE_VIEW_SET,
      routeView: selectedRouteView
    };
  }

  /**
   * Set the form state
   */
  static formStateSet(formState: FormStateViewVO): ActionFormState {
    return {
      type: ViewActions.PAGE_FORM_STATE_SET,
      formState: formState
    };
  }

  /**
   * Set a set of forms force reset value to true.
   */
  static formStateForceResetByIds(formIds: string[]): ActionStringArray {
    return {
      type: ViewActions.PAGE_FORM_STATE_FORCE_RESET_BY_IDS,
      strings: formIds
    };
  }

  /**
   * Remove the form state by id
   */
  static formStateRemoveById(formId: string): ActionString {
    return {
      type: ViewActions.PAGE_FORM_STATE_REMOVE_BY_ID,
      string: formId
    };
  }

  /**
   * Set the componenet state
   */
  static componentStateSet(
    componentState: ComponentStateViewVO
  ): ActionComponentStateViewVO {
    return {
      type: ViewActions.PAGE_COMPONENT_STATE_SET,
      componentState: componentState
    };
  }

  /**
   * Remove the component state by id
   */
  static componentStateRemoveById(componentId: string): ActionString {
    return {
      type: ViewActions.PAGE_COMPONENT_STATE_REMOVE_BY_ID,
      string: componentId
    };
  }

  /**
   * Set the id of the currently selected client quota
   */
  static clientQuotaSelectedIdSet(clientQuotaId: number): ActionNumber {
    //Constuct action with the specified interface
    return {
      type: ViewActions.CLIENT_QUOTA_SELECTED_ID_SET,
      number: clientQuotaId
    };
  }

  /**
   * Set the id of the currently selected property
   */
  static propertySelectedIdSet(propertyId: number): ActionNumber {
    //Constuct action with the specified interface
    return {
      type: ViewActions.PROPERTY_SELECTED_ID_SET,
      number: propertyId
    };
  }

  /**
   * Set the id of the currently selected product
   */
  static libraryExtractProductSelectedIdsSet(
    productId: number[]
  ): ActionNumberArray {
    //Constuct action with the specified interface
    return {
      type: ViewActions.LIBRARY_EXTRACT_PRODUCT_SELECTED_IDS_SET,
      numbers: productId
    };
  }

  /**
   * Set displayed columns
   */
  static libraryExtractDisplayedColumnsSet(
    allocs: ExtractDefinitionPropertyAllocationObjectVO[]
  ): ActionExtractDefinitionPropertyAllocationObjectVOArray {
    //Constuct action with the specified interface
    return {
      type: ViewActions.LIBRARY_EXTRACT_DISPLAYED_COLUMNS_SET,
      allocs: allocs
    };
  }

  /**
   * Set the id of the currently selected product
   */
  static productSelectedSectionPropertyIdSet(productId: number): ActionNumber {
    //Constuct action with the specified interface
    return {
      type: ViewActions.PRODUCT_SELECTED_SECTION_PROPERTY_ID_SET,
      number: productId
    };
  }

  /**
   * Sets the current product section id
   */
  static libraryClassificationProductSectionIdSet(
    productSectionId: number
  ): ActionNumber {
    //Constuct action with the specified interface
    return {
      type: ViewActions.LIBRARY_CLASSIFICATION_PRODUCT_SECTION_ID_SET,
      number: productSectionId
    };
  }

  /**
   * Sets the list of the selected product classes ... this will reset old ones
   */
  static libraryClassificationProductClassesSelectedSet(
    productClassIndexs: ProductClassIndexAHubVO[]
  ): ActionProductClassIndexAHubVOs {
    //Constuct action with the specified interface
    return {
      type: ViewActions.LIBRARY_CLASSIFICATION_PRODUCT_CLASSES_SELECTED_SET,
      productClassIndexs: productClassIndexs
    };
  }

  /**
   * Sets the list of alloc indexes used in the view ... this will reset old ones
   */
  static libraryClassificationAllocIndexesSet(
    productPropertyIndexes: ProductPropertyIndexAHubVO[]
  ): ActionProductPropertyIndexAHubVOs {
    //Constuct action with the specified interface
    return {
      type: ViewActions.LIBRARY_CLASSIFICATION_PRODUCT_PROPERTY_INDEXES_SET,
      productPropertyIndexes: productPropertyIndexes
    };
  }

  /**
   * Create an action to set the selected chaining product classes.
   *
   * @param productClassIndexs            The indexes of the classes that have been selected.
   */
  static libraryConfigProductsChainingProductClassesSelectedSet(
    productClassIndexs: ProductClassIndexAHubVO[]
  ): ActionProductClassIndexAHubVOs {
    //Constuct action with the specified interface
    return {
      type:
        ViewActions.LIBRARY_CONFIG_PRODUCTS_CHAINING_PRODUCT_CLASSES_SELECTED_SET,
      productClassIndexs: productClassIndexs
    };
  }

  /**
   * Create an action to set the selected product alloc chain id.
   *
   * @param productPropertyAllocChainId           The id of the selected alloc chain id.
   */
  static libraryConfigProductsChainingProductAllocChainSelectedIdSet(
    productPropertyAllocChainId: number
  ): ActionNumber {
    //Constuct action with the specified interface
    return {
      type:
        ViewActions.LIBRARY_CONFIG_PRODUCTS_CHAINING_PRODUCT_ALLOC_CHAIN_SELECTED_ID_SET,
      number: productPropertyAllocChainId
    };
  }

  /**
   * Set the id's of the currently selected chain values.
   */
  static libraryConfigProductsChainingProductAllocChainSelectedValuesIdsSet(
    valueIds: number[]
  ): ActionNumberArray {
    //Constuct action with the specified interface
    return {
      type:
        ViewActions.LIBRARY_CONFIG_PRODUCTS_CHAINING_PRODUCT_ALLOC_CHAIN_SELECTED_VALUE_IDS_SET,
      numbers: valueIds
    };
  }

  /**
   * Clear the id's of the currently selected chain values.
   */
  static libraryConfigProductsChainingProductAllocChainSelectedValuesIdsClear(): ActionWork {
    //Constuct action with the specified interface
    return {
      type:
        ViewActions.LIBRARY_CONFIG_PRODUCTS_CHAINING_PRODUCT_ALLOC_CHAIN_SELECTED_VALUE_IDS_CLEAR
    };
  }

  /**
   * Create an action to set the changed product property allocation chain values.
   *
   * @param valueChanges          The value change objects that have changed.
   */
  static libraryConfigProductsChainingProductAllocChainChangedValuesSet(
    valueChanges: ProductPropertyAllocChainValueCommitAHubVO[]
  ): ActionProductPropertyAllocationChainValueCommitAHubVOs {
    //Constuct action with the specified interface
    return {
      type:
        ViewActions.LIBRARY_CONFIG_PRODUCTS_CHAINING_PRODUCT_ALLOC_CHAIN_CHANGED_VALUES_SET,
      productPropertyAllocChainValueCommitAHubVOs: valueChanges
    };
  }

  /**
   * Create an action to remove a product property allocation chain values from the change list by id.
   *
   * @param changedIds           The id's of the changes to remove.
   */
  static libraryConfigProductsChainingProductAllocChainChangedValuesRemove(
    changedIds: number[]
  ): ActionNumberArray {
    //Constuct action with the specified interface
    return {
      type:
        ViewActions.LIBRARY_CONFIG_PRODUCTS_CHAINING_PRODUCT_ALLOC_CHAIN_CHANGED_VALUES_REMOVE,
      numbers: changedIds
    };
  }

  /**
   * Create an action to clear the product property allocation chain values that have been changed.
   */
  static libraryConfigProductsChainingProductAllocChainChangedValuesClear(): ActionWork {
    //Constuct action with the specified interface
    return {
      type:
        ViewActions.LIBRARY_CONFIG_PRODUCTS_CHAINING_PRODUCT_ALLOC_CHAIN_CHANGED_VALUES_CLEAR
    };
  }

  /**
   * Create an action to record the resolve product property allocaiton chain value chained values.
   *
   * @param chainedValues             The chained values to record.
   */
  static libraryConfigResolveProductPropertyAllocationChainValueValuesSet(
    chainedValues: string[]
  ): ActionStringArray {
    return {
      type:
        ViewActions.LIBRARY_CONFIG_PRODUCT_PROPERTY_ALLOCATION_CHAIN_VALUE_VALUES_SET,
      strings: chainedValues
    };
  }

  /**
   * Store the product property allocation chain validate report.
   *
   * @param productPropertyAllocChainValidateReport       The report to set in the view store.
   */
  static libraryConfigProductsChainingProductAllocChainValidateReportSet(
    productPropertyAllocChainValidateReport: ProductPropertyAllocChainValidateReportAHubVO
  ): ActionProductPropertyAllocationChainValidateReportAHubVO {
    return {
      type:
        ViewActions.LIBRARY_CONFIG_PRODUCT_PROPERTY_ALLOCATION_CHAIN_VALIDATE_REPORT_SET,
      productPropertyAllocationChainValidateReport: productPropertyAllocChainValidateReport
    };
  }

  /**
   * Create a new action to set the library extracts selected definition id.
   *
   * @param extractDefinitionId            The id of the extract definition to select.
   */
  static libraryExtractsDefinitionIdSet(
    extractDefinitionId: number
  ): ActionNumber {
    // Construct and return the new action.
    return {
      type: ViewActions.LIBRARY_EXTRACTS_DEFINITION_SELECTED_ID_SET,
      number: extractDefinitionId
    };
  }

  /**
   * Create a new action to set the library extracts selected definition id.
   *
   * @param extractDefinitionId            The id of the extract definition to select.
   */
  static libraryExtractsDefinitionExtractIndexSet(
    extractIndexes: ExtractIndexAHubVO[]
  ): ActionExtractIndexAHubVOs {
    // Construct and return the new action.
    return {
      type: ViewActions.LIBRARY_EXTRACT_DEFINITION_EXTRACT_INDEX_SET,
      extractIndexes: extractIndexes
    };
  }

  /**
   * Create a new action to set the library extracts definition propery id.
   *
   * @param extractDefinitionPropertyId            The id of the extract definition property to select.
   */
  static libraryExtractsDefinitionPropertyIdSet(
    extractDefinitionPropertyId: number
  ): ActionNumber {
    // Construct and return the new action.
    return {
      type: ViewActions.LIBRARY_EXTRACT_DEFINITION_PROPERTY_SELECTED_ID_SET,
      number: extractDefinitionPropertyId
    };
  }

  /**
   * Sets the extract property definition supplied
   */
  static libraryExtractsDefinitionPropertySet(
    extractDefinitionProperty: ExtractDefinitionPropertyAHubVO[]
  ): ActionExtractDefinitionPropertyAHubVOs {
    //Constuct action with the specified interface
    return {
      type: ViewActions.LIBRARY_EXTRACT_DEFINITION_PROPERTY_SET,
      extractDefinitionProperties: extractDefinitionProperty
    };
  }

  /**
   * Sets the extract definition product identifier property supplied
   */
  static libraryExtractsDefinitionProductIdentifierPropertySet(
    extractDefinitionProperty: ExtractDefinitionPropertyAHubVO
  ): ActionExtractDefinitionPropertyAHubVO {
    //Constuct action with the specified interface
    return {
      type:
        ViewActions.LIBRARY_EXTRACT_DEFINITION_PRODUCT_IDENTIFIER_PROPERTY_SET,
      extractDefinitionProperty: extractDefinitionProperty
    };
  }

  /**
   * Updates or adds the extract property definition supplied
   */
  static libraryExtractsDefinitionPropertyUpdate(
    extractDefinitionProperty: ExtractDefinitionPropertyAHubVO
  ): ActionExtractDefinitionPropertyAHubVO {
    //Constuct action with the specified interface
    return {
      type: ViewActions.LIBRARY_EXTRACT_DEFINITION_PROPERTY_UPDATE,
      extractDefinitionProperty: extractDefinitionProperty
    };
  }

  /**
   * Updates or adds the extract property definition supplied
   */
  static libraryExtractsDefinitionPropertiesUpdate(
    extractDefinitionProperties: ExtractDefinitionPropertyAHubVO[]
  ): ActionExtractDefinitionPropertyAHubVOs {
    //Constuct action with the specified interface
    return {
      type: ViewActions.LIBRARY_EXTRACT_DEFINITION_PROPERTIES_UPDATE,
      extractDefinitionProperties: extractDefinitionProperties
    };
  }

  /**
   * Deletes the extract property definition supplied
   */
  static libraryExtractsDefinitionPropertyDelete(
    extractDefinitionProperty: ExtractDefinitionPropertyAHubVO[]
  ): ActionExtractDefinitionPropertyAHubVOs {
    //Constuct action with the specified interface
    return {
      type: ViewActions.LIBRARY_EXTRACT_DEFINITION_PROPERTY_DELETE,
      extractDefinitionProperties: extractDefinitionProperty
    };
  }

  /**
   * Create a new action to set the library extracts show assets state.
   *
   * @param showAssetsState            show assets state.
   */
  static libraryExtractShowAssetsSet(showAssetsState: boolean): ActionBoolean {
    // Construct and return the new action.
    return {
      type: ViewActions.LIBRARY_EXTRACT_SHOW_ASSETS_SET,
      boolean: showAssetsState
    };
  }

  /**
   * Create a new action to set the library extracts show columns state.
   *
   * @param showColumnsState            show columns state.
   */
  static libraryExtractShowColumnsSet(showColumnsState: boolean): ActionBoolean {
    // Construct and return the new action.
    return {
      type: ViewActions.LIBRARY_EXTRACT_SHOW_COLUMNS_SET,
      boolean: showColumnsState
    };
  }

  /**
   * Create a new action to set the library extracts asset swap source asset.
   *
   * @param assetSwapSource            is an asset swap pending.
   */
  static libraryAssetsSwapSourceSet(
    assetSwapSource: ProductAssetViewVO
  ): ActionProductAssetViewVO {
    // Construct and return the new action.
    return {
      type: ViewActions.LIBRARY_EXTRACT_ASSET_SWAP_SOURCE_SET,
      productAsset: assetSwapSource
    };
  }

  /**
   * Create a new action to set the library extracts asset swap source asset.
   *
   * @param assetsToSwap            array of the (2) assets to swap.
   */
  static libraryAssetsSwap(
    extractId: number,
    assetsToSwap: ProductAssetViewVO[]
  ): ActionProductAssetViewVOs {
    // Construct and return the new action.
    return {
      type: ViewActions.LIBRARY_EXTRACT_ASSET_SWAP,
      extractId: extractId,
      productAssets: assetsToSwap
    };
  }

  /**
   * Create a new action to set the library extracts selected id.
   *
   * @param extractId            The id of the extract to select.
   */
  static libraryExtractIdSelectedSet(extractId: number): ActionNumber {
    // Construct and return the new action.
    return {
      type: ViewActions.LIBRARY_EXTRACTS_SELECTED_ID_SET,
      number: extractId
    };
  }

  /**
   * Create an action to commit a set of extract filters.
   *
   * @param extractId                The id of the extract who we want the conflicts for
   */
  static extractConflictsSet(extracts: ExtractAHubVO[]): ActionExtractAHubVOs {
    return {
      type: ViewActions.LIBRARY_EXTRACT_CONFLICTS_SET,
      extracts: extracts
    };
  }

  /**
   * Send the dragged object action.
   *
   * @param draggedobject             The object thats being dragged.
   */
  static draggedObjectSet(draggedobject: any): ActionObject {
    return {
      type: ViewActions.DRAGGED_OBJECT_SET,
      object: draggedobject
    };
  }

  /**
   * This action is used to clear the dragged object.
   */
  static draggedObjectClear(): ActionWork {
    return {
      type: ViewActions.DRAGGED_OBJECT_CLEAR
    };
  }

  /**
   * Set the extract products change set
   */
  static extractProductsChangeSetSet(extractProducts: ProductAHubVO[]): ActionProductAHubVOs {
    return {
      type: ViewActions.EXTRACT_PRODUCTS_CHANGE_SET_SET,
      products: extractProducts
    };
  }

  /**
   * This action is used to clear the extract products change set (eg revert any unsaved changes)
   */
  static extractProductsChangeSetClear(): ActionWork {

    return {
      type: ViewActions.EXTRACT_PRODUCTS_CHANGE_SET_CLEAR
    };
  }

  /**
   * Create an action to remove products from the product changeset by it ids from the store.
   */
  static extractProductChangeSetRemoveByIds(
    productIdsToBeRemoved: number[]
  ): ActionNumberArray {
    return {
      type: ViewActions.EXTRACT_PRODUCTS_CHANGE_SET_REMOVE_BY_IDS,
      numbers: productIdsToBeRemoved
    };
  }

  /**
   * This function will create an instance of the action to set the current extract allocation id.
   *
   * @param extractAllocationId       The id of the extract allocation to set too.
   */
  static extractAllocationIdSet(extractAllocationId: number): ActionNumber {
    return {
      type: ViewActions.LIBRARY_EXTRACT_ALLOCATION_ID_SET,
      number: extractAllocationId
    }
  }

  /**
   * This function will create an action to open or close than allocation document setting
   *
   * @param extractAllocationId       The id of the extract allocation to set too.
   * @param open                      Is it open or closed
   */
  static extractAllocationIdDocumentSettingOpenClose(extractAllocationId: number, open: boolean): ActionBooleanNumber {
    return {
      type: ViewActions.LIBRARY_EXTRACT_ALLOCATION_DOCUMENT_SETTING_OPEN_CLOSE,
      number: extractAllocationId,
      boolean: open
    }
  }

  /**
   * Set the currently selected exporter blueprint tree node
   */
  static exporterBlueprintMappingNodesSet(
    blueprintMappingNodes: BlueprintMappingNode[]
  ): ActionExporterBlueprintMappingNodesAHubVO {
    //Constuct action with the specified interface
    return {
      type: ViewActions.LIBRARY_PUBLISHING_EXPORTER_BLUEPRINT_MAPPING_NODES_SET,
      nodes: blueprintMappingNodes
    };
  }

  /**
   * Clear the blueprint nodes we have built from the blueprint (used to construct the blueprint tree)
   */
  static exporterBlueprintMappingNodesClear(): ActionWork {
    //Constuct action with the specified interface
    return {
      type:
        ViewActions.LIBRARY_PUBLISHING_EXPORTER_BLUEPRINT_MAPPING_NODES_CLEAR
    };
  }

  /**
   * Set the blueprint nodes we have built from the blueprint (used to construct the blueprint tree)
   */
  static exporterBlueprintTreeNodeSelectedPathSet(path: string): ActionString {
    //Constuct action with the specified interface
    return {
      type:
        ViewActions.LIBRARY_PUBLISHING_EXPORTER_BLUEPRINT_TREE_NODE_SELECTED_PATH_SET,
      string: path
    };
  }

  /**
   * Clear the currently selected exporter blueprint tree node
   */
  static exporterBlueprintTreeNodeSelectedPathClear(): ActionWork {
    //Constuct action with the specified interface
    return {
      type:
        ViewActions.LIBRARY_PUBLISHING_EXPORTER_BLUEPRINT_TREE_NODE_SELECTED_PATH_CLEAR
    };
  }

  /**
   * Set the currently selected exporter class mapping
   */
  static exporterClassMappingOptionsSet(
    classOptions: ExporterMappingClassOptionAHubVO[]
  ): ActionExporterMappingClassOptionAHubVOs {
    //Constuct action with the specified interface
    return {
      type: ViewActions.LIBRARY_PUBLISHING_EXPORTER_MAPPING_CLASS_OPTIONS_SET,
      classOptions: classOptions
    };
  }

  /**
   * Set the currently selected exporter class mapping
   */
  static exporterClassMappingOptionsClear(): ActionWork {
    //Constuct action with the specified interface
    return {
      type: ViewActions.LIBRARY_PUBLISHING_EXPORTER_MAPPING_CLASS_OPTIONS_CLEAR
    };
  }

  /**
   * Set the currently selected exporter class mapping
   */
  static exporterSelectedClassMappingIdSet(classId: number): ActionNumber {
    //Constuct action with the specified interface
    return {
      type:
        ViewActions.LIBRARY_PUBLISHING_EXPORTER_MAPPING_CLASS_ID_SELECTED_SET,
      number: classId
    };
  }

  /**
   * Set number of items per row in a grid view
   */
  static gridViewItemsPerRowSet(numberOfItemsPerRow: number): ActionNumber {
    //Constuct action with the specified interface
    return {
      type:
        ViewActions.GRID_VIEW_ITEMS_PER_ROW_SET,
      number: numberOfItemsPerRow
    };
  }

  /**
   * Set the data array position from which to start the current view 
   */
  static infinityScrollerCurrentViewStartSet(position: number): ActionNumber {
    //Constuct action with the specified interface
    return {
      type:
        ViewActions.INFINITY_SCROLLER_CURRENT_VIEW_START,
      number: position
    };
  }

}

import { Directive, Input } from '@angular/core';
import { MatTooltip, TooltipPosition } from '@angular/material/tooltip';


/**
 * This directive is used when we want to show an objects id with a tool tip.
 */
@Directive({ selector: '[idToolTip]' })
export class IdToolTipDirective extends MatTooltip {


    /**
     * By using the inputs name and then passing it to the message property
     * we actually pass the message string down to the parent tool tip class.
     */
    @Input('idToolTip') message: string;

    /**
     * The name of the style to use in the tool tip.
     */
    tooltipClass: string = "id-tool-tip";

    /**
     * The tool tips location.
     */
    position: TooltipPosition = 'below';

    /**
     * The default delay in ms before showing the tooltip after show is called.
     */
    showDelay: number = 1500;
}
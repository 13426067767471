/**
 * Static readonly implementations of the export types in the aHub.
 */
export enum ExportTypeCodeAHubEnum {
    ALL = 'ALL',
    AVIEW = 'AVIEW',
    AWORKBOOK_CATALOGUE = 'AWORKBOOK_CATALOGUE',
    AWORKBOOK_CATALOGUE_JSON = 'AWORKBOOK_CATALOGUE_JSON',
    CSV = 'CSV',
    PDF = 'PDF'
}

/**
 * This function will get the path to the icon for an export type code.
 */
export const exportTypeIconPathGet = (exportTypeCode: ExportTypeCodeAHubEnum) => {

    // Set up the path to return.
    let exportTypeIconPath: string = "./assets/images/imageNotAvailable.png";

    // What export type id does this item have?
    switch (exportTypeCode) {

        // All export types.
        case ExportTypeCodeAHubEnum.ALL:

            // Set to the allExportTypes icon.
            exportTypeIconPath = "./assets/images/allExportTypes.png";

            // Job done.
            break;

        // Export type for aWorkbook legacy.
        case ExportTypeCodeAHubEnum.AWORKBOOK_CATALOGUE:

            // Set to the aWorkbook icon.
            exportTypeIconPath = "./assets/images/aWorkbookIcon.png";

            // Job done.
            break;

        // Export type for aWorkbook JSON.
        case ExportTypeCodeAHubEnum.AWORKBOOK_CATALOGUE_JSON:

            // Set to the aWorkbook icon.
            exportTypeIconPath = "./assets/images/aWorkbookJSONIcon.png";

            // Job done.
            break;


        // Export type for aWorkbook JSON.
        case ExportTypeCodeAHubEnum.AVIEW:

            // Set to the aView icon.
            exportTypeIconPath = "./assets/images/aViewIcon.png";

            // Job done.
            break;

        // Export type for aWorkbook CSV.
        case ExportTypeCodeAHubEnum.CSV:

            // Set to the aWorkbook icon.
            exportTypeIconPath = "./assets/images/csv.png";

            // Job done.
            break;

        // Export type for aWorkbook PDF.
        case ExportTypeCodeAHubEnum.PDF:

            // Set to the aWorkbook icon.
            exportTypeIconPath = "./assets/images/pdf.png";

            // Job done.
            break;
    }

    // Return the path to the export type icon.
    return exportTypeIconPath;
}

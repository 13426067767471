import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { takeUntil, filter, debounceTime } from 'rxjs/operators';
import { componentDestroyStream, Hark } from '../../hark.decorator';
import { FormGroup, FormControl } from '@angular/forms';
import { StoreAccess } from 'app/store/store-access';
import { AppActions } from 'app/store/actions/app.actions';

@Component({
  selector: 'app-product-property-option-list-editor',
  templateUrl: './product-property-option-list-editor.component.html',
  styleUrls: ['./product-property-option-list-editor.component.css']
})
@Hark()
export class ProductPropertyOptionListEditorComponent implements OnInit {

  @Input() valueOptions: ProductPropertyEnumListEditorOption[]
  @Input() selectedOptions$: BehaviorSubject<string[]> = new BehaviorSubject([]);
  @Input() showDoneEditing = true;
  @Input() showRawData = true;

  /**
   * Done button click emitter to indicate when the add button has been clicked
   */
  @Output() doneEditing: EventEmitter<void> = new EventEmitter<void>();


  selectedOptions: string[] = [];

  listInputForm = new FormGroup({
    listInput: new FormControl()
  });


  constructor() {
    // This is intentional
  }

  ngOnInit() {

    this.selectedOptions$.pipe(
      takeUntil(componentDestroyStream(this)),
    ).subscribe(selectedOptions => {
      this.selectedOptions = selectedOptions
      this.listInputForm.controls.listInput.setValue(JSON.stringify(selectedOptions), { emitEvent: false })
    });

    combineLatest([
      this.listInputForm.controls.listInput.valueChanges,
      this.listInputForm.statusChanges
    ]).pipe(
      debounceTime(50),
      filter(([listInput, status]) => status === 'VALID'),
      takeUntil(componentDestroyStream(this))
    )
      .subscribe(([listInput, status]) => {

        const listInputArray: string[] = JSON.parse(listInput);

        const validValueOptions: string[] = this.valueOptions.map(valueOption => valueOption.value);

        const validListInputArray = listInputArray.filter(inputArrayElement => validValueOptions.includes(inputArrayElement));

        if (validListInputArray.length === listInputArray.length) {
          this.selectedOptions$.next(listInputArray);
        }
      });
  }

  ngOnDestroy() { }

  doneEditingClick() {
    this.doneEditing.emit();
  }

  selectionChanged() {
    this.selectedOptions$.next(this.selectedOptions);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.valueOptions, event.previousIndex, event.currentIndex);
    this.selectedOptions.sort((a, b) => {
      return this.valueOptions.map(option => option.value).indexOf(a) - this.valueOptions.map(option => option.value).indexOf(b);
    })
    this.selectedOptions$.next(this.selectedOptions);
  }

  /**
 * Copy the value to the clipboad
 */
  copyValueToClipboard() {

    //Distpatch a copy to the clipboard action
    StoreAccess.dispatch(AppActions.clipboardTextCopySet(JSON.stringify(this.selectedOptions).toString()));
  }
}

export interface ProductPropertyEnumListEditorOption {
  value: any;
  label: string;
}

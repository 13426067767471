import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';


/**
 * The string cannot start with a number.
 */
export function numericalStartValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        const forbidden = /^[0-9]+/g.test(control.value);
        return forbidden ? { 'numericalStart': { value: control.value } } : null;
    };
}

@Directive({
    selector: '[numericalStart]',
    providers: [{ provide: NG_VALIDATORS, useExisting: NumericalStartValidatorDirective, multi: true }]
})
export class NumericalStartValidatorDirective implements Validator {

    validate(control: AbstractControl): { [key: string]: any } {
        return numericalStartValidator()(control);
    }
}
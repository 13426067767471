/**
 * Wrapper for the component that is passed in to display the objects
 * in the index list.
 */

import { Component, ComponentFactory, Input, OnInit, ViewContainerRef, EventEmitter, Output } from '@angular/core';

/**
 * Complete is initilised with a placeholder <div>
 */
@Component({
  selector: 'app-injected-wrapper',
  template: '<div #placeHolder></div>',
  styleUrls: []
})
export class InjectedWrapperComponent implements OnInit {

  // The component factory passed in to create the components for object display.
  @Input() componentFactory: ComponentFactory<any>; //Class?

  // The Value object to be redered.
  @Input() dataVO: any;

  // The parameter of the created component to which to pass the VO data.
  @Input() paramName: string;

  constructor(private readonly viewContainerRef: ViewContainerRef) { }

  ngOnInit() {

    // Create a component using the factory supplied in our wrapper.
    const createdComponent = this.viewContainerRef.createComponent(this.componentFactory);

    // Apply VO data to the component for display ( if the insertion parameter exists. )
    if (createdComponent.instance.hasOwnProperty(this.paramName)) {
      createdComponent.instance[this.paramName] = this.dataVO;
    }

  }

}

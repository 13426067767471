import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmptyComponent } from 'modules/common/components/empty-component/empty.component';
import { LoggedInGuard } from 'router/guards/logged-in-guard';
/**
 * Guards & Resolvers
 */
import { LoginTokenManagerGuard } from 'router/guards/login-token-manager.guard';
import { PermissionGuard } from 'router/guards/permission-guard';
import { UnsavedChangesGuard } from 'router/guards/unsaved-changes.guard';
import { StoreClearResolver } from 'router/resolver/store-clear.resolver';
import { ExternalRedirectGuard } from './router/guards/external-redirect-guard';
import { UrlRedirectGuard } from './router/guards/url-redirect.guard';
import { environment } from 'environments/environment';

export const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('routes/login/login.module').then(m => m.LoginModule),
    canActivate: [],
  },
  {
    path: 'logout',
    loadChildren: () => import('routes/logout/logout.module').then(m => m.LogoutModule),
  },
  {
    path: 'welcome',
    loadChildren: () => import('routes/welcome/welcome.module').then(m => m.WelcomeModule),
    canDeactivate: [UnsavedChangesGuard],
    canActivate: [UrlRedirectGuard, LoggedInGuard],
    resolve: {
      storeClearResolver: StoreClearResolver
    },
  },
  {
    path: 'my-account',
    loadChildren: () => import('routes/my-account/my-account.module').then(m => m.MyAccountModule),
    canDeactivate: [UnsavedChangesGuard],
    canActivate: [UrlRedirectGuard, LoggedInGuard],
    resolve: {
      storeClearResolver: StoreClearResolver
    }
  },
  {
    path: 'aview',
    loadChildren: () => import('./modules/routes/aview/aview.module').then(m => m.AViewModule),
    canActivate: [LoggedInGuard],
    resolve: {
      storeClearResolver: StoreClearResolver
    }
  },
  {
    path: 'access-control',
    loadChildren: () => import('routes/access-control/access-control.module').then(m => m.AccessControlModule),
    canDeactivate: [UnsavedChangesGuard],
    canActivate: [UrlRedirectGuard, LoggedInGuard, PermissionGuard],
    resolve: {
      storeClearResolver: StoreClearResolver
    },
  },
  {
    path: 'library-config',
    loadChildren: () => import('routes/library-config/library-config.module').then(m => m.LibraryConfigModule),
    canDeactivate: [UnsavedChangesGuard],
    canActivate: [UrlRedirectGuard, LoggedInGuard, PermissionGuard],
    resolve: {
      storeClearResolver: StoreClearResolver
    },
  },
  {
    path: 'library-contents',
    loadChildren: () => import('routes/library/library.module').then(m => m.LibraryModule),
    canDeactivate: [UnsavedChangesGuard],
    canActivate: [UrlRedirectGuard, LoggedInGuard, PermissionGuard],
    resolve: {
      storeClearResolver: StoreClearResolver
    },
  },
  {
    path: 'library-publishing',
    loadChildren: () => import('routes/library-publishing/library-publishing.module').then(m => m.LibraryPublishingModule),
    canDeactivate: [UnsavedChangesGuard],
    canActivate: [UrlRedirectGuard, LoggedInGuard, PermissionGuard],
    resolve: {
      storeClearResolver: StoreClearResolver
    },
  },
  {
    path: 'distribution',
    loadChildren: () => import('routes/distribution/distribution.module').then(m => m.DistributionModule),
    canDeactivate: [UnsavedChangesGuard],
    canActivate: [UrlRedirectGuard, LoggedInGuard, PermissionGuard],
    resolve: {
      storeClearResolver: StoreClearResolver
    },
  },
  {
    path: 'configuration',
    loadChildren: () => import('routes/configuration/configuration.module').then(m => m.ConfigurationModule),
    canDeactivate: [UnsavedChangesGuard],
    canActivate: [UrlRedirectGuard, LoggedInGuard, PermissionGuard],
    resolve: {
      storeClearResolver: StoreClearResolver
    },
  },
  {
    path: 'account-management',
    loadChildren: () => import('routes/account-management/account-management.module').then(m => m.AccountManagementModule),
    canDeactivate: [UnsavedChangesGuard],
    canActivate: [UrlRedirectGuard, LoggedInGuard, PermissionGuard],
    resolve: {
      storeClearResolver: StoreClearResolver
    },
  },
  {
    path: 'client-library-version-management',
    loadChildren: () => import('routes/client-library-version-management/client-library-version-management.module').then(m => m.ClientLibraryVersionManagementModule),
    canDeactivate: [UnsavedChangesGuard],
    canActivate: [UrlRedirectGuard, LoggedInGuard, PermissionGuard],
    resolve: {
      storeClearResolver: StoreClearResolver
    },
  },
  {
    path: 'usage',
    loadChildren: () => import('routes/usage/usage.module').then(m => m.UsageModule),
    canDeactivate: [UnsavedChangesGuard],
    canActivate: [UrlRedirectGuard, LoggedInGuard, PermissionGuard],
    resolve: {
      storeClearResolver: StoreClearResolver
    },
  },
  {
    path: 'client-tools',
    loadChildren: () => import('routes/client-tools/client-tools.module').then(m => m.ClientToolsModule),
    canActivate: [UrlRedirectGuard, LoggedInGuard, PermissionGuard],
    resolve: {
      storeClearResolver: StoreClearResolver
    },
  },
  {
    path: 'dev-tools',
    loadChildren: () => import('routes/dev-tools/dev-tools.module').then(m => m.DevToolsModule),
    canActivate: [UrlRedirectGuard, LoggedInGuard, PermissionGuard],
    resolve: {
      storeClearResolver: StoreClearResolver
    },
  },
  {
    path: '',
    canActivate: [UrlRedirectGuard, LoginTokenManagerGuard],
    component: EmptyComponent
  },
  // {
  //   path: '',
  //   redirectTo: 'products',
  //   pathMatch: 'full'
  // },
  {
    path: 'externalKnowledgeBaseRedirect',
    canActivate: [LoggedInGuard, ExternalRedirectGuard],
    data: {
      externalUrl: environment.knowledgeBasePath,
    },
    // We need a component here because we cannot define the route otherwise
    component: EmptyComponent,
  },
  {
    path: 'externalAworkbookSoftwareDownloadRedirect',
    canActivate: [ExternalRedirectGuard],
    data: {
      externalUrl: 'https://www.aworkbook.com/software-downloads/',
      newTab: true
    },
    // We need a component here because we cannot define the route otherwise
    component: EmptyComponent,
  },


  // ==================================
  // CATCH ALL UNKNOWN - MUST BE LAST
  // ==================================
  {
    path: '**',
    canActivate: [UrlRedirectGuard, LoginTokenManagerGuard],
    component: EmptyComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [ExternalRedirectGuard],
})
export class AppRoutingModule { }

/**
 * This is the class full of search utilities.
 */
export class SearchUtils {

    /**
     * This function will search a list of strings with one single string by splitting it up on the spaces.
     * 
     * The function will return true if all of the search string parts are found
     * in the list of object parameters provided.
     */
    static stringSearch(searchString: string, objectParameters: string[]): boolean {

        // Make sure we check for empty data.
        if (searchString == undefined || searchString.trim().length == 0)
            return true;

        //Split the search text into the various terms
        let searchTerms: string[] = searchString.toLowerCase().split(' ');

        // Now call the string terms search with the terms we have created.
        return SearchUtils.stringTermsSearch(searchTerms, objectParameters);
    }


    /**
     * This function will search a list of strings, with a list of strings.
     * 
     * The function will return true if all of the search string parts are found
     * in the list of object parameters provided.
     */
    static stringTermsSearch(searchTerms: string[], objectParameters: string[]): boolean {

        // We want to look for list of object parameters that DON'T match all of the terms passed in.
        // Therefore we look for the terms that aren't in this list of object parameters.
        // If there are none, then we return true.
        let missingTerm: string = searchTerms.find((term) => {

            // Is the term missing in the list? Defaults to yes.
            let termIsMissing: boolean = true;

            // Repeat through the list of parameters.
            for (let parameter of objectParameters) {

                // Does the paramter have the term we are looking at?
                if (parameter && parameter.toLowerCase().indexOf(term) > -1) {

                    // The term is not missing, we have found it.
                    termIsMissing = false;

                    // Then leave the repeat.
                    break;
                }
            }

            // Return true if the term is missing, as we want to return the term.
            return termIsMissing;
        });

        // Did we find any terms that were missing? If there are no missing terms we return true
        // as this object shouldn't be filtered out.
        return (missingTerm == undefined);
    }
}
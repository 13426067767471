import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'exclamationToNot'
})
export class ExclamationToNotPipe implements PipeTransform {

    transform(value: string, args?: any): any {
        if (value) {
            return value.replace(/!/g, '<strong> not </strong>');
        }
    }

}

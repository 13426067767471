import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dataset-category-sorting-info-dialog',
  templateUrl: './dataset-category-sorting-info-dialog.component.html',
  styleUrls: ['./dataset-category-sorting-info-dialog.component.css']
})
export class DatasetCategorySortingInfoDialogComponent implements OnInit {

  /**
   * The VO that is passed in / out.
   */
  public dialogVO: any = null;

  constructor() {
    // This is intentional
  }

  ngOnInit() {
  }

}

export interface DataSetLibraryViewClassConfigAHubVO {
    classId: number;
    productIdentifierPropertyAlloc: number;
    productMainImagePropertyAlloc: number;
    productInfoPropertyAllocs?: number[];
}

export const EMPTY_DATASET_LIBRARY_VIEW_CLASS_CONFIG: DataSetLibraryViewClassConfigAHubVO = {
    classId: null,
    productIdentifierPropertyAlloc: null,
    productInfoPropertyAllocs: null,
    productMainImagePropertyAlloc: null,
}

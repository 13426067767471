import { Reducer } from 'redux';
import { createReducer } from 'reducers/reducer-util';
import { tassign } from 'modules/common/type-assign.util';

/**
 * Value objects.
 */
import { ProductClassIndexAHubVO } from 'valueObjects/ahub/library/product-class-index.ahub.vo';

/**
 * Actions.
 */
import { ViewActions } from 'actions/view.actions';

/**
 * Action Types
 */
import {
    ActionProductClassIndexAHubVOs,
    ActionProductPropertyAllocationChainValueCommitAHubVOs,
    ActionProductPropertyAllocationChainValidateReportAHubVO
} from 'actions/types/library.action-types'
import { ActionNumber, ActionNumberArray, ActionStringArray } from 'actions/types/common.action-types';
import { ActionWork } from 'actions/types/work.action-types';
import { ProductPropertyAllocChainValueCommitAHubVO } from 'valueObjects/ahub/library/product-property-alloc-chain-value-commit.ahub';

/**
 * Utils.
 */
import { ListUtil } from 'store/list.util';
import { List } from 'store/list.vo';
import { IdListUtil } from 'store/id-list.util';
import { ProductPropertyAllocChainValidateReportAHubVO } from 'valueObjects/ahub/library/product-property-alloc-chain-validate-report.ahub.vo';

/**
 * ----------------------------------
 * View State interface
 * ----------------------------------
 */
export interface ViewLibraryChainingState {
    chainingProductClassesSelected: ProductClassIndexAHubVO[];
    chainingProductAllocChainSelectedId: number;
    chainingProductAllocChainSelectedValueIds: number[];
    chainingProductAllocChainValuesChanged: List<ProductPropertyAllocChainValueCommitAHubVO>;
    chainingProductAllocChainValueChainedValues: string[];
    productPropertyAllocationChainValidateReport: ProductPropertyAllocChainValidateReportAHubVO;
};


/**
 * ----------------------------------
 * Initial State
 * ----------------------------------
 */
export const ViewLibraryChainingInitialState: ViewLibraryChainingState = {
    chainingProductClassesSelected: [],
    chainingProductAllocChainSelectedId: -1,
    chainingProductAllocChainSelectedValueIds: [],
    chainingProductAllocChainValuesChanged: ListUtil.listCreateEmpty(),
    chainingProductAllocChainValueChainedValues: [],
    productPropertyAllocationChainValidateReport: null
};

/**
 * ----------------------------------
 * View State Reducer
 * ----------------------------------
 */

/**
 * Set the selected classes 
 */
export const chainingProductClassesSelectedSet = (state: ViewLibraryChainingState, action: ActionProductClassIndexAHubVOs) => {
    return tassign(state, { chainingProductClassesSelected: action.productClassIndexs });
}


/**
 * Set the selected product alloc chain id. 
 */
export const chainingProductAllocChainSelectedIdSet = (state: ViewLibraryChainingState, action: ActionNumber) => {
    return tassign(state, { chainingProductAllocChainSelectedId: action.number });
}

/**
 * Set the selected value ids.
 */
export const chainingProductAllocChainSelectedValueIdsSet = (state: ViewLibraryChainingState, action: ActionNumberArray) => {
    return tassign(state, { chainingProductAllocChainSelectedValueIds: action.numbers });
}

/**
 * Clear the selected value ids. 
 */
export const chainingProductAllocChainSelectedValueIdsClear = (state: ViewLibraryChainingState, action: ActionWork) => {
    return tassign(state, { chainingProductAllocChainSelectedValueIds: [] });
}

/**
 * Set the list of changed chain values.
 */
export const chainingProductAllocChainChangedValuesSet = (state: ViewLibraryChainingState, action: ActionProductPropertyAllocationChainValueCommitAHubVOs) => {
    return tassign(state, {
        chainingProductAllocChainValuesChanged: IdListUtil.listAppend(state.chainingProductAllocChainValuesChanged, action.productPropertyAllocChainValueCommitAHubVOs)
    });
}

/**
 * Remove values from value change set by ids.
 */
const chainingProductAllocChainChangedValuesRemove = (state: ViewLibraryChainingState, action: ActionNumberArray) => {
    return tassign(state, {
        chainingProductAllocChainValuesChanged: IdListUtil.listRemove(state.chainingProductAllocChainValuesChanged, action.numbers)
    });
}

/**
 * Clear the changed product property allocation chain values.
 */
const chainingProductAllocChainChangedValuesClear = (state: ViewLibraryChainingState, action: ActionWork) => {
    return tassign(state, { chainingProductAllocChainValuesChanged: IdListUtil.listCreateEmpty() });
}


/**
 * Set the product property allocation chain value chained values. 
 */
const chainingProductAllocChainValueChainedValuesSet = (state: ViewLibraryChainingState, action: ActionStringArray) => {
    return tassign(state, { chainingProductAllocChainValueChainedValues: action.strings });
}

/**
 * Set the product property allocation chain validate report. 
 */
const chainingProductAllocChainValidateReportSet = (state: ViewLibraryChainingState, action: ActionProductPropertyAllocationChainValidateReportAHubVO) => {
    return tassign(state, { productPropertyAllocationChainValidateReport: action.productPropertyAllocationChainValidateReport });
}

/**
 * ----------------------------------
 * Reducers Mapping
 * ----------------------------------
 */

/**
 * Reducers handlers object ... match actions to the handler functions
 */
let reducerHandlers = {};

/**
 * Map the actions to the reducer functions this will allow us to call the reducer
 */
reducerHandlers[ViewActions.LIBRARY_CONFIG_PRODUCTS_CHAINING_PRODUCT_CLASSES_SELECTED_SET] = chainingProductClassesSelectedSet
reducerHandlers[ViewActions.LIBRARY_CONFIG_PRODUCTS_CHAINING_PRODUCT_ALLOC_CHAIN_SELECTED_ID_SET] = chainingProductAllocChainSelectedIdSet
reducerHandlers[ViewActions.LIBRARY_CONFIG_PRODUCTS_CHAINING_PRODUCT_ALLOC_CHAIN_SELECTED_VALUE_IDS_SET] = chainingProductAllocChainSelectedValueIdsSet
reducerHandlers[ViewActions.LIBRARY_CONFIG_PRODUCTS_CHAINING_PRODUCT_ALLOC_CHAIN_SELECTED_VALUE_IDS_CLEAR] = chainingProductAllocChainSelectedValueIdsClear
reducerHandlers[ViewActions.LIBRARY_CONFIG_PRODUCTS_CHAINING_PRODUCT_ALLOC_CHAIN_CHANGED_VALUES_SET] = chainingProductAllocChainChangedValuesSet
reducerHandlers[ViewActions.LIBRARY_CONFIG_PRODUCTS_CHAINING_PRODUCT_ALLOC_CHAIN_CHANGED_VALUES_REMOVE] = chainingProductAllocChainChangedValuesRemove
reducerHandlers[ViewActions.LIBRARY_CONFIG_PRODUCTS_CHAINING_PRODUCT_ALLOC_CHAIN_CHANGED_VALUES_CLEAR] = chainingProductAllocChainChangedValuesClear
reducerHandlers[ViewActions.LIBRARY_CONFIG_PRODUCT_PROPERTY_ALLOCATION_CHAIN_VALUE_VALUES_SET] = chainingProductAllocChainValueChainedValuesSet
reducerHandlers[ViewActions.LIBRARY_CONFIG_PRODUCT_PROPERTY_ALLOCATION_CHAIN_VALIDATE_REPORT_SET] = chainingProductAllocChainValidateReportSet


/**
 * Create a reducers based on the reducers handlers
 */
export const ViewLibraryChainingStateReducer: Reducer<ViewLibraryChainingState> = createReducer(ViewLibraryChainingInitialState, reducerHandlers);

/**
 * Check if this reducers can handel the function specified
 */
export const ViewLibraryChainingStateHasHandler = (actionType: string): boolean => reducerHandlers.hasOwnProperty(actionType);
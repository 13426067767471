import { Component, Input } from '@angular/core';

import { UserIndexAHubVO } from 'valueObjects/ahub/accounts/user-index.ahub.vo';

@Component({
  selector: 'app-user-index-item',
  templateUrl: './user-index-item.component.html',
  styleUrls: ['./user-index-item.component.css']
})
export class UserIndexItemComponent {

  @Input() userIndexAHubVO: UserIndexAHubVO = {
    id: 0,
    email: "",
    firstName: "",
    lastName: "",
    status: "",
    homeRegion: "",
    createdDate: null,
  }

  constructor() {
    // This is intentional
  }
}

import { Component, OnInit, ViewChildren, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { StoreAccess } from 'app/store/store-access';
import { AppActions } from 'app/store/actions/app.actions';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, takeUntil, debounceTime } from 'rxjs/operators';
import { componentDestroyStream, Hark } from '../../hark.decorator';

@Component({
  selector: 'app-product-property-text-list-editor',
  templateUrl: './product-property-text-list-editor.component.html',
  styleUrls: ['./product-property-text-list-editor.component.css']
})
@Hark()
export class ProductPropertyTextListEditorComponent implements OnInit {
  /**
   * Value set
   */
  @ViewChildren('item') items: any;
  itemInputs: any;

  @Input() listValues$: BehaviorSubject<string[]>;
  @Input() showDoneEditing = true;
  @Input() showRawData = true;
  @Input() addItemRegex: string;
  @Input() addItemRegexError: string;

  /**
   * Done button click emitter to indicate when the add button has been clicked
   */
  @Output() doneEditing: EventEmitter<void> = new EventEmitter<void>();


  customValidationFunction: Function = undefined;

  listChanged$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  addValueForm: FormGroup;

  listInputForm = new FormGroup({
    listInput: new FormControl()
  });

  constructor(private readonly formBuilder: FormBuilder) { }

  /**
   * Init of the renderer
   */
  ngOnInit(): void {

    this.customValidationFunction = this.customValidation.bind(this);

    this.listInputForm.controls.listInput.setValue(JSON.stringify(this.listValues$.getValue()), { emitEvent: false })

    this.listChanged$.pipe(
      filter(listChanged => listChanged),
      takeUntil(componentDestroyStream(this))
    ).subscribe(listChanged => {
      this.listInputForm.controls.listInput.setValue(JSON.stringify(this.listValues$.getValue()), { emitEvent: false })
    });

    combineLatest([
      this.listInputForm.controls.listInput.valueChanges,
      this.listInputForm.statusChanges
    ]).pipe(
      debounceTime(50),
      filter(([listInput, status]) => status === 'VALID'),
      takeUntil(componentDestroyStream(this))
    )
      .subscribe(([listInput, status]) => {
        this.listValues$.next(JSON.parse(listInput));
        this.listChanged$.next(true);
      });

    this.customValidationFunction = this.customValidation.bind(this);

    this.addValueForm = this.formBuilder.group({
      formInput: ["", this.addItemRegex ? [Validators.required, this.customValidationFunction] : [Validators.required]]
    });
  }

  /**
   * After the view has been initalised we want to gain focus
   */
  ngAfterViewInit() { }

  ngOnDestroy() { }

  doneEditingClick() {
    this.doneEditing.emit();
  }

  /**
   * Triggered when the list data has changed
   * 
   * @param data 
   */
  listDataChanged(data) {
    this.listValues$.next(data);
    this.listChanged$.next(true);
  }

  customValidation(control: FormControl) {

    //Get the form value 
    const formValue = control.value;

    //Test the value
    const customValidation = RegExp(this.addItemRegex).test(formValue);


    //Valid value then we will return no error
    if (customValidation) {
      return null;
    }

    //Broken teturn an error 
    return {
      errorMessage: this.addItemRegexError
    };
  }

  /**
   * Copy the value to the clipboad
   */
  copyValueToClipboard() {
    StoreAccess.dispatch(AppActions.clipboardTextCopySet(JSON.stringify(this.listValues$.getValue()).toString()));
  }
}
